import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, createRoutesFromChildren, matchRoutes, RouterProvider, useLocation, useNavigationType } from "react-router-dom";
import "./index.scss";
import RouterEn from "./pages/en/_router";
import RouterFr from './pages/fr/_router';
import RouterDashboard from './pages/dashboard/_router';
import { ContextThemeProvider } from "./pages/contextTheme";
import * as Sentry from "@sentry/react";

Sentry.init({
	dsn: "https://8b615c93e5c790ec4fecd13ed8eb048c@o4505938475483136.ingest.sentry.io/4505938476728320",
	integrations: [
		new Sentry.BrowserTracing({
			// See docs for support of different versions of variation of react router
			// https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
			routingInstrumentation: Sentry.reactRouterV6Instrumentation(
				React.useEffect,
				useLocation,
				useNavigationType,
				createRoutesFromChildren,
				matchRoutes
			),
		}),
		new Sentry.Replay()
	],

	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	tracesSampleRate: 1.0,

	// Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
	tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

	// Capture Replay for 10% of all sessions,
	// plus for 100% of sessions with an error
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1.0,
});



const router = createBrowserRouter([
	RouterEn,
	RouterFr,
	RouterDashboard,
]);


ReactDOM.createRoot(document.getElementById("root")).render(
	<ContextThemeProvider>
		<RouterProvider router={router} />
	</ContextThemeProvider>
);
