export const dataAcademicDepartments = [
    { id: "accounting", en: { name: "Accounting" }, fr: { name: "Comptabilité" } },
    { id: "aerospaceEngineering", en: { name: "Aerospace Engineering" }, fr: { name: "Ingénierie Aérospatiale" } },
    { id: "anthropology", en: { name: "Anthropology" }, fr: { name: "Anthropologie" } },
    { id: "architecture", en: { name: "Architecture" }, fr: { name: "Architecture" } },
    { id: "artHistory", en: { name: "Art History" }, fr: { name: "Histoire de l'art" } },
    { id: "biology", en: { name: "Biology" }, fr: { name: "Biologie" } },
    { id: "businessAdministration", en: { name: "Business Administration" }, fr: { name: "Administration des affaires" } },
    { id: "chemicalEngineering", en: { name: "Chemical Engineering" }, fr: { name: "Génie chimique" } },
    { id: "chemistry", en: { name: "Chemistry" }, fr: { name: "Chimie" } },
    { id: "civilEngineering", en: { name: "Civil Engineering" }, fr: { name: "Génie civil" } },
    { id: "computerScience", en: { name: "Computer Science" }, fr: { name: "Informatique" } },
    { id: "criminology", en: { name: "Criminology" }, fr: { name: "Criminologie" } },
    { id: "economics", en: { name: "Economics" }, fr: { name: "Économie" } },
    { id: "education", en: { name: "Education" }, fr: { name: "Éducation" } },
    { id: "electricalEngineering", en: { name: "Electrical Engineering" }, fr: { name: "Génie électrique" } },
    { id: "english", en: { name: "English" }, fr: { name: "Anglais" } },
    { id: "environmentalScience", en: { name: "Environmental Science" }, fr: { name: "Sciences de l'environnement" } },
    { id: "finance", en: { name: "Finance" }, fr: { name: "Finance" } },
    { id: "geography", en: { name: "Geography" }, fr: { name: "Géographie" } },
    { id: "graphicDesign", en: { name: "Graphic Design" }, fr: { name: "Design graphique" } },
    { id: "history", en: { name: "History" }, fr: { name: "Histoire" } },
    { id: "industrialEngineering", en: { name: "Industrial Engineering" }, fr: { name: "Génie industriel" } },
    { id: "informationSystems", en: { name: "Information Systems" }, fr: { name: "Systèmes d'information" } },
    { id: "journalism", en: { name: "Journalism" }, fr: { name: "Journalisme" } },
    { id: "law", en: { name: "Law" }, fr: { name: "Droit" } },
    { id: "literature", en: { name: "Literature" }, fr: { name: "Littérature" } },
    { id: "marketing", en: { name: "Marketing" }, fr: { name: "Marketing" } },
    { id: "mathematics", en: { name: "Mathematics" }, fr: { name: "Mathématiques" } },
    { id: "mechanicalEngineering", en: { name: "Mechanical Engineering" }, fr: { name: "Génie mécanique" } },
    { id: "medicine", en: { name: "Medicine" }, fr: { name: "Médecine" } },
    { id: "music", en: { name: "Music" }, fr: { name: "Musique" } },
    { id: "nursing", en: { name: "Nursing" }, fr: { name: "Soins infirmiers" } },
    { id: "philosophy", en: { name: "Philosophy" }, fr: { name: "Philosophie" } },
    { id: "physics", en: { name: "Physics" }, fr: { name: "Physique" } },
    { id: "politicalScience", en: { name: "Political Science" }, fr: { name: "Science politique" } },
    { id: "psychology", en: { name: "Psychology" }, fr: { name: "Psychologie" } },
    { id: "sociology", en: { name: "Sociology" }, fr: { name: "Sociologie" } },
    { id: "softwareEngineering", en: { name: "Software Engineering" }, fr: { name: "Génie logiciel" } },
    { id: "statistics", en: { name: "Statistics" }, fr: { name: "Statistiques" } },
    { id: "theatre", en: { name: "Theatre" }, fr: { name: "Théâtre" } },
    { id: "other", en: { name: "Other" }, fr: { name: "Autre" } }
];
