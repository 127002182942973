import React from 'react';
import EdInput from './../../components/edInput';
import EdButton from './../../components/edButton';
import validator from 'validator';
import Lottie from "lottie-react";
import animationData from "./../../assets/lottie-checked.json";
import EdHelmet from '../../components/edHelmet';

const PageRegister = () => {
    const [isSubmitted, setIsSubmitted] = React.useState(false);
    const [errors, setErrors] = React.useState({});
    const [values, setValues] = React.useState({});

    const handleChange = (e) => {
        const { name, value, label } = e.target;
        if (!e || !e.target || values[name] === value) return;

        // Update current values
        values[name] = value;
        if (label) values[name + "Label"] = label;
        setValues({ ...values });

        // Update errors
        checkValidation();
    }

    const checkValidation = () => {
        let validationErrors = {};

        // isEmail
        if (!values.email)
            validationErrors.email = "Email is required";
        else if (!validator.isEmail(values.email))
            validationErrors.email = "Email is not valid";

        // isLength
        if (!values.password)
            validationErrors.password = "Password is required";
        else if (!validator.isLength(values.password, { min: 4 }))
            validationErrors.password = "Password must be at least 4 characters";

        // equals
        if (!values.password && !values.confirmPassword) {
            validationErrors.password = "Password is required";
            validationErrors.confirmPassword = "Confirm Password is required";
        }
        else if (!validator.equals(values.password, values.confirmPassword)) {
            validationErrors.password = "Password and Confirm Password must be same";
            validationErrors.confirmPassword = "Password and Confirm Password must be same";
        }

        // isAlpha
        if (!values.firstName)
            validationErrors.firstName = "First Name is required";
        else if (!validator.isAlpha(values.firstName))
            validationErrors.firstName = "First Name must be alphabetic";

        if (!values.lastName)
            validationErrors.lastName = "Last Name is required";
        else if (!validator.isAlpha(values.lastName))
            validationErrors.lastName = "Last Name must be alphabetic";

        // check country is selected
        if (!values.country || values.country === "") {
            validationErrors.country = "Country is required";
        }

        // check university is selected
        if (!values.university || values.university === "") {
            validationErrors.university = "University is required";
        }

        // check role is selected
        if (!values.role || values.role === "") {
            validationErrors.role = "Role is required";
        }

        // check student related fields
        if (values.role === "student") {
            if (values.enrollmentStatus === undefined || values.enrollmentStatus === "") {
                validationErrors.enrollmentStatus = "Enrollment Status is required";
            }
            if (values.expectedGraduationYear === undefined || values.expectedGraduationYear === "") {
                validationErrors.expectedGraduationYear = "Expected Graduation Year is required";
            }
            if (values.degreeProgram === undefined || values.degreeProgram === "") {
                validationErrors.degreeProgram = "Degree Program is required";
            }
        }

        // check educator related fields
        if (values.role === "educator") {
            if (values.academicPosition === undefined || values.academicPosition === "") {
                validationErrors.academicPosition = "Academic Position is required";
            }
            if (values.academicDepartment === undefined || values.academicDepartment === "") {
                validationErrors.academicDepartment = "Academic Department is required";
            }
        }

        // check validationErrors
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return false;
        }
        else {
            setErrors({});
            return true;
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (checkValidation() === false) return;

        // Save to database
        console.log(values);

        // Submit form
        window.scrollTo(0, 0)
        setIsSubmitted(true);
    }

    return <main className="grow py-12">
        <EdHelmet title="Register" description="Register an account to access educational materials, gain exclusive discounts, and participate in webinars. Available for both educators and students." />
        {isSubmitted === false &&
            <section className="max-w-sm md:max-w-lg px-3 mx-auto text-center">
                <h1 className="font-bold text-3xl my-3">Register your Account</h1>
                <div>Register an account to access educational materials, gain exclusive discounts, and participate in webinars. Available for both educators and students.</div>
                <form className="text-left mt-8 space-y-6" onSubmit={handleSubmit}>
                    <EdInput
                        type="text"
                        name="firstName"
                        label="First Name"
                        defaultValue={values.firstName}
                        autoComplete="firstName"
                        onChange={handleChange}
                        isRequired={true}
                        errors={errors}
                        lang="en"
                    />
                    <EdInput
                        type="text"
                        name="lastName"
                        label="Last Name"
                        defaultValue={values.lastName}
                        autoComplete={true}
                        onChange={handleChange}
                        isRequired={true}
                        errors={errors}
                        lang="en"
                    />
                    <EdInput
                        type="email"
                        name="email"
                        label="Email address"
                        defaultValue={values.email}
                        autoComplete={true}
                        isRequired={true}
                        onChange={handleChange}
                        errors={errors}
                        lang="en"
                    />
                    <EdInput
                        type="password"
                        name="password"
                        label="Password"
                        autoComplete={false}
                        isRequired={true}
                        onChange={handleChange}
                        errors={errors}
                        lang="en"
                    />
                    <EdInput
                        type="password"
                        name="confirmPassword"
                        label="Confirm Password"
                        autoComplete={false}
                        isRequired={true}
                        onChange={handleChange}
                        errors={errors}
                        lang="en"
                    />
                    <EdInput
                        type="edUniversity"
                        name="university"
                        label="Type University Name"
                        defaultValue={values.university}
                        autoComplete={false}
                        isRequired={true}
                        onChange={handleChange}
                        errors={errors}
                        lang="en"
                    />
                    <EdInput
                        type="edCountry"
                        name="country"
                        label="Select Country"
                        defaultValue={values.country}
                        autoComplete={false}
                        isRequired={true}
                        onChange={handleChange}
                        errors={errors}
                        lang="en"
                    />
                    <EdInput
                        type="edAcademicRole"
                        name="role"
                        label="Select Role"
                        defaultValue={values.role}
                        autoComplete={false}
                        isRequired={true}
                        onChange={handleChange}
                        errors={errors}
                        lang="en"
                    />

                    {values.role === "student" &&
                        <EdInput
                            type="edEnrollmentStatus"
                            name="enrollmentStatus"
                            label="Select Enrollment Status"
                            defaultValue={values.enrollmentStatus}
                            autoComplete={false}
                            isRequired={true}
                            onChange={handleChange}
                            errors={errors}
                            lang="en"
                        />
                    }

                    {values.role === "student" &&
                        <EdInput
                            type="edExpectedGraduationYear"
                            name="expectedGraduationYear"
                            label="Select Expected Graduation Year"
                            defaultValue={values.expectedGraduationYear}
                            autoComplete={false}
                            isRequired={true}
                            onChange={handleChange}
                            errors={errors}
                            lang="en"
                        />
                    }

                    {values.role === "student" &&
                        <EdInput
                            type="edAcademicDegree"
                            name="degreeProgram"
                            label="Select Degree Program"
                            defaultValue={values.degreeProgram}
                            autoComplete={false}
                            isRequired={true}
                            onChange={handleChange}
                            errors={errors}
                            lang="en"
                        />
                    }

                    {values.role === "educator" &&
                        <EdInput
                            type="edAcademicPosition"
                            name="academicPosition"
                            label="Select Academic Position"
                            defaultValue={values.academicPosition}
                            autoComplete={false}
                            isRequired={true}
                            onChange={handleChange}
                            errors={errors}
                            lang="en"
                        />
                    }

                    {values.role === "educator" &&
                        <EdInput
                            type="edAcademicDepartment"
                            name="academicDepartment"
                            label="Select Academic Department"
                            defaultValue={values.academicDepartment}
                            autoComplete={false}
                            isRequired={true}
                            onChange={handleChange}
                            errors={errors}
                            lang="en"
                        />
                    }

                    {
                        (values.role === "student" || values.role === "educator")
                            ? <EdButton color="sky" className="w-full mt-3">Register</EdButton>
                            : <EdButton color="white" className="w-full mt-3" disabled={true} readOnly={true}>Register</EdButton>
                    }
                </form>
            </section>
        }
        {isSubmitted === true &&
            <section className="max-w-sm md:max-w-lg px-3 mx-auto text-center -mt-10 mb-10">
                <Lottie animationData={animationData} loop={false} autoplay={true} />
                <h1 className="font-bold text-3xl -mt-10">Thank you!</h1>
                <div>
                    Your submission has been received.Please check your ✉️ (email)
                    to confirm your account. If you do not receive an email
                    within 24 hours, please contact us at <a
                        href="mailto:support@edplato.com?subject=Urgent%3A%20Account%20Confirmation%20Email%20Not%20Received&body=Hi%20Support%2C%0D%0A%0D%0AI%20didn't%20receive%20my%20account%20confirmation%20email.%20Please%20help.%0D%0A%0D%0AThanks%2C%0D%0A"
                        className="text-sky-700"
                        target="_blank"
                        rel="noreferrer"
                    >
                        support [at] edplato.com
                    </a>
                </div>
            </section>
        }

    </main>
}

export default PageRegister