export const dataCourseSources = {
    lms: [
        { id: "moodle", en: { name: "Moodle" }, fr: { name: "Moodle" } },
        { id: "blackboard", en: { name: "Blackboard" }, fr: { name: "Blackboard" } },
        { id: "canvas", en: { name: "Canvas Network" }, fr: { name: "Canvas Network" } },
        { id: "hbs", en: { name: "Harvard Business School" }, fr: { name: "Harvard Business School" } },
    ],
    cloud: [
        { id: "googledrive", en: { name: "Google Drive" }, fr: { name: "Google Drive" } },
        { id: "onedrive", en: { name: "OneDrive" }, fr: { name: "OneDrive" } },
        { id: "icloud", en: { name: "iCloud" }, fr: { name: "iCloud" } },
        { id: "dropbox", en: { name: "Dropbox" }, fr: { name: "Dropbox" } },
    ]
}
