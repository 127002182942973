import React from 'react';
import BlockPosts from './../../components/blockPosts';
import { dataBlogPosts } from './../../data/dataBlogPosts';
import EdHelmet from '../../components/edHelmet';

const PageBlogPost = () =>
    <main>
        <EdHelmet title="Blog" description="EdPLATO, une plateforme alimentée par l'IA qui transforme l'apprentissage. Explorez le Centre de connaissances supervisé par des professeurs avec des cours, des quiz, des études de cas, l'évaluation par l'IA et d'autres outils d'évaluation." />
        <section>
            <h1>Blog</h1>
            <p className="mb-10 text-lg text-gray-600 dark:text-gray-400 lg:text-lg">
                EdPLATO, une plateforme alimentée par l'IA qui transforme l'apprentissage. Explorez le Centre de connaissances supervisé par des professeurs avec des cours, des quiz, des études de cas, l'évaluation par l'IA et d'autres outils d'évaluation.
            </p>
            <hr />
        </section>

        <section className='max-w-5xl mx-auto grid gap-6 grid-cols-1 md:grid-cols-2'>
            <BlockPosts posts={dataBlogPosts} lang="fr" />
        </section>
    </main>


export default PageBlogPost