import React from 'react';
import EdHelmet from '../../components/edHelmet';

const PageContact = () =>
    <main>
        <EdHelmet title="Contactez-nous" description="Veuillez nous contacter sur les réseaux sociaux ou par courriel si vous avez des questions sur EdPLATO." />
        <section>
            <h1>Nous contacter</h1>
            <p className="mb-8 text-lg lg:text-lg">
                N'hésitez pas à nous contacter sur les réseaux sociaux ou par
                <a className="mx-1" href="mailto:info@edplato.com?subject=Bonjour%20de%20la%20part%20d'EdPLATO" target="_blank" rel="noopener noreferrer">info@edplato.com</a>
                si vous avez des questions concernant EdPLATO.
                <br />
                Rejoignez-nous pour révolutionner l'éducation !
            </p>
            <hr />
        </section>

    </main>

export default PageContact;