import React from "react";
import { redirect } from "react-router-dom";
import LayoutDashboard from './layoutDashboard';
import PageDashboard from './pageDashboard';
import PageCourses from './pageCourses';
import PageCourse from './pageCourse';
import PageTests from './pageTests';
import PageInsights from './pageInsights';
import PageGenerateTest from './pageGenerateTest';
import PageGenerateTestDemo from './pageGenerateTestDemo';
import PageSettings from './pageSettings';
import PageProfile from './pageProfile';

const RouterDashboard = {
    path: "/dashboard",
    //errorElement: <PageError />,
    element: <LayoutDashboard />,
    children: [
        { path: "/dashboard", element: <PageDashboard /> },
        { path: "/dashboard/courses", element: <PageCourses /> },
        { path: "/dashboard/courses/:courseId", element: <PageCourse /> },
        { path: "/dashboard/tests", element: <PageTests /> },
        { path: "/dashboard/insights", element: <PageInsights /> },
        { path: "/dashboard/generate-test", element: <PageGenerateTest /> },
        { path: "/dashboard/generate-test-demo", element: <PageGenerateTestDemo /> },
        { path: "/dashboard/profile", element: <PageProfile /> },
        { path: "/dashboard/settings", element: <PageSettings /> },
        { path: "*", action: () => redirect("/error") },
    ],
}

export default RouterDashboard
