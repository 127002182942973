import React from 'react';
import EdHelmet from '../../components/edHelmet';

const PageTerms = () =>
    <main>
        <EdHelmet title="Conditions d'utilisation" description="Comprenez les termes et conditions qui régissent votre utilisation de la plateforme d'EdPLATO, favorisant une communauté d'apprentissage positive et productive." />
        <section>
            <h1>Conditions d'utilisation</h1>
            <p className="mb-8 text-lg lg:text-lg">
                Comprenez les termes et conditions qui régissent votre utilisation de la plateforme d'<a className="" href="/">EdPLATO</a>, favorisant une communauté d'apprentissage positive et productive.
            </p>
            <hr />
        </section>

        <section>
        </section>

    </main>

export default PageTerms