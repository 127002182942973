import React from "react"
import { getSlugLang } from "../helper/languageHelper"

const BlockPost = ({ post, lang }) =>
    <article className="max-w-5xl px-4 mx-auto lg:px-4 pt-6">
        <img
            src={post.image}
            alt={post[lang].title}
            className="
                w-full mb-8 rounded-lg 
                object-cover object-center
                max-h-36
                md:max-h-72
                lg:max-h-96
                dark:opacity-80
                " />
        <h1>
            <a href={`${getSlugLang(lang)}/blog/${post.slug}`} target="_self">
                {post[lang].title}
            </a>
        </h1>
        <div className="flex flex-wrap mb-4">
            {post[lang].tags.map((tag) => {
                const tagSlug = tag.trim().toLowerCase().replace(/ /g, "-")
                return <a
                    key={tagSlug}
                    href={`${getSlugLang(lang)}/blog/tag/${tagSlug}`}
                    className="text-sm font-medium mr-2 mb-2 px-2.5 py-0.5 rounded 
                            bg-sky-100 text-sky-800 
                            dark:bg-sky-200 dark:text-sky-800 
                            hover:bg-sky-200 dark:hover:bg-sky-300"
                >
                    {console.log(tagSlug)}
                    #{tagSlug}
                </a>
            })}
        </div>
        <div className="article-body">
            <div dangerouslySetInnerHTML={{ __html: post[lang].html }} />
        </div>
        <hr />
    </article>

export default BlockPost