import React from 'react';
import EdHelmet from '../../components/edHelmet';

const PagePrivacy = () =>
    <main>
        <EdHelmet title="Politique de confidentialité" description="La protection de vos données est importante. Découvrez comment EdPLATO sécurise vos informations et respecte votre vie privée tout au long de votre parcours éducatif." />
        <section>
            <h1>Politique de confidentialité</h1>
            <p className="mb-8 text-lg lg:text-lg">
                La protection de vos données est importante. Découvrez comment <a className="mx-1" href="/">EdPLATO</a> sécurise vos informations et respecte votre vie privée tout au long de votre parcours éducatif.
            </p>
            <hr />
        </section>

        <section>
        </section>

    </main>

export default PagePrivacy