import React from "react";


const BlockCustomers = ({ lang }) =>
    <section className="max-w-none w-full mx-auto py-20 px-10 my-10 text-center bg-gray-100 dark:bg-gray-800">
        <div className="text-xl text-gray-700 dark:text-gray-200 mb-10">
            {lang === "fr" ? "Démarrant notre voyage avec" : "Kickstarting our journey with"}
        </div>
        <div className="flex flex-wrap justify-center gap-10 grayscale">
            <img src="/images/logo-essca.svg" alt="ESSCA" className="h-10" />
            <img src="/images/logo-d3.png" alt="D3 Innovation Hub" className="h-10" />
        </div>
    </section>


export default BlockCustomers;