import React from 'react';
import { Breadcrumb } from 'flowbite-react';
import { Form } from 'react-router-dom';


const Favorite = ({ contact }) => {
    let favorite = contact.favorite;

    return (
        <Form method="post">
            <button
                name="favorite"
                value={favorite ? "false" : "true"}
                aria-label={
                    favorite
                        ? "Remove from favorites"
                        : "Add to favorites"
                }
            >
                {favorite ? "★" : "☆"}
            </button>
        </Form>
    );
}

const PageProfile = () => {
    const contact = {
        first: "John",
        last: "Doe",
        avatar: "https://placekitten.com/g/200/200",
        twitter: "your_handle",
        notes: "Some notes",
        favorite: true,
    };

    return <main className="dashboard p-8 pb-24">

        <Breadcrumb aria-label="breadcrumb" className='-ml-3'>
            <Breadcrumb.Item href="/"><i className="fas fa-home mr-2"></i> Home</Breadcrumb.Item>
            <Breadcrumb.Item href="/dashboard">Dashboard</Breadcrumb.Item>
            <Breadcrumb.Item>Profile</Breadcrumb.Item>
        </Breadcrumb>

        <h1 className="text-3xl mb-0">Profile</h1>
        <p className="text-lg mb-6">View your profile.</p>

        <div className="relative bg-white shadow-md dark:bg-gray-800 sm:rounded-lg border border-gray-100 dark:border-gray-700">




            <div className="flex p-4">
                <div className="flex-auto">
                    <img
                        key={contact.avatar}
                        src={contact.avatar || null}
                        alt={contact.first + " " + contact.last}
                    />
                </div>
                <div className="flex-auto ">
                    <h1>
                        {contact.first || contact.last
                            ? (<>{contact.first} {contact.last}</>)
                            : (<i>No Name</i>)
                        }
                        {" "}
                        <Favorite contact={contact} />
                    </h1>

                    {contact.twitter && (
                        <p>
                            <a target="_blank" rel="noreferrer" href={`https://twitter.com/${contact.twitter}`}>
                                {contact.twitter}
                            </a>
                        </p>
                    )}

                    {contact.notes && <p>{contact.notes}</p>}

                    <div className="flex justify-between my-4">
                        <Form action="edit">
                            <button
                                className="bg-sky-600 hover:bg-sky-800 px-5 py-2.5 text-sm leading-5 rounded-md font-semibold text-white"
                                type="submit">
                                Edit
                            </button>
                        </Form>
                        <Form
                            method="post"
                            action="destroy"
                            onSubmit={(event) => {
                                if (!window.confirm("Please confirm you want to delete this record.")) {
                                    event.preventDefault();
                                }
                            }}
                        >
                            <button
                                className="bg-red-500 hover:bg-red-700 px-5 py-2.5 text-sm leading-5 rounded-md font-semibold text-white"
                                type="submit">
                                Delete
                            </button>
                        </Form>
                    </div>
                </div>
            </div>




        </div>

    </main>
}
export default PageProfile
