import React from 'react';
import EdHelmet from '../../components/edHelmet';

const PageLicensing = () =>
    <main>
        <EdHelmet title="Licences" description="Explorez nos options de licences, débloquez la puissance des fonctionnalités avancées et des ressources d'EdPLATO adaptées à vos besoins éducatifs." />
        <section>
            <h1>Licences</h1>
            <p className="mb-8 text-lg lg:text-lg">
                Explorez nos options de licences, débloquez la puissance des fonctionnalités avancées et des ressources d'<a className="" href="/">EdPLATO</a> adaptées à vos besoins éducatifs.
            </p>
            <hr />
        </section>

        <section>
        </section>

    </main>

export default PageLicensing