const EdNumber = ({ name, value, onChange, onIncrement, onDecrement, ...props }) =>
    <div className={"edNumber flex flex-row h-8 rounded-lg relative " + props.className}>
        <button
            className=" bg-gray-300 text-gray-600 hover:text-gray-700 hover:bg-gray-400 h-full w-20 rounded-l cursor-pointer outline-none"
            data-action="decrement"
            onClick={onDecrement}>
            <span className="m-auto text-2xl font-thin">−</span>
        </button>
        <input
            type="number"
            className="
                block w-full border disabled:cursor-not-allowed disabled:opacity-50 bg-gray-50 border-gray-300 text-gray-900 focus:border-sky-500 focus:ring-sky-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-sky-500 dark:focus:ring-sky-500 p-2.5 text-sm rounded-lg
                border-l-0 border-r-0 border-t border-b rounded-none text-center md:text-basecursor-default items-center outline-none
            "
            id={name}
            name={name}
            onChange={onChange}
            value={value}
        />
        <button
            className="bg-gray-300 text-gray-600 hover:text-gray-700 hover:bg-gray-400 h-full w-20 rounded-r cursor-pointer"
            data-action="increment"
            onClick={onIncrement}>
            <span className="m-auto text-2xl font-thin">+</span>
        </button>
    </div>

export default EdNumber