import { exceptionHelper } from './../helper/exceptionHelper';

const getCourses = async () => {
    return Promise.resolve([
        { id: "1", name: "course 1" },
        { id: "2", name: "course 2" },
        { id: "3", name: "course 3" },
    ]);
}

const save = async (dataset) => {
    try {
        //return await addDoc(collection(firebaseHelper.firestore, "courses"), dataset);
        console.log("save apiCourses -> dataset", dataset)
    } catch (ex) {
        exceptionHelper.handleException(ex);
    }
}


export const apiCourses = {
    getCourses,
    save,
}