import React from 'react';
import BlockHero from './../../components/blockHero';
//import lottieKidsAroundMonitor from "./../../assets/lottie-kids-around-monitor.json";
//import lottieLaptopAndBooks from "./../../assets/lottie-laptop-and-books.json";
import lottieThreeStudents from "./../../assets/lottie-three-students.json";
import lottieFlyingSudent from "./../../assets/lottie-flying-male-student.json";
import lottieFlyingProf from "./../../assets/lottie-flying-female-prof.json";
import BlockFeatured from './../../components/blockFeatured';
import BlockFAQ from './../../components/blockFAQ';
import EdHelmet from '../../components/edHelmet';
import BlockCustomers from './../../components/blockCustomers';


const PageHome = () =>
    <React.Fragment>
        <EdHelmet />

        <BlockHero
            animationData={lottieThreeStudents}
            title="EdPLATO - AI-powered platform transforming learning"
            description="EdPLATO is an advanced educational platform that offers AI-generated professor-supervised courses, cases, pop quizzes, AI-grading and other evaluation tools."
            ctaLabel="Register Now"
            ctaLink="/register"
        />

        <BlockCustomers lang="en" />

        <BlockFeatured
            animationData={lottieFlyingProf}
            animationPosition="left"
            title="AI Adaptive Testing"
            description="Unleashing the Power of AI to Transform Assessments: Experience Customized, Dynamic, and Adaptive Testing for Enhanced Learning Outcomes."
            items={[
                {
                    icon: "fas fa-globe",
                    title: "Dynamic Difficulty Adjustment",
                    description: "Adjust the complexity based on the learner's proficiency level."
                },
                {
                    icon: "fas fa-chart-line",
                    title: "Real-Time Progress Tracking",
                    description: "Continuously monitor and analyze student performance in real-time."
                },
                {
                    icon: "fas fa-chart-bar",
                    title: "Detailed Performance Reports",
                    description: "Gain comprehensive insights with charts and visual reports to enhance learning outcomes."
                }
            ]}
        />

        <BlockFeatured
            animationData={lottieFlyingSudent}
            animationPosition="right"
            title="AI Personalized Learning"
            description="Empowering Learners Through AI: Crafting Unique, Personalized Learning Journeys for Individual Success and Growth."
            items={[
                {
                    icon: "fas fa-rocket",
                    title: "Custom Learning Paths",
                    description: "Unique journeys tailored to individual needs."
                },
                {
                    icon: "fas fa-book",
                    title: "Intelligent Content Recommendations",
                    description: "Relevant materials suggested by AI."
                },
                {
                    icon: "fas fa-lightbulb",
                    title: "Personalized Feedback System",
                    description: "Individualized suggestions to boost understanding."
                }
            ]}
        />
        <BlockFAQ
            title="Frequently Asked Questions"
            description="Revolutionize education with AI-generated, customized assessments designed to streamline teaching and maximize learning."
            items={[
                {
                    question: "What is EdPLATO?",
                    answer: "EdPLATO is a personalized learning and adaptive testing website designed to provide tailored educational experiences and assessments to enhance learning outcomes.",
                },
                {
                    question: "Who is the driving force behind the creation of EdPLATO?",
                    answer: "EdPLATO is backed by a  team of international professionals with over 20 years of experience in advanced graduate-level teaching and learning, complemented by a skilled tech team with an extensive background in technology, LLM and ML. Their combined expertise drives the development of EdPLATO's innovative ed-tech solutions, aiming to bridge the gap between traditional teaching methods and emerging technologies.",
                },
                {
                    question: "How does EdPLATO personalize learning?",
                    answer: "EdPLATO utilizes advanced algorithms and AI technology to adapt learning materials and assessments based on individual student needs, preferences, and proficiency levels.",
                },
                {
                    question: "What subjects or topics does EdPLATO cover?",
                    answer: "The platform is designed to cater to various educational levels and curricula.Currently, EdPLATO covers subjects related to social science and commerce, with plans to expand its offerings to include math, languages, arts, social studies, and more.",
                },
                {
                    question: "How does adaptive testing work on EdPLATO?",
                    answer: "Adaptive testing on EdPLATO dynamically adjusts the difficulty level of questions based on the learner's performance and proficiency. It ensures that each student receives questions that match their current skill level, providing an optimized learning experience.",
                },
                {
                    question: "Can EdPLATO track my progress?",
                    answer: "Yes, EdPLATO offers real-time progress tracking. It monitors and analyzes your performance as you engage with the platform, providing insights into your strengths, areas for improvement, and overall progress.",
                },
                {
                    question: "Are the learning materials on EdPLATO created by real educators?",
                    answer: "Yes! EdPLATO's learning materials are developed by a team of qualified educators who collaborate with AI technology to create engaging and effective content.",
                },
                {
                    question: "Is EdPLATO suitable for all age groups?",
                    answer: "While EdPLATO is currently focused on serving university-level learners, we have a vision to expand our scope of services to include younger learners in the future. Our goal is to provide educational resources and adaptive testing for learners of all age groups, catering to elementary school, high school, and university levels.",
                },
                {
                    question: "How can I get started with EdPLATO?",
                    answer: "To get started with EdPLATO, simply visit our website and create an account. From there, you can explore the available courses and adaptive assessments, customize your learning experience, and track your progress.",
                },
                {
                    question: "Is there any cost associated with using EdPLATO?",
                    answer: "EdPLATO offers both free and premium subscription options. While some features and content are available for free, certain advanced functionalities and additional resources may require a subscription fee.",
                },
            ]} />
    </React.Fragment>
export default PageHome;
