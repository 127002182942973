const EdToggle = ({ defaultValue, name, onChange }) =>
    <label className="relative inline-flex items-center cursor-pointer">
        {console.log("name: " + name, "defaultValue: " + defaultValue)}
        <input
            //className="sr-only peer"
            type="checkbox"
            name={name}
            checked={!!defaultValue}
            onChange={onChange}
        />
        <div className="
        hidden
            w-11 h-6
            bg-gray-200 dark:border-gray-600
            peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-sky-500 dark:peer-focus:ring-sky-800
            rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full
            peer-checked:after:border-white peer-checked:bg-sky-600
            after:content-['']
            after:absolute
            after:top-[2px]
            after:left-[2px]
            after:bg-white
            after:border-gray-300
            after:border
            after:rounded-full
            after:h-5
            after:w-5
            after:transition-all
        ">&nbsp;</div>
    </label>

export default EdToggle