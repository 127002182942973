import React from "react"
import { Outlet, useParams } from "react-router-dom";
import BlockHeader from './../../components/blockHeader';
import BlockFooter from './../../components/blockFooter';
import { saveLang } from "../../helper/languageHelper";

const LayoutMain = () => {
    // This is to force the page to reload when the language changes
    const params = useParams();
    React.useEffect(() => { }, [params])

    saveLang("fr")
    return <div className="flex flex-col min-h-screen bg-white dark:bg-gray-900">
        <BlockHeader
            menuList={[
                ["Accueil", "/fr"],
                ["Solutions", "/fr/solutions"],
                ["Tarifs", "/fr/pricing"],
                ["Blog", "/fr/blog"],
                ["À propos", "/fr/about"],
                ["Contact", "/fr/contact"],
                ["EN", window.location.href.replace(window.location.origin + "/fr", window.location.origin)],
            ]}
            buttonList={[
                ["Se connecter", "/fr/signin", "white"],
                ["S'inscrire", "/fr/register", "blue"],
            ]}
        />
        <Outlet />
        <BlockFooter
            menuList={[
                {
                    title: "Produit",
                    items: [
                        { title: "Solutions", url: "/fr/solutions" },
                        { title: "Tarification", url: "/fr/pricing" },
                        { title: "Fonctionnalités", url: "/fr/solutions" },
                        { title: "Intégrations", url: "/fr/solutions" },
                    ]
                },
                {
                    title: "Entreprise",
                    items: [
                        { title: "À propos", url: "/fr/about" },
                        { title: "Blog", url: "/fr/blog" },
                        { title: "Carrières", url: "/fr/contact" },
                        { title: "Contact", url: "/fr/contact" },
                    ]
                },
                {
                    title: "Nous rejoindre",
                    items: [
                        { title: "LinkedIn", icon: "fab fa-linkedin-in", url: "https://linkedin.com/company/edplato-com" },
                        { title: "Twitter", icon: "fab fa-twitter", url: "https://twitter.com/edplato_com" },
                        { title: "Instagram", icon: "fab fa-instagram", url: "https://www.instagram.com/edplato_com/" },
                        { title: "Youtube", icon: "fab fa-youtube", url: "https://www.youtube.com/@EdPLATO" },
                    ]
                }
            ]}
            ctaTitle="EdPLATO est une plateforme éducative avancée qui propose des cours générés par l'IA et supervisés par des professeurs, des études de cas, des mini-quiz, des évaluations basées sur l'IA et d'autres outils d'évaluation."
            ctaLabel="Inscrivez-vous maintenant !"
            ctaLink="/fr/register"
        />
    </div>
}

export default LayoutMain