import React from "react"

const BlockTeamMember = ({ member, lang }) =>
    <div className="shadow-sm border p-3 rounded-md border-gray-100 dark:border-gray-900 ">
        <div className="rounded-lg overflow-hidden relative mb-1">
            <img className="group hover:scale-125 transform scale-100 object-cover w-full h-[160px] lg:h-auto cursor-pointer transition-all duration-1000 ease-in-out"
                src={`/images/${member.name.toLowerCase().replace(" ", "-")}.jpg`} alt={member.name} />
        </div>

        <div className='p-1 flex flex-col'>
            <h3 className="text-lg mb-2 font-medium text-gray-900 dark:text-white">{member.name}</h3>
            <span className="text-sm mb-2">{member.title[lang]}</span>
            <p className="text-xs text-gray-500 dark:text-gray-400 flex-grow">
                {member.description[lang]?.slice(0, 210)}
                <span className="hidden" id={`description-${member.name}`}>{member.description[lang]?.slice(210)}</span>
                {member.description[lang]?.length > 210 &&
                    <button
                        className="ms-1 text-blue-900 dark:text-blue-400 font-semibold"
                        id={`button-${member.name}`}
                        onClick={() => {
                            document.getElementById(`description-${member.name}`).classList.toggle("hidden")
                            document.getElementById(`button-${member.name}`).classList.toggle("hidden")
                        }}>...{lang === "fr" ? "plus" : "more"}</button>}
            </p>
            <ul className="flex space-x-3 list-none p-0 m-0 mt-auto">
                {member.social.map((link, socialIndex) =>
                    <li key={socialIndex} className="text-gray-500 hover:text-gray-900">
                        <a href={link.url} className="text-gray-500 hover:text-gray-900" target="_blank" rel="noopener noreferrer">
                            <i className={`fab fa-${link.type} fa-lg`}></i>
                        </a>
                    </li>
                )}
            </ul>
        </div>
    </div>

export default BlockTeamMember