import React from 'react';
import { Breadcrumb } from 'flowbite-react';


const PageInsights = () =>
    <main className="dashboard p-8 pb-24">

        <Breadcrumb aria-label="breadcrumb" className='-ml-3'>
            <Breadcrumb.Item href="/"><i className="fas fa-home mr-2"></i> Home</Breadcrumb.Item>
            <Breadcrumb.Item href="/dashboard">Dashboard</Breadcrumb.Item>
            <Breadcrumb.Item>Insights</Breadcrumb.Item>
        </Breadcrumb>

        <h1 className="text-3xl mb-0">Insights</h1>
        <p className="text-lg mb-6">Explore visualizations and in-depth analytics for valuable insights.</p>

        <div className="relative bg-white shadow-md dark:bg-gray-800 sm:rounded-lg border border-gray-100 dark:border-gray-700">
        </div>

    </main>

export default PageInsights
