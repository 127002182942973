import React from 'react';
import { useParams } from 'react-router-dom';
import { dataBlogPosts } from './../../data/dataBlogPosts';
import EdHelmet from '../../components/edHelmet';
import BlockPost from '../../components/blockPost';


const PageBlogPost = () => {
    const { slug } = useParams();
    const lang = "fr"
    const selectedBlogPost = dataBlogPosts.find((post) => post.slug === slug);
    if (!selectedBlogPost) throw new Response("Non trouvé", { status: 404 });

    return <main>
        <EdHelmet title={selectedBlogPost[lang].title} description={selectedBlogPost[lang].excerpt} />
        <BlockPost post={selectedBlogPost} lang={lang} />
    </main>


}
export default PageBlogPost