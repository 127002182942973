import React from 'react';
import EdHelmet from './../../components/edHelmet';

const PageLicensing = () =>
    <main>
        <EdHelmet title="Licensing" description="Explore our Licensing Options, Unlock the power of EdPLATO's advanced features and resources tailored to your educational needs." />
        <section>
            <h1>Licensing</h1>
            <p className="mb-8 text-lg lg:text-lg">
                Explore our Licensing Options, Unlock the power of <a className="" href="/">EdPLATO</a>'s advanced features and resources tailored to your educational needs."
            </p>
            <hr />
        </section>

        <section>
        </section>

    </main>

export default PageLicensing