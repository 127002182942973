const isFrench = () =>
    //window.location.pathname.split('/')[1] === 'fr'
    window.localStorage.getItem('lang') === 'fr'

const saveLang = (lang) =>
    window.localStorage.setItem('lang', lang)

const getCurrentLang = () =>
    window.localStorage.getItem('lang') || 'en'

const getSlugLang = (lang) =>
    lang === 'fr' ? '/fr' : ''

export {
    saveLang,
    isFrench,
    getCurrentLang,
    getSlugLang,
}
