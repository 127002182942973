export const dataCountries = [
    { id: "us", en: { name: "United States" }, fr: { name: "États-Unis" } },
    { id: "ax", en: { name: "Aland Islands" }, fr: { name: "Îles Åland" } },
    { id: "al", en: { name: "Albania" }, fr: { name: "Albanie" } },
    { id: "dz", en: { name: "Algeria" }, fr: { name: "Algérie" } },
    { id: "as", en: { name: "American Samoa" }, fr: { name: "Samoa américaines" } },
    { id: "ad", en: { name: "Andorra" }, fr: { name: "Andorre" } },
    { id: "ao", en: { name: "Angola" }, fr: { name: "Angola" } },
    { id: "ai", en: { name: "Anguilla" }, fr: { name: "Anguilla" } },
    { id: "aq", en: { name: "Antarctica" }, fr: { name: "Antarctique" } },
    { id: "ag", en: { name: "Antigua and Barbuda" }, fr: { name: "Antigua-et-Barbuda" } },
    { id: "ar", en: { name: "Argentina" }, fr: { name: "Argentine" } },
    { id: "am", en: { name: "Armenia" }, fr: { name: "Arménie" } },
    { id: "aw", en: { name: "Aruba" }, fr: { name: "Aruba" } },
    { id: "au", en: { name: "Australia" }, fr: { name: "Australie" } },
    { id: "at", en: { name: "Austria" }, fr: { name: "Autriche" } },
    { id: "az", en: { name: "Azerbaijan" }, fr: { name: "Azerbaïdjan" } },
    { id: "bs", en: { name: "Bahamas" }, fr: { name: "Bahamas" } },
    { id: "bh", en: { name: "Bahrain" }, fr: { name: "Bahreïn" } },
    { id: "bd", en: { name: "Bangladesh" }, fr: { name: "Bangladesh" } },
    { id: "bb", en: { name: "Barbados" }, fr: { name: "Barbade" } },
    { id: "be", en: { name: "Belgium" }, fr: { name: "Belgique" } },
    { id: "bz", en: { name: "Belize" }, fr: { name: "Belize" } },
    { id: "bj", en: { name: "Benin" }, fr: { name: "Bénin" } },
    { id: "bm", en: { name: "Bermuda" }, fr: { name: "Bermudes" } },
    { id: "bt", en: { name: "Bhutan" }, fr: { name: "Bhoutan" } },
    { id: "bo", en: { name: "Bolivia, Plurinational State of" }, fr: { name: "Bolivie, État plurinational de" } },
    { id: "bq", en: { name: "Bonaire, Saint Eustatius and Saba" }, fr: { name: "Bonaire, Saint-Eustache et Saba" } },
    { id: "ba", en: { name: "Bosnia and Herzegovina" }, fr: { name: "Bosnie-Herzégovine" } },
    { id: "bw", en: { name: "Botswana" }, fr: { name: "Botswana" } },
    { id: "bv", en: { name: "Bouvet Island" }, fr: { name: "Île Bouvet" } },
    { id: "br", en: { name: "Brazil" }, fr: { name: "Brésil" } },
    { id: "io", en: { name: "British Indian Ocean Territory" }, fr: { name: "Territoire britannique de l'océan Indien" } },
    { id: "bn", en: { name: "Brunei Darussalam" }, fr: { name: "Brunéi Darussalam" } },
    { id: "bg", en: { name: "Bulgaria" }, fr: { name: "Bulgarie" } },
    { id: "bf", en: { name: "Burkina Faso" }, fr: { name: "Burkina Faso" } },
    { id: "bi", en: { name: "Burundi" }, fr: { name: "Burundi" } },
    { id: "kh", en: { name: "Cambodia" }, fr: { name: "Cambodge" } },
    { id: "cm", en: { name: "Cameroon" }, fr: { name: "Cameroun" } },
    { id: "ca", en: { name: "Canada" }, fr: { name: "Canada" } },
    { id: "cv", en: { name: "Cape Verde" }, fr: { name: "Cap-Vert" } },
    { id: "ky", en: { name: "Cayman Islands" }, fr: { name: "Îles Caïmans" } },
    { id: "cf", en: { name: "Central African Republic" }, fr: { name: "République centrafricaine" } },
    { id: "td", en: { name: "Chad" }, fr: { name: "Tchad" } },
    { id: "cl", en: { name: "Chile" }, fr: { name: "Chili" } },
    { id: "cn", en: { name: "China" }, fr: { name: "Chine" } },
    { id: "cx", en: { name: "Christmas Island" }, fr: { name: "Île Christmas" } },
    { id: "cc", en: { name: "Cocos (Keeling) Islands" }, fr: { name: "Îles Cocos (Keeling)" } },
    { id: "co", en: { name: "Colombia" }, fr: { name: "Colombie" } },
    { id: "km", en: { name: "Comoros" }, fr: { name: "Comores" } },
    { id: "cg", en: { name: "Congo" }, fr: { name: "Congo" } },
    { id: "ck", en: { name: "Cook Islands" }, fr: { name: "Îles Cook" } },
    { id: "cr", en: { name: "Costa Rica" }, fr: { name: "Costa Rica" } },
    { id: "hr", en: { name: "Croatia" }, fr: { name: "Croatie" } },
    { id: "cw", en: { name: "Curacao" }, fr: { name: "Curaçao" } },
    { id: "cy", en: { name: "Cyprus" }, fr: { name: "Chypre" } },
    { id: "cz", en: { name: "Czech Republic" }, fr: { name: "République tchèque" } },
    { id: "dk", en: { name: "Denmark" }, fr: { name: "Danemark" } },
    { id: "dj", en: { name: "Djibouti" }, fr: { name: "Djibouti" } },
    { id: "dm", en: { name: "Dominica" }, fr: { name: "Dominique" } },
    { id: "do", en: { name: "Dominican Republic" }, fr: { name: "République dominicaine" } },
    { id: "ec", en: { name: "Ecuador" }, fr: { name: "Équateur" } },
    { id: "eg", en: { name: "Egypt" }, fr: { name: "Égypte" } },
    { id: "sv", en: { name: "El Salvador" }, fr: { name: "Salvador" } },
    { id: "gq", en: { name: "Equatorial Guinea" }, fr: { name: "Guinée équatoriale" } },
    { id: "er", en: { name: "Eritrea" }, fr: { name: "Érythrée" } },
    { id: "ee", en: { name: "Estonia" }, fr: { name: "Estonie" } },
    { id: "et", en: { name: "Ethiopia" }, fr: { name: "Éthiopie" } },
    { id: "fk", en: { name: "Falkland Islands (Malvinas)" }, fr: { name: "Îles Falkland (Malvinas)" } },
    { id: "fo", en: { name: "Faroe Islands" }, fr: { name: "Îles Féroé" } },
    { id: "fj", en: { name: "Fiji" }, fr: { name: "Fidji" } },
    { id: "fi", en: { name: "Finland" }, fr: { name: "Finlande" } },
    { id: "fr", en: { name: "France" }, fr: { name: "France" } },
    { id: "gf", en: { name: "French Guiana" }, fr: { name: "Guyane française" } },
    { id: "pf", en: { name: "French Polynesia" }, fr: { name: "Polynésie française" } },
    { id: "tf", en: { name: "French Southern Territories" }, fr: { name: "Terres australes françaises" } },
    { id: "ga", en: { name: "Gabon" }, fr: { name: "Gabon" } },
    { id: "gm", en: { name: "Gambia" }, fr: { name: "Gambie" } },
    { id: "ge", en: { name: "Georgia" }, fr: { name: "Géorgie" } },
    { id: "de", en: { name: "Germany" }, fr: { name: "Allemagne" } },
    { id: "gi", en: { name: "Gibraltar" }, fr: { name: "Gibraltar" } },
    { id: "gr", en: { name: "Greece" }, fr: { name: "Grèce" } },
    { id: "gl", en: { name: "Greenland" }, fr: { name: "Groenland" } },
    { id: "gd", en: { name: "Grenada" }, fr: { name: "Grenade" } },
    { id: "gp", en: { name: "Guadeloupe" }, fr: { name: "Guadeloupe" } },
    { id: "gu", en: { name: "Guam" }, fr: { name: "Guam" } },
    { id: "gt", en: { name: "Guatemala" }, fr: { name: "Guatemala" } },
    { id: "gg", en: { name: "Guernsey" }, fr: { name: "Guernesey" } },
    { id: "gn", en: { name: "Guinea" }, fr: { name: "Guinée" } },
    { id: "gw", en: { name: "Guinea-Bissau" }, fr: { name: "Guinée-Bissau" } },
    { id: "gy", en: { name: "Guyana" }, fr: { name: "Guyana" } },
    { id: "ht", en: { name: "Haiti" }, fr: { name: "Haïti" } },
    { id: "hm", en: { name: "Heard Island and McDonald Islands" }, fr: { name: "Îles Heard et McDonald" } },
    { id: "va", en: { name: "Holy See (Vatican City State)" }, fr: { name: "Saint-Siège (État de la Cité du Vatican)" } },
    { id: "hn", en: { name: "Honduras" }, fr: { name: "Honduras" } },
    { id: "hk", en: { name: "Hong Kong" }, fr: { name: "Hong Kong" } },
    { id: "hu", en: { name: "Hungary" }, fr: { name: "Hongrie" } },
    { id: "is", en: { name: "Iceland" }, fr: { name: "Islande" } },
    { id: "in", en: { name: "India" }, fr: { name: "Inde" } },
    { id: "id", en: { name: "Indonesia" }, fr: { name: "Indonésie" } },
    { id: "ir", en: { name: "Iran" }, fr: { name: "Iran" } },
    { id: "ie", en: { name: "Ireland" }, fr: { name: "Irlande" } },
    { id: "im", en: { name: "Isle of Man" }, fr: { name: "Île de Man" } },
    { id: "il", en: { name: "Israel" }, fr: { name: "Israël" } },
    { id: "it", en: { name: "Italy" }, fr: { name: "Italie" } },
    { id: "jm", en: { name: "Jamaica" }, fr: { name: "Jamaïque" } },
    { id: "jp", en: { name: "Japan" }, fr: { name: "Japon" } },
    { id: "je", en: { name: "Jersey" }, fr: { name: "Jersey" } },
    { id: "jo", en: { name: "Jordan" }, fr: { name: "Jordanie" } },
    { id: "kz", en: { name: "Kazakhstan" }, fr: { name: "Kazakhstan" } },
    { id: "ke", en: { name: "Kenya" }, fr: { name: "Kenya" } },
    { id: "ki", en: { name: "Kiribati" }, fr: { name: "Kiribati" } },
    { id: "kr", en: { name: "Korea, Republic of" }, fr: { name: "Corée, République de" } },
    { id: "kw", en: { name: "Kuwait" }, fr: { name: "Koweït" } },
    { id: "kg", en: { name: "Kyrgyzstan" }, fr: { name: "Kirghizistan" } },
    { id: "la", en: { name: "Lao People's Democratic Republic" }, fr: { name: "République démocratique populaire lao" } },
    { id: "lv", en: { name: "Latvia" }, fr: { name: "Lettonie" } },
    { id: "lb", en: { name: "Lebanon" }, fr: { name: "Liban" } },
    { id: "ls", en: { name: "Lesotho" }, fr: { name: "Lesotho" } },
    { id: "ly", en: { name: "Libyan Arab Jamahiriya" }, fr: { name: "Jamahiriya arabe libyenne" } },
    { id: "li", en: { name: "Liechtenstein" }, fr: { name: "Liechtenstein" } },
    { id: "lt", en: { name: "Lithuania" }, fr: { name: "Lituanie" } },
    { id: "lu", en: { name: "Luxembourg" }, fr: { name: "Luxembourg" } },
    { id: "mo", en: { name: "Macao" }, fr: { name: "Macao" } },
    { id: "mk", en: { name: "Macedonia, The Former Yugoslav Republic of" }, fr: { name: "Macédoine, l'ex-République yougoslave de" } },
    { id: "mg", en: { name: "Madagascar" }, fr: { name: "Madagascar" } },
    { id: "mw", en: { name: "Malawi" }, fr: { name: "Malawi" } },
    { id: "my", en: { name: "Malaysia" }, fr: { name: "Malaisie" } },
    { id: "mv", en: { name: "Maldives" }, fr: { name: "Maldives" } },
    { id: "ml", en: { name: "Mali" }, fr: { name: "Mali" } },
    { id: "mt", en: { name: "Malta" }, fr: { name: "Malte" } },
    { id: "mh", en: { name: "Marshall Islands" }, fr: { name: "Îles Marshall" } },
    { id: "mq", en: { name: "Martinique" }, fr: { name: "Martinique" } },
    { id: "mr", en: { name: "Mauritania" }, fr: { name: "Mauritanie" } },
    { id: "mu", en: { name: "Mauritius" }, fr: { name: "Maurice" } },
    { id: "yt", en: { name: "Mayotte" }, fr: { name: "Mayotte" } },
    { id: "mx", en: { name: "Mexico" }, fr: { name: "Mexique" } },
    { id: "fm", en: { name: "Micronesia, Federated States of" }, fr: { name: "Micronésie, États fédérés de" } },
    { id: "md", en: { name: "Moldova, Republic of" }, fr: { name: "Moldova, République de" } },
    { id: "mc", en: { name: "Monaco" }, fr: { name: "Monaco" } },
    { id: "mn", en: { name: "Mongolia" }, fr: { name: "Mongolie" } },
    { id: "me", en: { name: "Montenegro" }, fr: { name: "Monténégro" } },
    { id: "ms", en: { name: "Montserrat" }, fr: { name: "Montserrat" } },
    { id: "ma", en: { name: "Morocco" }, fr: { name: "Maroc" } },
    { id: "mz", en: { name: "Mozambique" }, fr: { name: "Mozambique" } },
    { id: "mm", en: { name: "Myanmar" }, fr: { name: "Myanmar" } },
    { id: "na", en: { name: "Namibia" }, fr: { name: "Namibie" } },
    { id: "nr", en: { name: "Nauru" }, fr: { name: "Nauru" } },
    { id: "np", en: { name: "Nepal" }, fr: { name: "Népal" } },
    { id: "nl", en: { name: "Netherlands" }, fr: { name: "Pays-Bas" } },
    { id: "nc", en: { name: "New Caledonia" }, fr: { name: "Nouvelle-Calédonie" } },
    { id: "nz", en: { name: "New Zealand" }, fr: { name: "Nouvelle-Zélande" } },
    { id: "ni", en: { name: "Nicaragua" }, fr: { name: "Nicaragua" } },
    { id: "ne", en: { name: "Niger" }, fr: { name: "Niger" } },
    { id: "ng", en: { name: "Nigeria" }, fr: { name: "Nigéria" } },
    { id: "nu", en: { name: "Niue" }, fr: { name: "Niué" } },
    { id: "nf", en: { name: "Norfolk Island" }, fr: { name: "Île Norfolk" } },
    { id: "mp", en: { name: "Northern Mariana Islands" }, fr: { name: "Îles Mariannes du Nord" } },
    { id: "no", en: { name: "Norway" }, fr: { name: "Norvège" } },
    { id: "om", en: { name: "Oman" }, fr: { name: "Oman" } },
    { id: "pk", en: { name: "Pakistan" }, fr: { name: "Pakistan" } },
    { id: "pw", en: { name: "Palau" }, fr: { name: "Palaos" } },
    { id: "ps", en: { name: "Palestinian Territory, Occupied" }, fr: { name: "Territoire palestinien occupé" } },
    { id: "pa", en: { name: "Panama" }, fr: { name: "Panama" } },
    { id: "pg", en: { name: "Papua New Guinea" }, fr: { name: "Papouasie-Nouvelle-Guinée" } },
    { id: "py", en: { name: "Paraguay" }, fr: { name: "Paraguay" } },
    { id: "pe", en: { name: "Peru" }, fr: { name: "Pérou" } },
    { id: "ph", en: { name: "Philippines" }, fr: { name: "Philippines" } },
    { id: "pn", en: { name: "Pitcairn" }, fr: { name: "Îles Pitcairn" } },
    { id: "pl", en: { name: "Poland" }, fr: { name: "Pologne" } },
    { id: "pt", en: { name: "Portugal" }, fr: { name: "Portugal" } },
    { id: "pr", en: { name: "Puerto Rico" }, fr: { name: "Porto Rico" } },
    { id: "qa", en: { name: "Qatar" }, fr: { name: "Qatar" } },
    { id: "re", en: { name: "Reunion" }, fr: { name: "Réunion" } },
    { id: "ro", en: { name: "Romania" }, fr: { name: "Roumanie" } },
    { id: "ru", en: { name: "Russian Federation" }, fr: { name: "Fédération de Russie" } },
    { id: "rw", en: { name: "Rwanda" }, fr: { name: "Rwanda" } },
    { id: "bl", en: { name: "Saint Barthelemy" }, fr: { name: "Saint-Barthélemy" } },
    { id: "sh", en: { name: "Saint Helena, Ascension and Tristan da Cunha" }, fr: { name: "Sainte-Hélène, Ascension et Tristan da Cunha" } },
    { id: "kn", en: { name: "Saint Kitts and Nevis" }, fr: { name: "Saint-Kitts-et-Nevis" } },
    { id: "lc", en: { name: "Saint Lucia" }, fr: { name: "Sainte-Lucie" } },
    { id: "mf", en: { name: "Saint Martin (French part)" }, fr: { name: "Saint-Martin (partie française)" } },
    { id: "pm", en: { name: "Saint Pierre and Miquelon" }, fr: { name: "Saint-Pierre-et-Miquelon" } },
    { id: "vc", en: { name: "Saint Vincent and the Grenadines" }, fr: { name: "Saint-Vincent-et-les Grenadines" } },
    { id: "ws", en: { name: "Samoa" }, fr: { name: "Samoa" } },
    { id: "sm", en: { name: "San Marino" }, fr: { name: "Saint-Marin" } },
    { id: "st", en: { name: "Sao Tome and Principe" }, fr: { name: "Sao Tomé-et-Principe" } },
    { id: "sa", en: { name: "Saudi Arabia" }, fr: { name: "Arabie saoudite" } },
    { id: "sn", en: { name: "Senegal" }, fr: { name: "Sénégal" } },
    { id: "rs", en: { name: "Serbia" }, fr: { name: "Serbie" } },
    { id: "sc", en: { name: "Seychelles" }, fr: { name: "Seychelles" } },
    { id: "sl", en: { name: "Sierra Leone" }, fr: { name: "Sierra Leone" } },
    { id: "sg", en: { name: "Singapore" }, fr: { name: "Singapour" } },
    { id: "sx", en: { name: "Sint Maarten (Dutch part)" }, fr: { name: "Saint-Martin (partie néerlandaise)" } },
    { id: "sk", en: { name: "Slovakia" }, fr: { name: "Slovaquie" } },
    { id: "si", en: { name: "Slovenia" }, fr: { name: "Slovénie" } },
    { id: "sb", en: { name: "Solomon Islands" }, fr: { name: "Îles Salomon" } },
    { id: "so", en: { name: "Somalia" }, fr: { name: "Somalie" } },
    { id: "za", en: { name: "South Africa" }, fr: { name: "Afrique du Sud" } },
    { id: "gs", en: { name: "South Georgia and the South Sandwich Islands" }, fr: { name: "Géorgie du Sud et les îles Sandwich du Sud" } },
    { id: "es", en: { name: "Spain" }, fr: { name: "Espagne" } },
    { id: "lk", en: { name: "Sri Lanka" }, fr: { name: "Sri Lanka" } },
    { id: "sr", en: { name: "Suriname" }, fr: { name: "Surinam" } },
    { id: "sj", en: { name: "Svalbard and Jan Mayen" }, fr: { name: "Svalbard et île Jan Mayen" } },
    { id: "sz", en: { name: "Swaziland" }, fr: { name: "Swaziland" } },
    { id: "se", en: { name: "Sweden" }, fr: { name: "Suède" } },
    { id: "ch", en: { name: "Switzerland" }, fr: { name: "Suisse" } },
    { id: "tw", en: { name: "Taiwan" }, fr: { name: "Taïwan" } },
    { id: "tj", en: { name: "Tajikistan" }, fr: { name: "Tadjikistan" } },
    { id: "tz", en: { name: "Tanzania, United Republic of" }, fr: { name: "Tanzanie, République-Unie de" } },
    { id: "th", en: { name: "Thailand" }, fr: { name: "Thaïlande" } },
    { id: "tl", en: { name: "Timor-Leste" }, fr: { name: "Timor-Leste" } },
    { id: "tg", en: { name: "Togo" }, fr: { name: "Togo" } },
    { id: "tk", en: { name: "Tokelau" }, fr: { name: "Tokelau" } },
    { id: "to", en: { name: "Tonga" }, fr: { name: "Tonga" } },
    { id: "tt", en: { name: "Trinidad and tobago" }, fr: { name: "Trinité-et-Tobago" } },
    { id: "tn", en: { name: "Tunisia" }, fr: { name: "Tunisie" } },
    { id: "tr", en: { name: "Turkey" }, fr: { name: "Turquie" } },
    { id: "tm", en: { name: "Turkmenistan" }, fr: { name: "Turkménistan" } },
    { id: "tc", en: { name: "Turks and Caicos Islands" }, fr: { name: "Îles Turks et Caïques" } },
    { id: "tv", en: { name: "Tuvalu" }, fr: { name: "Tuvalu" } },
    { id: "ug", en: { name: "Uganda" }, fr: { name: "Ouganda" } },
    { id: "ua", en: { name: "Ukraine" }, fr: { name: "Ukraine" } },
    { id: "ae", en: { name: "United Arab Emirates" }, fr: { name: "Émirats arabes unis" } },
    { id: "gb", en: { name: "United Kingdom" }, fr: { name: "Royaume-Uni" } },
    { id: "um", en: { name: "United States Minor Outlying Islands" }, fr: { name: "Îles mineures éloignées des États-Unis" } },
    { id: "uy", en: { name: "Uruguay" }, fr: { name: "Uruguay" } },
    { id: "uz", en: { name: "Uzbekistan" }, fr: { name: "Ouzbékistan" } },
    { id: "vu", en: { name: "Vanuatu" }, fr: { name: "Vanuatu" } },
    { id: "ve", en: { name: "Venezuela, Bolivarian Republic of" }, fr: { name: "Venezuela, République bolivarienne du" } },
    { id: "vn", en: { name: "Viet Nam" }, fr: { name: "Viêt Nam" } },
    { id: "vg", en: { name: "Virgin Islands, British" }, fr: { name: "Îles Vierges britanniques" } },
    { id: "vi", en: { name: "Virgin Islands, U.S." }, fr: { name: "Îles Vierges américaines" } },
    { id: "wf", en: { name: "Wallis and Futuna" }, fr: { name: "Wallis et Futuna" } },
    { id: "eh", en: { name: "Western Sahara" }, fr: { name: "Sahara occidental" } },
    { id: "ye", en: { name: "Yemen" }, fr: { name: "Yémen" } },
    { id: "zm", en: { name: "Zambia" }, fr: { name: "Zambie" } },
];
