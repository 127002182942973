import React from 'react';
import { useParams } from 'react-router-dom';
import { Button, TextInput, Table, Breadcrumb, Modal } from 'flowbite-react';
import { dataCourses } from './../../data/dataCourses';
import { exceptionHelper } from './../../helper/exceptionHelper';
import { apiCourses } from '../../api/apiCourses';
import { v4 as uuidv4 } from 'uuid';
import { isEmpty } from '../../helper/stringHelper';
import { showError, showNotImplemented, showSuccess } from '../../helper/swalHelper';
import { dataCourseSources } from '../../data/dataCourseSources';
import BlockDropZone from '../../components/blockDropZone';


const PageCourse = () => {
    const { courseId } = useParams();
    const [dataset, setDataset] = React.useState([]);
    const [modalImport, setModalImport] = React.useState(false);
    const isNew = courseId === 'new';

    React.useEffect(() => {
        if (isNew) {
            setDataset({
                id: uuidv4(),
                name: '',
                note: '',
                materials: []
            });
        } else {
            setDataset(dataCourses.find(item => item.id === courseId));
        }
    }, [courseId, isNew]);

    const handleChanges = (e) => {
        const { name, value } = e.target;
        setDataset(prevState => ({ ...prevState, [name]: value }));
    }

    const handleDelete = (e, materialId) => {
        e.preventDefault();
        setDataset(prevState => ({
            ...prevState,
            materials: prevState.materials.filter(item => item.id !== materialId)
        }));
    }

    const handleAdd = (e) => {
        e.preventDefault();
        const files = e?.target?.files ?? []
        if (files?.length < 1) return;

        let newMaterials = [];
        for (let file of files) {
            // validate the file
            if (file.type !== 'text/plain') { }
            if (file.size > 10000000) { showError('Error', 'File is too big'); return; }
            if (file.size < 1000) { showError('Error', 'File is too small'); return; }

            const fileName = (file.name?.split('.')?.slice(0, -1)?.join('.') ?? file.name ?? "")?.replace(/\s+/g, ' ')?.replace(/[^a-zA-Z0-9 ]/g, "")?.trim() ?? ""
            let fileType = ''
            let filePages = 0
            switch (file.type) {
                case 'text/plain':
                    fileType = 'txt';
                    filePages = 1;
                    break;
                // case 'application/pdf':
                //     fileType = 'pdf';
                //     break;
                // case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                //     fileType = 'docx';
                //     break;
                // case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                //     fileType = 'xlsx';
                //     break;
                // case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
                //     fileType = 'pptx';
                //     break;
                default:
                    showError('Error', 'Only TXT files are allowed'); return;
            }

            newMaterials.push({
                id: uuidv4(),
                name: fileName,
                type: fileType,
                pages: filePages,
            });
        }

        // add the new materials to the list
        if (newMaterials.length > 0) {
            setDataset(prevState => ({
                ...prevState,
                materials: [...prevState.materials, ...newMaterials]
            }));

            // reset the file input
            e.target.value = null;

            // show success message
            showSuccess('Success', `${newMaterials.length} material${newMaterials.length > 1 ? 's' : ''} added`);
        }
    }

    const handleSave = (e) => {
        e.preventDefault();
        try {
            if (isEmpty(dataset.id)) { showError('Error', 'id is required'); return; }
            if (isEmpty(dataset.name)) { showError('Error', 'name is required'); return; }
            const result = apiCourses.save(dataset);
            console.log("handleSave -> result", result);
        }
        catch (ex) {
            exceptionHelper.handleException(ex);
        }
    }

    const handleImport = (importId) => {
        setModalImport(false);
        showNotImplemented();
    }

    return <main className="dashboard p-8 pb-24">

        {/* 1- Breadcrumb */}
        <Breadcrumb aria-label="breadcrumb" className='-ml-3'>
            <Breadcrumb.Item href="/"><i className="fas fa-home mr-2"></i> Home</Breadcrumb.Item>
            <Breadcrumb.Item href="/dashboard">Dashboard</Breadcrumb.Item>
            <Breadcrumb.Item href="/dashboard/courses">Courses</Breadcrumb.Item>
            <Breadcrumb.Item>{isNew ? 'New Course' : dataset.name}</Breadcrumb.Item>
        </Breadcrumb>

        {/* 2- header */}
        <div className="flex w-full flex-col my-6 relative items-start">
            <h1 className="text-3xl mb-0">{isNew ? 'New' : 'Edit'} Course</h1>
            <div className="text-lg">{isNew ? 'Create a new course.' : 'Edit course details.'}</div>
            <div className="absolute top-0 right-0">
                <Button type="button" size="sm" color="light" onClick={() => setModalImport(true)}>Import Materials</Button>
            </div>
        </div>

        {/* 3- Form */}
        <div className="flex w-full flex-col my-6 bg-white shadow-md dark:bg-gray-800 sm:rounded-lg border border-gray-100 dark:border-gray-700 p-6 relative items-start">
            <BlockDropZone subtitle="Drag and drop materials here to upload" onFileSelected={handleAdd} isMultiple={true} />
            <TextInput
                className="w-48 mt-4"
                name="name"
                placeholder="Course Name"
                type='text'
                onChange={handleChanges}
                defaultValue={dataset?.name}
                required />
            <TextInput
                className="w-full mt-4"
                name="note"
                placeholder="Note"
                type='text'
                onChange={handleChanges}
                defaultValue={dataset?.note}
            />
            {dataset?.materials?.length > 0 &&
                <div className="w-full mt-4">
                    <Table>
                        <Table.Head>
                            <Table.HeadCell>Name</Table.HeadCell>
                            <Table.HeadCell>Type</Table.HeadCell>
                            <Table.HeadCell>Pages</Table.HeadCell>
                            <Table.HeadCell></Table.HeadCell>
                        </Table.Head>
                        <Table.Body>
                            {dataset?.materials?.map((item, index) =>
                                <Table.Row key={index}>
                                    <Table.Cell className='px-0'>
                                        <TextInput type='text' name="name" placeholder="Material name" required defaultValue={item.name} />
                                    </Table.Cell>
                                    <Table.Cell>{item.type?.toUpperCase()}</Table.Cell>
                                    <Table.Cell>{item.pages}</Table.Cell>
                                    <Table.Cell><button onClick={(e) => handleDelete(e, item.id)}><i className="fas fa-trash-alt mr-2"></i></button></Table.Cell>
                                </Table.Row>
                            )}
                        </Table.Body>
                    </Table>
                </div>}
        </div>

        {/* 4- Table Footer */}
        <div className="flex flex-row w-full justify-end space-x-2">
            <Button type="button" size="sm" color="light" onClick={() => window.location.href = `/dashboard/courses`}>Cancel</Button>
            <Button type="button" size="sm" color="success" onClick={handleSave}>Save</Button>
        </div>

        {/* 5- Modal Import */}
        <Modal className='dashboard' show={modalImport === true} onClose={() => setModalImport(false)}>
            <Modal.Header>Import Materials</Modal.Header>
            <Modal.Body>
                {[["lms", "LMS"], ["cloud", "Cloud Storage"]].map(item =>
                    <React.Fragment key={item[0]} >
                        <div className="flex items-center">
                            <span>{item[1]}</span>
                            <div className="flex-grow border-t ml-2 border-gray-300"></div>
                        </div>
                        <div className='flex flex-row flex-wrap space-x-2 mt-2 mb-6'>
                            {dataCourseSources[item[0]].map(item => (
                                <div className="flex flex-col items-center space-y-2" key={item.id}>
                                    <button
                                        className="bg-light text-gray-700 border border-gray-300
                                        hover:bg-light-hover w-28 hover:border-gray-500"
                                        onClick={() => handleImport(item.id)}>
                                        <img
                                            src={`/images/logo-${item.id}.png`}
                                            alt={`${item.en.name} logo`}
                                            className="object-contain h-full"
                                        />
                                    </button>
                                    <span className="text-xs w-28 break-words text-center">{item.en.name}</span>
                                </div>
                            ))}
                        </div>
                    </React.Fragment>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button type="button" size="sm" className="sky" onClick={() => setModalImport(false)}>Close</Button>
            </Modal.Footer>
        </Modal>

    </main>
}

export default PageCourse
