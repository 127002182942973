export const dataAcademicPosition = [
    { id: "academicServicesCoordinator", en: { name: "Academic Services Coordinator" }, fr: { name: "Coordinateur des services académiques" } },
    { id: "adjunctInstructor", en: { name: "Adjunct Instructor" }, fr: { name: "Instructeur adjoint" } },
    { id: "adjunctProfessor", en: { name: "Adjunct Professor" }, fr: { name: "Professeur adjoint" } },
    { id: "administrativeAssistant", en: { name: "Administrative Assistant" }, fr: { name: "Assistant administratif" } },
    { id: "assistantDean", en: { name: "Assistant Dean" }, fr: { name: "Doyen adjoint" } },
    { id: "assistantDirector", en: { name: "Assistant Director" }, fr: { name: "Directeur adjoint" } },
    { id: "assistantProfessor", en: { name: "Assistant Professor" }, fr: { name: "Professeur assistant" } },
    { id: "associateDean", en: { name: "Associate Dean" }, fr: { name: "Doyen associé" } },
    { id: "associateDirector", en: { name: "Associate Director" }, fr: { name: "Directeur associé" } },
    { id: "associateProfessor", en: { name: "Associate Professor" }, fr: { name: "Professeur associé" } },
    { id: "coordinator", en: { name: "Coordinator" }, fr: { name: "Coordinateur" } },
    { id: "curriculumDesigner", en: { name: "Curriculum Designer" }, fr: { name: "Concepteur de programmes" } },
    { id: "dean", en: { name: "Dean" }, fr: { name: "Doyen" } },
    { id: "departmentChair", en: { name: "Department Chair" }, fr: { name: "Chef de département" } },
    { id: "director", en: { name: "Director" }, fr: { name: "Directeur" } },
    { id: "emeritus", en: { name: "Emeritus" }, fr: { name: "Professeur émérite" } },
    { id: "executiveDirector", en: { name: "Executive Director" }, fr: { name: "Directeur exécutif" } },
    { id: "instructor", en: { name: "Instructor" }, fr: { name: "Instructeur" } },
    { id: "instructionalDesigner", en: { name: "Instructional Designer" }, fr: { name: "Concepteur pédagogique" } },
    { id: "lecturer", en: { name: "Lecturer" }, fr: { name: "Chargé de cours" } },
    { id: "librarian", en: { name: "Librarian" }, fr: { name: "Bibliothécaire" } },
    { id: "professor", en: { name: "Professor" }, fr: { name: "Professeur" } },
    { id: "programAssistant", en: { name: "Program Assistant" }, fr: { name: "Assistant de programme" } },
    { id: "programCoordinator", en: { name: "Program Coordinator" }, fr: { name: "Coordinateur de programme" } },
    { id: "programDirector", en: { name: "Program Director" }, fr: { name: "Directeur de programme" } },
    { id: "programManager", en: { name: "Program Manager" }, fr: { name: "Gestionnaire de programme" } },
    { id: "projectCoordinator", en: { name: "Project Coordinator" }, fr: { name: "Coordinateur de projet" } },
    { id: "provost", en: { name: "Provost" }, fr: { name: "Recteur" } },
    { id: "researchAssistant", en: { name: "Research Assistant" }, fr: { name: "Assistant de recherche" } },
    { id: "researchFellow", en: { name: "Research Fellow" }, fr: { name: "Chercheur associé" } },
    { id: "teachingAssistant", en: { name: "Teaching Assistant" }, fr: { name: "Assistant d'enseignement" } },
    { id: "visitingProfessor", en: { name: "Visiting Professor" }, fr: { name: "Professeur invité" } }
];
