import React from "react";
import { Link } from "react-router-dom";
import BlockCopyright from './blockCopyright';
import { ContextTheme } from "../pages/contextTheme";


const BlockFooter = ({ menuList, ctaTitle, ctaLabel, ctaLink }) => {
    const { theme } = React.useContext(ContextTheme);

    return (
        <footer className="justify-self-end pt-16 pb-8 lg:pt-24 lg:pb-10">
            <div className="px-4 mx-auto max-w-8xl">
                <div className="grid max-w-screen-xl gap-10 pt-10 mx-auto mt-5 border-t border-gray-200 dark:border-trueGray-800 grid-cols-3 md:grid-cols-5">
                    <div className="col-span-3 md:col-span-2">
                        <div>
                            {" "}
                            <Link href="/" className="flex items-center space-x-2 text-2xl font-medium text-sky-600 dark:text-gray-100">
                                <img src={`/logo2-${theme || "light"}.png`} alt="EdPLATO Logo" className="w-52" />
                            </Link>
                        </div>

                        <div className="max-w-md mt-4 text-gray-600 dark:text-gray-400">
                            {ctaTitle} <a target="_self" href={ctaLink} className="ml-1">{ctaLabel}</a>
                        </div>
                    </div>

                    {menuList.map((col, colIndex) => (
                        <div key={colIndex}>
                            <h5 className="mb-6 text-sm font-semibold text-gray-400 uppercase dark:text-white">{col.title}</h5>
                            <ul className="list-none p-0">
                                {col.items.map((item, index) => (
                                    <li key={index} className="mb-4">
                                        <a
                                            href={item?.url}
                                            rel={item.url.startsWith("https") ? "noopener noreferrer" : undefined}
                                            target={item.url.startsWith("https") ? "_blank" : undefined}
                                            className="footer-item"
                                        >
                                            <div className="flex">
                                                {item.icon &&
                                                    <span className="rounded-md w-6 h-6 flex items-center justify-center mr-2">
                                                        <i className={item.icon}></i>
                                                    </span>
                                                }
                                                {item.title}
                                            </div>
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}

                </div>
                <hr className="hidden max-w-screen-xl mx-auto my-6 lg:px-4 lg:my-8 border-gray-200 dark:border-gray-700" />

                <BlockCopyright />
            </div>
        </footer>
    )
}

export default BlockFooter