import React from 'react';
import EdHelmet from '../../components/edHelmet';

const PagePolicy = () =>
    <main>
        <EdHelmet title="Politiques" description="Transparence et lignes directrices régissant votre expérience avec EdPLATO, garantissant un environnement d'apprentissage sécurisé et équitable." />
        <section>
            <h1>Politiques</h1>
            <p className="mb-8 text-lg lg:text-lg">
                Transparence et lignes directrices régissant votre expérience avec <a className="" href="/">EdPLATO</a>, garantissant un environnement d'apprentissage sécurisé et équitable.
            </p>
            <hr />
        </section>

        <section>
        </section>

    </main>

export default PagePolicy;