import React from 'react';
import { useParams } from 'react-router-dom';
import BlockPosts from './../../components/blockPosts';
import { dataBlogPosts } from './../../data/dataBlogPosts';
import EdHelmet from '../../components/edHelmet';

const PageBlogTag = () => {
    const { tag } = useParams();
    const lang = "fr"
    const filteredBlogPosts = dataBlogPosts.filter((post) => post[lang].tags && post[lang].tags.findIndex((t) => t.trim().toLowerCase().replace(/ /g, "-") === tag) !== -1);
    if (!filteredBlogPosts) throw new Response("Non trouvé", { status: 404 });

    return <main>
        <EdHelmet title={"#" + tag} description="" />
        <section>
            <h1>Tag: <a
                href={"/blog/tag/" + tag}
                className="px-2.5 py-0.5 rounded bg-sky-100 text-sky-800 dark:bg-sky-200 dark:text-sky-800 hover:bg-sky-200 dark:hover:bg-sky-300"
            >#{tag}</a>
            </h1>
            <hr />
        </section>

        <section className='max-w-5xl mx-auto grid gap-6 grid-cols-1 md:grid-cols-2'>
            <BlockPosts posts={filteredBlogPosts} lang={lang} />
        </section>
    </main>
}

export default PageBlogTag