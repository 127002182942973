import React from 'react';
import EdHelmet from '../../components/edHelmet';

const PageDocumentation = () =>
    <main>
        <EdHelmet title="Documentation" description="Find comprehensive guides, tutorials, and resources to maximize your understanding and utilization of the EdPLATO platform's features." />
        <section>
            <h1>Documentation</h1>
            <p className="mb-8 text-lg lg:text-lg">
                Find comprehensive guides, tutorials, and resources to maximize your understanding and utilization
                of the <a className="mr-1" href="/">EdPLATO</a> platform's features.
            </p>
            <hr />
        </section>
    </main>

export default PageDocumentation