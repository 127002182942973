import React from "react";
import { redirect, Navigate } from "react-router-dom";
import PageHome from './pageHome';
import PageAbout from './pageAbout';
import PageError from './pageError';
import LayoutMain from './layoutMain';
import PageContact from './pageContact';
import PageSolutions from './pageSolutions';
import PagePricing from './pagePricing';
import PageBlog from './pageBlog';
import PageBlogPost from './pageBlogPost';
import PageRegister from './pageRegister';
import PageSignIn from './pageSignIn';
import PageBlogTag from './pageBlogTag';
import PageDocumentation from './pageDocumentation';
import PageHelp from './pageHelp';
import PageTerms from './pageTerms';
import PagePrivacy from './pagePrivacy';
import PageLicensing from './pageLicensing';
import PagePolicy from './pagePolicy';
import PageCareers from "./pageCareers";

const RouterEn = {
    path: "/",
    element: <LayoutMain />,
    errorElement: <PageError />,
    children: [
        { path: "/", element: <PageHome /> },
        { path: "/solutions", element: <PageSolutions /> },
        { path: "/solution", element: <Navigate to="/solutions" replace /> },
        { path: "/pricing", element: <PagePricing /> },
        { path: "/blog", element: <PageBlog /> },
        { path: "/blog/:slug", element: <PageBlogPost /> },
        { path: "/blog/tag/:tag", element: <PageBlogTag /> },
        { path: "/about", element: <PageAbout /> },
        { path: "/contact", element: <PageContact /> },
        { path: "/careers", element: <PageCareers /> },
        { path: "/career", element: <Navigate to="/careers" replace /> },
        { path: "/documentation", element: <PageDocumentation /> },
        { path: "/help", element: <PageHelp /> },
        { path: "/terms", element: <PageTerms /> },
        { path: "/term", element: <Navigate to="/terms" replace /> },
        { path: "/privacy", element: <PagePrivacy /> },
        { path: "/licensing", element: <PageLicensing /> },
        { path: "/policy", element: <PagePolicy /> },
        { path: "/register", element: <PageRegister /> },
        { path: "/signin", element: <PageSignIn /> },
        { path: "/login", element: <Navigate to="/signin" replace /> },
        { path: "/sitemap.xml", action: () => redirect("/sitemap.xml") },
        { path: "*", action: () => redirect("/error") },
    ],
}

export default RouterEn
