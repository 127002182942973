import React from "react"
import { getSlugLang } from "../helper/languageHelper"

const readTime = (post, lang) =>
    Math.round(post[lang].html.trim().split(' ').length / 130)

const BlockPosts = ({ posts, lang }) =>
    (posts && posts.length > 0) && posts.map((post) =>
        <article key={post.id} className="flex flex-col md:flex-row mb-6">
            <a href={`${getSlugLang(lang)}/blog/${post.slug.trim()}`} className="w-36 h-64 flex-shrink-0">
                <img
                    className="object-cover rounded-lg  w-full h-full"
                    src={post.image}
                    alt={post[lang].title.trim()}
                />
            </a>
            <div className="flex flex-col justify-start pt-1 px-3">
                <h2 className="text-xl font-bold leading-6 text-gray-900 dark:text-white">
                    <a href={`${getSlugLang(lang)}/blog/${post.slug.trim()}`}>{post[lang].title.trim()}</a>
                </h2>
                <p className="my-3 text-gray-600 dark:text-gray-400 text-sm">
                    {post[lang].excerpt.trim()}
                </p>
                <a
                    className="inline-flex items-center text-xs font-normal text-blue-600 dark:text-blue-400 underline underline-offset-4  hover:no-underline "
                    href={`${getSlugLang(lang)}/blog/${post.slug.trim()}`}
                >
                    {lang === 'fr'
                        ? `À lire en ${readTime(post, lang)} minutes`
                        : `Read in ${readTime(post, lang)} minutes`}
                </a>
            </div>
        </article>
    )

export default BlockPosts