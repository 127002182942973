import React from 'react';
import EdHelmet from '../../components/edHelmet';
import BlockTeamMember from './../../components/blockTeamMember';
import { dataOurTeam } from './../../data/dataOurTeam';

const PageAbout = () =>
    <main>
        <EdHelmet
            title="À propos de nous"
            description="EdPLATO est un pionnier dans le domaine de l'apprentissage alimenté par l'IA et de la technologie éducative. Basé au Canada, EdPLATO est dédié à la révolution de l'éducation grâce à des solutions innovantes de technologie éducative pilotées par l'IA qui mettent l'accent sur l'accessibilité, l'orientation vers la connaissance et l'impact social."
        />

        <section>
            <h1>À propos de nous</h1>
            <p className="mb-8 text-lg lg:text-lg">
                <a href="/">EdPLATO</a> est un pionnier dans le domaine de l'apprentissage alimenté par l'IA et de la technologie éducative. Basé au Canada, EdPLATO est dédié à la révolution de l'éducation grâce à des solutions innovantes de technologie éducative pilotées par l'IA qui mettent l'accent sur l'accessibilité, l'orientation vers la connaissance et l'impact social.
                Le nom EdPLATO représente notre engagement envers l'Éducation avec Apprentissage Personnalisé, Évaluation Adaptative et Intelligence Artificielle en Ligne". Ceci reflète notre focalisation principale sur les expériences d'apprentissage sur mesure, les stratégies d'évaluation adaptative et l'exploitation de la puissance de l'IA pour rehausser les résultats éducatifs.
            </p>
            <hr />
        </section>

        <section className='mb-8'>
            <h2 className='mb-5 text-2xl font-semibold text-gray-900 lg:text-3xl dark:text-white'>Notre mission</h2>
            <p>Notre mission est claire : donner aux étudiants issus de milieux divers la possibilité de libérer leur plein potentiel et d'atteindre leurs objectifs académiques et professionnels grâce à la puissance de l'IA. Parallèlement, nous nous efforçons de construire un monde plus équitable et durable grâce à nos initiatives éducatives basées sur l'IA. En exploitant les capacités de l'IA et en les associant à l'expertise éducative, notre objectif est de créer des expériences d'apprentissage transformatrices qui façonnent l'avenir de l'éducation.</p>
        </section>

        <section className='mb-8'>
            <h2 className='mb-5 text-2xl font-semibold text-gray-900 lg:text-3xl dark:text-white'>Notre équipe</h2>
            <p></p>
            <div className="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 lg:gap-6">
                {dataOurTeam.map((member, index) => <BlockTeamMember key={index} lang="fr" member={member} />)}
            </div>
        </section>
    </main>

export default PageAbout;