export const dataUniversities = [
    { id: "1ec68aa1-35a4-4e4e-a498-aae4065dee91", name: "3G University" },
    { id: "4acf67cb-76ca-4b3a-ad8a-54dcc7474509", name: "A Better City" },
    { id: "f1432f4b-665a-4977-9763-37b04302ee2e", name: "A.T. Still University of Health Sciences" },
    { id: "4dc35793-aea4-4bdc-9a71-1e4e59a0bdde", name: "AAA School of Advertising" },
    { id: "5cc1ce89-e12c-4f87-8660-0a760d45e0b5", name: "Aachen University of Applied Sciences" },
    { id: "0e91474d-ecb7-4f21-8d02-4ca8c317675a", name: "Aalborg University" },
    { id: "f4ab70d2-3d8b-4377-bcfa-86df3e0b6a35", name: "Aalen University" },
    { id: "2410d918-7b9d-4ecc-b3b2-47bdab21b7da", name: "Aalesund University College" },
    { id: "984faad8-b56b-4114-8f02-109a3735e582", name: "Aalto University" },
    { id: "d97b1e41-6ca1-4e52-b5a4-1ec99fffa9a8", name: "Aarhus University" },
    { id: "c542eb73-bec9-445d-acd8-702db44b1724", name: "Aba Stonier School of Banking" },
    { id: "4a15c2ee-6892-49a8-9ac0-98d1b8210d3e", name: "Abant Izzet Baysal Universitesi" },
    { id: "87a4748e-220d-4dfa-8db9-31563c600ad2", name: "Abat Oliva CEU University" },
    { id: "3a8eedda-cecb-45ac-8378-10cbfffe760d", name: "ABC University" },
    { id: "7841a7c1-1413-46e7-8d87-f6e2d5f070cc", name: "Abdul Wali Khan University Mardan" },
    { id: "94a84e7d-0df7-46ba-8da5-1929acdd7bff", name: "Abdullah Gül University" },
    { id: "d82a8877-54c6-4016-b803-20907cfaf2b3", name: "Abertay University" },
    { id: "79218627-48e3-4e67-a8fc-b35083b55f7c", name: "Aberystwyth University" },
    { id: "97cf1a36-6bf3-44bc-bf04-0e6453308c0f", name: "ABES Engineering College" },
    { id: "ec8b5709-93d8-407a-a879-dfebad777d19", name: "ABES Institute of Technology" },
    { id: "6f8ac0b9-769f-45cc-8927-94d729d1bcd2", name: "Abilene Christian University" },
    { id: "a76e9ae8-ed80-4940-9e8c-daf2780040fa", name: "Abilene Educational Supply" },
    { id: "2d098dca-c662-491a-99ec-2272bd21f2c9", name: "ABM College" },
    { id: "cdb7a1d3-f6eb-4ca2-a410-945ad885e532", name: "Abo Akademi University" },
    { id: "cd9b9902-fcd2-4f4a-a26b-4794358890ff", name: "Abraham Baldwin Agricultural College Foundation" },
    { id: "cfea143a-6aea-4955-afd7-6b5fbba0728a", name: "Abraham Lincoln University" },
    { id: "b8124f9c-35db-4294-bb25-7b2b9436790e", name: "Abu Dhabi School of Management (ADSM)" },
    { id: "c23b2d7b-75a1-48e3-adcb-ecfdc4730c28", name: "Abu Dhabi University" },
    { id: "4ec41859-3e9e-4681-9d46-8c310eb23f20", name: "Academia Engiadina" },
    { id: "e00e58bf-2d6f-4b23-b974-3b9c8c454652", name: "Academia.edu" },
    { id: "cfbcccfa-75a0-478b-9e2d-628b9b93e204", name: "Academic Book Importers" },
    { id: "4c72d1fe-a215-4619-ab30-805dc9997967", name: "Academic College of Tel Aviv-Jaffa" },
    { id: "dce58e87-0cc5-421a-9ae3-a0d21c7f9944", name: "Academic Publishing Service" },
    { id: "da8e6de4-cf04-47b5-9601-7813e4ae057c", name: "Academie Accor" },
    { id: "8d81751d-a48c-48f6-903e-cdd46bef87a4", name: "Academy College" },
    { id: "d7f84bfe-6e69-4807-b0fd-fdc4707976a3", name: "Academy for Jewish Religion" },
    { id: "5c537bd3-b58c-4f7a-ac74-2850604b214c", name: "Academy of Art University" },
    { id: "1b46c393-6258-4956-b35b-bfddeb463dcd", name: "Academy of Christian Humanism University" },
    { id: "fbbbf1d2-c252-4264-890e-c7d757210117", name: "Academy of Economic Studies Bucharest" },
    { id: "7fee7016-703b-4068-9fcd-28860438d840", name: "Academy of Executive Education" },
    { id: "2cac0443-b21e-41b2-8c71-4e7033ef71a9", name: "Academy of National Economy" },
    { id: "4a2d8d16-ed72-4086-a795-1f18e2e57afd", name: "Academy of Sports Sciences Research and Management" },
    { id: "35c266af-1122-4efa-9f3e-1da90b8f093e", name: "Academy of Technical Training" },
    { id: "c92122b2-1db6-49a1-a7ef-b82d11bf2c16", name: "Academy Technical Training (ATT) Dubai" },
    { id: "f798126c-fbc6-4783-9baf-108c2f548e1b", name: "Acadia Christian School" },
    { id: "bfc1251f-7a20-4a30-b5fb-c5e746407716", name: "Acadia University" },
    { id: "829e1fbe-1ded-4f20-9568-70cd793fc693", name: "Académie de Nancy-Metz" },
    { id: "743344fe-0715-4764-9644-0c029be1d4e5", name: "Accadis Bildung Gmbh" },
    { id: "e8d75232-735c-4ee2-9d8d-692d8d020143", name: "Accis" },
    { id: "3968fcbe-3013-4a6c-b216-60c4597bec97", name: "Accord Business School" },
    { id: "a8454af6-345a-4881-b655-00b2f16699ec", name: "Accounting University of Ulsan" },
    { id: "70bc1e3f-c277-48a1-9102-864ece1e9300", name: "Accra Polytechnic" },
    { id: "ee3ec661-35bd-4306-b781-b1bf21309f09", name: "Ace Denmark" },
    { id: "9b7d7792-5760-4e92-8a57-51970476804e", name: "Ace Institute of Management" },
    { id: "ca40d436-5cd5-416a-bbc1-737c3b595e5d", name: "Acfci - Reseau Egc" },
    { id: "43d376a5-5720-46e4-94e3-3aae65640e8c", name: "Acharya Institute of Management and Sciences" },
    { id: "184024ab-4838-408b-9a87-9b693d9f1a1d", name: "Acharya Nagarjuna University" },
    { id: "def533ee-4eae-49b1-8103-199347507d54", name: "Acharya School of Management" },
    { id: "efda1ff5-9c56-4a68-a7ff-c91f4ce4c2da", name: "Acropolis Institute of Management Studies & Research" },
    { id: "dc4641e0-d69d-40fd-9951-cabdad7a9bf6", name: "Acsenda School of Management" },
    { id: "075f40ac-468c-49c4-b5ad-268f296c751c", name: "Acton School of Business" },
    { id: "91247a66-9e2a-4245-a357-87bed553bcc7", name: "ADA University" },
    { id: "63285750-d2a2-4afa-8330-b337d2a1f259", name: "Adama Science and Technology University" },
    { id: "25ba947a-d7e5-4eb7-9760-3f5c3de478fd", name: "Adamas University" },
    { id: "0dbd7ca6-df8f-4242-a548-801e6e6e9fc4", name: "Adams Learning Inc" },
    { id: "b989521c-7049-4a7f-8dbc-0ef33365d58a", name: "Adams State University" },
    { id: "600b9e19-ee65-44ed-86b6-cf4574fd960c", name: "Adana Bilim ve Teknoloji Universitesi" },
    { id: "7d97d5df-0b4d-4c4d-b728-073f23bad275", name: "Adani Institute of Digital Technology Management (AIDTM)" },
    { id: "96bb928e-bb19-4bbe-901f-1b18b1070723", name: "Adani University" },
    { id: "1966afb0-353b-4aa6-8913-00a6295e7672", name: "Addis Ababa University" },
    { id: "bf7e2214-d0cb-4e79-baf7-2994323e8b06", name: "Adelaide & Meath Hospital Inc." },
    { id: "4aa4c841-808c-4668-8e41-01df6fd45234", name: "Adelphi University" },
    { id: "4a093489-eeb3-416f-8dcd-3fa05923ac11", name: "Aden Business School" },
    { id: "2461e8a4-71be-4c9f-bbfd-82cd76d63aab", name: "ADEX Centros Academicos" },
    { id: "312214b8-cfb7-41f8-bf5d-c5b9db65bf36", name: "Adirondack Community College" },
    { id: "7f298961-a5cf-443e-b015-97635363a961", name: "Aditya Institue of Managment Studies & Research (AIMSR)" },
    { id: "1ef66126-076f-4e60-b0c7-461914053aa1", name: "Adizes Graduate School" },
    { id: "785e6365-b14d-4fde-85b9-4ad89bca986f", name: "Adler School of Professional Psychology" },
    { id: "876ac627-ebe3-4818-a395-7b1851d5b103", name: "Admas University School of Management" },
    { id: "5a9f0830-cbc8-4a6c-a657-32edd8b73973", name: "Administrative Staff College Of India" },
    { id: "6fbe20ad-3ee5-474b-be79-b8fe43f3e8e4", name: "Adnan Menderes University" },
    { id: "09e10578-40fa-4499-9170-8ebb488ddd85", name: "Adrian College" },
    { id: "79915a81-9efc-4792-894d-71b0449d10d2", name: "Advance TAFE" },
    { id: "b21405ed-ea5c-406c-a0fa-446e974f31e4", name: "Advanced Educational Products Inc." },
    { id: "f20f9f3b-9cab-434d-a866-067ad383c7c4", name: "Advanced Institute of Industrial Technology" },
    { id: "bb842275-2a29-4727-9c23-1c09a76bfe31", name: "Advancia" },
    { id: "53e56024-149c-44e7-aee0-a4ba2c3e8ba8", name: "Advancia Negocia" },
    { id: "4427f63a-de4b-4184-ae07-378354055fb9", name: "Advantage Leadership Solution Pvt. Ltd." },
    { id: "b5c1445e-e363-4e73-be37-58104191d997", name: "Advent Institute of Management" },
    { id: "81afee90-27ac-4acc-804f-2a370f6c0c3e", name: "Adventer Serviciós Jurdícos, Económicos y Financieros" },
    { id: "95eb3f97-ee8c-4e18-adb4-d26b50115434", name: "Adventist International Institute of Advanced Studies" },
    { id: "5c579305-5182-4247-9cb1-f498333aa4bd", name: "Adventist University Of Africa" },
    { id: "f58e05c7-0c03-49b7-b951-07fd1cf95492", name: "Adventist University of Central Africa" },
    { id: "cee0db4a-8c34-4e4a-9846-f354d66e6d40", name: "Adventist University of Health Sciences" },
    { id: "8a49ff2a-5c1d-4096-b93c-5918194b75ae", name: "Adventist University of the Philippines" },
    { id: "68dadde6-8dac-4eff-81d5-05efc65d1a37", name: "ADYP - Ajeenkya D Y Patil University" },
    { id: "c5f432c9-3ff1-42c3-9424-81884a7c418a", name: "Aegis Global Academy" },
    { id: "4052f9ca-18f1-4469-8531-89241070a1e0", name: "Aegis School of Business and Telecommunication" },
    { id: "7e23452b-f672-45a7-8c87-5daef7af2881", name: "AES Institute of Computer Studies" },
    { id: "e0841c1d-b425-4a74-9a0f-5b25c6f5e374", name: "AESE Business School" },
    { id: "5467a6fd-3756-42aa-a451-235704c90bf6", name: "AFPA" },
    { id: "96b967ae-5f9f-4d8c-a434-0b9cc8dfbd02", name: "Africa Nazarene University" },
    { id: "2445cae1-2ef5-4996-a05c-a18e1ae6423f", name: "Africa University" },
    { id: "71c9709d-621c-4db3-b673-5236694ec2fb", name: "African Centre for Migration & Society ACMS" },
    { id: "d0ec5ab0-c41b-4ee0-9ae0-a4f32756fa97", name: "African Christian College" },
    { id: "97386dae-20e2-4fbc-82d9-2827681ff31a", name: "African Leadership Academy" },
    { id: "e68cc40c-49f9-4b2b-a01a-e02db0bc005f", name: "African Methodist Episcopal University" },
    { id: "7e9a7439-5dd5-4674-a2ed-a17f48d160af", name: "African School of Finance and Banking" },
    { id: "6c8cc785-a175-468d-ba51-8e39400f3ad1", name: "AFW Wirtschaftsakademie Bad Harzburg GmbH" },
    { id: "8ed02cd1-99b5-43df-8985-a6c484db2ebc", name: "AGH University of Science and Technology" },
    { id: "88ad199e-014e-40a4-9b3c-13920b7004d2", name: "Agnes Scott College" },
    { id: "52c1cc6c-dce8-446a-8c2e-02cca2cbd0a1", name: "Agra College" },
    { id: "93304f54-0be0-46a2-a5f6-2f268f8f37e3", name: "Agricultural University of Georgia" },
    { id: "c3ca6b8b-a97a-4b1c-bc3d-16ee0b72a959", name: "Agroparistech" },
    { id: "134eb961-d5f0-48a8-9f2c-88257a624f20", name: "AGSB Uuniversity" },
    { id: "1a2810ff-a635-46a1-a0ef-c1f9f2f8f1b9", name: "Ahmadu Bello University" },
    { id: "1070f620-a36b-43a7-8e76-03f8c60b6cd3", name: "Ahmed bin Mohammed Military College" },
    { id: "f01e8959-8019-4eaf-ac62-68110e8d6a0c", name: "Ahmedabad University" },
    { id: "6b28b684-9b86-4d4f-b797-e2c0f22f7fad", name: "Ahram Canadian University" },
    { id: "8758af79-0b03-4b57-aa4e-bd7303a46b25", name: "AIB College of Business" },
    { id: "b17e2a9a-55fe-4244-82fe-b56ffac64428", name: "AIC - Asian International College" },
    { id: "78df6d5d-2e37-4d39-8df7-e027caae649a", name: "AICAR Business School" },
    { id: "29da993c-feff-4e5a-8d60-1d7f9665e6c8", name: "Aichi University" },
    { id: "b3fac744-8b9e-490a-b81d-077ed30709da", name: "AIM WA - UWA Business School" },
    { id: "b8a884cb-2e8b-4836-9e48-83ddb2ac486e", name: "Aims Community College" },
    { id: "bcf7edd2-46d2-4766-92d0-08ee72d19425", name: "AIMST University" },
    { id: "431ce15c-6b0f-411d-a87f-c4db31f77665", name: "Air Command and Staff College" },
    { id: "ee10b70f-d9f0-4041-a628-d6ebc2e6f57f", name: "Air Force Institute of Technology" },
    { id: "6aa179aa-c5ec-43ab-aca9-5dd4eb9cab93", name: "Air University" },
    { id: "cc8e8e3f-4e2a-417c-8ac7-0c3cc5ce6f61", name: "Air War College" },
    { id: "65c29633-0e76-4faa-8703-ce66429754c8", name: "Airlangga University" },
    { id: "98e9e2c8-6869-4923-b2d3-839e4ef6c35b", name: "AIS Saint Helens" },
    { id: "d3440611-7044-4839-afba-38b3a1b10ff0", name: "Ajman University" },
    { id: "c3fc51de-0b9f-402c-a428-849cd2e44d18", name: "Ajman University of Science and Technology" },
    { id: "af3c11a3-b414-495a-b89e-4deaea256587", name: "Ajou University" },
    { id: "430e1e75-77d6-4a89-945a-0dfe8d21df51", name: "AKAD University - AKAD Hochschule Stuttgart" },
    { id: "3fe0aaf6-ba38-424e-a395-6e2f05383650", name: "Akademia Finansów i Biznesu Vistula" },
    { id: "91312f87-0b7f-4f36-b9e3-5d2937fc058c", name: "Akademia Finansów i Biznesu Vistula" },
    { id: "b387d4bf-73a8-419a-811e-b4f667baa21c", name: "Akdeniz Karpaz Üniversitesi" },
    { id: "4d20bcd8-ee8a-4e50-a832-d196b84dadd6", name: "AKFA University" },
    { id: "869247a2-9a22-49a8-b57b-ea1440f92893", name: "Akita International University" },
    { id: "d4e35b31-dc68-4cb1-8a99-60e9dd2eb7ab", name: "Al Ain University" },
    { id: "06d04718-7c82-4837-9037-ea7634b21603", name: "Al Ain Womenâ€™s College" },
    { id: "57224ce3-143a-4d19-bc19-957418c97052", name: "Al Akhawayn University" },
    { id: "059df126-2a85-4c7a-811f-2c286a0946c4", name: "Al Dar University College" },
    { id: "c3fecf4f-4040-4da6-b349-6081fa99008a", name: "Al Farabi Kazakh National University" },
    { id: "6a8143cc-2c17-4df2-84ee-498acb27ed17", name: "Al Yamamah University" },
    { id: "23e27be4-7c69-47b2-8d14-37bb3d2af33f", name: "Al-Aqsa Institute" },
    { id: "b6201e4c-00d5-4f7b-8a36-ccdd1dfce037", name: "Al-Balqa' Applied University" },
    { id: "8c5fe06b-a640-4e39-b81f-09d3cfd0a122", name: "Al-Madinah International University" },
    { id: "64befef9-9db4-4785-a44f-b9e2466362b4", name: "Al-Quds University" },
    { id: "8710491d-6ef9-488e-b71f-10fba3557689", name: "Alabama A&M University" },
    { id: "38681ac4-62f6-4620-b505-ae969ef09e37", name: "Alabama Agricultural and Mechanical University" },
    { id: "bb3a171a-24bb-48f7-a9c0-f703d53b8674", name: "Alabama State University" },
    { id: "82c4e45c-58cd-4b05-baca-6e8fc14d1374", name: "Alamo Colleges" },
    { id: "b8febbc5-1253-4d9a-8a08-f804204a9e8f", name: "Alaska Career College" },
    { id: "38a09223-5095-4da2-b3c2-a9f248c59f37", name: "Alaska Pacific University" },
    { id: "d09c4f30-0d30-4602-bb15-5083d582089b", name: "Alastair Rylatt" },
    { id: "6ced4aa6-7288-44a3-afb9-0457049950e1", name: "Alba Graduate Business School" },
    { id: "04ef3e07-4695-4eb0-bfe6-fbced046649d", name: "Albany College of Pharmacy" },
    { id: "73063506-e053-4722-8ca6-1902595d296e", name: "Albany Community Charter School" },
    { id: "711a6838-c2d4-4357-912c-9e88c88edc43", name: "Albany Law School" },
    { id: "9477ffcc-49a6-41d3-87d5-917ef7bb9888", name: "Albany Medical College" },
    { id: "0cbc354c-c0ec-4a16-93e4-739d7cd8a1d0", name: "Albany State University" },
    { id: "957b80ba-ce58-4f0f-a9be-1c03cfa41163", name: "Albany Technical college" },
    { id: "64a6a5a0-ec48-4e4f-98a2-cd8a9dfd46cd", name: "Albert Einstein College of Medicine" },
    { id: "124f3d7f-915c-40ac-865e-9446a10bc603", name: "Albert-Schweitzer-Gymnasium" },
    { id: "fce66dd9-28bb-4a5f-845a-78e6c1b814a3", name: "Alberta College of Art + Design" },
    { id: "0afb9fb7-739d-45b9-8ff8-5fef5821e16c", name: "Alberta Innovation and Advanced Education" },
    { id: "c8242942-b1db-486a-997d-561fda403ef8", name: "Albertson College" },
    { id: "e1933943-92ba-49e9-9c00-7ddbae38b60a", name: "Albertus Magnus College" },
    { id: "986b38a9-c031-4fcd-8e07-345a4da6aff5", name: "Albion College" },
    { id: "430a3603-9d7f-4ea7-8b44-0a0a3bcae063", name: "Albright College" },
    { id: "c9d169d3-ef33-4cdb-a4f1-4bd1b658813c", name: "Alcorn State University" },
    { id: "2a11a388-1dca-452d-81d3-93050adde54b", name: "Alder University" },
    { id: "d5c8fd64-75b9-4eb2-932f-b8e08824db3c", name: "Alderson Broaddus University" },
    { id: "a4a6d9c3-9656-4c7a-be08-a31a8a78162b", name: "Aleksandras Stulginskis University" },
    { id: "73dee12f-c656-4ddd-9d6f-6b014ca9251a", name: "Alexander College" },
    { id: "24b90f01-89a5-4dd5-950d-12b707777884", name: "Alexander Technological Educational Institute" },
    { id: "58f96791-124e-4ae8-9ad4-1d88049523e1", name: "Alexandria University" },
    { id: "df585ff4-4394-485c-b58f-54f75dfa1a86", name: "Alexandru Ioan Cuza University" },
    { id: "baa79094-451f-4107-8bf2-c996235adfc9", name: "Alfaisal University" },
    { id: "fc79c2ce-bfbd-4e43-8a05-4b94727669c5", name: "Alfonso X el Sabio University" },
    { id: "30bb09b6-40d5-41ff-8920-ace01afacaac", name: "Alfred University" },
    { id: "a9216295-265b-4cad-b810-4989feddf974", name: "Algebra centar Zagreb" },
    { id: "8b359843-8265-4be2-8548-e7b6f91a3d1b", name: "Algebra University Croatia" },
    { id: "0610f465-2855-4a20-9e39-33952a12302c", name: "Algoe Consultants" },
    { id: "f1af599e-99c9-41a5-9343-10c003862247", name: "Algoma University" },
    { id: "31f2f07b-92e8-4662-8463-4ff605864693", name: "Algonquin College" },
    { id: "3190ba22-6305-4fa8-b71f-7936ebb9c142", name: "Alhosn University" },
    { id: "5b985345-a87a-42ee-822b-aeaa81e0104e", name: "Alice Lloyed College" },
    { id: "7e54a2a9-b80b-42d6-a2c6-b470069885a8", name: "All Hallows College" },
    { id: "5076a928-b598-4c01-b328-67fc611a13e0", name: "All India Management Association" },
    { id: "c4614e39-cd39-4bec-9db5-ef60eed8755d", name: "Allahabad University" },
    { id: "deed401e-313a-4db1-b6c1-a169f76fc475", name: "Allana Institute of Management Sciences (AIMS)" },
    { id: "f9a3cae8-e293-4255-b36a-fc011ad3e9bb", name: "Allegany College of MD" },
    { id: "dbba0c5a-2ac1-4d58-b93a-e6b2f1a7ab31", name: "Allegheny College" },
    { id: "739818e0-6763-43f5-be68-d78a736a6bfb", name: "Allen Hancock College" },
    { id: "3aeb40fb-68d3-4fca-84ee-902178d48e48", name: "Allen Interactions" },
    { id: "3a434acd-d15d-4799-a96d-fdebf571d940", name: "Allen University" },
    { id: "a57bbfd1-af9d-4a06-b893-1d533e545bb8", name: "Alliance Business School Bangalore" },
    { id: "c8abe1fa-34ff-497c-9372-819afb7f3188", name: "Alliance University" },
    { id: "5ac086b5-6de7-4d91-b064-5b36d5ef94de", name: "Alliant International University" },
    { id: "0c9e5bc4-a074-45cf-ac47-fbce61494601", name: "Allianze University College of Medical Sciences" },
    { id: "b47f9512-c608-42cc-bc86-d09bc97b4c11", name: "Allyn Welch Holdings Inc" },
    { id: "f199ccf5-572b-434f-9b0e-75ac7b9fb2e0", name: "Alma College" },
    { id: "cb674ecd-6ee7-409a-b8a7-627d2e8767ab", name: "ALMA Graduate School" },
    { id: "46248e7a-9660-4747-a033-77d3b3b1d499", name: "Almaty Management University" },
    { id: "1e5bd05b-24e8-49ce-a5a3-0853d9320f03", name: "Alpen Adria Universitat Klagenfurt" },
    { id: "7596899c-0d5b-4048-826f-1d7480ea5329", name: "Alpen-Adria-Universität Klagenfurt" },
    { id: "68bdc1c4-b61b-4581-a1fa-b8ba1eccc67f", name: "Alpena Community College" },
    { id: "754541a3-8424-4d4b-b334-8c3fbf2e5ed2", name: "Alphacrucis College" },
    { id: "b3539bb1-dae1-44b4-b83b-d4145d0218d2", name: "Alva's Institute of Engineering & Technology" },
    { id: "b5f68dd5-6076-447a-86a4-22e33c8d460a", name: "Alvernia University" },
    { id: "29cd0b81-913d-4f9c-b3e6-17168719b487", name: "Alverno College" },
    { id: "72647fad-80e5-4296-b67a-aa6da710b5c2", name: "AMA Bahrain International University" },
    { id: "98e96e5c-ce1f-46c4-8ccd-62509da1199b", name: "AMA Europe NV/SA" },
    { id: "c55b498c-0c44-48b3-a5c2-17f73ca52368", name: "Amarillo College" },
    { id: "f1e25b24-4db5-4dca-b9c4-253f34b66751", name: "Amasya Üniversitesi Rektörlü?ü" },
    { id: "84b6cd5a-67fb-424b-95b3-5b7cb2a44f23", name: "Amazon DC" },
    { id: "47376fb7-e4e8-45eb-ba95-55196c2f7294", name: "Ambassador College" },
    { id: "37c26359-b75b-4ce5-a59d-ceea14f26642", name: "Ambedkar University - Delhi" },
    { id: "a890691e-022c-4e3e-bc51-bf7872cae3c8", name: "Amber Univ" },
    { id: "c032ae0b-5346-4e1c-b3dd-40bb7a2fbb63", name: "Amberton University" },
    { id: "285a4e41-24bd-4f17-acd8-3f044146bebe", name: "Ambra College - American College of Brazilian Studies" },
    { id: "5bf41519-690f-4081-aec2-a956931e72e1", name: "Ambrose University College" },
    { id: "3ea1f143-f13b-45df-b4c8-8de9b5f11079", name: "AMD Academy of Fashion and Design" },
    { id: "bedcc997-999c-4b34-b802-58eb4d7772ae", name: "AMD Akademie fur Mode & Design" },
    { id: "3ac267b1-0a8c-401a-a039-6b1747a11c81", name: "AMD Akademie Mode & Design" },
    { id: "a122a07e-ce7e-4a01-87a3-096b2959a082", name: "American Association for Physician Leadership" },
    { id: "b5ede76a-1998-4718-83ef-c5bb90cf0f25", name: "American Business and Technology Univerisy" },
    { id: "cbefeb71-225f-4732-8862-47679e999c8b", name: "American Business School" },
    { id: "cd4b2225-4def-49bf-98a7-d6f7c3b4d1c4", name: "American College" },
    { id: "6de41e3e-e6c6-444c-806f-b1ecad86b19a", name: "American College of Cardiology Foundation" },
    { id: "18f18db6-3855-4243-9452-33c9f45205c2", name: "American College of Greece" },
    { id: "65592c81-b0ca-4776-b9a0-11c6ec85f912", name: "American College of Healthcare Executives" },
    { id: "12fc0cd0-b9e4-4003-9be5-f176f5d07727", name: "American College of Surgeons" },
    { id: "0d8e3d20-acd2-4e9f-a223-6b5f7078ad0b", name: "American College of Technology" },
    { id: "4767ccaf-d8c1-4661-a280-03f81aa76c9f", name: "American College of the Middle East (ACM)" },
    { id: "b567cccf-52a7-4cac-8590-87aff5a17a24", name: "American College of Thessaloniki" },
    { id: "62e06dd9-613b-42fb-9c5f-dd864fdc1b46", name: "American Council on Education" },
    { id: "9ba17b42-31f2-4e6f-9b9e-6fb7e19a6090", name: "American Graduate School in Paris" },
    { id: "605de6a1-b56d-41f0-9632-45641dac9b58", name: "American Graduate University" },
    { id: "42c4b0fc-fb35-4080-810e-51d7313a1b04", name: "American Heritage Schools" },
    { id: "56e39557-9e29-41e0-80c4-4cbd10ae0f98", name: "American Hotel & Lodging Educational Institute" },
    { id: "2e334bf0-b64c-48ab-b0b7-a79de865031d", name: "American Intercontinental University" },
    { id: "e0f29580-9e20-44c9-95f8-b5ecbe4f82fc", name: "American International College" },
    { id: "e663e8ad-dff3-4e30-981a-f55fa1e7b97a", name: "American International University - Bangladesh" },
    { id: "1d5fe551-97e6-4b5a-b04f-3572ab9b3f40", name: "American Jewish University" },
    { id: "4c31c073-78c1-441a-a82f-d64bfee9c1e1", name: "American Management Association International" },
    { id: "aaac553a-1d1c-4319-a010-78e05c0239ce", name: "American Meridian University" },
    { id: "928264cc-e445-4527-9a2e-70631c94d304", name: "American Military University" },
    { id: "13b7b0c7-3975-460e-9caf-7d9186984d4f", name: "American Public Univ System" },
    { id: "32c84d69-cc3e-44b4-bc91-707d1dd61eeb", name: "American River College" },
    { id: "b4f181ba-4b83-472a-b28c-48a03b5195ae", name: "American Sentential University" },
    { id: "b4f2bcbc-04bd-423e-bec8-2b7acd3c484a", name: "American Society for Quality (ASQ)" },
    { id: "37aec016-38b6-4d2a-ad54-0f953de338d4", name: "American University" },
    { id: "88e80bd5-ced4-4a14-a971-1a7efb2c95b0", name: "American University Bookstore" },
    { id: "052dfe60-6f8f-4d6b-8590-6a5b3480c3a4", name: "American University Center of Provence" },
    { id: "1e478c3a-d160-47ac-aae2-06f14e3200b0", name: "American University in Bosnia and Herzegovina" },
    { id: "b4c25db2-c0d4-4ca3-ac16-ade1da84f2b9", name: "American University In Bulgaria" },
    { id: "7fd752c4-ce51-4f27-9a26-3267ecbf4bba", name: "American University in Cairo" },
    { id: "0becce2f-2560-42bd-a471-be510eda275a", name: "American University in Dubai" },
    { id: "14c7b5b3-90b3-4bf5-8909-0f4df90ad663", name: "American University in Kosovo (AUK)" },
    { id: "b1152311-fbcd-42c1-9ec7-bd087af3af85", name: "American University In Paris" },
    { id: "d78dc85a-f4a7-4271-bcca-e8a060aec0ee", name: "American University in the Emirates" },
    { id: "a353c66a-c233-482b-a0ae-94408473b2a7", name: "American University of Afghanistan" },
    { id: "5c37c9b7-4ff2-4559-8294-a6cd6eef4797", name: "American University of Armenia" },
    { id: "03674801-5d73-4760-877d-337c59295f7c", name: "American University of Bahrain" },
    { id: "e692c29d-6e70-45c8-b751-f92910600ad4", name: "American University of Beirut" },
    { id: "45a16a50-2aa6-48f4-bdfa-57a05c605ef6", name: "American University of Central Asia" },
    { id: "36aa605e-bf34-4c6b-b4a6-bf0a425fc138", name: "American University of Iraq Baghdad" },
    { id: "8330d089-51eb-48bc-a69f-fd763bfc138c", name: "American University of Iraq Sulaimani" },
    { id: "0d7c8947-01b2-40e5-9c25-065758f5b1dc", name: "American University of Nigeria" },
    { id: "24bc76e9-13ef-4065-8bd0-27f89522dd7b", name: "American University of Rome" },
    { id: "bbf7f124-05c1-45e6-9897-6907bae5b5cb", name: "American University of Science & Technology" },
    { id: "73a6007f-26f6-4e6e-8450-9334d0f6df62", name: "American University of Sharjah" },
    { id: "0e4d5e7b-f9ce-4326-911c-40bf63918d51", name: "American University of the Middle East" },
    { id: "0d133471-326d-4c1d-85d0-18b5832b052b", name: "American Virtual University" },
    { id: "48a87893-4270-4027-8a90-0bcd42a6d530", name: "Amet University" },
    { id: "3195f865-8cb9-4566-9daf-88dd8533088b", name: "Amherst College" },
    { id: "78b3b972-f2ef-40c3-b510-58f4cea45cd5", name: "Amity Online University" },
    { id: "81902fb0-89d0-4968-ba3e-3a9e87fdbb27", name: "Amity University" },
    { id: "c1d88927-1a71-42de-91c0-1e391852f43a", name: "AMP" },
    { id: "5e982578-942c-4528-ac4e-b841e45f657d", name: "Amphitheather Public School" },
    { id: "699af2d9-d506-4963-82eb-0d6a391506dd", name: "Amridge University" },
    { id: "60601a95-af53-4cf7-b63f-c3726bd2fbf7", name: "Amrita Coimbatore Campus" },
    { id: "09f91bd9-20ac-447d-afe3-2b13037e8124", name: "Amrita University" },
    { id: "ed3d8914-37c0-4ced-b0a5-fc2d322b92b3", name: "Amrut Mody School of Management (AMSOM)" },
    { id: "224e5d10-b7ba-4b2c-8125-700c5eab3ca1", name: "AMS - Chulalongkom University" },
    { id: "5bc338ec-b391-44d2-881b-cea4b6201b76", name: "AMS Management Institute" },
    { id: "21fdf635-a8a4-42af-a26e-18c68c3bb5e8", name: "AMS/LIB of Tung-Nan Univ" },
    { id: "90b2e637-021b-48f2-9ca6-19c17e95fbd5", name: "Amsterdam Institute of Finance" },
    { id: "0f2f9bff-eda3-4d24-9de0-873acac9e5d4", name: "Amsterdam School of Real Estate" },
    { id: "97ad5b7d-6944-4dbf-bc43-8a75788bd0b4", name: "Anabaptist Mennonite Biblical Seminary" },
    { id: "0d6f2a73-d63e-401e-865b-eed08663b3a5", name: "Anadolu University" },
    { id: "bbb78004-8ef6-453f-875c-7cc564816b47", name: "Anamarc College" },
    { id: "7fd0c1ca-a9c1-439b-8e17-d1e65d364bfd", name: "Anand Agricultural University" },
    { id: "e676ddec-e149-4f05-87b9-e5fd23f4f41b", name: "Anatolia College" },
    { id: "13503172-157f-4c3f-9dac-a67febea9542", name: "Andalas University" },
    { id: "5ae67ceb-107b-4e1c-809b-a063307b4c73", name: "Anderson Center" },
    { id: "d6ea9807-805a-43c0-aa2e-1f743dc2d18a", name: "Anderson University" },
    { id: "86603962-574a-494b-88d6-cb322c6c8e1a", name: "Andhra University" },
    { id: "a978f718-4531-4fc9-a3e5-847218afad84", name: "Andhra Vidyalaya College" },
    { id: "42d4d4e0-463a-41e0-8555-3c4d47ca271e", name: "Andover-Newton Theological School" },
    { id: "a2a17de5-9cc9-4c98-8e4e-994907e3b85d", name: "Andrews University" },
    { id: "374b8c6c-65f1-491e-b17a-2b18e4190567", name: "AndrÃ¡ssy UniversitÃ¤t Budapest" },
    { id: "d2f5f605-7f85-4e21-be18-21ff9a58a58a", name: "Angeles University Foundation" },
    { id: "a831c9bc-7f2a-4d88-8367-da602fb91297", name: "ANGELL Akademie Freiburg" },
    { id: "d7889a58-1e5c-482a-953a-16ec4b090a27", name: "Angelo State University" },
    { id: "52bc2cee-6a43-4608-b940-2c2077a6aad0", name: "Anglia Ruskin University" },
    { id: "260a9eab-1d5c-4069-b739-10dad5291616", name: "Anglo-American University" },
    { id: "cd053b3f-eb63-415b-9c24-314ebe1be945", name: "Anhanguera" },
    { id: "90ac69c0-ad8d-4f2c-bf5c-38cdc8d50fd8", name: "Ankara University" },
    { id: "428a080e-f473-4d59-add1-2d32532e00e4", name: "Ann Gregory" },
    { id: "825bf389-5a04-4aca-a92f-46f3c72bbee0", name: "Anna Maria College" },
    { id: "58e1cbad-2d63-4371-b789-a79bfd548577", name: "Anna University" },
    { id: "ee872196-21db-46ab-87e9-fe23d76483a9", name: "Annamalai University" },
    { id: "68c02199-b16e-4afb-8514-e8226e1bab3a", name: "Anne Arundel Community College" },
    { id: "2dde416d-5173-4c32-b2f5-a3a15651b1dd", name: "Anniston City Schools" },
    { id: "bf81e83a-ac30-4310-974b-cc0b8f0fd182", name: "Anoka-Hennepin School District" },
    { id: "a29f5d13-7bb5-459b-bc74-684ced2f4b2c", name: "Anoka-Ramsey Community College" },
    { id: "9f8e0bf9-962e-4bc1-8430-b91c4834aeb5", name: "Ans Laboratory of Business Adm" },
    { id: "484177e4-d7e1-4900-be00-e94165ae4a72", name: "Ansal University Gurgaon" },
    { id: "77bf0427-d4d0-434d-9be3-c2bcb77f50a8", name: "Antalya International University" },
    { id: "fb56463c-982f-4dd1-90c3-70be352d32ce", name: "Antelope Valley College" },
    { id: "a3e4b01f-905c-409e-a83f-87e577f87977", name: "Antelope Valley Hospital" },
    { id: "730e463e-5f89-4485-b18d-c14f669dfb82", name: "Anthos" },
    { id: "3535583c-3b09-4ac6-b236-e9b3a5684444", name: "Antioch College" },
    { id: "d0ae26df-e840-421b-a3ef-83d88e88a5f0", name: "Antioch University - Los Angeles" },
    { id: "352053d0-dd01-4ccf-8315-8275d2c148d3", name: "Antioch University - Midwest" },
    { id: "5f1262be-46c1-4ece-b485-a7a4abfb5100", name: "Antioch University - New England" },
    { id: "d3eed296-6d34-42ab-8e15-4b53f73ec0c1", name: "Antioch University - Santa Barbara" },
    { id: "f910f2b0-697b-4553-bceb-813393425e55", name: "Antioch University - Seattle" },
    { id: "0f47bc89-d4c9-4a2c-9feb-245a49dae6d7", name: "Antonine University" },
    { id: "35aed1f1-aea0-4b10-b851-af5a3e5a2f17", name: "Antonio De Nebrija University" },
    { id: "75b9ac7c-f85b-47ab-9b2f-7fea9e654138", name: "Antrans Clean" },
    { id: "91a3b57f-6218-4a51-987d-b42f3942b1b8", name: "Antwerp International Business School" },
    { id: "228429ae-56c5-4359-b7c6-dda4435b8253", name: "Antwerp Management School" },
    { id: "a414ca05-37b8-4d99-a2cb-2c77b06e8d8c", name: "Anytime Learning Pvt. Ltd. (ALPL) - Sunstone Business School" },
    { id: "7cbd4be5-2a24-4295-b0ba-3166b3f96e2d", name: "AOG Contractonderwijs BV" },
    { id: "bfe25397-424c-4316-9b09-360e2cc43981", name: "Aoyama Gakuin University" },
    { id: "bcf09729-da9f-48d7-af8c-0391f123f84d", name: "Apeejay School of Management" },
    { id: "a38b7b8a-9436-458e-bfbb-36e77f19e6ef", name: "Apeejay Stya University" },
    { id: "bb0a2de4-829a-4b33-9d36-5ab97d6e98d8", name: "Apex College" },
    { id: "a7551b09-ff4f-4f41-b2d8-ab933ab87dbb", name: "APICS" },
    { id: "7140f108-dbe2-4690-8efc-1d676fb4b99f", name: "Apiscopal Divinity School" },
    { id: "31b600f6-4699-478e-afc3-8d98ac92fc51", name: "APJ Abdul Kalam Technological University" },
    { id: "f63ee89d-9595-4f78-b0d0-6ccd6d361b77", name: "Apollo Hospitals Education Trust" },
    { id: "318ac019-89e7-4888-8603-c160526cef1c", name: "Appalachian State University" },
    { id: "9325eded-d03f-46d8-9147-1baa60f05f8a", name: "Applied Science University - Jordan" },
    { id: "012d8db1-e9b3-4352-80a1-cf2461495fdf", name: "APSCU" },
    { id: "323c4838-847e-4efe-9624-eb78fd06e620", name: "Aquinas College - Michigan" },
    { id: "5e20ce25-ddaa-4d61-b789-1ae6494822c0", name: "Aquinas College - Tennessee" },
    { id: "b655a60a-1c93-406a-b302-779fa7de7aa2", name: "Aquinas College Bookstore" },
    { id: "0e40af8c-c580-489e-a4e8-f5383a6a7c04", name: "Arab Academy for Science, Technology, and Maritime Transport" },
    { id: "f3feb478-b269-4841-978f-3a11446df337", name: "Arab Open University" },
    { id: "6d9d2282-b8f6-447b-872e-848d36227c5c", name: "Arab Open University - Jordan" },
    { id: "b4a26b52-e388-4bf2-8861-e41b57e04308", name: "Arab Open University - Lebanon" },
    { id: "6bbf4f15-e727-4027-aa04-be4c69d68749", name: "Arabian Society of Human Resource Management" },
    { id: "96177781-6a66-4ca4-ba1a-2cfe41c9c343", name: "Arapahoe Community College" },
    { id: "e6426eef-7fd4-4197-8be2-032f4e81d124", name: "Araullo University" },
    { id: "55ddb885-bd34-450f-b451-4ee1015bcc5d", name: "Aravali Institute of Management" },
    { id: "8c39a6f0-9164-40bf-aed1-44f7b1b0992c", name: "Arba Minch University" },
    { id: "e56d41de-6d2c-4d55-9a8b-2fbb9d9ca18c", name: "Arcada University of Applied Life" },
    { id: "9269eb3f-7d26-4379-a58e-1320686a451e", name: "Arcadia University" },
    { id: "70350be3-83ca-4a3c-8e90-e9f54dbd43fc", name: "Arcnam Nord / Pas De Calais" },
    { id: "ef634f04-47ef-43d7-a793-82e7c76e36b9", name: "Ardahan University" },
    { id: "d800b9d3-900a-45de-ab46-0c4ea97b9b01", name: "Argentine Catholic University" },
    { id: "d929476d-ed0f-4ebe-9b56-a8960b68edc0", name: "Argosy University" },
    { id: "bcf14d26-3524-4ed0-896e-de7c031b01a2", name: "Argosy University - Denver" },
    { id: "a66ab4a2-bfdb-4d75-8bbe-ba14078d0eaf", name: "Ariel University Center of Samaria" },
    { id: "e4fd5f57-92e1-4026-87fe-e0b26a248bf6", name: "Aristotle University of Thessaloniki" },
    { id: "54156cc5-f19a-4aae-a07d-74e3250f2d1e", name: "Arizona Christian University" },
    { id: "3ac61bc1-4c05-4803-93f1-58218000f532", name: "Arizona State University" },
    { id: "a7e48a28-df22-428e-b549-ae52fd222eb8", name: "Arizona State University - College of Public Service and Community Solutions" },
    { id: "ae989786-c1e6-4b8b-9693-f895f3ff43f3", name: "Arizona Summit Law School" },
    { id: "9e723940-229f-49a6-acde-c68e2309be52", name: "Arkansas Northeastern College" },
    { id: "9009edae-880e-4fd4-9d91-c5811caab7ec", name: "Arkansas State University" },
    { id: "a881eb69-7993-4bac-9171-e24ca47235f6", name: "Arkansas State University - Mountain Home" },
    { id: "bbb247eb-e1fc-44e0-a0bb-720de111f977", name: "Arkansas Tech University" },
    { id: "dc42bc3b-216a-4ed6-8ac4-faeed1cb1dc1", name: "Arlington Baptist College" },
    { id: "7d143d28-a082-4d2e-8543-89644cbc8325", name: "Armenian National Agrarian University" },
    { id: "10e4b7d2-ef77-4a54-980c-a497ff9b65cc", name: "Armstrong Atlantic State University" },
    { id: "2b39eece-78b8-4221-9be1-8d27446760b5", name: "Army Institute of Management" },
    { id: "b7ae8c3e-bf0b-41bc-a0c7-24d64d80ab86", name: "Army Management Staff College" },
    { id: "eaf7a915-ec79-4677-99b0-a56bca3fb621", name: "Army Public College of Management Sciences" },
    { id: "0e3572fa-0cdc-43c3-90e2-e864fad405e8", name: "Art Institue of Wisconsin" },
    { id: "357402d4-77cf-4b83-a93d-0c40bed10753", name: "Art Institute of California" },
    { id: "697b1550-fbf7-4410-8ce1-c74efed07115", name: "Art Institute of California - San Diego" },
    { id: "4e5de0e7-7c2b-423b-bcbd-dbc2789be4ec", name: "Art Institute of Chicago" },
    { id: "5a5715a8-c5d0-4c6d-87ad-e9c5e34d5532", name: "Art Institute of Colorado" },
    { id: "938a66bd-1f1a-4fc2-b0ea-ae1bfe9aa5d3", name: "Art Institute of Dallas" },
    { id: "372cd1b8-a6c8-4480-b739-4000b812c0ca", name: "Art Institute of Houston" },
    { id: "2e2218f1-3adb-492d-85f1-6a943a210084", name: "Art Institute of Indianapolis" },
    { id: "8d9a4150-dac5-4fc9-937e-3fe31cae0cd5", name: "Art Institute of Pittsburgh" },
    { id: "3fbb2d2f-0f36-4532-90e3-f5fa55eeaff6", name: "Art Institute of Portland" },
    { id: "7241f7f3-154e-49d2-b947-757e894fdbf8", name: "Art Institute of Vancouver" },
    { id: "43ae7435-82cb-4751-ae0d-a43dd169e519", name: "Art Institute of Washington - Dulles" },
    { id: "05c78f82-a25f-4e87-91a2-788aa222723f", name: "Artesis University - Antwerp" },
    { id: "81d71634-e086-41dd-9de1-e661d031e533", name: "Arthur Lok Jack Graduate School of Business" },
    { id: "8fb33d9f-9095-4ab6-82ee-467258a42ece", name: "Arts et Metiers Paris Tech" },
    { id: "41d01762-903f-45db-8933-76949eaeac4e", name: "Arts, Sciences & Technology - University in Lebanon" },
    { id: "20fcce41-e1b3-41b5-8759-3457cb2c0359", name: "Artsberg Enterprise Ltd" },
    { id: "a301fd11-0edd-47dd-9cbb-8731e953ce0e", name: "Asbury College" },
    { id: "34831b65-88fd-4137-9bf3-777b3bd0a1e7", name: "Asbury Seminary" },
    { id: "557f4cde-a6bf-4d67-af1a-155b4d729e0e", name: "ASE Bucharest University of Economics" },
    { id: "4f43d51b-2e89-495f-b1d5-6c817953892c", name: "Asebuss" },
    { id: "4b5caeb4-d6b4-4c0c-9910-3283a08d5249", name: "Ashbury College - Canada" },
    { id: "963aebdd-1127-4f3e-96c3-3c52844afd95", name: "Ashesi University College" },
    { id: "925a5dcd-63f7-4766-acbf-72c72c7dd171", name: "Asheville-Buncombe Technical Community College" },
    { id: "c1f5c8ea-390e-48c2-8f93-0c22802a18e0", name: "Ashford University" },
    { id: "3370ab96-9d1d-41a6-97e2-0a610feedf5b", name: "Ashland University" },
    { id: "a8ed70e1-222d-49d0-b03b-61cf89fd245c", name: "Ashoka University" },
    { id: "145db7af-73e9-47f5-a361-0e459c600cf5", name: "Ashridge Business School" },
    { id: "2281b309-a019-4ba2-9eb1-57f853b400a9", name: "Asia Competitiveness Institute" },
    { id: "3104a561-00ab-44c8-bde9-0a3df756462e", name: "Asia e university" },
    { id: "3dbff820-1893-41c3-8c8e-e60ffc7bf623", name: "Asia Pacific College" },
    { id: "8e6e4b8c-d20c-422c-b475-906e3c8e2371", name: "Asia Pacific Management Institute" },
    { id: "a89eabb6-a414-45ce-9129-7a82902c344c", name: "Asia Pacific University of Technology & Innovation (APU)" },
    { id: "65b649bb-a3d9-4ec1-ba56-9568f59d5980", name: "Asia School of Business" },
    { id: "08932f18-f79c-450a-8c47-12095c5cf491", name: "Asia University" },
    { id: "f1d4e6cf-6c6c-4adf-9bd0-ec39dcd1b17f", name: "Asia-Pacific Institute of Management" },
    { id: "63daeb91-b02e-4bd8-8eb0-1e50345af5e2", name: "Asia-Pacific International University" },
    { id: "7ce27f49-11f2-4a0e-8c80-50549482a8c8", name: "Asian College" },
    { id: "038074d7-e36d-46cd-beda-66b38c36d0d7", name: "Asian Institute of Management" },
    { id: "f931eda1-6984-4b5e-a84c-5db1777d1f0d", name: "Asian Institute of Technology" },
    { id: "79611bae-4b8e-4705-a75c-2b9a6ad1f152", name: "Asian Institute of Technology in Vietnam" },
    { id: "2993abbb-2c73-44fc-b13f-01fc4020a789", name: "Asian Management Institute" },
    { id: "1b370536-b249-4dac-9f89-05d0cfc2b4fa", name: "Asian School of Business" },
    { id: "10481347-dd52-4a43-800c-104a2b197ddc", name: "Asian School of Business Management" },
    { id: "8dcfaa99-0b70-44c4-8a92-d916b059c30a", name: "Asian University" },
    { id: "55d86eaa-1c5b-4b37-92e2-0a46ba192a37", name: "Asnuntuck Community College" },
    { id: "54a4fd0d-9eda-42fa-9134-fdecf453cebd", name: "Asociacion Ale, IAP" },
    { id: "f8d467c1-3e9e-4213-9122-19351cd0d198", name: "Asociacion Para La Cooperacion Educ" },
    { id: "c3b29f39-bde0-480c-b487-a4aa3ed6ca76", name: "ASP Katowice" },
    { id: "3ea26165-a159-4145-a726-d8b51a7f78b7", name: "Aspen University" },
    { id: "0b8e2910-12ab-4de0-a521-60bbc890dc94", name: "ASPIRE Human Capital Management Private Limited" },
    { id: "aaa3f340-b70c-4b3a-b83d-9ca15bb78229", name: "Aspire Training Team" },
    { id: "eef9b7c2-e2db-4391-8bbb-023f0d469644", name: "Assam Downtown University" },
    { id: "a8bd1d25-7379-4988-8792-76272809c736", name: "Assemblies of God Theo" },
    { id: "1b06f332-0233-45f5-8755-0d92fcf63d91", name: "Assiniboine Community College" },
    { id: "1bf47602-b955-400f-83ba-f9f849649418", name: "Assiut University" },
    { id: "964e9480-5067-4eb5-8b68-039028058491", name: "Associated Colleges of the Midwest" },
    { id: "1cf1dfd3-df26-4280-b6ca-298723624700", name: "Association des Etudiants HEC ULg" },
    { id: "e03c4d82-cb59-42b5-b335-5f9da6343c02", name: "Association for the Study of Higher Education" },
    { id: "dd3b4fbd-e904-4aa7-b33f-232570ea3ed1", name: "Association Leonard de Vinci (EMLV)" },
    { id: "8d185091-8984-4c93-aa56-121e156eb16e", name: "Association of Danish Engineers" },
    { id: "5b8031fb-f0fc-44da-94df-97251d0d3ff2", name: "Associação EGP-U.Porto" },
    { id: "c06c601f-5252-44ab-9e25-cde0ea743ad0", name: "Assumption College San Lorenzo" },
    { id: "40d5c2ef-8245-4320-828e-06ffcec2d03a", name: "Assumption Univ Central Lib" },
    { id: "dae156b5-20e6-4c5e-83a0-60617e7d39e1", name: "Assumption University of Thailand" },
    { id: "62e5625e-3241-4023-a8dc-7b994addc521", name: "Aston University" },
    { id: "cd715c4d-76e3-447a-8b0b-cd73e745369e", name: "ASTP - Association of European Science & Technology Transfer Professionals" },
    { id: "a39460d8-97f8-4573-ae1d-a864a5c9d376", name: "ASW Berufsakademie Saarland" },
    { id: "14d8e530-c517-4b2d-ab78-bd67048257eb", name: "Ateneo de Davao University" },
    { id: "489105a2-d90b-444c-b0e6-f2d63692484a", name: "Ateneo De Manila University" },
    { id: "293376a2-54c2-4ed1-b88c-40b3d5fde1f6", name: "Ateneo de Naga University" },
    { id: "33bc5103-27eb-483c-91d8-0655351a9b9a", name: "Ateneo de Zamboanga University" },
    { id: "d44e8f61-6559-4b06-b43d-3d5ce383635f", name: "Athabasca University" },
    { id: "de8f2831-e524-4647-a066-2628d7978b6d", name: "Athens Graduate School of Management" },
    { id: "2ca64368-7029-470d-ba12-0be28e67f565", name: "Athens Information Technology" },
    { id: "f1455b29-103e-4410-be2e-427de96dec8b", name: "Athens State College" },
    { id: "ffdd9407-0fd5-43ae-b9d3-ae804671d42a", name: "Athens State University" },
    { id: "7fe37f82-9d08-49f0-ba10-cd49c6d03813", name: "Athens Technical College" },
    { id: "0e9d2928-4a9b-4e49-97c5-cd57e6180fb2", name: "Athens University of Economics and Business (AUEB)" },
    { id: "c00e175b-0235-4ae6-99ae-428b671cfe6e", name: "Athlone Institute of Technology" },
    { id: "ce877e03-ae87-4e5c-a3e3-eed40e4f573a", name: "Atlanta Adventist Academy" },
    { id: "3cdb4431-f419-4e10-b5a1-6359001db5d4", name: "Atlanta Christian College" },
    { id: "510cbf2f-7f93-486a-876e-36d75c5354ff", name: "Atlanta Technical College" },
    { id: "34fa20ac-a788-4ca6-84e4-281f28858d07", name: "Atlantic International University" },
    { id: "a6cb3c7f-51a2-40f2-9410-6e3270749e85", name: "Atlantis University" },
    { id: "c789d90c-26b1-4d0c-879c-32cbf200ebf9", name: "Atmiya Institute of Technology and Science" },
    { id: "344f5ae6-39cf-4a19-b9d9-8760b49cce32", name: "Auburn University" },
    { id: "f4a34c2b-d316-4e98-bfb3-7019d11c2e67", name: "Auburn University - Executive MBA" },
    { id: "f571ff8f-757d-4be9-909e-f99b676de222", name: "Auburn University - MBA Program" },
    { id: "edafe20d-f916-45b0-9b1d-467a1bc834ab", name: "Auburn University at Montgomery" },
    { id: "7a7ae44b-e354-44e7-8aa2-936b2e2ddb8f", name: "Auckland Institute of Studies New Zealand" },
    { id: "7383cb53-1c23-4d73-a6c2-4056f20ef097", name: "Auckland University of Technology" },
    { id: "5f563931-df05-4f3f-b3dd-5511f399ce4b", name: "AUDENCIA Nantes School of Management" },
    { id: "56dfcc66-fcec-4cb9-b559-619eddcc4863", name: "Audyogik Shikshan Mandal (ASM)" },
    { id: "51e5ba59-1efc-43df-9c8f-d95bfab28d3b", name: "Augsburg University" },
    { id: "05a9b283-edbf-4f5f-b23d-e22daaadde39", name: "Augsburg University of Applied Sciences" },
    { id: "1ac34397-bf04-433f-b40f-411c745f6eff", name: "Augusta State University" },
    { id: "596a75e6-4fdc-420b-baa1-c7aa00dfbc8f", name: "Augusta University" },
    { id: "b3cb0876-bc63-4fbd-9495-c363ed5ca686", name: "Augustana College" },
    { id: "e7be84b1-85ac-419f-a65b-28ee6839330c", name: "Aukland Institute of Technology AUT" },
    { id: "008267cd-745a-42b1-bc0e-975922df12ed", name: "Auraria Higher Education Center" },
    { id: "32dfb636-9dd0-4b6f-8560-7c388f5c747e", name: "Aureus University School of Medicine" },
    { id: "6abf74b3-0aa2-415e-9746-f98ed54e97da", name: "Auro University of Hospitality and Management" },
    { id: "1b2f5b63-5f04-46af-ab6e-72325d9a3587", name: "Aurora College - Aurora Campus" },
    { id: "fb2fa1c0-a818-4e6b-9e53-8aa2fdb91bab", name: "Aurora College Library - Yellowknife Campus" },
    { id: "7b74e57a-3a6e-4e33-a487-d1a4efffc4fd", name: "Aurora Technological and Research Institute" },
    { id: "84501af9-84fe-4b9d-b72d-79cc9b6c54b9", name: "Aurora University" },
    { id: "7261dcbf-329b-42b7-82ba-00604b0c613d", name: "Aurora University - Wisconsin" },
    { id: "d60c0875-83e6-4b98-8f38-e8ef7ebfa4c7", name: "Aurora's Business School (ABS)" },
    { id: "7f29fdab-2315-42c8-ace6-729779834850", name: "AusGrid" },
    { id: "98c6bd2e-2eca-4fbc-8905-61071e537fd0", name: "Austin College" },
    { id: "939225bb-0054-47a2-897d-53319266a861", name: "Austin Community College" },
    { id: "1eb09368-5071-45da-825a-0c812445f9f5", name: "Austin Peay State University" },
    { id: "59a4c510-ecbb-4516-97f9-12bea8e14dd7", name: "Australia and New Zealand School of Government" },
    { id: "fa9c9aa8-8987-498f-94d0-448e3c70ec19", name: "Australia Business Development Centre" },
    { id: "48422054-aa11-4219-8149-048270b8cdbc", name: "Australian Adelaide International College" },
    { id: "b37f1139-b848-4471-a84d-8f59c278b470", name: "Australian Business Deans Council" },
    { id: "9b4e27ff-3fd5-4163-ab18-f31530660a0c", name: "Australian Catholic University" },
    { id: "f9011ffa-8094-46d4-a87d-5e8d7e42f4cd", name: "Australian College of Applied Psychology" },
    { id: "b99729df-b14f-4b2e-8453-b1d3adf9a337", name: "Australian College of Business Technology" },
    { id: "7d207384-4e14-45d6-b407-499d5d49c9cf", name: "Australian College of Kuwait" },
    { id: "2b9a38f4-a1b1-4064-a1d1-6a63d6439b73", name: "Australian Film Television and Radio School" },
    { id: "794a28b1-d0fd-41d2-a0ce-bf52bc13b8db", name: "Australian Graduate School of Management" },
    { id: "337f3713-8f4c-495f-8b27-a43e9ac02a6d", name: "Australian Institute of Business" },
    { id: "50d87903-dc47-40b1-b0d1-47a7c9149078", name: "Australian Institute of Management" },
    { id: "fd544e97-7c94-464e-a6bb-145b557d9291", name: "Australian Institute of Music" },
    { id: "d8481bfd-a208-4eda-a25e-4051d91cee7b", name: "Australian Institute of Police Management" },
    { id: "edd30f59-9ade-4a4b-a0f7-90d6db03ebc7", name: "Australian Institute of Professional Education" },
    { id: "ff6c67cf-1c63-48ea-b63a-d3a9c6132522", name: "Australian Institute of Professional Education Pty Ltd." },
    { id: "8bb29f30-14b2-4192-aa97-e1969e110775", name: "Australian International Hotel School" },
    { id: "536efe61-033f-4477-992b-678962eb47b0", name: "Australian International School, Sharjah" },
    { id: "9fecce29-6f19-430f-9cda-21040ca1c90c", name: "Australian Music College Melbourne - COLLARTS" },
    { id: "b1fa60c4-06fb-491e-93d5-bb493ca8d06b", name: "Australian National University" },
    { id: "1b4d165d-9152-430f-9baa-85f8f869172f", name: "Australian School of Business" },
    { id: "76f54088-2bd7-4524-ac9b-66bf437b5ee9", name: "Austrian Marketing University of Applied Sciences" },
    { id: "7cf8b5e6-ce37-4469-9acd-90b3acb2cbc3", name: "AUT City Campus Univ Bookshop" },
    { id: "cfa5a96d-711d-4ab9-ad1b-5810f0a9641b", name: "Autonomous University of Peru" },
    { id: "882f720f-f7e5-48e4-914e-5523a3724809", name: "Autonomous University of Queretaro" },
    { id: "f7d7cc5a-b2f6-4d88-a950-af8b4ac33ad9", name: "Avans Hogeschool" },
    { id: "401cfb82-7314-4b35-a59d-123646c4a10a", name: "Avans University of Applied Science" },
    { id: "6eeb5863-156a-4ba3-8beb-33eef0b0a279", name: "Ave Maria University" },
    { id: "f0be73c7-64d0-4770-af32-4577e6b731cc", name: "Averett University" },
    { id: "b117fd8d-dbcb-479c-9041-a9abedf4fd63", name: "Averroes Developpement" },
    { id: "6a7f193c-2629-4036-8902-e062ca6769e8", name: "Avicenne Private Business School" },
    { id: "bbcc7f66-8ee1-46ec-939f-e209ac169e4b", name: "Avila College Bookstore" },
    { id: "d4b0adc6-c07e-42b4-8716-9c82eec19c24", name: "Avila University" },
    { id: "51a5eb46-9403-4d38-8ae4-3559549bbba0", name: "Avinashilingam Institute for Home Science & Higher Education for Women" },
    { id: "c00ee338-abb4-4a1e-b1b7-0fc4d6acbc84", name: "Avondale" },
    { id: "2de081c0-bb5e-4b2e-a4e4-e4b75f4876f6", name: "AVT Business School" },
    { id: "f1c35448-9ba3-4212-a50d-d89bf013c4e5", name: "Axa Gie" },
    { id: "c6a19ff2-9225-43da-a2a4-a9505752d745", name: "Azerbaijan Diplomatic Academy" },
    { id: "eb43c131-bf02-42d9-af43-b005f10cf49b", name: "Azerbaijan State Oil and Industry University" },
    { id: "0229cf4d-52d1-4b27-8b26-f81c954714d2", name: "Azerbaijan State University of Economics" },
    { id: "4d368d07-b940-48b8-b226-f6f20eeddee4", name: "Azerbaijan Technical University" },
    { id: "ed3fc325-48d9-4943-8ac5-eebb8d85dcae", name: "Azim Premji University" },
    { id: "cf13ce35-d183-4682-98b8-004eda3fb5b9", name: "Aztec Shops" },
    { id: "ebd608a4-0455-4c45-bf7e-2d8dd8edfc78", name: "Azusa Pacific University" },
    { id: "a98bdfb7-5e17-4d3c-b4d7-41e13e362104", name: "B. S. Abdur Rahman University" },
    { id: "12367b24-11ef-447b-8ea8-d190cdcfad02", name: "B.J.S. Rampuria Jain College" },
    { id: "9abb24cf-5f16-4b95-bc75-6233847ad98a", name: "BA School of Business and Finance" },
    { id: "ead0582e-a0a4-4f65-bdac-64d3d543b90a", name: "Baao Community College" },
    { id: "32e38132-b325-4ee0-b8a1-bea4b90d7bce", name: "Baba Ghulam Shah Badshah University" },
    { id: "c710a7a2-5f39-4db1-b723-cdda584466da", name: "Babson College" },
    { id: "1c4f361b-fba2-404b-a719-f4e3bd79c5ee", name: "Babson College - CIMS" },
    { id: "19d5cf82-f839-4240-825d-2bedcb810d1c", name: "Babson Executive Conference Center" },
    { id: "894969f0-2001-4eab-92ae-837ab567d4c7", name: "Babu Banarasi das University" },
    { id: "c7a977b6-c5ea-44ea-8a97-b16dd11889ee", name: "Bacon Academy" },
    { id: "9ffa03dd-828f-4e38-8e56-6863f45b12ec", name: "Bacone College" },
    { id: "8b157eee-0556-408b-8ead-2a38429a690e", name: "Baden-Württemberg Cooperative State University" },
    { id: "d6115018-d902-4c2b-8798-288786ec3629", name: "Baekseok Arts University" },
    { id: "0d613569-a0e1-48e8-b1ed-90aa97065fb9", name: "Bahauddin Zakariya University" },
    { id: "87f578a4-3195-41a1-84d8-1aa8bbc6276f", name: "Bahcesehir University" },
    { id: "f8ae250b-e895-421c-bc2e-868976356b2d", name: "Bahir Dar University" },
    { id: "e8296f69-362a-41f4-bfce-e09cc9d42a05", name: "Bahrain Institute of Banking and Finance" },
    { id: "240d6bd5-d30a-4777-9673-888e747c7c73", name: "Bahrain Polytechnic" },
    { id: "83d89e6a-5a4b-4bb2-b375-e81b8da15a2c", name: "Bahria University" },
    { id: "6f9518fe-744a-4242-9895-c0fa0c6992ef", name: "Bainbridge Graduate Institute" },
    { id: "2f84f10d-3aa0-4cf0-941d-ac2bcc32d464", name: "Baker College" },
    { id: "b1fc258e-36ef-48d6-bc10-f904e45a3299", name: "Baker College of Auburn Hills" },
    { id: "8f3ebdec-beb1-4981-98be-74526a2f87c8", name: "Baker College of Flint" },
    { id: "d3a040d4-49ac-4b51-b10a-169e7566bda6", name: "Baker College of Port Huron" },
    { id: "39c4418a-bfa8-476e-a2e0-1775f798613a", name: "Baker University" },
    { id: "4ab77c27-f4f1-4fc8-a17c-660a41f2fd6e", name: "Bakke Graduate University" },
    { id: "0f4f40ca-f680-4576-9245-cc4ae465819e", name: "Balaji Institute of Management Studies" },
    { id: "ec8bc8a9-a4c8-4f77-bae0-e4e598ae0c15", name: "Baldwin-Wallace College" },
    { id: "e752d1f1-a2df-40a5-8095-441d56918d71", name: "Ball State University" },
    { id: "43fc1313-ce10-4536-8875-9e2173cc17d2", name: "Ballajura Community College" },
    { id: "0985e7ea-f88e-4335-b755-85f5964bb60d", name: "Balochistan University of Information Technology and Management Sciences" },
    { id: "3e3b8653-4767-4174-aa92-270747b51526", name: "Baltic International Academy" },
    { id: "980d390b-4f30-4b41-8156-7d7f6300972a", name: "Baltic Management Institute" },
    { id: "1ffce2d2-cc3a-442b-a33f-235faab7228b", name: "Baltimore City Community College" },
    { id: "a6a12721-6d07-4836-9e4f-6db7b1d2133e", name: "Baltimore Hebrew College" },
    { id: "e22e16d4-df10-4090-b1c9-a9e5f8628713", name: "Baltimore International College" },
    { id: "ce1167c2-148d-4ba1-b7ec-7c8fc2d8cb3a", name: "Banaras Hindu University" },
    { id: "46a23878-1363-46f3-a52a-69ff1ff897a7", name: "Banarsidas Chandiwala Institute of Professional Studies" },
    { id: "abf5d6b8-80ee-43a4-85ee-65635ec58af2", name: "Banff Centre" },
    { id: "7cddb868-2417-4d6d-b031-3fc75082d30f", name: "Bangalore Management Academy" },
    { id: "ce96949b-b344-405c-89ae-08010a66e02c", name: "Bangalore University" },
    { id: "5dd98510-ec9f-45e2-8903-5de8b443579b", name: "Bangkok University" },
    { id: "f35b3a38-184a-43ac-9abc-6088167bd5ee", name: "Bangladesh Institute of Capital Market" },
    { id: "5ef1fa37-7ce4-4d4b-beef-aac4a45a1400", name: "Bangladesh Open University" },
    { id: "2df12779-0780-4909-8328-7901beb3452c", name: "Bangladesh University of Engineering and Technology" },
    { id: "67bc7139-58ba-40f6-b946-e3e4044ec2ac", name: "Bangor University" },
    { id: "91aa0127-a137-45bc-bc94-3cbeaf10c072", name: "Bank Boston Corporate University Brazil" },
    { id: "abc7928d-17fe-4281-944f-52da9a38958e", name: "Bank Street College of Education Library" },
    { id: "a0a7a3a1-badc-4e48-a9f3-6387c1ffdb4b", name: "Banking Academy of Vietnam" },
    { id: "9cf46a96-e067-473a-93d1-4c66a6846483", name: "Banking Institution of Higher Education" },
    { id: "b59270a2-9622-4352-890f-50ee49d1979b", name: "Banking University of Ho Chi Minh City" },
    { id: "aa024404-94da-4c5a-9ba1-1daac5462e53", name: "Bar-Ilan University" },
    { id: "fa88a511-6657-4f9c-a937-bfca68c3c1e9", name: "Barcelona Culinary Hub" },
    { id: "b13b9bdd-8893-4284-9ec0-08bef148c691", name: "Barcelona Graduate School of Economics" },
    { id: "92071e82-37a1-4d5c-a4d2-a7e364aa6d3b", name: "Barcelona Institute for Global Health" },
    { id: "c053eef1-a0bc-4465-a85f-75b0a58fc9c8", name: "Barcelona Management Institute" },
    { id: "206b5baf-f03a-44a7-9dcc-9b69392a3cad", name: "Barcelona Technology School" },
    { id: "6af492a3-b2ad-487e-ba63-08dece64462d", name: "Barcelona University College" },
    { id: "85e25286-8a61-41df-b014-c3612875b9c5", name: "Barclay College" },
    { id: "0878c29c-63c1-44e1-9501-59842c4fdda5", name: "Bard College" },
    { id: "69712902-cf10-4c78-9351-28d23a3fdaea", name: "Barkatullah University" },
    { id: "a4a28e5e-78e3-486e-b2ea-190bf8e72af3", name: "Barna Business School" },
    { id: "6c996daa-ea93-45ee-84df-dec3fbbecc53", name: "Barnard College" },
    { id: "122de16b-4626-4564-a8c2-644788b8f9a1", name: "Barnes & Noble" },
    { id: "3013d5ab-42de-4a01-91ce-4a2f68870089", name: "Barnes & Noble College" },
    { id: "7cb1cb66-b874-4468-a59a-444040a7248e", name: "Barnes & Nobles" },
    { id: "09faed38-3de1-41e5-a776-82777d71d470", name: "Barnes Noble Store" },
    { id: "57b59d4a-5310-492b-b1d6-0f9ba3cc83b1", name: "Barrington School District 220" },
    { id: "c0ce4573-9dbc-473c-af13-950de0e0e7fe", name: "Barry University" },
    { id: "b4bb6459-32f5-42a9-8576-230ed3739372", name: "Barton College" },
    { id: "6a4ba02e-688a-4ef1-a5d4-bbe8b36735d2", name: "Basel School of Business" },
    { id: "b8a2c36e-08dc-418d-bb66-52791353e9cd", name: "Basis International University" },
    { id: "ac2d8184-e3af-4d99-b226-0da7e4395117", name: "Baskent University" },
    { id: "7dbaea51-0677-4a85-811e-e4480bc30509", name: "Basque Culinary Center" },
    { id: "be9a0872-5953-4ed0-b735-9f6536576810", name: "Batam International University" },
    { id: "c76062d5-b7be-4700-b042-5b0125a4e5f3", name: "Bates College" },
    { id: "3e56a766-20d2-4064-bbb9-d6c1aeba69f7", name: "Bates Technical College" },
    { id: "fa3f7f01-0809-4fa8-a74b-3be1b797758a", name: "Bath Spa University" },
    { id: "f937a339-ce1c-49fb-8b6f-a2fb72bbc838", name: "BAU International University" },
    { id: "c1b3a3e2-da73-4c28-838d-9b2c30ed973e", name: "Bauder College" },
    { id: "19700d72-383c-4df0-a89f-607c35a1fca3", name: "Bauhaus University - Weimar" },
    { id: "d8adbab6-5fdc-47c7-a9a8-b1657864343f", name: "Bauman Moscow State Technical University" },
    { id: "95668783-ddb5-40c4-a263-b1a5303ec710", name: "Baxter Institute" },
    { id: "fca33129-bf3c-4087-a071-44df5ca6de2b", name: "Bay Path University" },
    { id: "3cbcb1e7-f8f9-4aa4-a7cc-ded0d560f8d6", name: "Bay State College" },
    { id: "38889f23-523d-4ef7-ac89-550cae079ac7", name: "Bayamon Central University" },
    { id: "8bd031a9-eb68-441a-9db6-03ecd77b43e6", name: "Baylor Health Care System" },
    { id: "80cec41a-1618-40be-80ac-fe9e5dc29474", name: "Baylor University" },
    { id: "a984ffb4-c084-41dc-b219-bf209364ffe8", name: "BBD University" },
    { id: "a527f87f-9169-4231-bf5c-d9d008060274", name: "BC Institute for Studies in Intl Trade" },
    { id: "befa2990-0ebb-4c93-9dd8-92a646c1133a", name: "BDC Impact" },
    { id: "d3a466a2-9555-4c5c-8990-a02df00f0373", name: "BDS Institute Of Management" },
    { id: "0d4fb7fc-05bb-41e8-a096-243fa21502fc", name: "Beacon College" },
    { id: "816855aa-f8f7-45d7-87d9-4ca68e95e6e1", name: "Beaconhouse National University" },
    { id: "aaa9b6df-9800-48ae-82c4-bcc8f6ba4782", name: "Beaumont Health System" },
    { id: "fcae1e9d-7b06-4d7c-b959-6ef874ccbcd3", name: "Beaumont School" },
    { id: "920f335d-784b-4430-a456-f7f8ac93490d", name: "Beaver Country Day School" },
    { id: "b2ee6d10-afab-4d88-8960-eeeea2322d86", name: "Becker College" },
    { id: "fd963b56-e5a1-4ea0-936d-b31271d13ed0", name: "Bedford School District" },
    { id: "4960851f-b041-4984-8efd-04558633feed", name: "Beeckestijn Business School" },
    { id: "10db4bfd-4403-48d1-b2c2-324d45519c91", name: "Beihang University" },
    { id: "e105b92c-2c8d-40a2-8342-85bb18399e8c", name: "Beijing Dance Academy" },
    { id: "41bed78e-e439-425d-b011-095562095657", name: "Beijing Foreign Studies University" },
    { id: "b8fbba79-d0e5-4e96-bafa-f7e496455161", name: "Beijing Institute of Technology" },
    { id: "fe7ea17b-0ee1-4957-a0d8-b3d8f4c0b604", name: "Beijing International MBA (BiMBA)" },
    { id: "f8799881-cdeb-47ce-a229-a722dc55b2b3", name: "Beijing International Studies University" },
    { id: "e8eeae1a-7b0c-4058-99ae-5df3ee9982aa", name: "Beijing Jiaotong University" },
    { id: "62e47de6-a499-4134-b77b-326a4567bb34", name: "Beijing Normal University" },
    { id: "ad993281-2b35-4b61-bf9f-0e21c105d157", name: "Beijing Royal School" },
    { id: "65b5b0eb-173a-40d8-8515-f8f11db535d2", name: "Beijing Technology and Business University" },
    { id: "c4bb4bde-b1c9-4838-b2bd-1d6db01dedd9", name: "Beijing Union University" },
    { id: "d17af657-3e2d-4c60-9112-05c2a07fa8e1", name: "Beijing University of Chemical Technology" },
    { id: "ca110e9a-6281-4d7d-98f3-ab20e7acb772", name: "Beijing University of Posts and Telecommunications" },
    { id: "86745f6e-a7bd-469c-8619-3a7b2015f261", name: "Beijing University of Technology" },
    { id: "33e3a456-d936-4ef4-92cc-34dd74618925", name: "Beijing Wuzi University" },
    { id: "a8717abb-6ba5-40f3-8874-e882f4db0793", name: "Beit Berl College" },
    { id: "5b1204ea-2963-4ef6-9ecc-cad739199df8", name: "Belarusian State University" },
    { id: "ea68dbbe-e5f3-4dfa-a2d2-1b3d7dacfa81", name: "Belfast College of Technology" },
    { id: "a24b465a-56ca-4218-aff8-99c550fc700d", name: "Belgrade Business School" },
    { id: "b0ab0c15-6696-405e-af65-fa4a583fb605", name: "Belgravia College" },
    { id: "235fb4f7-2ad3-4d5b-b8df-182879a1af1d", name: "Belhaven University" },
    { id: "4bf6a354-c4a1-419f-bc11-a3f10f80d60c", name: "Bell Multicultural School" },
    { id: "7867ed31-d57a-47e7-afc2-49b95353a0c3", name: "Bella Vista College Prepatory School" },
    { id: "8cbd8746-be91-45de-85c7-7f3ac0374a08", name: "Bellarmine University" },
    { id: "0919ad4e-1599-49d4-bb01-ea140b949d98", name: "Bellevue College" },
    { id: "1d747971-695f-4fd4-9258-6909b10abb90", name: "Bellevue University" },
    { id: "9ffcf06a-1f16-4503-8ff9-c3cd1060ec84", name: "Bellevue University Library" },
    { id: "dbb149cd-b468-40d5-850e-24a29be4e0f5", name: "Bellingham Technical College" },
    { id: "438dbb60-fac3-4dc5-8946-876f23b1746f", name: "Belmont Abbey College" },
    { id: "9a16d603-c207-4d41-bc1b-23ff0cd7eb60", name: "Belmont Community Adult School" },
    { id: "3b3f6a96-d2ee-4771-96f7-7993d615072e", name: "Belmont University" },
    { id: "65401a5c-4af6-4607-af81-6193bfe6a0ca", name: "Beloit College" },
    { id: "1e10bdbd-1351-4c55-8301-d0bd53f70a65", name: "Bemidji State University" },
    { id: "f2e4c036-da4d-4182-8e50-e021173fca09", name: "Ben Gurion University of the Negev" },
    { id: "5811be9a-a854-499c-9720-b6154462c5af", name: "Benedict College" },
    { id: "8371476e-29af-4c53-b058-7dee7aaec4d8", name: "Benedict School" },
    { id: "ddaf570a-9a4a-4ad6-97fb-d7572ed5ceaf", name: "Benedictine Academy" },
    { id: "be60871c-2835-4669-807b-7d082bb6dff0", name: "Benedictine College" },
    { id: "022fc3d5-cb42-478f-9f90-68ec4f87e368", name: "Benedictine University" },
    { id: "9f8fe8e8-d729-4e7e-9219-0f8d6c790147", name: "Benedictine University - Springfield" },
    { id: "8db6dfe0-5402-4a7f-903a-a9bc5b5808e0", name: "Benemerita Universidad Autonoma de Puebla" },
    { id: "e4b9a6df-af72-4fd3-936a-5fee34eed555", name: "Bengal Institute of Technologies and Management" },
    { id: "7a550476-b10e-428a-92f3-cd5f0ed8c6bd", name: "Benjamin Franklin International School" },
    { id: "1d7ec8ea-479a-45fe-9732-de8a2da321e4", name: "Bennett College" },
    { id: "424c85d4-79c8-415a-96f1-1826218e4e38", name: "Bennett University" },
    { id: "a8262aa4-6047-4128-97b8-18975053c1c3", name: "Bennett, Coleman & Company Ltd (BCCL)" },
    { id: "a686ca83-d215-4c10-b48e-8a59401ca2de", name: "Bennington College" },
    { id: "de3c09cd-e4b2-4c53-9fb8-4ec42c39fd7f", name: "Bentley University" },
    { id: "b21bed65-a358-4afb-a400-3fa091d162c3", name: "Berea College" },
    { id: "32cbfbe1-c05f-4a82-a645-131a86b7f4dc", name: "Bergen Community College" },
    { id: "acfda37b-bba2-4478-afb5-a3a86992e804", name: "Bergen County Technical Schools" },
    { id: "20f9f6e6-5e07-4a82-9bdd-098c3c6a11d0", name: "Bergen University College" },
    { id: "47e5561c-1dee-45a4-84d3-220e9eef8b18", name: "Bergische Universitat Wuppertal" },
    { id: "06ca82b1-3b24-4ba4-a0c8-d2ff9b36d84a", name: "Berjaya University College of Hospitality" },
    { id: "1991b390-74d1-477e-8083-65043bd6f108", name: "Berkeley College" },
    { id: "598c13e0-953f-4c44-b55f-4c449483e084", name: "Berklee College of Music" },
    { id: "da6e2cab-5cd6-4e6b-9d4d-f1ab43126694", name: "Berkshire College of Agriculture" },
    { id: "a4f075cf-edbc-42d4-a947-69d641e63a01", name: "Berkshire Consultancy LTD" },
    { id: "7d241e78-73d7-4135-b993-64fbc657a447", name: "Berlin International University of Applied Sciences" },
    { id: "d3c0e9c7-d855-4ca4-8d25-8038f9626aed", name: "Berlin School of Business and Innovation GmbH" },
    { id: "839a1bcf-4bd1-40a0-b182-89f0f360363b", name: "Berlin School of Creative Leadership" },
    { id: "42454a8b-b00e-4a98-9c2b-595af98c6122", name: "Berlin School of Economics and Law" },
    { id: "85d81ac5-ce07-446a-aaad-cdfbb601b454", name: "Bermuda College" },
    { id: "46715ca1-a792-4af1-ae09-184aaf014858", name: "Bern University of Applied Sciences" },
    { id: "df2d3c13-64eb-4d18-967f-a9081f55ab4f", name: "Berry College" },
    { id: "3e9281b6-059c-44b4-81f3-d5dd8752fb8c", name: "Berufsakademie - Ravensburg" },
    { id: "fa820580-c253-49e6-9765-37180a20ce5a", name: "Berufsakademie - Stuttgart" },
    { id: "058a66aa-c589-4c48-8dd9-a42b360739c2", name: "Berufsakademie Heidenheim" },
    { id: "77424356-bc1b-465f-a608-484533859b56", name: "Berufskolleg Wirtschaft Und Verwaltung Mit Wirtschaftsgymnasium" },
    { id: "ef198532-dd17-4ce4-b7dc-381d72131163", name: "BET's Global Business School" },
    { id: "9549d70f-2314-4ba1-a438-8ea74d2f8527", name: "Beta Education" },
    { id: "49801039-6d23-44d4-adc4-c90a8e6b4de7", name: "Bethany College" },
    { id: "8f151274-5378-44ca-863c-33546de33cad", name: "Bethany College - California" },
    { id: "b8354798-9efa-4f45-8d53-9964d8e90157", name: "Bethany Lutheran College" },
    { id: "c29ddf80-62c9-408a-a19d-194cd5e13755", name: "Bethel College" },
    { id: "e47e195e-8501-4b14-ad5c-18cb709fca5c", name: "Bethel University" },
    { id: "287437a1-eadf-48b5-a7c6-8d0523843880", name: "Bethlehem University" },
    { id: "8a823a69-75ca-4fb5-9688-0fe1d8dd3e20", name: "Bethune-Cookman College" },
    { id: "51333976-79cf-40a6-811a-841529c8cc2a", name: "Bethune-Cookman University" },
    { id: "edd6598a-a960-4330-96d7-b1e4b4d97e91", name: "Beulah Heights University" },
    { id: "87de30c1-0b07-49ef-9851-cc748cafcdd9", name: "Beulah Hieghts University" },
    { id: "a104d908-b6aa-4853-bf76-fd0457c4ed75", name: "Beuth Hochschule fur Technik Berlin" },
    { id: "781d0537-4605-4462-9088-0c419feec330", name: "Beykent University" },
    { id: "db7296b4-afa0-4280-bd43-84764f5721e4", name: "Bezalel" },
    { id: "1b8c5d23-2306-4eab-8496-226c91d0a162", name: "Bhaktivedanta College" },
    { id: "922d2112-86b1-40c9-aa2f-37f4248e22cf", name: "Bharathiar University" },
    { id: "4f6be6c8-c1d3-4d92-b67f-5eb9a206457e", name: "Bharathidasan Institute of Management - Trichy (BIM Trichy)" },
    { id: "554b531b-ddaf-4c21-a2fb-fff6907edb9e", name: "Bharathidasan University" },
    { id: "a95e4f63-bfe3-4dfc-9596-a7b13e7e6e4c", name: "Bharati Vidyapeeth Institute of Management and Research" },
    { id: "1cda0afd-567f-4014-af3c-b2dbba9c90bb", name: "Bharati Vidyapeeth University" },
    { id: "1eb26391-346f-4002-a35c-203c43d8902c", name: "Bharti Vidyapeeth Institute of Management Studies & Research" },
    { id: "f9252f5c-21f0-4ce9-a719-a53186edc760", name: "Bhavan's Center for Interdisciplinary Studies" },
    { id: "092f16b0-4fe1-4d3b-89b8-615399a76c69", name: "Bhavan's Royal Institute of Management" },
    { id: "f71f6f11-a7e7-4adc-b627-eab425427333", name: "Bhavan's Usha & Lakshmi Mittal Institute of Management" },
    { id: "cf7ebde0-cd44-429a-8a4e-e4bf4bfa6330", name: "BI International" },
    { id: "42778c32-ef17-4904-b4cf-152fa3323e45", name: "BI Norwegian School of Management" },
    { id: "b18c121b-a83f-4077-a534-414cee7a9a45", name: "BIEM e.V." },
    { id: "1dab5905-3a64-4bd9-a5ba-f8bbb8c3b0d2", name: "Bifrost University" },
    { id: "a1a65a2b-e2b6-43f0-aa86-9a2630a70fef", name: "Big Fish France" },
    { id: "00d3effd-3731-47d0-a6d1-bd848799d88c", name: "Bihar Institute of Public Administration & Rural Development" },
    { id: "d739e058-223c-4032-9f57-afb1cba4f1cc", name: "Biju Patnaik University of Technology" },
    { id: "e5dd26e4-4d70-4967-82f3-05b5650ac75a", name: "Bilecik Üniversitesi" },
    { id: "d3f2d365-287f-4aaf-906a-cbde97f0151b", name: "Bilkent University" },
    { id: "255b080e-96ab-4476-b3eb-1bcdb8d8abc5", name: "Billings Fund" },
    { id: "95f65ca7-1559-40a7-bfbe-2f65d4ad157e", name: "Binary University College of Management & Entrepreneurship" },
    { id: "1b7954d7-18c7-4f08-b961-d31181e2f4f5", name: "Bindura University of Science Education" },
    { id: "f786b5de-b62f-42fb-9468-c2fa6f70bcd9", name: "Binghamton University" },
    { id: "95de38e6-9139-4642-aabb-1d48e17a8f84", name: "Bingöl Üniversitesi" },
    { id: "488662ec-beb1-461b-b3fc-53dfee9ff8cd", name: "Binus University (Universitas Bina Nusantara)" },
    { id: "0e25e072-3e3c-44de-b371-b72c23881867", name: "Biola University" },
    { id: "8c7acafe-c3a1-48c9-af5e-1ef74b09802f", name: "BIR Training Center" },
    { id: "555572f5-81c1-471e-a8e0-657b8a0c5576", name: "Birkbeck University of London" },
    { id: "b35760f6-e6d5-419f-9d78-8ae9e5790a77", name: "Birkbrunn Studenthaus & Akademie" },
    { id: "d58fc6eb-8419-4fdc-ae5c-ec409ba3c6ea", name: "Birla Institute of Management Technology (BIMTECH)" },
    { id: "c249a47e-58ab-43aa-b9e1-ec42fc0117c1", name: "Birla Institute of Technology & Science" },
    { id: "0889f308-dae6-4f44-ba4e-56603a3d9b1c", name: "Birla Institute of Technology & Science (BITS) - Pilani" },
    { id: "b81685d0-9605-41e1-97f1-69aa0a992ec8", name: "Birmingham City University" },
    { id: "b4912b72-42ca-4f90-8c14-72b1740f8cf6", name: "Birmingham College of Food" },
    { id: "af8b33a8-bf05-46da-aad4-4b803dfc5f79", name: "Birmingham Southern College" },
    { id: "731142bf-e204-4bb3-9e90-4fca9df85568", name: "Birzeit University" },
    { id: "a7876906-f929-4ad5-9b9e-4b0b1a9b0699", name: "Bishop Burton College" },
    { id: "28848220-2f8a-40a9-bf2c-d5383a4e5089", name: "Bishop Justus School" },
    { id: "276fd902-54bf-4bfb-9ee7-c6ac2b417449", name: "Bishops University" },
    { id: "f8abb70d-121d-49be-a176-ded3d5930280", name: "Bisk Education" },
    { id: "05ba9fc3-8aec-419d-994a-4eff2c8cd284", name: "Bismarck State College" },
    { id: "5ed636df-a81d-4a96-9705-3255045dd93f", name: "BITS Law School" },
    { id: "e308a850-0a6c-4479-afb4-ebb7484a9aea", name: "BITS School of Management (BITSOM)" },
    { id: "1bf8f036-4044-480c-8907-6dd24ae8951b", name: "Black Hawk College" },
    { id: "78d68a7c-07e7-4981-9415-aff868ee4103", name: "Black Hills State University" },
    { id: "c1654ad0-a2df-4d30-bde2-b35f75077820", name: "Blackburn College" },
    { id: "01436bff-e280-495f-96d9-4e7c5d37ecaa", name: "Blackhawk Technical College" },
    { id: "2c351238-002f-4cfc-a698-510f44f42165", name: "Blackpool and The Fylde College" },
    { id: "9cbbdab3-d75a-4ac5-8f68-64f6128f36d8", name: "BlackRock Inc." },
    { id: "b04f0c23-d55f-4060-a3d9-01ce73f6a05d", name: "Blackwell" },
    { id: "5b12c474-25b0-489a-8c55-1d55658af015", name: "Blekinge Institute of Technology" },
    { id: "0581c2c7-089f-4d00-96ae-1c3c8822af89", name: "Blended Pty Ltd" },
    { id: "fd5178b5-46c0-4351-b535-9b7a89d75bb8", name: "Blessed John Xxiii Seminary" },
    { id: "7a9de016-97ba-4ff3-a37b-d0519419f6da", name: "Bloomfield College" },
    { id: "8a435081-7257-4935-9d49-c72d504bf6d8", name: "Bloomsburg University" },
    { id: "12ecf037-f293-440f-9f94-957c1a05f10a", name: "Blue Hill College" },
    { id: "cc886b75-b4f8-4795-aacc-0d6a7dad0d90", name: "Blue Mountain College" },
    { id: "eb21f941-349d-4aaa-9095-51e82297ae43", name: "Blue Mountains International Hotel Management School" },
    { id: "e805a313-2b36-4fcf-b03c-879cf2517e04", name: "Blue Mountains International Management School" },
    { id: "a96899c1-052a-4809-a9de-59ea5ab2c7b2", name: "Blue Quills" },
    { id: "e0e90245-a654-4722-ac4c-8f2abab51909", name: "Blue Ridge Community College" },
    { id: "c2b6bd71-6f77-4bfa-9bfb-98ecbc8743ea", name: "Blue Sky Performance Improvement" },
    { id: "727e174e-430d-405c-b22b-5f8e3c246396", name: "Blue Valley School District #229" },
    { id: "f6ee5336-6cf4-4ee9-aa5a-3708e5707eea", name: "Bluefield College" },
    { id: "a7bab7ce-4a5c-4feb-90e4-8bc7ea99e640", name: "Bluefield State College" },
    { id: "f7c21d8d-d09d-477b-b51e-2fb7fe8dcc55", name: "BluePrint Foundation for Success Development" },
    { id: "17361fee-5cb3-4263-81cc-e0e82c3c3d6d", name: "Bluffton University" },
    { id: "0dc87cf7-96c8-4520-81af-3cfe67fb1ac9", name: "BML Munjal University - School of Management" },
    { id: "14d7a003-cbb3-4d1f-9378-2b7d4a46796c", name: "BMO Financial Group Institute for Learning" },
    { id: "2a001083-cd36-4eb9-89f4-cf394df5f118", name: "Bob Jones University" },
    { id: "f8e4a16d-8920-4b46-8fc9-795e1e623eae", name: "Bocconi University" },
    { id: "1bdbb0bb-6740-408b-ae20-1b6812911451", name: "Bogazici University" },
    { id: "e8ae349e-3573-48eb-a1ec-a32731fefc30", name: "Boise State University" },
    { id: "408b9cdd-a56c-4b40-8d32-f932e27ea010", name: "Bond University" },
    { id: "c8ed3c51-3b44-407e-9ce6-1ac21f65e25c", name: "Bonnabel" },
    { id: "1b14719e-5d29-4061-aa58-45dd8b26dc43", name: "Bookbridge/Nbc172" },
    { id: "117f0936-d5a6-4089-af5d-4e73738daadb", name: "Borah High School" },
    { id: "010394b5-d13b-4565-8306-4a6b1fe09bc2", name: "Bordeaux Business School" },
    { id: "86cfc380-48cd-4c84-b255-e6d80e8cde14", name: "Borders Inc. Spo Dept" },
    { id: "40352a3c-180e-4bd6-97f7-a9f26599600d", name: "Boricua College" },
    { id: "bed8b27b-f305-4719-8f86-498a076484db", name: "Bossier Parish Community College" },
    { id: "b1b8608e-1635-4f2d-b99d-a952077e7d42", name: "Boston Architectural College" },
    { id: "1f24840b-a0cb-4703-9559-336119a3a6f7", name: "Boston Arts Academy" },
    { id: "d933f480-cad1-4ced-80d0-3a6ab583b645", name: "Boston Business School" },
    { id: "8fbfde47-91fa-4c29-a793-f83a638b256d", name: "Boston Cancer Policy Institute Inc." },
    { id: "fb71cf8f-991c-4034-a6c1-68c7b3a4c9b8", name: "Boston City Campus and Business College" },
    { id: "688c28c2-99e4-4d42-bfae-12f9227a6c0e", name: "Boston College" },
    { id: "b07d5354-452d-4a75-a492-e44b1e9c8716", name: "Boston International College" },
    { id: "20ca0d75-fe3a-482e-a0f7-1ce5da6f39d5", name: "Boston Latin School" },
    { id: "b01c98ae-e2ed-4e52-ad73-58078779f974", name: "Boston University" },
    { id: "2dff4f67-cf2b-4c32-8abc-74d071fa461b", name: "Boston University - School of Public Health" },
    { id: "dea3e3d6-d0a9-4cf4-bf6d-dbbf2b461c83", name: "Boston University Bookstore" },
    { id: "a63ad203-d396-4f03-aeba-babf3c035940", name: "Boston University Brussels" },
    { id: "1832b809-a786-4cfb-a1b2-314ef8db374c", name: "Botanique et Bioinformatique de l'Architecture des Plantes" },
    { id: "2f6d5018-5493-402d-9d42-16024959a595", name: "Botho University" },
    { id: "bcd595f5-345b-4c83-ad23-2f37f0c0fd97", name: "Bournemouth University" },
    { id: "e402327b-ec9e-4ef4-ab86-bcf31d56b0e6", name: "Bow Valley College" },
    { id: "a832c998-0a93-4926-8be3-94bf0e3439fb", name: "Bowdoin College" },
    { id: "a1dee629-538b-4333-8eb8-b72425fb9f30", name: "Bowie State University" },
    { id: "b4733a55-57c6-41ba-af1d-9bd1d32989d5", name: "Bowling Green State University" },
    { id: "ed5d8cc4-2a77-4b10-8f20-1d46ab062b99", name: "Box Hill Institute" },
    { id: "7726c3bd-9eb5-4bc3-8045-747bc381d6c5", name: "BPP College of Professional Studies" },
    { id: "0c603ea7-3bad-428d-9ee1-2e8bf429f253", name: "BPP University" },
    { id: "bce87c01-2efa-462f-b0e8-46a8b7558bfd", name: "BRAC University" },
    { id: "b4986997-4ebb-48ec-91c8-3a9b92ab9bab", name: "Bradley Book Company" },
    { id: "1a3220f1-e2df-4840-9d2c-2095c732389f", name: "Bradley University" },
    { id: "0c3c4949-ff38-4782-872c-1b9b3c8950fb", name: "Brain Business School" },
    { id: "77ffed58-d757-47f8-ab65-ececb0ef15ca", name: "BrainNet Supply Management Group AG" },
    { id: "8b538f31-8392-4e60-8577-0be6fd2306c3", name: "Brandeis University" },
    { id: "a8ee3f35-a2b3-451d-882a-d1eedc805e13", name: "Brandenburg Technical University" },
    { id: "847c18fb-882e-4288-a5e0-7f1cf76a87c2", name: "Brandman University" },
    { id: "4b751f19-176e-46ca-a464-851680421bbe", name: "Brandman University - Irvine campus" },
    { id: "be3fac9b-6c21-4038-94a4-ffd91d123e10", name: "Brandman University - Lacey Campus" },
    { id: "0a0f50de-61d0-4eba-9a3c-cd8af6935f07", name: "Brandman University - Walnut Creek" },
    { id: "8b37180d-e44c-4bd3-bf54-73888748c5d5", name: "Brandman University - Antelope Valley Campus" },
    { id: "427cad37-dba4-49f7-8820-28dec87f3479", name: "Brandman University Bookstore" },
    { id: "e233b6ca-4399-4d95-86e6-998d01ab0aab", name: "Brandon University" },
    { id: "e8fcb019-b52e-4b4a-9e92-8f204ddfdeac", name: "Brazilian Business School" },
    { id: "81250f9d-baa7-498e-853e-442bed937ebd", name: "Brazilian Management Institute" },
    { id: "a5be51ad-ed01-4327-84ff-efba83b1fe8a", name: "Brazosport College" },
    { id: "e3d4ef1f-c6f5-4a5f-9c57-9f309ba125c1", name: "Breck School" },
    { id: "400a21dc-ae3f-496c-82ca-c174eb9cc2d7", name: "Bremen University" },
    { id: "e2cceb78-cf48-4a44-a9eb-1fa6b202fc23", name: "Bremen University of Applied Sciences" },
    { id: "0803330d-bde5-459e-b1e6-1394f3abde85", name: "Brenau University" },
    { id: "461fc1d5-fe7d-4b56-8b29-756ff077ebde", name: "Brescia University" },
    { id: "5240c195-c63b-4358-9680-e79d781623fa", name: "Brescia University College" },
    { id: "673c0228-83a3-48db-8dfd-197c1b58e98e", name: "Brevard College" },
    { id: "7454bc3d-e817-42a2-aa1a-ccb037175666", name: "Brevard Community College" },
    { id: "241774a0-1d5d-4918-b8e7-17f0d9984862", name: "Briar Cliff University" },
    { id: "00a5a6e8-5d4c-4ba5-b44b-5c60d896409f", name: "Briarcliffe College" },
    { id: "5ed43ca0-610f-4d40-ab79-ddbed4b1e533", name: "BRIDGE School of Management" },
    { id: "3aa8b53f-2538-457d-829b-599fc2bc1e72", name: "Bridgepoint Education" },
    { id: "63ec961c-2cf9-4f41-9943-a05fafb0805b", name: "Bridgewater Associates" },
    { id: "a2ed51ab-5219-455e-823a-a0530ce691fa", name: "Bridgewater State University" },
    { id: "be4edf47-4488-43d8-97de-30929f6b4c67", name: "Briercrest College" },
    { id: "a76e56f5-9310-4b5a-89d3-887cc5889715", name: "Brigham Young University" },
    { id: "64e1da75-738e-421f-8c8a-924ade538e6e", name: "Brigham Young University - Hawaii" },
    { id: "33d3cbca-52e5-4808-b84c-c0d55c0ff09f", name: "Brigham Young University - Idaho" },
    { id: "20cb81a3-6060-4b2c-b3db-2d5ad1b9e7e4", name: "Brighthouse Ltd" },
    { id: "97d667fe-d92c-43d4-a130-248e165dbb8e", name: "Britanico Internacional" },
    { id: "3883bdc7-9a0b-455a-a97f-898728ead5bf", name: "British Columbia Institute of Technology" },
    { id: "985c183a-ba13-4ec7-8d24-cef4ad26e0c3", name: "British Institute of Technology & eCommerce" },
    { id: "71d17068-0adf-4d9a-9b44-6f3716834f0c", name: "British University - Egypt" },
    { id: "7ea8f22e-d126-4bf3-bbbf-b5dbbaf97c3f", name: "British University - Vietnam" },
    { id: "a0502ce4-f641-4ff6-bf27-85e05f2500d9", name: "Brno University of Technology" },
    { id: "2a10ba66-6c27-4c48-81dd-146839ccdadf", name: "Broadview University" },
    { id: "55da7018-0e56-46b7-b7ed-b915aa031824", name: "Brock University" },
    { id: "30aeeb9c-2808-4611-9a8d-704bfad2ea66", name: "Brookdale Community College" },
    { id: "73b1585d-7096-46be-9d1d-4cf7fe1d141d", name: "Brookdale University" },
    { id: "dedf3450-f0d0-48d5-885d-02b62a1f3e65", name: "Brookhaven College" },
    { id: "97a15337-a542-4565-924b-588a7100edec", name: "Brookings Institution" },
    { id: "86923e2b-3e5b-4039-bd9c-a942a7611953", name: "Brookline College" },
    { id: "086d464a-da45-4788-9446-50b029587cb1", name: "Brookline High School" },
    { id: "83c732ca-5977-4e44-8927-27f39c6edd16", name: "Brooklyn College" },
    { id: "5fc910b0-04dc-42b5-a10a-6768d5b77af3", name: "Brooklyn Law School" },
    { id: "88fcb6d7-01a1-46b9-a90e-1709a82020fc", name: "Brooklyn Technical High School" },
    { id: "9e7bf45b-e4ca-4bf0-91d4-8bb3e98e608f", name: "Broom Community College" },
    { id: "8dd1e613-6dcc-455b-8c9e-15c26e6f9557", name: "Broward College" },
    { id: "16a9870f-6a04-4b3b-b26e-bc7a060e5d43", name: "Brown Mackie College" },
    { id: "7a00dfa6-1718-4e1b-9d5f-30d2547f695d", name: "Brown Mackie College - Indianapolis" },
    { id: "a0858154-7ea4-4149-a051-6804b1e40c91", name: "Brown Mackie College - Oklahoma City" },
    { id: "f8f6afb6-35be-4421-a3bf-7a5a88d72765", name: "Brown Mackie College - Tulsa" },
    { id: "088816a2-e80b-4581-8ceb-b515d4b3dca5", name: "Brown Mackie College at Greenville" },
    { id: "7d153716-d92c-4e04-93e7-949a802b5c48", name: "Brown Makie College - Boise" },
    { id: "2f3f8e5e-d65e-44f8-8910-59be7915995c", name: "Brown University" },
    { id: "000048c5-8bdd-4b38-b5fb-843640472aa4", name: "Brunel University" },
    { id: "8794db59-88a3-42f7-aa67-f06753e57527", name: "Brussels Business Institue" },
    { id: "62ae715d-0a05-48c6-ba65-2db9f2e1ff54", name: "Bruxelles Formation" },
    { id: "61701e74-3d0c-4171-bc62-d1c374271460", name: "Bryan College" },
    { id: "df943244-2c7f-43a8-8eda-43624ea8017c", name: "Bryan University" },
    { id: "6a6ed6fb-a843-4067-ace1-166dec3168b7", name: "Bryant & Stratton College" },
    { id: "de7d60d5-70ad-494b-abae-fa56fbdb34ed", name: "Bryant and Stratton College" },
    { id: "39783fd8-359f-48c4-907c-821a63bb9f5e", name: "Bryant University" },
    { id: "4c8fec5f-6666-49f3-a039-f49c93627b3e", name: "Bryn Athyn College" },
    { id: "7af2756d-66b9-43b5-aac1-6bff6d87f093", name: "Bryn Mawr College" },
    { id: "d416018a-4eec-46c7-9376-32a152aa1e0a", name: "BSI Learning" },
    { id: "66e795cd-7d71-4a5b-b4af-f7d349b3bf31", name: "Bspark Values-Led Leadership Academy" },
    { id: "7ac53efd-ed5a-4323-ad2b-e93be4a09535", name: "Bucerius Law School" },
    { id: "5c395e42-d89f-4128-99fd-e6b999912493", name: "Bucharest Business School" },
    { id: "c5207ae6-3fe5-4d11-a167-a5193fc17f88", name: "Bucharest School of Management" },
    { id: "363f8426-14ab-4f18-b15e-f6f5350f8582", name: "Buckingham Browne and Nichols School" },
    { id: "3041bd2d-e902-4206-9dff-05f412867beb", name: "Buckinghamshire New University" },
    { id: "fa4f810c-c734-47f2-b079-60e25a6165bd", name: "Bucknell University" },
    { id: "4c99cf50-d3d4-4cdd-95b7-5670683f7fcc", name: "Bucks County Community College" },
    { id: "385ec46e-ad40-4208-8c3c-23b79a60d98b", name: "Bucks County Technical High School" },
    { id: "99110e53-7732-4c0f-9635-938557b2850c", name: "Budapest Business School" },
    { id: "ebdf66aa-21cb-4501-834b-587f13f7870f", name: "Budapest College of Communication and Business" },
    { id: "5df38f1b-bf82-47c1-a1e0-4691a2e3bd0c", name: "Budapest University of Technology and Economics" },
    { id: "226e56a3-3f57-4ac4-8031-5d1fffa917b6", name: "Budge Budge Institute of Technology" },
    { id: "03236686-d6c3-4739-aba9-7dec67378935", name: "Budgetext" },
    { id: "946cb710-a152-42c6-a804-6c71725dfc02", name: "Buena Vista University" },
    { id: "408eea04-ef4d-400b-990d-b5c36334c52d", name: "Buffalo State College" },
    { id: "1497df3e-084a-4c2e-a702-44c9c583b933", name: "Bugil Academy" },
    { id: "223f34dc-a839-4f5a-9c5a-c9c118c0cd13", name: "Bukidnon State University" },
    { id: "b6f76800-eb7c-4fc4-bafd-6bb0716f370a", name: "Bundelkhand Institute of Engineering & Technology" },
    { id: "4f6d14a3-e04b-4efe-a9b8-3e266d91dabd", name: "Bunka Gakuen University" },
    { id: "937251ec-d8f9-41de-b9d0-08a8674e4a1d", name: "Bunkyo University" },
    { id: "5379d88f-74af-4a6f-82d0-f78bd8f94dca", name: "Burapha University" },
    { id: "a7991c76-c5d8-495c-98b4-5170d3ff7e17", name: "Burgundy School of Business" },
    { id: "fcd22048-b9af-4a5a-a455-351db38f954d", name: "Burlington College" },
    { id: "78ed8f13-35a6-478b-a85a-b2dfe877559d", name: "Burlington County College" },
    { id: "4a7303ed-efbd-4040-90d4-28f1bcd61d24", name: "Busines Jigsaw" },
    { id: "6473ba59-8705-4739-80f2-8fce7cc90d29", name: "Business & Hotel Management School" },
    { id: "4d743912-d38d-48d6-a22f-cf504778294a", name: "Business Academy Aarhus" },
    { id: "5c0bb6e8-cfe3-4d84-abfc-5b757c6cecbb", name: "Business Academy Copenhagen North" },
    { id: "0a4b73d0-d0ec-4e3a-be9b-787018a6ac5c", name: "Business Academy Middle West" },
    { id: "516d6641-3cb7-46ec-a51e-d586b7cb06d3", name: "Business and Information Technology School" },
    { id: "e8e417f6-2250-4b86-aee5-aa60b1de83f8", name: "Business and Technology" },
    { id: "31f6b13e-77f1-4c24-b0ee-a37a85dede80", name: "Business Breakthrough University" },
    { id: "d8bfeb7e-ca4d-45a5-adeb-3e9ea87b7c81", name: "Business College of Athens" },
    { id: "4dd97154-5dc9-4ac0-b4c4-b3e08544bd6d", name: "Business Management" },
    { id: "f1820990-c68b-4845-a1cf-d4a23adb35ec", name: "Business School Berlin Potsdam" },
    { id: "59ebb7ff-b650-44d3-8566-e9c0daac1314", name: "Business School Lausanne" },
    { id: "90052b1c-fc59-4adb-b0ac-c2576d0ae856", name: "Business School of SiChuan University" },
    { id: "e1e973be-287c-4a7c-870d-800946ca1761", name: "Business School Sao Paulo (BSP)" },
    { id: "0e97ef88-8880-456c-8c59-29c6e576dc1c", name: "Buskerud and Vestfold University College" },
    { id: "29599e8b-1531-492c-ae54-5230c2ee75a3", name: "Buskerud University College" },
    { id: "3a59c639-9941-491a-a354-e23beca68918", name: "Butler Community College" },
    { id: "d782c5d2-6eec-4870-875d-5a3963ea8062", name: "Butler University" },
    { id: "9ed1791e-f741-492d-ad82-647cd84cb063", name: "Butte College" },
    { id: "6c5c9938-e882-42be-a8fd-7ef7cab366d5", name: "BVVS - Institute of Management Studies (BIMS)" },
    { id: "5dee5780-a532-44a1-9383-8e1cccd25efa", name: "Byron Community College" },
    { id: "c3742375-3006-446f-9d08-bd998812e91b", name: "BYU Marriott School - Romney Institute of Public Management" },
    { id: "0fcd4f15-5fa1-4760-9d9a-eb5a5534b902", name: "Bülent Ecevit Üniversitesi" },
    { id: "774b856b-6e47-445d-85b9-2fffa351189c", name: "C.D. Jain College of Commerce" },
    { id: "f07f7b78-6a5f-43f1-af2f-444ab3e67da2", name: "C.S.U.F. Foundation" },
    { id: "11ca6e12-1a22-4695-99da-46121254a523", name: "Ca'foscari University - Venice" },
    { id: "670a4359-7ce3-4d4d-8080-6235ba357847", name: "Cabrillo College" },
    { id: "149deee4-d462-4904-bb9d-e9d8298c06ce", name: "Cabrini University" },
    { id: "8351dc00-d698-409d-90c0-6aa760e11e5f", name: "Cadiz University" },
    { id: "c0a70d5d-4e61-48fb-80e6-e9290541272a", name: "CAECE University" },
    { id: "174f83a6-7644-4630-8f92-bc40b3c4740f", name: "Cag University" },
    { id: "1d2d22f4-53db-4fab-84cb-f8a1b4c01515", name: "Cairn University" },
    { id: "275ec547-6472-441f-a9ad-7f4317449bf1", name: "Cairo University" },
    { id: "25f81831-f63d-452b-b92c-3f48eb517a0f", name: "Calcutta Business School" },
    { id: "2e9b08f7-3f49-4e44-ac6a-26744901d97a", name: "Calderdale College" },
    { id: "8f346cf3-7149-434f-b4bc-8f6fc8bb678b", name: "Caldwell College" },
    { id: "4980bba2-3092-49c1-8c49-0e032e525e40", name: "Caldwell Community College & Technical Institute" },
    { id: "9a14ecbd-f4c8-47c6-94d4-b4e0087a17a5", name: "Caldwell University" },
    { id: "9b623532-d646-4fb4-a522-9f1e321e32e8", name: "Calgary Arts Academy" },
    { id: "3e21fd7a-c80b-4d67-96f7-6aa71b076102", name: "Calgary Police Service" },
    { id: "f1397e44-c2e2-43a8-9caa-55b03269d3fc", name: "Calhoun Community College" },
    { id: "e762997d-0b4b-4787-bffc-1f88c9dc70b6", name: "California Baptist University" },
    { id: "a2db857c-54da-4590-9bd1-5031b1e5dc66", name: "California College for Health Sciences" },
    { id: "1ba45508-1c4d-487a-951d-13356f06a5dc", name: "California College of the Arts" },
    { id: "a572c5a4-bb5a-4a3e-a53b-4a97aaa08284", name: "California Community Colleges" },
    { id: "3c19056a-4953-485e-a5f6-920baac254f3", name: "California Institute of Advanced Management" },
    { id: "589bba35-2867-4ce0-b517-76fce144bde2", name: "California Institute of Integral Studies" },
    { id: "774aaff5-fed1-41f5-a1d7-b33a207d6d61", name: "California Institute of Technology" },
    { id: "ac32596b-d8fd-4f03-8549-a3061471eaec", name: "California Institute of the Arts" },
    { id: "0b9ddfd1-9056-4186-94d2-c03fa1f28a48", name: "California Lutheran University" },
    { id: "6ce23a12-a67f-496c-80ea-bcf1b273d7e9", name: "California Maritime Academy" },
    { id: "7f808486-5457-48b0-95b5-bf237c7f5aec", name: "California Miramar University" },
    { id: "512f3c57-109f-4884-9602-d8d4b9146f37", name: "California National University" },
    { id: "0befe39d-a27e-4e61-9e63-fd6d39b07e9e", name: "California Polytechnic State University - San Luis Obispo" },
    { id: "db660c4c-b29d-4889-97e1-81d83f465670", name: "California Preparatory College" },
    { id: "5a8871e9-0eff-4625-a210-86de99911794", name: "California South Bay University" },
    { id: "7b1d120e-6d34-4fd0-a651-7b3b4f8c6b2e", name: "California Southern University" },
    { id: "23904ab8-50ab-41ac-a266-8ffc8e2c212a", name: "California State Polytechnic University - Pomona" },
    { id: "9469dca0-a1c1-43d3-85e1-8f8183328b42", name: "CALIFORNIA STATE SAN MARCOS" },
    { id: "6d287fe6-802f-409a-b4d9-dca2e5662fff", name: "California State University - Bakersfield" },
    { id: "46c5ee4f-0326-4f7c-8c67-49c887ea4601", name: "California State University - Channel Islands" },
    { id: "abd397e7-e41c-4ce5-aef5-24b10d5144f5", name: "California State University - Chico" },
    { id: "64dd3717-d88e-4af7-9020-d48263fd0a4c", name: "California State University - Dominguez Hills" },
    { id: "bdb84ba6-cce0-4ce3-ba35-3f4ec7e65dc7", name: "California State University - East Bay" },
    { id: "50a4423d-ae63-4882-b610-06bcd7066d7d", name: "California State University - Fresno" },
    { id: "eff572cb-f555-4270-beaa-0a8c6d43aa54", name: "California State University - Fullerton" },
    { id: "fbfc8e50-c05e-483d-aa98-6d5515e356a6", name: "California State University - Long Beach" },
    { id: "aaafa544-3b8b-4c69-937c-b53a036a1def", name: "California State University - Los Angeles" },
    { id: "8755bdfb-0e53-4a68-9816-4733f38df138", name: "California State University - Maritime Academy" },
    { id: "a75e1ffe-c275-4509-8dea-468dc68c5cea", name: "California State University - Monterey Bay" },
    { id: "fe895130-d3b6-40cb-a8d6-ffa6128068d8", name: "California State University - Northridge" },
    { id: "ebf15aa4-3059-4bde-a0f9-5d3532a70c8d", name: "California State University - Sacramento" },
    { id: "0647ae85-d2e5-46f8-8ba5-940ada9e3028", name: "California State University - San Bernardino" },
    { id: "92a401fb-28e0-42ac-94a6-57ef2d76fc00", name: "California State University - San Marcos" },
    { id: "aa292efa-de85-4602-91b3-543c889e47f1", name: "California State University - Stanislaus" },
    { id: "85471537-ee90-4ad9-a4b7-fb4d2e39d6e4", name: "California State Universlity Long Beach University Book Store" },
    { id: "7ccdc4b0-dc01-44d5-a5d8-da17566f4c55", name: "California University of Management and Technology" },
    { id: "46e7924c-f30f-4b69-9fbf-aff2721452ce", name: "California Western School of Law" },
    { id: "4b03e6e4-129c-49c1-ae2d-cc2512407fe4", name: "Calvin University" },
    { id: "90e82475-b0c1-41ad-950a-a0c5d97d71a4", name: "Cambra Oficial de Comerc I Industria de Terrassa" },
    { id: "b2f30453-3178-44b6-81e6-92e7f8d27eba", name: "Cambrian College" },
    { id: "3e607473-f423-48d2-bb4c-d1c7cede8c73", name: "Cambridge College" },
    { id: "d1cfaf27-4c73-4b7d-94be-96526ad5efaf", name: "Cambridge College International - Dhubai Campus" },
    { id: "57d29215-999a-4543-998a-9c4267cf57be", name: "Cambridge Ctr for Adult Educ" },
    { id: "35c43201-80e9-4b4b-9533-e75765b74df9", name: "Cambridge Graduate University" },
    { id: "eee2146b-6631-42cb-b1f0-f09189cda16b", name: "Cambridge Institute of Technology" },
    { id: "e713c657-24dd-4af9-9b2f-ee1173092de2", name: "Cambridge Judge Business School" },
    { id: "6f05513a-eef3-4e26-8564-fb384d9975ce", name: "Cambridge Manufacturing Industry Links" },
    { id: "84e8fb25-8abb-42e1-b8f3-f1c9e8e3f703", name: "Cambridge Public Schools" },
    { id: "e0c85212-986b-48a1-8b60-df556030b4ee", name: "Cambridge Univeresity Health Partners" },
    { id: "20edfde4-4b43-4769-9d35-4ef63e003b76", name: "Cambridge University Hospital NHS Foundation Trust" },
    { id: "f1201c06-f434-4c5c-bb1c-42a14c3c3f73", name: "Camden County College" },
    { id: "42c1fd89-5649-45c2-9892-fb986e56f177", name: "Cameron University" },
    { id: "9ddbfb3e-205a-4c8c-9332-bc060bb69742", name: "Camosun College Bookstore" },
    { id: "df93cdcc-dc38-428e-b298-76a3e2230f90", name: "Campbell University" },
    { id: "0abd685d-29d3-4d30-b8ee-9a33f3a24452", name: "Campbellsville University" },
    { id: "b0ce5405-e3f5-46e7-958a-c420f40a7d0e", name: "Campius Shop" },
    { id: "91c05bf7-1071-4f0c-b993-7a5a14e15f64", name: "CAMPUS 02 University of Applied Sciences" },
    { id: "68ca510f-42ed-4e4c-86ac-b836fa2ffc21", name: "Campus Book & Supply" },
    { id: "4f10f43e-10b4-4310-9e05-e211519b958a", name: "Campus Bookstore" },
    { id: "c674d226-682d-447f-9e49-d7ea2ff9589b", name: "Campus de la CCI" },
    { id: "9a1db259-143c-4d7b-ae83-2e2feebaa8a7", name: "Campus International Business Schools Network" },
    { id: "8de16fc9-5dda-489b-a922-32dacce1bc1e", name: "Campus M21" },
    { id: "037d6a44-15c7-4d0d-aaf4-07416e6a1b18", name: "Can Tho University" },
    { id: "05a2a917-d102-4426-83ae-62e0c5f5bbf0", name: "Canada Music Academy" },
    { id: "cb0da0fa-f84f-4a3e-abb9-82a730e6ad67", name: "Canada School of Public Service" },
    { id: "b416dbdb-4e1b-4688-8eb7-21568ff2f6b1", name: "Canademica" },
    { id: "2e1455a3-ae1f-4da0-94d1-6a0936e47ccc", name: "Canadian Business College" },
    { id: "5b988418-f83b-4c7e-857a-da02cbe92211", name: "Canadian Coast Guard College" },
    { id: "47c1a9ad-7093-494f-8c3c-e7474dcc56c4", name: "Canadian Federation of Business School Deans CFBSD" },
    { id: "8d02fbe0-ae09-49ba-8072-09c63afb788e", name: "Canadian Forces College" },
    { id: "ddb0c6f0-5456-4e41-a079-9686d3c88035", name: "Canadian Memorial Chiropractic College" },
    { id: "95cda320-b668-4189-8cdd-5f99469ae22e", name: "Canadian Mennonite University" },
    { id: "a3026a93-278a-4847-a5bd-790ce14b98f9", name: "Canadian Organization Development Institute" },
    { id: "1fb9327d-81bf-4e76-b095-f0ba4bad41ac", name: "Canadian University of Dubai" },
    { id: "2090caf9-cc2e-4748-bba3-b2e723810721", name: "Canadore College" },
    { id: "cd49e88e-1c34-4005-a6fe-a320bc70df6e", name: "Canakkale Onsekiz Mart University" },
    { id: "6b208d6e-e72c-465f-9bc5-6a8098f1cdde", name: "Canisius College" },
    { id: "65447a3f-3b87-4d14-a13c-4b5adc930c72", name: "Canon Eurasia Gã¶Rã¼ntã¼leme Ve Ofis Sistemleri a.åž." },
    { id: "41c96751-61cb-4c8d-8f61-20ef19cb615b", name: "Canterbury Christ Church University" },
    { id: "41ae6f87-1db1-4a04-acca-e7c88fdbd8da", name: "Canvas University" },
    { id: "79e294f5-06e7-4038-bb8f-0dd9e869a13b", name: "Cape Cod Community College" },
    { id: "5f222b8d-db2d-4f47-b829-cc3b979e01d1", name: "Cape Fear Community College" },
    { id: "121ce514-5749-47c7-aae6-4a84d6fb2320", name: "Capella University" },
    { id: "8fb8d63b-9bd4-415e-bd10-6e31da7b8586", name: "Capilano University" },
    { id: "69135934-8b89-4dd2-bfc2-aacbb8c0a5cf", name: "Capital Area Technical Center" },
    { id: "13c0234e-61d8-438d-8da1-8076262ca961", name: "Capital College" },
    { id: "2dc9f347-7d79-4b0b-b317-89d3291571fc", name: "Capital Community College" },
    { id: "ac6610bb-ebbb-4409-a083-4c3a2959396c", name: "Capital University" },
    { id: "7a89abea-7b5b-4127-8b0c-c6ec00a474b6", name: "Capital University of Economics and Business" },
    { id: "f396698f-85ba-40fe-b3ea-633c9599f1be", name: "Capitol College" },
    { id: "d31e632e-f368-4966-9ac7-f754010d503c", name: "Capsher Technology" },
    { id: "6315c78e-11de-4b0b-a33f-3354e9ecec81", name: "Cardean University" },
    { id: "1551aa5a-090b-47cb-84cf-e2ee46bcdef8", name: "Cardiff Metropolitan University" },
    { id: "9d1b6bc0-cd20-46df-850a-7a6ccd22a99b", name: "Cardiff University" },
    { id: "29e3846e-2e09-4fe6-abbc-12fec4da49a0", name: "Cardinal O'Hara High School" },
    { id: "a7f8bbbd-58cc-44e1-beec-0a410f1c6b3c", name: "Cardinal Stritch University" },
    { id: "345906f5-ddb6-4465-aa00-03d3aed82092", name: "Cardinal Tien College" },
    { id: "98544caf-351a-4725-944d-9362d9ab1b99", name: "Career College of California" },
    { id: "1250bf9c-4972-4ee3-bc2c-87f9302231ea", name: "Carinthia Tech Institute" },
    { id: "e96fa7c6-d45b-40e9-b225-0d75b77e560b", name: "Carinthia University of Applied Sciences" },
    { id: "4ecfe535-9872-4ee1-8dd7-a79e1af83769", name: "Caritas Community & Higher Education Service" },
    { id: "b8a320ad-d1ac-4bae-aaf2-3f69a7c938fc", name: "Caritas Francis Hsu College" },
    { id: "3b6c88fd-622b-48b1-a022-11cff7a0b8de", name: "Carleton College" },
    { id: "fa8227e6-28a9-48be-8322-bb2f203636b1", name: "Carleton University" },
    { id: "73882f3f-ff34-441f-9d8f-5a7d9c63a76b", name: "Carleton University Bookstore" },
    { id: "5c72509e-8b67-443f-8145-f4a4e17eea5c", name: "Carlos Albizu University" },
    { id: "99ab7550-93be-493e-8140-cdea63da4a0d", name: "Carlow University" },
    { id: "f2b9ec01-3627-4500-a12c-734263a26bb0", name: "Carlson School of Management" },
    { id: "31693a05-2765-42d2-af45-9abd206577aa", name: "Carnegie Mellon University" },
    { id: "fee9e14f-5524-4388-b917-48d059dd0306", name: "Carnegie Mellon University - Silicon Valley" },
    { id: "75085237-bda2-40b9-b490-339160784f48", name: "Carnegie Mellon University in Qatar" },
    { id: "2e8f8ac8-8f93-4590-a9c2-eb16ce085a3f", name: "Carnegie Vanguard High School" },
    { id: "c1aaa94c-e09d-4346-b439-1bede5f3062a", name: "Carolina University" },
    { id: "2737252f-a2c6-4c34-a9fe-386586f3304f", name: "Carrel" },
    { id: "0eb1141b-c9d8-4cbf-8bf2-7ae59ec06572", name: "Carrington College" },
    { id: "0769ead6-6a55-490f-89d5-12c781f59dd7", name: "Carroll University" },
    { id: "ff6e7eda-c9bc-4048-ab40-efda55f8b5a8", name: "Carson-Newman University" },
    { id: "55bb312e-af40-4ff8-94cb-4be5e2961538", name: "Carteret Community College" },
    { id: "8ac426ae-fd9a-43fb-b350-831bb9de4df6", name: "Carthage Business School-University of Tunis Carthage" },
    { id: "205eb4c5-27fa-4c0f-af83-38098735b557", name: "Carthage College" },
    { id: "e9c5daf7-91b4-4f9f-83ac-7e6c5d42549b", name: "Cascadia Community College" },
    { id: "c796b783-205c-4768-a016-e60dc3058536", name: "Case Center Japan" },
    { id: "0265b928-7fdc-4828-bbce-caae768a3b21", name: "Case Research Society of India" },
    { id: "5f11d217-6297-4f73-9758-38f3c2857739", name: "Case Western Reserve University" },
    { id: "759ade92-2119-4f6f-a769-e3979a3e6b5f", name: "Castleton University" },
    { id: "9da571be-7108-4d3c-881d-f168a745a19b", name: "Casuarinas College" },
    { id: "2f394d5b-8606-4ba2-8cd2-76c251ca4d17", name: "Catawba College" },
    { id: "4595e05b-5f64-4683-9e13-cf09a52e3b6a", name: "Caterpillar S.A.R.L." },
    { id: "8ee2c9f1-81a7-49f0-9341-a4fe2adb04ff", name: "Catherine Bailey Management Development Consultant" },
    { id: "7c747a72-912e-479a-942a-d5312e9eab6c", name: "Catholic University - Leuven" },
    { id: "e92a8965-7f29-4222-8948-eb995b15cccb", name: "Catholic University Lille" },
    { id: "4a29ff49-61b4-461b-b0c4-d1b799205332", name: "Catholic University of Cameroon" },
    { id: "91d7e380-11f9-4f0b-9338-5464807289a4", name: "Catholic University of Colombia" },
    { id: "8723a771-6e86-4019-ac07-1303a193b0ff", name: "Catholic University of Korea" },
    { id: "24d05f2a-f9c4-46dd-b2f3-22ee63ffb985", name: "Catholic University of Portugal" },
    { id: "2b659e27-0943-4234-94b1-ae12bd4210f6", name: "Catholic University of Puerto Rico" },
    { id: "5007783d-488e-408f-b3b9-9e165069c490", name: "Caucasus University" },
    { id: "c9ddcb23-a4c1-4af7-b361-a3ea436dec85", name: "Cavendish College" },
    { id: "e2ca11e5-ca06-4f88-8c99-ee31082d81ca", name: "Cavite School of St. Mark" },
    { id: "d7918a2f-3e56-41be-a462-1d1f2163dc15", name: "Cañada College" },
    { id: "d92bc3e1-6d4a-4cad-9be1-71cb2394692d", name: "CBS-SIMI Executive" },
    { id: "d07aa01b-fbcf-4af6-b1ff-8e2a9e96f143", name: "CC of Philadelphia" },
    { id: "82c01c76-0a82-468f-aa6e-e82ff138f0e5", name: "CCI Clermont-Issoire" },
    { id: "998dcfb1-5b64-4c8c-86f6-ed88c5f7ac7d", name: "CCI Dijon - Groupe Esc Dijon Bourgogne" },
    { id: "33361c89-c789-4254-9a45-70d9bd4df6fd", name: "CCI Strasbourg" },
    { id: "f7ca2791-499d-4017-9f5b-4ace81ed22ee", name: "CCIP-DIRECTION DE L'ENSEIGNEMENT" },
    { id: "000fe3dc-5da7-4bf4-b79c-39fa1043d975", name: "CCMP" },
    { id: "85e2da74-2b28-4f62-b2c3-6a61a43a5783", name: "CEA" },
    { id: "2f9007a7-9a30-4b47-890b-6a18cbe08bfb", name: "Cecil College" },
    { id: "1bb94d5f-711c-499e-a2df-b4697f3f2229", name: "CECOS London College" },
    { id: "9de8aeb0-8f1a-46cf-bdfa-b52ff02aae9d", name: "Cedar Crest College" },
    { id: "a6d61bdf-1386-4222-b315-7fd4c2732f68", name: "Cedar Valley College" },
    { id: "04238556-9ede-4aaa-95b1-33be75092c36", name: "Cedars at Cobble Hill" },
    { id: "713e3a05-85a4-4f5e-88c4-cdaeda0242e0", name: "Cedarville University" },
    { id: "99405e6c-61f2-4ffe-bf46-4dfaaa32aff4", name: "CEDEP" },
    { id: "7fcaed7b-027b-4221-9096-a87c12d943bb", name: "Ceeca" },
    { id: "68d41cba-2644-4096-ba5f-9dc295670139", name: "CEESO" },
    { id: "9d263c43-44f2-4bd2-80ea-fa46be9d7353", name: "CEET Business School" },
    { id: "59da0e60-c47e-4b6a-9ba1-cdf6621fafb3", name: "Cegos Sa" },
    { id: "a93fec36-4908-49ce-89f0-e0baa501f9f9", name: "CEIBS" },
    { id: "324bddcf-e8b6-4fdc-ace6-95b6aaaf3621", name: "CEIPA" },
    { id: "0495bae2-d4d4-44d1-97dc-7f00a9885495", name: "Celal Bayar University" },
    { id: "780b5a94-629c-4fb0-a2dd-c676b35500b4", name: "Cengage Learning Australia" },
    { id: "06fbdd9c-453e-4783-a3ba-87fca05e85d3", name: "Centenary College" },
    { id: "6be309a9-ae39-4bfb-af2b-311332d38f53", name: "Centenary College of Louisiana" },
    { id: "5fc38c93-b642-4d2f-9c2b-2ca34cdd7408", name: "Centennial College" },
    { id: "d8903cc6-2f91-46a2-9c77-bf2972854461", name: "Center for Advanced Professional Studies" },
    { id: "732ca1e2-1401-4b50-bc0e-2dbbaa497cf2", name: "Center For Advanced Studies In Engineering (CASE)" },
    { id: "2b72b9fe-4ebf-4278-afe5-ea71b088dfdc", name: "Center for Business Skills Development - Thunderbird Russia" },
    { id: "6703af23-65d1-4439-8913-6b4085b47c3d", name: "Center for Collaborative Leadership" },
    { id: "3d371f99-a37a-4136-a1d8-999372e4edcb", name: "Center for Economic Strategy and Competitiveness" },
    { id: "3156643c-5520-40f3-9484-0910be02798a", name: "Center for Effective Organizations" },
    { id: "1e467528-6336-4516-a9a8-7e88e9da3a5b", name: "Center for European Studies Rhone Alpes" },
    { id: "4f50d183-e041-4d47-84a2-7efa4464fbc2", name: "Center for Global Innovation and Entrepreneurship" },
    { id: "4e65c061-56e1-4140-adbb-49a8c070246e", name: "Center for Global Innovation and Entrepreneurship - CGIE" },
    { id: "87f16bf0-472c-418f-9b52-40388bc634fb", name: "Center for Latin American Studies" },
    { id: "13e2f5e0-8035-4a10-a808-f4edfee6dd85", name: "Center for Ledelse" },
    { id: "bd9b5efb-5213-45e4-995d-bb4e9d4752df", name: "Center for Lifelong Learning" },
    { id: "dd582539-c7aa-4e71-8d83-985de6d8b89e", name: "Center for Management and Policy Studies" },
    { id: "4b99eed6-3fd8-4b9b-ac23-1842c85efe15", name: "Center for Management Research" },
    { id: "1e2cec60-1ae9-4ff0-8aba-a81d1b514a06", name: "Center for Management Studies" },
    { id: "4a2812d1-33e9-475d-bed4-2c6480c7dfc3", name: "Center for Strategy & Leadership" },
    { id: "8c53bea2-d910-41bc-9b2b-0f617e953087", name: "Central Arizona College" },
    { id: "e5b8f652-ba22-4791-996f-3329dba8a182", name: "Central Baptist College" },
    { id: "2075cba0-40ca-4f1c-811b-ad03fbe76940", name: "Central Bucks School District" },
    { id: "01c37c76-e081-4545-a062-7270efb65725", name: "Central Christian College" },
    { id: "f2d16d99-9dfc-4e65-97c1-cd7179e5f57e", name: "Central College" },
    { id: "f19eb6c4-8319-4632-9f2b-dac64bd969c6", name: "Central Colleges of the Philippines" },
    { id: "593cae6e-a66d-4e56-a991-c7a16e67f609", name: "Central Community College" },
    { id: "eda19a30-2a5b-4146-87c2-b806a3c0e152", name: "Central Connecticut State University" },
    { id: "732cb2e7-998d-47c5-a100-e74938d2c5d7", name: "Central Economics and Mathematics Institute (CEMI)" },
    { id: "61baab52-1dd0-402f-985b-8d30a0b4bc9c", name: "Central European University" },
    { id: "aa70873e-9023-4103-b4b1-6320b61ff022", name: "Central Georgia Technical College" },
    { id: "20c45bfb-fe89-4809-90be-ea8750d41c58", name: "Central Institute of Business Management Research & Development" },
    { id: "8b850aca-d147-495d-8492-9a81a779e6c0", name: "Central Library of the College of Mgmt" },
    { id: "445f6dc8-1b1c-4110-9208-14926939c034", name: "Central Maine Community College" },
    { id: "747f7ac6-03f0-4000-b7b0-1270db517fb4", name: "Central Methodist University" },
    { id: "32872fb7-04d6-4fd7-982f-7f9760927898", name: "Central Michigan University" },
    { id: "1d74282e-5365-4adc-b57f-fd32b502c365", name: "Central New Mexico Community College" },
    { id: "f9379c3d-25e6-4579-be96-c0625bae2efa", name: "Central Ohio Technical College" },
    { id: "7d859ffb-368c-4b89-ac59-2889cc4acbf6", name: "Central Ohio Technical College - Newark" },
    { id: "04b51909-5af5-4cd4-a8d3-f6249d78eb3a", name: "Central Oregon Community College" },
    { id: "b4518479-b49f-44b5-ba4f-38d5ef457b47", name: "Central Park Montessori" },
    { id: "9a8a4a2c-5a7f-4c57-aff5-eca7b55cbbb2", name: "Central Pennsylvania College" },
    { id: "4ad5c83c-c421-4fdf-b5fc-24addfa98c9a", name: "Central Philippine University" },
    { id: "65558598-ab7b-4fa5-a1ec-8765f9cbe3b6", name: "Central Piedmont Community College" },
    { id: "5a741b60-95db-4e9f-8703-ddf3e5ddeb3f", name: "Central Queensland University" },
    { id: "5ad72d19-de7c-4d55-8b04-368816b1d959", name: "Central Saint Martins" },
    { id: "9dcfe682-098f-45ec-9d48-1d44b89b9424", name: "Central South University" },
    { id: "f402ad20-d965-4073-979c-c1685368bbb0", name: "Central State University" },
    { id: "4a94429c-cc04-4748-9c6b-95c518eb9323", name: "Central Texas College" },
    { id: "1dda1a8b-323c-4372-8005-ab36172ea211", name: "Central University" },
    { id: "17ab2d8f-eb18-45a1-a865-ea1c959b933a", name: "Central University College" },
    { id: "e382a57a-7206-4c8e-b17e-544eabb36b5c", name: "Central University of Finance and Economics (CUFE)" },
    { id: "85fb4621-4a8a-46da-85e6-be576f2bf724", name: "Central University of Himachal Pradesh" },
    { id: "ee46d74a-a38f-4750-882e-499770c2c855", name: "Central University of Jammu" },
    { id: "38ae1cb6-0be7-4976-b68c-550dd568ed0e", name: "Central University of Rajasthan" },
    { id: "ee39ecc2-89b5-4b3b-984b-46f6ced746b0", name: "Central University of Technology, Free State" },
    { id: "ef8649a8-eb48-4a77-8b5f-1748fee4eeda", name: "Central Virginia Community College" },
    { id: "4219d470-eb1e-4b55-bb5b-79edb1ddbd3b", name: "Central Washington University" },
    { id: "22979f5e-c1ce-4e87-bca1-8e3c07094e91", name: "Central Wyoming College" },
    { id: "a8c56d84-3c4d-44ac-bafd-48f219e07f3c", name: "Centralia College" },
    { id: "90364431-ed82-4b81-a495-9e9b968ceafa", name: "Centre College" },
    { id: "c5600110-a1c7-49ad-99b9-7915133e646b", name: "Centre d'Etudes Franco Americain de Management (CEFAM)" },
    { id: "18dbcae1-f718-4eee-a7ba-83157e5370ed", name: "Centre for Arts and Technology" },
    { id: "6bb2acc4-1605-41b4-a2d2-96e0521651ed", name: "Centre for Digital Media (CDM)" },
    { id: "28070893-31ea-445b-a71c-5773e7671ce2", name: "Centre for Environmental Planning and Technology" },
    { id: "77ec0797-e70c-4c40-bfd9-9cb015d87dc1", name: "Centre for Fin & Mgmt Studies" },
    { id: "bdbff999-3073-46c3-9cf8-a152c5c3d3e7", name: "Centre for Financial Management" },
    { id: "3856a52a-3567-425d-98d2-ab3accef7d35", name: "Centre for Health Service Management" },
    { id: "4aaa8ac7-0548-41e4-a6fc-a58e6523bf01", name: "Centre for Hotel & Tourism Management, University of the West Indies" },
    { id: "9b019c76-635b-43aa-aea8-7b9fe91db3e4", name: "Centre For Management - India" },
    { id: "af07c301-8724-4993-b6ec-a1a146353a3d", name: "Centre for Management Development" },
    { id: "290a84da-053a-42ef-913a-65b71c1fe46d", name: "Centre for Management Training & Research" },
    { id: "433f0639-217e-4265-b0fc-5c4a36a899e7", name: "Centre for Organization Development" },
    { id: "4fd1e2a8-1417-4b74-83c1-49725adf2da0", name: "Centre for Vocational Education Lolland Falster" },
    { id: "ced19096-203d-4007-9c0b-552c0239485b", name: "Centre MIM" },
    { id: "f8efa246-52d3-4acd-9951-b0da4dfb124e", name: "Centre of Administration and Operations of ASCR" },
    { id: "0ef1e15c-976a-41fb-aa3c-29bce1c06944", name: "Centro Budista Kadampa Naropa" },
    { id: "4bdc62df-3cf4-464f-b9ba-0650f7386a60", name: "Centro de Ensino Unificado de Teresina (CEUT)" },
    { id: "effe9b2a-f8ee-43e7-b7fb-771fb1f9d8eb", name: "Centro de Estudios Superiores de Diseño de Monterrey (CEDIM)" },
    { id: "e9a5d6ed-0a43-4e36-833c-8dd476db32b2", name: "Centro de Esudios Superiores de San Angel (CESSA)" },
    { id: "55365709-6139-4dda-8325-ca2484956a58", name: "Centro de Investigacion en Alimentacion y Desarrollo A.C." },
    { id: "5032f82d-9989-46c9-ab33-053766a8aebe", name: "Centro de Investigación y Docencia Económica (CIDE)" },
    { id: "c17e489c-d3af-4177-9944-9f7b87cb4c17", name: "Centro Diseño Cine Y Televisión" },
    { id: "065be80a-be3a-4ca7-8582-843e5299a3ec", name: "Centro Educacional de Fundacao Salvador Arena CEFSA" },
    { id: "ebecf7d1-5a7a-4bfc-9903-4b4287109b0a", name: "Centro Federal de EducaÃ§Ã£ TecnolÃ³gica (CEFET)" },
    { id: "9398eb3c-6b45-432e-9555-aed021fd14b1", name: "Centro Latinoamericano de Innovacion en Logistica" },
    { id: "c7cfb40e-be2a-4d7a-9728-e0f7c533d03c", name: "Centro Tecnologico Positivo" },
    { id: "a371af1d-5ff1-45e9-aee5-f50556709ae5", name: "Centro Universitario Cesine" },
    { id: "38241797-0d57-4be3-94d4-9859c59f911e", name: "Centro Universitario da FEI" },
    { id: "196b0db9-9019-4fe2-a90b-261aa2e471ee", name: "Centro Universitario de Brasilia" },
    { id: "d5bf8e0d-11fe-432a-814c-67198a395052", name: "Centro Universitario do Norte (UniNorte)" },
    { id: "7c23dc0c-4283-493b-b425-034c4f3604e9", name: "Centro Universitario Doctor Emilio Cardenas (CUDEC)" },
    { id: "a56fa8b8-846a-4fa2-b8b4-1cbfbd2e54a0", name: "Centro Universitario Educativo y Cultural" },
    { id: "403610c9-a256-4a10-8a5d-0e0a648f52b5", name: "Centro Universitario IBMR" },
    { id: "3dc76af0-d735-40d0-a669-f9c06e3bd3e7", name: "Centro Universitario Incarnate Word" },
    { id: "e9bb669c-72ce-473e-b16c-9b97bc21479f", name: "Centro Universitario Jorge Amadol" },
    { id: "b0ef4c68-e349-4042-a65a-c905382f597e", name: "Centro Universitario Paulistano" },
    { id: "15a11a17-15e2-412f-8a80-a864c38e55d4", name: "Centro Universitario Sao Camilo" },
    { id: "a68051c1-b1e3-4fed-a18f-1f0c21853ba1", name: "Centro Universitario Una" },
    { id: "2cf9c917-080e-4078-858e-1121a4ac1c8e", name: "Centro Universitário Ritter dos Reis (Uniritter)" },
    { id: "7fa46ed7-04db-4333-989e-fb1cf3e00801", name: "Centrum Kszta?cenia Podyplomowego Wojskowego Instytutu Medycznego" },
    { id: "e457fa9d-ed40-4d49-8f17-5d7582041928", name: "CENTRUM PUCP" },
    { id: "0a70daad-946c-4e8f-9735-f168776ed516", name: "Centum Learning" },
    { id: "15d3f955-d4af-40d2-85ab-2643ebe1d13c", name: "Centura Capital, LLC" },
    { id: "ea9b5a9c-2fd3-441f-8e6d-805b7ce95a93", name: "Centurion School of Rural Enterprise Management" },
    { id: "086d05e3-04cd-4ce7-aaa5-04c00b06df2b", name: "Centurion University of Technology and Management" },
    { id: "33c1a521-7dc3-47c1-acbc-eef55f92589f", name: "Century College" },
    { id: "53c87dcc-ff28-461f-b2b8-118eb809c0c4", name: "CEPI Management" },
    { id: "16352a4d-4098-4036-a272-3d02b86c315c", name: "CEPT University" },
    { id: "8196409e-dd38-434d-94a3-7ac22fdaf08a", name: "CERAM" },
    { id: "ed05e5d0-4cc1-4c5f-b0de-8c0ec7475375", name: "Cerritos College" },
    { id: "4932b155-d3f4-4e34-9e92-5c368ddcff84", name: "Cerro Coso Community College" },
    { id: "4c878dae-8d90-4917-99e6-982237cc5b45", name: "CESA - Colegio de Estudios Superiores de Administracion" },
    { id: "cb231d23-6cce-4037-8024-f22bdd0fd7b7", name: "Cesine" },
    { id: "29ea8e3b-4f64-497b-9634-537d219e18cf", name: "CESSA San Angel" },
    { id: "779e61f7-5d78-41ef-b8c0-9b15f09f39d2", name: "Cesuga" },
    { id: "834f24ef-a991-4841-b572-6489c2299ccd", name: "CESUN" },
    { id: "8003e5de-09c7-49b3-abdd-9dcfa0d812e2", name: "CETYS Universidad" },
    { id: "5e749a2b-1239-4b17-bafe-c6e79c319950", name: "CEU IAM - Institute for Advanced Management" },
    { id: "9d6eabda-7a0d-4d1d-a8e1-e0ccc2614583", name: "CFA Society of the UK" },
    { id: "3b655650-5ca7-4bd9-b397-78e5ab0450cc", name: "CFE College of Accountancy and Finance address" },
    { id: "dd797ee7-1694-43a2-97e1-e9ddd790c07c", name: "Cfl - Center for Leadership" },
    { id: "6a052f11-a135-4bc8-a62f-82b93efac1fe", name: "Cggveritas University" },
    { id: "2cfcd18f-c588-4a09-ac85-999fe6041bec", name: "CH Institute of Management And Communication" },
    { id: "388d89a2-cefd-4341-9548-fc070ebf367e", name: "Chabot College" },
    { id: "322cc0ca-4449-4370-b3c9-44117e84baad", name: "Chad Garland, CPA" },
    { id: "cc54716d-c3f2-4727-90fd-9f0a905f9710", name: "Chadron State College" },
    { id: "e6ec40d6-26cb-4c04-aaa3-e2db9ff426ab", name: "Chaffey College" },
    { id: "c7d3a2a3-a450-4774-a656-722d87b13965", name: "Chairperson, Case Method Teaching Seminar" },
    { id: "a0c83626-4680-4b47-bfff-1cb647adc41a", name: "Chalmers University of Technology" },
    { id: "317e37ee-8622-470b-855f-3408061d99f4", name: "Chambre De Commerce Et D'Industrie De Pa" },
    { id: "3db53f32-6300-4c05-9d56-6a6d65f0e0ec", name: "Chaminade University" },
    { id: "bc558829-a591-484d-9b79-8023c8b3a863", name: "Champion Local School District" },
    { id: "29d9071e-1b1b-47c5-943d-8164949864c0", name: "Champlain College" },
    { id: "c9033cda-ffd2-49de-9785-b0927028fb3f", name: "Chancellor University" },
    { id: "f12d7df7-d783-44cf-8dd6-254977af25bb", name: "Chandigarh Business School" },
    { id: "8a395136-6096-4fcc-9d8b-ddb3c909fca2", name: "Chandigarh University" },
    { id: "37b7dd7f-8935-4fbc-ac84-cfc45a510734", name: "Chandragupt Institute of Management Patna (CIMP)" },
    { id: "5317798c-c827-48ac-aa69-bd2bcb58f68d", name: "Chang Jung Christian University" },
    { id: "6626ce25-f2dd-4184-9cd6-196da3a84c32", name: "Chaoyang University of Technology" },
    { id: "28bbf86a-3af3-499d-8d74-469c5999bcdc", name: "Chapman University" },
    { id: "e7f0f80f-6f9f-4ec0-959b-37c1039fbbec", name: "Charisma University" },
    { id: "5e461d2d-0051-4084-bdf8-378e15f8fc79", name: "Charles Darwin University" },
    { id: "ad1b79c9-f0a8-4a19-84fa-d1036d75641f", name: "Charles River Centre Malaysia" },
    { id: "17e9ad65-26c6-4930-adf9-b20e3ebd294e", name: "Charles Sturt University" },
    { id: "c31ec0b0-451d-445c-88af-edba42ab9767", name: "Charles University" },
    { id: "3ea46900-47f8-4d66-a9e7-ac3d2b712b07", name: "Charleston School of Law" },
    { id: "15d4de82-52c4-4d4d-bda8-8cc9694e9e8d", name: "Charleston Southern University" },
    { id: "95e6d199-9d51-4aa1-a27c-aee1d8eaaae5", name: "Charleton University Bookstore" },
    { id: "30e0ad15-1f6c-4eed-8513-edc37bc2f10b", name: "Charlotte School of Law" },
    { id: "3cd2d57b-6c90-4d8a-a917-3c2e9d5fb92f", name: "Charotar University of Science and Technology" },
    { id: "73803013-d8cb-481a-b513-c801292e3d7f", name: "Charter College" },
    { id: "664012ef-fb70-4fbd-b5f7-0960fe9bfd1d", name: "Charter Oak State College" },
    { id: "1b7bc570-e467-4f0f-8e1d-edff7392623f", name: "Chartered Institute of Arbitrators" },
    { id: "9104154f-0690-45c7-9132-c76d8c9d2ce2", name: "Chartered Institute of Logistics & Transport" },
    { id: "29ecb995-3138-4915-8792-8d31c5ec79a5", name: "Chartered Institute of Marketing" },
    { id: "6cb3dc9f-41d0-4ba6-8b91-dc217c85c314", name: "Chartered Management Institute" },
    { id: "982ee875-a700-4478-ad9e-dffc09924c8b", name: "Chatham Bookstore" },
    { id: "b01ce753-fd20-480c-8d78-6d60737519ab", name: "Chatham College" },
    { id: "9669248c-6e3c-4e27-a4a3-a0cbdface520", name: "Chatham University" },
    { id: "6819ea92-6420-45f5-a281-b5b102253c7e", name: "Chattanooga State Community College" },
    { id: "1bbec59d-918e-4cec-9ca0-fe46f612ee54", name: "Chemeketa Community College" },
    { id: "4654ceb3-3ee8-43ae-99b3-517d9c9af6d3", name: "Chemnitz University of Technology" },
    { id: "a88128a5-be80-4a0b-9877-b6a97c39dfe4", name: "Chengdu University of Technology" },
    { id: "981334f3-5c23-495b-9013-faa4707f02e5", name: "Chennai Business School" },
    { id: "89254fb7-4627-4561-81b0-2adca3e8ceb4", name: "Cheongju University" },
    { id: "34e33a28-32f0-44d4-b5ec-1ed86daa8434", name: "Cheongshim Graduate School of Theology" },
    { id: "c8d14f21-c731-433c-a878-282809ae7acd", name: "Chernorizets Hrabar Free University of Varna" },
    { id: "598419c3-53b2-4581-8dfd-af25080739ea", name: "Chesapeake College" },
    { id: "af4d24b4-01c7-4cf0-854f-19efe4c10e5a", name: "Chestnut Hill College" },
    { id: "868c3b22-2d07-4b23-9f31-0e5a804e4dd8", name: "Chetana's R.K. Institute Of Management & Research" },
    { id: "74b21600-9705-4d0a-8903-766f865457c4", name: "Cheung Kong Graduate School of Business" },
    { id: "e8eb0df8-1b44-45cc-ae01-8990531c1ad1", name: "Cheung kong gsb [*DUP ACCT*]" },
    { id: "6a0ab8ed-69ef-470a-8740-a6f51b647fc5", name: "Cheyney University of Pennsylvania" },
    { id: "a30362e3-1247-40de-b5d0-1020b3d81e64", name: "Chhatrapati Sahu Ji Maharaj University" },
    { id: "1a3c85eb-c40d-44df-958f-c659608f4db4", name: "Chhattisgarh Swami Viveananda Technical University, Bhilai" },
    { id: "06e784eb-f761-4da2-8880-b9e4df24ffc3", name: "Chiang Mai University" },
    { id: "ba3fdbbf-2960-4152-b709-870b3692040c", name: "Chiba University of Commerce" },
    { id: "696b6f31-4e5f-4780-a4e7-3c9916b30cd8", name: "Chicago Board of Education" },
    { id: "f258b02a-f84f-4c58-bb32-b116993631bf", name: "Chicago Booth London" },
    { id: "ee8336ff-4244-4a45-8b73-576b62d49a28", name: "Chicago Digital Media Arts College" },
    { id: "d0ff51a5-2cc0-43be-886c-bcc9c8387397", name: "Chicago State University" },
    { id: "0ef9b8f0-4d2a-42dc-a1c8-53315fde760e", name: "Chienkuo Technology University" },
    { id: "8d3ec76e-4209-4969-bf42-a566e0b77651", name: "Chifley Business School" },
    { id: "1bc52e30-5714-4ccb-8d54-79a7ad0c18c4", name: "Chihlee Institute of Technology" },
    { id: "d80537e7-8ce4-45bd-b62d-758d13fbfe82", name: "China Agricultural University" },
    { id: "8f6884ec-81ce-4214-ba07-83c23136a760", name: "China Business Executives Academy - Dalian" },
    { id: "fa07807d-d1f2-4c44-8d68-3981ac4875bc", name: "China Business Executives Academy, Dalian" },
    { id: "fdf56eca-1da2-4aee-b43a-7bb84414ac08", name: "China Europe International Business School" },
    { id: "db55c15f-ed09-495c-b4f7-9477e414cc9f", name: "China Foreign Affairs University" },
    { id: "4e216170-b2d0-4a14-850a-d20f61712189", name: "China Jiliang University" },
    { id: "51a62fc0-6ce1-483f-8c98-3dd7ae79ba5b", name: "China Medical University" },
    { id: "a2ffc0fa-ea13-45f3-9b23-a23537507b90", name: "China Pharmaceutical University" },
    { id: "617634f2-df18-4812-b587-bd4b05dbd91c", name: "China Unicom Online University" },
    { id: "11ed5372-c932-4c1c-bfc0-d177b960bcab", name: "China University of Geosciences" },
    { id: "0f410c19-9198-4ebf-9338-2ed73519ac13", name: "China University of Mining and Technology" },
    { id: "ae700899-fe02-4052-8533-f67fadbf2675", name: "China University of Political Science and Law" },
    { id: "8cd2f960-5d25-4ee0-8317-46147540e539", name: "Chinese Culture University" },
    { id: "83846337-1e91-4129-8ae9-ade4a38ed7dc", name: "Chinmaya Vishwa Vidyapeeth" },
    { id: "62aa0e53-924c-4f5d-af24-16a71257551f", name: "Chipola College" },
    { id: "fa7b5316-c18c-46fc-a029-f2e85dc3d70f", name: "Chippewa Secondary School" },
    { id: "0240b8a3-4d98-4c8d-9280-dbbb68376d28", name: "Chippewa Valley Technical College" },
    { id: "91ab1b98-88b2-4e2c-a32d-3a409e908ce6", name: "Chisholm Institute" },
    { id: "310f2a94-9d9b-4969-9d29-1ceaf766e188", name: "Chitkara University" },
    { id: "e0775f04-9627-48a2-a982-9f38e8b67136", name: "Chittagong Independent University" },
    { id: "f19cba31-accb-4836-a8b5-fe8f5210473b", name: "Choate Rosemary Hall" },
    { id: "739fcdf4-1d81-4c55-942c-7bc9a56f831f", name: "Choithram College of Nursing" },
    { id: "ad9da1db-3a01-4e41-9cfe-ddf72abda280", name: "Chonbuk National University" },
    { id: "9782e493-ae05-4bb5-8add-adcecf3c1938", name: "Chongqing University" },
    { id: "8e05da09-7987-4ac7-a3fc-20287bc5d6d4", name: "Chonnam National University" },
    { id: "62ea1839-8c29-4ce4-a014-56a19b373885", name: "Chosun University" },
    { id: "5f53dce6-d750-4095-b897-5c61986efe80", name: "Chowan University" },
    { id: "efa07ebf-af68-4514-92f8-4bc695620a97", name: "Christ Institute of Management" },
    { id: "5d738917-a7b0-43c6-a2c6-285595f08036", name: "Christ University" },
    { id: "a609675b-3bba-4973-9349-e264ec7439cf", name: "Christ University - Institute of Management (CIM)" },
    { id: "840a2a0a-94f1-4d52-894e-c8674d9d3364", name: "Christchurch Polytechnic Institute of Technology" },
    { id: "9e0e5441-0e38-4312-8466-dae2a2ca3b4c", name: "Christian Brothers University" },
    { id: "b2e6ddd8-81a7-4912-986e-e7241bd0d9f1", name: "Christian Heritage College" },
    { id: "c61ea728-add6-4ec0-8648-8d77c378a63a", name: "Christian Theological Seminary" },
    { id: "fd750839-c56a-4cf0-acd9-1ee538526d59", name: "Christian-Albrechts University - Kiel" },
    { id: "113f1970-b6b7-4a78-a68b-10131436976d", name: "Christie's Education" },
    { id: "b12c50a5-5809-4a06-824f-d86887088ebd", name: "Christopher Newport University" },
    { id: "f89bf073-1db5-4387-aec1-c79aa79d9d2f", name: "Chubu University" },
    { id: "a42ff0a2-764f-451a-97e4-5cc5f2c3116e", name: "Chugye University for the Arts" },
    { id: "90d3e43e-29d6-47bc-8086-31a9806a4daa", name: "Chukyo University" },
    { id: "13f15544-7b9a-44ac-99f3-05926cc5bb21", name: "Chulalongkorn University" },
    { id: "32e717dd-d071-4243-87ed-4fa411e6f107", name: "Chung Hua University" },
    { id: "037f2455-54ce-4db1-ad6b-9d20715353b8", name: "Chung Yuan Christian Univ" },
    { id: "abb0b52d-2389-44c2-b970-367f3d21583c", name: "Chung-Ang University" },
    { id: "0aba95ff-9d05-4362-a449-ec39a2650531", name: "Chung-Hua Institution For Economic Research" },
    { id: "59fc4366-20c4-46cc-a267-9a2fbeaf79eb", name: "Chungbuk National University" },
    { id: "8d7c83b8-5b2f-4548-b0c7-07b7efe152a3", name: "Chuo Gakuin University" },
    { id: "6ace1878-780d-4f1f-af08-bc5ddf225027", name: "Chuo University" },
    { id: "9363ac6c-405d-4388-9175-e6d5a65ba795", name: "Church Divinity School of the Pacific" },
    { id: "e923b9ef-1a7a-4e0a-8c1e-44388b7a0d0d", name: "CIBERTEC" },
    { id: "ba524cc1-9820-4f88-bd4f-01af7770afcb", name: "CIBS (Canary Islands Business School)" },
    { id: "ac2f6e3c-b70d-4cf0-aca3-212ef79fbe32", name: "CICESE" },
    { id: "f09a690f-d74a-4a74-b6c0-d521d7dca7c5", name: "CIMA Chartered Institute of Management Accountants" },
    { id: "1f412e6b-6a7e-4690-984c-d79b8099bd06", name: "Cincinnati Christian University" },
    { id: "3ad27f07-31f0-477c-a2cb-a32729022f72", name: "Cincinnati State Technical and Community College" },
    { id: "2a1157a2-3225-43f7-b684-c862a62068ca", name: "Ciputra University" },
    { id: "93ab25c8-082f-47ab-af1b-fb5aede6f7fa", name: "Circleville Bible College" },
    { id: "30b37039-17e1-45dd-9d3e-7c635f31d075", name: "Circondario del Tribunale di Napoli" },
    { id: "2bfa74f4-a326-438a-83d7-a5d519cfa714", name: "CITI Private Bank" },
    { id: "37e697e7-153f-4b74-9bbc-0617d88706db", name: "Citibank Na" },
    { id: "eec20bcc-24c1-4f56-a598-f2dac9757895", name: "Citrus College" },
    { id: "430dc982-0fa1-422b-98d0-43f8ea4ec068", name: "City Charter High School" },
    { id: "f5a62479-7c2a-4493-afc4-9d21bfed4a06", name: "City College International Faculty of the University of Sheffield" },
    { id: "106ae9ba-a7dc-4134-b60a-4ab500a275ea", name: "City College of Fort Lauderdale" },
    { id: "5b9a4bc5-77dd-4125-b8b6-37fdcb7f544c", name: "City College of San Francisco" },
    { id: "c47e5fe7-c673-4998-8906-7ac38bdcdc21", name: "City College, Wuhan University of Science and Technology" },
    { id: "ee7b84f7-f407-46e7-b815-87cb8f046c97", name: "City Colleges of Chicago" },
    { id: "51b6d53d-d998-4f01-90ea-93d12795ef04", name: "City Lit" },
    { id: "902d6d61-a084-412d-b99f-12e3fe1c5eb8", name: "City of Glasgow College" },
    { id: "499d47b1-b95e-4959-9594-080fa1c6e449", name: "City University College of Ajman (CUCA)" },
    { id: "e609aac5-9b06-48c9-b994-9f2565a7cb94", name: "City University London" },
    { id: "5992013c-8b80-41ac-9bbd-aea73187062c", name: "City University Malaysia" },
    { id: "72050c38-1fa5-4e90-83c1-225e15e8ac6b", name: "City University of Hong Kong" },
    { id: "48994c8a-3401-4024-ab58-57b230babb20", name: "City University of Seattle" },
    { id: "49e4b7eb-eb6d-421d-9b34-63c393eabc5a", name: "Civil Service College" },
    { id: "edeb9aac-da9d-48ec-9221-e082c21b0801", name: "Clackamas Community College" },
    { id: "3568ef5b-8825-4bed-bba9-c7cc15925c25", name: "Claflin University" },
    { id: "062a2d94-fc8c-4b3f-abf6-2e46a86e71ea", name: "Claremont Graduate University" },
    { id: "af330513-8960-4d01-a5f6-21f20ea54a91", name: "Claremont Lincoln University" },
    { id: "503a36b2-300a-4823-8cf9-3554b88d9fcc", name: "Claremont McKenna College" },
    { id: "ef87e1a9-0acd-4a83-8ad5-33d24da3d0d8", name: "Clarewood University" },
    { id: "17074fe3-fff4-44bc-bb26-f531d75e94e0", name: "Clarion University of Pennsylvania" },
    { id: "dd94b4a2-c46e-473e-a8de-9eb4c1a7a701", name: "Clark Atlanta University" },
    { id: "86848895-7906-40f5-9ded-89cc36c9c883", name: "Clark College" },
    { id: "251eb31b-d36b-4ec1-9c2e-bd5a1e246092", name: "Clark State Community College" },
    { id: "0e05626c-3a17-45e1-9156-d43a44e14d7c", name: "Clark University" },
    { id: "6b012590-5279-4f8d-941d-e0602d9d84a2", name: "Clarke College" },
    { id: "82611356-36db-4806-89fd-ae647ea95a43", name: "Clarke University" },
    { id: "28b76572-1509-4337-8935-e8f0f61f5626", name: "Clarkson College" },
    { id: "1195752d-162d-40c3-bb6d-f96c80d25d83", name: "Clarkson University" },
    { id: "681aed8e-3670-4d3f-8be5-ae3e1b43d234", name: "Classic Private University" },
    { id: "6ad5fc33-fc13-415b-b000-260dc959c2fe", name: "Claustro De Profesores" },
    { id: "40656ba0-9ed2-4761-91f9-e2bcab977694", name: "Clay High School" },
    { id: "c0f19f68-f6f7-4c1f-a024-8d041db14181", name: "Clayton State University" },
    { id: "72199917-e24c-4aa3-b784-1d7054a1fb16", name: "Clearwater Christian College" },
    { id: "71f75950-a3c2-4ac6-949e-977da2fc0a87", name: "Cleary University" },
    { id: "8ca8e26c-7f4c-4a92-a354-a2101c51ba20", name: "Clemson University" },
    { id: "f4dba09f-686a-46c9-9c41-211fe111ee94", name: "Cleveland Clinic Academy" },
    { id: "350171b1-2881-4540-b01d-c811bad2bd3c", name: "Cleveland Community College" },
    { id: "6a6040b9-50f2-4232-8683-b041b36cae64", name: "Cleveland State Community College" },
    { id: "114df7cc-f5a7-4711-a743-0c417d5648df", name: "Cleveland State University" },
    { id: "56908fa1-0fd1-4d08-a631-65347e2ddb7c", name: "Clintonville Public School District" },
    { id: "215a36e6-9d03-43e7-91b5-f2ad33a72d44", name: "Clovis Community College" },
    { id: "c5d40213-2648-4be4-b446-c79fadc0147b", name: "Club Managers Association" },
    { id: "6f4f7335-6a44-4496-967a-2f134cf0747d", name: "CMC Graduate School of Business" },
    { id: "4976bf54-ab5a-4216-95d8-2982214f8904", name: "CMJ University" },
    { id: "0018e0ca-c8ee-46dc-94ee-35152ea7e110", name: "CMR Institute of Technology" },
    { id: "bca21fb7-f8c9-4702-92c3-a82851adef51", name: "CMR University" },
    { id: "a426bdf4-76e0-4958-ae57-65bfcd7eb224", name: "CMS Business School" },
    { id: "c833fe01-c82f-4af5-8934-9d7f9e9b7892", name: "CNEH" },
    { id: "40c5ee26-a8f3-4989-baa0-f991d8f10f49", name: "CNRS - Centre national de la recherche scientifique" },
    { id: "5b3ab294-7f96-4810-92f9-cbc70c87ad36", name: "Coaching Sistémico" },
    { id: "8dd9e488-dd11-4125-9d4e-d6fe35ed3375", name: "Coast Community College District" },
    { id: "ffe81794-0f7a-4190-ab26-0c7da87f1477", name: "Coastal Carolina University" },
    { id: "b269d736-aafd-439e-b1b1-abf696fdf290", name: "Coastal Pines Technical College" },
    { id: "03809602-04d1-4192-81c8-c4285355d734", name: "Cochin University of Science and Technology" },
    { id: "e6fc994b-d71b-42c9-86ed-d8860a289d10", name: "Coconino Community College" },
    { id: "8e8afba8-8ecd-40f9-92f9-0749b7f36d89", name: "Codman Academy Charter School" },
    { id: "610e0924-744a-4275-a245-3849f7548962", name: "Coe College" },
    { id: "99cf67c1-2bb9-4628-9884-e99ec0b7b623", name: "Cogswell Polytechnical College" },
    { id: "d864bdd1-58e1-4064-a873-252a33b72f63", name: "Coimbatore Institute of Technology" },
    { id: "91163c06-a006-44fd-aed1-aa3fc29a23ca", name: "Coker College" },
    { id: "e57c90b8-b438-4f04-bc3c-be16518f6d34", name: "Colby College" },
    { id: "56011e3b-cfc6-4f5d-b31e-2c27e18ade15", name: "Colby-Sawyer College" },
    { id: "cdd215c7-9c98-457e-a2f8-844185d557aa", name: "Colegio de Economistas de Valencia" },
    { id: "16b4e0f6-8aad-4987-8e49-c815e8211a77", name: "Colegio de Postgraduados" },
    { id: "00dea96d-396f-413c-9f45-5be499833951", name: "Colegio de San Juan de Letran" },
    { id: "d3496302-90a7-4f9c-9f0b-c496626c3759", name: "Colegio Maya" },
    { id: "1e1c4cb7-d7b8-4e7a-93f9-24f352b93573", name: "Colegio Oficial de Agentes Comerciales de Málaga y provincia (COAC)" },
    { id: "666de64e-d5d4-4408-ad32-11949fdb4d39", name: "Colegio Rio de la Plata" },
    { id: "abc3004c-c800-48e7-bf75-b90a0c93b1a6", name: "Colgate University" },
    { id: "9731921f-b2a1-44fa-ab3a-1d72e8a3ea9c", name: "Collarts" },
    { id: "f92cdad8-e9f5-44fc-898c-b5ddc3474ed9", name: "Collective Academy" },
    { id: "9d35df51-ea36-4db3-917e-bd1ea44c25fa", name: "College America - Colorado Springs" },
    { id: "4b9e7a1a-9d7c-4dc5-ae36-109d8f42e421", name: "College America - Denver" },
    { id: "81787cec-6263-45fb-90ef-540ba39e6914", name: "College Bookstore" },
    { id: "1686c042-ebd7-4796-9d69-f38144c5689d", name: "College Dawson" },
    { id: "b36f431c-10e2-4a11-ac9f-37bb7d979d38", name: "College Des Ingenieurs (Cdi)" },
    { id: "1b11b31e-4b2f-4aef-8fb3-d4d103cf9dc6", name: "College Des Medecins Du Quebec" },
    { id: "60a2ead1-0d2f-47af-afd2-466f3b33906a", name: "College ESLG" },
    { id: "ee0adbc7-25c1-4b13-b67b-bbc99da350d2", name: "College for Creative Studies" },
    { id: "605594eb-9041-4fcf-ac1f-a18948c344d2", name: "College for International Studies" },
    { id: "43c324d1-0d14-4c3b-b152-1d62db8a7730", name: "College of Agora" },
    { id: "147f8c17-e03b-4863-b944-f59475f7715e", name: "College of Agribusiness Management" },
    { id: "eba88e37-e1b6-407c-aae0-6a3e8229db13", name: "College of Alice & Peter Tan" },
    { id: "e9efbcd3-93e3-4fca-af3a-85fbea342eed", name: "College of American Pathologists" },
    { id: "5345b520-08ec-4ce5-9fdc-2ee11e607048", name: "College of Applied Business" },
    { id: "4147b674-e901-4197-9d6d-ac4706ba8fe9", name: "College of Banking and Financial Studies" },
    { id: "189a55a5-d1b8-4a19-81c6-108c04cb6702", name: "College of Biblical Studies" },
    { id: "e0e6274a-7fac-4445-a701-4da422c81e4a", name: "College of Business Administration (CBA)" },
    { id: "d142eff5-f707-43f3-9c9f-49ffb87c1772", name: "College of Central Florida" },
    { id: "4562fa09-78ac-4f00-bc84-27d891284be0", name: "College of Charleston" },
    { id: "249866f7-a591-4b70-aac0-a37f29c20f8c", name: "College of Coastal Georgia" },
    { id: "f5cf2f6a-1d30-434e-ada4-649208c8968b", name: "College of Dunaujvaros" },
    { id: "aea5b632-4eb2-40c7-a79a-5ccf7c3ffcf3", name: "College of DuPage" },
    { id: "8eecec65-5a8c-4318-afd1-29830aee9967", name: "College of Economics and Management" },
    { id: "c16cb936-abf1-4b8d-b6f2-ce7c8d78fbf4", name: "College of Engineering - Pune" },
    { id: "15a54973-d3f6-45cc-8e1b-70a14142873f", name: "College of Europe" },
    { id: "32edfb32-8f19-44f5-9038-49ba7696ab58", name: "College of Lake County" },
    { id: "cb704abd-570d-4b8a-bd83-36bf3875a0c0", name: "College of Law and Business" },
    { id: "832ad420-67db-40a8-beeb-9e1b73e47b4b", name: "College of Management" },
    { id: "51c85612-5510-405e-8fec-4c180b537076", name: "College of Management, Mahidol University" },
    { id: "72f0a3a8-62da-4ffb-aeea-83309e25263f", name: "College of Mount Saint Vincent" },
    { id: "a54ccbae-be85-494a-9b66-22447c659469", name: "College of New Caledonia" },
    { id: "d319fc98-59a3-474c-b730-c5e4daa3fef8", name: "College of New Calendonia" },
    { id: "0326784d-87a6-48eb-b332-ff24bb51ac16", name: "College of New Rochelle" },
    { id: "17e5b9cf-ae61-406b-9eb0-d8887a3c46d0", name: "College of Notre Dame" },
    { id: "ce145d0b-ba1b-4cbe-9858-d556b3e816b2", name: "College of Polytechnics - Jihlava" },
    { id: "8292386d-31f2-466f-b349-577252ca8d3b", name: "College of Saint Benedict and Saint John's University" },
    { id: "5c574753-8f51-4218-8222-1f8d0f37ab33", name: "College of Saint Elizabeth" },
    { id: "9597ec9a-2b63-4416-a8dc-675e0ff06e2a", name: "College of Saint Joseph" },
    { id: "27e4b635-6077-486a-908c-acc6af0b0fde", name: "College of Saint Mary" },
    { id: "e1222407-940a-40bd-9b89-f23645562d84", name: "College of Saint Scholastica" },
    { id: "d3409f63-c0eb-4042-9d9b-a077c0b6bb6a", name: "College of San Mateo" },
    { id: "568ecc7a-f06e-403d-829c-fda7fc138ec2", name: "College of Science, Technology, and Applied Arts of Trinidad and Tobago COSTAATT" },
    { id: "04eb12f5-4ec4-467a-804f-90c158f653d7", name: "College of Southern Idaho" },
    { id: "0751dd85-8235-44cc-9460-f4de4a07d5af", name: "College of Southern Maryland" },
    { id: "8b88cf21-94af-4d59-8c81-0b6b28fac40f", name: "College of the Albemarle" },
    { id: "1af88793-73b8-486b-bdb2-6afb5ed418e9", name: "College of the Canyons" },
    { id: "55c784b3-5f4f-4ec6-a75e-ffecf788bdcd", name: "College of the Desert" },
    { id: "b1e1e48b-a82f-4d11-b8d1-17bcdef8de45", name: "College of the Holy Cross" },
    { id: "3974b273-35ce-4ea5-9cfd-6ff13554d6d3", name: "College of the North Atlantic" },
    { id: "fc8189d5-6085-42fc-92b2-fd71515e62bd", name: "College of the Redwoods" },
    { id: "c88e2bf6-61f2-425c-ad15-1ff41582e746", name: "College of the Sequoias" },
    { id: "a64486eb-2d09-4ee2-aca1-fec6ae29646c", name: "College of Tourism and Hotel Management" },
    { id: "40741843-92c4-46b7-bcab-ffd456c5a207", name: "College of Western Idaho" },
    { id: "a6ebccf5-f94c-405f-8fba-eb23527348cf", name: "College of William and Mary" },
    { id: "2b455862-ac64-4316-81e9-d832fad32ede", name: "College of Wooster" },
    { id: "d1d72d66-a270-4889-b7e5-5f7d0356c657", name: "College Store" },
    { id: "7923345e-51f9-4d53-9b2f-f35893fb6659", name: "CollegePlus" },
    { id: "81d1f04b-8f99-40b6-930a-8d1e9cc2e4bd", name: "Colleges of Nanoscale Science and Engineering" },
    { id: "6617edc0-3306-4cdd-9882-e63ea01bfadf", name: "Collegi d'Aparelladors de Barecelona CAATEEB" },
    { id: "5798819c-255d-48e0-92e8-0f4faa20ce83", name: "Collier County Public Schools" },
    { id: "eb1bd5c6-c613-4a83-9033-0644e0c043a3", name: "Collin College" },
    { id: "e0eebcab-c7d5-479e-8f0b-84ea566828eb", name: "Collège de Rosemont" },
    { id: "2bac8c19-1f9b-4edc-b0f6-191b1394de59", name: "Collège Jean-Eudes" },
    { id: "21def872-863a-42e5-a2c8-786c1616984b", name: "Cologne Business School" },
    { id: "341aa02e-b98c-421c-909e-e787f577c005", name: "Cologne University of Applied Science" },
    { id: "fb9778cc-5600-4c90-9684-8dd312995fff", name: "Coloplast A/S" },
    { id: "efb51c9e-1889-47b7-bd53-e5448161bc08", name: "Colorado Christian University" },
    { id: "ff6ed059-e73d-4dba-a837-5256ec494c01", name: "Colorado College" },
    { id: "7604df3c-af9c-4304-a23a-80b1e79fa013", name: "Colorado Heights University" },
    { id: "9189252c-9497-467b-be29-fdbfcb944037", name: "Colorado Mesa University" },
    { id: "8e6eb1f8-4622-4daa-afd3-ce8263dc345f", name: "Colorado Mountain College" },
    { id: "efef001a-4b69-43c8-b49f-ddac93f043f3", name: "Colorado School of Mines" },
    { id: "dfe777de-01d6-4f8f-aeaa-77605807ca27", name: "Colorado State University" },
    { id: "b8408e3f-310f-4d46-af5c-6ccfa204ed40", name: "Colorado State University - Global Campus" },
    { id: "1660f32d-b7b2-49cf-99fe-7bfcd4cac87b", name: "Colorado Technical University - Colorado Springs" },
    { id: "aecb5b01-e1ad-471e-9997-feefb38372e0", name: "Colorado Technical University - Denver" },
    { id: "8df0b86f-e997-406d-b263-1d580b5a2c86", name: "Columbia Basin College" },
    { id: "cb83f502-4da1-4e60-870a-956b1387561d", name: "Columbia Bible College" },
    { id: "369a58c1-44e4-4fe3-9efe-319e4a58feaf", name: "Columbia Central University" },
    { id: "674e6d89-d163-4316-b807-24fc993dfdfd", name: "Columbia College" },
    { id: "d59f0160-68ba-4810-b82b-8ef253068c45", name: "Columbia College - Canada" },
    { id: "35d509a6-7fca-476d-a7a6-914171aaa592", name: "Columbia College - Chicago" },
    { id: "0f5e9ad6-bd91-4275-bc3b-8a2199f6fa3a", name: "Columbia College Follette Store 0085" },
    { id: "ac775698-c752-4f36-867d-f2773335b8f0", name: "Columbia College of Missouri" },
    { id: "f3e5c263-dbff-4174-a72b-b024beee6ebd", name: "Columbia International University" },
    { id: "52c6cc62-23ae-4edc-87d3-06ab54d18a96", name: "Columbia Law School" },
    { id: "92bbc9a1-e8aa-48a4-82ce-a6f92a6aba19", name: "Columbia University" },
    { id: "bc85b6ce-0335-4545-83d3-3f8f2050aca2", name: "Columbia-Greene Community College" },
    { id: "f6b1a85e-3a78-4ce4-a8a0-ec603046de70", name: "Columbus State Community College" },
    { id: "664f44e4-6b74-43b0-9b2c-30b06c446584", name: "Columbus State University" },
    { id: "84e63118-2159-4711-bceb-da10b2744a67", name: "Columbus Technical College" },
    { id: "20d42978-ff8e-4110-aea8-5586f7225d23", name: "Comenius University" },
    { id: "94d6276a-7115-4f38-84fc-82f53c7b298a", name: "Commecs Institute of Business and Emerging Sciences" },
    { id: "85c195f1-3480-4f02-ab37-cc9ee8ccf4f1", name: "Commonwealth School" },
    { id: "2d7eeec2-d3ba-4513-b8a6-ec3c952f0d73", name: "Communication University of China" },
    { id: "4a72cfeb-b8cf-43ea-b66f-1def94b282c5", name: "Community and Tech College of Shepherd" },
    { id: "2d91ec56-3806-4d99-9383-788c653832a8", name: "Community Care College" },
    { id: "64015256-a2a9-48c2-be9c-46bf8ff12720", name: "Community College Baltimore County Essex" },
    { id: "ce21e93c-d793-4115-ade1-a67b868f321e", name: "Community College Dunedin" },
    { id: "60b677e9-c83e-4ba8-bb2c-8d72c4bb6f93", name: "Community College Gippsland" },
    { id: "90638c49-cddb-4b42-bd34-de1143bab9f5", name: "Community College North Canterbury" },
    { id: "7dded636-2b9a-47c0-9f97-c7627f368246", name: "Community College Northern Inland" },
    { id: "e3c9ae96-80e8-46d2-9197-0ceb5ddc452e", name: "Community College of Allegheny County" },
    { id: "928b69d2-70eb-452e-93c4-61fe4a6ebe64", name: "Community College of Aurora" },
    { id: "bbd300e1-e43f-4cc8-8a78-5a182ffdb7d4", name: "Community College of Baltimore County" },
    { id: "dc4921e5-75f1-4a2a-a0b0-9f07dd076bd1", name: "Community College of Beaver County" },
    { id: "895ed118-399d-4572-ad98-73294abadcea", name: "Community College of Philadelphia" },
    { id: "2beb262a-c81d-4b27-bd4c-f04704b4ab13", name: "Community College of Qatar" },
    { id: "4cb28871-bcd6-45b4-bee0-0d1c4bbe635d", name: "Community College of Rhode Island" },
    { id: "d7952cd1-7a99-42da-9cfb-a496e967a449", name: "Community College of Southern Nevada" },
    { id: "81f0e380-942e-4aaa-88e1-da66d63a7a93", name: "Community College of the Air Force" },
    { id: "59a64042-07f9-417f-9533-e872c2c68f7a", name: "Community College of Vermont" },
    { id: "3cc037be-d820-4311-adaa-b1e264c958a1", name: "Community College System of New Hampshire" },
    { id: "61f31c41-a696-4df1-93e7-3ea5838f6ad9", name: "Community College Warrnambool" },
    { id: "a83d8812-e011-47cd-bca3-bca943c8fe98", name: "Complutense University of Madrid" },
    { id: "babc6187-b54e-46ce-a063-0a8e6c7c610d", name: "Compton Community College" },
    { id: "512645d3-a438-4d69-9f06-271dea393298", name: "Computer Power Institute" },
    { id: "827501d5-225f-4146-838a-c2d856eba1ea", name: "COMSATS University Islamabad" },
    { id: "afc1c8aa-63d1-48db-b23d-465084ef850c", name: "Concepts Institute of Education" },
    { id: "501702b6-302e-4818-8700-feba8b67acb4", name: "Concord Academy" },
    { id: "85a1eb89-0a52-46cb-ab0b-3e46b04dacdc", name: "Concord University" },
    { id: "0a75fdd2-3a09-4490-82e5-e544fda324fb", name: "Concordia College - Alabama" },
    { id: "3f54c519-e023-45fa-9adc-52fdd0791071", name: "Concordia College - Michigan" },
    { id: "57816c0d-fed0-4489-a3e6-2efc6ee85ffb", name: "Concordia College - Moorhead" },
    { id: "2fdb3cc6-3a05-45fa-90c7-f90af5c4530d", name: "Concordia University - Ann Arbor" },
    { id: "3d82adf6-138d-4985-ae93-d67289a25974", name: "Concordia University - Canada" },
    { id: "1b655a03-6864-4d91-b79d-671c62e004ee", name: "Concordia University - Chicago" },
    { id: "047220e0-21e7-41f4-8c0a-7353c4ee8a84", name: "Concordia University - Edmonton" },
    { id: "d33c6825-aee4-4f5e-96a8-c54fa39e3d57", name: "Concordia University - Irvine" },
    { id: "57711864-9fe3-4dab-8214-51c53cf4592f", name: "Concordia University - Nebraska" },
    { id: "bb77ef1a-4ee7-4917-b36a-0053f1bf5404", name: "Concordia University - New York" },
    { id: "8ba0aaa7-dc60-40c5-8fe0-47ef58a29a93", name: "Concordia University - Portland" },
    { id: "5fed2d32-866b-49c5-bbe0-9e3a8f180367", name: "Concordia University - Selma" },
    { id: "1486dacc-0bb8-4b41-8cb6-0db04bb71453", name: "Concordia University - St Paul" },
    { id: "18eb6259-941e-4450-aed2-5fb2928948bb", name: "Concordia University - Texas" },
    { id: "ba2514af-2219-42f1-901c-b7564a2b64fa", name: "Concordia University - Wisconsin" },
    { id: "70e5249b-bd5b-46f4-a8e4-404706515d29", name: "Concordia University - Mequon" },
    { id: "fd43fd68-99d6-4bbd-ae48-effec03aa7a1", name: "Concordia University - Saint Paul Bookstore" },
    { id: "d9c4fc1b-45fb-4115-be09-ccb0668a8625", name: "Conestoga College" },
    { id: "98d070f7-9487-4d28-a67e-e5a516b0eb51", name: "Connecticut College" },
    { id: "2ed15ded-fc49-4db1-8add-7b2889d14bd4", name: "Connecticut Textbook Exchange" },
    { id: "7b521e10-cfc8-4850-8aba-5865ba18d834", name: "Conrad School of Science" },
    { id: "ab3dd63e-a417-4eea-bad1-d9ab9e232d39", name: "Conservation Learning Campus" },
    { id: "643f6dfa-ca21-482e-a8e4-d8ee1947465a", name: "Consolidated High School District 230" },
    { id: "af605c16-c22d-4060-95c1-5f6e46ff444a", name: "Consolidated School District" },
    { id: "40488e71-d755-473d-92cb-c06554c43840", name: "Consorzio Sdipa" },
    { id: "3087a725-c334-4e0b-b6ad-ab257c90a655", name: "Consultant Management Group" },
    { id: "41833d55-9e44-4eee-a210-a0148987c89f", name: "Consumnes River College" },
    { id: "1a7edf13-6860-4b0c-bcfc-7b27055da6d3", name: "Contents Works Inc" },
    { id: "5f449d7a-e1aa-48b4-b25d-0001c381559c", name: "Convent College" },
    { id: "f24d689e-c3fa-4233-be72-d4d7e6147421", name: "Converse College" },
    { id: "dc255ec8-8fc0-4a6f-bfd3-120ae62e65ad", name: "Cooperative College of Malaysia" },
    { id: "073c54ee-a341-4d4c-9a2f-1abbcd7ddb54", name: "Copenhagen Business School" },
    { id: "b6158138-1b94-4faf-956b-caaec9431c77", name: "Copies-N-More" },
    { id: "11ba9b4c-f647-4b3d-96af-29981bc34d15", name: "Copley Publishing Group" },
    { id: "e566fc6b-e5b0-420d-a39a-e17f56d5270e", name: "COPPEAD" },
    { id: "cc2faaee-a270-4747-b7c5-23f486777285", name: "Coppin State University" },
    { id: "f7bbdacc-e8d8-4523-ae53-496bc4928de0", name: "Copyworks" },
    { id: "89911f54-5bfd-4aab-8f4e-de6bba968ff1", name: "Corban College & Graduate School" },
    { id: "d88a7c4d-7a91-4dfd-8bd3-7d70b825ab73", name: "Corban University" },
    { id: "7c16a3bf-c00b-42ce-b4b3-ae4c7b7b7205", name: "Core Business School" },
    { id: "96b64020-f225-4a0b-92bc-bdfc10902141", name: "Corinthian Colleges Inc." },
    { id: "6622d9e8-c269-4657-89d8-72565cd98912", name: "Cork Institute of Technology" },
    { id: "4a191ba7-22d2-40e7-aa47-191af0927faf", name: "Cornell College" },
    { id: "e09776b3-0e38-41b4-8714-b3e7e60faf7c", name: "Cornell Insistute of Business and Technology" },
    { id: "84f9203a-bad6-43b3-8837-e05aa1841815", name: "Cornell NYC Tech" },
    { id: "4bccc02e-4440-4a35-8fb6-bbb44811397b", name: "Cornell University" },
    { id: "f73ad7cd-fb20-4e65-93db-3921e57c5116", name: "Cornell University - College of Human Ecology - Sloan Program in Health Administration" },
    { id: "c3fba56e-3b5d-437f-8f6e-2eb7e05aed7d", name: "Cornell University - Institute of Labor Relations" },
    { id: "8cb90e38-e9c1-422c-985d-07d125f25279", name: "Cornell University - Samuel Curtis Johnson Graduate School of Management" },
    { id: "8acb6eda-1778-494f-8952-4c7bf1296585", name: "Cornell University - School of Hotel Management" },
    { id: "6889d089-79f1-4f49-8bb6-cd50b6db02fd", name: "Corner Brook Regional High" },
    { id: "cc5877f1-24c9-42d7-8245-76c62c72cb0d", name: "Cornerstone International College" },
    { id: "c77b9ada-3022-467f-a7ba-9a70fc74e8c3", name: "Cornerstone University" },
    { id: "1f847cb3-e5f4-4688-87a6-d3e2fb4f9b13", name: "Corning Community College" },
    { id: "8fd123b3-4237-4b35-88c7-0d3f084750c0", name: "Cornwall College" },
    { id: "6b788085-0916-4954-b82e-2a95516ac11e", name: "CORO" },
    { id: "c6294f56-38fd-4aaf-9022-02c503d6f090", name: "Corporate Edge Ltd" },
    { id: "e9c0fb80-cc71-4bf2-9ac3-edb80890f242", name: "Corvinus University of Budapest" },
    { id: "262d86a5-4a7e-4f10-93bc-26b43a098673", name: "Cosumnes River College" },
    { id: "a3e69580-58e6-49ab-9ad6-3ce14b39383d", name: "Cotrugli Business School" },
    { id: "4b6105af-ef06-4459-a8db-953ec716e48d", name: "Cottey College for Women" },
    { id: "60435b04-48d7-4960-908d-b8b12a966995", name: "Council of Pacific & Prairie University Libraries" },
    { id: "dffb286a-b482-49e5-85af-7ec40f4794bc", name: "COUNCIL OF SUPPLY CHAIN MANAGEMENT PROFESSIONALS" },
    { id: "2d36b89f-0383-4291-a468-40498e5a22c4", name: "Council Rock School District" },
    { id: "f8127b3c-9a78-4391-8bfa-935d4c8d3572", name: "Covenant College" },
    { id: "cfc09af2-0367-4383-b872-b4dd306e5371", name: "Covenant Communication" },
    { id: "84bf8456-da6a-4dd1-8bf5-eab287e31306", name: "Covenant Theological Seminary" },
    { id: "3f951a08-c032-402d-b6cb-1d31df12863c", name: "Covenant University" },
    { id: "7cba8abe-a6c7-4191-9253-e4a9eef92af0", name: "Coventry University" },
    { id: "ecf97070-50ec-4ca8-9170-b62589238fe7", name: "Coventry University Branch at The Knowledge Hub Universities" },
    { id: "a1c583b8-942e-4e64-920c-17767bb594f0", name: "CPP Exec Ed Pricing Recon" },
    { id: "8ffab34d-2997-4683-8ed9-2ffcfd85b51a", name: "Cracow University of Economics" },
    { id: "47563083-fc68-4e17-8c4b-00362635e425", name: "Crafton Hills College" },
    { id: "08dd4fa6-3efd-4888-af3c-16bc9780c8ed", name: "Cranfield University" },
    { id: "587aaccc-1e82-4391-bdcf-3163d633a1e2", name: "Cranford College" },
    { id: "ca520309-71ce-4ed5-a6fc-505c8d9b829e", name: "CRCGM - Centre de recherche Clermontois en Gestion et Management" },
    { id: "91896460-bdb4-48bf-a43b-8e233f4bd6a5", name: "Creighton University" },
    { id: "fd71b94f-f3db-4ced-9bac-7bafbf4786e8", name: "CREPUQ" },
    { id: "0d5f6256-e997-4084-8d22-71a70896cdfe", name: "Croft Centre" },
    { id: "a72c3dd1-65e0-4705-92c9-109f97c27eb0", name: "Crossroads Bible College" },
    { id: "a1dfcb44-743e-493c-a427-088698308d15", name: "Crown College" },
    { id: "e121982d-5274-49c0-9bcf-7abf2cd1b827", name: "CRPM" },
    { id: "29ec7ec5-bd53-4081-be1f-0aed482ded5d", name: "CSAC Institute for Excellence in County Government" },
    { id: "6ef02c31-481a-4ca7-88fa-16a73833d7b8", name: "CSU Channel Islands" },
    { id: "e92fb729-1d50-4bcc-9de1-9bdfd91c07e6", name: "CSUMB Library" },
    { id: "db8414a9-d4bf-4034-b16c-d8fb54432310", name: "Cuesta College" },
    { id: "52f5354d-76bf-440e-acc8-610d849416bc", name: "Cukurova University" },
    { id: "cfad8533-ccdd-472e-8d5d-eaee1f02f27a", name: "Culver-Stockton College" },
    { id: "2211b474-845a-4263-885c-25f3238b4ae7", name: "Cumberland University" },
    { id: "6a66d70c-93c4-42d2-80af-2b191c4ede13", name: "Cumhuriyet University" },
    { id: "9e773cda-c4a6-4117-8b89-f0ef6b30dfed", name: "Cummings Institute" },
    { id: "3bdf163d-fd2b-4a3e-a316-69ffb3022c1b", name: "Cummins College of Engineering for Women" },
    { id: "9c0785c2-2317-464e-afe0-cb39570cce3a", name: "CUNEF Universidad" },
    { id: "2de9a884-b6db-478a-87cc-321100b37fae", name: "CUNY - Baruch College" },
    { id: "ab324284-96ab-4135-9085-81e96d47d8e7", name: "CUNY - Borough Manhattan Community College" },
    { id: "dd0805be-559c-4e06-bfc6-c8183c63772f", name: "CUNY - Bronx Community College" },
    { id: "8e3a61a8-cf11-452f-94bb-b4ca33187b64", name: "CUNY - Brooklyn College" },
    { id: "57e6cf1e-917b-4c05-af5d-70ebe7013400", name: "CUNY - City University of New York" },
    { id: "c902fd26-f51e-4d0c-8cfb-684fab481484", name: "CUNY - College of Staten Island" },
    { id: "f15593ae-9c01-4ad3-b63e-f4bd9aeedb53", name: "CUNY - Graduate Center" },
    { id: "b18ac413-ff23-4b21-993e-a6c71816c918", name: "CUNY - Graduate School of Journalism" },
    { id: "3ed6a1de-ea1d-4874-bbca-26241653c96f", name: "CUNY - Hostos Community College" },
    { id: "aad773f8-783a-42e2-b2b2-a1c39cd1a29d", name: "CUNY - Hunter College" },
    { id: "de4b841e-dcd3-4d33-9d8e-22fe897cec72", name: "CUNY - John Jay College of Criminal Justice" },
    { id: "692662a7-cf46-4333-8f11-20fa2d6afec2", name: "CUNY - Kingsborough Community College" },
    { id: "b2a46162-ddec-422f-90ec-9d0e284f1d79", name: "CUNY - LaGuardia Community College" },
    { id: "74bdf9e9-19eb-400d-b149-d4076aa556e9", name: "CUNY - Lehman College" },
    { id: "472069c9-4f43-4ffb-952c-4d084854f11f", name: "CUNY - Macaulay Honors College" },
    { id: "1b006a23-4152-4ddf-af44-fec8f49fe10e", name: "CUNY - Medgar Evers College" },
    { id: "d29ed9ce-42c7-4675-b72e-1353f75ba216", name: "CUNY - New York City College of Technology" },
    { id: "90a1dd30-a36d-45c0-b1c5-5f29f8c88bba", name: "CUNY - Queens" },
    { id: "12fd08a4-17a1-4f74-9d0c-8803c3f274ab", name: "CUNY - Queensborough Community College" },
    { id: "06defe7b-6bbb-4a94-a1f2-124cd8f9fa5e", name: "CUNY - School of Law" },
    { id: "db08404a-0c07-482a-83e3-db53c3edd54b", name: "CUNY - School of Professional Studies" },
    { id: "08d9dc95-a0e7-44ed-ac77-630473c1b7d1", name: "CUNY - Stella and Charles Guttman Community College" },
    { id: "23ae5618-40e8-45cc-b933-80bb8faed6b4", name: "CUNY - The City College of New York" },
    { id: "fdbfcfca-1f0a-4deb-b0b4-52035ff88d52", name: "Curry College" },
    { id: "74b9b543-474b-4084-90cd-11243c1cd9d7", name: "Curtin University" },
    { id: "530f0c70-6177-44f7-b719-2053dd49dfa6", name: "Cushing Academy" },
    { id: "7d23c0ad-d3a9-4d98-b112-7c514d2681ec", name: "Cuyahoga Community College" },
    { id: "ef8b2bec-ec22-41f8-ac0a-9526256cc62d", name: "Cuyamaca College" },
    { id: "497d4660-982f-40fa-8575-7933d933d172", name: "Cyber University" },
    { id: "8c2238ac-f64c-44bb-9ddf-33d8996299f7", name: "Cypress College" },
    { id: "0ba85c06-0444-486d-813a-5317be5cb546", name: "Cyprus International Institute of Management" },
    { id: "fd82d3de-cd47-4ef0-9594-540b3090c239", name: "Cyprus International University" },
    { id: "3b27cdbc-ce82-47e0-ace9-f1b40b8fa81f", name: "Cyprus University of Technology" },
    { id: "61c9eda4-1b49-4fef-a706-a16b0dfc19e8", name: "Czech Managment Center" },
    { id: "4b29369a-ad36-43b2-a993-20edc2c0a34d", name: "Czech Technical University in Prague" },
    { id: "ec25bf55-ab92-4766-b8d0-73ce55962c5b", name: "Czech University of Life Sciences" },
    { id: "ea3cc053-289b-45af-bb51-99e95aa3f054", name: "CÁMARABILBAO University Business School" },
    { id: "d2401523-a753-4ab0-9232-48a5f47bdd63", name: "Cégep de Drummondville" },
    { id: "98c3124d-45e0-4c11-8517-eb5c62a776a0", name: "Cégep de Victoriaville" },
    { id: "fa48e826-0311-4de5-bde7-a441c11a92a0", name: "Cégep à distance" },
    { id: "0cd5123b-6a6b-4db1-8bd8-4c2af2a663ba", name: "D'Youville College" },
    { id: "ad02183d-83c3-453b-b8c6-e2ca00829b6b", name: "D. A. Tsenov Academy of Economics" },
    { id: "701487ce-b2d6-4411-965d-05cc0137dcde", name: "D.A.V. College Kanpur" },
    { id: "1420c9e9-e707-4628-94c2-3f568f5cea5d", name: "Da-Yeh University" },
    { id: "d47d00d1-00d3-4f44-9016-53bca5c43b4b", name: "Daejeon University" },
    { id: "4627334f-d05c-4619-afce-24b6cd26341c", name: "Daemen College" },
    { id: "ff244a43-6c59-4857-99f9-410d9514aa8a", name: "Dahan Institute of Technology" },
    { id: "3a4a978b-13dd-4e29-8fe4-ac32b5a72198", name: "Dakota County Technical College" },
    { id: "51a3ebb7-3e0e-4c0c-bf9d-d60ed21fae22", name: "Dakota State University" },
    { id: "6a2aa0d0-1666-402f-9cdb-949d4b866579", name: "Dakota Wesleyan University" },
    { id: "1a9c4fb1-72e2-460c-b272-ece3d6f03c67", name: "Dalarna University" },
    { id: "61e7bd88-078b-4558-b831-090cc66f71df", name: "Dalhousie University" },
    { id: "eb9b5293-8b18-43cd-a9ae-3edd0c8d9289", name: "Dalian University of Technology" },
    { id: "4c2b9069-1ac7-4059-9bc9-70777c463765", name: "Dallas Baptist University" },
    { id: "69169e15-a590-436f-a743-8e5343212475", name: "Dallas Christian College" },
    { id: "ff08e4f1-8aba-4880-8c31-ff85fdeb944a", name: "Dallas County Community College District" },
    { id: "addd216d-c407-40a9-a4f0-3a3601c4dcba", name: "Dallas Theological Seminary" },
    { id: "588431d0-348e-48af-8af7-896cac374aa4", name: "Dalro" },
    { id: "9069ba90-ab7b-40de-9f1f-e270692a3f82", name: "Dalton College" },
    { id: "238547fa-6318-47fd-93b4-3d9d765e15c0", name: "Dalton State College" },
    { id: "30275b40-651e-412c-a2ac-24ea9d91bdef", name: "Daly College Business School" },
    { id: "02cd5ba7-a25e-4d00-a934-9317bf5ece45", name: "Dana Porter Library - University of Waterloo" },
    { id: "708a7de4-cef9-4d5b-a438-a9ec71aef774", name: "Dania" },
    { id: "78cb49cd-30f7-4844-ab36-8b1d276a2584", name: "Daniel Webster College" },
    { id: "7b82e617-4284-4973-b770-a43dc4913d36", name: "Dankook University" },
    { id: "0f6f2667-444e-4b96-a028-a4ee3eae5520", name: "Dansville Central Schools" },
    { id: "76f22ba6-f6f2-48cd-8244-aac1d4f93728", name: "Danube University Krems" },
    { id: "9745c01d-aa0f-4214-ac49-941e6b8210eb", name: "Danubius University of Galati" },
    { id: "2acd7d73-7013-43fd-a62d-c5ac2d157d37", name: "Dar Al-Hekma College" },
    { id: "0a4234be-f24c-4cc9-b65a-52665ed1919e", name: "Dar Basile Institue" },
    { id: "67106e08-3436-4900-a33d-620b77dbf943", name: "Darkei Noam Rabbinical College" },
    { id: "a3deed5b-0cd5-4f1e-b977-0b00b2e45e57", name: "Dartmouth College" },
    { id: "6bbc99e4-3364-4c85-8093-fe68832b59ab", name: "Data ScienceTech Institute" },
    { id: "7aceae1c-7daf-4e77-94eb-dabedc6e6995", name: "Datta Meghe Institute of Management Studies" },
    { id: "516a3129-153c-4449-83e0-425203ca7564", name: "DAV University" },
    { id: "3dc135d9-86df-4d7a-aabb-36ce84031af5", name: "Davao del Norte State College" },
    { id: "9ab648ba-c8eb-4fe6-b059-4a7a1ed49910", name: "Davenport University - Flint" },
    { id: "f1563369-f6f3-44d9-8c0c-3d15898b3573", name: "Davenport University - Grand Rapids" },
    { id: "3fac8f12-af7e-4b3b-b8c0-26897399b699", name: "Davenport University - Holland" },
    { id: "789be117-97f5-48f0-afb4-2e667b81867f", name: "Davenport University - Romeo Extension" },
    { id: "9e647834-c174-47c8-bd44-cf5ec3407e29", name: "Davenport University - Warren" },
    { id: "c6e2fb84-1940-43f7-9364-e9acfceb9c23", name: "Davenport University-Livonia" },
    { id: "89a8bdee-0c9c-498e-9aeb-9ab29597ca23", name: "Davidson College" },
    { id: "6a02961e-c626-4bfc-9f43-009d544d96ba", name: "DaVinci Institute" },
    { id: "38e422ca-ecfc-4c93-adbb-f6618e906e8b", name: "Davis & Elkins College" },
    { id: "6982e698-7747-4a12-a501-0f7cba30e861", name: "Dawson College" },
    { id: "3b0262da-b553-4573-b905-2170969e1c79", name: "Dawson Community College" },
    { id: "74a10588-af6d-408c-a68c-46da71556b4e", name: "Dayalbagh Educational Institute" },
    { id: "3059a0f2-5875-4026-84be-f88da67bdabb", name: "Dayanand College Hisar" },
    { id: "7f195c23-95d5-4a6f-9fec-b0a276af1f66", name: "Dayananda Sagar College of Engineering" },
    { id: "9b2eb137-44bf-4209-86c5-9b65583a0b77", name: "Daymar College" },
    { id: "fa59edd9-942a-401b-87a1-b310f2428f85", name: "Daytona College" },
    { id: "78f3c34d-83c5-4bc3-9283-dfefc380d3b2", name: "Daytona State College" },
    { id: "9022f032-5ea6-4630-98ae-cf0c09ff18fc", name: "Dba Group Ltd" },
    { id: "bccd6336-2f92-48bb-bdc6-55ed56e0248c", name: "Dbrownconsulting" },
    { id: "0bbb7de7-b642-4091-b555-721f891cc729", name: "DC School of Management and Technology" },
    { id: "c07e992f-5b6c-43f6-9f21-99586a82202f", name: "DCL Services" },
    { id: "f8ba88c1-1d75-4a57-a154-d55fc5c22e75", name: "DCT University Center-Switzerland" },
    { id: "729a75b8-a8c2-40b9-a779-9be96ad3bc22", name: "De Anza College" },
    { id: "4541ae72-bc09-4260-baa1-60349dab964a", name: "De La Salle University - Phillipines" },
    { id: "97b2ff45-e73f-4c13-9ce8-7ffad0928044", name: "De Montfort University" },
    { id: "8e2c1eac-8b09-4844-828f-034fc8584fb1", name: "Deakin University" },
    { id: "d87fba77-fcf8-469d-9b3f-6001694e67d0", name: "Dean College" },
    { id: "df63d8ce-b382-402b-b02c-bd5c9247e54e", name: "DeAnza College" },
    { id: "b14d17de-691c-4519-9084-13733742713a", name: "Deenbandhu Chhotu Ram University of Science and Technology" },
    { id: "11419a9d-023d-4146-8942-1650cc40e7a8", name: "Deerfield Academy" },
    { id: "d3ad8561-3ccf-48ff-8f29-ac8b5f7129ac", name: "Defence Academy of the United Kingdom" },
    { id: "d9995e3c-98fd-4d43-a311-a9f98ad3e31f", name: "Defense Acquisition University" },
    { id: "dd90ae2a-c942-4714-86d3-9ceb4a0dbe90", name: "Defense Cyber Investigations Training Academy" },
    { id: "e71128f4-f8a5-458b-aa33-911b0b21d840", name: "Defense Security Cooperation University" },
    { id: "98afa996-d412-4ffd-a1a8-63dcc5270908", name: "Defiance College" },
    { id: "a90a4427-f5d7-488b-8942-b6fcef730fe0", name: "Deggendorf Institute of Technology" },
    { id: "19b536ae-b3e2-4e71-a45b-816d469f4c44", name: "Dehli Business School" },
    { id: "689fd4fb-a120-41eb-9f62-c54d925f1a9e", name: "Dehradun Institute of Technology" },
    { id: "47305e55-c4ca-4110-8dce-4a8b16759eb9", name: "Del Mar Community College" },
    { id: "026ab8f3-eacd-4c38-8a03-e3d2b34e76cf", name: "Delaware County Community College" },
    { id: "9d7975e4-301e-4108-b94c-d20762c0a6d7", name: "Delaware State University" },
    { id: "14ede76e-2c6d-481e-92a3-bfd78857d524", name: "Delaware Technical Community College" },
    { id: "0e62051e-2850-4d0a-9829-2b53ba2a1275", name: "Delaware Valley College" },
    { id: "7a4affeb-cd88-4426-be75-0b5b53fdb8b9", name: "Deleware County Community College" },
    { id: "941e8ccf-8873-4794-963c-da75a202667c", name: "Delft University of Technology" },
    { id: "2108c19e-7688-4890-b612-08ba975a6cb3", name: "Delgado Community College" },
    { id: "4fa7921d-c589-4e3c-80e1-e33d4fda5e59", name: "Delhi Business School" },
    { id: "8a82578d-7616-4a78-b865-8519abc9806e", name: "Delhi School of Management" },
    { id: "c9e78141-00de-4341-b832-952af47b4e68", name: "Delhi Technological University" },
    { id: "e3f23faa-7236-4c00-b83a-5ebe80b1fd55", name: "Delin Capital" },
    { id: "6c96df90-914e-48ac-8a7c-a9aa963bb87f", name: "Deloitte Advisory Services Ltd" },
    { id: "634555a5-9301-4868-ae8b-aaba88db3c11", name: "Delran Township School District" },
    { id: "651f0624-d3c6-4276-86b9-6dacccd8211d", name: "Delta Career Ed Corp" },
    { id: "760c8d4a-667a-4e54-970a-6cb4ecdca9be", name: "Delta College" },
    { id: "451491fe-b4ed-4b6d-aaa6-711128c95451", name: "Delta State University" },
    { id: "37bbef2b-151e-48d2-a481-b87d2b428d9d", name: "Deltak - Chicago" },
    { id: "506cfe60-1e42-4b0d-a320-47d2787f6a52", name: "Delter International Business Institute" },
    { id: "c606f058-eaca-4359-95b7-ae0584eafd83", name: "DEMA Emergency Services College" },
    { id: "0de3facb-7ad5-4382-8e7b-9c8e3bd7460f", name: "Demidov Yaroslavl State University" },
    { id: "965a9008-7ea5-4fd6-b15a-3727103f5fea", name: "Democracy Prep Public Schools" },
    { id: "000efd5d-de30-4565-870b-1c744f998697", name: "Democritus University of Thrace" },
    { id: "a825b5a3-231a-40d7-9d13-45866eb89044", name: "Denison University" },
    { id: "26c1290c-9d02-471a-90c5-3c4584d7e39c", name: "Denmark's International Study Programme" },
    { id: "961eabdf-438f-469f-b73e-575f40aa3cfb", name: "Denver School of Nursing" },
    { id: "c2d2158b-d867-45d1-b722-f113a9d6c956", name: "Denver Seminary" },
    { id: "e1ab22fe-81bd-4ef7-b5d5-b1828147f9cb", name: "Deogiri Institute of Engineering and Management Studies" },
    { id: "acc7540c-e86a-4248-a981-5b3c53a2530e", name: "Department of Defense Education Activity" },
    { id: "300ca7c4-28b6-4805-9644-9155d85f42ec", name: "Department of Education-Oklahoma" },
    { id: "88775899-ad82-4d3f-a42c-97008914298c", name: "Department of Machine Parts" },
    { id: "0ad1853e-94f1-45a9-aa8c-0e4891a86cec", name: "DePaul University" },
    { id: "9aa018d2-a8c6-4c69-82b1-df2e3e80f5fa", name: "Depauw University" },
    { id: "6553f8e4-f86d-44d4-963c-2640ea7400cf", name: "Des Moines Area Community College" },
    { id: "5147408c-3727-4bd9-9976-c5760d299a49", name: "Des Moines University" },
    { id: "e54f21d8-2672-42ca-9db8-ad898b217a51", name: "Desales University" },
    { id: "175acec0-893c-4180-805f-85e07c83f97b", name: "Design Management Institute" },
    { id: "ce306496-ab56-4795-8e9a-99b29a4a189f", name: "Deutsche Hochschule für Prävention und Gesundheitsmanagement GmbH" },
    { id: "42fc5a96-f44f-4ab0-a7bc-b025c15c9eb9", name: "Deutsche Universität für Verwaltungswissenschaften Speyer" },
    { id: "8e4321f3-200e-4740-95d7-4b9a626fb7df", name: "Devi Ahilya University" },
    { id: "ca250bd6-6572-4c21-83b6-44a747086267", name: "Devi Ahilya Vishwavidyalaya Indore" },
    { id: "1182a786-69fa-45ca-b46d-70354d9492d8", name: "DeVry Brasil" },
    { id: "0dffae3c-7ef8-4b4b-8939-3cd4ec5dab2e", name: "DeVry University" },
    { id: "c7720461-3594-4104-a183-2399bb3faf30", name: "DG Vaishnav College" },
    { id: "b564d37c-47f7-4726-af64-fa11961f984e", name: "DGC Formation" },
    { id: "cb8ac822-7835-43d6-9215-953e7c48cf94", name: "DHA Suffa University" },
    { id: "11937e2a-5058-4565-9f09-797e966671ed", name: "Dharmsinh Desai University" },
    { id: "2e425977-1def-4a06-b289-306baf9ebd66", name: "DHBW Lorrach" },
    { id: "26e9ca36-6b90-4a7a-aa9f-dfbe0f3e53e9", name: "DHBW Mosbach" },
    { id: "137cd149-752a-4962-9a06-143d8e815661", name: "DHBW Stuttgart" },
    { id: "f9d78781-17fd-45cb-a9e7-1d579f1c4360", name: "Dhruva College of Management" },
    { id: "9b70923e-ce13-4d1f-9f91-5fca4bf7f289", name: "Dhurakij Pundit University" },
    { id: "752692a8-965a-48be-bd8a-20e6d2f3f14e", name: "Diablo Valley College" },
    { id: "da7fe11d-38a6-4432-8133-2c48607271bc", name: "Dickinson College" },
    { id: "e29de164-08ce-4edf-afb2-84b8285cbbdb", name: "Dickinson State University" },
    { id: "3f8825e6-5756-41f7-9f8d-7671401d6c1d", name: "Die Denkfabrik Gmbh" },
    { id: "287ddea7-e8ed-45e3-b387-b1e28c86400a", name: "Dietmar Dreier" },
    { id: "cb506bf2-b1d3-4e75-8381-cd1a479486e3", name: "Difcam" },
    { id: "cc8880ff-8d78-4d41-a00e-69c51365d2e3", name: "Dillard University" },
    { id: "17734459-9fdc-4a98-8885-9cb4f129ce4b", name: "Diplomatic Academy of Vietnam" },
    { id: "9b09a41d-be4e-462c-b69d-3f0602d9bc0e", name: "Discovery Collegiate" },
    { id: "f5b37280-90a9-4de3-8eda-3f1a8c772c55", name: "Disha Education Society" },
    { id: "4a1b26e2-1531-4c85-9cb5-426c46f4b4e5", name: "DISTED College" },
    { id: "d6f83b8e-fa37-44a9-8112-5d0681d6817c", name: "DIT University" },
    { id: "404b5266-7850-4657-84f2-7e54598f6fde", name: "Divine Word University" },
    { id: "e162e59d-8fcc-4d28-86c6-8d56b9a31bad", name: "Division of Research" },
    { id: "c63173dd-4d7b-4d14-84c3-e9571d091a9f", name: "Dixie State University" },
    { id: "c18a3621-86d7-4a9f-b590-8492426ada8a", name: "DLB Educational Corp." },
    { id: "40f71fac-d3ab-4101-8295-46d81364a024", name: "DLD College London" },
    { id: "67634787-fdea-49f2-8b0f-f96cd2560ebb", name: "Dnipropetrovsk National University" },
    { id: "fcc4e958-cdff-4610-9c89-7d2d8907436b", name: "Doane University" },
    { id: "0e5a3ba6-877d-4285-9b29-65b0b1ba3435", name: "DOBA Faculty" },
    { id: "0c927b1a-e3f1-4161-b43d-336f418f0735", name: "Doctoral Program" },
    { id: "3fdf5775-845d-42c0-8ce7-675a13bc7cb6", name: "Doctors Hospital - The Institute of Learning" },
    { id: "c4208053-1678-4824-a750-c30b95c725b5", name: "Dogu Marmara Kalkinma Ajansi" },
    { id: "691b0226-66f4-429e-ab67-5764eb41a5ac", name: "Dogus University" },
    { id: "99bb67d7-0e31-4ea8-a309-83068e1ccea1", name: "Dokkyo University" },
    { id: "8bb99836-db0f-4bb1-8665-d0664facfe07", name: "Dokuz Eylul University" },
    { id: "e982590f-e808-496c-b2a7-56206ddbdcf8", name: "Dominican College" },
    { id: "70f51a96-2eab-45d5-bf2e-edca7c720075", name: "Dominican University - Illinois" },
    { id: "53b7f66b-a774-4ab9-9357-8351db30f582", name: "Dominican University of California" },
    { id: "643762c8-118a-40f8-80b2-af1b08b17776", name: "Don Bosco Institute of Management" },
    { id: "1377e00f-e671-4e77-9fc1-47655c42b406", name: "Dona Ana Community College" },
    { id: "504b9ad1-b782-4a46-96c7-c9d7f23ae40d", name: "Donedeal" },
    { id: "390cbebb-f7b9-4b45-b0ab-52854f1ee265", name: "Donetsk State University of Management" },
    { id: "5a2c9e94-7da1-4a3d-b06b-f255521da675", name: "Dong Hua University" },
    { id: "8e16e2bd-ec29-4ac2-9a57-fbc7b762af11", name: "Dong-A University" },
    { id: "ed5d63c1-b41c-4e73-ab77-c2b17419128a", name: "Dong-Eui University" },
    { id: "1e18156a-4034-48d1-ae98-4a1e3e7d0663", name: "Dongbei University of Finance and Economics" },
    { id: "fa7fa46a-a203-40a1-a03e-564020ec3f9d", name: "Dongduk Women's University" },
    { id: "96535f0a-2061-4852-a2ab-84bb05255b36", name: "Dongguk University" },
    { id: "410717d8-f276-4bcf-9da1-02998b0d7d94", name: "Donghua University" },
    { id: "9680b126-64d5-40a5-8560-374f6a443855", name: "Dongseo University" },
    { id: "14fe8c4e-f4cf-4e64-9d59-999c694b094d", name: "Dongyang Mirae University" },
    { id: "357b412e-43ed-4d19-9c1e-fe10a9065089", name: "Doon Business School" },
    { id: "3b7a2279-f563-4cf0-a8bf-af1a075aaf13", name: "Doon University" },
    { id: "ee955c96-b1ff-493b-b0b3-f15d2f713103", name: "Dordt College" },
    { id: "2fb11085-90ec-457c-a674-9bfc031e1465", name: "Doshisha University" },
    { id: "0db3561e-288d-40c3-8519-a54a77228787", name: "Douglas College" },
    { id: "21b9267e-bf41-45d5-8974-61af5aa49d46", name: "Dowling College" },
    { id: "7301d388-64d8-4f27-8e31-b75de97b0216", name: "Dr. Ambedkar Institute of Management Studies & Research" },
    { id: "6ee1017f-eb7e-4eca-b114-8cc86b553c3d", name: "Dr. Babasaheb Ambedkar Marathwada University" },
    { id: "ce49c121-d419-4df9-96ec-e7985c6e7444", name: "DR. D. Y. PATIL B-SCHOOL" },
    { id: "5885f5a2-2b56-4638-8272-121e54723fc3", name: "Dr. Gaur Hari Singhania Institute Of Management & Research" },
    { id: "9b4dacd6-c549-4122-9696-6fba244078e5", name: "DR. Peter-Jan Engelen" },
    { id: "99f36083-0ea1-48d6-9d2b-6e5623c0c2da", name: "Dr. S . RadhaKrishnan College of Business Management" },
    { id: "f219cd96-ae8b-41bd-aa96-5d72d8dd1268", name: "Dr. V. N. Bedekar Institute of Management Studies (DR VN BRIMS)" },
    { id: "86b9604b-69b4-4929-9a2d-483227604879", name: "Dr.G.R.Damodaran College of Science" },
    { id: "dcaf9c35-bdf1-470e-8cfd-239da03738d8", name: "Drago College Store" },
    { id: "59bec8ed-3b3a-4310-a02f-b8de1ac33885", name: "Drake State Tech College" },
    { id: "cb08038b-5949-45bf-b68b-e20f8c602c65", name: "Drake University" },
    { id: "d56c3196-070e-4dfa-b150-ec031233e42c", name: "Draper University" },
    { id: "b22c283d-b99b-4647-9136-7e2abeb8de58", name: "Dravidian University" },
    { id: "c18497bb-7359-4890-91b9-460c689e35a1", name: "Dresden University of Technology" },
    { id: "0ae7218b-cf5e-4e88-bfe5-d734c1b3cd5f", name: "Drew University" },
    { id: "deb53928-5538-4c97-8f81-defd3f3aa226", name: "Drexel University" },
    { id: "47144704-2af4-42c8-8070-8f394cbecc02", name: "Drexel University - Sacramento" },
    { id: "9dbf0d1b-755d-493b-9dbb-8673a827257b", name: "DRIE - CCI PARIS ILE DE FRANCE" },
    { id: "13fe9a1a-06a4-435a-b70d-b71ded1e2b5f", name: "DRIEMS Business School" },
    { id: "f06feb63-e419-4df8-9559-efa98e884496", name: "Drury University" },
    { id: "5057aaef-a0b2-45a3-82f9-1f8938536046", name: "DSMS Business School" },
    { id: "87d218e3-cc16-4645-82a5-075f61776b55", name: "DTU Executive School of Business" },
    { id: "d7141ff9-7377-41de-b481-afffa9eb3346", name: "Duale Hochschule Baden-Württemberg Karlsruhe" },
    { id: "8035b998-bbfe-495e-a30a-b247a4183e9f", name: "Duale Hochschule Baden-Württemberg Stuttgart" },
    { id: "ae2bb98e-5ee2-421f-ad72-9bedf07c4332", name: "Dubai Men's College" },
    { id: "d5760c24-070c-46cb-a10a-2210a4ada3e3", name: "Dubai School of Government" },
    { id: "ecdf8eba-74fa-49ec-8c83-94ac56b97aad", name: "Dublin Business School" },
    { id: "8e67f55d-b977-4dbe-a5c1-a04a0c385c00", name: "Dublin City Schools" },
    { id: "b2fd8151-7434-4e5a-b826-bdff90e17202", name: "Dublin City University (DCU)" },
    { id: "a778583c-d4a5-4f83-b2f2-141588307d01", name: "Dublin Institute of Technology (DIT)" },
    { id: "d746cc5b-5884-498a-84ca-92a3d6f44fe4", name: "Dubois Business College" },
    { id: "9d34e513-e17a-4953-bd41-700e8c30c37a", name: "Dubrovnik International University" },
    { id: "072b404e-6a95-4f4c-9828-cddce20a4007", name: "Duisenberg School of Finance" },
    { id: "6234d5d0-d46f-45d2-8054-e61884c41439", name: "Duke Corporate Education" },
    { id: "86580b8a-5e0b-4d1b-beac-fe55d520762d", name: "Duke Corporate Education UK" },
    { id: "d45ef478-29d1-4b87-a3c8-65805fdb68b5", name: "Duke Global Inc" },
    { id: "ca1d7f38-92f5-4b33-b998-f99572b3f4bb", name: "Duke Kunshan University" },
    { id: "2eaa1d9d-fbda-4c00-96cf-22c47d0a3685", name: "Duke University" },
    { id: "09ed7710-65ba-4c4f-a29c-f6b2527cde2a", name: "Duke University - School of Medicine" },
    { id: "554a6584-eb56-4464-9d3e-4209abd977f7", name: "Duke University School of Law" },
    { id: "b309e453-1410-445c-a203-16818a068546", name: "Duke-National University of Singapore" },
    { id: "dc0da401-4044-4099-8327-a35b374edc61", name: "Duksung Women's University" },
    { id: "0025ccdc-0479-42b9-9dba-dc6849b4375d", name: "Dumlupinar Universitesi" },
    { id: "94264837-4b47-42d8-9455-ce26c17e6371", name: "Dun Laoghaire Institute of Art & Design & Technology" },
    { id: "ea7fd95c-1232-449c-9d8b-ab3c13774c7f", name: "DunaÃºjvÃ¡rosi FÅ‘iskola" },
    { id: "afc2fc00-4bfe-4490-a80b-8fa09b4d2b31", name: "Dundalk Institute of Technology" },
    { id: "f991bf0f-337a-486d-96a6-617d9fb13b65", name: "Duquesne University" },
    { id: "89566a56-b54f-49e3-8c9e-2560d4166a2b", name: "Durgadevi Saraf Institute of Management Studies" },
    { id: "2fa56627-4c76-40e7-a9b3-b66ea584770f", name: "Durgadevi Saraf Institute of Management Studies (DSIMS)" },
    { id: "e4d9c246-a673-4ccf-8ae0-ae598bb9f7b7", name: "Durham College" },
    { id: "cb5debf1-835c-4a9d-8108-a31bc75ebd34", name: "Durham Public Schools" },
    { id: "424d21e9-c957-429c-bb42-f5e7b8051951", name: "Durham Technical Community College" },
    { id: "586a43c9-afe3-40b3-8e18-c476bbff76fd", name: "Durham University" },
    { id: "e66fd67c-b7bf-43c7-ba06-595f2ff40d23", name: "Dusseldorf Academy of Marketing Communications" },
    { id: "523837b1-76d5-4fb8-a8a8-bb275441f9d8", name: "Dutch Research Institute for Transitions (DRIFT)" },
    { id: "938ecc31-8154-48cd-83a3-d0d639864c7d", name: "Duval County Schools" },
    { id: "e7bc5801-8286-4712-829b-dd58a2e59569", name: "DY Patil Deemed to be University" },
    { id: "5d61ab01-7d9a-41a3-bf15-f198ad786d16", name: "E2v Technologies Plc" },
    { id: "2d5dce77-da2b-43ca-908b-e27587e33e8b", name: "EADA" },
    { id: "cd06ef88-2e8c-4acf-9ebd-b44555d2589b", name: "EAE Business School" },
    { id: "9f6de01d-3e1c-4251-84e9-5aaea3ce61ec", name: "Eagle Gate College" },
    { id: "3ebaed1d-358c-4fea-87ed-8ca62542341f", name: "Eagle Pass Independent School District" },
    { id: "19ba6bbb-a7ce-43c4-96f5-aa694741962b", name: "Ealde Business School" },
    { id: "29d051b4-4bcc-411f-b636-c3be05d896fb", name: "Earlham College" },
    { id: "2d382b5a-5e6c-41cc-9b97-f18b6df1cfae", name: "EARTH University" },
    { id: "64b56937-4074-432e-8c74-75e4d34db02e", name: "East African School of Aviation" },
    { id: "419eb378-6bda-4646-b6ed-6b0fb73ae992", name: "East Carolina State" },
    { id: "261d827e-48e2-462d-bd9a-31f8474fa809", name: "East Carolina University" },
    { id: "5b30077a-aa20-41c4-a07b-e05e6b125d74", name: "East Central College" },
    { id: "6e30dfac-8aed-4747-a7a0-3742f3d004ee", name: "East Central University" },
    { id: "5ddaafdd-eee5-4ec2-87da-36d63776a33f", name: "East China Normal University" },
    { id: "f976c52c-4900-4d3d-91e4-5f11c1643332", name: "East China University of Science and Technology" },
    { id: "05fa4f2c-1d8d-406b-af19-9b86a8da858c", name: "EAST HAMILTON SCHOOL" },
    { id: "5b182fcd-3830-46fb-98fe-4b24dad08f68", name: "East Los Angeles College" },
    { id: "885e225d-780e-4fed-abf9-8e4e311ce09d", name: "East Lyme Public Schools" },
    { id: "8c3dd948-850c-4f27-b410-c71136d73b95", name: "East Riding College - Beverley Campus" },
    { id: "8ce45b23-91dd-416e-8175-58c3e36d5b17", name: "East Stroudsburg Area School District" },
    { id: "c8c79622-486f-4708-83c8-df2a5ad1347c", name: "East Stroudsburg University" },
    { id: "225f8cfa-c499-4610-ad0d-3ffd5d6fe6eb", name: "East Tennessee State University" },
    { id: "977e5a83-73ba-4497-a7af-3e49ab0c716f", name: "East Texas Baptist University" },
    { id: "31e67f64-6bba-42d6-8e06-152de70a7fc0", name: "East-West Center" },
    { id: "324195ce-f4de-4572-9c93-7d4bb5f00f31", name: "East-West University" },
    { id: "338cca71-3f68-4647-87cf-aa329a4d089b", name: "Eastern Arizona College" },
    { id: "f454fe77-dece-4b8a-a28c-01c3b21c8121", name: "Eastern Connecticut State University" },
    { id: "169bc7ac-b641-4d22-bc14-44092b928eb0", name: "Eastern Florida State College" },
    { id: "dda77cd7-e7ee-44bc-a48d-9480ce9443b4", name: "Eastern Illinois University" },
    { id: "7466f9e8-bd9c-4377-9a9e-7b9ab0887a4e", name: "Eastern Institute for Integrated Learning in Management" },
    { id: "339ecfa9-4a23-4681-986a-bce56866c160", name: "Eastern Institute of Technology" },
    { id: "43852fe6-5454-46e3-9435-2e81cb78302e", name: "Eastern International College" },
    { id: "c29d7088-2771-47b8-9656-0befe4d2fde6", name: "Eastern International University" },
    { id: "4b016b6b-e9e6-438c-b2c1-864fe861c056", name: "Eastern Kentucky University" },
    { id: "16532e72-7473-4441-b8d1-c6eae5de66ae", name: "Eastern Mediterranean University" },
    { id: "c7a1fb14-3bf8-48d4-a4c0-de1d8b018fea", name: "Eastern Mennonite University" },
    { id: "cfeb6c9c-269b-4819-b425-3a5020491b85", name: "Eastern Michigan University" },
    { id: "047fc618-2d9d-42ac-940c-c15c51d969d9", name: "Eastern Nazarene College" },
    { id: "9cfcbdbc-8c92-4c00-b075-9f9060c04420", name: "Eastern New Mexico University" },
    { id: "ff62c585-88bf-4620-93f8-4ae0e9f2f2a8", name: "Eastern Oregon University" },
    { id: "791e46fe-e45b-4580-a86b-f85231494742", name: "Eastern University" },
    { id: "0bbb1116-4503-4fe4-a035-37612d59cc90", name: "Eastern University -East Falls Campus Store" },
    { id: "a00210df-5624-4726-939e-60e7b458a687", name: "Eastern Virginia Medical School" },
    { id: "bbae2c54-2da5-460b-bc84-b30f9918bb51", name: "Eastern Washington University" },
    { id: "b5e85074-671c-4210-b5c9-83ed2d737802", name: "Eastman Systems" },
    { id: "80dac53d-1344-43bd-aabe-6ba14dd8aee7", name: "EBC Hochschule Dusseldorf" },
    { id: "e3660eb9-b99f-473b-a582-730a08e7fa10", name: "EBCM - European University of Munich" },
    { id: "cbee387e-f0fe-4f6d-9ac5-624526625aa0", name: "Eberhard Karls Universitat Tubingen" },
    { id: "3c64ed4d-0ed1-4c37-a4fe-ae750897e74d", name: "EBS University" },
    { id: "71a97a2a-07ea-4c7d-8b32-0a245063d4b5", name: "EBZ Business School" },
    { id: "b9529aac-6e89-4865-a809-04bfa5a41494", name: "ECAE - Escuela de Negocios" },
    { id: "cdddd0d1-66d4-4bfd-9821-304f158a1c7c", name: "eCampus.com" },
    { id: "ab37a600-b7ab-4d34-b2ca-2215f70e41cf", name: "Ecch Sundry Academic" },
    { id: "ee12a71c-738d-42bc-9fd6-dea36d150b9b", name: "Ecema" },
    { id: "86f5ba55-bc53-4258-b6c3-f4d7a8228c4d", name: "Echelon Institute of Management Development (EIMD)" },
    { id: "a4728b67-a5b3-4b64-90ac-2469e9c358c8", name: "Eckerd College" },
    { id: "e45b6c97-6cc7-49d9-892a-42a4bcd78f32", name: "Ecole 3a" },
    { id: "080ae43b-9285-4362-bca0-971cf0615496", name: "Ecole Centrale Lille" },
    { id: "df80c470-3c5b-4fba-8cf3-e140d369d71c", name: "Ecole Centrale Paris" },
    { id: "882a71b6-cdfd-40c1-af86-d1ac876fa7d2", name: "Ecole de Management De Normandie" },
    { id: "e38a5450-b9c9-4361-bbbf-aab618f7778f", name: "Ecole de Technologie Superieure" },
    { id: "00294981-7a95-4a91-9f7e-9dc1adf45c18", name: "Ecole des Hautes Etudes Commerciales" },
    { id: "5d20ba2a-4f7d-428c-9985-765f6fb093db", name: "Ecole des Mines de Nantes" },
    { id: "c4d5cd3c-ace3-4a1f-8b6c-7f7496f495aa", name: "Ecole des Ponts Business School" },
    { id: "71f7943b-d7a8-487a-80bf-a1c71c34d26d", name: "Ecole Internationale de Marketing du Luxe" },
    { id: "90add821-b836-4d4e-9747-06e1f47e2b4b", name: "Ecole Mohammadia d'ingenieurs" },
    { id: "ce705dda-dc1e-44f1-89e5-8020f44eb0d6", name: "Ecole National" },
    { id: "a0012d52-f16c-4fb0-8e53-93a5aca38bca", name: "Ecole Nationale D'Administration Publique" },
    { id: "e05196e7-40fe-4b29-89f3-fada839ecb43", name: "Ecole Nationale superieure des mines de Nancy" },
    { id: "062a37a9-e848-4c71-86cf-8f17a926f299", name: "Ecole Nationale SupÃ©rieure d'IngÃ©nieurs de Caen" },
    { id: "ddf1b554-010a-49af-b9a3-6864756c0679", name: "Ecole Nationale Supérieure d'Arts et Métiers" },
    { id: "32529113-572c-4e72-91dd-eb20117790b8", name: "Ecole Normale Superieure de Cachan" },
    { id: "fb204e3e-98d0-47ed-be7c-11c3dc9e0d72", name: "Ecole Polytechnique Montreal" },
    { id: "aa5309a0-4adc-4e3c-afff-9952e37aee34", name: "Ecole Polytechnique Federale de Lausanne (EPFL)" },
    { id: "e39e7c3f-1e0e-46d4-ac42-d89a92b79856", name: "Ecole Polytechnique Féminine (EPF)" },
    { id: "45d4c2ff-f25b-44bc-8660-caa93381ea4a", name: "Ecole Superieure D'Assurances" },
    { id: "e299fc3b-5048-45de-8fe1-072a8aae0873", name: "Ecole Superieure du Commerce Exterieur (ESCE)" },
    { id: "95ade6d4-c813-4c33-9063-6c2bf2d362c9", name: "Ecole Supérieure de Gestion et Finance" },
    { id: "8506daaf-f459-46a5-98a0-215940ce1d7f", name: "Ecole Supérieure des Affaires" },
    { id: "6272a649-7b54-4612-a335-81953a0f0e0e", name: "Economics Studies Academy of Moldova" },
    { id: "f736763b-6656-4235-aa24-1a29c9b6a2a4", name: "ECPI University" },
    { id: "4075fe95-0939-4aa5-a5d9-891b4f85e5ea", name: "ECRI Institute" },
    { id: "4dcf8852-1213-4a00-8934-696295e97800", name: "EDC Paris Business School" },
    { id: "f2a39d3a-7fed-4dd2-9327-5a4f740ae9a3", name: "EDEM Escuela De Empresarios" },
    { id: "7483e16f-a1e0-4072-839c-a710c4994047", name: "Edge Hill University" },
    { id: "50b6f7a6-bdd5-4cc8-a971-fc4db349d782", name: "Edgecombe Community College" },
    { id: "1a214bda-80a1-416e-894a-6f4a952b1ab3", name: "Edgewood College" },
    { id: "87b1f5ab-106c-4eff-af26-ea350a6c2e08", name: "EDHEC - Business School" },
    { id: "2cea4a64-95d1-44c6-8473-b6f1a2490a44", name: "EDI Achievement Coaching Inc." },
    { id: "e70b6ffe-39a2-410e-bb0e-a066f2a0a76a", name: "Edinboro University" },
    { id: "318af30a-95d8-41f1-bce5-9ecb23705e5a", name: "Edinburgh Napier University" },
    { id: "671f6024-daa8-4893-b833-205227141c5a", name: "Edison Community College" },
    { id: "8ace93c6-5c42-423f-86fc-d4999c370a4d", name: "Edison International" },
    { id: "607c9100-892c-4bc5-ab12-f1ac906b2430", name: "Edison State College" },
    { id: "ad97e270-48de-4ecf-bfd6-51d7858bb655", name: "Edith Cowan University" },
    { id: "13f6daae-14e8-4095-9ae7-0fa0aaa2774f", name: "Editora e Distribuidora Educacional S.A." },
    { id: "38a12c73-1487-42fd-b6f5-290bcc945e69", name: "Edmap Inc" },
    { id: "aaee503f-f467-4a3e-b61f-f1ff0e24aeb9", name: "EDMC" },
    { id: "01552d82-cd1d-40fb-a9b9-087ac910baea", name: "Edmc Online Inc" },
    { id: "583b5e8f-c166-4800-9b21-19d0947783ec", name: "Edmonds Community College" },
    { id: "4674b843-a8b9-4be0-bcbf-32a099d71b0d", name: "EDU Effective Business School" },
    { id: "5b94704f-09a2-4fad-b793-b9a644cd19cc", name: "Eduaction Institute of the Northeast Ac" },
    { id: "be2ce6c7-4376-4641-ab60-680541e29f14", name: "Educasia Inc." },
    { id: "71553d32-af69-40aa-87d0-d36f18570f1a", name: "Education Affiliates" },
    { id: "6293e82a-97b1-4f91-9136-c0063e7a1f4a", name: "Education First -United Kingdom" },
    { id: "a2009faf-02cd-4fc9-8fb8-2089341c2b80", name: "Education Pioneers" },
    { id: "421ccc25-e843-490b-89e0-f2e35584c5bd", name: "Educational Uniasselvi" },
    { id: "be27c918-9f7e-4f2f-be7c-9f7a11947242", name: "Educatis University" },
    { id: "7511004a-21eb-4c4d-b315-8a1d5231f042", name: "EDUCAUSE" },
    { id: "062b1622-11a7-4fb3-a382-90d4cbe6d79a", name: "Educomp Raffles Higher Education Limited" },
    { id: "5226a69e-126b-454f-a47c-455860c90534", name: "Edutus University" },
    { id: "5e7b8c68-90f3-452d-b7b1-751f631f6480", name: "Edwards Business Students' Society" },
    { id: "5d111a6c-4eee-46f8-871b-d9189a2082df", name: "Ef International Language Schools" },
    { id: "d5517530-c32b-47a8-b34e-58dbf0370c1a", name: "Effat University" },
    { id: "fea5a510-160f-4e31-9f50-e5be906f3a25", name: "Effectus" },
    { id: "ec66af1c-daaa-4988-a032-cb4463c231b0", name: "EFMI Business School" },
    { id: "c25a2abf-61fa-4668-9c2c-271305ef0c56", name: "EGADE Business School - Tecnologico de Monterrey" },
    { id: "2122de97-1986-4f21-8f6c-3551963e5b28", name: "EGE - Ecole de Gouvernance et d'Economie de Rabat" },
    { id: "743b66df-75e0-4cfd-b47f-6df341a5c8cb", name: "Ege University" },
    { id: "ba7227d6-7d9e-4cb3-b1cb-10b1e9e71b56", name: "EHL Haute Ecole SA" },
    { id: "b5624af5-916b-43bc-ac14-7c60962f636c", name: "EIGSI -Ecole D'Ingenieurs La Rochelle - Casablanca" },
    { id: "893bf204-8b79-45df-b36b-55ce31e486bf", name: "Eindhoven University of Technology" },
    { id: "26d47d8d-e90d-426b-8a9f-98d90ba489e1", name: "Eisenhower Medical Center" },
    { id: "3514f78f-77ca-4996-88df-675a639d58b6", name: "Ekonomi Universitas Indonesia" },
    { id: "da4855d7-9bfe-4f59-bcd1-286b8ba0e4b6", name: "Ekvilib Institute" },
    { id: "1109533a-e9d1-4236-a310-c4ef03d9054a", name: "El Camino College" },
    { id: "465b1aa8-f1c9-42d3-a56a-6dc698dd7cee", name: "El Colegio de la Frontera Norte" },
    { id: "20822c32-1828-431e-bc92-883522ea186a", name: "El Colegio de León" },
    { id: "78699c09-2987-473c-8561-6a19c55e8362", name: "El Colegio de Mexico" },
    { id: "e7d0df5f-7466-4518-886b-11f5ac8f0565", name: "El Colegio de Ventas y Negocios" },
    { id: "665f32b4-b74c-4618-a071-85b272145755", name: "El Dorado University" },
    { id: "bb63b939-2b20-458a-9dcf-790762494fe9", name: "El Paso Community College" },
    { id: "2ce71047-7715-401d-9337-44296a938163", name: "Elgin Community College" },
    { id: "45a8d001-b53b-4075-b4c1-7bf3814e82a5", name: "Elis - Educazione" },
    { id: "d41bca56-9f5f-4b3a-84b2-aa0b6a4c1c3f", name: "Elizabeth City State University" },
    { id: "e13728c2-219a-42da-9164-ed3571c36fbf", name: "Elizabethtown College" },
    { id: "5cd9a806-41cd-42cb-98f7-b7fc5cc2bb72", name: "Elizabethtown Community and Technical College" },
    { id: "b0677fb5-b96c-47b7-98e7-23626f96a437", name: "Ellis University" },
    { id: "804f8785-a624-4c34-8672-e25c2feb3799", name: "Elmfield House Associates Ltd" },
    { id: "3ac9e75b-0e72-4ab9-ba00-ca42ad39193c", name: "Elmhurst College" },
    { id: "4a9b819e-cfb4-41dd-a9f2-dc544df7ee8f", name: "Elmira College" },
    { id: "7c8c1ccb-695a-4f76-8384-8f79e703d278", name: "Elms College" },
    { id: "ef051e12-d8f3-410d-80db-dfda54b867bf", name: "Elon University" },
    { id: "1cf6bfb3-55a9-406f-b20c-4b7d8b8e9403", name: "Elvebakken VGS" },
    { id: "a2c08e37-5e3a-4e08-9af2-2eff2c93a1b6", name: "EM-Lyon" },
    { id: "e3f9f099-507b-4af7-bca1-3f78d23e6814", name: "Embanet-Compass" },
    { id: "b9bc9248-1a4c-42e5-9ee3-72c6904bb208", name: "Embry Riddle Aeronautical University" },
    { id: "eac98359-6deb-4379-91cb-336a6dbf01d4", name: "EMD School of Mgmt" },
    { id: "397839d6-2c47-4caa-bc6f-ce496c8af9a7", name: "Emeritus Institute of Management" },
    { id: "d14f9e60-03e5-417d-a5ac-a484027960ca", name: "Emeritus Institute of Management Pte Limited" },
    { id: "772a7436-42e8-433b-9cfa-5222ee3eb467", name: "Emerson College" },
    { id: "d74ec0b0-06da-4adb-a921-b501369acdd7", name: "Emily Carr University" },
    { id: "6559098d-0942-4312-804a-c6878c3d05d5", name: "Emirates Academy of Hospitality" },
    { id: "156a4a0b-6003-486a-909c-50c4b624b1b1", name: "Emirates Aviation College" },
    { id: "37fe92e0-ae8c-4b94-b8cd-ac3cda60e9bc", name: "Emirates College for Management & Information Technology ECMIT" },
    { id: "64f410ee-cfa6-4dc0-a74c-119a2061bdcf", name: "Emmanuel College" },
    { id: "a9e2df5d-214e-40f5-a7a9-d15566572056", name: "Emmaus Bible College" },
    { id: "02614532-ae79-47b0-8e24-80c6f9c390c6", name: "Emmerich Manual High School" },
    { id: "4777da16-0c1a-4808-8277-8afbe1694635", name: "Emory and Henry College" },
    { id: "9d5d4481-dfde-4ea1-88e2-fa6fdeeb203b", name: "Emory University" },
    { id: "2cef8f22-3610-482a-8fdd-86836f159283", name: "Emory University Barnes and Noble Bookstore" },
    { id: "4377d72c-a41a-4505-acf7-a7f38b94dffd", name: "Empire State College" },
    { id: "587b818e-12fb-4d20-977e-b905157ec36c", name: "Emporia State University" },
    { id: "8c159151-a9bd-4467-94bf-a88a4088b26b", name: "ENAC - Ecole Nationale de l'Aviation Civile" },
    { id: "4cd98c9c-006f-4f6e-8c58-030f8fd2e05c", name: "Enderun Colleges" },
    { id: "4e9a3465-6f8c-43f9-804b-e3e5be7f6153", name: "Endicott College" },
    { id: "1efede1d-5e8f-40c3-90aa-cc746f3fa822", name: "Enercon India Ltd." },
    { id: "f9f4d366-734d-49fb-9e78-000caca0303e", name: "Energy Delta Institute" },
    { id: "d1bb888c-4f70-4c89-b1c1-50011cccae1f", name: "Enfield Primary Care Nhs Trust" },
    { id: "72820027-5254-4afe-85cf-79ae95a5233f", name: "Engineering Duty Officer School" },
    { id: "407817e4-f7c4-46bc-8343-dedca6e6df23", name: "English at Work" },
    { id: "e1b32375-335c-4f01-bb26-35b12f90e5b9", name: "English Institute of Sport" },
    { id: "62b3a762-9693-4b4d-885f-5699e49a5cd6", name: "ENS de Lyon" },
    { id: "bf57c8df-ca33-425e-8e4c-0534dea0d22c", name: "ENSCI - École nationale supérieure de création industrielle" },
    { id: "6062c6d7-3423-480e-935c-b2fe9df56962", name: "ENSEA" },
    { id: "6f84a7a8-b62d-4710-9cd1-a0c324577f52", name: "Ensign College" },
    { id: "f795fc98-e3ca-440e-aedc-79f16d458860", name: "Ensworth School" },
    { id: "d3a7ff0d-283f-412b-b40a-7ee7728270c4", name: "Entrepreneurship and Management Processes International" },
    { id: "176ae233-9652-4fe1-b472-47c26930fa72", name: "Entrepreneurship Development Institute of India" },
    { id: "a6765f67-bb92-4802-a6b7-5fb4d817ca45", name: "EOI - Escuela de Negocios" },
    { id: "72f43b9b-79e4-405f-bc59-02dffc8feb01", name: "Episcopal Academy" },
    { id: "7e03640e-744e-45ef-baf0-297c886c1d58", name: "EPITECH (France)" },
    { id: "bcc60812-cf86-4fa2-a86b-f570d16533fe", name: "Epoka University" },
    { id: "a8eeeaab-adf8-430f-968c-7b7826899f3e", name: "Equip Training" },
    { id: "4de9afa1-aff4-45dd-be92-b788a71874ec", name: "ERAS Ltd" },
    { id: "c3b27b1c-0c7b-47e6-83b5-635704596b8b", name: "Erasmus University - Rotterdam" },
    { id: "32a6968f-b537-41aa-ba53-a6e8c10882e3", name: "ERC Institute" },
    { id: "ae282121-b52d-4e7f-81ec-a7d124ed770b", name: "Erciyes University" },
    { id: "9b2ceb10-253a-4483-84e9-bf59794beb1c", name: "Erhvervs Akademi Sydvest" },
    { id: "fae12684-390a-4d67-9b9c-5e011b91fc5a", name: "Erhvervsakademi Dania" },
    { id: "eff15518-34f7-4188-b53f-c89ef22f884e", name: "Erie Community College" },
    { id: "35a6f0d4-b27a-48e5-81d6-43d6c4ac607e", name: "Ernst & Young" },
    { id: "6d1592a4-4577-427f-98fe-9ca8f39b18c5", name: "Ernst & Young Academy of Business" },
    { id: "8644d12c-5c73-4aeb-90ca-9db06de05a67", name: "Erode Builder Educational Trust's Group of Institutions" },
    { id: "28f69ad5-50bc-4b1a-a98d-e48bd3f88cb6", name: "ESA - Escole Superieure des Affaires" },
    { id: "b0e37496-81a3-490c-afb0-c9c18d204ea0", name: "ESA Business School" },
    { id: "c6cb3221-77e2-4b91-8b37-7d7cb2d37d99", name: "ESADE - Barcelona" },
    { id: "757f2c16-ada3-41e6-b5ae-7b634000a3d6", name: "ESAN - Graduate School of Business" },
    { id: "c7856049-4774-42bc-821a-1d0531a00a13", name: "ESAP - Escuela Superior de Administracion Publica" },
    { id: "6b9f3f83-65cf-470a-bb88-8c18826f673d", name: "ESC - Le Havre" },
    { id: "d60a1af1-efda-4391-8a42-e00600a70e43", name: "ESC - Toulouse" },
    { id: "38037bec-ce9d-458d-94d5-2266969a4f14", name: "ESC Bretagne Brest" },
    { id: "ab806fe1-e2ce-49cc-a096-6261aeb17090", name: "ESC La Rochelle" },
    { id: "291246fa-4437-421b-9b24-cd680d65176a", name: "ESC Pau" },
    { id: "95f37078-10f1-4ea0-b0bc-2a8ebfd2027c", name: "ESC Rouen" },
    { id: "d7bbc716-8973-4b5e-b470-e8aa45846d77", name: "ESCA Ecole de Management" },
    { id: "8aadfbdb-fb3b-4dbe-b57b-d0f9aa735384", name: "ESCE Ecole Supérieur de Commerce Extérieur" },
    { id: "5d196560-7dfd-450b-94bb-8e2c50b1add6", name: "ESCE Paris" },
    { id: "c0e9d8a9-b480-4196-943d-2115b1eb9f7b", name: "ESCEM School of Business and Management" },
    { id: "46338087-0b57-46eb-91b6-9f9c0e550bdf", name: "Escola Mater Christi" },
    { id: "76f50d0a-35ce-4363-a3fe-5186d4f806d7", name: "Escola Nacional de Administracao Publica ENAP" },
    { id: "0a782d82-a872-436d-88f1-44a51c16cc9e", name: "Escola Superior de Comerc Internacional" },
    { id: "3bcdb7dc-bf8c-426b-bb63-7176b31ded27", name: "Escola Superior de Comunicacao Social" },
    { id: "0c48d582-4d50-43b1-884f-c49112523f2c", name: "Escola Superior de Empreendedorismo (ESE) - Sebrae" },
    { id: "c992803f-72c5-40c7-9ec7-377cb8b329a2", name: "Escola Superior de Enfermagem do Porto" },
    { id: "b4f00c8e-249f-4faa-9f38-ed7656c4b308", name: "Escola Superior de Hotelaria e Turismo" },
    { id: "44d770a7-e9e5-4e88-b299-f3881aea44f0", name: "Escola Superior de Hotelaria e Turismo do Estoril (ESHTE)" },
    { id: "7fa93036-63ac-4c55-a465-7c73a0a06500", name: "Escola Superior de Propaganda e Marketing (ESPM)" },
    { id: "be8220fe-f167-4522-b4cd-f6d8325f201d", name: "Escola Universitaria Politecnica de Mataro" },
    { id: "5efb1345-21ec-474d-b439-9be8cb78132b", name: "Escomex Univesidad de Negocios" },
    { id: "a06c430b-d67c-4f28-a2b4-ea3739fdc5cd", name: "ESCP - Europe School of Management - Germany" },
    { id: "83053207-d6c9-479b-ad47-5c0002c7ab9c", name: "ESCP - Europe School of Management - Spain" },
    { id: "2ef26cb9-3316-45e0-a53e-535e13d489a6", name: "ESCP - Europe School of Management - United Kingdom" },
    { id: "86056036-b7b3-4703-82d4-bb0dca837265", name: "ESCP Europe - Torino" },
    { id: "8ca787a0-995b-49f5-a4eb-ced34b55a1fb", name: "ESCP Europe EESC (ESCP)" },
    { id: "46c282bd-4084-4ac1-ba7c-b7b1109695ea", name: "Escuela Bancaria y Comercial" },
    { id: "57d7595c-13a8-4cfb-90e3-7a625ed40840", name: "Escuela Bancaria y Comercial - Campus Reforma" },
    { id: "acd2414f-17a2-4ff3-882b-3fecfa744a29", name: "Escuela Ciencias de la Educación" },
    { id: "1ad3ff8e-282d-44e7-a10b-a34ccb4af2a4", name: "Escuela Colombiana de Carreras Industriales" },
    { id: "d35b5f7c-a6b2-4fd9-b008-38a0ae0b7353", name: "Escuela Colombiana de Ingenieria" },
    { id: "b4a93d8d-e93f-46d8-976e-311fec34c54e", name: "Escuela de Administracion de Negocios EDAN" },
    { id: "2a23f82d-f400-4789-a296-2c8e8ed114bf", name: "Escuela de Arte Xul Solar Junin" },
    { id: "41ffe5b3-94bc-4b31-81bc-de57cce75b36", name: "Escuela de Comunicacion Monica Herrera" },
    { id: "0a14cb2b-b8a7-4f58-a57f-56cf2c219065", name: "Escuela De Direccion De Empresas" },
    { id: "dd765ea8-78e3-41e1-8a8a-738408f93742", name: "Escuela de Finanzas Global Business School" },
    { id: "8802096e-daec-499a-aa3c-d7d4eaf56910", name: "Escuela de Ingeniera de Antioquia" },
    { id: "9e1c643a-d43b-44c9-8d3f-a6248e1b238f", name: "Escuela de Ingenieria de Antioquia" },
    { id: "14fe6994-ee0a-4a67-9132-bc98272e45b4", name: "Escuela de Negocios del Pacifico" },
    { id: "ad7750fb-24d0-4fd2-a823-38b8c6484371", name: "Escuela de Negocios IEEC" },
    { id: "46dde9d7-1007-4483-acb3-772942711c0c", name: "Escuela de Negocios y Direccion" },
    { id: "e8554cdf-98f7-4b3c-bde7-15349873d870", name: "Escuela Internacional de Protocolo" },
    { id: "a22e82b7-674e-45c0-889d-a138891105b4", name: "Escuela Libre de Derecho" },
    { id: "246c53d7-8fa5-4010-81b9-fa12237261b1", name: "Escuela Libre de Negocios A.C." },
    { id: "adede2bd-b6c5-4822-97d3-ef85ced0a777", name: "Escuela Militar de Ingeniera" },
    { id: "024fa3d4-c318-45dd-a8a4-952297159115", name: "Escuela Naval" },
    { id: "12e133d0-6d34-4723-8c99-85773ae84843", name: "Escuela Politecnica Nacional" },
    { id: "74ac082c-dc6d-4964-8c79-2f4113b7f49d", name: "Escuela Secundaria Tcnica No. 29" },
    { id: "42600ced-4998-4898-97c4-9ab890f02e6b", name: "Escuela Superior de Comercio Internacional (ESCI)" },
    { id: "72905fef-7b64-44b2-afed-72d73ff7315b", name: "Escuela Superior de Economia y Negocios (ESEN)" },
    { id: "9eb8a631-970c-456a-8ad6-be36b9431a4d", name: "Escuela Superior de Negocios Agexport" },
    { id: "4a124fc9-eaa6-4c36-aaf6-783980ae4e1e", name: "Escuela Superior Europea de Comercio SL" },
    { id: "a9ebf688-5992-4a36-a81e-eee8053366e1", name: "Escuela Superior Politecnica del Litoral (ESPOL)" },
    { id: "fc5447fa-42ab-49f6-a1e3-cb1045035d8f", name: "Escuela Tecnica Superior de Ingenieros de Minas" },
    { id: "920dc708-7b51-452b-8445-723b9c744a4a", name: "ESDEN Business School" },
    { id: "b54a688e-c776-4271-9fa5-62c693c33919", name: "ESDES The Business School of UCLY" },
    { id: "e7f6bdf2-1efd-4c86-ac67-56b1d705f8cb", name: "ESE Escuela de Negocios" },
    { id: "051a24d5-85a7-49b5-a937-d06c465d1095", name: "ESEADE" },
    { id: "38afdee1-99d5-42e3-803e-1c395a01ccef", name: "ESEI International Business School Barce" },
    { id: "19135f48-f660-4d55-a169-170ab7d32e49", name: "ESERP Fundacion Universitaria" },
    { id: "f6ac33f3-fbac-404a-9941-381d39052272", name: "ESESA - Escuela de Negocios" },
    { id: "ebcb5913-c884-4471-b64e-6e083a4049f2", name: "ESG - Paris School of Business" },
    { id: "1848136a-ae38-4796-be96-72629664756b", name: "ESGCI" },
    { id: "74fbacaa-665e-4a59-8220-e94e4df77be9", name: "ESGHT/UAlg" },
    { id: "d21ccc7f-9d53-4041-8b01-392cc44492ee", name: "Eshotel" },
    { id: "1b33752e-e380-4d16-8273-2c45d64933a5", name: "ESI - Guatemala" },
    { id: "d3aa97b4-cfb9-4a1a-a74e-47ae27913098", name: "ESIC" },
    { id: "c9ef77fe-489c-4069-a7e4-d5f9f5378cf0", name: "ESIC Business & Marketing School" },
    { id: "26a9af3a-8922-45f8-9687-610e8ca2f298", name: "Eskisehir Osmangazi University" },
    { id: "cb125cbf-f862-4f4f-8a22-27012f466ffb", name: "ESLSCA Business School" },
    { id: "e5683bbc-7b15-4420-9ba4-f98af7f18b95", name: "ESMAC - Escola Superior de Administracao Marketing e Comunicacao" },
    { id: "64f53718-7f29-4c4d-a119-66df01ffb1cd", name: "ESPAE - Escuela de Postgrado en Administacion de Empresas" },
    { id: "db4ea732-ccff-41ad-b65c-9ca42cf3daa2", name: "ESPCI - Paris Tech" },
    { id: "d8bc66df-8646-4e7d-9679-d7df0ce03888", name: "ESPE Escuela Politecnica del Ejercito" },
    { id: "aae929cb-87cb-44aa-9720-c2fb57491bec", name: "ESPL - Ecole Superieure des Pays de Loire" },
    { id: "2332cfa9-86f5-4145-8c41-ae137aa75215", name: "ESSCA School of Management" },
    { id: "48fce140-e32a-40c8-bc9f-0cdb350f0559", name: "ESSEC Asia-Pacific" },
    { id: "d9c19a71-3e08-44f5-90e9-e8d85168d002", name: "ESSEC Business School" },
    { id: "3b325557-90aa-455e-9748-5690f4c46c56", name: "Essex Business School" },
    { id: "17da6cc7-23dc-45b4-bbee-56002eb7a6e1", name: "Esslingen University of Applied Sciences" },
    { id: "a76ebdec-f9fb-4df3-a71f-61e6767f6f56", name: "Estacio de Sa University" },
    { id: "9699ad46-2112-46e5-b747-1b01d636bfe8", name: "Estonia Business School" },
    { id: "092f93f3-a516-4e69-8425-1315cab1578b", name: "Estonian Business School" },
    { id: "5041e5ee-e6f8-4626-a563-3405579c117f", name: "Estonian Entrepreneurship University of Applied Sciences" },
    { id: "027ff633-07ff-46fc-8c77-4721038af0a6", name: "Estonian-American Business Academy" },
    { id: "0599efc5-c6ed-48ab-a203-f6dfa7fc17b6", name: "Estrella Mountain Community College" },
    { id: "b753b7b1-f6de-4af7-a7b9-aa225ec53d5b", name: "Eszterhazy Karoly College" },
    { id: "254c2dfa-c420-4ec3-ad3a-b0cca084713b", name: "ETEP Faculdades" },
    { id: "d25fbc83-7ee5-4829-96ba-59f21599fa86", name: "ETH - Zurich" },
    { id: "de841568-6303-468e-90bf-103ec39a3243", name: "EThames Degree College" },
    { id: "c2e4be2f-6f8d-4f86-8c67-f187b25b6b39", name: "EThames Graduate School" },
    { id: "657c7ee9-77fd-42ee-a1b5-eb3bcb6eda59", name: "ETNA" },
    { id: "4b04a448-5c0a-4e3e-bd41-59109a985a22", name: "Etobicoke Collegiate Institute" },
    { id: "5d3189df-af7c-413f-a151-b96148346398", name: "EU SA" },
    { id: "96d18de8-6edc-47fa-ab24-9c46a9239b5a", name: "EUCLID" },
    { id: "5e146f8b-7380-4259-902e-64c47fea0d10", name: "EUDE - Escuela Europea de Dirección y Empresa" },
    { id: "a9cfe362-42ec-4812-861b-ea73c25387e4", name: "Euncet Business School" },
    { id: "ef2ce7d1-db60-457b-afc6-af9797485b2c", name: "Eureka College" },
    { id: "be2fa075-b90d-4934-87a5-612eed331f93", name: "Eureka Financial Ltd." },
    { id: "dd518f61-afa1-4ff3-9723-11d81999df1a", name: "Euro Business College" },
    { id: "523a248e-b5ea-47c2-8d42-c643f8357103", name: "Euroacademy" },
    { id: "13454221-6ce7-4f66-94a1-22c86b3ea381", name: "EUROMBA Foundation" },
    { id: "702c413f-b5bd-4d7c-9411-b5f1f78ca1ab", name: "Europa Kolleg Hamburg" },
    { id: "aa552575-440a-4c21-8650-71a72f61a5a0", name: "Europaische Fernhochschule Hamburg GmbH" },
    { id: "34effcdf-5c67-48a0-b44a-f532e73e450c", name: "Europe Asia Business School" },
    { id: "40ca9f1f-ba5c-4b80-be4e-93dc3f2afef6", name: "Europe Innovation Business School" },
    { id: "82e324f3-3786-4865-b348-4515804320c9", name: "European Business School - London" },
    { id: "46015a40-182c-4f94-b00e-1f403d32ed57", name: "European Business School - Paris" },
    { id: "08d24b63-2671-420a-83f1-740b35f23f7e", name: "European Business School - Switzerland" },
    { id: "1cbe0fab-3301-4f41-9923-5e0a85704097", name: "European Centre for Reputation Studies" },
    { id: "bd3d6542-83d1-4e3a-a6c8-f768ac23828c", name: "European College of Economics and Management" },
    { id: "c9ef4560-d4f1-4abe-904a-ba76cb5cdbed", name: "European Forum for Entrepreneurship Research" },
    { id: "aed7d167-f954-4be1-8c49-0cb18abc14ef", name: "European Foundation for Cluster Excellence" },
    { id: "8c75630d-430d-4387-9bfb-05c489299e7f", name: "European Institute of Design - Rome" },
    { id: "0e2d96ec-b853-45fe-9d28-d357ffe7a562", name: "European Institute of Purchasing Management (EIPM)" },
    { id: "57e42e63-e41f-4834-8ac4-2dd6dae18eb7", name: "European Peace University" },
    { id: "79c0e585-42b8-47d6-84b4-b12f429e6418", name: "European School of Economics - Italy" },
    { id: "76f387e9-e016-42a5-91f1-e676c6364abb", name: "European School of Economics, London" },
    { id: "02893a4d-fd03-4396-82b5-d6ad646b2d60", name: "European School of International Education (ESIE)" },
    { id: "98d839c2-9dc0-4c18-beeb-571f6556f0e0", name: "European School of Management (ESM-Tenerife)" },
    { id: "830ede1c-c68d-4053-bd5a-a68c043d965f", name: "European School of Management and Technology (ESMT)" },
    { id: "9faae914-f841-467e-bcd5-d7330bdd9da2", name: "European Students of Industrial Eng & Mgmt (ESTIEM)" },
    { id: "747cde80-9f87-4ce8-abdf-35edfa0ce687", name: "European University" },
    { id: "bb28959c-b02a-40c5-81bc-351127df9e3b", name: "European University - Barcelona" },
    { id: "4a68a8b7-4601-4543-9d59-17e8661f3a86", name: "European University - Geneva" },
    { id: "b29d6a2b-d5f8-4c5b-afa1-7ba09968679b", name: "European University - London" },
    { id: "30750427-ff8c-4189-92d4-866a4cfb3b18", name: "European University Institute - Rome" },
    { id: "0e93012a-e39b-45e7-a019-dad8f84b0970", name: "European University of Brittany" },
    { id: "c2df4fb4-0979-4546-a080-89e2cc3632d2", name: "European University of Lisbon" },
    { id: "0f7f8580-1fd0-4ae9-8644-966b4a5f7f90", name: "European University of Rome (UER - Università Europea di Roma)" },
    { id: "261fe8d4-e829-4795-bd68-f43f06d8a95b", name: "European University Viadrina Frankfurt" },
    { id: "740c2687-9f5c-45b7-ba95-97b3da0532f3", name: 'European University, Greece"' },
    { id: "dc00f275-1985-4479-a9b4-d4267630225a", name: "European University, Montreux" },
    { id: "70e8893a-07b3-434d-9bb0-db90edf67687", name: "Europort Business School" },
    { id: "b2860480-2a8e-4d76-8a85-23231ea29178", name: "Europäische Fachhochschule" },
    { id: "bf2f5a1a-8635-4198-9548-4e5d032d2c53", name: "Evangel University" },
    { id: "3c8f0712-99ee-4e13-b7c9-98a3ef885394", name: "Everest University Online" },
    { id: "a7c96265-71db-496b-9b53-d2b5e5c48120", name: "Everett Community College" },
    { id: "98d61905-c834-4daf-a4dd-f50bfe995803", name: "Everglades University" },
    { id: "6651ba05-8661-4591-ae89-7b3cfbe244af", name: "Evergreen State College" },
    { id: "a3cfd7bb-a76b-48ff-b82f-b5b8fdcc6350", name: "Everonn Education" },
    { id: "9c1ad9ab-b621-49d4-a387-7678db339026", name: "Eversheds LLP" },
    { id: "552547d6-0188-498d-98d1-1a818842a131", name: "Evesham College of Further Education" },
    { id: "e7f717bb-9b02-49f8-af18-40477291807e", name: "Evidence in Motion, LLC" },
    { id: "1b2cff70-1525-41a7-afc7-0dd70a39d0f7", name: "Ewha School of Business" },
    { id: "f90f2920-bbec-4f27-b929-0a36cb18073a", name: "Ewha Womans University" },
    { id: "39534cfa-8931-4945-8555-af09040b927d", name: "EXCELIA Business School" },
    { id: "d9f99c87-3427-46f8-b2aa-48e45dd7e234", name: "Executive Counselling and Training Academy (ECTA)" },
    { id: "b607f829-461d-4571-95d6-abf34a2faf28", name: "Exempla Saint Joseph Hospital" },
    { id: "674bc70b-07bf-4b59-848b-ed5cea328094", name: "Experta Business Academy" },
    { id: "8f6e7d8b-009e-4bd7-a932-5d81b8812cff", name: "Express Courier Service Inc" },
    { id: "d7f3c770-f538-4203-8d79-74330a93a6e3", name: "Eötvös Loránd University" },
    { id: "ec2181f1-fc18-44af-949d-6d9fc58811a1", name: "FACAMP - Faculdade De Campinas" },
    { id: "b6d8c09f-717a-449c-a091-fb87edd6b265", name: "FacBrasil" },
    { id: "e9d7e8d7-fea6-4fe6-b8c1-f8c27a2597fd", name: "Fachhochschul Burgenland" },
    { id: "c60be5e3-5378-417e-9ac5-f07c588bf3e5", name: "Fachhochschule Bielefeld" },
    { id: "d306946f-f820-4c53-bafd-9401aac0148f", name: "Fachhochschule Bonn-Rhein-Sieg" },
    { id: "4473d5b0-6db5-46d8-9c1c-053bd4b83083", name: "Fachhochschule Brandenburg" },
    { id: "359abba3-fdf3-4e12-a1f6-fcca322f3861", name: "Fachhochschule Darmstadt" },
    { id: "ba422f25-f383-4211-9744-d469b534056c", name: "Fachhochschule Deggendorf Applied Scienc" },
    { id: "9393383f-7877-4771-a580-07e646f3eb68", name: "Fachhochschule Der Wirtschaft (Fhdw)" },
    { id: "04f1eb62-0a3b-4123-bb39-dd68e07267d1", name: "Fachhochschule Der Wirtschaft - Paderbor" },
    { id: "e2b187c3-4428-4d20-bbb3-64337057d3dc", name: "Fachhochschule des BFI Wien Gesellschaft" },
    { id: "59a2d5cf-a881-4b8a-8558-59a544e147a3", name: "Fachhochschule des Mittelstands (FHM) GmbH" },
    { id: "9ef5d193-52d5-4de8-a880-2ae8241fbdb7", name: "Fachhochschule Dortmund" },
    { id: "c2ec7127-b0e2-43a7-90ea-505eec1d9a0a", name: "Fachhochschule für Angewandtes Management" },
    { id: "a4884fc2-82e0-4ab2-a447-b325d01d8e80", name: "Fachhochschule Gelsenkirchen" },
    { id: "79597944-7b25-414d-85d6-91cd26b689a2", name: "Fachhochschule Giessen-Friedberg" },
    { id: "f5e6520d-25f7-4476-bd9a-f38424a1ac87", name: "Fachhochschule Graubünden" },
    { id: "05eabaa6-6a00-4eb2-ba23-577ba0dc974f", name: "Fachhochschule Heidelberg" },
    { id: "769d749f-d02e-4e90-8e5b-1b3a32167332", name: "Fachhochschule Jena" },
    { id: "80e329a7-8399-4385-9eb6-8ee6ac37f9ef", name: "Fachhochschule Koln" },
    { id: "d8510aae-e124-4e1a-9d13-f1cbc7977431", name: "Fachhochschule Lubeck University of Applied Sciences" },
    { id: "3b33b5fe-3ca7-47ec-9924-eac5298018d5", name: "Fachhochschule Lübeck" },
    { id: "9e224947-c1ce-46cf-b45a-f4182ad8d025", name: "Fachhochschule Marketing & Sales" },
    { id: "e04eb435-256b-4a97-9b83-4e3b3eb7b526", name: "Fachhochschule Salzburg" },
    { id: "0053bfe1-2fe4-4235-b600-f8fdf63d6b56", name: "Fachhochschule Schmalkalden" },
    { id: "e1f54d98-9522-42ac-91d4-5131ad3780b8", name: "Fachhochschule Stralsund" },
    { id: "e27616f6-d66e-47fa-97f5-720f97d38205", name: "Fachhochschule Sudwestfalen" },
    { id: "68a956de-47ea-4b15-8965-94a269e1f601", name: "Fachhochschule Technikum Karnten" },
    { id: "a6630cb0-0328-424d-8819-813f1ee539c4", name: "Fachhochschule Vorarlberg" },
    { id: "609c4ede-c7a5-4639-ad08-2c10bcdb1f15", name: "Fachhochschule Wiener Neustadt" },
    { id: "5751a9ea-6bd2-45a0-8d24-60b79e9d6d62", name: "Fachhochschule Wiesbaden" },
    { id: "8d7cb2fc-5b5b-4cb2-8b8a-ed4ed025cb23", name: "Fachhochschule Wuerzburg-Schweinfurt" },
    { id: "4567e216-fefe-4d5f-a165-24a5aa572178", name: "FACIG - Faculdade de Ciencias Gerenciais de Manhuacu" },
    { id: "c2cba3eb-1b62-422d-912a-7e41336f1fa2", name: "Faculdade 7 de Setembro" },
    { id: "0d32b3aa-591d-4c81-b9ad-aa80dc208799", name: "Faculdade AIEC" },
    { id: "7779c163-d597-4d34-8a92-89ae642a6458", name: "Faculdade Cancao Nova" },
    { id: "e979b567-dffa-4c8f-a026-e19812cd3a0e", name: "Faculdade CCAA" },
    { id: "d366623a-7422-4dd9-92bc-ec343717425d", name: "Faculdade da Serra Gaucha FSG" },
    { id: "26aa397d-10ca-41e7-bce9-faf09604dd1f", name: "Faculdade de Administracao e Ciencias Contabeis FACC" },
    { id: "621dae0f-98ff-442c-9d8d-00c537139904", name: "Faculdade de Castanhal FCAT" },
    { id: "8eff8536-dac0-4423-b09f-861a8e10703c", name: "Faculdade de Medicina e Enfermagem do Hospital Israelita Albert Einstein" },
    { id: "7c50855c-5d49-4c27-b53d-2e0cfaf3b64b", name: "Faculdade de Tecnologia de Guaratingueta FATEC" },
    { id: "786da308-59e0-40b6-a40a-35ed1d9e15e0", name: "Faculdade de Tecnologia de Jundiai" },
    { id: "d328b53d-faaa-41e2-844f-3e8bcbae8a27", name: "Faculdade de Tecnologia Senac Criciúma" },
    { id: "fc486829-edf7-4086-ab3b-171ddc4a8958", name: "Faculdade dos Guarapes (FG)" },
    { id: "fd43af96-215b-43a6-8bca-21f53f919e83", name: "Faculdade Eca de Queiros" },
    { id: "f3b7569d-8fb8-4b1a-91ba-53182655574f", name: "Faculdade Impacta" },
    { id: "2dd1633e-53b6-43b9-93cc-929b9d0e444a", name: "Faculdade Integrada de Santa Maria FISMA" },
    { id: "fda15d4f-b907-4992-9388-88e12dc7f6e5", name: "Faculdade Integrado" },
    { id: "26c7ac50-256d-4ba8-9103-fe0d79952af5", name: "Faculdade Internacional da Paraiba (FPB)" },
    { id: "c7ab56ad-21e8-48a5-8045-13be44da792b", name: "Faculdade ITOP" },
    { id: "012e509e-0a59-44fc-8a32-71e767cfc5a6", name: "Faculdade La Salle" },
    { id: "65699068-aafb-4f90-b97d-4f917af8df91", name: "Faculdade Legale" },
    { id: "61dbceca-c575-4888-8d1f-d2e83aa580d1", name: "Faculdade Marista" },
    { id: "744da49c-0468-4473-9954-87f5ea5647ec", name: "Faculdade Mauricio de Nassau" },
    { id: "6bfde6e4-98f3-4d28-9afd-0b0791ca4b44", name: "Faculdade Murialdo" },
    { id: "4f012d22-6caf-4df7-8fa7-5a4594ac6283", name: "Faculdade Novos Horizontes" },
    { id: "4dfad7cf-43cc-4dec-84bf-5aa1aa4455a7", name: "Faculdade OPET" },
    { id: "cc78d409-0233-4917-97aa-463f16401277", name: "Faculdade Paraiso" },
    { id: "21444d61-e9f2-4bd5-93eb-8a507d854526", name: "Faculdade Projecao" },
    { id: "b4975d86-f158-413d-8179-4bc07b5c57df", name: "Faculdade Santo Agostinho" },
    { id: "81b605b4-97c4-4bc4-b045-821b8ad4adca", name: "Faculdade Sumare" },
    { id: "e1a68c54-efe4-4f60-a04f-fe9313fe2d4c", name: "Faculdade Teologica Sul Americana" },
    { id: "58ddbb9d-ca7b-4c73-88e9-2a0b0b8768cf", name: "Faculdades Alves Faria" },
    { id: "67d31ebf-9186-4465-8b2e-bcf179f1080d", name: "Faculdades Integradas Rio Branco" },
    { id: "75b6600f-fe5d-4463-9a3b-c582db089d4b", name: "Faculdades Integradas Teresa D'Avila FATEA" },
    { id: "c822f51b-61f1-4171-a272-73b58068992f", name: "Faculdades Network" },
    { id: "76b040df-e10f-4b79-ac6d-87fec64fe154", name: "Faculdades Rio-Grandenses" },
    { id: "8f608e98-49b5-429c-a260-382a06d9ca43", name: "Facultad de Administracion de Empresas" },
    { id: "722e841f-112d-45d5-8c25-d9a12dacd995", name: "Facultad de Ciencias Administrativas" },
    { id: "3b2dda9c-9941-45cf-a054-e1c04b5b981f", name: "Facultad de Ciencias Económicas y Empresariales (ETEA)" },
    { id: "66735805-ca91-487c-bc84-78d31256732a", name: "Facultad De Letras Y Filosofia" },
    { id: "87a01a04-5f8b-48f6-81e5-c39b006562b6", name: "Facultad Libre de Derecho de Monterrey" },
    { id: "106cd486-bdd3-4a34-bccb-f3d7c5991c29", name: "Facultas Bookshop AG" },
    { id: "a365b3b3-3e01-48cf-98ed-230b8b9206e8", name: "Facultatea de Antreprenoriat, Ingineria ?i Managementul Afacerilor - FAIMA" },
    { id: "98fd4a08-1977-4bb5-921e-024a8dafa9a6", name: "Faculte Jean Monnet" },
    { id: "52e93d4c-063f-4176-9e8f-1f44922e2923", name: "Faculty of Commercial Sciences" },
    { id: "473defef-8fd2-4687-966d-25971dfd0aff", name: "Faculty of Economics" },
    { id: "4b406a87-55b2-4dfa-be63-4332490da6d5", name: "Faculty of Economics - Skopje" },
    { id: "07fef17f-8e6c-46d8-a5b2-e67b28e5973c", name: "Faculty of Economics and Business Administration" },
    { id: "63a7f27d-367e-4216-aa0e-e6df94a05b88", name: "Faculty of Economics and Management" },
    { id: "cb936b4d-98bd-493d-a20a-b07c59b04a00", name: "Faculty of Economics in Osijek" },
    { id: "153a253a-8925-4651-9fbd-30bfdf7b2476", name: "Faculty of Economics, Finance, and Administration (FEFA)" },
    { id: "40312842-416a-40de-bf45-738474299e2d", name: "Faculty of Engineering" },
    { id: "1bfd43bb-623c-4abb-9c79-18b4e248ef11", name: "Faculty of Management Studies, University of Delhi" },
    { id: "5048afdf-8962-47bf-a0da-db813e70b577", name: "Faculty of Organisation Studies - Novo Mesto" },
    { id: "b719df2a-5c81-4e82-a9e3-9f035d8497cf", name: "Faculty of Science" },
    { id: "1dc5d522-3fc9-4272-97bf-d598624d4a46", name: "FADERGS" },
    { id: "468f8909-aa7b-4c86-839b-ab227a22116a", name: "FAE Centro Universitario" },
    { id: "70f4e4d3-57b0-4436-8792-a8ddb3750bcc", name: "FAESA Campus Cariacica" },
    { id: "3e2a3704-fe23-4f4c-9d52-a552b5da86eb", name: "FAI - Centro de Ensino Superior em Gestao, Tecnologia e Educacao" },
    { id: "3c7a7ecd-215f-4c47-8880-d9c6fa20645a", name: "Fairfield University" },
    { id: "0b253ff7-5770-4d63-8bf3-1dea44d28bd9", name: "Fairleigh Dickinson University" },
    { id: "2507f054-1ee1-4b58-a4d6-5932e64d77c7", name: "Fairmont State College" },
    { id: "914f4425-02ae-499b-bc77-d849f72c3446", name: "Faith Evangelical College & Seminary" },
    { id: "528f0d29-35db-46ec-8644-a8e83a154268", name: "Falmouth University" },
    { id: "a026c3d7-ec13-406a-aeae-87d59d646e01", name: "Fanshawe College" },
    { id: "c4d3d17f-992a-42b0-a39d-ba27de90b0b4", name: "FAPPES - Faculdade Paulista de Pesquisa e Ensino Superior" },
    { id: "4e61f45d-cfc4-4bd7-8671-3d829f4d8789", name: "Far Eastern Federal University" },
    { id: "9de80389-4043-46d4-9525-ef7760ebaacd", name: "Far Eastern University" },
    { id: "efd6adef-f4f0-4e2d-a795-c234487d9e03", name: "Farmingdale State College" },
    { id: "0d511aba-08ad-407e-8564-0f738550df4a", name: "Fashion & Design Institute" },
    { id: "22236f5b-05d7-4502-a834-280ef0dbdfec", name: "Fashion Institute of Design & Merchandising" },
    { id: "6adeb3e1-178d-4168-a0fa-721f21ad0528", name: "Fashion Institute of Technology" },
    { id: "56d3a5ff-f150-4b1d-a0f9-ded7991fa138", name: "FAST School of Management" },
    { id: "20dfb257-cafc-4f5c-bf3e-3b6ab97f2c7f", name: "Father Saturnino Urios University" },
    { id: "82f2c588-de08-4c81-8a18-86d112e6ab30", name: "Fatih University" },
    { id: "083e956a-2cdd-473f-9522-c7e181005eb2", name: "Fatima Jinnah Women University" },
    { id: "92eb3fe8-4f21-4051-9650-b8fe99bd7424", name: "Faulkner University" },
    { id: "5c95d0c0-7ffc-4a15-beb3-51fae29c0ddd", name: "Faulknere State Community College" },
    { id: "e9c497d0-8e38-4b31-9cb6-816c6f921276", name: "Fayatteville State University Bookstore" },
    { id: "d1269a5d-152a-4596-8364-a284d17a78bd", name: "Fayetteville State University" },
    { id: "04fa36a5-d5a8-4d04-ad95-bec6642ef079", name: "Fayetteville Technical Community College" },
    { id: "46a1a08e-4860-4425-a751-237c2b40b78e", name: "Fazlani Altius Business School" },
    { id: "7af8f363-f4ba-451c-b4ac-7bcb6f125633", name: "FCP Academic Services" },
    { id: "233c1408-ecff-4ed8-ba81-60f71a6da3d3", name: "FEAA" },
    { id: "53e070f0-961b-4b5d-9199-27e61d98eb0d", name: "FEAD" },
    { id: "f478e799-584f-46f8-904d-00951aeec8f9", name: "FECAP - Fundação Escola de Comércio Álvares Penteado" },
    { id: "b877d6d7-1cae-4779-a43f-73b8d79b571a", name: "Federal Executive Institute" },
    { id: "6014fd54-79b2-4ea1-a57d-6c6b032f8e1f", name: "Federal Institute of Science and Technology" },
    { id: "39f5ec42-5272-407c-86e1-ec30299b2b24", name: "Federal University of Agriculture Abeokuta" },
    { id: "6b36268b-5b2a-4929-8373-9003404df6a8", name: "Federal University of Campina Grande UFCG" },
    { id: "a25c1445-266b-4410-8932-4696453be561", name: "Federal University of Paraiba" },
    { id: "1db7d256-a4d7-46b9-b5bf-e05b71d2dd6e", name: "Federal University of Santa Maria" },
    { id: "f32b98f9-7098-4f8b-8689-817e119e378a", name: "Federal University of Technology Akure" },
    { id: "e93a1638-9594-43e9-a09c-b701fdb2b9c9", name: "Federal University of Technology Minna" },
    { id: "1db84389-8049-48c2-b106-c87346fb9e1f", name: "Federal Urdu University of Arts Science & Technology" },
    { id: "5a49f92c-bb6d-4139-909d-eb5ce783ddfc", name: "Federation University Australia" },
    { id: "1501f646-748c-45ad-8be6-52ac639c81aa", name: "Federation University of Australia" },
    { id: "6fbaeccf-3a47-4ae3-b94b-0690ce90881e", name: "Felician College" },
    { id: "c822d790-4636-4dd7-b74a-1a7ae0690494", name: "Feng Chia University" },
    { id: "eef61ae4-9be9-423e-9d0c-4554d1bd370f", name: "FEPAM - Faculdade Europeia de Administracao de Marketing" },
    { id: "8ed79653-7378-4e3d-afa8-0c0c5b98572a", name: "Fernuniversitat Hagen" },
    { id: "642d0df8-21a0-4375-b1d5-73574e263191", name: "Ferris State University" },
    { id: "c15b104c-a056-4d4c-96b5-30f6a102e369", name: "Ferrum College" },
    { id: "d71b6201-7168-4adf-944d-56596feb1cda", name: "FESP - Faculdade de Educacao Superior do Parana" },
    { id: "88d02b33-9352-4ffe-8ad1-bde4cf0572c8", name: "FESPSP Fundacao Escola de Sociologia e Politica de Sao Paulo" },
    { id: "e7462027-e8da-4e5d-a661-363263104c62", name: "FFHS Fernfachhochschule Schweiz" },
    { id: "d27411ae-35f5-4613-a633-1ab44c7734df", name: "FGV - EAESP" },
    { id: "c3e55b48-275a-4427-8b13-035173ecb979", name: "FGV EESP" },
    { id: "0ab24f39-5d92-4dc5-b32e-bded48a72126", name: "FGVSP - Eae Sp" },
    { id: "780ef2f2-47a6-44d7-81e2-f3277af0ec43", name: "FH Campus Wien" },
    { id: "515288e7-8930-4e5b-a0d6-1ec868dc81e6", name: "FH Joanneum" },
    { id: "4d04996a-f48f-4747-bca9-5daf856280aa", name: "FH MUNSTER UNIV FOR APPLIED SCIENCE" },
    { id: "108c328c-8fb4-4e15-b275-536d8985d38b", name: "FH Nordakademie" },
    { id: "345cd638-077f-4d59-9c5c-1ba0e5303c61", name: "FH Steyr" },
    { id: "28f9ec70-bd8e-41f7-9e4c-cc400469c18a", name: "FHS KUFSTEIN TIROL BILDUNGS GMBH" },
    { id: "4be826ba-a78e-4f7e-b1b5-ce70452eeae1", name: "FHWien University of Applied Sciences" },
    { id: "9cca9735-a35f-4030-ab77-02e0c44d006a", name: "FHWS - University of Applied Sciences Würzburg-Schweinfurt" },
    { id: "22627ffe-ee36-4360-b8b3-a3e017f83541", name: "FIA - Fundacao Instituto de Administracao" },
    { id: "34d84e7a-15f6-4936-a8fc-876b20e1fadd", name: "FIAP - Faculdade de Informatica e Administracao Paulista" },
    { id: "e86ed752-ec49-430d-9971-ef744e4b5376", name: "Fielding Grad University" },
    { id: "ef53b433-a76d-4813-bca7-543a49aa667f", name: "Figeac Institute of Technology" },
    { id: "93dc21bc-6714-46b8-b75f-a0e417c3b5ae", name: "Fiji National University" },
    { id: "5c3e4f08-5ece-4614-99f2-970eb25e6df2", name: "Financial Academy Moscow" },
    { id: "fee2f8b9-937e-4a11-82eb-3db32bf5a063", name: "Financial University Under the Government of the Russian Federation" },
    { id: "7bec5ebf-77b6-4e09-a03b-bb1747f1230c", name: "Finger Lakes Community College" },
    { id: "4cd90e84-1da8-4058-8078-792f8504fa71", name: "Finlandia University" },
    { id: "4a785139-589d-4112-9a6e-b08b68848a15", name: "Finva" },
    { id: "f99c3fca-78d6-4666-b5ea-50ae360ccae9", name: "FIPECAFI - Institute for Accounting, Actuarial and Financial Research Foundation" },
    { id: "ff059bb1-f6a4-4f88-b602-95cc0571cc95", name: "Firebird Institue of Research in Management" },
    { id: "1a883437-d297-407a-97f8-929ab807ad07", name: "First Asia Institute of Technology and Humanities" },
    { id: "69c95ebd-439a-4115-bff1-9fa657127c87", name: "First Nations University of Canada" },
    { id: "2acee057-065a-4c3c-8ce1-fa010560e735", name: "Fisher College" },
    { id: "0e827fff-956d-4344-a184-5f13abda4faf", name: "Fisip Universitas Airlangga" },
    { id: "5294c261-c2f6-4e7c-afee-6dcfd7ae7c60", name: "Fisk University" },
    { id: "5a2e175c-7fb2-4c55-bf5e-1d19a2338d34", name: "Fitchburg State University" },
    { id: "d5347f3a-e1f3-4c16-bde6-65da7dad9433", name: "Fitzwilliam Institute Group" },
    { id: "d978f4a7-032f-47ce-aa43-8f357319ff82", name: "Flacso Argentina" },
    { id: "cb725823-c062-42b9-ab80-f01175d8cca2", name: "FLACSO Uruguay" },
    { id: "4ecf599b-46f6-4cd8-bdc2-3e33a79f8571", name: "Flagler College" },
    { id: "412f5c36-2022-4774-937b-5b35de29412b", name: "Flagler College Tallahassee" },
    { id: "33be3063-b0f8-441e-978d-92dcb2eee95a", name: "Flanders Business School" },
    { id: "31ed6b9b-8724-4260-b845-7166717e3d94", name: "Flathead Valley Community College" },
    { id: "7ee01ba2-ff4d-445f-8f22-ccd45396a29c", name: "Fleming College" },
    { id: "83ae8513-1d17-4662-b3a5-c7d3b4500c5c", name: "Fletcher's Meadow Secondary School" },
    { id: "f9171cfe-71b6-4a73-a0ca-19a6e6466b98", name: "Flinders University" },
    { id: "919446ae-4e24-40eb-a89e-6cab8b61357e", name: "Florida A&M University" },
    { id: "e4e7a878-1739-4f7e-9dbc-bac1a670fc17", name: "Florida Atlantic University" },
    { id: "e89b7dca-fc22-43d2-9e52-4da5de035e0f", name: "Florida Career College - Miami" },
    { id: "cd195745-7c31-4536-8e05-18fa9bcf931c", name: "Florida Christian University" },
    { id: "c9c8d0e8-8b55-4ae3-980e-e2a593a19ae2", name: "Florida Coastal School of Law" },
    { id: "1138320f-37cc-466b-a8b2-abf54a7d736d", name: "Florida Gateway College" },
    { id: "b0cb958c-e012-4f4c-987c-bee8dbb6f91b", name: "Florida Gulf Coast University" },
    { id: "032e4c76-fac5-4372-a7e6-1d3640fc7a99", name: "Florida Institute of Technology" },
    { id: "84cc45bd-7035-4245-9b49-eb6139dee7b0", name: "Florida International University" },
    { id: "c6885744-dc7b-4a20-9f55-0712f740d5c0", name: "Florida Memorial University" },
    { id: "41184846-ae8f-40d8-83d8-8abb306f5ad4", name: "Florida Metropolitan University" },
    { id: "8371255f-482c-4f35-925b-9c234fb0fe61", name: "Florida National University" },
    { id: "2a6ceb48-75b0-4531-8fed-11fed8418e00", name: "Florida Polytechnic University" },
    { id: "80456a3b-cc6d-4874-b850-69415a05cbc9", name: "Florida Southern College" },
    { id: "591e547f-766e-4ec1-9033-9a13b69caf0e", name: "Florida SouthWestern State College" },
    { id: "0f30a1ed-ab23-489e-9479-4495770360d4", name: "Florida State College at Jacksonville" },
    { id: "379dea5e-49d1-4f33-899f-cbeab0107300", name: "Florida State College South Campus Bookstore" },
    { id: "b6de7afd-c606-4eb2-9ec5-d124bd732cd4", name: "Florida State University" },
    { id: "ccda32b7-1e88-4803-8773-ce22b2d08656", name: "Florida State University - Panama City" },
    { id: "ac02fdcb-6967-49c3-ae66-27cec8b73927", name: "Florida Tech" },
    { id: "570903ee-5784-4c88-8c12-a238662290cc", name: "Florida Virtual School" },
    { id: "643c061b-7aaa-4182-81db-6db83c57d47a", name: "FMSolutions" },
    { id: "7f2cbe4a-1174-4e52-91a7-5004a26c34bd", name: "FMTU - Faculty of Toursim and Hospitality Management" },
    { id: "c523882b-c207-41dd-a30b-7f8598f0e230", name: "FMU Education Group" },
    { id: "2468f906-2c66-4d7c-8187-a11431463423", name: "Fo Guang University" },
    { id: "ef9fa6a9-ceac-4801-b1d3-de3b2b86e35e", name: "Follet Store #1550" },
    { id: "c41402e2-0382-44b4-815a-6d5617f3d904", name: "Follett" },
    { id: "621f83fa-5a4a-4e57-94f1-482ea6051aa4", name: "Follett Art Center College of Design" },
    { id: "90b4ffeb-e376-422f-b7f0-4ab73f7a3e28", name: "Follett Higher Education Group" },
    { id: "cb94b0f6-4a5f-4b81-aaff-d8ab2940a380", name: "Follett Library Resources" },
    { id: "b2a9d4e1-8cda-4de6-b56b-39e567b9869d", name: "Follett Store" },
    { id: "c65e787b-96d1-4aa3-b140-0f6ab706446c", name: "Folsom Lake College" },
    { id: "a19dc645-3bb9-416a-843d-03afdf82ecd9", name: "FOM Hochschule - Duisburg" },
    { id: "9fd70d2a-a32a-4e3c-9e85-f768bcbc9f8d", name: "FOM Hochschule - Dusseldorf" },
    { id: "ba442b42-dc64-420b-b1b7-1988cdee3df0", name: "FOM Hochschule - Essen" },
    { id: "83a4aad5-f69a-44e9-8ba6-159b5a147e0d", name: "FOM Hochschule - Hamburg" },
    { id: "9f52884a-1ed3-48cc-8451-0e815007fdbe", name: "FOM Hochschule - Koln" },
    { id: "a76bf532-0ca9-419d-9ade-ca2bd51c68ec", name: "FOM Hochschule - Stuttgart" },
    { id: "8c1d1638-14ec-4eee-afe0-0726ffb5a367", name: "FOM Hochschule für Oekonomie & Management gemeinnützige mbH" },
    { id: "fc43542e-1edc-45ea-a39a-4b61365dd5a6", name: "Fondazione European School of Management Torino" },
    { id: "5b9feff8-bfd3-4316-8bfb-a9b71627c8b2", name: "Fontbonne University" },
    { id: "2e0bf7e0-a164-4e74-9b6c-d04889c93abb", name: "Fontys Intlhogeschool Economie (Nl)" },
    { id: "506222fd-7cfb-4117-9a59-c835fa3ac6ec", name: "Fontys University of Applied Sciences" },
    { id: "1f1cbdc5-b18d-4c6a-96d2-a676df8dcd64", name: "Foothill College" },
    { id: "4c206a6d-44f3-41c7-a975-30014b7da8f7", name: "Footwear Design & Development Institute" },
    { id: "f33bd412-2f4a-4d4c-b70d-cee415631f04", name: "Fordham University" },
    { id: "79f01d8b-d9ea-403f-ae3d-fd33d0721d78", name: "Fordham University Bookstore" },
    { id: "be67f4f7-fce3-484f-8a1d-73a14bf8050f", name: "FORE School of Management" },
    { id: "e1306663-aab4-4bc1-8c6c-f3dc72d26b8a", name: "Foreign Trade University" },
    { id: "f3dc2efa-3230-4e8a-b7cb-5951845ae386", name: "Forest Institute" },
    { id: "84c6874c-d44d-4a7d-97c4-b21c80a7f328", name: "Foretagsekonomiska institutionen" },
    { id: "9aee8491-0ba6-4a04-b0dc-e831d48b5e4a", name: "Formacion Ejecutiva 360" },
    { id: "777ecceb-c226-45c5-af90-9ad26a5471f6", name: "Forman Christian College" },
    { id: "876b6fc5-d986-4b66-8994-c4241a2d0c2d", name: "Formato Educativo Business School" },
    { id: "0195dbe6-8d2d-449f-ab86-69ec9a0def75", name: "Formedia" },
    { id: "ae149251-323b-48ee-bb2e-27e65601f6d9", name: "Foro Europeo Campus Empresarial" },
    { id: "5f9cdcd7-612c-4c10-a53a-c09f473e43f9", name: "Foro Europeo de Formacion Empresarial" },
    { id: "ce835df5-a2d6-4f3c-a271-12333c69613d", name: "Forsyth Country Day School" },
    { id: "246bb656-436b-4390-8a2b-0fc682cdf808", name: "Fort Hays State University" },
    { id: "2336aeb1-b587-4fb0-af53-ff48d6f243d8", name: "Fort Lewis College" },
    { id: "014d678e-2762-4232-adbc-2b452b01b6f9", name: "Fort Scott Community College" },
    { id: "a53e130d-6ac0-4e8b-b503-7921ca07a7ff", name: "Fort Valley State University" },
    { id: "f36b1943-6dd4-4fa6-9b60-54b74413083e", name: "Fortis College" },
    { id: "64bd531b-0c97-4b00-b8a3-615af2a5e6c4", name: "Fortis College in Centerville" },
    { id: "085f560d-cb2c-415f-8f44-5b960714edfd", name: "Fortune Institute of International Business (FIIB)" },
    { id: "27746534-8a12-4cfa-a6a3-6fea2cf57540", name: "Fostiima Business School" },
    { id: "3a955f09-4b64-4137-8a3f-d3bbf51fdcea", name: "Foundation for International Education" },
    { id: "b6e7b6d6-9e26-4c1b-8b4f-864ad61a1b6b", name: "Foundation for Liberal & Mgmt Education (FLAME)" },
    { id: "b39e019d-aaf2-465d-8616-91c735aef5af", name: "Foundation University - Islamabad" },
    { id: "27f79ac3-52bb-4801-99d8-35ded070af4e", name: "Fox Chase Cancer Center" },
    { id: "3f27e14d-5f6e-466e-83fd-88ca0a92db43", name: "Fox Valley Technical College" },
    { id: "b8f29659-1c59-4ffe-af2f-16ef2b41c06a", name: "Foxborough High School" },
    { id: "a5e1aa02-f68b-4259-852d-65aea25aef6f", name: "FPT School of Business [FSB]" },
    { id: "a41b15d2-2634-4d88-88f8-131e218dafc1", name: "Framingham State University" },
    { id: "52ab90dc-6566-48e6-a275-03f699099e33", name: "France Business School - Clermont-Ferrand" },
    { id: "43015765-8172-42d5-8a60-631fe06bacfa", name: "France Business School - Paris" },
    { id: "16a356cd-33a4-4760-b035-4eb53da0af64", name: "France Business School - Tours" },
    { id: "171d8c1f-a183-4ad3-9329-3759e94e8165", name: "Francis Marion University" },
    { id: "4deb6afd-8e05-42a4-a9a6-16c8d86ae778", name: "Francis Tuttle Technology" },
    { id: "eefed9b0-686f-43ff-b33f-4cd12ffcb1bc", name: "Franciscan University" },
    { id: "a9ed31b3-4232-4e01-a7ff-294a1cfca4cb", name: "Frankfurt International School" },
    { id: "e02c697f-9be5-4125-9b27-2bc1dba418a6", name: "Frankfurt School of Finance & Management" },
    { id: "bfc1f381-45d4-42fd-9aae-df0d1fa7335a", name: "Frankfurt University" },
    { id: "d18bfdb9-6454-4ab5-ae8e-094cf64f6c6b", name: "Franklin and Marshall College" },
    { id: "3a9dcafe-28ba-4449-9d3e-c8d7d53ff2de", name: "Franklin College" },
    { id: "c7bc07e6-c82d-407f-8601-c02ac2085516", name: "Franklin Olin College of Engineering" },
    { id: "224f1048-9304-4f1b-9e53-e583c3026098", name: "Franklin Pierce University" },
    { id: "5d4a58fe-c636-44ce-a32f-4a6dd32ee260", name: "Franklin Pierce University- Online Division" },
    { id: "cd779e6c-d133-41cc-824f-d8ad3c58e768", name: "Franklin Public Schools" },
    { id: "e39207a8-faac-41c7-adb4-deea6bc4ff52", name: "Franklin University" },
    { id: "f1f0181f-ecfd-496a-86f9-dd45bd9085b1", name: "Franklin University Switzerland" },
    { id: "6d0dc7e2-2acc-4d07-9604-17780aa72c36", name: "Fraunhofer Center for International Management and Knowledge Economy IMW" },
    { id: "38ebb4f4-6eb1-436d-b6af-3e54c0e7bb4a", name: "Fraunhofer-Institut für Produktionstechnologie IPT" },
    { id: "ed4854fe-4b14-431a-b92c-8685f30fad2d", name: "Frederick Community College" },
    { id: "e9669978-bee9-4193-956d-f2c2f285865b", name: "Frederick County Public Schools" },
    { id: "659578e6-bb23-4e0a-bb4f-288cf31a970f", name: "Frederick Taylor University" },
    { id: "4a6b5941-593c-4227-abba-5bda3c98e0d9", name: "Frederick University" },
    { id: "2cc960a0-8fba-4ec2-8032-f1ba8296ef5e", name: "Free University Brussels" },
    { id: "da8cfba3-db5e-4d73-a9a5-19fc5d847ee6", name: "Free University Netherlands" },
    { id: "0342de05-fc89-48cf-8d76-9f1a19e30426", name: "Free University of Bozen - Bolzano" },
    { id: "08cd2a76-df13-440e-b7d1-f27641aa5d22", name: "Free University of Tbilisi" },
    { id: "1a93e737-ab01-4a6a-8794-0b50cc66d23a", name: "Freed Hardeman University" },
    { id: "abe85d86-8ec5-4bc4-a9b8-e20ba5cc2c70", name: "Freehold Regional High School District" },
    { id: "579e6616-17db-49b0-9ae2-7f11400ca850", name: "Freie Universitat Berlin" },
    { id: "8b344473-f422-4bec-80ed-dd75255619fb", name: "Fremont College" },
    { id: "af448065-0be4-470b-86dd-d421da627cbe", name: "French - Vietnamese Center for Management Education" },
    { id: "6d3fd62b-071b-446e-ac37-5118e19aa8c8", name: "Fresno City College" },
    { id: "3061416a-23a1-48cd-841d-66dd7e8f2cba", name: "Fresno Pacific University" },
    { id: "c9aee888-1e80-46b2-8459-91d86b7b40ce", name: "Friedrich Schiller University, Jena" },
    { id: "2aedfd8c-8790-4299-984f-8ff2e1490492", name: "Friedrich-Alexander-Universitat Erlangen-Nurnberg (FAU)" },
    { id: "d04b2adf-22de-4c15-b742-4ea62aa0ee8b", name: "Friends University" },
    { id: "633b1826-cb54-4317-846e-3e02855a1065", name: "Friendship Public Charter School" },
    { id: "4007bcaf-ec84-41dd-943e-429852183199", name: "Front Range Community College" },
    { id: "e2021c94-ef3f-44de-aa47-e4f666f8f269", name: "Frontier Community College" },
    { id: "81c4f3bd-d526-47c7-9cef-a01fdf95ee19", name: "Frostburg State University" },
    { id: "b29bc6f2-edfd-4751-b240-16c84d5408c8", name: "FSC-Jacksonville Fulfillment Center" },
    { id: "894980c0-0f06-460f-a41d-9d008b74ddc3", name: "FSCJ-KENT Bookstore" },
    { id: "4dab4d85-7c9b-4a66-a98d-fb0ab6676314", name: "FT | IE Corporate Learning Alliance" },
    { id: "8fe59c76-1ae9-4136-bd2d-8e8e34ea75e4", name: "FTEC Faculdades" },
    { id: "c30791e9-5152-472a-96e9-6e837cdf018a", name: "Fu Jen Catholic University" },
    { id: "7babbf11-38f9-4347-a666-4aacae7ad87c", name: "FUCAM Bibliotheque" },
    { id: "519edce5-19d9-44b7-8927-621f5c6d0bdd", name: "FUCAPE Business School" },
    { id: "d10487e6-6ee4-41d5-8c72-54c324e939e9", name: "Fudan University" },
    { id: "361593ae-2e9a-4170-9e97-f2e44f971672", name: "Fudan University, Fanhai International School of Finance" },
    { id: "d46985bf-4e25-475c-9d8e-6cc6d0dd9627", name: "Fukuoka Jo Gakuin University" },
    { id: "1c8f2864-ad22-4b2d-b32b-797cf65a02b6", name: "Fukushima National College of Technology" },
    { id: "41e2bc89-ac8d-49d5-956e-ea280f98f17d", name: "Fulbright Economics Teaching Program" },
    { id: "57b1168e-0a46-4593-8505-7dfe6c43f036", name: "Full Sail University" },
    { id: "63be9c38-9e65-4963-8a86-e7e88baa8938", name: "Fuller Theological Seminary" },
    { id: "ac685c19-e61d-4eca-adb7-2fc856d56bab", name: "Fullerton College" },
    { id: "71a62052-a464-4dc1-8441-994a6cd1ea1a", name: "Fulton Montgomery Community College" },
    { id: "93cff15d-8a05-4d42-9a15-c40effe70854", name: "Fundacao Armando Alvares Penteado FAAP" },
    { id: "05970fb6-330e-4a70-89dd-0656e4003a2e", name: "FUNDACAO DOM CABRAL" },
    { id: "919d3af5-e5ac-4a4c-8049-79b293152940", name: "Fundacao Dom Cabral (FDC)" },
    { id: "b92350cc-ae97-4c49-9a19-1487cbe47665", name: "Fundacao Edson Queiroz" },
    { id: "577d0b55-d5af-4a9f-b346-1277dda39357", name: "Fundacao Getulio Vargas-FGV EBAPE" },
    { id: "69d200e6-4882-4f25-94c7-f4d4bde7cdf3", name: "Fundacao Joao Pinheiro Governo de Minas Gerais" },
    { id: "b9dd514a-e266-4d07-9f1f-96b7c41b6022", name: "Fundacao Oswaldo Cruz" },
    { id: "3289cf0b-2bac-4fbb-b259-cf09883580c1", name: "Fundacao Vanzolini" },
    { id: "9162434a-766c-4952-9122-9d2aced835eb", name: "Fundacio CIM" },
    { id: "17e38868-16d9-49eb-b44b-686e38173e1e", name: "Fundacion Arturo Rosenblueth" },
    { id: "14317515-59cd-4779-af04-5ae4080d48fe", name: "Fundacion Ciff" },
    { id: "2a4ff7fc-4834-4148-9f53-ce3c69dcfbd5", name: "Fundacion de Altos Estudios en Ciencias Comerciales" },
    { id: "b2e045f4-f4f3-4abb-9c4f-f162b0c1ab64", name: "Fundacion del Tucuman" },
    { id: "f97262d0-28fa-47f8-bec3-a4bdfa8fc2d4", name: "Fundacion Gutenberg" },
    { id: "f9c63370-240c-4f60-b4ab-be40d3ceaf61", name: "Fundacion Publica Andaluza Progreso y Salud" },
    { id: "1934047c-c847-4290-bfbb-a9d11e45c4eb", name: "Fundacion Univ De Las Americas" },
    { id: "7935bcf3-b31c-421b-836a-81c647817864", name: "Fundacion Universidad Empresa" },
    { id: "bf8df2fa-3584-478b-bda5-c0d5701cc0cb", name: "Fundacion Universitaria del Area Andina" },
    { id: "47d5f982-bc11-4484-8f6a-581851caa520", name: "Fundacion Universitaria Konrad Lorenz" },
    { id: "0ae35258-b6b6-4f14-b27f-d72fb28d68fa", name: "Fundacion Universitaria San Martin" },
    { id: "75d1c3e3-3430-49b1-b5c4-3f5e30ad3eaf", name: "Fundacion Valle del Lili" },
    { id: "1a082c81-46cc-48af-922f-d2fa3a3833bc", name: "Fundacion Zaragoza Logistics Center" },
    { id: "6f0b6ff1-abb1-4955-847f-550d1b96e30c", name: "Fundación Icil" },
    { id: "47451e56-65f2-4279-9c24-db2f93ae7b68", name: "Fundación Universitaria Cafam" },
    { id: "083186b1-e2d6-477f-aa07-7ce7f4cb47ce", name: "FUNDESEM Business School" },
    { id: "a71d24df-2019-46e6-af74-96d6602a1e3c", name: "Funitec La Salle" },
    { id: "75180cfb-532a-4b89-99b9-58104723d889", name: "Furman University" },
    { id: "d69c3b62-6144-43d9-9e7c-d529477ef619", name: "Fuzhou University" },
    { id: "1291c83a-25bd-4827-b40f-00dad9522f35", name: "FÃ¶rderverein Finanzwirtschaft und Banken an der UniversitÃ¤t Karlsruhe" },
    { id: "e2edf26e-1147-40c8-b93f-b008c7a9536d", name: "FÉDÉRATION FRANÇAISE DES TECHNICIENS ET SCIENTIFIQUES DU TOURISME" },
    { id: "34483024-f318-4eec-b634-042149ce6b72", name: "G. B. Pant University Of Agriculture & Technology" },
    { id: "419351c9-1b38-4fa0-b866-cb3153fcd7aa", name: "G.H. Raisoni Institute of Engineering and Technology" },
    { id: "15fddbe8-30f8-4842-9379-1104284368a6", name: "G.S.U. School of Education" },
    { id: "7db1f5e1-8d25-4a69-b60e-713fc84533ca", name: "Gachon University" },
    { id: "a53bcdfb-c016-4671-9b9c-fec2a56b9bb2", name: "Gadjah Mada University" },
    { id: "12cc10c1-cb49-4cde-989d-e11f34a90faa", name: "Gadsden State Community College" },
    { id: "7a7257e8-c7bc-4235-b68d-928920b2ac5e", name: "Gaeddu College of Business Studies" },
    { id: "55711b78-01b3-43b2-9db7-711dde039a4c", name: "Gainesville State College" },
    { id: "d02c54c5-32c7-464c-9451-72646db1891f", name: "Gakushuin University" },
    { id: "7c0d2255-ccad-4137-881a-dd3765172ffe", name: "Galatasaray University" },
    { id: "49c296c7-3875-4e70-a3bf-6b33150c45ef", name: "Galen College of Nursing" },
    { id: "c453e9c0-74dc-4cab-8900-1919f190b9e4", name: "Galen University" },
    { id: "aed9c869-a3bf-49ca-b349-fa395ecf78e4", name: "Galgotia University" },
    { id: "808d8219-75f9-43d5-8d96-eebb33fa0099", name: "Galgotias Business School" },
    { id: "af833815-99ec-48ec-bb64-63defff9d645", name: "Gallaudet University" },
    { id: "7d0c9321-5e2d-4801-beac-e033dbe157f5", name: "Galveston College" },
    { id: "23e73115-9037-407a-95e3-072a84e95874", name: "Galway-Mayo Institute of Technology" },
    { id: "71ffeab9-b73e-4738-82cb-d40599a871be", name: "Gannon University" },
    { id: "a9bcf637-0f0d-47e6-b179-f42a56bc2688", name: "Ganpat University" },
    { id: "6ceda5f3-02a8-4123-8df3-171ff1dd2f27", name: "Gansu Institute of Political Science and Law" },
    { id: "09c85ced-1e93-4485-879f-2a279762af2c", name: "Ganzheitliche Unternehmensentwicklung" },
    { id: "7c006a5d-033e-4a7e-93b3-626fbb818b67", name: "Gardner-Webb University" },
    { id: "c5b982b2-4063-4fa2-9126-eeda5c0b9783", name: "Garnet Valley High School" },
    { id: "83afd953-55c7-4e08-888f-bf02b0473a2c", name: "Garrett College" },
    { id: "a6057da2-526e-4af5-a29c-53455ff429b6", name: "Gaston College" },
    { id: "06c0bf36-c3e8-44bd-b318-649d673a9edc", name: "Gateway Community College" },
    { id: "2144093c-7096-4cfe-bc62-fc843ba7ae2a", name: "Gateway Technical College" },
    { id: "8a09b6bd-9b45-4ab2-9192-2d143fdc1c90", name: "Gautam Buddha University" },
    { id: "96e50571-f286-44d9-a53f-930a4b8e4b90", name: "Gazi University" },
    { id: "88991705-598f-41e0-8514-9eefc85cf343", name: "Gaziantep University" },
    { id: "e74f3c74-0043-439c-8ab9-a81b2da0088c", name: "GBSB Global Business School" },
    { id: "6b8babe6-ca0a-4967-9ff8-4862d1ddc43e", name: "GC University Faisalabad" },
    { id: "ddb2e648-d20f-40ee-a97f-3f21865ec838", name: "GC University Lahore" },
    { id: "366d2c78-f1d9-4225-ad8d-c8bd1254cb98", name: "GCU London Library" },
    { id: "b497fc4f-65f0-475a-ba76-98849eaef3f2", name: "GD Goenka World Institute" },
    { id: "2bb39a58-3716-4d05-a8a6-d966a4f00ecc", name: "Gdansk Foundation for Mgmt Dev" },
    { id: "e5b9e132-7471-4e5c-a1d5-1ee0e61ebc96", name: "Gdansk Management College" },
    { id: "dd6611cb-a182-4dc9-b789-fd29508fed17", name: "Gdansk School of Banking" },
    { id: "39e856ab-12d8-468b-b64e-2234f6be2603", name: "Gdansk University of Technology" },
    { id: "69be5169-9308-4f77-97eb-2d6774c6c893", name: "Gebze Institute of Technology" },
    { id: "b7228c5a-460a-4714-b040-ff316eaea021", name: "Gediz University" },
    { id: "5ba7eef6-4a8e-48cd-aebb-5407dcbde0af", name: "Gelisim Universitesi" },
    { id: "e801f8ae-ab17-4724-80ad-98a9cae3c470", name: "Gemological Inst of Amercia" },
    { id: "dc527956-3f5e-4521-a9c4-0521056604d2", name: "Gemological Inst of America" },
    { id: "22893c3b-4ba5-40cd-9390-d9142cb71da9", name: "Genesee Community College" },
    { id: "92fc2d85-0825-446b-aae5-f45f5b6a8965", name: "Geneva Business School" },
    { id: "13b1aa51-3bd7-4fa4-a880-3e80d3a353f7", name: "Geneva Centre for Security Policy" },
    { id: "6f6e692f-024e-4115-b949-fafefa4a7a9d", name: "Geneva College" },
    { id: "8f550fd1-94b8-47c2-9114-837ff35adeeb", name: "Geneva High School" },
    { id: "befb1737-4aec-4b08-90bb-77118498ff8c", name: "Geneva School of Business Administration" },
    { id: "62c20ca1-fac6-4987-b6e0-4b80b6625bbc", name: "Geneva School of Dipolmacy and International Relations" },
    { id: "58c24c98-0e5b-4e3d-a7e1-08f78b940e5b", name: "Geneva School of Economics and Management" },
    { id: "a8d6ae1e-5128-46d4-a09f-a41a1d517b05", name: "Georg Simon Ohm Management Institute" },
    { id: "4b9304d4-272b-4f07-8807-e26ecba49424", name: "Georg-August-Universität Göttingen" },
    { id: "c6b10a46-8b33-4db0-9d4c-46be092d497f", name: "George Bacovia University" },
    { id: "c4563f77-58a9-409c-a2a4-e372fbd22cba", name: "George Brown College" },
    { id: "35b6e938-aaa7-42a1-84ae-0d8291242017", name: "George C. Marshall European Center for Security Studies" },
    { id: "8f60410b-6bbf-45e0-bf9c-caf80a04a868", name: "George Fox Seminary" },
    { id: "231602bf-33ed-4c28-8d56-f5fa8914047a", name: "George Fox University" },
    { id: "cf0eebea-4073-4594-b543-649da9134e1b", name: "George Mason University" },
    { id: "601c9940-bd90-425b-bee8-bec736fe4464", name: "George Northwestern Technical College" },
    { id: "a701195f-25a2-43ce-91e7-7091be0b130c", name: "George W. Bush Presidential Center" },
    { id: "dfa6e3c2-0827-407a-99d9-0175a059996b", name: "George Washington University" },
    { id: "535cc7c9-78af-4c6e-bbeb-c248e6b4912b", name: "Georges River Grammar GRG" },
    { id: "47287667-7fc9-4d48-87f7-a5e704a17e08", name: "Georgetown College" },
    { id: "c54211a2-66b6-4c2f-9a74-3e538427d200", name: "Georgetown Day School" },
    { id: "a52f6f44-a14e-4e42-b731-35b38a638d7a", name: "Georgetown University" },
    { id: "95ade913-f49e-4426-9bf9-4201675f121a", name: "Georgetown University - Center for Language Education and Development" },
    { id: "58b698a0-ee3a-499e-aba2-95b97cb07c18", name: "Georgia College and State University" },
    { id: "5794ef76-d203-40d2-bab8-f4ff805a153f", name: "Georgia Gwinnett College" },
    { id: "898432fc-865e-4636-a9ec-75009af46b25", name: "Georgia Highlands College" },
    { id: "248ccae7-9efc-4a27-a26d-0a7bdf646c54", name: "Georgia Institute of Technology" },
    { id: "0ee5e08f-f25f-4dd1-b996-e0e20c576b49", name: "Georgia Northwestern Technical College" },
    { id: "5f7ab539-8657-4a22-b6a3-f64fd33bbdf4", name: "Georgia Perimeter College" },
    { id: "e78e2a89-0d25-40d3-bc4d-8b87ce446f37", name: "Georgia Regents University" },
    { id: "089c195d-e10d-4eaa-bdad-2257603df6f6", name: "Georgia Southern University" },
    { id: "e210af73-8533-4c7f-bdc1-1c56c394cd5f", name: "Georgia Southwestern State University" },
    { id: "04663aef-9037-4bcc-a079-7598ed70525a", name: "Georgia State University" },
    { id: "7f366307-2d8e-4800-b8ee-83975f6ea99a", name: "Georgian American University" },
    { id: "a9f94f9c-d1be-4f9c-9b3f-3a792ea71044", name: "Georgian College" },
    { id: "45dfdc49-8147-4656-960c-42f29fa3379c", name: "Georgian Court College" },
    { id: "510cbced-0ee5-495f-b45d-31e9447de7b9", name: "Georgian Court University" },
    { id: "cacd2a58-4e2b-4a57-8b0d-c62ee040876d", name: "Georgian Institute of Public Affairs" },
    { id: "e86e010e-7f44-4679-b133-559ddb7ad1d7", name: "Georgian Technical University" },
    { id: "950e6303-a4f3-4854-9db6-51153ce7d0f4", name: "GeorgiaTech Research Institute" },
    { id: "df602c37-8eb6-4110-87e5-ea119ad58d88", name: "German Graduate School of Management and Law (GGS) GmbH" },
    { id: "dc4cf182-9b7a-4dd2-85e1-48e7f0326498", name: "German Jordanian University" },
    { id: "6e2466a2-13b6-4d56-9ce2-8352ec5e1b50", name: "German University in Cairo" },
    { id: "34b4d38b-aaee-4c18-bd1b-6fe4b4688207", name: "German University of Technology in Oman" },
    { id: "a070544f-493a-4e85-b78a-c827c478561d", name: "Geschäftsstelle des Wissenschaftsrates" },
    { id: "d9365bd7-0230-4605-8edf-77a8d91c2cc5", name: "Gestalt Community Schools" },
    { id: "6166f2b6-7068-45ae-a2ae-e25903bae41d", name: "Getty Leadership Institute" },
    { id: "659ff973-a9b8-470d-80a0-48c91acaae8e", name: "Gettysburg College" },
    { id: "fe7f5ca5-a0cf-4ee0-95ea-e7e6e33f1b6f", name: "GFK Academy" },
    { id: "9c46c8fe-3d17-414a-9102-54e71dc8fbc3", name: "Ghent University" },
    { id: "fde40513-7f69-488c-90d1-5a71d2d23cd9", name: "Gheorghe Asachi Technical University of Iasi" },
    { id: "7e0ef285-925a-42f8-b51f-1d138c5ffb72", name: "Ghulam Ishaq Khan Institute of Engineering Sciences and Technology" },
    { id: "184b2f4b-1aac-42f0-9b49-d2823a73ca67", name: "Gian Jyoti Institute of Management and Technology" },
    { id: "a8b09209-c0fb-418f-adad-328c8f689614", name: "Gibbs College Library" },
    { id: "c3585362-2bb2-4017-92f0-73050471e085", name: "Gidc Rofel Institute" },
    { id: "6149dd0b-1a0f-4923-a476-e88b52433980", name: "GIFT University" },
    { id: "eb9f2be2-6ca6-4ea6-a4d3-7207dbe14f57", name: "GIHE - Glion Inst of Higher Education" },
    { id: "d61fbf16-d26a-4ccf-ba57-6c230bb47158", name: "GIHE - Glion Institute of Higher Educ" },
    { id: "2ded7dc0-aa56-47ad-a804-cb4c94e6d91e", name: "GIHE - Glion Institute of Higher Education" },
    { id: "77910548-c70f-4a58-912b-bdc156b271ba", name: "Giovanni Gavetti" },
    { id: "6f8df70e-9c88-4313-a4e4-cd19ff68d968", name: "Girne American University" },
    { id: "7b473ed3-acde-4a50-a3f1-f33777432143", name: "GISMA Business School" },
    { id: "4ad2462a-908d-43d9-b37c-8cc00aed6132", name: "GIST International College" },
    { id: "e43ee6ad-bf56-4c71-bc06-ab7fbf93837a", name: "GIT School of Public Policy" },
    { id: "2c2e9971-85e2-45a5-a2ca-9d645e6b9ce3", name: "Gitam University" },
    { id: "00059d54-3cf7-4d4c-9926-91941e497bd4", name: "GL Bajaj Institute of Management & Research (GLBIMR)" },
    { id: "d87a59a7-dd9f-4a8f-a61b-1b85e3bf9883", name: "GLA University" },
    { id: "efa87aaf-b3a4-40ff-a7d8-ca1a8d89c6c3", name: "Glasgow Caledonian New York College" },
    { id: "f5e9771a-0c20-4d4c-9ea9-80907bce797b", name: "Glasgow Caledonian University" },
    { id: "bf36c1c0-1f5a-4f5f-97ef-35aa552fb482", name: "Glasgow Kelvin College" },
    { id: "c38510e7-8c80-4413-892c-f9cbc2cc0f48", name: "Glasgow Metropolitan College" },
    { id: "278e729b-9f50-4bdd-87b7-83fee865f841", name: "Glendale Community College" },
    { id: "eef22f1e-a436-41f6-abab-7bcd6ac8c2e1", name: "Glenville State University" },
    { id: "3d0db57f-756c-438e-ac91-70d638306d1a", name: "Glion Hotel School" },
    { id: "f0e513e3-2aab-4fdd-b523-ea4b19cc4393", name: "Global Academy of Technology" },
    { id: "bb42a6ff-9f3d-480e-beae-c6b6f20d7700", name: "Global Accountancy College" },
    { id: "f1a3230c-e11e-49ae-95dd-301f5de319e3", name: "Global Business School" },
    { id: "fbc99581-3f2d-43ab-9181-3d46ed740d36", name: "Global College International" },
    { id: "7c4774fe-9d34-49d4-9cbb-5e893115713e", name: "Global College London" },
    { id: "63e1e716-4adb-4b97-8af6-3afc886a9798", name: "Global Humanistic University" },
    { id: "d303ae3f-5fa9-48e1-a644-9e4bc092c5c9", name: "Global Institute of Business Studies (GIBS)" },
    { id: "f4f0ef1d-8f5e-4db9-8f98-dbd027216a99", name: "Global Institutes Amritsar" },
    { id: "8d5b6a4a-2fbc-4406-8e3a-3522f4e11e20", name: "Global Leadership University" },
    { id: "adb42e14-3761-46cb-8969-2679485df98c", name: "Global Linkage Corporation" },
    { id: "ee5f250c-4615-475b-a9d4-b81ea14004bb", name: "Global School of Management (GSM)" },
    { id: "1dfe29fb-a436-49c5-9d65-b8acb5b2c3df", name: "GlobalNxt University" },
    { id: "65fdc755-9046-4ff5-b389-2cc3b63f0e30", name: "Globe University" },
    { id: "72c13291-6d69-4489-afd7-e70c0190e4b6", name: "Globis" },
    { id: "a27b8ead-4c64-44bb-90aa-a5c57d552e1a", name: "GLOBIS Asia Campus Pte. Ltd." },
    { id: "0fd953bb-9a24-41f5-a4e0-460acc83cdee", name: "GLOBIS China Co." },
    { id: "5abce042-8e95-4aea-9e1b-8401671a1229", name: "GLOBIS CORPORATION" },
    { id: "2d87c5f3-8c20-4ee9-b54b-3ef0a07470fa", name: "GLOBIS Europe BV" },
    { id: "d6d95a80-df1f-4200-89cc-00b590327b2e", name: "GLOBIS USA" },
    { id: "e64df534-2093-48ac-9a1e-5ce58ef1b146", name: "Globsyn Business School" },
    { id: "5ad3db5d-d55d-403b-8b0f-865515d481ec", name: "Glyndwr University" },
    { id: "b89974c5-e20f-4fb0-b725-f62215fc0c8c", name: "GMU School of Education" },
    { id: "bd6f1103-7145-4f21-85de-e7ae2045b3e5", name: "GNA University" },
    { id: "9c6ec707-e509-4529-b0b5-a73a957ace73", name: "Gnoman Copy" },
    { id: "ccae4c52-cf0b-4306-a759-6178d14af060", name: "Goa Institute of Management" },
    { id: "a78fee65-d208-4951-97f2-80c9e9459d05", name: "God's Bible School and College" },
    { id: "c3ec7200-5b70-4e84-a903-689e29ffb977", name: "Goe Business School" },
    { id: "c4a41c87-d04c-4078-8949-95d9dea11b76", name: "Goethe Business School gGmbH" },
    { id: "ff52b2ff-0f9b-4459-aaa3-afba08ad0a11", name: "Goethe Universitat Frankfurt Am Main" },
    { id: "36b4ae26-1bf7-45c4-8e6b-2265a625ec4e", name: "Gogebic Community College" },
    { id: "ec8fbed2-4648-4ba9-824c-ea0313fcd70d", name: "Gokhale Institute of Politics and Economics" },
    { id: "14da1cf0-2428-4a90-bea6-81796f101f5b", name: "Golden Gate University" },
    { id: "d78e4df2-1116-4d07-bf39-9140ec1eaca9", name: "Goldey Beacom College Inc" },
    { id: "d72a6c10-1c75-4d33-9cec-2647b633e6e8", name: "Goldsmiths University London" },
    { id: "ef07f3f9-d188-45f8-bf01-5bc1f710ce15", name: "Golf Academy of America" },
    { id: "6c72a20d-cc29-4c5c-a7ab-bf6c35509ea1", name: "Golf Core Education" },
    { id: "9507df6a-9938-455d-bc54-bc01c9c9a36a", name: "Gonzaga University" },
    { id: "e95bfa6b-caa9-4101-9237-fdec3ff4963b", name: "Goodwin University" },
    { id: "6d4e9b57-bf34-42bc-bdc2-4a6f7a73dbe9", name: "Gorakhpur University" },
    { id: "a09faad9-3bab-4141-913d-8f379ffd510b", name: "Gordon College" },
    { id: "08773ec4-3082-42fd-a7fd-5dcc811db9af", name: "Gordon Institute of Business Science" },
    { id: "af5c1902-04b4-4836-8141-dfda80d14f81", name: "Gordon-Conwell Theological Seminary" },
    { id: "121263f4-30aa-4b96-9989-02e83e092601", name: "Goshen College" },
    { id: "4ad146f7-1b91-494f-8c3f-402d514e7e06", name: "Goteborgs University" },
    { id: "e17d3229-c311-4649-8a62-627d68966026", name: "Gotland University" },
    { id: "89c0206c-544c-4a41-922e-3f97923681e2", name: "Gotomedia LLC" },
    { id: "d37ed33b-cca5-48be-9f16-bfc7536844f1", name: "Goucher College" },
    { id: "794dbe7c-6953-43e9-87ee-2da87f024190", name: "Government College University Faisalabad" },
    { id: "ee20e856-d43f-4e6b-b087-4cac60262d09", name: "Governors State University" },
    { id: "139505b5-5945-4d3c-b31b-f7ce622a7bfa", name: "Gower College Swansea" },
    { id: "87301226-7b38-4362-970e-24406ac225e3", name: "GPRC" },
    { id: "5dd549ea-fd4e-4964-b0eb-19a0975ccd8b", name: "Grace College" },
    { id: "d06b38e4-130c-4037-83bd-c07872111083", name: "Grace Evangelical College and Seminary" },
    { id: "2f87bd97-ad88-4154-8b7c-3f1801233145", name: "Grace University" },
    { id: "233e234a-5288-4b68-afca-2c4616f1c138", name: "Graceland University Independence Campus" },
    { id: "f2a91423-afa7-4459-bf83-eefc92f57333", name: "Graceland University â€“ Lamoni Campus" },
    { id: "1351712f-c01a-4644-8e83-1eda970f2d14", name: "Graduate Inst of Intl Studies Geneva" },
    { id: "66fa239a-cf23-4704-9a0e-79c0292f11d1", name: "Graduate Institute of International and Development Studies" },
    { id: "ba469570-9173-4924-9a3d-c292905f3443", name: "Graduate School" },
    { id: "3f346cb5-d1f8-401a-98d1-eda190878a68", name: "Graduate School of Bank" },
    { id: "2a78a73e-f9f3-4810-87a8-17c6ecb27c31", name: "Graduate School of Business Administration - India" },
    { id: "5efaa914-c8d0-45b3-8840-2672f7e97573", name: "Graduate School of Business Administration Lomonosov Moscow State University" },
    { id: "817e0baf-faef-4130-8504-11978fd17195", name: "Graduate School of Business Leadership, UNISA" },
    { id: "7bcd9261-3226-44ed-a706-eea351aa3d46", name: "Graduate School of Management Abidjan" },
    { id: "e2e9b48b-5dee-477e-8464-51fe5c39423f", name: "Graduate School Ostwurttemberg" },
    { id: "4dc5c0f5-e6a6-4a04-8e47-c1f217fb730e", name: "Graduate School Ostwürttemberg" },
    { id: "5cb4c8dd-a22f-41db-b160-dad0cea13c5b", name: "Grafton University College - Islamabad" },
    { id: "d903df08-2e1c-4bb0-bcdd-0dfe5548bc27", name: "Grand Canyon University" },
    { id: "6327c4ca-477d-4301-92c7-485354768167", name: "Grand Rapids Community College" },
    { id: "ab6bf5cf-2356-48b4-9bb9-8b9000f09663", name: "Grand Valley State University" },
    { id: "e719b3c1-ed00-4dfe-b23f-db81342e67d5", name: "Grand View University" },
    { id: "c76446f7-5ca0-4c6e-978e-69e62a9348f0", name: "GRANDE PRAIRIE REGIONAL COLLEGE" },
    { id: "a022c28d-a5f9-493a-8e43-dc860978032f", name: "Grant Wood Area Education Agency" },
    { id: "f06626c2-4074-4387-843b-285965e25ce5", name: "Graphic Creations Inc." },
    { id: "e789d2d6-4887-432f-8eb2-2f4c25ba8c94", name: "Gratz College" },
    { id: "6e7d9e9c-9654-4066-abf1-553838dfc809", name: "Grays Harbor College" },
    { id: "8f0f6a17-ec70-4f98-93af-727d2271fd7f", name: "Graz University of Technology" },
    { id: "5857f4c4-3bc9-4af9-a0f9-49a567e97fbf", name: "Great Basin College" },
    { id: "ccea4ece-b6c5-4f75-b085-4dbb4ab106d5", name: "GREAT India B School" },
    { id: "c96310b6-8443-4895-82b8-8e30df4393ee", name: "Great Lakes Institute of Management (GLIM)" },
    { id: "1dad4bb5-ca23-4110-9576-def73b00ddc5", name: "Great Lakes Institute of Management - Gurgaon" },
    { id: "6d3b6b02-5a84-4b16-ba92-ede65ffff07b", name: "Greater New Bedford Reg Voc Tec HS" },
    { id: "57d96620-89ff-46ed-a3f6-37816ef357ad", name: "Green Mountain College" },
    { id: "dc41461a-67ad-4ac5-85d8-196ba68c4385", name: "Green Pearl Education Management Corporation Private Limited (GPEMC)" },
    { id: "bdbe9bea-08f6-463a-89f8-7a5d09d7b3f6", name: "Green River Community College" },
    { id: "c7330372-6954-41ec-89bc-59be537d1334", name: "Green Templeton College" },
    { id: "04bdc0d1-c91a-487a-905b-f2db7c1fba5f", name: "Green University of Bangladesh" },
    { id: "011e0167-36ac-45dd-8262-1fffd833375e", name: "Greene County Extension" },
    { id: "54e842c2-2ac2-431e-8a01-fba46b439f3a", name: "Greenfield Community College" },
    { id: "0ea2faed-6774-401f-9cca-802dc9663aae", name: "Greensboro College" },
    { id: "73dd0ab3-9456-4848-b51b-026cb252893f", name: "Greensboro Day School" },
    { id: "99ba8370-c0d7-482f-b760-9bb7a7dd4c20", name: "Greenville College" },
    { id: "053bf0d5-f7d6-444e-9cd0-b6e3315348c7", name: "Greenville Technical College" },
    { id: "da42ad56-f766-4e47-a4fb-3e206d45abf9", name: "Greenwich Academy" },
    { id: "084b496b-d19f-4199-9038-adde80d87544", name: "Greenwich School of Management" },
    { id: "8da8e912-95e0-474a-ba1c-48c88526cdd8", name: "Greenwich University" },
    { id: "b0ad83d0-fae8-406c-8855-ae8d753a28fb", name: "Greenwood College School" },
    { id: "4a10e334-1f78-435f-b50a-5fee7e8d4e7a", name: "Gremkay International" },
    { id: "310299b7-64ea-41b8-b52b-d3daf8f3dfab", name: "Gren Communication Consulting Sp" },
    { id: "c826515e-fdcb-4256-9456-98e67b8eaee5", name: "Grenoble Ecole De Management" },
    { id: "821e3d39-c9b1-415e-bb17-d4b41a28de5c", name: "Grenoble Institute of Technology" },
    { id: "9d16ea69-d294-4d45-8b05-2d9bac67d953", name: "GRG School of Management Studies (GRGSMS)" },
    { id: "01d21bae-28f2-4dfd-9cdb-aad2d35204ce", name: "Griffith College Dublin" },
    { id: "8111919c-65b3-4a92-bb51-4d781a8adb62", name: "Griffith University" },
    { id: "b9b15cfe-7fea-437b-9549-d5010b6c9591", name: "Grinnell College" },
    { id: "429fc0a9-7c81-4782-98df-5cefd4de47bf", name: "Groep T - International University College Leuven" },
    { id: "88299014-d98c-4b69-8cd5-1e52067e18c2", name: "Grossmont College" },
    { id: "fbd5568a-cb79-45cd-94b3-0e6756afecb5", name: "Groupe Esa Paris" },
    { id: "c4724a2a-0404-4115-bb7f-566ea7446860", name: "Groupe Esc Bretagne Brest" },
    { id: "074b5b36-9c6b-4578-92d9-7c7827c620a8", name: "Groupe ESC Compiegne" },
    { id: "422c45f1-36b2-467c-a411-eb55ebbe96b7", name: "Groupe ESC Dijon Bourgogne" },
    { id: "9de9fb10-8c2f-4784-a69e-72baf91e86c5", name: "Groupe ESC Pau" },
    { id: "acf62b98-c099-48be-83e8-609ce969cc27", name: "Groupe ESC Saint Etienne" },
    { id: "9195efa2-c08a-47d2-9d81-b4de9f16b5fa", name: "Groupe ESC Toulouse" },
    { id: "14a480e8-26b8-4cb9-a445-22b7e2f27ffb", name: "Groupe ESC Troyes" },
    { id: "202636d1-e469-4008-a288-803e34b14888", name: "Groupe ESIEE" },
    { id: "55a7274f-2a5d-49d2-aac7-14825991707d", name: "Groupe ESSCA" },
    { id: "ca591806-d835-42cf-8e00-5bf9190ec5da", name: "Groupe Euromed Management" },
    { id: "d7f515e8-46df-4996-9d3c-386db4299ce0", name: "Groupe INSEEC London" },
    { id: "e36ae423-55ef-4a6d-ae7c-1053b91277f1", name: "Groupe ISCAE" },
    { id: "a79eb84d-02f1-48a8-b389-76768b2d4a8f", name: "Groupe Sup De Co La Rochelle" },
    { id: "d78f18c7-0e85-48ab-bea0-0e126b944d5b", name: "Grove City College" },
    { id: "a45dfc62-051f-4c2c-8211-563f9f5830cc", name: "Grow Talent Company" },
    { id: "d2c87fdd-2567-420a-9107-3463d823fee8", name: "Grupo Esneca Formación" },
    { id: "a06a35b8-1adc-4bab-9b5a-061a403ac474", name: "Grupo USJ" },
    { id: "1b19ccf0-ee9c-4e6f-abd0-9b972a46aaae", name: "GS Fame Institute of Business" },
    { id: "804ed2fb-544f-44e6-b820-80bd43c5c60f", name: "GSM Barcelona" },
    { id: "fa101016-a118-40e2-a7c6-a64b62690561", name: "GSM London" },
    { id: "89164869-e11a-4f6a-b3fd-fc61eccef165", name: "GTT Connect" },
    { id: "7e9653eb-4afb-405b-98fb-8ec054b72a57", name: "Guagua National Colleges" },
    { id: "ec90303e-fd82-4bf0-8c9a-38e48fb082fd", name: "Guam Community College" },
    { id: "6a27a203-109a-4b40-b3e5-4ee99851a97c", name: "Guangdong Polytechnic Normal University" },
    { id: "a15c2d98-a2e9-4509-95b0-25be2b810168", name: "Guangdong Technion - Israel Institute of Technology" },
    { id: "ec35aea3-547a-4f45-8614-3094efcbd9a5", name: "Guangdong University of Foreign Studies" },
    { id: "380e2e64-a4ba-45bd-a307-7dd091c0fba8", name: "Guangdong University of Technology" },
    { id: "d7bef33c-156d-492c-a2e8-663484441945", name: "Guildford College of Further & Higher Education" },
    { id: "44c5dd1a-6ec4-4633-9c29-ac01498cfec9", name: "Guilford College" },
    { id: "d2cd974c-d05c-458a-8c5c-1a5be60aea74", name: "Guilford Technical Community College" },
    { id: "66d1cd8e-72d7-4671-941b-f96fe6477d91", name: "Guizhou University" },
    { id: "b990cec1-6270-49bf-9a26-ee374d014d55", name: "Gujarat National Law University" },
    { id: "e9cbeaa7-7b9f-4734-8f35-02ffb4ec5b09", name: "Gujarat Technological University" },
    { id: "06139dd6-5729-4292-ae3c-69aae093ea75", name: "Gujarat University" },
    { id: "45c66498-7709-4356-af02-42efa4520f12", name: "Gulf Coast State College" },
    { id: "8c783657-e9b3-4f52-a3a1-31698d2b0b23", name: "Gulf University for Science and Technology" },
    { id: "758b1011-0ef8-42a2-bc60-0ec01b0c6e22", name: "Gumushane University" },
    { id: "d3a74d6d-346a-4539-89c1-2660f2d71e83", name: "Gunadarma University" },
    { id: "a52cd8a4-3ea7-4a41-9fb3-7e895660daf9", name: "Gupta College of Management & Technology" },
    { id: "bd82dde7-ee21-48ce-b92f-2925f5f26504", name: "Guru Gobind Singh Indraprastha University" },
    { id: "1d0690b7-c72f-420a-af5e-649a7d200b79", name: "Guru Nanak Dev University College" },
    { id: "c0a99b74-15c9-48a9-be0e-7bb30d20b632", name: "Guru Nanak Institute of Management Studies" },
    { id: "3e800987-00b0-4531-9c04-976a44a73c5f", name: "Gurukul Kangri University" },
    { id: "765830e7-4d3d-4ed3-91c0-53b0ffed1009", name: "Gustavus Adolphus College" },
    { id: "c93fba1e-bf47-4667-b0c9-df949990f10d", name: "Guwahati University" },
    { id: "8918a3b4-e8dc-4090-acd3-8271f0422621", name: "Gwynedd-Mercy College" },
    { id: "8c2b89f1-a217-4896-b946-01155e56f7ec", name: "Gymnasium Kloster Disentis" },
    { id: "42c62eb6-53a3-42f2-935e-c51104bc7589", name: "H Kust Library" },
    { id: "98e1d154-712d-4fca-aa32-c68269a872ed", name: "HAAGA HELIA University of Applied Sciences" },
    { id: "7f404fac-cb36-4260-8f4a-6a373ad333ea", name: "Haas School of Business" },
    { id: "ddbcc41d-4321-4bf8-844c-5b3bfa953553", name: "Hacettepe University" },
    { id: "2bc4ad08-ef05-497b-b77e-259e0ed768f1", name: "Hackett Labs" },
    { id: "b5ada81d-fdad-43f5-a8bf-1765b5afff2e", name: "HafenCity University" },
    { id: "74a5f2c3-c7ad-473f-af42-df1f6cd048d0", name: "HafenCity Universität" },
    { id: "c7abca75-c4aa-4a94-819c-45eec8b2495c", name: "Hagerstown Community College" },
    { id: "72d9cf18-ee59-4ea3-82d4-f293bc92df28", name: "Haigazian University" },
    { id: "ed8707df-412d-43a8-b340-256f95f1dfcb", name: "Hailey College of Commerce" },
    { id: "13f9621c-be19-4f93-a937-ff0c2ab591b4", name: "Hakkari Üniversitesi" },
    { id: "a6e2b822-e873-4949-bf26-801933e6179e", name: "Hakuoh University" },
    { id: "dbd8dc7f-8754-4b4d-bab2-6728440a3897", name: "Halic Universitesi" },
    { id: "5d0f7348-3993-408c-98e6-92d0f507c668", name: "Halifax Community College" },
    { id: "ff24e106-62e8-49cd-b947-bc63495b784a", name: "Hall of Fame Book Trader" },
    { id: "7e57f4bc-b92c-4455-acfb-08b6b70a0927", name: "Hallym University" },
    { id: "dcd203d1-7ca1-49de-a87a-6003538a7992", name: "Halmstad University" },
    { id: "0fca548f-4ad4-4812-9abf-399bd5779b38", name: "Hamad Bin Khalifa University" },
    { id: "4f18863b-5d69-4184-be1d-9d0bba64f142", name: "Hamburg Media School" },
    { id: "e6a5a3d6-6798-4292-8be3-40ec7af495e8", name: "Hamburg School of Business Adminstration (HSBA)" },
    { id: "499591c7-410e-4b42-9906-aecd2205b685", name: "Hamburg School of Logistics" },
    { id: "bc1c1daa-7996-4df2-a5e2-4cba51b0dfc6", name: "Hamburg University of Technology (TUHH)" },
    { id: "ef3251f9-61bb-43be-acc9-decf619e3d42", name: "Hamdan Bin Mohammed e-University" },
    { id: "f78f1a6b-a598-48a1-b12e-1481a6f8744d", name: "Hamdard University" },
    { id: "ad6d8acc-ec3c-4593-a471-b19270bc6696", name: "Hamilton College" },
    { id: "fb8611a5-d575-43fa-b36f-450e6e1fcd24", name: "Hamline University" },
    { id: "ee32ceb2-3b80-41c5-bf71-772c8057e97a", name: "Hampden Sydney College" },
    { id: "0fc1ad05-18a8-403f-9544-7110b361ccb7", name: "Hampshire College" },
    { id: "540acc49-3f9b-4f4f-8ce5-251e51879407", name: "Hampton University" },
    { id: "7033e4f2-1d8b-4458-b124-5033ee7fed5a", name: "Han University - Arnhem" },
    { id: "a3090965-d5e7-46d1-b2b1-db787f827049", name: "HAN University of Applied Sciences" },
    { id: "13b0fb63-7fcb-416d-8f19-d0cd87559e48", name: "Handelsakademie Lambach des Schulvereines am Benediktinerstift Lambach" },
    { id: "5f51a95a-b10a-444b-abeb-10b61add14a0", name: "Handelsskolen KNord" },
    { id: "089c631b-2813-42bf-9abf-4ce599bedace", name: "Handong Global University" },
    { id: "d6c20076-b8c6-4acc-a10e-747f0a01c49b", name: "Hang Seng School of Commerce" },
    { id: "189448c9-f322-41b1-9628-7e596d95e1ec", name: "Hangzhou Dianzi University" },
    { id: "d42cdb72-46aa-418e-a941-d2aa3405163f", name: "Hanken School of Economics" },
    { id: "9264f3ab-7ae6-470c-8305-2e69fe8bfb50", name: "Hankuk University of Foreign Studies" },
    { id: "b8d5397d-1a2f-41df-8892-2c48fccdd613", name: "Hannam University" },
    { id: "aae9cef7-2dbd-47fc-9088-cb37abc55db5", name: "Hannan University" },
    { id: "92594954-0295-4a50-850c-960664cb70c8", name: "Hannibal Legrange College" },
    { id: "e2fb59d2-a036-4f9d-bb7d-611adb8c60b9", name: "Hannover Medical School" },
    { id: "994968aa-fa6b-4a9d-be7a-d998370ce812", name: "Hanoi University of Science and Technology" },
    { id: "4b79eb74-f105-4084-8acd-7ce1377d34fe", name: "Hanover College" },
    { id: "405b4cd5-dd63-40a9-85bb-f213313467a4", name: "Hanshin University" },
    { id: "b8470fc8-8eaf-4b41-8788-1ff65608c4bc", name: "Hansung University" },
    { id: "0a39e142-958d-489f-9a86-eb32bf4acb5c", name: "Hanyang University" },
    { id: "c60258d6-ac8c-4fe1-884c-776ee7ac5fb4", name: "Hanze University for Applied Sciences" },
    { id: "b5ccd994-12c9-4929-bf99-14b17f95771d", name: "Hanzehogeschool Groningen" },
    { id: "fdb2532b-b86e-4f29-8d32-ed6500532c4c", name: "Happy Valley Business School" },
    { id: "f617f8ce-b9ed-4b41-b642-dc022fd93482", name: "Harappa Learning Pvt Ltd" },
    { id: "e25afbf6-45df-4452-b3b9-e603db12a1d1", name: "Harbin Engineering University" },
    { id: "58a0a3cc-1f9c-45ad-a523-531252f536cb", name: "Harbin Institute of Technology" },
    { id: "ec36971d-8cb0-4703-a933-7747664a0aec", name: "Hardin-Simmons University" },
    { id: "f6aa5bef-0a73-4e77-9ab5-8a38e7392aad", name: "Harding University" },
    { id: "3bdb9c97-4f5e-4bb4-b632-07e366a29d8b", name: "Harford Community College" },
    { id: "0fec28c8-c218-41c2-a38b-6ff45421be11", name: "Harold Washington College" },
    { id: "f529bfee-4760-4968-9c3d-f316a8f7dbf7", name: "Harper Adams University College" },
    { id: "6248d424-d608-4e71-9c11-7fd0a6489d85", name: "Harper College" },
    { id: "0f25720e-943e-4d24-b795-56817eb9a2fa", name: "Harris Stowe State University" },
    { id: "95b48f7b-d6ed-4b8b-9d1c-e8b5ee180b65", name: "Harrisburg Area Community College" },
    { id: "12a28e2a-4e03-4062-85e0-87278de2cc67", name: "Harrisburg University" },
    { id: "8c609fd0-a4bf-470e-a70d-1e1d60c37c96", name: "Harrisburg University of Science and Technology" },
    { id: "d004c851-3894-4350-976d-b1de7785f60d", name: "Harrison College" },
    { id: "085552df-16f1-46b0-910a-6e1bf9e4a942", name: "Hartford Community College" },
    { id: "66165595-8d6d-46c5-86ca-85096a39e5c0", name: "Hartwick College" },
    { id: "d11b239d-f7ac-4e11-8a83-1468602e1f34", name: "Harvard Administrative Innovation Group" },
    { id: "1d072184-fcc6-4307-abd5-bf943a8ffc56", name: "Harvard Business Publishing" },
    { id: "b5951235-0bcb-47f0-9bf2-5a65918ab958", name: "Harvard Business Sch APAC Res Ctr" },
    { id: "e18616cc-2387-42eb-92d1-5c9202eda924", name: "Harvard Business School" },
    { id: "e1d9c913-f7d6-46de-a09f-3f0e0049b1ce", name: "Harvard Business School Association of Southern California" },
    { id: "0d011566-19b9-4808-bf90-b341af0b3ea5", name: "Harvard Business School Executive Education" },
    { id: "ca59c1b4-611b-4c56-86c1-52086b5e77ec", name: "Harvard Catalyst" },
    { id: "cd0411bd-9639-44aa-90e3-ba83b0467401", name: "Harvard Center Shanghai" },
    { id: "7cd13103-1570-4112-8f40-dc866b9b96a8", name: "Harvard China Fund" },
    { id: "db1a798b-23a1-45bc-b848-4de857c5ae5b", name: "Harvard Continuing Education" },
    { id: "a7eda4fe-0336-4e05-8c13-d80c9bdc631a", name: "Harvard Divinity School" },
    { id: "91cfa5ad-bf43-4784-80be-fce379a8214d", name: "Harvard Division Of Continuing Education" },
    { id: "592da36d-a197-43ab-884a-80e23492a9dd", name: "Harvard Education Publishing Group" },
    { id: "0f60949c-6b4e-45c3-a113-bee6f65b516e", name: "Harvard Extension" },
    { id: "ae5c4c8a-15b3-4cef-9a2e-bccb46b60990", name: "Harvard Extension School" },
    { id: "353b2eac-3ebe-475f-9aaf-b56619bc8d38", name: "Harvard Faculty of Arts and Sciences" },
    { id: "48c0a9af-a33e-4b9f-bca2-b617008d401c", name: "Harvard Graduate School of Design" },
    { id: "680c6063-8930-4e24-8489-70af37ded3d7", name: "Harvard Graduate School of Education" },
    { id: "3ea09431-a93a-4ad4-be44-3738336069a6", name: "Harvard GSAS Business Club" },
    { id: "7b2ee030-d0ee-47a9-8bc5-48b2b4691a91", name: "Harvard Kennedy School - John F. Kennedy School of Government" },
    { id: "a183710e-cdaa-457d-b986-0e96da73e2aa", name: "Harvard Law School" },
    { id: "3b819372-b09e-4a33-89dd-772047f0e924", name: "Harvard Medical School" },
    { id: "0d2bc97a-a7e7-40fc-9c7d-1cb645dc5334", name: "Harvard School of Dental Medicine" },
    { id: "34d850c5-a643-4ae4-9467-dd76ff45470b", name: "Harvard Summer School" },
    { id: "71800434-794f-4c8f-81cd-7730e70e6008", name: "Harvard T.H. Chan School of Public Health" },
    { id: "359ddd65-8b8f-4672-8be0-abb1b9e3b233", name: "Harvard Trade Union Program" },
    { id: "187e3978-aee4-4552-aa54-69012d2d7989", name: "Harvard Univ Office of Work/Life" },
    { id: "25e5e6d9-1f6a-4d92-a393-fb6fd039dbe0", name: "Harvard University" },
    { id: "07005ae3-8bae-49eb-82fa-d96d271aed95", name: "Harvard University - UIS TP" },
    { id: "72fe96a1-1928-47af-a52c-f24f7c018871", name: "HARVARD UNIVERSITY / HPPS" },
    { id: "9be3de48-b051-48ee-b4f6-2e7aa8bf6be4", name: "Harvard University Employees Credit Union" },
    { id: "55ae0bed-5768-43ec-9303-d60ea4df3937", name: "Harvard University Press" },
    { id: "92c9b405-45bd-4149-8585-ea04c0a356ed", name: "HarvardX" },
    { id: "5bc47b43-2b02-4776-9a91-0544b2df30d5", name: "Harvey Mudd College" },
    { id: "41f232f1-7d3b-4fd0-ac93-24dadc48d9c5", name: "HAS Hogeschool" },
    { id: "4199183e-e957-4da1-ab1c-d8a7e6a63dc2", name: "Haskell College" },
    { id: "8ccc513a-3ea5-4c39-9164-65f08d4888c4", name: "Hastings College" },
    { id: "302b2d46-10e1-4e82-8414-69e0b3a11095", name: "Haus der Technik" },
    { id: "c84192d9-4ed1-451e-9a33-ba1dbf4b7369", name: "Haute Ecole Francisco Ferrer" },
    { id: "d304b8e3-a108-4992-849e-a1cfe7c28171", name: "Hautes Etudes Commerciales - Liege" },
    { id: "433eef7e-62e1-4bdd-8076-eafce116575b", name: "Haverford College" },
    { id: "ecb1ca20-2b51-4727-9967-778bbe28b4c5", name: "Haverhill High School" },
    { id: "cd6bc0b1-0366-4af8-b5d9-04b1a8d911c2", name: "HAW Hamburg" },
    { id: "5024252a-3af5-4dd7-99d3-b3caebc782dd", name: "Hawaii Pacific University" },
    { id: "59db3c81-882f-42bf-bfcd-215298ae5433", name: "Hawken University" },
    { id: "592cca13-c4b9-45b1-8972-a804e5a8019f", name: "Hazara University" },
    { id: "2a50df75-3b63-4a79-9023-62fd9e94a9d0", name: "Hazard Community & Technical College" },
    { id: "bbe643c3-c875-4417-adec-617d43b6bdef", name: "HBP Test" },
    { id: "9cb57dcc-84e9-4d19-842b-5d1bb6e39be9", name: "HBP University" },
    { id: "aee50331-3729-4c32-9168-800009286ac4", name: "HBS - Global Operations" },
    { id: "4cc4fb16-1b26-4029-bef3-7caf8041aa79", name: "HBS - Knowledge & Library Services" },
    { id: "1c487d4c-4384-4d27-bad6-c8c7c056547d", name: "HBS Club of San Diego" },
    { id: "cc54a6c4-614a-4ba5-9374-af0770ae08a6", name: "HBS Publishing Group" },
    { id: "e843e5a8-f5bb-4ed9-af38-2fb61c7bc3ff", name: "HBSA/NC" },
    { id: "535a93a0-3a42-44f6-b0f5-5dcb2d399113", name: "Hbsp/Mary Dolans" },
    { id: "c39f5de2-ca85-4e5a-b404-648f0b4a8656", name: "HCT" },
    { id: "3415e5b6-7b28-4978-bd88-dd4622abe76f", name: "HDF School of Management" },
    { id: "cf57f169-986c-4089-8676-524ccc3b9770", name: "HE OTHER" },
    { id: "1667d812-d8ab-4cbe-99b0-3cf6a65c3578", name: "Heald College - Fresno" },
    { id: "ed331099-f452-4b93-b764-94e489fbc6ab", name: "Heald College Portland" },
    { id: "1d8a986b-9892-43ef-93ae-868a5910a371", name: "Hebei University of Technology" },
    { id: "8ff84ebf-484e-435a-9ab0-9a72fd323cc6", name: "Hebrew Theological College" },
    { id: "83ba9678-ef7f-4097-bf54-ae2350a04a52", name: "Hebrew Union College" },
    { id: "8011a618-d5be-4d32-9b3e-760d597ea929", name: "Hebrew University of Jerusalem" },
    { id: "60abddfe-2fc5-4a21-a6d9-845f0a7db923", name: "HEC - Montreal" },
    { id: "be054368-7f1e-4c61-8bd9-9d929b2e534a", name: "HEC - Paris" },
    { id: "06e26174-d272-4779-95cc-28aca65ddd5f", name: "HEC - Ulg" },
    { id: "dfa82a7e-962d-4b88-acc8-290d8262c911", name: "HEC Tunis" },
    { id: "88cd4120-d256-4d20-9229-7f0ee43b107c", name: "Hector School of Engineering & Mgmt" },
    { id: "cdd7e32f-41ff-425a-9d55-b99116882695", name: "Hedmark University College" },
    { id: "0f1b61c7-19d8-478c-b147-3c3d21629912", name: "Hefei University of Technology" },
    { id: "fc9542b8-0d2c-44a5-8940-d508bc1f6a7b", name: "HEG (Haute Ecole de Gestion) Fribourg" },
    { id: "5a4d31bc-e435-45a4-9b49-21ddc299ca50", name: "HEG - Geneva School of Business Administration" },
    { id: "544e183a-b1bf-49d9-b305-b137c05bc727", name: "HEG - Haute Ecole de Gestion de Geneve" },
    { id: "e3271165-12f2-4a0b-ba43-7f2157a73775", name: "Heibei North University" },
    { id: "23e5f435-45d6-41be-beaa-d423caa75d71", name: "Heidelberg University" },
    { id: "2c4167ac-6766-4fe6-81fe-4ffeb50497d9", name: "HEIG-VD - La Haute Ecole d'Ingenierie et de Gestion du Canton de Vaud" },
    { id: "4ac88fee-871d-409e-80c6-fbd2a32f1bf4", name: "Heilbronn Business School" },
    { id: "15c4309c-3faf-4440-a7e0-440139006a7b", name: "Heilbronn University" },
    { id: "8a8d0d8c-01e5-4af5-ade6-c71d87d69a69", name: "Heilbronn University of Applied Sciences" },
    { id: "a29baff6-128c-4f8f-89e2-8c9c9e300ad0", name: "Heinrich Heine Universitat Dusseldorf" },
    { id: "24dd35f1-8fcb-4be4-9841-44169b994424", name: "Helbus" },
    { id: "639a9fa9-3a88-4a40-945e-52aa4e849616", name: "Helena Chodkowska University of Technology and Economics" },
    { id: "083c8b7a-760a-40fe-8fc1-190c3526cf17", name: "Hellenic American University" },
    { id: "63481e75-d648-4b56-961c-6a6e8a31bb7a", name: "Hellenic College and Holy Cross Greek Orthodox School of Theology" },
    { id: "d62d2275-9ac7-4d1a-aeb2-a67fccdadb81", name: "Hellenic Management Assoc. (HMA)" },
    { id: "6caa7af7-89ee-4675-9b5f-9d47cf4e0603", name: "Hellenic Open University" },
    { id: "6e6f8dd8-3fbf-48e0-87b2-4437277104a0", name: "Helmut Schmidt University" },
    { id: "489445db-c423-4dc3-8db4-b2a3d1b58eca", name: "HELP University College" },
    { id: "37af6109-924f-4214-bab4-f70196412d91", name: "Helsinki Business College Oy" },
    { id: "96473957-cf2b-4db2-b906-34120bf89a6d", name: "Helsinki Metropolia University of Applied Sciences" },
    { id: "dbac0f50-ddca-4a82-a912-36f08a6d9337", name: "HEM-France" },
    { id: "bea35c8a-79e6-4b76-b213-ccfd55ca2ea7", name: "Hemchandracharya North Gujarat University" },
    { id: "caf6820c-7d4c-4ef3-b43b-297d35f8efbe", name: "Henan University" },
    { id: "5de4d138-5e29-4a19-8bef-a5fd63df0925", name: "Henderson Community College" },
    { id: "5d23acc6-074c-4630-be68-0f593dcc96b4", name: "Henderson State University" },
    { id: "9618a953-b3cb-40b0-b77e-6b3cc10d4b97", name: "Hendrix College" },
    { id: "c13c3cb1-aeab-4660-88ae-fad909985108", name: "Henly-Putnam University" },
    { id: "9802052f-110d-4305-ae52-f9a51dd92d9f", name: "Hennepin Technical College" },
    { id: "96b345ae-c73f-4443-ade8-642db286accb", name: "Henry Cogswell College" },
    { id: "81a62f66-4028-41d1-81d9-904b98fb7782", name: "Henry Ford College" },
    { id: "c67041b0-97af-4748-844e-a4820c04e637", name: "Heriot-Watt University" },
    { id: "883f3b53-0070-4fcf-b1de-e45cbced2cbb", name: "Heriot-Watt University Dubai Campus" },
    { id: "13925a92-d68f-474e-a405-54a4017ff5bb", name: "Heritage College" },
    { id: "b973a841-6bae-450f-bcef-b80a16d1628d", name: "Heritage Institute of Technology" },
    { id: "d8b09420-d88d-460e-99a1-91b344e9caf4", name: "Heritage Pacific Asset Management" },
    { id: "34c2ffaf-0a07-4736-888d-7eaa18fa5a72", name: "Heritage University" },
    { id: "ca369e3d-2854-4e79-bb01-120f50641ca6", name: "Herlufsholm Skole og Gods" },
    { id: "eceb816b-acc9-4199-a6e5-9572c0768887", name: "Hertie School of Governance" },
    { id: "9de87ec6-399d-4099-bb6b-c44f5729e185", name: "Herzing University" },
    { id: "5228dfad-c915-4385-89de-16a3121bbd6a", name: "HES-SO Genève" },
    { id: "1306e0c4-a402-48a7-8174-92359dcee2c7", name: "Hessische Film- und Medienakademie (hFMA)" },
    { id: "6043bf66-2f18-4371-b338-553482c926c9", name: "Hesston College" },
    { id: "847284d2-d562-4ebf-b7dc-677ea974895d", name: "HFH Hamburger Fern-Hochschule" },
    { id: "2bc40cae-1cff-42f1-af39-ed83daac633c", name: "HHL - Leipzig Graduate School of Management" },
    { id: "9d7e20b5-6faf-43fa-b25d-e1636f50517e", name: "Hibernia College Dublin" },
    { id: "2e601cb2-93ac-4b11-9999-ea7cd13dacd7", name: "Hierank Business School" },
    { id: "2e4dabfa-e963-45d2-8be6-6c347304dbf2", name: "High Point University" },
    { id: "92ccf7b7-6cc5-4594-8b8e-2ffa93dcba07", name: "Higher Colleges of Technology CERT" },
    { id: "d24052b3-328f-4724-8cfa-50add5b8038c", name: "Higher School of Economics" },
    { id: "fda2aa28-3e7d-4ec8-893c-977b1cfc24d6", name: "Higher School of Management and Technology (ESTG)" },
    { id: "af234528-837c-4c13-8873-355df36317d5", name: "Higher School of Professional Studies - Blace" },
    { id: "af9e72fa-bd4c-4c9d-a4e7-3d8885737d2a", name: "Highland Community College" },
    { id: "378a92e9-52f6-41af-8a49-66452a77bc2a", name: "Highlands College" },
    { id: "1e426380-c28e-41e7-9617-9ebefb18a296", name: "Highline Community College" },
    { id: "4a0e2bfa-dc3e-4d9e-b042-7ec7aa0d9f7b", name: "Hilbert College" },
    { id: "5eac773e-f5d2-4b4b-8434-ee29af256d82", name: "Hillfield Strathallan College" },
    { id: "db8f4fe3-2418-4b27-ad12-7fbcf678b33c", name: "Hillsborough Community College" },
    { id: "c1597d6c-b7b9-480e-a10a-b59a68add8a3", name: "Hillsdale College" },
    { id: "ce9da6e2-0913-4844-965d-0c20eb73fede", name: "Hillsdale Free Will Baptist College" },
    { id: "57c5252c-d26f-4bd3-ba46-5ce8fd3a0179", name: "Hindustan University" },
    { id: "67f2df63-a1cb-4816-849b-4defaa09f556", name: "Hindustan University-School of Management & Executive Education (SOM-HITS)" },
    { id: "7dfcc0a1-26ac-415e-822f-d30484aaf398", name: "Hiram College" },
    { id: "76a7b11c-ec52-4be6-9be4-bcb4e2b723a6", name: "Hiroshima Jogakuin University" },
    { id: "99d5dd52-840d-4238-b076-ca877574685f", name: "Hiroshima Shudo University" },
    { id: "bdf04de8-9f2d-47c0-865c-981360ab0170", name: "Hiroshima University" },
    { id: "f3916d6f-474f-4331-b0da-6daaf02e6aa7", name: "HITEC University" },
    { id: "3b49fe7c-fa9a-4793-bd50-3611e28e5978", name: "Hitit Universitesi" },
    { id: "726de6f0-8c87-4bb5-b9f1-fe5af6fd735b", name: "Hitotsubashi University" },
    { id: "94c903cf-84ed-40ae-9ddb-5f3fee2b9ad8", name: "Hiwassee College" },
    { id: "7b99395a-d5f1-4a2a-a296-a52e8d77d4b5", name: "HKU University of the Arts Utrecht" },
    { id: "426ca452-6e3c-4303-b883-528454e5b1da", name: "HNE Eberswalde" },
    { id: "b21b4282-34d0-4760-807e-50c0820ed77a", name: "Ho Chi Minh City University of Technology" },
    { id: "e00f73fa-c04f-4b9a-ac30-a5cbcc86edf3", name: "Hoa Sen University" },
    { id: "2711d9b7-9cb3-4d5f-928a-79dcb7abe51c", name: "Hobart and William Smith College" },
    { id: "d569a3c7-e092-42a3-82c8-4380b24ca610", name: "Hochschule Albstadt-Sigmaringen" },
    { id: "b19fde8e-9c14-43df-a591-38a397b58b0a", name: "Hochschule Anhalt" },
    { id: "0f63f875-3379-4b0e-b7a8-ec23cbbad9a3", name: "Hochschule Ansbach" },
    { id: "145b0c42-6143-4063-99e5-cd942453045c", name: "Hochschule Aschaffenburg" },
    { id: "ea338335-4f47-4227-a663-b577c799d0fe", name: "Hochschule Bremen" },
    { id: "fcd9a5e7-678f-4e4e-8407-75047483f1c0", name: "Hochschule Bremerhaven" },
    { id: "f1bd4dbc-c77f-4cad-aadc-15a203638486", name: "Hochschule der Medien" },
    { id: "f01e3c1c-1532-4223-857e-d8cddb79cb66", name: "Hochschule der Wirtschaft Fur Management" },
    { id: "f851bf03-a934-481f-a9dd-98449242f436", name: "Hochschule Emden/Leer" },
    { id: "f7071752-08fe-4b9c-a061-2e597467d5d6", name: "Hochschule for Telekommunikation Leipzig" },
    { id: "be6b480a-9ddc-4735-bcb5-4fd6c2b250d8", name: "Hochschule Fresenius" },
    { id: "6fb74178-b25c-4270-91f8-87103f191c4b", name: "Hochschule Fulda" },
    { id: "e38bdd23-c7e8-4113-9109-4c1c3e7e51ba", name: "Hochschule fur Musik, Theater und Medien Hannover" },
    { id: "ef09335b-93be-4576-b691-56d53769d1ad", name: "Hochschule fur Technik und Wirtschaft Berlin" },
    { id: "621da604-6c8d-43fa-a7cc-a684cde25cca", name: "Hochschule fur Wirtschaft Luzern" },
    { id: "339209d2-6ecf-46cf-bd3c-41afd34c0c5f", name: "Hochschule fur Wirtschaft und Recht" },
    { id: "8d18fc17-d80b-472d-8aec-bf6828cb25eb", name: "Hochschule fur Wirtschaft und Umwelt" },
    { id: "e46b83bd-4c1d-481c-95fe-d923c9803fff", name: "Hochschule fur Wirtschaft Zurich" },
    { id: "8e159a8a-d009-4038-a713-5a690a77da53", name: "Hochschule Furtwangen University" },
    { id: "08cc2a99-2ebd-4d56-b866-54a591c25e61", name: "Hochschule fut Angewandte Wissenschaften Amberg-Weiden" },
    { id: "daf56438-5e28-4b15-8998-809b1e6d3657", name: "Hochschule für Angewandte Wissenschaften Würzburg-Schweinfurt" },
    { id: "e09826ba-78e3-4e4c-86cb-90f4f9d4a2e0", name: "Hochschule für angewandtes Management" },
    { id: "edef7b28-bfca-4d59-a84b-0e4de7d383bb", name: "Hochschule für Technik Stuttgart" },
    { id: "b7a79ea2-6867-400e-a5ab-8b1ae02b029a", name: "Hochschule für Technik und Wirtschaft des Saarlandes" },
    { id: "d29242c4-8768-46af-b450-3ad409a0453d", name: "Hochschule für Wirtschaft und Umwelt Nürtingen-Geislingen" },
    { id: "91c3ca30-e079-46e0-937c-4f906553349e", name: "Hochschule Hannover" },
    { id: "eff3b496-4b0e-4553-b0c2-2cac65b3fa9a", name: "Hochschule Harz" },
    { id: "84ff0995-ef73-4d59-a201-099383963f04", name: "Hochschule Hof" },
    { id: "5076a7ec-1893-4802-9182-de6c16dffa29", name: "Hochschule Ingolstadt - Bibliothek" },
    { id: "d6bd2ad2-2699-4f0a-9a88-a0e549b17d0d", name: "Hochschule Ingostadt" },
    { id: "656ef639-aae1-4667-8052-5f8859f919cb", name: "Hochschule Kempten" },
    { id: "4434ba96-0b5f-4f50-a1d3-658f214a54dc", name: "Hochschule Konstanz University of Applied Sciences" },
    { id: "ee6ffa45-ee93-4583-9c24-c3f70c4fbe92", name: "Hochschule Lausitz" },
    { id: "7592b073-25a2-40e7-ad03-e6ed76ad7e18", name: "Hochschule Liechtenstein" },
    { id: "3a7267c2-35d6-404d-8783-bb2294f3d703", name: "Hochschule Magdeburg-Stendal" },
    { id: "687fdea6-1356-4e05-a263-6592aad21e45", name: "Hochschule Mannheim" },
    { id: "a69ca5c1-643c-40ee-aef9-f597d4e33de5", name: "Hochschule Mittweida" },
    { id: "9cc867e0-1d7f-4950-96aa-8a7a5fa7dda9", name: "Hochschule Neu-Ulm" },
    { id: "8fa6f9d8-b026-4e96-a713-ed9f3b2528b2", name: "Hochschule Niederrhein" },
    { id: "c2bfcce4-69a8-482e-839d-4553f896b6ee", name: "Hochschule Osnabrück" },
    { id: "58f668ed-23cc-4a00-b31e-dfb08b544927", name: "Hochschule Ostwestfalen-Lippe" },
    { id: "09281b8f-d5fc-451b-aca4-82beef26ec8c", name: "Hochschule Pforzheim - University of Applied Sciences" },
    { id: "4134f296-8eda-48c7-93ab-f059b7493e98", name: "Hochschule Ravensburg-Weingarten" },
    { id: "80806ac5-c403-4926-9e4d-8014144a560f", name: "Hochschule RheinMain - University of Applied Sciences" },
    { id: "af59ec45-7beb-471d-a815-5b5e0a51796e", name: "Hochschule Rosenheim" },
    { id: "777c1809-5619-4ff3-b621-2a1b93e8ae5c", name: "Hochschule Rottenburg" },
    { id: "c76d717a-ebfe-4e0b-aa7b-2ab2d0e96047", name: "Hochschule Weserbergland" },
    { id: "fa819a48-def5-4c40-8ac2-2476abd06867", name: "Hochschule Wismar" },
    { id: "98a93b56-71aa-4042-aa00-a528f048e4db", name: "Hocking College" },
    { id: "8bc2206a-df45-48e1-897b-5028f8e08ff7", name: "Hodges Figgis, Ireland and Trinity College, Dublin" },
    { id: "d513ce10-3031-4ed4-8272-2ff176d49b1c", name: "Hodges University" },
    { id: "b20f22b6-868e-4b3c-bc77-ac9150b7d384", name: "Hofstra University" },
    { id: "e6d2e665-8aa4-4dfa-9685-ca5623b7156a", name: "Hogeschool In Holland" },
    { id: "966f4d5c-fa9b-43d7-b028-b86e0f6a23c3", name: "Hogeschool Inholland Diemen" },
    { id: "f06fdd57-72fa-43fa-8a2a-528491b83599", name: "Hogeschool Rotterdam" },
    { id: "25d2a4dc-498f-473b-a860-e8ec19125c4f", name: "Hogeschool Tio" },
    { id: "d76f707c-e3f2-48e3-a31c-351e78ebed70", name: "Hogeschool Utrecht" },
    { id: "49c73074-9182-4968-8bc0-8e41406c880b", name: "Hogeschool Van Amsterdam / Hes" },
    { id: "5bffbb74-7d23-408c-9a17-6eadd055286f", name: "Hogeschool Van Arnhem En Nijmegen" },
    { id: "594ac264-e686-448e-a185-fff94c18d5aa", name: "Hogeschool Zeeland" },
    { id: "314b7da5-6b14-4e1a-95a7-3c3b5942e90b", name: "Hogeschool Zuyd" },
    { id: "c042f668-2dd5-4e18-bb68-c211c66ca7da", name: "Hogeschool Universiteit Brussel (Hub)" },
    { id: "e47dc7b8-868a-48e9-91d3-2761fcf46203", name: "Hogskolan Vast" },
    { id: "c899e1d9-2b62-424f-8d8a-7a46a46ad6cf", name: "Hokkai-Gakuen University" },
    { id: "976ce4ca-7a4f-4611-93fa-41ab9990e910", name: "Hokkaido University" },
    { id: "e486d406-8f05-47a4-861e-49b44f561968", name: "Holar University College" },
    { id: "97e86271-f297-41bb-ae4f-8d7f3c028693", name: "Holborn College" },
    { id: "9ab44e4f-7643-4e5e-ab37-5b91091ecdb5", name: "Holland College" },
    { id: "c3c4d54d-23bf-45e5-a82c-3ff90c64ca4f", name: "Hollins University" },
    { id: "e59ace09-9442-4530-b1af-51abd9b7427d", name: "Holmes Institute" },
    { id: "5a747a97-32d0-4d16-a7c9-14aa7028d7fd", name: "Holmesglen Institute of TAFE" },
    { id: "f0470758-eeb2-4ac5-99fd-cfdae31fe20f", name: "Holon Institute of Technology" },
    { id: "f28045db-4b9c-40fd-b9a2-5eae01b759d8", name: "Holy Angel University" },
    { id: "0e71f622-afa3-468d-9c1f-62a6cbd0cef9", name: "Holy Cross College Norte Dame" },
    { id: "b07c6508-cd1e-4463-a9ed-a946b1cda9b5", name: "Holy Family University" },
    { id: "5be95c98-dcc7-4726-9825-1ea2ac59709d", name: "Holy Names University" },
    { id: "b6922549-f867-4f9f-be15-ce2464f3c016", name: "Holy Spirit University of Kaslik" },
    { id: "e48aa2ae-7215-4552-b68f-560e22f258ae", name: "Hong Kong Baptist University" },
    { id: "2a917373-4a05-4f35-8ac7-f3d6c9a38498", name: "Hong Kong Institute of Vocational Education" },
    { id: "d4fde033-4e4d-444c-9b1f-d5ed7a8d5065", name: "Hong Kong Metropolitan University" },
    { id: "820cf9e1-279a-43b9-ae27-c932231f0620", name: "Hong Kong Polytechnic University" },
    { id: "9415d0c1-c2fd-4c00-b0e3-5dda55403d3d", name: "Hong Kong Shue Yan College" },
    { id: "c76165f2-1080-4175-ad53-4d70dcddb748", name: "Hong Kong University of Science and Technology (HKUST)" },
    { id: "b7ef6ca8-0ac0-4736-9b20-9777e2c381b2", name: "Hong Kong Wine Academy" },
    { id: "29f0b330-673e-4e19-ae92-48f5ad97af6e", name: "Hongik University" },
    { id: "0040a356-9787-4deb-a493-ca54b59547b7", name: "Honolulu Community College" },
    { id: "af08a937-22f9-40c8-af09-37dbd549b910", name: "Hood College B&N Bookstore # 029" },
    { id: "88f5442e-a7eb-465c-b54e-9856bb32d27e", name: "Hope College" },
    { id: "4abf4449-b21e-4415-8a57-26cdd7f25a18", name: "Hope International University Follett # 115" },
    { id: "938e1747-4994-4d94-8c46-a0e384a0eed8", name: "Horace Mann Elementary School" },
    { id: "54515847-5dbc-456e-b057-55aefbcc3e61", name: "Horizons University" },
    { id: "9b71846d-e530-4faf-8292-edbe3b70530a", name: "Horry Georgetown Technical" },
    { id: "430114f4-9b9f-423c-8cdc-a20be2239711", name: "Horvath & Partner GmbH" },
    { id: "bccb8363-652f-498a-8566-9fceb2f7e82d", name: "Hosei University" },
    { id: "fa67b9b7-ccda-4e65-b6b6-00084ba888db", name: "Hoseo University" },
    { id: "ea16a77b-9e67-413e-8cbf-924a8b898cee", name: "Hostos Community College" },
    { id: "d86e4603-4929-4935-99f4-ef9f1ed86b1a", name: "Hotel and Tourism Management Institute - Switzerland" },
    { id: "fd81e7e3-0b31-441f-ade6-5f46609359d1", name: "Hotel Institute Montreux" },
    { id: "16f3a00a-9a80-4fbc-8473-eb8a4be73019", name: "Hotelleriesuisse" },
    { id: "d7ab28cc-7c7a-4f7a-bc54-325e4bd9d0b4", name: "Hotelschool Den Haag" },
    { id: "a8d3e5d4-9ec4-4519-b1e2-10f9d3dfef7c", name: "Hotelschool The Hague" },
    { id: "0b5c8687-c169-44a1-bcc9-619d4c5f4cc7", name: "Houghton College" },
    { id: "714751b6-0fc3-449c-8634-f9edb0165e4b", name: "Housatonic Community College" },
    { id: "be5c7ac9-92c7-4b1a-9787-66ab44608c15", name: "Houston Baptist University" },
    { id: "34b2a685-647e-45cb-bcea-15d59469d12e", name: "Houston Community College" },
    { id: "8e458996-a700-4c51-8f8c-7deee4873abf", name: "Howard Community College" },
    { id: "d987dbef-67b6-47b6-a7ea-06d3afa6e0e2", name: "Howard Payne University" },
    { id: "3603c63a-f240-485f-a824-204a23148b7f", name: "Howard University" },
    { id: "2befcf20-ab15-492b-aa4b-c421189771c5", name: "Howard University - Hyattsville" },
    { id: "eae06c6f-d8e2-4d38-9fe2-1ed26e15fad4", name: "Howest" },
    { id: "fe8778f3-b157-42f5-8173-ec7bf7e641af", name: "HSL Hamburg School of Logistics" },
    { id: "4bdfa5b5-774c-437a-9dc4-91a579531b6a", name: "HSM Educacao Executiva" },
    { id: "bf9086d3-b688-4b6c-87bb-77b63c08af30", name: "HSO Business School" },
    { id: "d9c20970-ef8e-4e9f-b6fa-023eb66801ab", name: "HTSI School of Tourism & Hosp Mgmt" },
    { id: "7fa849c8-3433-4434-a475-e7c9ce5f35b7", name: "HTWK Leipzig" },
    { id: "57060f40-3565-4ea7-9e37-7d31c15f1610", name: "Huafan University" },
    { id: "4eb5aa3f-6d22-407f-9fb1-7776f2df2fb5", name: "Huazhong University of Science and Technology" },
    { id: "c3b68d94-3460-4cfe-b958-53c21542f713", name: "Hubei University of Technology" },
    { id: "c27db4c3-7021-42fb-ad0d-c8214d6dbee8", name: "Hudson Community College" },
    { id: "fc9bb0c8-b2e9-4388-9dea-ae667e05de3c", name: "Hudson County Comm College" },
    { id: "d7bf1b94-eec5-4acb-8a5a-4c0573293201", name: "Hudson Valley Community College" },
    { id: "3e90a61a-b845-4b5b-a2d6-ad1f6042a20c", name: "Hult International Business School Inc." },
    { id: "7e0a1fa7-9a77-48f7-b14a-e56b82f3e4a3", name: "Hult International Business School Inc. - Dubai" },
    { id: "e5080a22-d167-498e-9df0-153e48f687ff", name: "Hult International Business School Inc. - London" },
    { id: "50155aca-ff9d-4194-b281-c937ebf3e3a6", name: "Hult International Business School Inc. - San Francisco" },
    { id: "cc4f03e2-5793-43bd-b454-4a87c3cfdab5", name: "Hult International Business School Inc. - Shanghai" },
    { id: "27f584e6-595f-436b-98ac-08762cb19ecb", name: "Hult Investments FZ-LLC" },
    { id: "5e4593cb-375f-4f47-8ab5-f545e8c3c5ee", name: "Human Capital Leadership Institute" },
    { id: "619b43a6-05ef-4ce5-967d-c23495ab0e5f", name: "Human Link Corporation" },
    { id: "18e2b3a5-cb4d-4a7c-927c-e1d6ac59cbc2", name: "Humane Society University" },
    { id: "77d65b04-3041-47ac-bcd6-f48a2785e54c", name: "Humble Independent School District" },
    { id: "513bf4ca-765b-41dc-bcf2-838b968088bb", name: "Humboldt State University" },
    { id: "2a33a135-6ac8-44f2-b01a-578a7491157d", name: "Humboldt Universitat zu Berlin" },
    { id: "c8af8675-5d69-47e2-95c5-d7dd5f61c453", name: "Humphreys University" },
    { id: "6db58a06-d802-4871-a0b0-64b410b1f1cc", name: "Hunan University" },
    { id: "4b4cfeac-8c29-4f89-b12f-579246ee5b36", name: "Hunter Test Account" },
    { id: "7be6f57f-5c1c-41b2-9394-3bf6b644076d", name: "Huntingdon College" },
    { id: "3ed1facf-3fb8-4ea2-9561-36c27883af15", name: "Huntington Beach Union High School District" },
    { id: "6c3dcb79-1006-4bfe-8cb7-e692e4c594c1", name: "Huntington University" },
    { id: "a0eae56f-f910-4963-a78c-e01fc9a522b7", name: "Hupan University" },
    { id: "32de5eae-6039-48fc-b745-f63855deab52", name: "Huron Valley Printing and Imaging" },
    { id: "23b3609c-3fa9-414e-ab9d-2736bc107278", name: "Husson College Bookstore" },
    { id: "14d7dfd3-8489-461e-a3dd-ea0beeb043fd", name: "Husson University" },
    { id: "38190b0a-db01-4b90-86a6-400f0f6b3855", name: "Huston-Tillotson University" },
    { id: "e3a0b29d-29ff-4508-bd51-49aac5e20d7c", name: "Hutchins Center For African and African American Research" },
    { id: "358ffe41-c025-4d5a-918e-c6630ed6fd1f", name: "Hutchinson Community College" },
    { id: "47e6cd02-40a9-4da1-b404-324dcff5077c", name: "Hwa Chong Institution" },
    { id: "2b237992-f8b0-4652-b4ca-3c6e0fc7ab85", name: "Hyderabad Business School" },
    { id: "83c87d49-410a-40b3-8fd8-582ffe0f28c8", name: "Hyderabad Institute of Arts Science and Technology" },
    { id: "44d748d4-adeb-4cb2-8d77-83129a19cbfc", name: "Hyupsung University" },
    { id: "80610be0-cd92-4d48-b5a3-6b430fa67173", name: "HZ University of Applied Sciences" },
    { id: "98b0510e-a9fe-4a5c-bb2e-275efb87e3a7", name: "Högskolan i Gävle" },
    { id: "aa756ac3-a011-402a-8a46-c2e50aadba3d", name: "Høgskolen i Hedmark" },
    { id: "899b06a5-e8ec-4444-bd96-3ead453bef5c", name: "Høgskolen i Oslo og Akershus" },
    { id: "a314bd0e-3265-4f73-aaaf-d4db2e5e05b6", name: "I-Shou University" },
    { id: "e52d4715-1e0b-41bd-ae13-5faff838c2ac", name: "I.P.E. Istituto per ricerche ed attività educative" },
    { id: "088889d6-b3fe-48a5-b82e-b0c286328f39", name: "IADE - Biblioteca Antonio Quadros" },
    { id: "6bbba071-51e2-4408-8d3b-0486144a50f5", name: "IAE - Bordeaux" },
    { id: "b3b0668a-d114-49bd-8431-8b7c362c3aea", name: "IAE - Esug Toulouse" },
    { id: "e6c3716c-f60c-4b3e-82c3-32b2a21af0a2", name: "IAE Aix-En-Provence" },
    { id: "df1b3a9d-fe74-4866-8d18-dd225a200352", name: "IAE Amiens" },
    { id: "741c14f1-e983-489c-82ea-bcf5e646b6bd", name: "IAE Grenoble" },
    { id: "ba891e01-410a-4585-ab2c-3ef090bb5949", name: "IAE Gustave Eiffel" },
    { id: "434aa774-ffa0-4f8f-b06a-bda5c4fee088", name: "IAE Lille" },
    { id: "8d4eaa43-3b3f-4048-916f-6a362be41ed8", name: "IAE Lyon" },
    { id: "f143647a-29f6-42e8-8563-56019cff1006", name: "IAE Nice" },
    { id: "8db2ee2b-e33f-4dae-a14e-c60e45129f30", name: "IAE Paris" },
    { id: "54b17117-10c1-45f9-9d73-01b736946ff1", name: "IAE Poitiers" },
    { id: "0f7441c1-6bf7-4ce2-b49d-43f90397c57e", name: "IAE Toulouse" },
    { id: "1269822e-4370-4d42-948a-55a2de4e13f5", name: "IAE Business School" },
    { id: "cf061ca4-0ef7-4fec-881d-eaedbef87d8a", name: "IAEN - Instituto de Altos Estudios Nacionales" },
    { id: "beb28c80-7f03-4bab-a4be-6fc5407a2b3d", name: "IAU College" },
    { id: "27da0ba2-36ed-4759-9310-45f353a32592", name: "IBA - Bangalore" },
    { id: "bf2be62f-f879-48b8-9c7e-8ce9a5f4e8f3", name: "IBA International Business Academy" },
    { id: "8d27d95a-32aa-437d-8659-5b384f8533c0", name: "IBAT College Dublin" },
    { id: "f99f18eb-316e-4f7f-96aa-0b285f44a71a", name: "IBC Kurser - Innovationsfabrikken" },
    { id: "83a13f01-4bbc-44be-b949-a716256535ec", name: "IBM Training" },
    { id: "e44fb71e-8c6a-47a9-856e-27c1e5cc7bf7", name: "IBMEC" },
    { id: "a5a85cde-aa49-4acc-a1a6-6c630986748c", name: "Ibra College of Technology" },
    { id: "d3d3476e-0349-43bf-8877-122c979303d1", name: "IBS - Admedabad" },
    { id: "0987cddc-30e8-4eee-8077-69b62abc9822", name: "IBS Hyderabad" },
    { id: "8dee62ee-7fc3-417a-9c80-f01230b1bd07", name: "IBS Mumbai" },
    { id: "77f97fca-f6cd-49fb-bb1d-714f9c480645", name: "IBS Project" },
    { id: "a739475b-b226-4d77-88de-9fdcabda9bf6", name: "ICAEW" },
    { id: "4542f36f-0ae3-4737-9748-0512d2de6b50", name: "ICAMI - Centro de Formacion Y Perfeccionamiento Directivo" },
    { id: "c65150e7-1118-4da0-9e1d-d91d22fa1c2f", name: "ICAR-Indian Institute of Horticultural Research" },
    { id: "d3f23f12-21a5-4b77-9ec5-86a37b738f2d", name: "Icat Fcul" },
    { id: "7abbade0-6d2e-42e7-ab42-eee821d431e2", name: "ICBM - School of Business Excellence" },
    { id: "89dad7b7-882f-4561-a694-42e23322334b", name: "ICD Business School" },
    { id: "2f5a5bed-c65a-41c0-bde8-dcfc2fb5dea8", name: "ICEX Spain" },
    { id: "8a460406-6e8c-4e8b-be61-9f0631f6a620", name: "ICFAI Center for Management Research" },
    { id: "c5df19a2-1fb5-4bc2-8e1e-57c1baa7dfda", name: "ICFAI FOUNDATION FOR HIGHER EDUCATION(IFHE)" },
    { id: "7416d08f-ddc9-4c0b-9ac0-1383c3ecec8a", name: "ICFAI University" },
    { id: "00048118-eede-42d8-9001-2e106699cdc5", name: "ICHEC" },
    { id: "e4a548e9-c1c9-4c32-b8e9-299e3bdd3206", name: "ICHM - Interntional College of Hotel Management" },
    { id: "9e6704b2-a6c1-42ed-848b-eb8aed5e8dec", name: "ICICI Manipal Academy" },
    { id: "e607090c-3159-49aa-9257-033286d12664", name: "ICL" },
    { id: "3fec1d1d-da6c-496f-9ebd-5ac1b5b6b1c8", name: "ICN Business School" },
    { id: "a3504d01-c3aa-4df0-80ba-335240e6f286", name: "Idaho State University" },
    { id: "a286874c-6b5f-4daa-9107-2a16916a2597", name: "IDE Ecuador" },
    { id: "7cf42ceb-ae72-418c-b6f4-d99db898394d", name: "IDEA - Interdisciplinarity in English Studies" },
    { id: "7fd92240-fc28-461d-b92d-0abe3aaf7d5c", name: "IDEA Public Schools" },
    { id: "ded43ad3-25f4-4bed-bd26-cb36637abd6d", name: "IDEA San Benito" },
    { id: "93d2566b-4c0d-4a4e-a49c-11c4c2c045e1", name: "Ideal Business College School" },
    { id: "87022a01-a71a-44e3-9c31-2b47bb03ded1", name: "Idec - Universitat Pompeu Fabra" },
    { id: "f82bad72-5cc7-41e1-80b7-d2f52d77fdbc", name: "Ider Institute" },
    { id: "e32c8613-e65b-4dbe-83e5-8a0bcfbe50aa", name: "IDESAA Escuela de Negocios" },
    { id: "7702bdc2-12d4-4545-adfc-580b7323fdfc", name: "IDESIE Business School" },
    { id: "b524991c-13a2-408b-a8da-03eae4ef62da", name: "IDHEAP - INSTITUT DE HAUTES ÉTUDES EN ADMINISTRATION PUBLIQUE" },
    { id: "851de266-6cae-443b-9c7c-20e325979e59", name: "Idrac Association" },
    { id: "3a002691-c828-4725-83cb-d2de0be0e675", name: "IE Business School" },
    { id: "a71954f2-febf-4e79-8cfe-ca4b6c98dd27", name: "IE University" },
    { id: "73b3e6a9-cc66-4dec-80b5-b52a10364746", name: "IECS Ecole De Mgmt De Strasbourg" },
    { id: "343a5118-0a68-4000-bcf3-820a9b448f3b", name: "IED - Istituto Europeo di Design, Roma" },
    { id: "b6c8e23b-2634-4fe4-b8b1-fc3de6e54e16", name: "IEDC - Bled School of Management" },
    { id: "ba42a80e-2dce-47e5-a435-3200e315ed58", name: "IEDE Escuela De Negocios" },
    { id: "779ebef1-210c-41a4-a725-3c298087fa46", name: "IEDE Escuela de Negocios - Chile" },
    { id: "16e708cc-78ae-40bf-93c9-ce96eaeb99ea", name: "IEEM - Universidad de Montevideo" },
    { id: "db683693-770e-4dea-84aa-c350680a0140", name: "IEP Rennes" },
    { id: "b00e0ca3-a071-4403-bcda-35540fba1f9a", name: "IES Abroad Institute for the International Education of Students" },
    { id: "a43570d3-d25f-4687-95f3-fc809a96b75a", name: "IES en Lenguas Vivas Juan Ramon Fernandez" },
    { id: "4ae0e0fc-8d72-45f8-a2e3-584150c508af", name: "IES Management College and Research Centre" },
    { id: "135b9da6-ba17-4a61-8ae5-c25a783fc251", name: "IES MCRC" },
    { id: "409f41f4-e6e0-4ac5-89c5-908caef57f00", name: "IESA Venezuela" },
    { id: "0119c61b-4f2b-4449-b1e8-aa930d55b1b9", name: "IESB High Education Institute of Brasilia" },
    { id: "9d578fe1-9d1b-4bf7-be4c-220126e0b4f1", name: "IESDE - Instituto de Estudios Superiores en Direccion de Mexico" },
    { id: "3209d2e1-90b1-4cff-967c-da28eb81ad42", name: "IESE Business School" },
    { id: "13c118d4-e492-4c02-9ce1-bd40c5716438", name: "IESE Publishing" },
    { id: "2a48942d-84ba-447c-8355-d2a9c3a01f3f", name: "IESEG School of Management" },
    { id: "18387580-34f2-46c7-8653-45024817c3a0", name: "IETH - IEMI EMC Campus" },
    { id: "218ec341-621f-45bc-a6d1-2955799e76ee", name: "IFAG Paris - Ecole de Management" },
    { id: "6aa9dae8-1cc3-4f94-b4fb-592e3bbaeb02", name: "IFC Ales" },
    { id: "776feb09-b9e3-45f8-938a-1dfa7d0aa63d", name: "IFC Provence" },
    { id: "c619ecc4-000a-4600-a9f2-af099fdb6b5e", name: "IFES - Instituto Federal do Espirito Santo" },
    { id: "0af7c6ec-7d9b-4def-aa2a-7b1c7150d6e6", name: "IFFE Business School" },
    { id: "a73551fe-5994-4dea-a424-3e079eb30e8c", name: "IFI Business School" },
    { id: "2c4d8a0c-bcaa-4495-a356-cf43e0b1441d", name: "IFIM Business School" },
    { id: "76b914a0-1d0d-4814-a0e8-aa6174b451a5", name: "IFMR Graduate School of Business, Krea University" },
    { id: "26260b61-2a8a-4ec1-bd3c-ce574d8c3606", name: "IFP School" },
    { id: "72408709-e18c-411d-88e4-b14fcd297a3c", name: "IFS - Instituto Federal de Educacao" },
    { id: "43bf69d9-622b-45eb-9df1-78b412f99b60", name: "IFS School of Finance" },
    { id: "0ca09a9d-31c4-497e-9dcd-d4deaa81213c", name: "IFS University College" },
    { id: "80e69de9-fb03-474a-b362-36b0db03ef47", name: "IFSC Campus Florianopolis" },
    { id: "5f372819-1964-4a66-be4a-74949687b755", name: "IFTM University Moradabad" },
    { id: "6b47f017-f18a-4a61-bcda-ea869584dfdd", name: "IGS Group, ICD Paris" },
    { id: "c3b1d2ba-3202-4f44-a991-149ea69406ac", name: "IHECS - Institut des Hautes Études\ndes Communications Sociales" },
    { id: "c7f1e9db-1ba2-4663-af93-1d04380df409", name: "IHEID" },
    { id: "40e6596a-ce70-4dcb-8f84-589e8c4b3248", name: "IIB -Institute of International Business" },
    { id: "ae556ce2-8881-453d-b15b-b6224157bdb7", name: "IIC University of Technology" },
    { id: "335e6755-e7c4-46ef-b2b6-781dd4d1a6fa", name: "IILM Academy of Higher Learning - Jaipur (JIILM)" },
    { id: "34f40791-7c0e-43be-9b5d-2e53d8d9cd57", name: "IILM Academy of Higher Learning - Lucknow" },
    { id: "e4e66eac-e881-4fe4-bec2-18ce60dda2ce", name: "IILM College of Management Studies (IILM-CMS)" },
    { id: "39632bf6-d701-438e-9078-9e13594aeafa", name: "IILM Graduate School of Management, Greater Noida (IILM-GSM)" },
    { id: "ee68f3fa-34fa-4450-97d2-1b1d75772bea", name: "IILM Institute for Business and Management" },
    { id: "75522598-9389-4767-ac9a-bf69bd3f4748", name: "IILM Institute for Higher Education (IILM-IHE)" },
    { id: "d6e7f2e9-954e-450d-aeba-181c80a0610a", name: "IILM Undergraduate Business School (IILM UBS)" },
    { id: "c87b9dd2-9205-4e22-8cd2-ae0c2b1637f5", name: "IIM Kozhikode - EPGP Kochi Campus" },
    { id: "d3dfc9a5-b0d4-4872-ae03-206bb5e51df1", name: "IIM Kozhikode - EPGP Kozhikode Campus" },
    { id: "9103bb81-0fc2-47d5-b69b-8a4cd7d1a576", name: "IIMEURO MBA School" },
    { id: "cc30ca74-8636-4b52-8bca-b966a705af11", name: "IIRP Graduate School" },
    { id: "b5005432-52b4-4cf2-af11-1533150cd1bc", name: "IIST - Institute for Int. Studies and Training" },
    { id: "8a29816e-b3ab-4ed7-b990-84ebe3f44490", name: "IIT ISM Dhanbad" },
    { id: "23b24d1e-f4cf-4def-9cbe-e6d44ca9e627", name: "IITB-WUStL Research and Educational Academy" },
    { id: "41434a4d-fcda-4381-a60a-53d3abc033e1", name: "IKF - Institute of Communication and Leadership" },
    { id: "766a71eb-99e7-4617-8349-4e98824b25d9", name: "Il sole 24" },
    { id: "78ca3f71-2231-4c8b-91be-309748544ff1", name: "ILAC International College" },
    { id: "f3d8fcc7-6b40-43fd-a9fd-7d72de430201", name: "ILEAD School of Business" },
    { id: "feac5ad1-86c1-49c5-a325-a33e23c7398f", name: "Ilia State University" },
    { id: "5d0b31bd-3de0-450d-b0d2-e2019db9521b", name: "IliffSchool of Theology" },
    { id: "361abe16-041d-4b30-924b-a162aab81174", name: "ILLEGAL POSTING" },
    { id: "91cafcc7-7f89-4ca7-8312-ba4bf046cc27", name: "Illinois Central College" },
    { id: "291b93d0-d33c-45fb-9304-bdfe2e463716", name: "Illinois College" },
    { id: "bf889260-4cb2-4134-8490-3e7e9e1eedb3", name: "Illinois Institute of Art Schaumburg" },
    { id: "7329c88c-0ed0-4eca-83d3-70f7afb1d9f6", name: "Illinois Institute of Technology" },
    { id: "2ff39cfc-7ff5-4c21-b40d-ed9208c26e7a", name: "Illinois State University" },
    { id: "b3a9e3be-701e-4299-acec-08336c9fe6ad", name: "Illinois Valley Community College" },
    { id: "3c378ae1-3e9b-4408-ad4f-7089f0d01531", name: "Illinois Wesleyan University" },
    { id: "ef9a152f-f2e3-4a9e-a249-0916448034f6", name: "Ilmenau Technical University" },
    { id: "f7b5ecf9-dd66-44d0-9ff8-db265b4ad995", name: "Iluccello Marketing" },
    { id: "9f1aef04-3b27-4107-a6cc-269b8cf0e84b", name: "IMADEC University" },
    { id: "2ca78977-0d4c-4389-a9ba-d9e5249dc9d1", name: "Imam Abdulrahman Bin Faisal University" },
    { id: "9048bbf3-a13f-45a2-b922-fdd52696c7cf", name: "IMC - FH Krems" },
    { id: "fe074f8d-a16c-457d-b49e-ea39a68fb125", name: "IMED" },
    { id: "dbd9a037-3922-43d1-86da-d82ba4710472", name: "IMERT - Pune" },
    { id: "aadde62a-30f0-4ed4-8b8e-ac357655a652", name: "IMF Business School" },
    { id: "fe3d54c7-f906-4d0e-9d6d-09a39248a418", name: "IMM Graduate School of Management" },
    { id: "24a58166-6dae-4a45-9910-8ca60242fbac", name: "Immaculata College" },
    { id: "bfa1bc26-40c4-48db-9530-d297c85f36f6", name: "Immaculata University" },
    { id: "c0df5728-12d8-45ff-80ea-2821dc7347e5", name: "Imperial College London" },
    { id: "728fb88e-144f-42db-b31c-b69b8edaacc6", name: "Imperial Valley College" },
    { id: "6970a17b-9899-4c35-b88d-3fa73aba2b1e", name: "Imperial Valley College Bookstore" },
    { id: "69393db5-ecc6-445a-bdb9-7144c6ea1298", name: "Implement" },
    { id: "9471aa4f-81e6-460f-93f3-3f5077b7eae0", name: "Implementing Success Ltd" },
    { id: "57863d08-cad7-442a-99f8-ee21838355fe", name: "IMS Unison University" },
    { id: "6db73730-8705-4925-8efd-10d2f983ee61", name: "INCAE Business School" },
    { id: "3a895c8b-3eac-410c-a3a4-070310df1232", name: "Incheon National University" },
    { id: "3dd6ebce-35fa-441c-b100-2a6e6e0dee4c", name: "Ind Univ Bloomington-III" },
    { id: "7f63b041-f0b3-4716-a674-9da7079be552", name: "INDEG-IUL" },
    { id: "a1f3aea6-ea7e-4daa-8c1b-baeca4a29cb2", name: "Independence University" },
    { id: "279934a9-9e96-4ede-85d7-aef845835611", name: "Independent Colleges Dublin" },
    { id: "2053295b-b56b-44a2-a473-d90d1d37b04b", name: "Independent Institute of Education IIE" },
    { id: "a8833518-d86b-4bda-94ff-735e42da26b1", name: "Independent University, Bangladesh" },
    { id: "2f802a91-f2ed-4771-93bc-cef90c1d5ff4", name: "Indian Education Society" },
    { id: "e7d475b8-414e-447d-aa55-f5bb465ea1a6", name: "Indian Institute for Human Settlements" },
    { id: "bfa1aa04-205f-41e8-ad8a-e6a227a019d9", name: "Indian Institute of Capital Markets" },
    { id: "82f6011d-ee25-4f8a-8308-b0835d81e873", name: "Indian Institute of Crop Processing Technology" },
    { id: "135c41c4-562d-4367-9307-7904af2674d6", name: "Indian Institute of Finance" },
    { id: "78f128a5-9258-4784-a18f-323d2cfb82f5", name: "Indian Institute of Foreign Trade" },
    { id: "2a7b0d79-d4b0-4d2c-8cb9-2e2ec1b41dc5", name: "Indian Institute of Foreign Trade - Kakinada (IIFTK)" },
    { id: "823224a6-aaee-44b0-a61e-78a6c65f83b7", name: "Indian Institute of Forest Management - Bhopal" },
    { id: "c29cdfd8-d123-4bcf-a9d1-f0d39e0adc5d", name: "Indian Institute Of Health Management Research" },
    { id: "02920631-95c0-49fe-a6ce-a57c9dd6ffe7", name: "Indian Institute of Information Technology - Allahabad" },
    { id: "05d66f2a-12ef-42cc-94ed-6660821211dd", name: "Indian Institute of Information Technology and Management" },
    { id: "4f71a2d1-dbda-4d84-8d01-51345008bccc", name: "Indian Institute of Logistics" },
    { id: "cd228b51-6dc0-4f3c-812c-910fbce34b14", name: "Indian Institute of Management - Ahmedabad" },
    { id: "7e8bb964-e553-458f-b9b9-60ddc44632da", name: "Indian Institute of Management - Amritsar" },
    { id: "ae4d2ad7-e742-4821-860a-be2c38309f45", name: "Indian Institute of Management - Bangalore" },
    { id: "4e90f9d1-aa97-4078-a22c-a15bcf49be7d", name: "Indian Institute of Management - Bodhgaya" },
    { id: "a1a3a3f6-f9cf-464a-8dc0-eab7fe8736ba", name: "Indian Institute of Management - Calcutta" },
    { id: "6a8b5470-102c-4529-aae0-5d58924ccd71", name: "Indian Institute of Management - Indore" },
    { id: "bdbb1afd-8ba1-40fd-aca9-32f6fd9eb044", name: "Indian Institute of Management - Jammu" },
    { id: "a2d41106-93b0-4422-b757-c7b898c9a6a7", name: "Indian Institute of Management - Kashipur" },
    { id: "53c1a365-cf39-4af1-aa53-9c00604635b8", name: "Indian Institute of Management - Kozhikode" },
    { id: "0ed20680-a68c-45e1-886d-e202158014dc", name: "Indian Institute of Management - Lucknow" },
    { id: "758eb52e-6538-472a-ae45-afcbf351e3bc", name: "Indian Institute of Management - Nagpur" },
    { id: "05130ebe-68f4-4a65-8fc5-b62b086391ba", name: "Indian Institute of Management - Raipur" },
    { id: "bbcd1ad3-7b8d-4316-a97e-a05282025133", name: "Indian Institute of Management - Ranchi" },
    { id: "67114586-2304-413d-b02b-bc48398c35f1", name: "Indian Institute of Management - Rohtak" },
    { id: "463c9585-1d7c-421f-8da1-e1546e546e70", name: "Indian Institute of Management - Sambalpur" },
    { id: "ba1e0cf7-e113-4434-8a8b-c5118ae318b9", name: "Indian Institute of Management - Shillong" },
    { id: "69eee5b0-2bbe-444a-90c5-eb1bb347bde3", name: "Indian Institute of Management - Sirmaur" },
    { id: "4ff65994-bdc3-4b47-b703-179a30580f9a", name: "Indian Institute of Management - Trichy (IIMT)" },
    { id: "f6349ee7-148a-414f-8f43-4b185a7a6cec", name: "Indian Institute of Management - Trichy Chennai Centre (IIMT-Chennai)" },
    { id: "2f924ef8-09c5-495f-9b27-88dbc54f26b6", name: "Indian Institute of Management - Udaipur" },
    { id: "78057a31-ac88-4403-887f-c722982fb3ea", name: "Indian Institute of Management - Visakhapatnam (IIMV)" },
    { id: "2fde3cf4-e782-4d5e-a29a-8de2335d9715", name: "Indian Institute of Management Tiruchirappalli" },
    { id: "e3627bd7-f6d6-47d1-bc9f-a834b157c137", name: "Indian Institute of Planning and Management" },
    { id: "7619e082-7314-404f-8bb2-93afde7f102f", name: "Indian Institute of Plantation Management" },
    { id: "f9a2e9e8-66a0-4eee-951a-79b05c78dc88", name: "Indian Institute of Science" },
    { id: "169e4630-3b0e-4448-9930-bbdf6fd5782a", name: "Indian Institute of Social Welfare and Business Management" },
    { id: "beb34b49-9d08-4d53-bfc2-d2e08ceaa86c", name: "Indian Institute of Technology (IIT Hyderabad)" },
    { id: "3f4a7bb7-c1a7-4ce2-a421-8523f1f9d999", name: "Indian Institute of Technology - Bombay" },
    { id: "05a6ee72-1aad-4e5c-98be-fef5e5bab16b", name: "Indian Institute of Technology - Delhi (DMS)" },
    { id: "d7459142-6292-474f-bdae-291a3531a38a", name: "Indian Institute of Technology - Gandhinagar" },
    { id: "65529961-d0a0-4128-bbb4-e085b96e3154", name: "Indian Institute of Technology - Jodhpur" },
    { id: "280e1c66-883e-4521-9be8-0d9873e58be8", name: "Indian Institute of Technology - Kanpur" },
    { id: "e938655b-817e-44cc-ada3-32487de49a17", name: "Indian Institute of Technology - Kharagpur" },
    { id: "019093fc-51af-4c2c-974e-4ca5fe802caf", name: "Indian Institute of Technology - Madras" },
    { id: "743a19d9-dae7-4c02-9ab9-56b9a09e102a", name: "Indian Institute of Technology - Patna" },
    { id: "8f433394-bfaf-4d7a-8446-4d70b1e5eb9a", name: "Indian Institute of Technology - Roorkee" },
    { id: "9b39f3d4-b0d6-417c-a3e8-80ea8645ce20", name: "Indian Institute of Technology Guwahati (IIT Guwahati)" },
    { id: "4be26458-61c7-493c-bc71-7715c6f20933", name: "Indian Maritime University" },
    { id: "1850004c-4249-43fb-b007-d7e1a658db90", name: "Indian Retail School" },
    { id: "a177e272-dd01-4a99-8bf9-ab357e3b3f82", name: "Indian River State College" },
    { id: "58aeb711-2d99-4235-a6f8-2429b17dacc8", name: "Indian School of Business (ISB)" },
    { id: "6574638d-0663-4c81-a0af-d3374e930c9f", name: "Indian School of Development Management (ISDM)" },
    { id: "2bab5d22-15b7-451e-b758-f52df4d0bc10", name: "Indian School of Mines" },
    { id: "33efe4e0-df8b-452f-9d4d-b4405265a336", name: "Indiana Institute of Technology" },
    { id: "d3ae521f-45c9-43f3-b253-a18e681e292b", name: "Indiana State University" },
    { id: "73f4d393-9f88-436c-9489-b103c1b63d2a", name: "Indiana University - Bloomington" },
    { id: "51d3e1af-16b7-46c3-8447-1a25eb82df52", name: "Indiana University - Business Horizons" },
    { id: "764a5509-16ee-491f-9cf8-0b7dbec3181f", name: "Indiana University - Columbus" },
    { id: "4f958de7-8462-4be8-aa2e-dcb68f8dc0dd", name: "Indiana University - East" },
    { id: "30e90f54-6800-419f-be0b-39e763aef47e", name: "Indiana University - Kelley Direct" },
    { id: "00ef1c60-e431-4073-8123-43a70b49b24d", name: "Indiana University - Kokomo" },
    { id: "a2c4c0c5-b6da-4a7e-825e-4dd574ebf91f", name: "Indiana University - Northwest" },
    { id: "779b9a1c-3e90-412c-a35b-3e4d9b70d9b2", name: "Indiana University - Purdue University" },
    { id: "8117f982-1d7c-4de5-92b5-1f90ba7d8abf", name: "Indiana University - Research and Technology Corp" },
    { id: "a9ec7b57-e2c8-47d3-862a-b798b9b209d6", name: "Indiana University - South Bend" },
    { id: "51e252b7-bc37-4169-b66a-c07614c820b9", name: "Indiana University - Southeast" },
    { id: "b4cf2d80-ac85-42e2-b5d4-eda03deb4488", name: "Indiana University of Pennsylvania" },
    { id: "532d5239-3768-415d-a7ee-37aad2fe6e48", name: "Indiana University-Purdue University Fort Wayne" },
    { id: "25c10591-c49c-4e30-9d84-5f3e6cea7a22", name: "Indiana Wesleyan University" },
    { id: "3c1af360-a5ac-4d46-939f-bf774e48ccf3", name: "Indira Gandhi Centre for Atomic Research" },
    { id: "32df5cdb-9f39-42ca-8e1a-4e84957337c0", name: "Indira Gandhi Delhi Technical University For Women (IGDTUW)" },
    { id: "5a8fc9ed-1fa7-4976-b761-83d474d03bb1", name: "Indira Gandhi National Open University" },
    { id: "eedf8f65-068d-40da-b25c-e385b429f80d", name: "Indira Institute of Management - Pune (IIMP)" },
    { id: "6ea56e83-56fc-4b6a-8b82-149c825ca8d8", name: "Indira School of Business Studies" },
    { id: "4e157bf0-a0e1-4107-9d9e-51bfbfd1fb0d", name: "Indonesia International Institute for Life-Sciences (i3L)" },
    { id: "ad539855-a362-4a99-bb0f-531edb7b73f8", name: "Indonesian Institute" },
    { id: "34949182-af20-4a78-8941-29480d877e3d", name: "Indraprastha University" },
    { id: "5ee734a6-e601-49b9-ab65-918c063198b9", name: "Indsearch" },
    { id: "155e79eb-8015-4ff2-94af-583c85155a28", name: "Indus Business School" },
    { id: "e56ae30e-6530-4354-bb09-926d6e89447f", name: "Indus Institute of Management" },
    { id: "03ad5700-49ac-4d31-8512-132c2e0a1957", name: "Indus International University" },
    { id: "bac845b4-b9c8-4e73-8877-b843efbb8738", name: "Indus Valley Institute of Art and Architecture" },
    { id: "bedb822e-3c71-4de3-9912-9e4c040da2e7", name: "Indus World School of Business (IWSB)" },
    { id: "f2e69ddf-d362-42b7-83d5-0207cf7de99f", name: "Industry and Competitive Analysis" },
    { id: "4ab7a81c-e353-43d6-b0b3-6b13bed002b4", name: "INESC Porto" },
    { id: "88210038-aa52-4679-9803-5d1c655496a2", name: "INESDI - Instituto de Innovacaion Digital de las Profesiones" },
    { id: "6214b7a6-77fd-4684-82e8-d950e5ca202d", name: "Infinity Business School" },
    { id: "7b6f3972-a507-4767-8345-4af57efee96b", name: "Informatics Education - Singapore" },
    { id: "25ff3cb1-e054-4943-9133-872111f4b8aa", name: "Information and Communications Technology Academy" },
    { id: "59ef4d62-48c4-4d27-bef2-1ce6d3d2cdd4", name: "Information Systems Management Institute" },
    { id: "a1b2506d-dc16-4638-9e54-7f58da915701", name: "Infosys Technologies Ltd" },
    { id: "dc055b0c-02d8-446c-a5a1-10ed63589141", name: "Inha University" },
    { id: "e1a40838-cfd8-41aa-93a5-c9b054841661", name: "INHolland University of Applied Sciences" },
    { id: "92305d7c-0321-43df-819d-46b9b0f44090", name: "INIDEM Business Law School" },
    { id: "6ddb7244-ac89-4989-90ce-3df68c1e64ee", name: "Inner Mongolia University" },
    { id: "fff2d274-5335-4847-9125-5b210297edab", name: "Innova College" },
    { id: "330a9860-8284-4e4c-9fe9-abcd07363ea2", name: "Innovation The Business School" },
    { id: "e0a92ade-88ac-455c-910d-ad68a2425f1d", name: "Inqba Escuela de Negocios" },
    { id: "acdd49c9-4497-4a95-bcde-aa6a54dbf18a", name: "INSA Toulouse" },
    { id: "7fbc74c7-8b06-4cb9-8c99-ecc33793643b", name: "INSEAD" },
    { id: "f79c8343-6709-4a34-8605-66745c0c7294", name: "INSEAD - Abu Dhabi" },
    { id: "5119f038-0c11-41c0-a754-6add073a0ffa", name: "INSEAD North America Inc" },
    { id: "b9bcee11-6aec-4d8d-ac2e-a1dc52676c58", name: "INSEAD Singapore" },
    { id: "9d742d04-3c28-40ac-b084-379c20fafb0c", name: "INSEEC Business School" },
    { id: "608cd4ed-29ed-4715-ac52-671eeac1d0bf", name: "Insight University" },
    { id: "07552411-8351-4beb-a524-50680eaee301", name: "Insitituto Nacional de Pos Graduacao INPG" },
    { id: "91179263-1781-4ca4-97c1-bd6a823cc97d", name: "INSPER - Instituto de Ensino e Pesquisa" },
    { id: "4b984c55-7fe4-4159-8b6d-08e0317430d8", name: "Inst De Admin Publica Si A" },
    { id: "b7e8e6f6-a1b0-415c-ab30-f8c99c038ee0", name: "Inst for the Intl Educ of Students" },
    { id: "4c910de4-921a-4649-9530-3dbfc5856b41", name: "Inst of Technology Blanchardstown" },
    { id: "631112d1-73d3-4491-8a23-f7673b4c29b5", name: "Inst of Technology Tallaght" },
    { id: "9edb437c-0adf-475d-a686-0673c0162bd8", name: "Instabul Bilgi University" },
    { id: "9556a02f-caf1-44f9-8b4b-9df745fd9ee4", name: "Instanbul Bilgi University" },
    { id: "be76ba71-5a86-4800-b37d-a60cbaa88de3", name: "Instititute for Integral Studies" },
    { id: "1b8766ed-4446-408a-8869-33ee0be4b27a", name: "Institucion Universitaria de Envigado" },
    { id: "8bc4af55-eea7-45c1-80a2-8ed0164cfe4c", name: "Institución Universitaria Esumer" },
    { id: "634151d1-aa01-4d0f-9bb8-8c25f7293de2", name: "Institut Aminuddin Baki" },
    { id: "830c5cc7-4bdd-4ec6-b880-ada1c921480f", name: "Institut Barcelona d'estudios Internacionals" },
    { id: "f7c57f1c-78b8-42c7-8d1d-167bb9e7201f", name: "Institut D'Educacio Continua (IDEC)" },
    { id: "0a084516-7e12-4d14-beaa-311dac54829d", name: "Institut D'Etudes Politiques De Paris" },
    { id: "f860459e-22f1-4529-9690-3d671ae4d84e", name: "Institut de Creation et d'Animation Numerique (ICAN)" },
    { id: "e8eff46b-8bd6-4fbe-9e09-ca8699ebc57e", name: "Institut De Developpement De Produits" },
    { id: "abba3a11-e33a-4dc8-a62d-7ed315caae72", name: "Institut de Hautes Etudes Internationales et du Developpement" },
    { id: "1039859c-2535-495f-b41f-1204043db3e1", name: "Institut de Tourisme et d'HÃ´tellerie du QuÃ©bec" },
    { id: "7199a03b-ea85-4c76-af72-d8d638deb012", name: "Institut D`Estudis Financers" },
    { id: "bff1a02b-a611-4a5d-8cbe-100a6d2158f9", name: "Institut Europeen de Formation IEF" },
    { id: "e5729c63-f25f-4944-af4c-09633123aa0d", name: "Institut Francais de Gestion (IFG)" },
    { id: "936e597c-20b6-4a16-a8ed-8091a7c1efea", name: "Institut Français de la Mode" },
    { id: "8db22e42-8c19-4cb5-96af-20ba59920686", name: "Institut fÃ¼r Management GmbH" },
    { id: "ba9be2b4-16eb-41f3-935d-18428a2fe7fa", name: "Institut Le Rosey" },
    { id: "0ca0ac5a-9722-4234-9bdf-2f0f182e5657", name: "Institut Mines Telecom" },
    { id: "62fe22d7-1e36-4acc-8d32-b7df27c2b051", name: "Institut National des Langues et Civilisations Orientales" },
    { id: "fe97c44b-6ab1-4d3c-8f4e-3f4f3c389d7f", name: "Institut Paul Bocuse" },
    { id: "42d28572-f8c2-4999-ac03-5882bb0e2168", name: "Institut PREPAVOGT" },
    { id: "ee4ad6c8-946a-45e9-9ccb-9893127af763", name: "Institut Sinaran" },
    { id: "97848969-6161-46fc-bf87-acea7d888acf", name: "Institut Superieur de Gestion" },
    { id: "63e08675-4032-4871-ada9-8f7e319e4473", name: "Institut Superieur Specialisé de la Mode - Shanghai" },
    { id: "4d8f9322-00e4-41e3-8b4d-2078d093f471", name: "Institut Teknologi Sepuluh Nopember" },
    { id: "4d5029db-bc7a-47c5-90a8-6bc9bb6c731e", name: "Institute at Palazzo Rucellai" },
    { id: "62344118-9a1d-4e37-babf-78b045bfb837", name: "Institute for Career and Personal Development" },
    { id: "962111a3-71e4-49bc-a05d-f5537a480d55", name: "Institute for China Business" },
    { id: "c36807e2-4592-4dea-bfe5-bc3de59f34cf", name: "Institute for Clinical Social Work" },
    { id: "1e6e4b3f-78bc-4d33-b370-67bd9fa81125", name: "Institute for Competitive Intelligence (ICI)" },
    { id: "66c30ac5-d3ad-4b64-b01d-38c0600bc1ca", name: "Institute for Competitiveness - India" },
    { id: "0ae046e4-60bb-43ae-b934-a4014bef6a5b", name: "Institute for Financial Management and Research" },
    { id: "5aba5330-3dbb-411a-88f7-66bc88213bd6", name: "Institute for Leadership and Communication Studies (ILCS)" },
    { id: "e61168c0-3598-439f-9001-059b16b42509", name: "Institute for Mergers, Acquisitions and Alliances (IMAA)" },
    { id: "52babf24-4ff7-45b5-b126-a0da9149f243", name: "Institute for Privatication & Management" },
    { id: "614aaaef-b691-4c3b-87d0-43b642dd41c6", name: "Institute for Tourism Studies" },
    { id: "2b9cd46e-e239-43d4-ae61-d5e12bac7b41", name: "Institute für Finanzdienstleistungen Zug IFZ" },
    { id: "0cd3f259-d5ad-43e4-acc2-5759c00f0e6c", name: "Institute of Accountancy Arusha" },
    { id: "cc2354bf-c2bb-4cce-9d01-f5bd0bf560ec", name: "Institute of Advanced Management & Research" },
    { id: "b4412d31-a47b-4614-93b1-716ec6450423", name: "Institute of American Indian Arts" },
    { id: "2b4b975b-1452-4539-af54-9931cb28d467", name: "Institute of Apparel Management" },
    { id: "43a83d0d-081a-450c-8f54-6ac019cca35c", name: "Institute of Applied Medicines & Research" },
    { id: "9c2a1944-eb9c-45fa-ba8f-aa744177ed92", name: "Institute of Bankers In Ireland" },
    { id: "f60ac668-ce7f-4e27-87f6-1f91ff7a2093", name: "Institute of Banking Studies" },
    { id: "17ce3674-1e80-4e97-9d49-2e7c81926beb", name: "Institute of Business Administration" },
    { id: "cf186c5c-11f9-4909-904b-70600584c7d4", name: "Institute of Business Administration (IBA) - Karachi" },
    { id: "a3ed4f06-6d67-4a27-a16e-538e675027cf", name: "Institute of Business Administration - Sukkur" },
    { id: "ee65a3dd-7e54-4aaf-ac45-10ba3c503dc9", name: "Institute of Business Development" },
    { id: "4d30a2c3-3f9d-4944-8012-c281cd677a04", name: "Institute of Business Economics" },
    { id: "fbb0d137-0176-46b9-8fae-4c4e56eb8840", name: "Institute of Business Management" },
    { id: "d3c2069b-18ce-414e-a613-3ce5f920a402", name: "Institute of Business Studies" },
    { id: "cfe316e4-06a2-4002-8492-508cb44ab867", name: "Institute of Business Studies & Research" },
    { id: "b750b58a-9ee9-4504-9494-f6098cbbdfcf", name: "Institute of Business Studies - Moscow" },
    { id: "11b42db4-04a1-4e69-946e-9c2eb0d19bb5", name: "Institute of Cancer Research" },
    { id: "d5a3e460-e5a3-4fc7-b0b3-3754534c3144", name: "Institute of Chartered Accountants In England & Wales (Icaew)" },
    { id: "f4e0a877-a26a-4aad-8dea-ba169289abd1", name: "Institute of Chartered Accountants of India" },
    { id: "27ddb139-dc29-405d-b912-95794b118743", name: "Institute of Commercial Management" },
    { id: "cbcdfb90-0e1e-4c7a-857e-1ed857b6e5be", name: "Institute of Corporate Directors" },
    { id: "9de98ed0-d8ec-4405-a321-5bc792eacc78", name: "Institute of Cost and Management Accountants of Pakistan" },
    { id: "36681453-7706-444a-ad53-6610dfb8a60a", name: "Institute of Directors" },
    { id: "96b4974b-d11d-42c0-8f3a-a8408a284e6c", name: "Institute of Economic Sciences" },
    { id: "dfee97f4-77fc-4532-9edf-a3b167a0c6e2", name: "Institute of Economic Sciences of the Polish Academy of Sciences" },
    { id: "b5bb7245-cd89-4038-9cfb-eb993a7e974f", name: "Institute of Economics and Crisis Management" },
    { id: "d78521d1-69b4-4676-b072-a8f59b3a868d", name: "Institute of Finance and Economics Mongolia" },
    { id: "e9cbaa89-a8c0-4604-b128-208124c9bc0e", name: "Institute of Financial Services" },
    { id: "c258cdca-3d4b-4aa1-b5ac-b091400e02e2", name: "Institute of Financial Studies" },
    { id: "ce203e76-0cf8-45d4-aefb-2bc3dcce33c6", name: "Institute of Hospitality Management in Prague" },
    { id: "5e9ab744-7e9e-45b5-8a97-6264571f50c0", name: "Institute of Human Resource Management" },
    { id: "cc8b4a1f-8bbd-4cf6-baf9-752b161f947d", name: "Institute of International Business Education" },
    { id: "21e73c25-1f65-4c45-9fd0-969ee2e220f6", name: "Institute of International Business Relations" },
    { id: "783a54e4-7566-4de6-a5ee-10835e8351c6", name: "Institute of International Trade" },
    { id: "bde12502-e7fb-4536-9570-ba94baabbb16", name: "Institute of Language Studies and Applied Social Sciences ILSASS" },
    { id: "f1a1ef38-5a32-48d7-a08e-1bac40465966", name: "Institute of Management and Global Education" },
    { id: "58515f3e-084a-4d24-8f71-7b7401ce385e", name: "Institute of Management and Information Science" },
    { id: "3c951642-fff7-4a92-b871-6687578dcc47", name: "Institute of Management Development and Research" },
    { id: "36a379d6-daad-4dd5-b3e9-58f4fbc4c413", name: "Institute of Management in Kerala" },
    { id: "8ab1099e-870b-4a78-a81c-b955e911e369", name: "Institute of Management Sciences" },
    { id: "91029790-d634-4369-9a9d-46ba84505946", name: "Institute of Management Studies (IMS) Ghaziabad" },
    { id: "e755a665-edcd-43a5-850d-a47545d2602f", name: "Institute of Management Technology - Dubai" },
    { id: "481be5ec-a3c5-4070-a899-8bd0abd445a9", name: "Institute of Management Technology - Ghaziabad (IMT)" },
    { id: "f3c5137b-8690-456e-b8ec-9c5ac2801200", name: "Institute of Management Technology - Hyderabad" },
    { id: "a80c144d-bc0a-4a20-9022-d57ba70a9ebb", name: "Institute of Management Technology - Nagpur (IMT)" },
    { id: "81f6ee0a-e913-40c3-a1cf-4ccd6fdbb8bf", name: "Institute of Management Technology Centre for Distance Learning (IMTCDL)" },
    { id: "1ccbcfde-f87f-47c5-a23e-446a72d1501a", name: "Institute of Management, Bratislava" },
    { id: "87e82b16-fdbf-4502-ae0f-21ebe9651189", name: "Institute of Marketing & Management" },
    { id: "e01dbe3b-a5da-42c5-9e5e-015809916b80", name: "Institute of Mathematics and Applications IMA" },
    { id: "1ab296fa-3fcf-445c-a33c-75ec004748ab", name: "Institute of Posts and Telecommunications Technology" },
    { id: "432c8ffd-4828-4294-8bfc-2bf5032a815c", name: "Institute of Practitioners In Advertisin" },
    { id: "580402a9-33d0-431f-a986-8102ece5562b", name: "Institute of Prodcution and Recording (IPR)" },
    { id: "eeee1f7a-7173-4dc5-a8a2-29ba7443e1b0", name: "Institute of Product Leadership" },
    { id: "0dbf1f25-df8f-45a3-a78f-656ce12ee738", name: "Institute of Professional Education and Research" },
    { id: "f89dd414-c9f8-4bea-b57d-0c1acddf5491", name: "Institute of Public Administration" },
    { id: "f229f447-d015-4366-9b06-40af357dc34e", name: "Institute of Public Enterprise" },
    { id: "533aba5a-8b5d-4a39-8384-3f83c99a017f", name: "Institute of Reading Development" },
    { id: "8ca96d69-939f-42c6-a4c7-fd9ae8184447", name: "Institute of Rural Management Anand (IRMA)" },
    { id: "20881853-5bbb-4a69-aafe-118f88c90ed5", name: "Institute of Science & Technology for Advanced Studies & Research" },
    { id: "05df13a4-4867-41e5-b630-476d6ed93c76", name: "Institute of Technical Education" },
    { id: "5487e2f3-9763-4d61-9dd7-9c66a5c2b97c", name: "Institute of Technology - Carlow" },
    { id: "9834288b-9256-40f8-b1d4-80aa0b4c3c91", name: "Institute of Technology - Tralee" },
    { id: "79032685-5872-4767-b189-5916585c2ab2", name: "Institute of Technology Blanchardstown (ITB)" },
    { id: "710943ba-a003-4203-a4c3-3b594ef4c8a1", name: "Institute of Technology Sligo" },
    { id: "f1c87d8d-c7d6-4f57-a4e3-5d545f3aebb9", name: "Institute Teknologi Bandung (ITB)" },
    { id: "ef2e6ef4-1dd9-4bf3-acd8-ce0bcc644aa9", name: "Instituto Argentino de Innovacion Social" },
    { id: "8a191cda-fed0-42cf-9481-f4cc77fafd05", name: "Instituto Brasileiro de Gestao de Negocios IBGEN" },
    { id: "a21dd644-d7ef-4e62-8e3b-4db1560935c0", name: "Instituto De Estudios Empresariales" },
    { id: "9bde41c0-135a-4ac5-8c75-c31cfceb6268", name: "Instituto De Administraï¿½Ï¿½O E Gerï¿½Ncia" },
    { id: "b51fd723-3b01-454e-9ef4-51f6be23d2fa", name: "Instituto de Desarrollo Directivo del Atlántico (IDDA)" },
    { id: "63d4488f-12b2-4019-a6d6-fd1277077f7e", name: "Instituto de Educacao Tecnologica IETEC" },
    { id: "6a85256a-9afe-4cc6-abfb-05423936b041", name: "Instituto de Efectividad Clinica y Sanitaria IECS" },
    { id: "43d8382d-86cc-439f-a948-7d48ba1ef906", name: "INstituto de Engenharia de Sistemas e Computadores" },
    { id: "f8c350ce-ac3a-40e4-85bb-af6129f16984", name: "Instituto de Estudios Bancarios Guillermo Subercaseaux" },
    { id: "94592d11-4c5b-4f46-9553-bdaef5268994", name: "Instituto de Estudios Superiores de Tamaulipas" },
    { id: "a9af448a-037b-4b5e-b892-9b524ceb8def", name: "Instituto de Estudios Universitarios (IEU)" },
    { id: "561f6ecb-f3e4-443f-bcd8-469b3f01741e", name: "Instituto de Formação de Líderes" },
    { id: "3cc5b056-9431-4380-a430-310ba24d59df", name: "Instituto de la Ingeniería de España" },
    { id: "8fddf732-d2bd-4a12-a3c6-a627fb07468a", name: "Instituto Educativo Economico Nacional IEN" },
    { id: "e144b9a2-3ef6-4a38-a835-f114e8ff2122", name: "Instituto Empresarial do Minho" },
    { id: "04d479b2-efde-42f7-9c77-990597d6abce", name: "Instituto Federal do Rio Grande do Sul IFRS" },
    { id: "ed384ae1-5d8f-4769-a939-b21319064893", name: "Instituto Federal do Sudeste de Minas Gerais" },
    { id: "64325683-f3c8-40b9-b326-5ec1d35dfdf5", name: "Instituto Germinare" },
    { id: "56850f63-7a10-4c91-98c4-777758ba8e21", name: "Instituto Global de Altos Estudios en Ciencias Sociales (IGLOBAL)" },
    { id: "ffd7ad7d-a18e-49ee-b260-55e649b17ad6", name: "Instituto Infnet" },
    { id: "01142c08-f73a-4869-9486-9e859b69bc77", name: "Instituto Internacional de Estudios Superiores" },
    { id: "c1d10a6b-8ce7-4560-9185-9d930305be29", name: "Instituto International de Educacao do Conselho de Presquisas em Educacao e Ciencias" },
    { id: "5c87b904-b784-4273-8c41-50c8ef79aaa2", name: "Instituto Mar de Cortes" },
    { id: "011b8109-a47f-417d-8b2a-bdda89b5f3bb", name: "Instituto Marangoni" },
    { id: "90a6b0c5-d57b-4d84-a098-c106d3e5bc4c", name: "Instituto Maua de Tecnologia" },
    { id: "7deced72-17ef-4b81-b5d1-875c74cc7bc7", name: "Instituto Nacional de Ciencias Medicas y Nutricion Salvador Zubiran" },
    { id: "cf6d6100-b474-4607-b2f8-06cc0cae5910", name: "Instituto OMG" },
    { id: "983ae711-02b4-4454-8528-cd6d4e94257c", name: "Instituto Panamericano de Estudios Superiores" },
    { id: "e9221d57-9090-4c17-8cd4-5d09830c56c1", name: "Instituto Politecnico de Braganca" },
    { id: "157aa923-b102-4fd6-82be-36d7d0b1388b", name: "Instituto Politecnico de Castelo Branco" },
    { id: "4c8390b9-f12e-4238-92c6-834a6ae939b1", name: "Instituto Politecnico de Leiria" },
    { id: "5ca8dfe7-0757-4bed-aa99-cfa0129e4b88", name: "Instituto Politecnico de Portalegre" },
    { id: "352f3876-9363-4327-bedc-4437c8609db6", name: "Instituto Politecnico de Viana do Castelo" },
    { id: "37476bac-79c7-496a-9c63-dc23531e4a75", name: "Instituto Politecnico Nacional (IPN)" },
    { id: "690894be-79ca-4155-9336-4320fa6c0943", name: "Instituto PolitÃ©cnico de Lisboa" },
    { id: "994d5d09-dfbb-4c75-882d-b2cbae848862", name: "Instituto Profesional AIEP" },
    { id: "d03d6cf9-d403-4d36-949c-baf9bd33bf15", name: "Instituto Rosedal Lomas" },
    { id: "f75e4429-6ae0-4fb0-8479-fc61c5659683", name: "Instituto Sanmiguelense" },
    { id: "bba08d8b-8c8c-4889-9c90-bbd53048132a", name: "Instituto Superior da Empresa (ISE)" },
    { id: "f283f5ec-c7fb-4434-bf49-290ca0cc6907", name: "Instituto Superior De Ciencias Do Trabal" },
    { id: "2680c0cc-2738-43a6-bf59-c85a4601bb69", name: "Instituto Superior de Contabilidade e Administração do Lisboa (ISCAL)" },
    { id: "8a070baf-3b36-490e-b56e-461f981ec36a", name: "Instituto Superior de Contabilidade e Administração do Porto (ISCAP)" },
    { id: "e0fc79e7-a236-426e-b0c4-446559c131e9", name: "Instituto Superior De Negocios Entrepreneur, S.C." },
    { id: "ee2d066d-fb7b-4661-bd16-dd7699e15c57", name: "Instituto Superior para el Desarrollo de Internet (ISDI)" },
    { id: "8d99500b-c97a-4091-abfc-a86177bcaab7", name: "Instituto Superior PolitÃ©cnico de Viseu" },
    { id: "fe8110c4-61a0-4e5b-af08-933d8da0eb27", name: "Instituto Superior Tecnológico Público Trujillo (IESTP)" },
    { id: "55629374-403c-4338-bae9-ed9830d1479e", name: "Instituto Superior Técnico" },
    { id: "5ebb6311-14e2-4009-97b2-9351935337c5", name: "Instituto Technologico de Cancun" },
    { id: "67ac56b0-f468-44a4-ba5b-8f193dd6b2d6", name: "Instituto Technologico y de Estudios" },
    { id: "a7a19fee-97aa-40f1-887b-c90a429482fc", name: "Instituto tecnologic de soledad" },
    { id: "ca9c02c2-db33-452c-8a7d-e62547887fce", name: "Instituto Tecnologico de Chihuahua" },
    { id: "d29aaa47-beda-4ea1-b3f2-34785c685f3b", name: "Instituto Tecnologico de Colima" },
    { id: "561fa78e-5ed8-443c-9d86-ca58c1ac1cd5", name: "Instituto Tecnologico de Costa Rica" },
    { id: "9b166707-8f17-4527-999e-12a50199d1d1", name: "Instituto Tecnologico de Durango" },
    { id: "c34b8423-2adb-4f46-b344-2d62e268b8cc", name: "Instituto Tecnologico de Hermosillo" },
    { id: "b923310f-05ab-4546-a24e-6bc2c82ac97f", name: "Instituto Tecnologico de Morelia" },
    { id: "e4dfc249-c858-4530-beab-008ab115633f", name: "Instituto Tecnologico de Saltillo" },
    { id: "a58fa557-b945-46f4-b21a-9d09aa1e3c6b", name: "Instituto Tecnologico de Sonora (ITSON)" },
    { id: "a1018492-50ba-4cb8-9c1a-f72ba81f1492", name: "Instituto Tecnologico De Telefonos De Mexico S.C." },
    { id: "25134f0c-638b-4671-98a2-69dc3c42aa38", name: "Instituto Tecnologico La Paz" },
    { id: "7b549f16-6b2a-41c1-add7-86b132d467a7", name: "Instituto Tecnologico Mazatlan" },
    { id: "f6de390f-cf6d-49c6-a029-a7d7cd8f4390", name: "Instituto Tecnologico Superior de Misantla" },
    { id: "b532fa51-6321-4164-991f-0e7279ba59ae", name: "Instituto Tecnológico de Acapulco" },
    { id: "2d878544-c9ab-4dd5-afb4-f297c9e3655c", name: "Instituto Tecnológico de Chihuahua" },
    { id: "24818b3d-e3e1-4633-a011-c911fa618eb6", name: "Instituto Tecnológico de Ciudad Madero" },
    { id: "b02cc48b-7b85-4eda-a7f4-0e1f57fc5dbe", name: "Instituto Tecnológico de la Laguna" },
    { id: "658fb399-a976-4f1c-85ab-78f30a1b68ac", name: "Instituto Tecnológico de León" },
    { id: "aeaafafd-8e90-4899-a789-094c432ad143", name: "Instituto Tecnológico de Sonora" },
    { id: "2d4135d4-f8da-49dd-bd47-88f057c57886", name: "Instituto Tecnológico Superior de Calkiní en el Estado de Campeche" },
    { id: "0c126661-e1b8-4da1-938d-3e76f90252a4", name: "Instituto Tecnológico Superior Sucre" },
    { id: "6be27ab7-a857-4788-a559-fe54b8f43f43", name: "Instituto Tecnológico Universitario Cordillera - ITSCO" },
    { id: "4fa45edc-3d8a-464f-b358-eb888102b055", name: "Instituts Saint-Luc Bruxelles" },
    { id: "599a6da6-5046-491c-a64a-e9864a6b0ad5", name: "Institutul De Administratie Publica Si a Afacerilor Din Bucuresti" },
    { id: "d3ddae53-6bb7-4359-bc2c-7bd783f7f7bb", name: "Insubria University" },
    { id: "caeecd7a-788f-4674-9b3b-bf686da65995", name: "Interamerican University of Puerto Rico, Metropolitan Campus" },
    { id: "43141d59-fe59-43f0-8833-0e5ab643b2de", name: "Interdisciplinary Center" },
    { id: "e1c8bbe9-1ec0-4dd7-874b-3da6c012f176", name: "Interise" },
    { id: "c544aec2-7c82-44a4-881c-6776f2fe80c3", name: "International Academy of Business" },
    { id: "23b405e0-6fd7-479f-a5e2-fe48269ac518", name: "International Academy of Design and Technology, Orlando" },
    { id: "5f354ce2-b760-4f95-bd90-2f9fa9e3690e", name: "International Academy of Media and Arts - Halle" },
    { id: "4aaa095f-09da-4c21-99a5-d47365ffc499", name: "International Academy of Sports Science & Technology (AISTS)" },
    { id: "fd16288d-20ca-4e2a-9421-364e44e6edc9", name: "International Anti-Corruption Academy" },
    { id: "c32b4da6-0b4d-4981-b4cc-9bf39d211d3d", name: "International Bible Institue of London (IBIOL)" },
    { id: "b1f4de84-7714-4220-8a5f-18559690a03f", name: "International Black Sea University (IBSU)" },
    { id: "14b24eca-5ca6-4875-8f78-6b7ec3140e4a", name: "International Business College Mitrovica" },
    { id: "c79dc730-c78c-47b2-9500-dc9a144e5125", name: "International Business School" },
    { id: "ff51c443-9188-4a52-a95b-4c322fed7ba9", name: "International Business School Budapest" },
    { id: "9db4a938-066a-491f-9832-1fe424ac8af6", name: "International Business School of Service Management ISS" },
    { id: "95586195-74e9-4b5d-90c2-9fb98c951957", name: "International Business School Vienna" },
    { id: "3c14a318-1c45-43bc-a631-581beffd9914", name: "International Center for Agribusiness Research and Education" },
    { id: "6de20ee0-e717-462c-b215-a1e286ea6c55", name: "International Christian University" },
    { id: "d4914b5f-b73f-44e3-b4ba-e4a5038fd4d5", name: "International Coaching Academy" },
    { id: "ee06e0e2-a0a8-4b66-8331-0068772b7321", name: "International College Bkst" },
    { id: "0a7a8ab8-c072-437f-841d-d2770a53a971", name: "International College of Economics and Finance" },
    { id: "4beb7a53-c52f-40b8-88fc-7810ba8308e4", name: "International College of Management, Sydney" },
    { id: "d54d1e42-1f58-43ba-9bd9-0e36d5ce0795", name: "International College of Naples" },
    { id: "cc27b0c1-18eb-487e-9e41-fb4c68bbd61c", name: "International Federation for Home Economics" },
    { id: "8f8b2bc0-4aca-4025-bc37-3b484976f5ab", name: "International Graduate Business School - Zagreb" },
    { id: "337ecbb9-dcd3-4004-8889-e89474b28406", name: "International Hellenic University (IHU)" },
    { id: "eaa4649b-bfe6-4068-b844-143676bcacb3", name: "International Higher School of Brand Management and Marketing" },
    { id: "2c241a15-9d01-4193-aa15-6f25844e20cb", name: "International Horizons College" },
    { id: "fa46f2fc-1b7f-4ff9-ab64-c4186fc6b5cb", name: "International Institute for Management Development (IMD)" },
    { id: "99ac4056-a583-42de-a9d9-970bd45e508e", name: "International Institute for Population Sciences" },
    { id: "2306e290-203b-4579-933a-8b64b677a8c1", name: "International Institute for Special Education" },
    { id: "f4d38841-1878-4525-9856-3b0164a6e633", name: "International Institute of Business" },
    { id: "9acdf685-324c-4a5a-a50c-22a403f27c0a", name: "International Institute of Business Management - ASBL" },
    { id: "46ec7e75-96bf-416e-bab5-002ccbe3bde3", name: "International Institute of Information Technology Bangalore" },
    { id: "ff36ada1-4666-479d-9726-1349bfb35683", name: "International Institute of Management and Technology Studies (IIMTS)" },
    { id: "b97a07f3-de7c-4334-8c89-6e3227a8eb01", name: "International Institute of Management Technology (IIMT)" },
    { id: "46910db2-9b03-460f-879f-f86e1d26ebd2", name: "International Invoicing Test" },
    { id: "2db28d88-2dac-4988-ac62-2ba4cf7728ea", name: "International Islamic University Islamabad" },
    { id: "eca6774d-f997-44b1-bb39-9b705d912e8a", name: "International Islamic University Malaysia" },
    { id: "fd60a8d6-100e-46d3-99eb-e1027b2256c9", name: "International Leadership University" },
    { id: "a0dc4883-c413-4bcd-8232-59523ba8e75b", name: "International Management & Finance Academy" },
    { id: "97c6a02d-7b48-4d4a-81c2-8fcb1cd878ae", name: "International Management Institute (MIM-Kyiv Business school)" },
    { id: "38cf102d-4427-4db4-89da-f9375e84beef", name: "International Management Institute - Bhubaneswar (IMIBH)" },
    { id: "52bf65be-9c91-409c-9ada-c119498512dd", name: "International Management Institute - Kolkata" },
    { id: "0389410b-0b19-48b3-a8dc-a818146e567f", name: "International Management Institute - New Delhi (IMI)" },
    { id: "00eec788-612b-4555-8a59-b570f94e1782", name: "International Management Institute St. Petersburg" },
    { id: "bb10ba79-e650-4d87-b8b2-41f821cc4ddb", name: "International Management School Geneva" },
    { id: "6505629b-f3bc-4c2f-ba77-9b736a233919", name: "International Prof Managers Assoc" },
    { id: "5a8fcffa-b32f-457e-8388-12b86e2b3673", name: "International Professional Managers Association (IPMA)" },
    { id: "9dd08a8e-2487-45e3-a206-c80f89b2cc6b", name: "International Real Estate Business School" },
    { id: "352d4e52-f67c-4ad6-b169-ecf912d16ca4", name: "International Sakharov Environmental University" },
    { id: "ea7c1c2c-9549-438d-a6b5-4d01f30b9ca0", name: "International School for Social and Business Studies" },
    { id: "d5b7334c-c038-4c79-962e-488067cc874e", name: "International School Hilversum B.V." },
    { id: "1fa87b66-013f-4e00-89c4-111e7e13b878", name: "International School of Business" },
    { id: "4121f9b8-ca21-4947-a178-d7c198304c84", name: "International School of Business & Media" },
    { id: "2a8e98c4-57be-4ec3-8893-246aa696d389", name: "International School of Human Capital Management" },
    { id: "5a5a8dc7-0116-408d-88fb-1524075ef399", name: "International School of Lausanne" },
    { id: "5a88a0d4-621e-4722-998f-adfa312b5c0b", name: "International School of Law and Business" },
    { id: "e21eb42a-70d9-42a9-a628-24c424c5bdf3", name: "International School of Management (ISM), Paris" },
    { id: "2b8f34f1-fde5-4b5b-b415-1bd9bf806094", name: "International School of Management - Dortmund (ISM)" },
    { id: "b3669dc7-0353-44a1-b3d3-800d0c8bc7fd", name: "International School of Management - Frankfurt" },
    { id: "f96b6efa-e7ed-4cca-b202-59a756e3b50d", name: "International School of Management Excellence (ISME)" },
    { id: "ae30b916-9513-4b66-9517-c0318530b0ac", name: "International School of Nanshan Shenzhen" },
    { id: "8049e5b3-b99e-43b1-9ca2-8a97fb7e09b5", name: "International Space University" },
    { id: "1a4f6b56-c0ca-4cf0-841d-991c61a65845", name: "International Sports Sciences Association" },
    { id: "b3f2a256-a617-4d89-aeae-1e16f792694d", name: "International Technological University" },
    { id: "435d9a5d-b762-40dd-bb95-5d8b268e2485", name: "International Turkmen Turkish University" },
    { id: "736da691-c935-45f7-aeea-22e81e69d1b5", name: "International Univ of Health and Welfare" },
    { id: "7abf03e6-63ee-4f62-bd37-c9dde97d13f7", name: "International University" },
    { id: "0854a42b-ee97-46bd-a4b5-53afacbfaa9a", name: "International University Geneva" },
    { id: "c3d9a0ed-b2c4-42e9-8cca-25a5b27d7f95", name: "International University Germany" },
    { id: "ce4cffb6-4df5-4d62-98c7-b21090fe224b", name: "International University College" },
    { id: "16dad17c-172a-4ced-b8bd-d91b066f3e8e", name: "International University Institute of Luxembourg" },
    { id: "e6c43231-0173-49df-8146-21908d7f8802", name: "International University of Catalonia" },
    { id: "0f9a804d-8766-4b31-aef3-305938896bbe", name: "International University of Grand Bassam" },
    { id: "06762e14-fc5e-4956-aa36-10064807cb74", name: "International University of Japan" },
    { id: "df75ce7a-77ac-4735-b12a-987d4439cf16", name: "International University of Monaco" },
    { id: "9d9ff32f-7094-46ef-b6d6-3dda9c17b232", name: "International University of Sarajevo" },
    { id: "031eb3df-3b49-45bb-9ea1-f9a6fe37f572", name: "International University of Valencia" },
    { id: "b6cfb6b0-41f6-4fd2-8827-9d25ea24f5c6", name: "International University Southern Europe" },
    { id: "05ef72fe-3b7a-4e53-8576-0ea2e74f1139", name: "International University Ho Chi Minh City" },
    { id: "2c3a9258-42dc-446d-99e0-470d0f4d48d8", name: "Internationale Berufsakademie" },
    { id: "4c0847c3-5d24-4762-9aee-bc729021e2ec", name: "Internationale Fachhochschule" },
    { id: "11ca3be0-ffe8-4147-9a68-369dfd240100", name: "Internationales Hochschulinstitut Zittau" },
    { id: "97d926c1-a358-4770-a671-599d69135246", name: "Interscience Institute of Management & Technology, Bhubaneswar" },
    { id: "8b2dc201-8e9b-4c10-b390-706dbb554640", name: "INTI International University and Colleges" },
    { id: "65cf6db7-75a3-4b26-a957-fa86b21f725a", name: "Intl College of the Cayman Islands" },
    { id: "29baffe1-6e30-40fd-99e7-6023ff3d8794", name: "Intl. Medien Service Gmbh" },
    { id: "39ab8e3b-33d0-4b19-82f1-e299445a1880", name: "INTO University" },
    { id: "bbbd8432-da9e-4b64-a9a6-f3b251a27a0b", name: "Inustrial College of Armed Forces" },
    { id: "989beda6-73ac-456a-9361-fc25812b54c2", name: "Inver Hills Community College" },
    { id: "3a52b3d0-8957-40d1-a014-cd41a21175ec", name: "Inverness College" },
    { id: "5069fcec-3133-4502-ae31-d8d96d387026", name: "Invertis University" },
    { id: "96e56d1f-68c9-48ff-9042-878f24e7c620", name: "Investigaciones Y Estudios Superiores S." },
    { id: "dbeb0179-83e8-48df-94a3-4f737cd2b90c", name: "Investment Banking Institute" },
    { id: "e3323f69-b11b-4d5a-b2a2-80f147446f05", name: "Invoicing Test" },
    { id: "1d69b5ad-3508-4c39-86e5-91236e269cd8", name: "Intitut za Ekonomska Raziskovanja" },
    { id: "d9a4d84f-812b-4337-93d4-fce444ea15e9", name: "Iona College" },
    { id: "d7e04747-19d6-486f-948b-73e3a42ed55e", name: "Ionian University" },
    { id: "fda50414-7c06-47ee-84b0-b2081f05cbba", name: "Iowa Central Community" },
    { id: "0a14691d-293e-4d43-a1bf-a616db8621c2", name: "Iowa Lakes Community College" },
    { id: "e4bf12de-2ace-44ea-b158-16f408f7343e", name: "Iowa State University" },
    { id: "0577e81f-192d-499a-b45d-9624f67e88c7", name: "Iowa Wesleyan College" },
    { id: "166c6aec-d10e-4a59-96c8-2f7d2dda5645", name: "Iowa Western Community" },
    { id: "e970c280-1e03-4451-bef6-d073da161917", name: "IPAC France" },
    { id: "817ca2e6-b8fc-4c1b-b439-b011feda45a4", name: "IPADE Mexico" },
    { id: "1484bdd1-f4e0-4f8d-8ad4-b5e06398977f", name: "IPAG" },
    { id: "83039fdc-d59b-4164-9ce4-0747a6e18e9e", name: "IPAG Business School" },
    { id: "bd362e92-eb6e-4c89-ada9-3a807c2fb57e", name: "IPAG Nice" },
    { id: "9b0218ab-521b-444f-b336-75596324ab53", name: "Ipag Paris" },
    { id: "d78d8d5b-325a-4bdf-a5de-b10f2b477ebb", name: "IPAM - Instituto Portugues de Administracao de Marketing" },
    { id: "561fa5a5-f805-4a86-9370-639605408602", name: "IPC Tertiary Institute" },
    { id: "59d46245-0855-4a73-a384-2b6df756f1fc", name: "IPDT" },
    { id: "5edcea30-8181-47a6-a38e-05d10ffe7d6e", name: "IPFW Bookstore" },
    { id: "097cae82-3cfa-4462-9259-f01de5a20d74", name: "IPM Business School" },
    { id: "caa1d561-3218-47d7-8cde-596514d32d26", name: "IPMI Business School" },
    { id: "f62a2bc5-2449-4e7c-83da-b1307e3174d9", name: "IPU Tertiary Institute New Zealand" },
    { id: "e02593ea-5f45-46aa-89a3-5f49b0073d3b", name: "Iqra University" },
    { id: "fc84d3e5-0bd0-4595-9a74-5b0ca928e200", name: "IQS - Institut Quimic De Sarria" },
    { id: "9997f578-6daf-48cc-a86d-9bd9fe5843ae", name: "Iran University of Science and Technology" },
    { id: "890bb560-32b5-47f7-b993-5d51f399a417", name: "Iranian National Center for Knowledge-based Management" },
    { id: "49de15e8-8aa9-40a6-b27a-816dd34cc4cd", name: "Irish Management Institute" },
    { id: "2cc931bb-dcd8-44bd-805c-c694d2d0db47", name: "Irm College" },
    { id: "54cf9e7c-1a82-4d09-b991-9588d3de78d8", name: "Irvine University" },
    { id: "de1693be-c29d-4542-9619-229f96eb07a8", name: "Irvine Valley College" },
    { id: "50aeb83b-f0aa-4228-a9d5-1b25eb89d7de", name: "ISAM International School of Agri Management" },
    { id: "de1f22ec-4d56-4aad-aa0d-001e215fc6b1", name: "ISARA" },
    { id: "d32997a0-85ff-4c99-b360-ae1bff9048c5", name: "ISBR Business School" },
    { id: "af1bbd51-e773-4fe4-8840-ebc0fde8b6b5", name: "ISC Paris" },
    { id: "60ba17af-4513-4bac-808c-2399fa76140d", name: "ISCAE Casablanca" },
    { id: "a0b696bf-9627-47d6-9150-76d6bff8d577", name: "ISCET (Instituto Superior De Ciencas Emp" },
    { id: "9d9ba106-ac61-41c4-82c8-96f950d51530", name: "ISCTE Business School" },
    { id: "cacc58ef-5c8d-4f41-9522-348d6e599a6b", name: "ISCTE - University Institute of Lisbon" },
    { id: "f65fe5f8-d60d-4188-b69f-2ad9bbd62a63", name: "ISEAD Global" },
    { id: "dbe8b3cb-5de2-49a2-9089-4286408fc753", name: "ISEADE - FEPADE" },
    { id: "21037ef5-3d9d-48f6-aee6-499276498cd6", name: "ISEC - Instituto Superior Educacao e Ciencias" },
    { id: "5f667df6-fdd4-4ce5-9316-43b40f226dd9", name: "ISEG - School of Economics & Management" },
    { id: "e5b82daf-2caa-4988-a7c6-c1304756b7c7", name: "ISEL - Instituto Superior de Engenharia de Lisboa" },
    { id: "8dd73452-4352-45f0-b2c9-1afb0f36860a", name: "ISERJ" },
    { id: "041ea5fd-1700-450b-bcd0-d1539093422d", name: "ISG - Ecole de Commerce" },
    { id: "98f3d626-b9e3-4e36-88c1-853fa67d6db7", name: "ISG - Instituto Superior de Gestão" },
    { id: "5b7be6e2-a973-46ab-9b84-60270a8a08b1", name: "ISG International Business School" },
    { id: "bc620c94-752f-4b01-bc37-ef816ee57fbe", name: "Isik University" },
    { id: "950729e9-8c75-4278-b255-ea1c0134ce1b", name: "ISIT Paris" },
    { id: "3083a7d7-9733-4d08-bf0d-99843faeaac2", name: "Isla Campus Lisboa" },
    { id: "74a92f9e-5565-4593-99a9-c223308a1783", name: "ISLA Gaia" },
    { id: "74559ae2-56c0-45b7-bf6d-de63e884c49e", name: "Islamia College University Peshawar" },
    { id: "0cbd0d6b-649e-4b03-a344-dcc9d7947dca", name: "Islamia University of Bahawalpur" },
    { id: "d3537cf2-f6c9-40bf-9469-6a4e777017c7", name: "Islamic University of Gaza" },
    { id: "23024351-dc2c-434c-a34c-a36498312d3d", name: "Islamic University Sultan Azlan Shah (KUISAS)" },
    { id: "30b15816-748a-47fd-90ff-f4a321d592ce", name: "Isle of Man International Business School" },
    { id: "3d4e2647-44b5-4afa-939b-f717ec36b704", name: "ISM Patna" },
    { id: "6b069bc5-77fc-4709-9564-41cc763e8d54", name: "ISM University of Management and Economics" },
    { id: "a08e81a1-82ed-4475-9894-506306b53809", name: "ISMA Secondary School" },
    { id: "1fc53d14-215d-4378-9bab-65dd83e9b649", name: "ISMAI - Instituto Superior da Maia" },
    { id: "df58e989-d14a-468f-ab15-3b038b630c85", name: "ISMT Business School - Mumbai" },
    { id: "bb51f463-e769-4c36-a2c4-ce5fdba3a6a1", name: "ISO New England" },
    { id: "3b7da28c-b70b-4b5e-b1a6-2ef0bb1700c4", name: "ISPA - Institute of Applied Psychology" },
    { id: "a306d452-6068-4c68-acc9-4f8e79db99b1", name: "Israel College" },
    { id: "36a93a6b-f1b0-4f46-8253-41d9620b8293", name: "ISS International School of Business Management" },
    { id: "f0f660f1-9efc-4284-8b5d-d79dc6da81b9", name: "Istanbul Arel University" },
    { id: "e6bd27aa-ecac-42bd-8adf-f15bed9ee262", name: "Istanbul Aydin University" },
    { id: "ac616a58-4326-4b39-823a-5bdc2ee573c8", name: "Istanbul Bilgi University" },
    { id: "43195b6d-f2c7-499b-b2a7-888d6e0f2325", name: "Istanbul Business School (?BS Türkiye)" },
    { id: "71d2a05f-54d6-45b6-bd83-293d3a76d3d2", name: "Istanbul Commerce University" },
    { id: "385553d2-8d9c-4b67-93e6-5597831fdd39", name: "Istanbul Kemerburgaz University" },
    { id: "97d5d358-429e-4738-bafd-b948a544a3f6", name: "Istanbul KÃ¼ltÃ¼r University" },
    { id: "9ec56316-69db-46ec-a4b6-6b184931ed0b", name: "Istanbul Medeniyet Universitesi" },
    { id: "8cdcb2e9-2f1d-444b-afa4-7fc3427aa30c", name: "Istanbul Sabahattin Zaim University" },
    { id: "a0bb85c2-bea8-4533-a57f-e81b5bc5ce98", name: "Istanbul Technical University" },
    { id: "d9ddc50e-afd3-4e13-b3de-8c0db173cead", name: "Istanbul University" },
    { id: "a8de7f4b-9dbb-4129-a6ac-0d4b19001cdb", name: "Istanbul Zaim Universitesi" },
    { id: "67853122-8337-4617-8794-9f3d2e1c6974", name: "ISTEC Paris" },
    { id: "0c15b73a-c66c-4db1-a839-3e59df0fd781", name: "Istituto di Management" },
    { id: "84d06ac4-dfc4-4fde-93a9-b30b31bed347", name: "Istituto Europeo Di Design" },
    { id: "80c2e9a1-4618-481c-95e2-8dde72bef462", name: "Istituto Marangoni" },
    { id: "22d9f8e7-186c-4401-87d8-6fc40a3677c7", name: "ISTUD Foundation" },
    { id: "966b66aa-3121-4d57-b5ed-eef7f7713e27", name: "ISUGA - Institut de Management Europe-Asia" },
    { id: "a9b20c15-473b-4e38-8ac7-93c969389ee7", name: "ITA - Instituto Tecnologico de Aeronautica" },
    { id: "d656cd4a-c017-4065-b872-92d9b27b20d6", name: "ITACA Escuela Superior de Negocios" },
    { id: "5542174b-2524-477a-825d-76178f3e8f40", name: "ITAM Mexico" },
    { id: "7c4395ef-9d59-4333-bf54-9d70c2ecd071", name: "ITBA - Instituto Tecnologico de Buenos Aires" },
    { id: "7db215de-e36f-4f2e-b75f-2ec438ebd889", name: "ITE College Central" },
    { id: "d23b6063-40cc-452b-9f89-70440b55efa0", name: "ITESO - Instituto Tecnológico y de Estudios Superiores de Occidente" },
    { id: "8cec38d3-3a3a-4023-9737-826ca9b645a3", name: "Ithaca College" },
    { id: "3b5a9c1e-feed-4e2d-855d-146e0334c8fc", name: "ITI Technical College" },
    { id: "1381d1d7-815b-4685-863e-447720d32020", name: "ITM Business School" },
    { id: "f452aa78-89eb-46d0-a289-ecf189584fd9", name: "ITS - Management & IT Institute" },
    { id: "79662f78-8f1c-4d9c-8e3d-b40f674cc1b9", name: "ITT Dublin" },
    { id: "e62168bc-c41d-4e24-8735-4c1ade4480da", name: "ITT Technical Institute" },
    { id: "0eb61b7e-badd-4eca-85d2-89fe80a7337d", name: "Ittihad University" },
    { id: "75f27ff2-8c39-415a-862f-f66352dff95a", name: "IU Information Management Innovation Professional University" },
    { id: "1100f2ac-e8cd-438e-bd00-06f39424ea4e", name: "IUBH Internationale Hochschule GmbH" },
    { id: "b0ae738f-dac9-49bd-bbfb-d006c358c5a1", name: "IUBH School of Business and Management" },
    { id: "dfc645d3-2042-41d9-8200-49b4cff4b457", name: "IULM University of Milan" },
    { id: "a4e9dc93-9895-4e62-a590-9b3d560c489f", name: "IUS Bookstore" },
    { id: "57eb1cf5-a5d4-4aee-8403-5009f0591ccb", name: "IUT Beziers" },
    { id: "f5f967ab-26b6-4bb6-b726-11284bf662ce", name: "IUT Nice Côte d'Azur" },
    { id: "98f1db3f-0423-45da-b29b-ec645161e12d", name: "Iut Sceaux" },
    { id: "7b935ee2-1529-47ba-ba16-7f48278a6628", name: "Ivan Franko National University of L'viv" },
    { id: "d56c71f8-2306-4eb4-a651-09ccb3f7a8dd", name: "Ivane Javakhishvili Tbilisi State University" },
    { id: "d2e00865-d37f-4408-8cf6-358df4ed31c2", name: "Ivey Business School" },
    { id: "b39fb1e6-cdd8-4184-9dcb-f9436be84da5", name: "Ivy Tech" },
    { id: "d6f46024-80ef-4b3e-b83c-78377d01dc1d", name: "Ivy Tech College" },
    { id: "7edfc3f7-d731-424e-a41b-47ddf467b9d8", name: "Ivy Tech Community College" },
    { id: "e3c00efa-3535-4d9f-a4ef-c5b99af82cc0", name: "Iwate Prefectural University" },
    { id: "a33f10bf-8d1e-4dfb-8d26-dcf1d02586c4", name: "Izmir Ekonomi Universitesi" },
    { id: "911482f0-bc82-4edb-bdd3-a73eff9cb074", name: "Izmir Institute of Technology" },
    { id: "2d69bed7-e60c-4abb-a187-108c1130abea", name: "Izmir Katip Celebi University" },
    { id: "34774a35-f0f4-4733-963b-ebc6c0099ace", name: "Izmir University" },
    { id: "afb46b19-93c8-40a8-8b03-860fac350567", name: "Izmir University of Economics" },
    { id: "57ee8f94-f831-467f-aae6-72b2ad762206", name: "J. F. Oberlin University" },
    { id: "9850b393-24e7-4335-8f36-c2fc66f9ffcc", name: "J.P. Dawer Institute of Information Science & Technology" },
    { id: "a01cc685-2506-4bcf-8dc4-ef711fb45a1b", name: "Jack M. Barrack Hebrew Academy" },
    { id: "1a2abd7c-51f2-430b-aa56-bcb5a3e687d8", name: "Jackson Community College" },
    { id: "7d37cecc-0e46-4326-b29f-c4f8fad95337", name: "Jackson State University" },
    { id: "45da607b-8ed9-450a-a044-a98f6690dc0a", name: "Jacksonville State University" },
    { id: "2e8a81c4-4294-4da4-8e6b-51d0702ea932", name: "Jacksonville University" },
    { id: "9dd70ea1-471c-4d4f-b66e-5cf7593b8be7", name: "Jacobs University Bremen Ggmbh" },
    { id: "3e538986-2a98-451e-b334-8351e5675a3f", name: "Jade University of Applied Sciences" },
    { id: "cc435043-57da-45c3-9d12-7275e3ae9ff5", name: "Jagan Institute of Management Studies" },
    { id: "1a818455-a32f-42cb-93bc-b744d9b293c3", name: "Jagiellonian University" },
    { id: "e941992b-3fa4-4800-af59-304d23cc711d", name: "Jagran Institute of Communication and Management" },
    { id: "44f597e8-67be-4719-a94c-982bb6b9d751", name: "Jagran Lakecity University" },
    { id: "2a130203-5244-491f-81f2-6710743a9b70", name: "Jahangirnagar University" },
    { id: "420434e7-bef4-4cbb-a07b-1c0d0dfa7dba", name: "Jai Narain Vyas University" },
    { id: "5daf480d-5383-49ae-9eb5-8c82b207be4c", name: "Jain University" },
    { id: "4426232e-dcbe-4915-89ef-29952a48a66d", name: "Jaipuria Institute of Management - Jaipur" },
    { id: "9a6ba6e7-37bc-4933-bef3-a1e649a9a534", name: "Jaipuria School of Business" },
    { id: "bf7b268e-9acc-4605-b5c5-2bbca423899c", name: "James Cook University" },
    { id: "6f9e01e2-8ec1-4769-86d2-85d4fa6b77d7", name: "James Madison University" },
    { id: "6e2be8d4-a8d8-4591-81e0-1b2a32b665e6", name: "Jamestown Business College" },
    { id: "e9ba61fa-76f5-4e2f-a7a9-1e2258eb99a4", name: "Jamestown Community College" },
    { id: "5623cad9-abe5-4f09-9551-cdf2060a20f5", name: "Jamia Hamdard University" },
    { id: "3f6fd915-e11b-4b75-9f00-17b192d4111e", name: "Jamnalal Bajaj Institute of Management Studies" },
    { id: "4669454c-db69-44df-bd8c-139b0054d3a4", name: "Jansons School of Business" },
    { id: "31eebd62-1934-4a3a-aa06-cbe1fc42257b", name: "Jawaharlal Nehru Technological University" },
    { id: "29980a67-16e4-4152-bfac-b3f21b70daad", name: "Jawaharlal Nehru Technological University Hyderabad" },
    { id: "7b2b34e3-f031-4abc-b9f6-3206aa5b299a", name: "Jaypee Institute of Information Technology" },
    { id: "bbe12eb7-970f-4c78-87bf-74cc6cdf8b64", name: "Jefferson College of Health Sciences" },
    { id: "7305b30a-fc08-4d77-81fa-84a03fb9fa28", name: "Jefferson Community College" },
    { id: "43651ae3-dcb5-4f03-9a96-426d672fefd1", name: "Jerusalem College of Engineering, Chennai" },
    { id: "1825e033-68ad-48fe-ab28-97ad9186fa9e", name: "Jerusalem College of Technology" },
    { id: "9f3a22b0-08a4-432c-a25e-b956c63e3346", name: "Jethro Leadership and Management Institute" },
    { id: "702ee7b8-f3c8-4fb5-8f84-6903e41fa7c7", name: "Jewel and Esk College" },
    { id: "15af4a08-c2e6-4085-9b28-28c3388b4dbb", name: "Jiangsu University" },
    { id: "837ab7b0-c74d-40dd-9abb-4ed003e663b1", name: "Jiangxi University of Finance and Economics" },
    { id: "fd1ed2a0-6bac-4391-b09b-3c2e5de5dbf7", name: "Jiaotong University" },
    { id: "ece067ed-0d13-44e4-8d93-1825fe953706", name: "Jikei University School of Medicine" },
    { id: "bf82a197-4a95-4e7d-bfbd-1adfde1be3c4", name: "Jilin University" },
    { id: "55e6eab7-fdd5-40a6-a1be-60f8fdd01085", name: "Jimma University" },
    { id: "5e811aa5-08da-4680-b313-b85e4581c192", name: "Jinan University" },
    { id: "71f96986-34de-4f5c-bf14-399284e81e39", name: "Jindal School of Banking & Finance (JSBF)" },
    { id: "41a1ab2c-46f8-41b8-9b77-86f4b4505490", name: "Jinnah University for Women" },
    { id: "8bccfb5d-a465-4a8c-b086-4fa630a32a4f", name: "Jinwen University of Science and Technology" },
    { id: "00c7667f-2180-414c-b9eb-8cbd3bb536d7", name: "Jio Institute" },
    { id: "f26f357b-e57a-4143-b48f-95ecb04a7d67", name: "Jissen Women's University" },
    { id: "3e4c8805-20cf-40cb-8ebb-e2d2bcba1f8d", name: "Jiwaji University" },
    { id: "a9d2fc84-02c5-4ddf-a3cc-e7ad8beb9bf9", name: "JJ Strossmayer University - Osijek" },
    { id: "317b4806-e756-44f9-82ab-b204bf38bedd", name: "JK Lakshmipat University" },
    { id: "39acc859-f6d3-463a-94ac-84eedb8571f3", name: "JMIT Seth Jai Parkash Mukand Lal Institute of Engineering and Technology" },
    { id: "9e324d1e-451e-4c3c-96ef-8f29a6edb881", name: "Jnana-Deepa Vidyapeeth Pontifical Institute of Philosophy and Religion" },
    { id: "cf5df2cd-c54e-460e-9a40-0904f52287ca", name: "Jobu University" },
    { id: "3a411359-42e3-4ef8-8654-187d96eddef3", name: "Johan Cruyff Institute" },
    { id: "02fbc761-e79e-4555-b1b7-bd514741311f", name: "Johanna Marius Languages" },
    { id: "9834a8d2-fb50-419c-935e-dff64fefe220", name: "Johannes Gutenberg University - Mainz" },
    { id: "32fe7b04-3ca3-4a3e-a39f-7d5f81f32815", name: "John Abbott College" },
    { id: "635b4cee-3b45-4b35-be5a-f70b32101a79", name: "John Brown University" },
    { id: "e6d31d3d-9403-428d-aa2e-2917640ca0ac", name: "John Cabot University" },
    { id: "4be15bce-27d1-4502-9ac5-dffa5fffbd0e", name: "John Carroll University" },
    { id: "a7854dfe-c94a-4dcc-a5e7-16afc42b52aa", name: "John F. Kennedy Library & Museum" },
    { id: "63193330-f7a8-42aa-a919-968740b1b175", name: "John F. Kennedy University" },
    { id: "3848b62f-11f3-4b39-8c7e-b8dff4bc5073", name: "John Marshall Law School" },
    { id: "05dfce9f-d41f-4154-9ee4-d6ac436fa026", name: "John Paul the Great Catholic University" },
    { id: "42053ff8-4390-4143-82e4-dce355a6881d", name: "John Tyler Community College" },
    { id: "a5b67a47-645e-42f4-a235-2f939e08b673", name: "Johns Hopkins Bloomberg School of Public Health" },
    { id: "1c1d84bc-575a-4252-8ebb-80f44c6cec29", name: "Johns Hopkins University" },
    { id: "31cbdaec-4afe-4d34-8774-6d34e027820f", name: "Johns Manville Sales GmbH" },
    { id: "3974fe40-f3b3-4826-8e14-9146419e1519", name: "Johnson & Wales University" },
    { id: "67194c53-430d-4e6f-891a-7e311eb71292", name: "Johnson C. Smith University" },
    { id: "0a17e763-d616-4347-83d9-476eaf33b42b", name: "Johnson County Community College" },
    { id: "84d3cde1-4181-4f48-b960-c9c50c3b0c33", name: "Johnson State College" },
    { id: "bf9d3a10-2242-4d7f-90e5-92199199ad6c", name: "Johnson University - Florida" },
    { id: "05cc1535-19c0-4c8d-bb67-e4c7a318ab31", name: "Johnson University - Tennessee" },
    { id: "8c9dfbed-3b3f-4da8-9647-584c98269b14", name: "Joliet Junior College" },
    { id: "87f0ebb0-44c9-42cb-b0d0-9ce330b0fbff", name: "Jomo Kenyatta University of Agriculture & Technology" },
    { id: "5ef14eed-cf9a-46fa-9ae4-b81a486a29bd", name: "Jones College" },
    { id: "8e43ec31-7328-4677-b8b5-a817f347500a", name: "Jones International University" },
    { id: "c3e8e24f-389b-4174-9186-179a32b3b2b8", name: "Jonkoping International Business School" },
    { id: "b6db554a-2cf1-42c2-97c5-94dbe7bbad4a", name: "Jonkoping University" },
    { id: "0786ace2-8d57-4104-8aad-c77bcdf6a806", name: "Joongbu University" },
    { id: "a72e2bdc-3f17-4960-b6b1-1964e8b614b4", name: "Jordan University of Science and Technology" },
    { id: "2d0c2340-f9ab-4686-9564-9cd19dad36b3", name: "Jorge Tadeo Lozano University (UTADEO)" },
    { id: "906e6aff-e8dd-4f72-a07e-85bd5b4582b4", name: "Josai International University" },
    { id: "197255c8-5b9c-42af-8ef7-98f6716b7b7d", name: "Jose Rizal University" },
    { id: "59a0436e-ff6d-4653-8597-63a613be95ef", name: "Joseph Business School" },
    { id: "fe46ba3a-34bb-46b4-8819-f1e62919a6da", name: "Josip Juraj Strossmayer University of Osijek" },
    { id: "51ab6390-c2b0-4698-b215-8f7316b6869a", name: "JP Morgan Chase - Illinois" },
    { id: "6308ea7a-582b-498f-9ff9-767a03c0b753", name: "JRE Business School" },
    { id: "ad752e1d-19b3-4181-8921-4dd26bd97c15", name: "JSN School of Management" },
    { id: "8f503359-9733-4d20-b4fb-b77fd90e4324", name: "JSS Academy of Technical Education" },
    { id: "fbc3229f-8dee-49a6-a833-40e0608683c4", name: "Jubail University College" },
    { id: "179eb3e1-5a91-4953-9395-291ae6288a98", name: "Jalan Universiti, Bandar Sunway" },
    { id: "836a6f84-d4f9-457a-8a24-6210d9b0e932", name: "Judson College" },
    { id: "5a6bff8e-54d6-4494-9186-f5ad0a1077c8", name: "Judson University" },
    { id: "ed4fd637-334c-4bb4-9f3a-962c502c9a2a", name: "Jumeira University" },
    { id: "bcc3ac06-4519-40e2-80fa-51b6920d7465", name: "Jumonji Gakuen Women's University" },
    { id: "9e1868ab-cc15-47b9-a636-f7aedb6a52fd", name: "Jumonji University" },
    { id: "362a543b-c3b8-4b00-8155-32062be7d435", name: "Juniata College" },
    { id: "45a85eb9-7ce0-47d9-b703-42460a8a700e", name: "Juraj Dobrila University of Pula" },
    { id: "12e45cd9-363b-4ceb-9d6b-ce60b9b08f04", name: "Justice Institute of British Columbia (JIBC)" },
    { id: "661cf558-0eb1-4a37-a878-ef1f6e0c95d4", name: "Justice K S Hegde Institute of Management" },
    { id: "c29ff1bd-1d07-40c0-97e1-a69efe8bef4b", name: "Justus Liebig University Giessen" },
    { id: "861fb584-691e-4860-8115-bd7210fb9ce7", name: "Jyvaskyla University of Applied Sciences" },
    { id: "dacb135b-b334-4404-8491-b315023e10dc", name: "Jyväskylä University of Applied Sciences" },
    { id: "8120e765-fbed-48eb-adc3-77f279cd940a", name: "K L University" },
    { id: "f9783174-532b-4917-bd1b-3bf90fc8745f", name: "K. K. Modi International Institute" },
    { id: "5d805f89-0a3a-4a61-a86d-c2df1cf921ae", name: "K.N. Toosi University of Technology" },
    { id: "b35f4bf1-4959-4c26-8efc-95bb71163ecb", name: "Kadi Sarva Vishwavidyalaya" },
    { id: "71131468-d5d6-458e-b561-ce7bae5dbefc", name: "Kadir Has University" },
    { id: "94ea83aa-3b6e-40d3-bcd9-c3545a0245b0", name: "Kaduna Business School" },
    { id: "309b91f0-2000-4c20-b440-da4e9dd64fa8", name: "Kaetsu University" },
    { id: "24a149a1-e2c6-434d-a07a-cc8c4d4e2fd2", name: "Kagawa University" },
    { id: "a6ac8029-0e70-46af-b787-39be8b47e445", name: "Kagoshima University" },
    { id: "41b1adad-6fa8-4076-947e-a9a01a9bfb07", name: "KAIST (Korea Advanced Institute of Science and Technology)" },
    { id: "fa02b86b-6e33-4f4c-a0ab-a64b3081d77a", name: "KAIST College of Business" },
    { id: "5482a787-bbc7-4f2f-b2f7-f6d2c3bf591a", name: "Kalaidos University of Applied Sciences" },
    { id: "d3939c9a-0b44-4bde-90d2-a97e1797b4b2", name: "Kalamazoo College" },
    { id: "f33ae73a-aad0-425b-b671-12dc00ec8f9d", name: "Kalamazoo Valley Community College" },
    { id: "d91058ac-398c-4166-8916-0b6c13a3e15d", name: "Kalayaan College" },
    { id: "927e8c5b-4dfd-496d-a46d-567c5b958427", name: "Kaleo School of Biblical Leadership" },
    { id: "08599c42-9d4d-484f-9dc8-626d37e60062", name: "Kalindi College" },
    { id: "69fa3163-2b6f-4356-b0f5-5c2378b59447", name: "Kaliningrad International Business Institute" },
    { id: "ba903c82-2c0e-4723-ba74-b679661003b8", name: "Kampala International University" },
    { id: "4b5c1ce2-ea72-4fe2-9ade-0458f33ed464", name: "Kanagawa University" },
    { id: "ea96bf57-0f45-4cf3-9870-9b02a1220f84", name: "Kanawha Valley Community & Technical College" },
    { id: "5d71edac-bc8c-45e0-8233-33398c7565fa", name: "Kanazawa Institute of Technology" },
    { id: "1b6074ba-9b93-4ee8-8d64-eba9d5c9dee1", name: "Kangwon National University" },
    { id: "357bae31-7d71-43ff-be39-b9dc3b560ce8", name: "Kanpur Institute of Technology & Pharmacy" },
    { id: "981eccba-0996-4330-993b-f4d04b58d45c", name: "Kansai Gaidai University" },
    { id: "22e3c82f-95e9-4780-bc20-0574982d60ef", name: "Kansai University" },
    { id: "f277c887-3387-456e-a92e-6490ea204b58", name: "Kansas City Kansas Community College" },
    { id: "3a61bad7-de8b-4fa5-9fd3-3b01c3473a9d", name: "Kansas State University" },
    { id: "4af7f724-ef1c-48b9-89a4-156d5cf9f599", name: "Kansas Wesleyan University" },
    { id: "4bcfa5b9-afc8-4e2b-8e74-9d2cb7335d4d", name: "Kanto Gakuin University" },
    { id: "74c6c004-e24f-4391-b10a-2d783ce66370", name: "Kantonsschule Buelrain" },
    { id: "82828ec9-e9c7-4d8d-b8bd-c0711ebe5c37", name: "Kantonsschule Enge - Zurich" },
    { id: "777480cf-e302-48a4-99ff-9c42726fb8da", name: "Kantonsschule Heerbrugg (KSH)" },
    { id: "d4ed1e4e-97e2-4ac0-a60a-c83e9d6e2eeb", name: "Kaplan Global Solutions - Colloquy" },
    { id: "dbcee8ae-8fe2-484a-aa96-845f75639a78", name: "Kaplan Higher Education" },
    { id: "1e03d05d-516a-4b23-89ef-7f5373b1bc33", name: "Kaplan Higher Education (HK) Ltd" },
    { id: "a73cf179-075d-45a1-aaf9-bd93f8db8695", name: "Kaplan Higher Education Corporation" },
    { id: "77964c46-b3fc-479e-b6b1-b43750e2a071", name: "Kara Harp Okulu" },
    { id: "f280c584-82af-44fe-892d-35038cdf7242", name: "Karachi Institute for Technology and Entrepreneurship" },
    { id: "a9a22104-f64d-4f1e-8272-af8686fe1d3f", name: "Karachi Institute of Economics and Technology (KIET)" },
    { id: "74c9ddbc-66c5-429e-bff7-d7acbc6a0676", name: "Karachi School for Business and Leadership (KSBL)" },
    { id: "d121f344-c5d7-4932-88ba-79aaac33d8e5", name: "Karakoram International University" },
    { id: "8f80cded-7461-4260-898e-2d82ed4a4a2b", name: "Kardan University" },
    { id: "4b185455-b567-4533-a8b3-0a2a010116ca", name: "Karl Franzens Universitaet Graz" },
    { id: "155e459a-a48f-4bf6-9703-12e74afb99b4", name: "Karlshochschule International University" },
    { id: "c8260f4d-fb01-418a-ab7f-acc8b40e4ffd", name: "Karlsruhe Institute of Technology (KIT)" },
    { id: "53e0f883-9e74-4a03-ad5c-752cdf504285", name: "Karlstad University" },
    { id: "6b2ec6a2-6497-4ff7-9b03-745b5cd72467", name: "Karnatak University" },
    { id: "6d639914-29ed-40c0-89f7-87bc2aa0ac40", name: "Karolinska Institutet" },
    { id: "3bca184a-7da7-4af9-ad0b-68a3bb59823e", name: "Karunya University" },
    { id: "82d2d8c2-0942-465d-ad4d-6a4c41b73c57", name: "KASB Institute of Technology" },
    { id: "7aae56b7-1cf5-44f4-9405-237e4a3d3056", name: "Kasetsart University" },
    { id: "b46b0cb4-4172-4776-af81-222989c1007a", name: "Kaskaskia College" },
    { id: "fc6ba256-cd72-4dde-b658-7b397e8f37e7", name: "Kassel University" },
    { id: "946cece9-3ba5-4d3e-bfa5-addc2678d51f", name: "Kate Freeborn" },
    { id: "f81790b2-006c-4bf0-a523-09a54430ff9c", name: "Kathmandu College of Management" },
    { id: "b3136bf3-012e-401e-b67d-1f696082eedd", name: "Kathmandu University" },
    { id: "b82535fd-77e6-4a91-b8c0-4e10f44c6d34", name: "Katholische Hochschule Nordrhein-Westfalen" },
    { id: "83f4acef-f56f-458c-a3bb-65fe97bd8c62", name: "Katholische Universitat Eichstatt - Ingolstadt" },
    { id: "a734241e-e03e-4d67-90e0-7dca6172132b", name: "Katholische Universitat Eichstatt-Ingolstadt" },
    { id: "4b0cdc5c-b1c2-4d45-9f7a-a6a36dd76447", name: "KAU - Korea Aerospace University" },
    { id: "6429bd22-345a-498e-8b36-83bc47574ef3", name: "Kauffman Fellows" },
    { id: "02284491-50ec-44af-b4ea-8db78760c209", name: "Kauffman Foundation" },
    { id: "ace664a2-00ee-4020-90b0-ec6d54debc87", name: "Kaufmännische Schule Nagold" },
    { id: "1002561d-3f70-465b-a1c2-86e9d551ea65", name: "Kaunas Technology University" },
    { id: "486e5089-255c-4acc-862c-e0ea0e09b9a3", name: "Kauno Technologijos Universitetas" },
    { id: "c35c3a4e-b409-4bb0-9a5c-c63e3990b882", name: "Kazakh National Pedagogical University" },
    { id: "6e27d79a-0cbf-4fd1-881c-17cfd04110f7", name: "Kazakh-British Technical University" },
    { id: "ce100e05-a36a-4d96-b675-e8d6a996a6b2", name: "Kazakhstan Institute of Management, Economics and Strategic Research (KIMEP)" },
    { id: "1d692e51-1c4d-4696-b410-75e6ac23c535", name: "Kazan Federal University" },
    { id: "386e4a56-4d40-457c-b6ef-9a365bc6a672", name: "Kazguu University" },
    { id: "b7999b52-5f60-4d1c-bc17-ed1e2363e851", name: "Kaziranga University" },
    { id: "b227e113-bf72-4914-a6a7-c101321d6a7e", name: "KCA University" },
    { id: "3d2352cb-2ba3-4918-bb82-ca3ca60d8f7b", name: "KCT Business School" },
    { id: "253d6a0d-bbb3-4544-811c-521e96565ece", name: "KDI School of Public Policy and Management" },
    { id: "4360ccc0-bedf-438a-b4e8-391132d1929f", name: "Kean University" },
    { id: "70e734b1-0c08-4f38-99b4-dd23193852ca", name: "Keck Graduate Institute" },
    { id: "52f32914-7111-46fa-9e8e-ed37a80a209b", name: "Kedge Business School" },
    { id: "860cd5e7-554e-4e4a-91be-58eab8e15e83", name: "Keele University" },
    { id: "e376e4c2-985c-4a42-807a-11a9d0ea8337", name: "Keimyung University" },
    { id: "f1092b17-7f88-4819-8f72-acea6096413a", name: "Keio University" },
    { id: "0d5554bf-7f23-42ec-ac70-f01d636b97a4", name: "Keiser University" },
    { id: "31434022-2245-44dd-9db8-e6baed8468ea", name: "Keiser University Melbourne" },
    { id: "f36e91c1-8913-40cb-9e72-a1feaf7bc7a1", name: "Keller University" },
    { id: "cdf06b2a-497e-4db6-99e7-64b85da3f2af", name: "Kellogg Community College" },
    { id: "07dfc73a-189a-45d6-9060-47e4b70d03d0", name: "Kementarian Pendidikan Malaysia" },
    { id: "89fa7a4b-a0a5-4759-ac89-05173fe4f487", name: "Ken Blanchard College of Business" },
    { id: "c83b4722-e4de-4582-85b6-479ca085c1a9", name: "Kenai Peninsula College" },
    { id: "b9b2b022-1ea9-448d-b0ba-0f3fbc814984", name: "Kendall College" },
    { id: "fb15b8ab-67b3-4a33-ba63-a59b41ab007a", name: "Kenichi Ohmae Graduate School of Business" },
    { id: "ac118904-8cff-4783-9df6-acf8d8750deb", name: "Kennesaw State University" },
    { id: "d789f056-0cc2-41bb-8467-7b4258a13b4a", name: "Kent Career Technical Center" },
    { id: "9b7cb63f-61ef-4930-90d9-3a7c0a81d307", name: "Kent Institute Australia" },
    { id: "5fd4932b-2714-4e0a-b7e7-4dabe671d3a3", name: "Kent School" },
    { id: "9983193a-22b0-4709-8027-bef99392bda1", name: "Kent State University" },
    { id: "e79cc1cf-aea2-491c-a1e7-82629adc00ef", name: "Kentucky Christian College" },
    { id: "9c7a4939-7b91-4d4d-91b5-4d78fad2d682", name: "Kentucky Community and Technical College System" },
    { id: "0936e420-bf8e-43ea-8c65-cf16cc71035d", name: "Kentucky State University" },
    { id: "c8af7220-e6f9-49d4-8764-8a53134ed3f2", name: "Kentucky Wesleyan College" },
    { id: "3c065418-1bbd-4db5-82a6-6ca2afc642a4", name: "Kenviron Corporate Education Pvt Ltd" },
    { id: "ce81831f-feb1-4f87-8363-415185864844", name: "Kenyatta University" },
    { id: "fc2c7a25-1ef4-4019-ba9b-3e922b24b170", name: "Kenyon College" },
    { id: "5c3efb6c-3eae-48e4-b588-120a882513c2", name: "KEPCO International Nuclear Graduate School" },
    { id: "1e8d30db-75b0-444a-a4e6-d712ecde20c0", name: "Kester Grant College" },
    { id: "b237bc59-0033-488a-9e93-8de54ed9450d", name: "Kettering University" },
    { id: "c01459f4-a6f0-44f0-ab2d-3c56e791189d", name: "Keuka College" },
    { id: "e079d0ad-107c-4081-9ed6-bf7fa0234e41", name: "Keyano College" },
    { id: "5283f3f2-9187-4212-a413-b9bf8275e2ea", name: "Keystone College" },
    { id: "f4a9b1fb-8ba4-4b29-80d6-4da9c1c747a8", name: "Khabarovsk State Academy of Economics and Law" },
    { id: "3621f872-d023-401e-aeac-bf095e5c7d3a", name: "Khalifa University" },
    { id: "85672e79-2b8d-4ba0-90bd-27e73cea1aa3", name: "Kharkiv National University of Economic" },
    { id: "c514d2c6-ca9b-4736-b296-165cd8c27820", name: "Khawarizm Academic" },
    { id: "3fd5b555-92b8-4b07-8a21-ce6392c3a0b8", name: "Khazar University" },
    { id: "01fe10dd-c2ab-47a2-9d3f-bbc2ece09f7a", name: "Khon Kaen University International College" },
    { id: "fbfe4a50-d57e-4a23-93a8-4ceec0e21800", name: "Khulna University - Business Administration Discipline (BAD)" },
    { id: "34cfd65f-cbf9-469c-b82c-39ff8fd63bfa", name: "Kiama Community College" },
    { id: "aaa2b865-ce9c-417d-8207-e406f2e6a979", name: "Kiel University of Applied Sciences" },
    { id: "2b26c16a-1660-4b54-a838-2dec2877dc8b", name: "KIIT School of Management" },
    { id: "83320214-7772-4169-b665-2e4f2adfb6f0", name: "KIIT University - Kalinga Institute of Industrial Technology" },
    { id: "35a3b44e-152d-42a5-807a-0a27db26775f", name: "KIMEP University" },
    { id: "32b06c72-d2fe-4bbf-b1df-f8b847816dcf", name: "King Abdulaziz University" },
    { id: "328aeed7-6a30-4feb-8aa3-a92c1590a068", name: "King Abdullah Economic City" },
    { id: "c77005d7-263e-44ee-b072-08b723c5fd37", name: "King Abdullah University of Science and Technology" },
    { id: "9a17d9dc-6a6e-473a-bbed-3e08b84123c8", name: "King Fahd University of Petroleum and Minerals" },
    { id: "a85fe52b-d943-4955-af8b-3c83614e9af0", name: "King Faisal University" },
    { id: "5150957d-f24e-4f6a-be87-b7a7eacbf6e8", name: "King Kopy" },
    { id: "50514e58-c3dd-4a6e-bae1-b3586559afa3", name: "King Mongkut's Institute of Technology - Ladkrabang" },
    { id: "1f35cab5-3b25-4e00-a848-d49fc9573cee", name: "King Mongkut's University of Technology Thonburi" },
    { id: "aeb10777-a1aa-4d1c-99ae-888c6494cca8", name: "King Saud University" },
    { id: "22104a06-e60f-4591-b8d1-d942bf80e4cc", name: "King University" },
    { id: "82be93f4-0063-4f6d-b08a-0da2e87363f3", name: "King's College London" },
    { id: "4b4352ee-3922-4803-afb1-be8555796c44", name: "King's Language Institute" },
    { id: "478f7ebc-16c9-43be-a980-a0bebab92b25", name: "King's Own Institute" },
    { id: "97b21058-1066-4502-9e1e-3dfbca0b808d", name: "King's University" },
    { id: "32ac70c5-21df-4695-bc74-d040277418b1", name: "Kingsborough College" },
    { id: "ea046f3d-7ebd-48e0-bd6b-25230bc6db02", name: "Kingston City Schools" },
    { id: "b05f745d-abd0-4453-b643-a06d7e28a762", name: "Kingston University" },
    { id: "0e707284-7882-45a8-9680-58795fc233fd", name: "Kingwood College" },
    { id: "465c9144-6c75-4564-8c37-e6ad8db6afba", name: "Kinki University" },
    { id: "d858ae8f-6c17-46c7-a703-496111e8ebe9", name: "Kio University" },
    { id: "6647f055-312d-48bb-bf0a-ff7dafd09fc5", name: "KIPP ACADEMY LYNN" },
    { id: "58799e64-4d5f-4cd5-acc3-8a1c459939eb", name: "Kirkwood Community College" },
    { id: "72b1fc10-fd0f-43c8-a2cb-376c249d07b8", name: "Kirloskar Institute of Advanced Management Studies" },
    { id: "24aa59e6-8d3d-4f57-b660-0007cae5ac57", name: "Kishwaukee College" },
    { id: "89880504-0e0a-43c9-8257-b2fcd714f545", name: "KIT - Karlsruher Institut fur Technologie" },
    { id: "3ce63a7d-5c9a-4c3f-a863-a291cdd70b22", name: "KJ Learning" },
    { id: "f5b41291-129e-46e9-84f5-4f3a4938b26f", name: "KL University" },
    { id: "ca072bc4-66c6-4bce-9950-1f31feac5630", name: "KL-TEAM Gesellschaft für Planung und Training mbH" },
    { id: "6b503687-d915-4310-a8c7-9bab7f5d6557", name: "KLES IMSR" },
    { id: "339668e3-728e-4aaf-a9b7-c58c08ed15d9", name: "KMD Institute" },
    { id: "5a2c2f62-b7ee-4d30-a3ea-893cd08eb0e1", name: "Knight Management Center" },
    { id: "18b1227a-cfee-439b-a271-09ec353071df", name: "Knowbility Corp" },
    { id: "20c598e5-9b6d-43fa-af25-d1eed18db0a3", name: "Knowledge Bureau" },
    { id: "d8111d42-9e62-4e62-a733-2ff0a13e97f8", name: "Knowledge Systems Institute" },
    { id: "5d5df2dc-6065-415d-b357-146e4ae3a36e", name: "Knox College" },
    { id: "06f362d4-3ca0-43e5-9fac-6bd79066fe9f", name: "Kobe Gakuin University" },
    { id: "450e5a0b-9a0a-41f1-a25f-4bf19227faef", name: "Kobe University" },
    { id: "8011d4bf-6b04-4697-b893-036e24f09589", name: "Koc University" },
    { id: "fde15ebe-441e-47fa-a53c-74d7e9d1e65e", name: "Kocaeli University" },
    { id: "9c820d07-dbba-4ba8-bade-598aafd9db0c", name: "Kochi Business School" },
    { id: "cb695ed2-18b2-4b95-84ee-842c2bb53b41", name: "Kochi University of Technology" },
    { id: "d6cf9a61-695f-4a45-830b-508563138799", name: "Kodolanyi Janos University of Applied Science" },
    { id: "2cdca1df-a5ea-4d0a-a65c-ce205e57b716", name: "Kogakuin University" },
    { id: "8d00ba98-61ca-4b59-8038-fe02e5740472", name: "Kohinoor Business School" },
    { id: "854e997f-538c-41a3-8c65-3e7808527c5e", name: "Kokugakuin University" },
    { id: "59c0764a-aa2b-4e63-9b0e-35a072537bc8", name: "Kokushikan University" },
    { id: "17eaa988-1239-49a0-b271-e0122a62161b", name: "Kolej Komuniti Langkawi" },
    { id: "8a042e51-df50-4883-a503-936f1fca2fe6", name: "Kolej Universiti Insaniah" },
    { id: "869dcbcd-ee1d-432b-bb9e-4ccd359d3604", name: "Komazawa University" },
    { id: "bde199ca-79d9-4862-b0c8-20a496240ed5", name: "Koneru Lakshmaiah University" },
    { id: "4d9f0187-57a8-4c7b-95e9-28f99dffdfa8", name: "Konkuk University" },
    { id: "1cdcde4a-9f11-48aa-9dfe-e3f19f7598c9", name: "Kookmin University" },
    { id: "39d6575c-251e-45c6-8174-c3e01c901e44", name: "Korea Information Society Development Institute (KISDI)" },
    { id: "18d34851-0c58-4629-8c96-45c2209eace3", name: "Korea Inst for Intl Economic Policy" },
    { id: "11e4452b-88c1-4e9f-8e2a-2870795f32c2", name: "Korea Institute of Finance" },
    { id: "9e16d26c-a6ac-4cb2-a1f1-e69a9c9f36e8", name: "Korea Tech - University of Technology and Education" },
    { id: "6739a909-3799-44e9-8509-54fddc4ab80a", name: "Korea University" },
    { id: "e6a7ad4a-cbbb-47f1-80c1-90b81d20dede", name: "Korea University Business School" },
    { id: "6bcad026-bcfd-4573-8a5a-c3b86a9b40e3", name: "Kosei Gakuin Educational Corporation" },
    { id: "4a76218a-3f36-49cc-b257-a13573dc41ca", name: "Kozminski University" },
    { id: "295ab7a0-abbf-4422-adaa-5c2727ce016d", name: "KPMG" },
    { id: "dd3148d8-e61e-49b1-aae7-0e2fac8e3e28", name: "KPMG - Austria" },
    { id: "a905c96d-4180-43da-9bc6-c843e2d08f13", name: "KR Mangalam Institutions of Higher Education" },
    { id: "1506ae8d-63a4-4e7d-83f1-8e8c7d5e9a55", name: "Kraft Foods Bulgaria AD" },
    { id: "bbf8abbf-7fc8-46e3-af2c-58c5087d75b8", name: "Kraft Foods Middle East & Africa FZE" },
    { id: "afba2d36-d9fd-45fc-882a-86721314fbbb", name: "Kraftees Colege Town" },
    { id: "b6c311a3-cb41-49de-a6be-af5462895641", name: "Kristiania University College - Ernst G. Mortensen Foundation" },
    { id: "79b3cb96-f1ca-4045-a96e-362139c6c3c8", name: "Kristianstad University" },
    { id: "b1f55a1a-e82b-4470-9aba-b42781036de5", name: "Krupanidhi College" },
    { id: "3fec4787-41e9-4607-b1a6-7e40fc101bcb", name: "KTC Ltd" },
    { id: "f0934e5f-ab16-4750-b5f3-c1ee4f833a40", name: "KTH Royal Institute of Technology" },
    { id: "0d6f39e2-93c0-4c0d-8cbf-ef2556da9f63", name: "Kuala Lumpur Metropolitan University College" },
    { id: "4164d8f5-823b-4e02-9f6d-0eb7ba31103e", name: "Kuehne-Stiftung" },
    { id: "c1977fac-16a8-488b-aec1-ae54d18630ab", name: "Kullar College" },
    { id: "a260a8e4-8ace-4ff9-ab67-092992566c11", name: "Kumamoto University" },
    { id: "d820e19a-da08-467e-b7c0-4d8770149904", name: "Kumaraguru College of Liberal Arts & Science (KCLAS)" },
    { id: "889c6ece-9670-426f-a798-3fe23e32f1c4", name: "Kumaun University" },
    { id: "52dfefbe-be75-4d87-a3cd-53c6629beb07", name: "Kun Shan University" },
    { id: "bf9be617-1876-418f-a562-b72ca1544ea7", name: "Kunming University of Science and Technology" },
    { id: "198cd580-5ac5-4df1-9837-a205bc406e14", name: "Kuril Founders B-School" },
    { id: "8d7e2a2b-2aa3-445b-9329-a43a48751345", name: "Kurukshetra University" },
    { id: "7d9995a8-2b3f-430e-9837-0cc9e26f8555", name: "Kutztown University" },
    { id: "d1b5aabc-9e8a-458d-ac3a-bf1801697621", name: "Kuvempu University" },
    { id: "c6abae3b-0032-4804-bb33-3bb8dbd4e0f4", name: "Kuwait University" },
    { id: "b3e19e54-9bb4-4de0-ab4f-9801edca72dc", name: "KVG College of Engineering" },
    { id: "416c5054-1124-496f-9a98-c608cfc0d939", name: "Kwame Nkrumah University of Science and Technology" },
    { id: "1267b10f-d1a7-48ad-8795-1b483589dc0e", name: "KwangWoon University" },
    { id: "77c3c8dd-fa56-4a6c-87f3-4ba2bdf84ad5", name: "Kwansei Gakuin University" },
    { id: "599c20f8-14a3-44b6-b118-60af31552fa4", name: "Kwantlen Polytechnic University" },
    { id: "da8b4f94-88dd-4c7b-af1d-0934d6a261ec", name: "Kyiv Mohlya Business School" },
    { id: "da308ffa-36ed-440e-a426-b83eb30e850e", name: "Kyiv Mohyla Academy" },
    { id: "390d1f3c-8224-46e8-98f4-53ada22a8daa", name: "Kyiv National Economic University" },
    { id: "ae71d35f-78e2-46e9-90de-a63d9cf37e6c", name: "Kyiv School of Economics" },
    { id: "81a16fff-244c-48be-a676-5cc73b3aa346", name: "Kyonggi University" },
    { id: "5cab06c3-0c27-4c86-afa5-97893d0cdecc", name: "Kyoto Gakuen University" },
    { id: "57798b56-f9d8-48eb-8cdc-ed9520374624", name: "Kyoto Sangyo University" },
    { id: "918af530-3f63-428e-8973-fde14cb4941a", name: "Kyoto University" },
    { id: "96e27baf-a286-4257-8bc7-9bd99613277f", name: "Kyowa Hakko Kirin Co." },
    { id: "cb3b7be9-8532-4353-b2db-5c1dff0a45fd", name: "Kyung Hee University" },
    { id: "326e1cb1-b0d7-4acf-994d-2ad5a13211a5", name: "Kyungpook National University" },
    { id: "e3fca55e-2348-4fd9-8977-52f66d07f3a5", name: "Kyungsung University" },
    { id: "f8fee002-e690-4586-9f20-f77257010315", name: "Kyushu University" },
    { id: "fb4e66da-88e3-4323-97e9-87db33b67676", name: "L&T Institute of Project Management" },
    { id: "91073491-28b9-46e2-a9a8-0c3bbf4bf3a1", name: "L'Universite Hassan II" },
    { id: "360d8012-56b9-4804-bcf3-a194f4c3a85f", name: "La Roche College" },
    { id: "40d8dd12-8991-4c2a-a5a2-7277b8267f7e", name: "La Salle Centro Universitario" },
    { id: "e786c44c-30f4-497b-ab17-8a5251db5f9f", name: "La Salle Universities - Spain" },
    { id: "99f3f74a-632c-432b-bc28-ebdd6ebedf57", name: "La Salle University" },
    { id: "ffa7a9ff-0988-4216-a74f-9455409bd2cc", name: "La Sierra University" },
    { id: "85c86d00-f53e-437e-bc7c-e82a5a5a8426", name: "La Sorbonne" },
    { id: "8cf6bf83-1f58-4c65-8371-cbad27119abd", name: "La Trobe University" },
    { id: "e0700e8a-1c8c-4922-85cf-27d33877c014", name: "Lacc Tranlsation Program" },
    { id: "7dcc46c0-190c-4104-badd-008baaa2b898", name: "Ladoke Akintola University of Technology" },
    { id: "42b376a3-dd24-43f3-9ba1-6c25c1c2e6d9", name: "Lafayette College" },
    { id: "d646ff78-76ff-4f8a-be4f-6da9a23f3dd0", name: "Lagos Business School" },
    { id: "77a9e326-2b51-49dd-9021-2643e8b6b98f", name: "Lagos State University" },
    { id: "a5b11e5c-dddf-4dc3-880c-de17e792f269", name: "LaGrange College" },
    { id: "2060766a-1999-4ad1-b165-cc3f74cf6df1", name: "Laguna Blanca School" },
    { id: "ad2556f8-66e4-4fd6-a805-969e19f79b59", name: "Lahore School of Economics" },
    { id: "51e5baea-35bd-4494-a344-6c0ccbeb8682", name: "Lahore University of Management Sciences" },
    { id: "41d57648-1d9b-4cb0-841e-12fcf872c866", name: "Lahti University of Applied Science" },
    { id: "4260ad92-578d-4dda-8fe4-69a6c6827b47", name: "Lake Erie College" },
    { id: "c65037db-0e1e-43ea-9b68-b658ceb13c96", name: "Lake Erie College of Osteopathic Medicine" },
    { id: "98bfb6fb-8d95-41e2-ab40-06ac84f92528", name: "Lake Forest College" },
    { id: "23c9e7c2-2d4a-48b4-9b17-205ecfea7db3", name: "Lake Forest Graduate School of Management" },
    { id: "164e5eef-46ae-49a5-96b4-5faf21f65ff7", name: "Lake Michigan College" },
    { id: "0a3ad0b4-7f13-4b23-836c-bf286b15ec34", name: "Lake Superior State University" },
    { id: "5359da01-1156-4909-8ca2-aaec9f4cc6ba", name: "Lake Washington Technical College" },
    { id: "2ebbb4f6-cfb1-401c-a687-db44d80a18e2", name: "Lake-Sumter Community College" },
    { id: "491c68ea-6d50-49c5-848d-a1f3e9591c72", name: "Lakehead Univ Bookstore" },
    { id: "d521196e-bc44-47c0-b95c-2ccd1a853484", name: "Lakeland College" },
    { id: "14b3af3e-0988-4309-ab97-7d70fd19168e", name: "Lakeland Community College" },
    { id: "06eb9f2f-8b34-4e9f-a528-e872d78c0684", name: "Lakeshore Technical College" },
    { id: "700649f0-032b-4dfb-a24c-2171d803e616", name: "Lakeside Business College" },
    { id: "a3baacda-b9ba-4cac-b7ae-45193c76580b", name: "Lal Bahadur Shastri Institute of Management" },
    { id: "304a7726-e346-4236-9bbf-f6f7e40ec323", name: "Lal Bahadur Shastri National Academy Of Administration" },
    { id: "4830d509-1852-4ff0-974c-c7b90184cc3b", name: "Lala Lajpatrai Institue of Management" },
    { id: "a76d7169-4450-42fd-a9f9-a0402c47949f", name: "Lamar State College - Port Arthur" },
    { id: "a3a4056e-62ff-4c93-a58b-62366581775e", name: "Lamar University" },
    { id: "e2f85dbe-4794-48de-bf57-2c7c5f8604dd", name: "Lambton College" },
    { id: "bd99996d-253d-450c-9995-0afe6e1fcb7d", name: "Lancaster Bible College" },
    { id: "550d7dd5-a2c5-4fdc-a8e7-77bfe4a7b646", name: "Lancaster University" },
    { id: "08a529f6-4edc-472a-95a1-af7679ffd606", name: "Landmark College" },
    { id: "457c8903-f2d1-4594-963e-9abea700b487", name: "Lane College" },
    { id: "6da68f26-38fc-4847-9875-3cdf2a1adc27", name: "Lane Community College" },
    { id: "685f20e9-363c-48a0-afa4-3af38e91e160", name: "Laney College" },
    { id: "014fa926-b85f-4234-be6f-329f9e12460c", name: "Langara College" },
    { id: "26c709c8-4420-479e-a241-ebaea87d577f", name: "Langham Management Consultants" },
    { id: "7fdd664c-46ea-4977-9839-8d24dc04211a", name: "Langston University" },
    { id: "4ab3948a-5e5d-4e97-abc5-aea472089dbf", name: "Lanier Technical College" },
    { id: "a759fe1c-ddb5-4217-bd0c-3dc30d97893c", name: "Lansbridge University" },
    { id: "8c72c26e-dc6c-4b3a-8c90-131145649635", name: "Lansing Community College" },
    { id: "6a0e1320-40bf-444d-a1ec-10084b873501", name: "Lanzhou Jiaotong University" },
    { id: "5fc80ca2-dd40-4336-9236-ec71e0a16bb7", name: "Lanzhou University" },
    { id: "a3cb315e-00e7-4ca0-8bcd-aa266c3864f7", name: "Lapland University of Applied Sciences" },
    { id: "6582286a-0871-4360-923c-59abaa501050", name: "Lappeenranta University of Technology" },
    { id: "47d3f220-f780-4082-b799-c8f96f48c141", name: "Laramie County Community College" },
    { id: "00bbc3b5-2f06-4a10-bc69-d49b1cf543a9", name: "Laredo Community College" },
    { id: "b52e2a8c-dc5a-43b9-9158-0175e3aef711", name: "Larsen & Toubro Limited" },
    { id: "d385009b-c2f3-4d9c-a5df-b9abb2154bb7", name: "Las Positas College" },
    { id: "cbd4aa4d-c64b-4003-bc01-137346db56b6", name: "LaSalla Investment Management" },
    { id: "83778d04-7e83-4be3-8fa2-d8e12cfaccfc", name: "LaSalle Beauvais" },
    { id: "36c2ba11-cc8e-4f56-98ab-5e7e80f2d97c", name: "LaSalle College" },
    { id: "1da845a3-22dd-4f2a-814a-eb6c6ffdb99a", name: "LaSalle College - LCI Bogotá" },
    { id: "fd0971fd-b2ea-4d47-a519-8bd3a4ee6e30", name: "LaSalle University - US" },
    { id: "28aa89d7-2db0-4a38-b1db-c6de7f40b548", name: "Lasell College" },
    { id: "5d3c78fe-5039-4c78-b091-71a3e5ea6369", name: "Laser Image Corporate Publishing" },
    { id: "039d57a5-9e4a-45db-b18a-e769cf86af30", name: "LASPAU - Academic and Professional Programs for the Americas Inc" },
    { id: "eb35c8ce-f950-4e2a-871d-6cafc6fe5050", name: "Laspau/Harvard Univ" },
    { id: "f09b4c7b-ee18-4c35-a0cc-1779cfc96708", name: "Latvia University of Agriculture" },
    { id: "080ece53-2c05-4345-9dc1-6ac568ad513f", name: "Latvijas M?kslas akad?mija" },
    { id: "041ab584-6ec1-4993-8c51-76ce047fc2ef", name: "Lauder Business School" },
    { id: "f80c7342-e7e3-43f8-beb8-b0e902d96a24", name: "Lauder College" },
    { id: "f84f0ad2-8945-4783-849c-60c37be69df9", name: "Laurea University of Applied Sciences" },
    { id: "628c1066-c2d8-45fc-891d-bcf1934385ba", name: "Laureate" },
    { id: "39c68071-68fb-430e-af85-1424c252261f", name: "Laureate - Los Angeles" },
    { id: "9567b54a-5ae4-48db-bbf5-dcdadd0566ba", name: "Laureate Education" },
    { id: "daf8227b-c811-44d6-9d1c-464508a50969", name: "Laureate Education - Baltimore" },
    { id: "e9b860a7-21bb-4dae-b503-248ea4dd5b5d", name: "Laureate Education - Miami" },
    { id: "de6fe6d3-9bd3-4035-87e8-f2535189dd52", name: "Laureate Education - San Antonio" },
    { id: "05cc51c7-907c-4dba-83f7-d5789cadb00a", name: "Laureate Internation Universities" },
    { id: "8e8d2397-9d28-4f15-b6bf-39873ba45182", name: "Laurel University" },
    { id: "d8e72f70-a6e3-43b2-8587-270366aadd46", name: "Laurentian University" },
    { id: "8c732c86-f046-4a39-bbf0-68aef54352c3", name: "Lawrence Public Schools" },
    { id: "c7d68ee6-fc8d-41d2-8b9c-11530e288aa4", name: "Lawrence Technological University" },
    { id: "a748ca30-4ca3-4b72-bd7b-7a653e3d6519", name: "Lawrence University" },
    { id: "27d03170-87ce-4be6-ad13-0dd4d210c8e2", name: "Lazarski University" },
    { id: "6d97e925-e4db-48a3-b97f-d1859e6e699c", name: "LCO Ojibwa Community College" },
    { id: "8491a6bc-f37c-4aa9-a700-058db5f819bb", name: "Le Cordon Bleu Australia" },
    { id: "09f90054-4f15-4849-a8dd-7e103c4dfe8e", name: "Le Cordon Bleu College of Culinary Arts" },
    { id: "dd8d4199-194e-4d8a-a4e4-2e600946d7d5", name: "Le Moyne College" },
    { id: "1590ac8c-39d8-49bb-bc2a-b9d142fe4b0c", name: "Lead College of Management" },
    { id: "34035b26-8b2a-49cd-b5e2-1661366918b1", name: "Lead Global Management Institute" },
    { id: "e84e591b-d13b-4bb1-a084-0c6935627055", name: "Lead University" },
    { id: "a00ce566-f4b8-402d-a7c4-55e18e1879ee", name: "Leader to Leader Institute" },
    { id: "51450caa-9801-4edf-9b20-024249f828b3", name: "LEADERS" },
    { id: "cdc43712-12b7-4c12-bd89-f7ce3652eb23", name: "Leadership Pipeline Institute" },
    { id: "bd323280-64ec-44ef-b793-af75387a6d7c", name: "League of American Orchestras" },
    { id: "57f8dcdf-9250-45d7-b142-6ddafd41ec93", name: "Learning Business English School" },
    { id: "b13d93e4-cef0-41b7-85e9-ae83cbbb2895", name: "Lebanese American University - Beirut" },
    { id: "4910b76d-e15b-41a5-bbca-4027f7bccfe9", name: "Lebanese Canadian University" },
    { id: "ab8e399b-09b8-4fdd-975b-5bafd8a00df5", name: "Lebanese University" },
    { id: "0e7d30ee-8257-4259-9707-40a1516729e7", name: "Lebanon College" },
    { id: "ff9b56a7-b47f-4d7b-b6f1-816e428a2428", name: "Lebanon Valley College" },
    { id: "a718788c-c42b-481b-87ad-0c2ab1d08fca", name: "LECTRIC" },
    { id: "88fbbbd7-26a3-46d0-ab87-69ad5c793512", name: "Lederne" },
    { id: "acd0ec39-33c3-4ee7-adef-1bfd59e7a7b8", name: "Lee College" },
    { id: "a79e8e4e-d6e5-4696-9ce6-afbe90fef1ff", name: "Lee Kuan Yew School of Public Policy" },
    { id: "c59ce3d7-7ab0-41cb-8fe9-1792b4fe5695", name: "Lee University" },
    { id: "56717ffc-4ff5-4c31-b33c-1d52f5c78f5b", name: "Leeds Beckett University" },
    { id: "8e0bd182-8f7e-4187-9cae-9dfbebf2f42c", name: "Leeds Metropolitan University" },
    { id: "c2ca1773-be71-49a1-a480-2abb259e05fb", name: "Leeds School of Business" },
    { id: "e251f2ac-aced-460f-ba87-4d386bb9975e", name: "Leeds Trinity University" },
    { id: "452cd99f-aa8f-4d8d-af79-6be49cda7f15", name: "Leeds University Business School" },
    { id: "2ab49df0-d5ef-4ebd-8b4d-c836c9492d8c", name: "Lees McRae College" },
    { id: "83cc0f38-4682-4e6c-93c1-0245e48775ff", name: "Leeward Community College" },
    { id: "4cefd313-5735-4192-b71b-c53eff11f8f6", name: "Legenda Education Group" },
    { id: "3b9f55fe-02e4-46e8-9a31-0af10d71abcf", name: "Lehigh Carbon Community College" },
    { id: "3e99cc07-8912-4e57-8e52-a54addd39a93", name: "Lehigh University" },
    { id: "3cd54c9f-2f81-4fc9-a091-6c93e43a686d", name: "Lehmanns Fachbuchhandlung" },
    { id: "e169611c-99da-4fec-8f3d-f97739832489", name: "Lehmanns Fachbuchhandlung GmbH" },
    { id: "c2d252e5-31e1-4b18-ac58-368db711bae6", name: "Lehrstuhl fur ABWL insb. Marketing III" },
    { id: "2a183a81-4c3a-432a-93b9-18d9f7359dd6", name: "Lehrstuhl fur Unternehmensfuhrung" },
    { id: "eb52e489-498a-45ce-b412-0b865d4b1a52", name: "Lehrstuhl für Wirtschaftsinformatik" },
    { id: "019e9d24-9de8-436a-8933-fdbf5ebad659", name: "Leibniz University - Hannover" },
    { id: "f687ce12-c954-453c-a006-ebee3cc17d92", name: "Leicester Business Academy" },
    { id: "ef2db10a-5c68-46af-972a-55efdbd7d076", name: "Leiden University" },
    { id: "708aa374-2886-4112-9bab-969b9c5d08e4", name: "Lenoir-Rhyne University" },
    { id: "73e12b60-35bd-4d8d-a11f-4e8e49faaca3", name: "Leon Kozminski Academy" },
    { id: "b86ac5b6-d4f7-4434-b444-e7ec7846dbcf", name: "Lerner College of Business" },
    { id: "264540ab-0bf8-4f8d-a68e-220c0b7be13e", name: "Les Roches - Marbella" },
    { id: "af469d87-215e-45a6-9c02-f1317c28b204", name: "Les Roches - School of Hotel Management" },
    { id: "d39bd022-a88a-4dac-a009-9911e0afe4c8", name: "Les Roches - Switzerland" },
    { id: "5f47b8f8-2dda-46aa-81ce-b1bafc28521e", name: "Lessius Hogeschool" },
    { id: "a84a0e8b-e18b-45da-85c1-8d728b127400", name: "Lethbridge College" },
    { id: "9a40f70b-9c6e-4906-83a9-bd3794c3be61", name: "Letourneau University" },
    { id: "454e5706-2932-4b7f-ada9-d623eaf82f72", name: "Letterkenny Institute of Technology" },
    { id: "78157e42-be79-476a-95df-d8a620341496", name: "Leuphana, University of Luneburg" },
    { id: "533d7f64-7bb3-455b-bb16-cf04c2bf44f4", name: "Lewis & Clark College" },
    { id: "777fb201-2d0d-47ec-ab91-58d411426594", name: "LEWIS & CLARK COLLEGE BOOKSTORE" },
    { id: "f0fd2ebf-4205-4840-aca4-2c0ee3906308", name: "Lewis and Clark Community College" },
    { id: "c493e07b-1612-4eac-a1a1-92c0769727b4", name: "Lewis Clark State College" },
    { id: "6491665f-006d-4b15-ac28-e4a0143a4877", name: "Lewis University" },
    { id: "50de395b-61d3-4281-802f-329f13bc2309", name: "Lewis-Clark State College" },
    { id: "3ba96261-5fad-49d8-bedd-c3703282976f", name: "Lexington High School" },
    { id: "a75bb733-e04d-437f-845d-72dfebf0c74a", name: "Lexington-Richmond School District" },
    { id: "d6399af4-628e-45f5-af67-f397d0d0bfa4", name: "Leysin American School in Switzerland" },
    { id: "54c350dd-55ec-4add-a18d-f79ed1705547", name: "Li, Erick" },
    { id: "38154535-e15d-4f43-ad11-52692970035e", name: "Liaoning University" },
    { id: "f38a6ca8-ba24-40e5-863e-d6b98d8ff889", name: "Libertas University" },
    { id: "3b64d4f6-149f-4cb9-9fab-826943abc4b6", name: "Liberty College" },
    { id: "1f4af823-ab6a-484e-95d8-ecba43a60ff1", name: "Liberty University" },
    { id: "baeadcda-532e-4d3f-b0f6-540481b3ffa0", name: "Librairie univ.du Quebec Metro" },
    { id: "b2be1c65-95ea-4551-b58e-970f6e2389ce", name: "Liceo de Cagayan University" },
    { id: "b21af734-6f96-49a1-bbc5-0d723c6778f5", name: "Life Pacific College" },
    { id: "0eafd67a-c179-4775-b64f-dc9d9a8b21e5", name: "Life University" },
    { id: "0ce0415e-f074-4667-810c-5c5cf0bdc18e", name: "Lifeway Campus Store" },
    { id: "820ed07b-f139-408a-a5cf-0576598881ac", name: "Lille School of Management" },
    { id: "a298fa91-e60a-41d0-af4d-75f04f4411ff", name: "Lillebaelt Academy of Professional Higher Education" },
    { id: "ae5ae8e1-84cf-4611-881a-50c9e208c63a", name: "Lillehammer University College" },
    { id: "dae97337-e6bb-45c0-b3f0-b9f14998868c", name: "Limak Johannes Kepler University Business School" },
    { id: "3ffd7c05-b680-4864-8d05-5c4d1552d95a", name: "Limerick Institute of Technology" },
    { id: "67e80471-ebb2-44c0-8be5-e82ee7d335b3", name: "Limerick International Study Centre" },
    { id: "8eced6dd-4b56-4bcf-ac14-6d23de9c70dc", name: "Limestone College" },
    { id: "579d36ac-67c7-48be-b6ea-c80477971394", name: "Limkokwing University of Creative Technology" },
    { id: "1db38419-d2f3-4f44-a0c3-a07fc8ea0832", name: "Lincoln Christian University" },
    { id: "7b94af89-60c2-44f0-acb6-741f356afd8c", name: "Lincoln College" },
    { id: "16584b2d-614d-4ea3-a17a-066efeb66811", name: "Lincoln College of New England" },
    { id: "a8683357-e284-496e-9178-8a908ccc7d9b", name: "Lincoln Memorial University" },
    { id: "aee98e9f-e3ee-4fb5-b5bc-9e5c7c6c0b75", name: "Lincoln Park High School" },
    { id: "b6232a51-da0f-454b-91d8-cd5fa100494c", name: "Lincoln Trail College" },
    { id: "cb0959ed-b668-4428-8afe-8df454f066b6", name: "Lincoln University" },
    { id: "dd55187f-c05a-46f7-b98e-7fc2c8e32a0a", name: "Lincoln University of Business and Management" },
    { id: "df331f69-1924-4e2c-b3ca-c85ec1cdef91", name: "Lindenwood University" },
    { id: "5d19abf3-e0a5-4b34-a209-a79fa2890da3", name: "Lindsey Wilson College" },
    { id: "c2f27c59-af32-4b94-993a-216d9068ef51", name: "Linfield University" },
    { id: "4f55b6e2-d8e2-426f-a7ea-4a52f7306784", name: "Ling Tung College" },
    { id: "5a5df6b3-5b97-4a68-985f-5d250e9642ec", name: "Lingnan (University) College, Sun Yat-sen University" },
    { id: "696d364f-7019-49ee-8116-eccbb98784e5", name: "Lingnan University" },
    { id: "748e8c7b-4b50-402e-b962-2ba55557f01c", name: "Linguis International" },
    { id: "b5b4d062-f9ce-42fd-b6a3-4e3ac04f30af", name: "Link Campus University" },
    { id: "004d803c-4beb-4f61-a208-279a583a911f", name: "Link School of Business" },
    { id: "ec5985b4-1898-45da-894c-112967ad4f9e", name: "Linklaters Business Services (Hong Kong)" },
    { id: "e92014d6-2280-4cd8-b92f-f72c12313d11", name: "Linkopings Universitet" },
    { id: "8e0489e0-3248-484a-bc37-775ec7c01347", name: "Linn-Benton Community College" },
    { id: "fcb97f61-6825-48e1-9838-c9be60cbde69", name: "Linnaeus University" },
    { id: "e29930be-5351-45b0-99ca-0589ce2a37ce", name: "Lipscomb University" },
    { id: "ad070a61-b8d9-44ba-ac8e-014d179cebdc", name: "Lisbon Business and Government School" },
    { id: "4cd05e08-31f3-4cb1-93a4-2b21427e38d6", name: "Lithan Hall Academy" },
    { id: "1330904c-a54f-40ba-8b41-6836ef14607b", name: "Lithuania Christian College" },
    { id: "9db417a2-cc97-4c77-bfbe-d05cdd583086", name: "Lithuanian Sports University" },
    { id: "26261008-bf58-4077-998a-edfa39388162", name: "Little Prof Bk Center" },
    { id: "05ce030a-962a-47d7-b0ff-b2c5c2af290d", name: "Liverpool John Moores University" },
    { id: "1e6496c5-d431-43a0-8034-4e449ca08c74", name: "Livetext Inc" },
    { id: "5bb699b0-ce10-415c-b460-40c352ea50ce", name: "Livraria Canuto" },
    { id: "14a6045b-4750-4d3f-9207-4a8366b5caeb", name: "LM Thapar School of Management" },
    { id: "3acd1303-60d7-4c6e-a824-a4113a3c0190", name: "LMU Ludwig-Maximilians-Universitat Munchen" },
    { id: "ade18d93-aa3a-45a0-9a05-c65f0277f677", name: "Lock Haven University" },
    { id: "40e1a609-2d93-47ba-a0fb-7d42befa62c8", name: "Lok Jagruti University" },
    { id: "3664b122-5c47-48ee-a07d-21c3ec0dbb45", name: "Loma Linda University" },
    { id: "004cbbd6-8e04-4af5-a71e-6a0b192d6d5c", name: "London Academy Business School" },
    { id: "efbbc40c-d429-4b86-beaa-b5d5ff21d879", name: "London Academy Of Management & Business" },
    { id: "6a784a96-cdd8-4fa4-90ac-e974778a4e82", name: "London Business School (LBS)" },
    { id: "e146bee1-177e-4e84-866b-48361ac41df2", name: "London College of Accountancy" },
    { id: "abce4616-3892-4c65-9fda-addb0d56f1d0", name: "London College of Contemporary Arts LCCA" },
    { id: "3431b8dc-5362-470a-941d-ac90ba5ac1e9", name: "London College of Fashion" },
    { id: "ef21da99-ec23-4ca4-be63-593108166063", name: "London Institute of Technology" },
    { id: "b74753bf-3a67-4fe8-94d9-3f2d7796e2c7", name: "London Metropolitan University" },
    { id: "b6d24b46-edb5-4f8f-87a0-1bf9d3297b7b", name: "London School of Business and Finance" },
    { id: "fb1940f9-266b-4bb4-b8a7-57ce4319b4fe", name: "London School of Commerce" },
    { id: "aae62d6b-9634-4637-8774-156abb131ae5", name: "London School of Economics and Political Science (LSE)" },
    { id: "cdfec2b3-bf78-4ed0-8ee6-a2fe41e33b59", name: "London School of Hygiene and Tropical Medicine" },
    { id: "52c251d3-e800-47d2-862f-49a995d7ce0c", name: "London School of Marketing" },
    { id: "9afa3ac1-dedb-47e2-b638-2e43a596d19f", name: "London South Bank University (LSBU)" },
    { id: "af5e3d04-faab-47df-8116-72e8f18b4269", name: "Lone Star College" },
    { id: "e97d79a8-358d-4790-ad35-918050ecaf8c", name: "Long Beach Comm College Dist" },
    { id: "b59d1258-eec9-4f71-bd79-837bb741c700", name: "Long Island University" },
    { id: "35c1dfd9-a0bd-4d39-9825-74658e4129fa", name: "Long Island University - Brooklyn" },
    { id: "9e48aa89-9dc1-409b-9b3d-dac2b136ecca", name: "Longwood University" },
    { id: "cb070fda-437b-41d1-be43-1c598af28282", name: "Lorain County Community College" },
    { id: "25e1b32a-170e-4212-9b1a-e0545063417b", name: "Lorange Insitute of Business - Zurich" },
    { id: "ca2b416d-da27-4cdb-b373-951f3462adac", name: "Lorange Institute of Business" },
    { id: "e18d5e25-1856-491b-8e4a-87fb83c8f8aa", name: "Loras College" },
    { id: "7316b3c3-528f-48d8-b6c4-e14605278302", name: "Lord Fairfax Community College" },
    { id: "0fe352b1-5aad-41ff-af96-a7a97e38494d", name: "Lorma Schools" },
    { id: "5cd50748-cc36-442d-9027-eda94f27306b", name: "Los Angeles City College" },
    { id: "2bf08f8a-3e88-44e7-9ca8-6d69bdfe91f1", name: "Los Angeles College International" },
    { id: "8667f850-70f3-48d2-a855-091b736a1610", name: "Los Angeles Mission College" },
    { id: "5ebafe49-6b4b-4315-8c71-459fd6518118", name: "Los Angeles ORT College" },
    { id: "5efa758d-c26d-40ff-8ef8-4cf6b943f3a5", name: "Los Angeles Pacific University" },
    { id: "dba17caf-e484-416d-897d-28cd2b0d47c5", name: "Los Angeles Pierce College" },
    { id: "e3117bdf-88e7-4de7-8334-6321968b723d", name: "Los Angeles Southwest College" },
    { id: "ddbf4fba-aa9c-4f43-ad4a-2f85dcd27776", name: "Los Angeles Trade Technical College" },
    { id: "f91185ff-7523-4bba-bbbe-ba6902c9215d", name: "Los Angeles Valley College" },
    { id: "74161aa9-72a9-4611-8b73-d17285df7c1e", name: "Los Medanos College" },
    { id: "1e31121f-101e-47b7-9385-a94615c70131", name: "Los Rios Community College District" },
    { id: "2d96eebb-322a-44bb-b3a5-aed7983e6ad8", name: "Loughborough College" },
    { id: "07570263-5d81-4e71-90e8-3d787b6a7bc6", name: "Loughborough University" },
    { id: "39ac97e1-c7be-46b1-9bf9-a467c9e87ec7", name: "Loughborough University London" },
    { id: "614db89a-9131-4cea-be4c-82d23eb9d347", name: "Louisiana College" },
    { id: "9635b961-12c2-499d-82a9-1511b05009ae", name: "Louisiana School for Math, Science, and the Arts" },
    { id: "fe5f2c93-a313-40af-9207-6458520f1cf0", name: "Louisiana State University" },
    { id: "44bb61ec-e748-4927-9237-3421749c002b", name: "Louisiana State University - A & M" },
    { id: "20f09e4e-ef6d-49ef-be9a-8ceae3e0d16e", name: "Louisiana State University System" },
    { id: "0dd1f101-8b12-4fbb-b33f-1bb5c2db72d8", name: "Louisiana State University - Shreveport" },
    { id: "b4baaad6-1234-4d1a-8916-ff02c62baf3d", name: "Louisiana Technical University" },
    { id: "fc53b4cb-b092-42c8-8ac8-0a904c6de237", name: "Lourdes University" },
    { id: "6ee059dc-5304-463d-8f07-dac7a113683f", name: "Lovely Professional University" },
    { id: "1a48b395-8d35-4cbb-b3ce-e66f9492c005", name: "Lowell High School" },
    { id: "238798a3-dfb4-48a8-86f4-beeadb270c1e", name: "Lower Columbia College" },
    { id: "8a17af3f-c674-4f0a-bcdf-66c9377e0aea", name: "Loyalist College" },
    { id: "5d40d428-7c7b-4b14-9f82-b72fd2e568f0", name: "Loyola High School of Los Angeles" },
    { id: "065afe52-2dd4-49b1-8321-4c9a8b763857", name: "Loyola Institute of Business Administration (LIBA)" },
    { id: "3bf827f7-e4c0-45d9-91c1-b13f9d85e224", name: "Loyola Marymount University" },
    { id: "a72f4cf1-b012-47a1-b3e6-cb5c6c559603", name: "Loyola University" },
    { id: "7b51f1f7-3c2b-4af4-a764-1e3756124e5b", name: "Loyola University - Chicago" },
    { id: "2a09f26c-4757-48a6-867e-ffb47f96f005", name: "Loyola University - New Orleans" },
    { id: "371f7fe5-bb57-4149-a9bd-24fd9be8b515", name: "Loyola University Maryland" },
    { id: "82f05986-17d8-4b55-aa77-5cb28cf825c2", name: "Loyola University Medical Center" },
    { id: "5eea41e6-3be9-49f1-a135-ce7c6dd99047", name: "Lubbock Christian University" },
    { id: "f064bce8-6a3d-4711-97a3-d21be69fd764", name: "Lubeck University of Applied Sciences (Fachhochschule Lübeck)" },
    { id: "3b70d9db-0265-48f7-b987-c540691bfcbd", name: "Lucerne University of Applied Sciences & Art" },
    { id: "e909b7df-814e-44c1-8310-59118fd4f3f5", name: "Luigji Gurakuqi University" },
    { id: "aad8e5e7-5bec-4adf-8b60-ac7cf7a47f5e", name: "LUISS BUSINESS SCHOOL - SOCIETA' PER AZIONI" },
    { id: "8ffb5fd3-ed9a-4cc1-b1b5-41cdc978e9f0", name: "LUISS Guido Carli University" },
    { id: "590fea1f-c8fe-47ab-b54c-2bd73b8e6668", name: "Lulea University of Technology" },
    { id: "3da9690c-446e-4282-b30b-95b3324ffdb5", name: "LUM Jean Monnet University" },
    { id: "5f1cff77-a5fe-4616-8371-612cb1e0f8b2", name: "Lund University" },
    { id: "5bf568de-fdac-47e4-ab1f-238d5745550d", name: "Luther College" },
    { id: "a0796286-2406-4c5b-ab66-99d7f1c48e3a", name: "Luther Rice University" },
    { id: "ead451e1-aacc-4d6d-8c57-bb580f29a164", name: "Lutheran College - Washington Semester" },
    { id: "a09375b0-15fb-4e47-bade-4f3ba9fe00de", name: "Lutheran Seminary" },
    { id: "4aa1cca3-65a4-4edb-9fb0-4822a1d7d808", name: "Luxembourg School of Business" },
    { id: "85dfcd0a-a321-4678-9869-cd8f37a57df7", name: "Luxembourg School of Finance" },
    { id: "8452c042-0b91-4a65-8342-02b4ffeff84c", name: "Luzerne County Community College" },
    { id: "57fc37db-e841-4477-9dcd-d1eac6df2c5d", name: "Lyceum of the Philippines University" },
    { id: "f69ab443-8b06-4a55-989c-76fbb44b30cb", name: "Lycoming College" },
    { id: "96799361-e15f-4ce7-ba3c-c6b898111e4e", name: "Lynchburg College" },
    { id: "46868b57-72ee-4df7-be47-dfc31fe0003f", name: "Lyndon State College" },
    { id: "fd7ceefc-25f7-4bc5-9e0f-571a0cef659c", name: "Lynn University" },
    { id: "327b2b2c-1690-403c-9611-f319d7e9b899", name: "Lyon College" },
    { id: "4dd8b88d-990f-4c71-adc9-a3c1079e70fb", name: "M K Bhavnagar University" },
    { id: "2e8ab982-eeb8-41ff-878c-48788a0c48c3", name: "M P Birla Institute of Management" },
    { id: "0e43f34e-b65b-4e3a-8929-48c39060c7b2", name: "M S Ramaiah University of Applied Sciences (MSRUAS)" },
    { id: "f8d29dcb-69ed-47af-8ce6-4075c3fb7926", name: "M. Uhrich & Associates" },
    { id: "75573156-337f-44b8-bbe0-473f0e9215fa", name: "M.S. Ramaiah Institute of Management" },
    { id: "757409ac-1247-4fcb-8e66-ad2a4c56b6ef", name: "Maastricht School of Management" },
    { id: "8edc9066-56a8-46f0-9005-2e05cd3fd40d", name: "Maastricht University" },
    { id: "af3803ff-9ea3-4a13-b151-62a4fb670247", name: "Macalester College" },
    { id: "23adbb88-034a-440d-b6e7-b007dc458a3f", name: "Macao Polytechnic Institute" },
    { id: "e990021b-7733-4d9a-8076-7defc26364a1", name: "Macau University of Science and Technology" },
    { id: "a057aaa9-175f-4589-8d64-967d68e9ac5d", name: "MacCormac College" },
    { id: "ff4b66e4-4082-4ae8-8b98-cb17e1ef952b", name: "MacEwan University" },
    { id: "7037a5f8-8141-4531-acae-8c30100d6b6d", name: "Maci-Mib" },
    { id: "ea3b30ee-079c-458e-a3b5-134a496e2b27", name: "MacMurray College" },
    { id: "80c06032-e656-4bd0-b446-f19b50a054cd", name: "Macomb Community College" },
    { id: "9818b56f-7dd1-45cb-b079-6df35c880375", name: "Macon State University" },
    { id: "a1507127-78c1-4cf9-bd10-e27ff695fe35", name: "Macquarie Community College" },
    { id: "2089a142-fc1b-4ef4-8efd-efb9e608e8ae", name: "Macquarie University" },
    { id: "c21f2481-a2f9-402b-b5be-2b2cb790c2a1", name: "Madinah Institue for Leadership and Entrepreneurship" },
    { id: "948fe28c-05f2-4b0a-9434-77e83d0239ce", name: "Madison Area Technical College" },
    { id: "8a4e9999-b2d1-48ba-8d99-2d7a95b8711c", name: "Madonna University" },
    { id: "d50f462b-be60-4b78-813f-8cd58edd773d", name: "Madonna University School of Business" },
    { id: "50ccebe9-bdd1-4a38-96e1-28c5cf8bf84b", name: "Madras Institute of Business Management MIBM" },
    { id: "ad42e236-9bc2-45b8-9c44-42d4d1e7ce2f", name: "Madras School of Economics" },
    { id: "2399b318-b72c-44bb-9617-3781c5bef364", name: "Madurai Kamaraj University" },
    { id: "3f366c95-d5d5-435b-98f9-b629738425db", name: "Mae Fah Luang University" },
    { id: "f26d0bdf-d8db-4f6f-88cd-8e845b833fda", name: "MAEER'S MIT School of Business" },
    { id: "d2bbfe22-0875-4bbc-8373-520a2a93887e", name: "Maersk" },
    { id: "7d06c050-b1c4-4f14-b29f-b896cf1a415b", name: "Magarpatta Institute of Management & Technology" },
    { id: "6dbd593e-ce1d-43f7-9deb-4a3bfaeef0c2", name: "Magna Carta College" },
    { id: "73181916-b818-47c6-8686-1acd4fba7462", name: "Magyar Nemzeti Bank" },
    { id: "5023afac-ce8e-40b1-9d26-be78757e84b0", name: "Mahachai Institute of Automotive Technology" },
    { id: "1cda9137-8a19-4f0e-a0aa-fe80d03359b1", name: "Mahamaya Technical University" },
    { id: "8d5e95c6-d6e8-4206-ab99-c4eb69e569fb", name: "Maharaja Agrasen Institute of Management Studies" },
    { id: "16a633c5-39e7-4b39-bcc9-a68417554d7e", name: "Maharaja Sayajirao University of Baroda" },
    { id: "916ee311-1594-4e78-992b-37625e39c185", name: "Maharana Pratap University of Agriculture and Technology" },
    { id: "6ac85536-3870-4149-a6de-fe60f3f9706b", name: "Maharashtra Knowledge Corporation" },
    { id: "ce9e4dfc-ab62-4726-baff-35b7507417ed", name: "Maharashtra State Skills University" },
    { id: "c6562e39-6843-42b8-a0d0-9c23f83eb133", name: "Maharishi Markandeshwar University" },
    { id: "0609acc0-9be2-42e7-83eb-1c9ff0526eb6", name: "Maharishi University of Management" },
    { id: "1a113351-67de-4bd8-8575-eeb209796cd8", name: "Maharshi Dayanand University" },
    { id: "1e283638-0fab-4faa-b126-8de55f0b5a11", name: "Maharshtra Educational Society" },
    { id: "1dc2595c-4ed2-49c9-92c8-7e62f4531e77", name: "Mahatma Gandhi University" },
    { id: "79118cb7-f55e-4066-a7e9-6ede00b67fb5", name: "Mahatma Gandhi University Kerala" },
    { id: "694aefc9-3489-464b-b71f-8283e863fbe8", name: "Mahidol University" },
    { id: "774dfae2-b5e3-48a3-850a-7cc76f8e4c8d", name: "Mahindra University" },
    { id: "19c596e0-e7dd-4d2e-9053-7ddb3e103f6c", name: "Maine College of Art" },
    { id: "7f19b28b-3b5f-45b2-b943-e3e4838a9503", name: "Majan College" },
    { id: "05d8e522-eb00-449f-ba1d-e3534a32fa46", name: "Makadok Workshop" },
    { id: "1384d883-7f88-43f0-b3c7-002ea38760aa", name: "Make My Trip" },
    { id: "7d0e6aa9-fc59-4469-be95-f8b71e535c14", name: "Makerere University" },
    { id: "92382a13-0125-4ae3-af97-45030361dc8a", name: "Malardalen University" },
    { id: "d5ba57b6-948f-4066-93e1-c0405e2f1bd6", name: "Malaviya National Institute of Technology, Jaipur" },
    { id: "0df64bcd-4f1e-40d2-b658-e1f69481ca90", name: "Malaysia Institute for Supply Chain Innovation" },
    { id: "14a2e57d-0dd3-483a-a85d-6176014e3047", name: "Malaysia University Of Science & Technology" },
    { id: "c8a669f2-8747-4f31-b802-a97adf8e76b6", name: "Maldives National University" },
    { id: "8d08b48b-79ca-4b88-bc54-62eea58f8267", name: "Male Business School" },
    { id: "bfffdf21-e994-4e88-a7df-20ee95e6b8ff", name: "Malmo University" },
    { id: "0d540773-9591-4c85-aa45-5b4cfc6e8936", name: "Malone University" },
    { id: "d8c8f6fb-64ea-4d79-ab6c-697c8f2d3e87", name: "Maltepe University" },
    { id: "52400642-93f3-42b1-97d1-0f377b9f9630", name: "Management Center - Innsbruck (MCI)" },
    { id: "98f8eba8-74e2-4232-aac0-dae002d06857", name: "Management Centre Europe" },
    { id: "1823fb5a-8704-4753-841e-c969a959aae2", name: "Management College of South Africa (MANCOSA)" },
    { id: "2b734bb4-aa72-48c7-9fc5-829b73ab716a", name: "Management Development Consultants" },
    { id: "dea30c5e-2a7b-46b9-ba20-6ec7538b36bc", name: "Management Development Institute - Gurgaon" },
    { id: "a4f52539-7048-443a-9771-56dc9144c3a8", name: "Management Development Institute - Murshidabad" },
    { id: "52c06d07-b446-4d0d-95dc-f4d7283a6b1e", name: "Management Development Institute of Singapore MDIS" },
    { id: "b6f29878-9e0d-4e68-bfaa-8499e4ccafaf", name: "Management Education and Research Institute" },
    { id: "218620fb-7b2a-430f-a34a-c95ca4cd4b76", name: "Management Institute of Canada" },
    { id: "ed0c0b02-fec6-4850-b645-824045b331e7", name: "Management Institute of Finland" },
    { id: "5319d3e4-0171-4a52-8297-fc490a6cc8f4", name: "Management Institute of Paris" },
    { id: "9098a1d9-3981-4ea1-a7e3-b3243b4217cf", name: "Management Training and Development Center" },
    { id: "0fb927dd-3b03-44d7-b364-0669bcd7d97c", name: "Manarat International University" },
    { id: "8254a3c6-8849-4e3e-819d-6cfb7d0e349c", name: "Manatee Technical College" },
    { id: "ebe6bc5d-0fa2-44e4-818a-842031d92388", name: "Manav Rachna International University" },
    { id: "8ed41354-16d6-40e3-a0ac-433b9e745940", name: "Manchester College" },
    { id: "bb12a534-db32-4d6f-90a3-0847e42fc58d", name: "Manchester Essex Regional School District" },
    { id: "871f254b-6dac-4bb3-81d9-ee1d9c6eaa20", name: "Manchester Metropolitan Univ" },
    { id: "e4fad88b-b3ff-4b53-b0a6-24b262bf3993", name: "Manchester Metropolitan University" },
    { id: "1ac22f75-ae10-4cf4-a0be-c923e164c23f", name: "Manchester Science Enterprise Centre" },
    { id: "4eead71a-8fb8-49b9-af6e-8c420c97f703", name: "Manesh University" },
    { id: "f3861ff1-4274-4387-97bb-35836a961b16", name: "Mangalayatan University" },
    { id: "9f5e7d2d-ff41-4853-b4c5-d0fa5e72508d", name: "Mangalmay Group of Institutions" },
    { id: "434ecd3a-790c-4e0d-805c-cb652f7b4e89", name: "Mangalore University" },
    { id: "39c4a30e-234c-43e8-988d-3165decd1d57", name: "Manhattan Christian School" },
    { id: "a501c0af-d59e-4dcb-a9ca-c6e811e70aed", name: "Manhattan College" },
    { id: "13a69541-6ce5-4b24-bb7d-7b7cc4990aaa", name: "Manhattan Institute of Management" },
    { id: "8802c60d-7376-492a-b3b6-e541123ad4ce", name: "Manipal Education" },
    { id: "4b4f264c-5ab4-4bcc-884c-57578bad62ed", name: "Manipal University" },
    { id: "54a6896c-f85a-41ae-846f-f3e7d469d9e7", name: "Manipal University Jaipur" },
    { id: "dd1cc821-c1a8-45af-bdea-5728b5ee5999", name: "Manipal University Dubai" },
    { id: "63047c67-8419-46ff-9c30-98351c57802a", name: "Manitoba Institue of Trades & Technology" },
    { id: "031f25d6-6b47-455e-be7a-5ecd9552c33c", name: "Mannaz" },
    { id: "cfee9b05-ec87-4220-ae73-b4306d0c7d6c", name: "Mannes College of Music" },
    { id: "6e57ab78-1ef8-4ce0-a3c4-ddea8acc596d", name: "Manonmaniam Sundaranar University" },
    { id: "70ddd9b5-e506-42a7-95b9-a071dcfd4570", name: "Mansfield University of Pennsylvania" },
    { id: "b6a740ff-a45d-4f11-8467-a48294bbd2ac", name: "Manuel S. Enverga University Foundation" },
    { id: "ece12322-d1f5-414f-8561-b9ddefb584a6", name: "Manufacturing Institute" },
    { id: "acc3f67e-f594-43bb-90ab-524cd69ca368", name: "Manukau Institute of Technology" },
    { id: "8f6566c0-fe9f-4cb6-810a-4df9ca52e3a8", name: "Maple Woods Community College" },
    { id: "4cd4fca2-0159-456b-a654-6cdca6072b03", name: "Mapúa Institute of Technology" },
    { id: "e28f58b5-b5f5-40d2-82f6-9fbd83ea485c", name: "Marahasarakham University" },
    { id: "485f1d7f-a540-410a-aaca-b4739828a66e", name: "Maranatha Baptist University" },
    { id: "db817c30-76fc-49e2-b5db-debc39f3ee98", name: "Marbella International University Centre" },
    { id: "b906a120-08f2-4921-b2a8-3f936984b67a", name: "Marble Falls High School" },
    { id: "5dfa1c40-fb55-4898-98c1-0771fd7ed2c4", name: "Marcus Oldham College" },
    { id: "6ee2f5cf-27b7-4a91-a24f-ed9d3d872125", name: "Mare de Dèu dels Àngels" },
    { id: "2acdb468-334d-4a93-9ed5-18348358c133", name: "Maret School" },
    { id: "370bf48f-69c9-4c83-9e89-a59791f4821e", name: "Maria College" },
    { id: "c40afbbc-028c-4591-a42d-8bb2bc215f44", name: "Maria Curie-Sk?odowska University (UMCS)" },
    { id: "172fac74-9817-409f-99fc-36ff82a662d7", name: "Maria Garcia Ochoa" },
    { id: "f0151e09-58a7-4f89-bb78-f424c1be76b8", name: "Marian College - Wisconsin" },
    { id: "899abfbb-bdad-4edb-9c6c-c163f84c05cd", name: "Marian Court College" },
    { id: "2985e827-a436-48ba-ad7e-637c4d5cc600", name: "Marian Institute of Health Care Management" },
    { id: "a4715f4d-4bfd-422b-90c2-bf1263f0b468", name: "Marian International Institute of Management" },
    { id: "e8fa8b22-0d12-4bd2-8216-6970a95738e3", name: "Marian University" },
    { id: "093d19fa-eede-42e4-b898-5ff86d87c326", name: "Marian University Indianapolis" },
    { id: "fce0dd8d-d94c-42e7-9c1a-562b113ae28a", name: "Marianopolis College" },
    { id: "a6e5b74e-5aa6-4dc2-b28f-6363413af443", name: "Maricopa Community Colleges" },
    { id: "854f7d33-9801-4e9d-a967-2fa116644fa9", name: "Marietta College" },
    { id: "f06c3fcd-b9e5-4e28-b77b-f27babb48e96", name: "Marine Corps Tactic and Opera" },
    { id: "82581adb-fed1-4889-af57-793da8d0efab", name: "Marine Corps University" },
    { id: "37910d9b-2558-45f7-a40e-a77f9309d916", name: "Marine Institute" },
    { id: "331f5c7b-781f-4a52-a90e-1440c7ed2ee5", name: "Marine Institute of Memorial University of Newfoundland" },
    { id: "00b57ac7-e401-4dc1-900b-faf7a9043145", name: "Marion Military Institute" },
    { id: "62d43394-7a63-4734-a4eb-4156f2c619ff", name: "Marist College" },
    { id: "759c09b2-e3c7-4fc7-8473-4229f8e51115", name: "Maritime Institute of Technology & Graduate Studies" },
    { id: "c8c27e4c-8397-4005-9759-6be0d55ef7d7", name: "Markedshøyskolen" },
    { id: "a10af2b5-7ad0-47a4-885e-a4015f150a70", name: "Market Focus Consulting Ltd" },
    { id: "9e12862e-0ff5-4e3d-a636-886bce0125d7", name: "Marketing Center Munster" },
    { id: "a16a4de3-a424-4120-bea0-bd504a7a257b", name: "Marlboro College" },
    { id: "335db38c-5e8d-4c6e-a810-bce191400faa", name: "Marmara University" },
    { id: "528ea3b3-25e0-4cb6-b0cc-5edaa41b4c25", name: "Marquette University" },
    { id: "7ceb919d-198b-4159-83c1-311c11c7c5bf", name: "Mars Hill College" },
    { id: "5b27d7d3-a3ba-44b7-855d-30ee4c01da85", name: "Marshall University" },
    { id: "90d35082-34fe-4b8f-874c-45c0a32ac6a7", name: "Marshfield Public School District" },
    { id: "a1eb7658-3f09-4637-bd16-b335baa17b1b", name: "Martin Luther Universitaet" },
    { id: "69e7e900-548f-42db-ac4d-8d16b32873ed", name: "Martin University" },
    { id: "3ef44810-c620-4ee0-acbf-995308294fd9", name: "Marwadi University" },
    { id: "0ad75e37-c641-421c-95c8-f2ce01fceddb", name: "Mary Baldwin University" },
    { id: "899de792-5764-4d8f-a25c-eeafe55edd29", name: "Mary Grove College" },
    { id: "390df8bc-0728-4cf1-b429-7f9f02adde13", name: "Mary Washington College" },
    { id: "140df837-df88-44bd-bdfe-fb94d7019972", name: "Marygrove College" },
    { id: "3839e345-e840-4029-ba62-a98f188e8e89", name: "Maryland Institute College of Art" },
    { id: "1ab3b7c6-2f7b-4c33-9903-310de29f774c", name: "Maryland University of Integrative Health" },
    { id: "9755afac-d843-4fdc-84e1-14edec92cafb", name: "Marylhurst University" },
    { id: "445d66e0-6e2c-4b42-bed7-2cd580d9e05d", name: "Marymount California University" },
    { id: "16949424-2b92-46c1-a735-62b54a03b565", name: "Marymount College" },
    { id: "5c02e572-0ae4-48ca-ab73-6656a450bc28", name: "Marymount Manhattan College" },
    { id: "4c6c3eaa-b251-43eb-896e-cb99c79c965b", name: "Marymount University" },
    { id: "9646081b-4031-4a42-befc-602d02c0e2a5", name: "Maryville College" },
    { id: "fb1465ec-35ce-4956-ad28-8ec7eeb025b2", name: "Maryville University" },
    { id: "a27ab499-5704-4a72-b3b3-75dd9c95b53d", name: "Marywood College" },
    { id: "098fca82-1e49-418e-97a5-7434cd2d0935", name: "Marywood University" },
    { id: "1ce02cc6-ef23-4d93-bd31-147d5cb9d778", name: "Masaryk University" },
    { id: "719421a4-9261-477f-98eb-9cdf386b4bd7", name: "Masdar Institute" },
    { id: "a285e74f-9781-4129-8d2e-7cd436806b47", name: "Massachusetts Bay Community College" },
    { id: "380c448a-8f82-414e-9550-7b4b4036d98b", name: "Massachusetts College of Art and Design" },
    { id: "0195ffb4-dfe0-4895-88f6-5add536a4032", name: "Massachusetts College of Liberal Arts" },
    { id: "8ab12b20-ca3b-491c-88a9-8df50c242d2f", name: "Massachusetts College of Pharmacy and Health Sciences" },
    { id: "11c38c11-62e9-425a-8012-4e184a1e67a6", name: "Massachusetts General Hospital" },
    { id: "b09e7e9f-b880-444f-8099-6a8f0136dda1", name: "Massachusetts Institute of Technology (MIT)" },
    { id: "9645b92d-57cc-4fd0-a146-2c0a8843398c", name: "Massachusetts Maritime Academy" },
    { id: "577d7992-57c7-4b0f-a89b-e6ef31660e16", name: "Massasoit Community College" },
    { id: "9df6aceb-6a17-4d04-80f3-fea78b8faddc", name: "Massey University" },
    { id: "893ee1a2-17a4-4066-90c9-9b4d80c8a301", name: "Masterplan com GmbH" },
    { id: "a7e5e8fd-7853-439f-8223-f630be9b5bc3", name: "Masters College" },
    { id: "26167576-d015-4cdc-8b72-7b6666306ab2", name: "Mata Vaishno Devi University" },
    { id: "9c4a680a-759c-49fb-bf43-f43a59eb127a", name: "Matej Bel University" },
    { id: "3e988c46-3ddf-43bc-995a-faad10e10a0f", name: "MateriaBiz Business School" },
    { id: "85ac93d6-54e1-4613-b278-14913bb79ba8", name: "Mathias Corvinus Collegium" },
    { id: "a9f457eb-f231-472b-a292-39595140e230", name: "MATS Institute of Management & Entrepreneurship" },
    { id: "68138dfe-4bce-48c7-9901-100c63722927", name: "Matthews John Hopkins Medical" },
    { id: "1cc1e377-126a-44de-8a4c-15d5605a7f0f", name: "Mattia College" },
    { id: "ff48d85b-0c29-48eb-ae4d-9a63522d69bb", name: "Maulana Abul Kalam Azad University of Technology" },
    { id: "293bd925-c407-40f3-8602-c39688650221", name: "Maulana Azad Medical College" },
    { id: "10998488-355b-4d44-9d25-600762a6e5c5", name: "Max Planck Institute for Comparative and International Private Law" },
    { id: "e716ac1a-bc4c-4f00-9fe6-08975539ecab", name: "Max-Planck-Gesellschaft" },
    { id: "221857f6-b79f-47db-b42f-51905689b326", name: "Maxknowledge,Inc" },
    { id: "c6fd3d7c-2ce5-4fa1-9e7e-b977c271bd01", name: "Mayo Clinic" },
    { id: "bf5db7ec-4bec-43ca-b3e5-4b565d9bbb3e", name: "Maysville Community and Technical College" },
    { id: "c20ca2a2-ea81-4dc3-bb25-7e566c0c3aee", name: "Mazars" },
    { id: "b3b18dcb-9440-4fd2-9b10-654048d9025c", name: "Mazars Academy" },
    { id: "4e017977-5f55-4f46-92df-28bcfd75d394", name: "MBA Association of Ireland" },
    { id: "e1a31171-24b7-4a15-8ebe-e5f437e26123", name: "MBA Business School" },
    { id: "a149c16d-8328-4338-99f5-9de9018e641c", name: "McCann School of Business and Technology" },
    { id: "d11375f7-e268-4b4e-910c-ac789821d740", name: "McCracken County High School" },
    { id: "46a0219a-3c9d-4edf-82c9-e54b01f3702f", name: "McDaniel College" },
    { id: "93ff32f5-2bcd-482c-b5b0-3e7bc304b3cf", name: "McGill - University - School of Agricultural and Environmental Sciences" },
    { id: "8bf37340-1410-48dc-86ab-02401edbe191", name: "McGill University" },
    { id: "62a19168-67d5-461a-942a-2c353e9e65d8", name: "McGill University - School of Hotel Management" },
    { id: "6589c8b6-c6b3-40e4-946a-ea8745dfcca7", name: "McGraw Bookstore Elmira College" },
    { id: "a6f9896f-8552-4ac0-a52c-fce982659c46", name: "McHenry County College" },
    { id: "a8a4fd5e-fdcd-4cb0-94af-124e09d00a10", name: "McKendree University" },
    { id: "2b28992c-33b4-46a6-921f-bcf5217ef4aa", name: "McKinley College" },
    { id: "77861730-67a0-4c92-b0fe-1494dc0d1788", name: "McLennan Community College" },
    { id: "a97a85de-2139-4e82-8a1a-793ce39ff53a", name: "McMaster University" },
    { id: "99fdcba6-07b6-4509-b570-27eb20b15a0d", name: "McMaster University Campus Store" },
    { id: "8f9caef9-fde4-45bb-98bd-53ec031e9fb7", name: "McMurry University" },
    { id: "02e201e3-ca90-4256-9c66-4288b865db3d", name: "McNally Smith College of Music" },
    { id: "de275f62-a2a1-4158-8a24-479bea62a8a1", name: "McNeese College" },
    { id: "61d69369-c2a2-4997-9bd4-9e8970cba3cb", name: "McNeese State University" },
    { id: "e0c692d1-dfbe-4003-a04a-ab1ebc2e33b6", name: "McPherson College" },
    { id: "176988d2-d50d-4aa7-9e92-bfce481a5ff8", name: "MDDP Business Academy" },
    { id: "fd8ef9a5-7443-433a-9278-a9355d2cafee", name: "MDE Business School" },
    { id: "d44165f3-7d9a-4d08-a7fc-939597324bb4", name: "MDI Management Development" },
    { id: "fc42ec98-03cb-4bef-98db-210786ade71c", name: "MECA CFO Academy" },
    { id: "a94f9448-1a08-495f-86f1-fc6588bba6b9", name: "Medaille College" },
    { id: "395db65d-74e8-481a-995b-da406312dc2b", name: "Media Design School" },
    { id: "d2841025-3643-4af4-95c4-adeb6bad488b", name: "Media Training Resources" },
    { id: "a00f8fcc-50db-43ff-a494-e6fb00a2cba7", name: "MediaTech Institute" },
    { id: "6cfae78f-023e-4f59-917a-5615d9e7e5d2", name: "Medical College of Wisconsin" },
    { id: "497bbb4d-3cfa-45a9-9baa-3949d7ee5354", name: "Medical University of South Carolina" },
    { id: "0ecba96a-7866-4a96-b33f-d63c8d91bef7", name: "Medical University of Vienna" },
    { id: "083d6b33-f8bb-4ee1-978e-b60bd2621f7b", name: "Medicine Hat College" },
    { id: "6b366a51-8abb-459e-b959-d194b95bdff6", name: "Mediterranean College" },
    { id: "6d784e3d-b09d-4a65-8c40-ddb86c55faa8", name: "Mediterranean School of Business" },
    { id: "5a5a7c13-da80-41b8-98ae-56d15358cb02", name: "Megatrend University" },
    { id: "531afbd4-a8c4-4c53-a802-eb21ebf90544", name: "Meharry Medical College" },
    { id: "47f92ec9-0de9-4842-a00b-58f13c4e50b7", name: "Mehran University Institute of Science & Technology Development (MUISTD)" },
    { id: "35cd56f0-fb3a-41fd-8a65-dd5f61d25e57", name: "Mehran University of Engineering and Technology" },
    { id: "ab27425b-cf5c-47a2-817f-6d43f60d0535", name: "Meiji Gakuin University" },
    { id: "39537d21-66e0-4f13-8738-5a2f84749eb8", name: "Meiji University" },
    { id: "e05dc692-3130-4009-9aa6-d9f39cd94644", name: "Meisei University" },
    { id: "82e58a77-cde0-46b7-bff8-d35a3c36a451", name: "Mejiro University" },
    { id: "60e735f3-1ba2-4fed-a219-728a951548e4", name: "Melbourne Business School" },
    { id: "697b50e0-116c-49f6-96d2-07c88ed49a47", name: "Melbourne Case Study Services" },
    { id: "c0bbefb8-8436-4c94-afd1-474f4d73309a", name: "Melbourne Central Catholic High School" },
    { id: "7e7bcf9f-aec9-43b8-afdc-93b316caf064", name: "Melbourne Institute of Technology" },
    { id: "611c2fb9-5da9-42f5-9a98-027db4c97b3b", name: "Meliksah University" },
    { id: "2697337b-8727-49e3-9f64-985fa55f77ab", name: "Mellen School District" },
    { id: "e2e3ce2b-d44c-4495-89b4-be1b79f3bae1", name: "Memorial Sloan Kettering Cancer Center" },
    { id: "00a7add6-c0c6-4fcc-a061-d040bac46c02", name: "Memorial University of Newfoundland" },
    { id: "f456f901-9fba-4d13-a005-10f82d51a45d", name: "Mendel University" },
    { id: "f8f2cad6-6686-4cd3-b56e-6ca405e90ee4", name: "Mendocino Community College" },
    { id: "aff7dec7-8206-415f-92f8-ef0609086154", name: "Menlo College" },
    { id: "5cd78d71-9c15-4316-8a6a-2b4ce0c2d1be", name: "Mepco Schlenk Engineering College" },
    { id: "0ff584b2-9ece-40b3-920d-a3062a308629", name: "MERC Institute of Management" },
    { id: "f1de23fb-605d-4428-be52-d6e6114abb8d", name: "Merced Community College District" },
    { id: "a767bc74-efaa-4a4b-8592-4f0fdb46fe8c", name: "Mercer County Community College" },
    { id: "89a54d35-8753-4568-b49a-8293557fc7a7", name: "Mercer University" },
    { id: "146b72b2-d33d-4f50-9f7c-20ba4c1b3f0e", name: "Mercure Academy" },
    { id: "aaca457d-fd50-421f-a747-a7c7a35bb94d", name: "Mercy College" },
    { id: "77c578dc-7704-4f89-b229-dcc902011a76", name: "Mercyhurst University" },
    { id: "4e714724-22d2-4851-b301-f25030d590a5", name: "Mercyhurst University Bookstore" },
    { id: "5f6e74ff-a60e-45cb-ace2-808c85e080e8", name: "Meredith College" },
    { id: "e82983b1-349d-489d-a520-eeb10a543cf8", name: "MERI Group of Institutes (MERI) - MERI College" },
    { id: "22182f15-f70c-401f-8ca7-3ebc97421700", name: "Meridian University" },
    { id: "a546500a-8571-4499-b0ef-37d5e2fefa8a", name: "Meritus University" },
    { id: "18bbef8e-d294-4ecc-a28b-b63004a726bd", name: "Merrimack College" },
    { id: "aef78bb3-359c-4bdb-bb97-de80b21df673", name: "Merrimack Education Center" },
    { id: "e34626af-73ea-473f-82d3-278d0c299f04", name: "Merritt College" },
    { id: "e71cdb5f-d5df-4024-b973-8e1db5ba6caa", name: "Mersin Universitesi" },
    { id: "8d2f23cb-4909-48c1-afb9-4dd2b26d9baa", name: "Mesa Community College" },
    { id: "f46d0b2b-da3a-40d7-bf57-80569c3e2e80", name: "Mesa State College" },
    { id: "afa17612-202a-4ed8-b5e8-8ad530c62627", name: "Messiah University" },
    { id: "8caa4798-ceba-4256-ab96-5b1b44d7887e", name: "MET Bhujbal Knowledge City" },
    { id: "381156b7-2af0-47fe-aef0-85e36fb56cb7", name: "MET Institute of Management" },
    { id: "dbf51293-601b-45d0-9a24-2720023d6070", name: "Meta Business School" },
    { id: "edefb4ac-a47c-4ce7-83ff-2b01941b9513", name: "Meteksan Printing & Industrial Trade Cor" },
    { id: "38dafc0d-79ee-437a-addf-961c4aece212", name: "Methodist College" },
    { id: "92cb198c-18fd-4ca8-b7d6-a0c0b7fc3741", name: "Methodist University" },
    { id: "2f2c5a0b-c5d5-44bb-b632-6c7ee816befe", name: "Metro State College of Denver" },
    { id: "23c505cd-7323-4d7b-b9ea-7d60c43447d3", name: "Metropolitan College of New York" },
    { id: "68de61d4-2301-4d58-9531-ddcefd944b36", name: "Metropolitan Community College" },
    { id: "1e729ddf-6a00-45b4-ba3b-01315f0582ea", name: "Metropolitan Nashville Public Schools (MNPS)" },
    { id: "8f94b02c-de46-4aa5-9180-0fab1281847e", name: "Metropolitan State University" },
    { id: "7bf7574d-c784-41f0-985d-c5320562e56a", name: "Metropolitan State University of Denver" },
    { id: "4d767766-503b-46a8-a3dd-6cc39ad2b37b", name: "Metropolitan University" },
    { id: "18fcc1f4-267b-4718-8ef2-9f115c06bd1b", name: "MHMK - Macromedia Hochschule fur Medien und Kommunikation" },
    { id: "fb59797b-49bc-4e51-af51-4af8356034ce", name: "Miami Dade College" },
    { id: "8df279eb-0582-4364-9412-ce1dbf3544b4", name: "Miami University Ohio" },
    { id: "f333d314-4a0b-49f7-9885-f8981ce39d0a", name: "MIB School of Management" },
    { id: "d4f63f2d-4259-44df-ac14-52d16c4bae57", name: "MICA" },
    { id: "4884a1b8-47a6-4ee1-82a0-4674091935ff", name: "Michigan School of Professional Psychology" },
    { id: "475f47f5-f74f-41e9-b289-85a45aa14ae9", name: "Michigan State University" },
    { id: "de4c4d28-b38d-4564-b84a-62684bf475b6", name: "Michigan Technological University" },
    { id: "03f6b36d-52f5-4ac7-b3ef-b68ef6fe4374", name: "Michigan Theological Seminary" },
    { id: "62f6b0f3-b789-497e-98d3-3216747b2e84", name: "Mid America Nazarene University" },
    { id: "661b268e-60ca-493c-acf5-4509dfcbeedf", name: "Mid North Coast Community College" },
    { id: "25a118ff-550a-4bf0-b87d-99e8ca1e8394", name: "Mid Sweden University" },
    { id: "74538812-1fbc-4ac8-bf3b-33ada239fbe3", name: "Mid-America Christian University" },
    { id: "2954820f-4596-418d-9472-00c0345f5b39", name: "Mid-Continent University" },
    { id: "914e5ba9-ade8-4899-8a00-be064fcfbf6d", name: "Mid-State Technical College" },
    { id: "9fa947a9-5e3f-40e2-8ae1-dcb58d391a68", name: "Mid-West University - Nepal" },
    { id: "bf63b7bc-4964-450e-bf6c-48eaa8e39bb4", name: "Middle East Technical University" },
    { id: "1ba0fc6a-1add-4e56-ba4f-323b751fcce1", name: "Middle Georgia State University" },
    { id: "efd3a5e4-aac9-46c8-8e17-ff66b43546f8", name: "Middle Tennessee State University" },
    { id: "b1efa447-57c3-4c5c-b140-cafe7cb5f764", name: "Middlebury College" },
    { id: "0201fb1c-6a4d-41b7-9de5-bfceb9e48359", name: "Middlebury College Store" },
    { id: "15060c02-e0a7-4142-a6b9-01f419907d4f", name: "Middlebury Institute of International Studies at Monterey" },
    { id: "d522c8ab-e1fd-4f39-85f8-717699fa4982", name: "Middlesex Community College - Bedford" },
    { id: "f7c89224-bd34-47b8-89a7-4236d6fb02e6", name: "Middlesex Community College - Lowell" },
    { id: "031bd2b1-1c4b-4978-8667-acb3ffb3f5b4", name: "Middlesex County College" },
    { id: "865dc37a-101d-49c7-9247-2c473069d4e4", name: "Middlesex High School" },
    { id: "58c77af5-ef3b-4939-a6d8-5a5e05699202", name: "Middlesex University" },
    { id: "45a016e9-5691-490c-b55d-11be9f5c70a1", name: "Middlesex University Dubai" },
    { id: "0dcdac14-ad8a-4daa-aa89-5dcd98a5c154", name: "Midland College" },
    { id: "b114c32f-af7c-47d3-82da-1dd2e0446a15", name: "Midland Lutheran College" },
    { id: "1d8fde39-13d2-4c36-b55b-d15e3aae8c6e", name: "Midland University - Nebraska" },
    { id: "62f9e6a3-0ad9-4b01-aeb6-de6b75c81943", name: "Midlands Technical College" },
    { id: "da51818f-29c3-49e3-9979-48dfe4444564", name: "Midstate College" },
    { id: "7edf90e4-95e8-44f0-aaed-5af6b47734c2", name: "Midway University" },
    { id: "47c26227-5dc6-4808-85f7-abb0d28ad52a", name: "Midwestern Baptist Theological Seminary" },
    { id: "19dd67a2-47fd-4422-a94f-4f8b987997a6", name: "Midwestern Career College" },
    { id: "db581031-6d30-4681-91b5-bdcce2f5fdc8", name: "MIDWESTERN STATE UNIV" },
    { id: "5f5a6b82-9e0f-4566-b6ca-9407e4eafc1a", name: "Midwestern State University" },
    { id: "a71bb197-ae10-4459-ac8d-7ada6b244ae6", name: "Midwestern University - Glendale" },
    { id: "84974dd1-018c-40d4-a96f-1e0eb222631f", name: "Mie University" },
    { id: "811b86b3-d62c-4af5-ae5d-f5d6e8e8ac3c", name: "MIEL Christian School" },
    { id: "d51fcd5b-32ff-45d7-b5af-867d896d3147", name: "MIET Group of Institutions" },
    { id: "ef1a0520-e593-4513-8075-8d4fdfc58039", name: "Miguel de Cervantes European University" },
    { id: "b7cfbef4-177d-4c7b-aab7-26f7ef5867d8", name: "Mikkeli University of Applied Sciences" },
    { id: "33870f36-21ae-4262-ad1c-e3454fe0d0f3", name: "Milano Fashion Institute" },
    { id: "570f0c4f-5146-453f-989c-08aa23a3ef8f", name: "Mildred Elley College" },
    { id: "0c0023d6-1eea-40e0-9428-cabcb02a5e91", name: "Miles College" },
    { id: "0a74256b-249e-4c37-a0c3-416e6a857fbd", name: "Miles Publication Pvt. Ltd." },
    { id: "f5d8bdc9-6d64-4a8e-bf76-078f6c948bd5", name: "Millennia Atlantic University" },
    { id: "ed824414-5b6a-4d35-a21b-628d4292be34", name: "Millennium Academy of Professional Studies" },
    { id: "5cf229ef-ec9a-47de-8a7a-1a824e7a1fd5", name: "Millennium Print Group" },
    { id: "4ba190a4-5097-482d-aaf3-d1a4890b6c24", name: "Miller-Motte College" },
    { id: "50dcb164-6000-48cf-9c1b-4618866e276e", name: "Millersville University" },
    { id: "7f41a7d4-ddc5-41f1-b505-3b03865211ac", name: "Milligan College" },
    { id: "3a0bba5d-1ef6-4d58-85e5-e3d1aeefd26e", name: "Millikin University" },
    { id: "292a6925-cafb-49f4-9de1-77c2eda684e9", name: "Mills College" },
    { id: "37170e8a-8763-478c-a497-05f3f4332b40", name: "Millsaps College" },
    { id: "b84d6ff0-2b9d-4046-9c80-b06128b13c29", name: "Milwaukee Institute of Art & Design" },
    { id: "cad85280-d037-4090-aa34-eebfe0725230", name: "Milwaukee School of Engineering" },
    { id: "c665a384-ba02-4ad8-a301-b4467de797e4", name: "Milwaukee Technical College" },
    { id: "6d71caf6-037e-42bf-80fc-65357c57727a", name: "Mimar Sinan University of Fine Arts" },
    { id: "5fb40abf-804f-42a0-977b-0a2720b5a271", name: "Mindanao State University" },
    { id: "a1fc5354-ec1b-452d-a24c-43b12472738f", name: "MindEdge Learning" },
    { id: "573c3a27-7389-463a-8044-2ef3fe79afc8", name: "Mineral Area College" },
    { id: "69154e23-acc3-47f9-bd53-5ac37932d990", name: "Minerva University" },
    { id: "60c3c6b4-6a8d-49d9-99f0-0bb88f71b7da", name: "Mines ParisTech" },
    { id: "427e2071-d5e3-4547-84c6-f7d92c9bf265", name: "Ming Chuan University" },
    { id: "60ecf305-1341-49d9-9386-53b2e36ed6ce", name: "Mingchi University of Technology" },
    { id: "d94b76f7-d240-46bd-a7d3-f8816a570b6d", name: "MingShin University of Science and Technology" },
    { id: "ebe99605-170f-43e9-896b-4d98c16d2333", name: "Minhaj University Lahore" },
    { id: "f71a9453-a1f9-4f38-ae12-dd3b8b157fcb", name: "Ministry of Education - Taiwan" },
    { id: "3ecbab60-f1f1-493d-8961-7d99d4138abd", name: "Minjiang College" },
    { id: "8d3f4edf-6639-452b-834a-aa1e4a448caa", name: "Minneapolis Community and Technical College" },
    { id: "ff6d9c58-6ae9-4f2f-8bb4-f901156f08cd", name: "Minnesota International University" },
    { id: "8e778df0-90bf-4f5d-8f0a-38eb95145f73", name: "Minnesota School of Business" },
    { id: "1a21548d-f6f8-48d0-9ed6-7a09eb8c01dd", name: "Minnesota State Community and Technical College" },
    { id: "8276b2d6-64aa-4e2a-8f0d-b1e6a15134e0", name: "Minnesota State University" },
    { id: "3132e8b5-b103-474f-8e6c-80ce043e70aa", name: "Minnetonka Public Schools" },
    { id: "556e598a-ff67-454f-81de-8c1e1f1797f6", name: "Minoan International College" },
    { id: "3585c119-a9bf-4071-8920-5f69fbaf73da", name: "Minot State University" },
    { id: "6d261ede-3866-438a-a7bb-5f875df503ab", name: "Minuteman Press Cedar Park" },
    { id: "4c35833b-c8d4-460d-adae-3d6b195a9f6d", name: "MIP Politecnico di Milano" },
    { id: "3cf8f3c7-153d-4000-b273-b7b64bbeb534", name: "MiraCosta College" },
    { id: "825818be-b618-4521-938e-23eea8709e69", name: "Mirbis Moscow Business School" },
    { id: "95e5742b-be56-4893-9f6b-3e30500ead51", name: "Miriam College" },
    { id: "4bbf0eff-2059-4627-aa55-3c8f739956ba", name: "MISB Bocconi" },
    { id: "4bcf15ce-1dfc-4335-8963-f4410d211b4b", name: "Miscarea Agricultorilor din Romania (M.A.R.)" },
    { id: "39792263-9366-4b28-af0c-92881bb7e1e2", name: "Misericordia University" },
    { id: "a9352645-5156-4202-a6d9-4caa7b532855", name: "Misr International University" },
    { id: "9b2a3963-634d-4341-a05e-bd5660d627f5", name: "Mission College" },
    { id: "f9037980-aed1-4313-a5b2-b5a3e73af482", name: "Mississippi College" },
    { id: "b9ac7cf0-47fc-4471-ab3f-bdd072befabc", name: "Mississippi State University" },
    { id: "a4376f00-c376-49ea-8939-86b1cbda0c2e", name: "Mississippi Valley State University" },
    { id: "20432b22-1993-4e13-bf0a-6906807b3827", name: "Missouri Baptist University" },
    { id: "28d8b0b7-9e02-4b57-974b-67345eb82c9a", name: "Missouri School of Journalism" },
    { id: "89f1ea52-b7ab-46d2-91fa-7b97493335b9", name: "Missouri Southern State University" },
    { id: "7be8762b-d834-454a-9801-acbb8f8da1ec", name: "Missouri State University" },
    { id: "1c37abe7-fd39-4734-b29e-fc7007c06901", name: "Missouri University of Science and Technology" },
    { id: "c9021abe-9b4c-49ff-b4f5-41242502a130", name: "Missouri Valley College" },
    { id: "dd2a3919-6365-4592-b80f-5342aa2bcae0", name: "Missouri Western State University" },
    { id: "a42aa283-95af-45fe-9a98-098f6959fcf4", name: "MIT Legatum Center" },
    { id: "f54a6371-7289-4d7d-9064-d11485dece99", name: "MIT Center for Transportation & Logistics" },
    { id: "66deb73d-c880-4d3e-8407-48475e01bee1", name: "MIT School of Telecom Management" },
    { id: "b851ca35-084b-45a7-b805-129a82c810d5", name: "MIT University of Meghalaya" },
    { id: "60b8aef8-e0cf-408e-847e-84f71a08669b", name: "MIT World Peace University (MIT WPU)" },
    { id: "188a125c-56f0-4fd8-a10c-7ef8f66de6ed", name: "Mitchell College" },
    { id: "c4bfdb8f-75b6-414d-8bf1-d7c97dec81e8", name: "Mitchell Community College" },
    { id: "975de5b8-aa88-4bbf-9f1f-57858a343e04", name: "MITCON Institute of Management" },
    { id: "06b8ac46-59b9-4d3c-b852-77b549d4f4a7", name: "Miyagi University" },
    { id: "2e458275-e0e9-4bfd-a3c4-59043b84a40f", name: "Mizuho Bank Americas" },
    { id: "7665e738-720c-4887-a46c-8f37287ad729", name: "MMA - Online de Madrid Manuel Ayau" },
    { id: "3d8d5672-7422-475b-b264-62b706074c4e", name: "MN School of Business- Moorehead" },
    { id: "3ee961c6-67d1-4c13-ac9b-85c2056bbe4b", name: "Moberly Area Community College" },
    { id: "e9b57db4-341f-4ce3-98b4-cce27534a804", name: "MOC-HBS" },
    { id: "fa7dcb22-d90f-4412-8827-27ae7cf7e247", name: "Modern University for Business and Science" },
    { id: "736c9e4d-66dd-460e-b8a4-a4f0503ff489", name: "Modesto Junior College" },
    { id: "2dd0b9e9-cb1e-41fd-b51e-6b07e87ea418", name: "Modi ATI Education Pvt Ltd (MAII)" },
    { id: "81dd9c62-805d-496e-9b95-42febc65c234", name: "MODUL University Dubai" },
    { id: "5dea9282-7f5f-48f7-9b45-ce791cf8abef", name: "MODUL University Vienna" },
    { id: "eeb66e5b-d0df-4005-87b3-6271e623ec73", name: "Mody University" },
    { id: "86fe8e21-1271-4d5f-b3b3-7de2b53c2012", name: "MOGOPE - Financiamos Equipas de Sucesso" },
    { id: "c2b45adb-7a29-4dd3-85c1-e1a06c834ef5", name: "Mohammad Ali Jinnah University" },
    { id: "f5f84dc1-7b4b-4301-9874-f3ac5efe826b", name: "Mohammed Bin Rashid School of Government" },
    { id: "18230552-4d3a-40d3-883c-9f4e2bba3786", name: "Mohammed VI Polytechnic University" },
    { id: "1d8d4f42-a07e-4ee4-b3c7-47fe1244f01b", name: "Mohave Community College" },
    { id: "53a656d8-5075-4bcc-bd63-401bdd840cd6", name: "Mohawk College" },
    { id: "6ee8b3ac-e7b7-425f-b708-a54418a524f1", name: "Mohawk Valley Community College" },
    { id: "efceb0d8-1da2-4105-a135-68a1f0d4e0ee", name: "Moi University" },
    { id: "5d291094-85fb-427b-9b80-1037119f9161", name: "Mokra Gora School of Management" },
    { id: "07a287fe-890f-43f3-858b-d15898df61bd", name: "Molde University College, Norway" },
    { id: "a075ec55-113e-4854-ab85-af369d8db7db", name: "Molloy College" },
    { id: "648a00ee-6634-4d69-af72-05bd991f1c69", name: "Momoyama Gakuin University" },
    { id: "3cf787f4-d838-443a-afa2-6b3db5429667", name: "Monash U MBA (usage reporting placeholder)" },
    { id: "2e0ba33d-c401-4af4-ba99-07a7a6f2d047", name: "Monash U School of Accounting and Finance (usage reporting placeholder)" },
    { id: "5ec028a0-bb1e-4024-ad8e-029f518298f2", name: "Monash University" },
    { id: "e62becd7-3fdd-4aea-a8de-eac84578fcce", name: "Monash University Malaysia" },
    { id: "941e4317-0b97-466c-915b-188b2e2281e7", name: "Mondragon University" },
    { id: "b3bad8aa-880f-4cfe-aa65-0b63d12e6fd6", name: "Mongolian Royal Academy" },
    { id: "93d4d80c-8cac-4150-84d1-0479d7dd599a", name: "Monitor Company Ltd" },
    { id: "2fc9cc1f-496a-404c-bd30-994a42aa54bc", name: "Monmouth College" },
    { id: "9e3dba8e-0955-4e24-883a-a1c95e93d1ef", name: "Monmouth University" },
    { id: "77b9933f-e62d-4037-b2fc-b20b463a619b", name: "Monmouth University - Leon Hess Business School" },
    { id: "1d0985ee-337c-43ee-83e0-bfd12ec6a264", name: "Monroe College Bookstore" },
    { id: "ae00ad3e-bd2d-4e51-b767-5c469482b132", name: "Monroe Community College" },
    { id: "c20f2c7d-c734-4f8a-9bd8-6f5df6f3768c", name: "Montana State Univ - Northern" },
    { id: "77fe14c3-8224-433f-83f9-d31f1b6f9942", name: "Montana State University" },
    { id: "5c895ee8-94a0-436c-b08d-a2ee2637e147", name: "Montana State University - Billings" },
    { id: "d308dbb4-8367-4697-bd81-a46d2e651506", name: "Montana State University Printing" },
    { id: "98062075-ddd1-4b6e-9bed-7244e9b75043", name: "Montana Tech" },
    { id: "302cd65a-5456-4e56-af99-9c0ab14fd148", name: "Montanuniversitat Leoben" },
    { id: "befbda8f-144f-4493-8849-553cd05eed68", name: "Montclair State University" },
    { id: "9fa532aa-5ed2-4d60-97b5-dc2126db14b1", name: "Monterey Institute of International Studies" },
    { id: "b2c700de-e390-43d3-be9a-ebdac3a2cafd", name: "Monterey Peninsula College" },
    { id: "bb4298d2-f811-472a-9e44-11878369b2d0", name: "Montgomery College" },
    { id: "7809fb04-43fb-4fd4-875d-b9dece5905d4", name: "Montgomery Community College" },
    { id: "c30dd9c9-a0f1-44f3-87d4-d9e8b46463c3", name: "Montgomery County Community College" },
    { id: "48f7c769-43d7-4875-b069-7c043d695deb", name: "Montgomery County Public Schools" },
    { id: "c07f575a-4af1-4dff-acbb-8435dd272870", name: "Montpellier Business School" },
    { id: "99be3a3d-9fd9-40e8-aef1-812529b78bdb", name: "Montpellier Supagro" },
    { id: "5419c36e-3f60-487d-8b4d-925f1748fb65", name: "Montreat College" },
    { id: "1afc8111-d184-4769-8150-173f80a845a8", name: "Montreux School of Business" },
    { id: "8e3bdf1a-12af-4caf-9e3b-0b8a17b10dbf", name: "Moodlakatte Institute of Technology" },
    { id: "6d547caf-491a-448e-b557-98925814eb24", name: "Moody Bible Institute" },
    { id: "688af6d2-f0d5-47b8-8bf5-fb8b81f30327", name: "Moorhead State University" },
    { id: "3525cc92-639a-47cb-a0c6-64fe9f26bbcb", name: "MOP Vaishnav College for Women" },
    { id: "968d0c17-c5b6-4b06-9401-bc11a1079310", name: "Moraine Park Techincal College" },
    { id: "46f4d590-a751-4983-8267-35977fdfaf28", name: "Moraine Valley Community College" },
    { id: "aa29fb06-b705-4ecc-9b39-58ad2ce19e6d", name: "Moravian University" },
    { id: "05c9ce9a-dda1-4e2e-bc4a-2ae84e7e74ad", name: "MOREHEAD STATE UNIV" },
    { id: "cfc0e493-3424-49b1-8626-43c8c738fcb3", name: "Morehead State University" },
    { id: "6c7ef8d2-f639-495f-b37f-a892d7575747", name: "Morehouse College" },
    { id: "4199c4cc-9092-4a48-bc35-e645545b152f", name: "Morehouse College - School of Medicine" },
    { id: "346d7ecd-f58a-404a-a2e0-58439c622254", name: "Morgan Clarke Consulting" },
    { id: "2449804d-ce12-4366-b091-d1dd85358819", name: "Morgan Community College" },
    { id: "e68fa5bf-ca39-430d-b4f5-3b63697064ad", name: "Morgan State University" },
    { id: "112b822e-b81d-4fa5-a8c7-a08e65c06ecc", name: "Morningside Coll Bkstr" },
    { id: "78222d9a-e89d-4229-9595-e5706536cbcf", name: "Morningside College" },
    { id: "1c0f3509-cd2d-4bfb-870d-b158a60b1ab3", name: "Morris Brown College" },
    { id: "a195fea0-19e8-4353-85de-77f6ec2b79bb", name: "Morrison University" },
    { id: "bfda8294-ada6-4360-8a80-a749fd8a0e9a", name: "Morrisville State College" },
    { id: "8e22a874-b44b-4baa-a5ba-e2793f47ac77", name: "Morton College" },
    { id: "200459b4-74fa-44cc-bc89-6a3597ceeee9", name: "Moscow Institute of Electronic Technology" },
    { id: "0a55ebb5-55cb-4215-a293-6f040444f5a2", name: "Moscow Institute of Entrepreneurship and Law" },
    { id: "9a173c00-4971-467f-9396-20b179030d3a", name: "Moscow Institute of Finance and Economics" },
    { id: "fdc81e75-0a15-4895-9adf-833040b5f554", name: "Moscow Institute of Physics and Technology (MIPT)" },
    { id: "a3b0c0e1-0fd6-4f51-ab90-81196475c819", name: "Moscow Institute of Physics and Technology State University" },
    { id: "e113abac-d9f4-4941-b91c-30636772762b", name: "Moscow International Higher Business School" },
    { id: "6b3432bb-e847-482c-b658-2e91df9e740f", name: "Moscow School of Management - Skolkovo" },
    { id: "a97944e4-2c82-4819-8b62-cdc13c5f2791", name: "Moscow State Institute of International Relations" },
    { id: "eb4fd6f3-20a7-4872-8959-1c67cc15eb3b", name: "Moscow State Institute of Radio-Engineering Electronics and Automation" },
    { id: "5d8050db-52e7-4084-adbc-80c5331bb7a9", name: "Moscow State University" },
    { id: "d8723ae5-4e68-4260-99c3-2f1a845cce44", name: "Moscow State University of Economics, Statstics and Informatics" },
    { id: "c4d9ba0e-1071-4301-ba85-cdd1491be004", name: "Moscow Technological Institute" },
    { id: "7017be5a-6531-4a00-bf10-abd627760591", name: "Mosman Community College" },
    { id: "38646045-3aa5-4b25-a256-11d5e3897a95", name: "Motilal Nehru National Institute of Technology" },
    { id: "d1a5124f-95fa-4b17-aa54-a109eaee70f2", name: "Mott Community College" },
    { id: "44dfbb77-8249-477b-b746-d8d6bb01b77b", name: "Mount Allison University" },
    { id: "16d9e69d-94ee-45ae-9da3-5a0ed9cd81a6", name: "Mount Aloysius College" },
    { id: "08fb5b8f-1132-4e3a-ac39-8335c3981d0b", name: "Mount Carmel College of Nursing" },
    { id: "7de0f43e-3127-4006-91fb-461794da31bb", name: "Mount Carmel Institute of Management" },
    { id: "b00c1635-108a-487c-84a5-b04ac65cec3d", name: "Mount Mary University" },
    { id: "f6bf8de0-459d-44e0-8b7c-13550803e1b1", name: "Mount Mercy College" },
    { id: "4a61a468-ebf9-4e30-afd9-bdb566952488", name: "Mount Mercy University" },
    { id: "5f21981f-6c7c-47ab-95de-59d65319a0aa", name: "Mount Olive College" },
    { id: "1b2ff042-918f-4ae3-a920-17faa64491d3", name: "Mount Royal University" },
    { id: "95d59102-9e13-4646-bc75-af49850bb9a5", name: "Mount Saint Mary College - New York" },
    { id: "be14c06d-7aa0-4322-8dd8-999ab5cc4f33", name: "Mount Saint Mary's University, Los Angeles" },
    { id: "9fc21c9d-c1fd-42d6-93f0-805614d94947", name: "Mount Saint Marys University - Maryland" },
    { id: "463b9544-3ada-4ef9-8d5d-a18bb319941d", name: "Mount Saint Vincent University Library" },
    { id: "aa0eed01-bb16-4da1-b2c9-8fd5acc36219", name: "Mount Sierra College" },
    { id: "a004ce0e-be64-44c5-8ada-566d427a7775", name: "Mount Sinai School of Medicine" },
    { id: "7176631a-eb2f-4a89-a004-617a51ba51ff", name: "Mount St. Joseph University" },
    { id: "61416896-715d-4782-b28e-49b584c3d18b", name: "Mount St. Mary's College" },
    { id: "7533851c-52f4-4150-941f-719a8a571702", name: "Mount St. Mary's College - California" },
    { id: "95164fe5-7f90-4e16-868a-bdb6dd37d9b7", name: "Mount St. Mary's University- Maryland" },
    { id: "f2f7b5ff-f519-4b2d-85de-afdd3b071630", name: "Mount Union College" },
    { id: "47194ad7-6181-4ffb-b584-ab7a5d90ec3b", name: "Mount Vernon Nazarene University" },
    { id: "f65e934a-08e9-46e3-b62f-f15ae9dc5277", name: "Mount Wachusett Community College" },
    { id: "7f712dd6-df00-48aa-a4ec-a1228465e0d5", name: "Mount Washington College" },
    { id: "287cae85-1f76-4fe2-9b54-00865cc4e059", name: "Mountain Lakes High School" },
    { id: "b865562f-30e8-483d-9a9d-a98f131cf762", name: "Mountain State University" },
    { id: "f44d163f-9421-4fed-8245-a5dc7945c47c", name: "Mountain View College" },
    { id: "87b37e0d-22e4-4131-bae8-5cc6a8b70dd2", name: "Mountains of the Moon University" },
    { id: "1da8fc11-b2b7-489f-a286-0a87cb87447b", name: "Mountbatten Institute - London - Peter" },
    { id: "6f081c2b-7165-40a4-bb9a-3ccbe7ee029d", name: "Mountbatten Institute - London Programme" },
    { id: "9ba69bb3-512e-4ea0-aeb0-fd3cf4684290", name: "Mountbatten Institute - New York" },
    { id: "d1217148-a100-4ad8-b53c-a387e14d235b", name: "Mt. Hood Community College" },
    { id: "691a9399-b6a9-4455-9a03-6e5e40b55865", name: "Mt. San Antonio College" },
    { id: "2d72770b-c94d-4231-8ccc-9e208b9dcce6", name: "Muhimbili University of Health and Allied Sciences" },
    { id: "081c4635-f379-4f7d-8ce8-092590c6bb0c", name: "Muhlenberg College" },
    { id: "4c5e8d63-6e2a-4990-a2a2-1a9a70d409f7", name: "Mukesh Patel School of Technology Management and Engineering" },
    { id: "8de74489-2eef-4401-84ec-b3c24bcf1dd0", name: "Multimedia University" },
    { id: "b3958f66-bf6b-403a-a10b-d85aefd65857", name: "Multnomah University" },
    { id: "036eb7d6-1dae-43e4-9786-58976e9fc20a", name: "Mumbai Business School (MBS)" },
    { id: "af3b5c49-7215-4270-8d8a-93c5d0a516b5", name: "Mumbai Educational Trust" },
    { id: "862ef9de-5311-49e9-80c6-9d5f733f0cdc", name: "Mundos E People and Business School" },
    { id: "710fb712-9a43-40cd-97a5-75d295d62994", name: "Munich Business School" },
    { id: "31fd4e1c-b5ee-47d4-9cd1-e4a54bf1a58f", name: "Munich University of Applied Sciences" },
    { id: "6e9a7595-3d73-4a79-9f25-fb017949d267", name: "Munster High School" },
    { id: "d6739997-064e-47aa-80e3-c90a4210a571", name: "Murdoch University - Australia" },
    { id: "3eb63e5c-7f59-4165-adb9-7e64cbca5a9c", name: "Murdoch University Dubai" },
    { id: "b05382ea-5815-4b47-ae79-763461733567", name: "Murray State University" },
    { id: "9d92368d-e255-4fae-9dcd-5cd4d8103225", name: "Murugappa Group" },
    { id: "d77402dd-cd36-4604-a0f6-5643071024ed", name: "Musashi University" },
    { id: "852faf56-7572-4fcf-83d3-259f44cd39db", name: "Musashino University" },
    { id: "c7210a1a-796d-40fe-b5b2-16a93e8a910b", name: "Museum of Jewish Heritage" },
    { id: "1be03fff-51f3-488f-80c5-b272558ea5c7", name: "Muskegon Community College" },
    { id: "1b8d3dca-4ef9-4c92-ab78-d465a92b6dc2", name: "Muskingum University" },
    { id: "04214689-7f8d-485f-b171-cac4e6b96297", name: "Mustafa Kemal University" },
    { id: "24ab79b3-45de-49ab-afe2-7a12cb7e88d1", name: "Mutah University" },
    { id: "ab515bad-61d7-48cf-a81d-af64134a947e", name: "Myanmar Metropolitan College" },
    { id: "618178e6-6246-4b74-a000-6e9f348c0793", name: "Mycoursepack.com" },
    { id: "ea2ce5a5-ae23-403a-99c4-50c733d6f9d6", name: "Mykolas Romeris University" },
    { id: "15f4c163-667c-4266-901a-b6fe0e13359f", name: "Myongji University" },
    { id: "9135a9bb-910c-4691-9b49-6118dfb4b6da", name: "MYRA School of Business" },
    { id: "4a73bd05-8cc4-49ca-bbc1-c6eb63da0584", name: "Mzumbe University" },
    { id: "2135a775-cc53-46d5-8bcf-8d068d02bcd2", name: "N. L. Dalmia Institute of Management Studies and Research" },
    { id: "ad13c3fe-f610-4379-bb92-5214ab38e218", name: "N.J. Council of Community Colleges" },
    { id: "771adef9-dc6b-43f5-8431-fd825a72f798", name: "NABA - Nuova Accademia di Belle Arti" },
    { id: "7f32e51c-1447-4b4d-a01e-0abfd0151b14", name: "NACM - National Association of Credit Management" },
    { id: "de33f002-8e7c-4a3b-bc9c-37fc84e94788", name: "Nagasaki University" },
    { id: "cb08884a-e617-47c7-94a2-60ccda2c7085", name: "Nagoya Gakuin University" },
    { id: "e1d98621-3364-46d8-8dbb-32bc409c454b", name: "Nagoya Univ of Foreign Studies" },
    { id: "391026a9-6200-4d5a-b015-1de3eba16a4d", name: "Nagoya University" },
    { id: "449008ef-9447-43da-92bc-ff069502a00a", name: "Nagpur University" },
    { id: "85f8443d-2e91-416f-b3cc-bda8e13bedf6", name: "NAIT BOOKSTORE" },
    { id: "4c573046-238d-40bf-97cf-f72306361ad1", name: "Nakazawa Consultants" },
    { id: "2293f67d-76bf-463b-af89-d1a536150d57", name: "Nalanda University" },
    { id: "2e8a4fa2-aee1-4383-82ee-aa1935b32d3f", name: "Namik Kemal University" },
    { id: "30665046-d13f-4a91-9b3c-0769423cb9a9", name: "Nanhua University" },
    { id: "994b27b2-47d0-4d3e-9962-8aead1abda7e", name: "Nanjing Audit University" },
    { id: "0edd440c-513c-4531-ab9a-7681b8d107b1", name: "Nanjing Normal University" },
    { id: "be3f063a-2235-4265-9e46-e124eac2abd8", name: "Nanjing University Business School" },
    { id: "54ae5913-9d6e-4a62-bf38-210232d67cfd", name: "Nanjing University of Technology" },
    { id: "79979e0d-ab27-4e37-8bf5-5cddbca99f64", name: "Nankai University" },
    { id: "aa6b78b6-1246-4a1d-be10-8439121de7b3", name: "Nanyang Girls' High School" },
    { id: "e98cb211-62a8-4221-bea3-12b05ef4f7a1", name: "Nanyang Polytechnic" },
    { id: "d20ecad6-3c73-463f-8fbf-7ebecf368e70", name: "Nanyang Technological University" },
    { id: "fd5c7e61-8b5a-469f-a480-c6f24c037437", name: "Nanyang Technological University ExEd (online reporting account)" },
    { id: "64c89383-3765-47d3-a24c-8a752972e69c", name: "Nanzan University" },
    { id: "6d2eb982-6e34-4dae-99c6-ad385b5433be", name: "Napa Valley College" },
    { id: "024cb4c1-a4a2-432a-a2c3-9977e87a5504", name: "Napc Other - No Univ" },
    { id: "051797fd-0cfa-4351-aac3-2c22793b24f7", name: "Naperville Community Unit School District 203" },
    { id: "8ce57249-1397-4c38-8c8a-432943288553", name: "Nara Institute of Science and Technology" },
    { id: "8953e6e5-1b92-4977-b7b7-c1e63af5574a", name: "Naresuan University" },
    { id: "5b73e357-7acc-4f07-9f0d-046deede5ee8", name: "Narmada College of Management" },
    { id: "b1ad7014-62c8-4231-b7c0-882a06faa181", name: "Naropa University" },
    { id: "2150b484-9c4c-4b34-9524-918c210b411f", name: "Narsee Monjee Institute of Management Studies (NMIMS)" },
    { id: "9eb7868a-2b16-4bc4-970a-c7fbf1e33512", name: "Narxoz University" },
    { id: "6e9fb887-5001-4e2f-b2b9-96931b6144a4", name: "Nash Community College" },
    { id: "132a0e92-7a47-4476-984d-884d571e47ce", name: "Nashua Community College" },
    { id: "7f670de6-69ee-4f05-a12c-e6aea7276461", name: "Nashville State Community College" },
    { id: "a12081af-ac8d-4ff2-9135-426a1711ecc0", name: "NASHVILLE STATE TECH COMMUNITY COLLEGE" },
    { id: "c20b7351-f946-4b5d-8f5e-bce628c89e73", name: "Nashville State Technical College" },
    { id: "0bc60b2d-1684-4376-97d4-1fec6b9530ea", name: "Nashville Technical Community College" },
    { id: "27f6437a-ef7f-4c43-a609-f2991af4a47b", name: "Nassau Board of Cooperative Educational Services" },
    { id: "e7d7d296-d430-45db-8b96-c39c8d36ff9b", name: "Nassau Community College" },
    { id: "19021589-284d-4643-a24c-017e6e3e87a7", name: "National Academy of Advanced Teacher Education" },
    { id: "29b2f216-9b07-4d4b-aab6-645eeb0130d0", name: "National Academy of Agriculatural Research Management" },
    { id: "32d820e2-854d-4fcc-b8b0-9b41b270ca36", name: "National Academy of Agricultural Research Management" },
    { id: "93af7d9f-7eb1-43ca-aefc-312cfedb4c92", name: "National Academy of Defence Production" },
    { id: "e5179012-3de0-4c27-af2b-339ef7f9fbd0", name: "National Academy of Indian Railways" },
    { id: "8f9b3b89-259a-45e1-a89e-e328415277ab", name: "National American University" },
    { id: "146f01fb-4ea4-4b37-b69b-ac748e34c4ff", name: "National Arts Strategies" },
    { id: "cdc8ddae-d8bf-4742-9077-167a21ce32df", name: "National Association of College Auxillary Services" },
    { id: "d5c41ca3-2d12-4a3e-b229-c65962693fb6", name: "National Black MBA Association" },
    { id: "9f8dd0f3-c926-4b4a-b1f6-50e08fea7c51", name: "National Cathedral School" },
    { id: "c93f0f42-0cc8-4041-b682-f1a2de14f2b6", name: "National Central University" },
    { id: "0a77b025-708a-4d2b-af98-e2e2fdb27337", name: "National Changhua University of Education" },
    { id: "2fea7ed7-791a-42da-96ba-866f97fe57dd", name: "National Chengchi University" },
    { id: "0f0fc8ef-fdc7-4fd5-aab2-fc654ac86a26", name: "National Chi Nan University" },
    { id: "9b6e50d9-c94d-452f-a309-10fecad66598", name: "National Chiao Tung University" },
    { id: "fecf6cd4-a755-4716-8038-5803ddd9ae62", name: "National Chiayi University" },
    { id: "5674fe79-4870-4c3f-9394-9cbef36852b8", name: "National Chung Cheng University" },
    { id: "9f6685be-eb8a-40c6-af1b-02634fe1665d", name: "National College" },
    { id: "a4534469-6d6d-42a8-8b9c-bc678dd050d1", name: "National College - West Virginia" },
    { id: "cd1038c2-1cc4-4830-99cc-c290a6a5cb1c", name: "National College of Ireland (NCI)" },
    { id: "64059082-3abe-4675-be19-99170cd386df", name: "National College of Natural Medicine" },
    { id: "6a999860-06f0-4ef7-bb26-8fa45fe78dec", name: "National College of Science and Technology" },
    { id: "98a3b7b7-2188-4398-950f-e53478df15eb", name: "National Defense College (Sweden)" },
    { id: "100ff053-e1ea-43a6-8832-a4c333a600ea", name: "National Defense University" },
    { id: "34e1cbf0-3ff4-4611-97fb-67100ad04d2c", name: "National Distribution" },
    { id: "d493103a-a63a-4ebc-895c-96ad107a542c", name: "National Education Society - National Business School (NES)" },
    { id: "dc363f02-0696-400d-8e52-4d73b2adcbdd", name: "National Engineering College" },
    { id: "b0934c04-06ef-4444-bb88-2e5a5ac47538", name: "National Entrepreneurship Network" },
    { id: "e3dc441a-e172-4520-be14-081f05b8beab", name: "National Graduate Institute for Policy Studies" },
    { id: "3908c8e1-a5dc-4420-af4d-f4396cf15775", name: "National Graduate School" },
    { id: "deca70a4-523b-49af-9d5b-b86acf049332", name: "National Graduate School of Quality Management" },
    { id: "68e37f8c-0fb2-483e-8a6b-e95709215a11", name: "National Guard Health Affairs - Saudi Arabia" },
    { id: "8a673fac-0533-481a-b315-693021ec1fde", name: "National Healthcare Group" },
    { id: "cfdd1c62-0be0-472a-a8fd-cec7fc444cd2", name: "National Hispanic University" },
    { id: "4accdb2d-4436-431f-8153-c6b1e68cd1ea", name: "National Innovation Foundation India" },
    { id: "85e2f8cb-82b3-4549-8d3d-ff3648f4775a", name: "National Institute for School Leadership" },
    { id: "774d9c83-0a0b-41c2-aed2-f3b489a9c806", name: "National Institute of Agricultural Marketing" },
    { id: "b29ceb2c-6215-464e-a644-3da88f2ee328", name: "National Institute of Bank Management" },
    { id: "5f7b5f92-bdd3-4886-9aa2-a0f79bc0ad9a", name: "National Institute of Construction Management and Research" },
    { id: "91a1cba7-3ba7-4356-a46d-d606cf4117b0", name: "National Institute of Design" },
    { id: "39656134-d1f6-4e74-9078-ef864c6d08a4", name: "National Institute of Development Administration" },
    { id: "e3004c05-f0dd-4700-9cd7-be062905aef5", name: "National Institute of Development Administration (NIDA)" },
    { id: "71e67107-340c-447d-a9d7-1c8e9fd6493e", name: "National Institute of Education" },
    { id: "bff56521-625b-4d89-ab06-fda14bf5c1fc", name: "National Institute of Fashion Technology" },
    { id: "8800a862-7372-4fa4-8fc6-77a6a4e083e4", name: "National Institute of Food Technology Entrepreneurship and Management (NIFTEM)" },
    { id: "136bda8f-6767-45e0-bdbf-b0c154df914e", name: "National Institute of Securities Markets (NISM)" },
    { id: "680a720e-06f5-499d-b5ba-d7fa668c113f", name: "National Institute of Technical Teacher's Training & Research" },
    { id: "66365c33-08ea-49da-a0f4-23936784cf5b", name: "National Institute of Technology - Durgapur" },
    { id: "9e4723df-9730-41da-824a-0280525c6f61", name: "National Institute of Technology - Karnataka" },
    { id: "a4e9d2c8-257d-400a-8bda-8dccef4c3fb6", name: "National Institute of Technology - Trichy" },
    { id: "e6697493-5f92-4ddb-a1ff-8774ce800e40", name: "National Institute of Technology - Warangal" },
    { id: "1d6baff1-7fae-4e77-b15c-c6c420e7f626", name: "National Institute of Technology Calcicut" },
    { id: "37604396-2c60-4115-9a9f-cb2061b6a412", name: "National Insurance Academy" },
    { id: "bfa5f163-dd2f-4a2c-9a94-897cf296ea0e", name: "National Intelligence University" },
    { id: "3f5aa197-3003-4d79-ba64-471f8f73708b", name: "National Kaohsiung University of Science and Technology" },
    { id: "72a1cd97-8c42-40c2-8b80-f912dcc9be0e", name: "National Labor College" },
    { id: "641eac9f-fcca-4ab9-a848-9a662a8a39a2", name: "National Law School of India University" },
    { id: "a8e07a14-66f1-4834-a126-5e1798efc403", name: "National Law University" },
    { id: "8d5d50eb-b4c6-478f-9b24-ca99d7aca0a9", name: "National Louis University" },
    { id: "371debaf-fb6c-4084-9278-c010a8061f98", name: "National Management School - Chennai" },
    { id: "f067cf64-6d93-487b-baf4-73df1479dd0f", name: "National Outdoor Leadership School" },
    { id: "6d0edf7a-2167-4c77-9e12-a586036671a2", name: "National Paralegal College" },
    { id: "b11b6230-2f07-4140-8c46-bcad2a92d4cc", name: "National Pingtung Institute of Commerce" },
    { id: "4e3001b5-dcd1-4949-a490-a993b05ef193", name: "National Pingtung University of Science and Technology" },
    { id: "2bc9da32-5787-4ccd-9b68-e37273bb54be", name: "National Rail and Transportation Institute (NRTI)" },
    { id: "68706282-9fe6-41c8-9e52-cfd5758466e1", name: "National Research University - Higher School of Economics" },
    { id: "e0674077-9a82-409b-a70a-4136a9deb9e2", name: "National School of Business Management" },
    { id: "369fd3ba-3420-4f7b-aa8b-628f680a7f0f", name: "National School of Government" },
    { id: "b5f96003-9ebc-4678-a86c-15ffaed09e76", name: "National School of Political and Administrative Studies" },
    { id: "75092985-b6ac-4c6f-8be8-d99563cb5aeb", name: "National School of Public Policy" },
    { id: "32d404b3-4830-416f-bc62-aefddbfbedf7", name: "National Skills Academy for Financial Services" },
    { id: "aa850901-0236-4cea-b158-106f9a1d753d", name: "National Sun Yat-Sen University" },
    { id: "711be358-744a-4f88-b7b7-edc98dc0ec97", name: "National Taichung University" },
    { id: "c9720d65-d575-49a7-bd09-88a01ee1dd09", name: "National Taipei University" },
    { id: "66b52844-f19f-4559-8879-da30078fc2f9", name: "National Taipei University of Technology" },
    { id: "b7ae6454-5242-431f-b27c-b35b9057301f", name: "National Taiwan Normal University" },
    { id: "76abefad-3524-4a99-8b64-cdc7ccb8ee32", name: "National Taiwan Ocean University" },
    { id: "c3414eb8-f96c-4582-b3a6-2cf871da51b9", name: "National Taiwan University" },
    { id: "914607e4-b52a-4bfd-9dd6-a18571818b17", name: "National Taiwan University of Science and Technology" },
    { id: "8ba11d8a-8d20-4406-a59b-6d07917fa6c7", name: "National Technical University of Athens" },
    { id: "f6c84e6c-1c41-45a9-ac00-54d01de394db", name: "National Textile University" },
    { id: "d5b91fe1-3071-477c-a2f1-4a2c50802d42", name: "National Tsing Hua University" },
    { id: "6c40b3d7-da78-4e43-bb9b-543dc0f1c315", name: "National United University" },
    { id: "38854555-c9c4-47b5-9881-c9eb08bfd5b8", name: "National Univ of Sciences and Technology (NUST)" },
    { id: "129acb8e-f1ea-49b5-ab9b-e6ca352bf418", name: "National University - Illinois" },
    { id: "a693af04-f536-424d-b8d5-ee9afe6a1d80", name: "National University Federico Villarreal" },
    { id: "9f675f19-7fe9-4af5-8bef-773b2b9fa110", name: "National University Hospital" },
    { id: "b9d1dcdd-8b85-4f07-8c34-013b0e244c21", name: "National University of Central Buenos Aires UNICEN" },
    { id: "55d28c9e-0df2-4650-baed-aeacc3d91fe0", name: "National University of Computer and Emerging Sciences" },
    { id: "c1fb3d1f-e1fa-4381-adea-77ee1236875e", name: "National University of Food Technologies" },
    { id: "40a33f41-6511-4dfb-bafe-b9f41e6f6f3e", name: "National University of Ireland - Maynooth" },
    { id: "254696d3-4899-4064-b8e9-a0dbc70b2fd0", name: "National University of Kaohsiung" },
    { id: "a6284389-3261-4fbf-b411-afb6441f9c26", name: "National University of Kyiv-Mohyla Academy" },
    { id: "a3dd9b8a-351a-40e6-9a6a-3dde3a6b9d26", name: "National University of Laos" },
    { id: "1e14ef24-2e28-464c-90db-3fe2aac09889", name: "National University of Modern Languages" },
    { id: "8a12107c-5db2-4a42-b737-1d76ca2f913c", name: "National University of Political Studies and Public Administration" },
    { id: "4e30660e-3bb1-4a46-ac1a-5e454e777608", name: "National University of Rwanda" },
    { id: "0bc7bdbe-5db0-4c30-be22-29afd2e0c2f9", name: "National University of San Marcos" },
    { id: "3bfcdaa4-a94c-4dfb-8a26-c896905e9509", name: "National University of Singapore (NUS)" },
    { id: "737e8fc6-05b3-4569-a0c3-66bf3755b3bb", name: "National University of Tainan" },
    { id: "f7ce3dff-08b2-4d65-ab63-c2082badd6d8", name: "National University of Trujillo" },
    { id: "33d8c493-1e25-41a0-853f-16ad0139b844", name: "National University-FAST" },
    { id: "bf762032-48c2-4f97-97f1-6f19b0f6a422", name: "National Yang Ming Chiao Tung University (NYCU)" },
    { id: "c36badcf-df0c-4826-b398-49bc33a121bb", name: "National Yunlin University of Science and Technology" },
    { id: "7d6b4d26-378e-4a49-830c-8806d1c92bfa", name: "Nationale Handelsacademie B.V." },
    { id: "9fbc0d3c-a1e5-48f5-b257-fd512fb43d2f", name: "Nationwide Children's Hospital Inc." },
    { id: "34e87e9b-70ea-4acc-933f-88668a4e1d9d", name: "Nato Special Operations HQ" },
    { id: "9eb98c56-a99b-40f9-82e8-2a5d807f498a", name: "Naugatuck Valley Community College" },
    { id: "0a9a2f96-8b27-4cc0-8277-28deed0700b6", name: "Naval Postgraduate School" },
    { id: "954d412a-246b-4553-ae48-61a41538115e", name: "Naval War College" },
    { id: "0dbebcb0-e314-4013-b079-f2980c6e47d6", name: "Navarro College" },
    { id: "1cb8fd2a-e6b7-4dce-a838-a6fcc026095f", name: "Navrachana University" },
    { id: "7ab7efe1-a647-42bc-8e66-435b4fdf9298", name: "Nazarbayev University" },
    { id: "921ca6af-04aa-4ab9-b696-3c514422e02e", name: "Nazarbayev University Library & IT Services" },
    { id: "13e79e16-275f-4f62-9c19-8c2af0811840", name: "Nazarene Bible College" },
    { id: "86189815-046d-42a5-8608-029986349da3", name: "Nazareth College" },
    { id: "67436187-2644-449e-a6c0-2e93dca3ea72", name: "NBN Sinhgad School of Management Studies" },
    { id: "201c4a25-75c7-4291-9e4a-9351d5db7af0", name: "NCERT - India" },
    { id: "80ca0862-3a42-4715-b1d2-6e97a40e13f9", name: "NCKU Industrial Design" },
    { id: "f11d740d-b8d8-4727-8845-2bc4b950af39", name: "Neapolis University" },
    { id: "b54d2d4b-a937-4e5b-a1a0-cfd404642e1a", name: "Nebraska Book Company" },
    { id: "19a6eef7-bf62-42cc-8b9f-ea04bc709a7a", name: "Nebraska Methodist College" },
    { id: "7e05017f-b435-48ac-b34c-aed5cbd946ed", name: "Nebraska Wesleyan University" },
    { id: "60b1b85e-2a04-4b91-958a-b52620ca0fea", name: "Nebrija Universidad - Campus de La Berzosa" },
    { id: "de195426-f093-4aca-82f5-588dc99a98fc", name: "NED University of Engineering and Technology" },
    { id: "bb308b17-5262-4925-9b6e-1ca0669e9ea6", name: "Nelson Mandela Metropolitan Univ" },
    { id: "65bada94-79cd-49ab-9466-c8d8792aab4a", name: "Nelson Mandela Metropolitan University" },
    { id: "510e4841-9207-4048-b5dc-9afbe2295d38", name: "Nelson Marlborough Institute of Technology" },
    { id: "7dea716f-177e-402f-8ce4-4d68d48961f6", name: "NEOMA Business School" },
    { id: "6e33901f-2bcb-4a8a-a1c9-e922fbe6f7ef", name: "Neosho County Community College" },
    { id: "45c7af53-5be3-4ae3-ba97-e7643041e3d9", name: "Nepal Administrative Staff College" },
    { id: "4db48ba9-590f-4894-ac86-37f4234f8e9f", name: "NESBURG SCHOOL" },
    { id: "8e079b27-f2cf-4545-bb6e-eb2adce96211", name: "Nesburg School of Business and Management" },
    { id: "eb6557c9-1452-4a2e-971a-d7ebe5bd2cfb", name: "Netacademy" },
    { id: "3aec1898-bf44-49b7-abfb-a599137d3e98", name: "Netanya Academic College" },
    { id: "b6fb419e-e94b-4dfb-9c86-d348f62f6eb7", name: "Nettur Technical Training Foundation" },
    { id: "34be15b5-695d-40fe-95c9-611754e7c242", name: "Network for Teaching Entrepreneurship" },
    { id: "73d3f862-bdc5-45f9-97e0-48a98375d6a0", name: "Neumann University" },
    { id: "f9a90d84-374f-4aa8-afd6-4c7a7ffeda12", name: "Neumont University" },
    { id: "a4aa881e-8e3c-4852-ae92-589d0d7af81e", name: "Nevada State College" },
    { id: "23ca3ae9-5c28-4acc-a5b9-bb0159f3d694", name: "New Beginnings Educational Center" },
    { id: "ddd12656-8a3f-477b-9ec0-cfcd1655ed31", name: "New Brunswick Community College" },
    { id: "e4ef6917-2ceb-4923-ad12-7127d922402f", name: "New Bulgarian University" },
    { id: "43497ceb-16ef-4aff-ae0c-aa2f1a3e090e", name: "New Business School, Amsterdam" },
    { id: "1215e4fd-2240-4b37-b927-fe4f984a3c12", name: "New Charter University" },
    { id: "37726d88-af7e-4958-a12a-f66c86cbe658", name: "New College of Florida" },
    { id: "7c221f97-86f1-4524-b4d4-b11aabde77c0", name: "New College of the Humanities" },
    { id: "3160eb20-f0e9-47a9-849d-c9ca2a657b7c", name: "New Delhi Institute of Management" },
    { id: "a85a2d06-e9f3-455a-a34e-a29b7260a7d7", name: "New Design University" },
    { id: "1c16bcc4-f8f5-49a1-a50c-fa0196321da1", name: "New Economic School - Moscow" },
    { id: "c52e7672-0ddd-49a3-aeb7-5846df37eea5", name: "New England College" },
    { id: "312bf7e6-03b4-4a30-885e-6921ade147ec", name: "New England College of Optometry" },
    { id: "3a7421eb-f102-4382-a6f4-5e4c17608132", name: "New England Conservatory" },
    { id: "cec6ab28-acc2-476f-bd78-0da96e86de01", name: "New England Girls' School" },
    { id: "a8fe0a72-af60-4267-b257-f98360caed52", name: "New England Institute of Technology" },
    { id: "8873c424-215b-4e5e-aae1-65a164de816c", name: "New England Law" },
    { id: "30f43e34-9598-40f2-bd53-7c22d3efb11d", name: "New England School of Acupuncture" },
    { id: "7afbda84-49c8-49ad-9f89-d6891ac4a014", name: "New Era Coaching Institute" },
    { id: "34db5051-c9d0-4db1-99b0-256b9c439b60", name: "New Era College" },
    { id: "d8b40887-de03-467f-9284-efcef08116ff", name: "New European College" },
    { id: "6df5f911-3698-4949-95b7-ec4e1f0d2d44", name: "New Hampshire Community Techinical College" },
    { id: "0b1c67f7-83e4-43f0-8a67-af46ebb600f9", name: "New Hampshire Technical Coll" },
    { id: "2c8dd114-ac96-4cde-8303-d7a3fb28cf84", name: "New Huado Business School" },
    { id: "b7560a17-f46e-4179-ac91-0b6eff9863f5", name: "New Huadu Business School" },
    { id: "122d52f4-0d7c-4f1a-a457-0f292b3062ac", name: "New Jersey City University" },
    { id: "58dd1dff-175d-4174-9cb0-d07802c52575", name: "New Jersey Institute of Technology (NJIT)" },
    { id: "e59a3b3e-1de2-4108-bb35-0f2c4e87d49a", name: "New Leaders" },
    { id: "0351af56-7fec-4dae-b738-f8013639049f", name: "New Mexico Highlands University" },
    { id: "52bc5db0-f912-44b3-9733-9b87800dce86", name: "New Mexico Junior College" },
    { id: "1c492863-656a-43d4-ae2e-b5af95b9a57a", name: "New Mexico Military Institute" },
    { id: "7c1ecf9e-ce2e-46ff-8026-902e274aa3cd", name: "New Mexico State University" },
    { id: "6f59265b-d3fe-4f2a-b3a0-b5254a80bf06", name: "New Mexico Tech" },
    { id: "d12b1ada-b15d-4fb3-9bc0-8eb6392a127c", name: "New Orleans Baptist Theological Seminary" },
    { id: "c7826f16-bd32-4c80-9318-fddf2b08fa76", name: "New River Community College" },
    { id: "b34d6f42-01cc-45bd-9298-c17a26fccb55", name: "New Westminster College" },
    { id: "1c02a8e1-86b0-4372-8a21-a1af17ec985f", name: "New York City Charter School" },
    { id: "f700e2e5-c06e-40fc-830f-062faa3170c5", name: "New York City Technical College" },
    { id: "b9f3fd81-54c5-42c8-91db-9de4bc241189", name: "New York Institute of Technology" },
    { id: "02e6fffa-9de2-40e8-b4f4-06bea40fa558", name: "New York Law School" },
    { id: "9325b282-412c-4d1b-8235-ae2dbbdb0e0c", name: "New York Legal Marketing Association" },
    { id: "a43f5605-b4c0-45f6-a8bc-c92850e91b8b", name: "New York Medical College" },
    { id: "75ecf46e-6aec-423b-99da-3ad7ce465e93", name: "New York University" },
    { id: "1710d474-9b07-409e-9f28-0447e8bdd6fd", name: "New York University Polytechnic School of Engineering" },
    { id: "f6f84fc1-d9cd-4671-92ec-8e7dd02ad296", name: "New York University Shanghai" },
    { id: "298bcef4-9b25-43f5-a2bf-b0ffa1da2805", name: "New Zealand College of Chiropractic" },
    { id: "174a9615-9983-4c57-991c-8dc458960275", name: "New Zealand Institute of Business and Technology" },
    { id: "8e82a0fe-49ca-41d5-9a7d-12edf4fd78fc", name: "New Zealand Management Academies" },
    { id: "70890eb9-df8a-4ce9-9e7a-175524a0d055", name: "New Zealand Maritime School" },
    { id: "602c70fc-e9b9-4cb1-a84c-d47ce36e7c70", name: "New Zealand School of Education" },
    { id: "6debf08e-6250-40a0-8c96-da4cae4ad297", name: "Newberry College" },
    { id: "ec26a55d-a9de-4dd9-8310-da84058541ea", name: "Newbury College Bookstore" },
    { id: "9683472a-7e92-462d-92c3-87f695535483", name: "Newcastle College" },
    { id: "cb5f4621-4fc0-45ac-b4da-03064ca200ab", name: "Newcastle University" },
    { id: "9513b62c-7d09-452f-a9d2-33f01cada43d", name: "Newman University" },
    { id: "311c0fbf-0cb6-4231-9b7a-674f784e49ca", name: "Newman University College" },
    { id: "4054ff7f-5919-40c9-8832-db46cf3884e8", name: "Newmann College" },
    { id: "21bba573-e9ba-4101-bb3c-c5a864fa4974", name: "Newport International University" },
    { id: "082ce631-d3ae-4063-a09a-323f7c89e3b3", name: "Newschool of Architecture and Design" },
    { id: "fb3e0b37-255a-4b01-a812-01270d635a4c", name: "Newton South High School" },
    { id: "5fe8f2ff-d107-4f6c-9e54-264660811151", name: "NEXT Campus" },
    { id: "2779c540-2ee0-42e2-8dd4-c63a3bb55df4", name: "Next Generation Learning" },
    { id: "40b70430-63d0-4c99-b71b-3f0e36f27dbd", name: "Ngee Ann Polytechnic" },
    { id: "e7469fa7-a71f-4961-a45b-f6d94af0fc03", name: "NHH Norges Handelshoyskole" },
    { id: "0c119f77-57ac-4882-8146-cd454b01f740", name: "NHL Hogeschool" },
    { id: "4a81e7c1-0f0a-4abf-a11a-873d10469939", name: "NHTV Breda University of Appiled Science" },
    { id: "e1182ff1-bdc4-4616-80f7-726f1bf860fa", name: "NHTV Internationale Hogeschool Breda" },
    { id: "5dec510d-7527-4b6c-9dee-3fe08b6a9fd7", name: "Niagara College Canada" },
    { id: "e89267a6-da32-4e04-b69c-cbf9bdf17596", name: "Niagara County Community College" },
    { id: "eb21e67b-f533-49d1-b245-b1e751fef8d6", name: "Niagara Institute" },
    { id: "d47b3c24-6c86-48ab-9327-49658ea49685", name: "Niagara University" },
    { id: "3a1b94ad-12fe-42cd-af8c-85c6e585dae8", name: "NIC Bank" },
    { id: "3c22edcf-ec32-49ba-8869-52c94f739f7b", name: "Nichibei Kaiwa Gakuin - Japanese Language Institute" },
    { id: "2726393b-972c-4c4e-aeed-5edc0050f0a6", name: "Nicholls State Univ" },
    { id: "fb792b53-0427-4893-ab6e-ebfffe29a0e6", name: "Nichols College" },
    { id: "ef9e8d20-4d79-42bc-92ff-aa3c01a6ba46", name: "Nichols State University" },
    { id: "0970968e-2e5b-47c0-b5dc-be1af60c6b6f", name: "Nicola Valley Institute of Technology" },
    { id: "359860ab-65c7-42a8-9e73-18ecdb516066", name: "Nicolaus Copernicus University" },
    { id: "9a39c445-b07f-46df-83fd-aa827486a4c0", name: "NIDA - National Institute of Development Administration" },
    { id: "00d1a99e-ebce-4c03-a023-669b97511487", name: "Niels Brock Fremtiden Er Din" },
    { id: "54157a0b-43c7-47e3-be8a-896e296971ec", name: "NIFT - National Institute of Fashion Technology" },
    { id: "7236fcc3-30c3-4f55-80b5-f7cef3ac43d0", name: "NIGG Architektur Gmbh" },
    { id: "eddf9b45-7be6-4482-b823-49f8e605e51b", name: "Nihon University" },
    { id: "ebfdcb25-4cc9-4ee9-8ccd-c71b5037e42a", name: "Niigata University" },
    { id: "19d20ed6-e6cc-4d04-bee2-3957b3e0bbd5", name: "NIILM - Center for Management Studies" },
    { id: "5931eda5-a916-4078-939b-b743dff4e3ed", name: "NIIT University" },
    { id: "dfd28cdc-537a-4ec8-ae58-19677362e91f", name: "Nilai University College" },
    { id: "6cfd5b26-f0dc-4d82-bf9a-76ce0a7eeb3d", name: "Nile University" },
    { id: "a185c20f-595f-4629-8443-e0cf52bdf1f3", name: "NIMA" },
    { id: "40cf8fec-c1a5-4ef1-961e-f40125dd4e87", name: "NIMA-Nederlands Instituut voor Marketing" },
    { id: "05b60a34-8070-405e-a1ed-2978178f1a94", name: "Nimra University" },
    { id: "4aa28ed3-00fb-4f5b-b13b-a018661c8069", name: "Ningbo Supply Chain Innovation Institute China" },
    { id: "8a2b79e2-b0d1-4db1-85a1-9b241b577a81", name: "Ningbo University" },
    { id: "e493996d-aff1-4bc2-b0fc-2dee2d09aa47", name: "Nippon Institute of Technology" },
    { id: "1c6947d5-89ad-42c4-907c-a5196afab714", name: "Nirma University" },
    { id: "e691453e-3b69-4499-89cc-ddfd7cc48cd1", name: "NIT Calicut" },
    { id: "aa17116a-8c1c-4904-a8c4-f211dd37dafb", name: "NITIE - National Institute of Industrial Engineering" },
    { id: "f38e93c5-0752-4b38-8682-8ca89aa539cd", name: "Nixor College" },
    { id: "9228c7a5-afb5-4cf4-8a30-f5b4c0ccd2d7", name: "Nmamdi Azikiwe University" },
    { id: "7a7d591c-9455-4a12-83e2-60fe4430c141", name: "NMIT (Northern Melbourne Institute of TAFE)" },
    { id: "c886ef7e-9f1e-4c9d-9efc-db7487736103", name: "NOCHI" },
    { id: "d1fce906-aac7-4676-8738-1a84fbdd7022", name: "Noida Institute of Management Studies" },
    { id: "fc44e817-551e-4541-a06a-98efe596d224", name: "Nokia Corporation" },
    { id: "17d02cb1-8a3d-49b0-bb33-e6d3df1dd646", name: "Nomura School of Advanced Management" },
    { id: "0fc1ee75-d0e6-470b-a301-d78f1b39d7ba", name: "Nord University" },
    { id: "bd447103-470a-4657-b575-c7506c008732", name: "Nord-Trondelag University College" },
    { id: "3edfc47c-c092-4130-b166-6b670a979ba2", name: "Nordakademie Gag" },
    { id: "80b024a1-447c-4373-b6ad-3ad24bf8c9c4", name: "Nordhausen University of Applied Sciences" },
    { id: "e8e0fb98-ae59-4c16-9471-446338166fb3", name: "Nordic International Management Institute" },
    { id: "3666965a-9a01-4aa9-a61f-598c403170bb", name: "Norfolk Collegiate School" },
    { id: "cd4ec418-64e1-4652-af55-885bb86b6be4", name: "Norfolk Public Schools" },
    { id: "f0908060-75d4-447f-9cfe-dc4fe593b437", name: "Norfolk State Univeristy" },
    { id: "33ecf66d-05cf-4f53-88df-e16c7000271a", name: "Norfolk State University" },
    { id: "125d623f-b268-4e30-80d1-8c21fdc3afb7", name: "Normandale Community College" },
    { id: "2d94fc63-690a-4852-a8d0-ab14eb16b685", name: "NorQuest College" },
    { id: "9b5372a8-9159-4a77-b0a8-057d59abfa24", name: "North American University" },
    { id: "74103f2c-6532-4954-9b20-8bd71f1495a6", name: "North Campus Bookstore" },
    { id: "8754e1e3-eb8d-40af-a50f-2beda816aa84", name: "North Carolina A & T State University" },
    { id: "cf157c51-22f3-42dc-ba6f-3c4419ac7db0", name: "North Carolina Central University" },
    { id: "ca410e6a-a119-4032-8af2-7743138f7a2c", name: "North Carolina State University" },
    { id: "8b960b5f-c229-49d7-9ff6-ab1bd6d749c6", name: "North Carolina Wesleyan" },
    { id: "b749a01b-281a-457f-be11-1616e20c1ac5", name: "North Central College" },
    { id: "1888566e-7352-4d80-aa7d-82f2f3474156", name: "North Central Michigan College" },
    { id: "37baa93f-61aa-43d1-a064-5aae084d209f", name: "North Central State College" },
    { id: "c0a1e44d-1709-46d9-ba0d-b131bc270979", name: "North Central Texas College" },
    { id: "b599685f-8721-409f-882e-99c28bcc9777", name: "North Central University" },
    { id: "4798cb93-d3bf-4309-bedd-7e20105ad0b1", name: "North China Electric Power University" },
    { id: "5661a1d0-53e5-417f-9f1e-6fe37b1affca", name: "North Coast TAFE Coffs Harbour Education Campus" },
    { id: "96aeec49-cc27-4fbc-833c-bfb818658bbd", name: "North Dakota State University" },
    { id: "64411186-dd9d-4824-b582-8743146d82ed", name: "North Eastern Hill University" },
    { id: "0f6f7a3d-5467-457b-bdf2-20d431ec3bac", name: "North Fort Myers High" },
    { id: "04b719f3-3fd7-440e-afeb-65ea86b4bb3f", name: "North Georgia College" },
    { id: "9d97037f-45f1-427e-b08f-02a70cadf464", name: "North Greenville University" },
    { id: "b528a1e5-af4b-4d7c-b64b-e5977c63cc33", name: "North Gwinnett High School" },
    { id: "ddfbc29b-d0eb-4f9c-88c2-fd78a1530a04", name: "North Harris College" },
    { id: "2c6ef7e4-fe99-4c04-aa9c-1c2d62954990", name: "North Harris Montgy Comm Coll" },
    { id: "9e1605d1-85ad-4f3f-942b-a4094036c3cd", name: "NORTH HENNEPIN COMMUNITY COLLEGE" },
    { id: "dd482390-90d2-44d2-a276-313a893bc3e8", name: "North Idaho College" },
    { id: "19248664-fedf-4975-89fa-6af64cba5860", name: "North Iowa Community School" },
    { id: "b0fe1d14-f3b1-43ac-ab00-2a63ec140cba", name: "North Island College" },
    { id: "08b7e7b2-fab7-44e3-8e98-7337c411b940", name: "North Maharashtra University" },
    { id: "e9b84d2f-1e15-4221-b120-207c7caded6a", name: "North Olmsted City Schools" },
    { id: "e0e190fc-b754-4c92-adad-7fb2f9fc1bc2", name: "North Park University" },
    { id: "00974eb2-0423-4b80-b9f7-3c503f0ab9b6", name: "North Seattle Community College" },
    { id: "60875b2b-1ace-4a11-8c69-c05be254acbc", name: "North Shore LIJ" },
    { id: "41b3fe85-fc2b-4857-a579-77ed4411f0ed", name: "North South University" },
    { id: "7810f22a-364b-4a25-836c-0c6ccc028ee7", name: "North-Eastern Federal University in Yakutsk" },
    { id: "371a85c0-b0c6-4188-8f83-dc816f746f3f", name: "North-West University" },
    { id: "5a16daaf-da2a-4399-bc32-da5152c53e3a", name: "Northampton Community College" },
    { id: "fc985b63-4cb1-4d5e-8a7d-08bc9d17aa27", name: "NorthCarolina A&T State University Bookstore" },
    { id: "7a94f6f0-9d4d-4c69-bf5a-7ec2ed384197", name: "Northcentral Technical College" },
    { id: "57160774-b1a0-4708-9cd3-3cae04b145a1", name: "Northcentral University" },
    { id: "4bb10b7d-6307-4d0a-af5f-01378fd9f887", name: "Northeast Agricultural University" },
    { id: "a7279874-f82c-4fb0-88b3-82a0d8c1e473", name: "Northeast Community College" },
    { id: "4f1f108f-7d2a-48c9-94f1-6ca6c0db88c6", name: "Northeast Deans" },
    { id: "7b0fc86a-964a-4480-868a-b563b7ec0624", name: "Northeast Iowa Community College" },
    { id: "7477feb6-0cb3-4309-a34f-4a69ad06dd01", name: "Northeast Normal University" },
    { id: "5d16582f-9eb3-4871-9346-df55a5420433", name: "Northeast Ohio Medical University" },
    { id: "ca84ca2e-40ef-42bf-b8c9-6325eb3c6566", name: "Northeast Texas Community College" },
    { id: "06f6a9f3-1d3e-44e9-be42-8a3299d04cb5", name: "Northeast Wisconsin Technical Community College" },
    { id: "ca5a0d4b-c78c-485b-813e-be95b36a9c92", name: "Northeastern Illinois University" },
    { id: "2404e236-7527-49f5-8d93-1a2482c4f74e", name: "Northeastern State University" },
    { id: "b27bbfda-c8ac-48bf-af17-0022980b4d4a", name: "Northeastern University" },
    { id: "40563020-5b2f-439f-a64b-57b16c39c8b5", name: "Northern (Arctic) Federal University" },
    { id: "3a64f694-b0d8-493d-b4b3-c00eaf6128ef", name: "Northern Alberta Institute of Technology (NAIT)" },
    { id: "9c3f917f-cc33-4bd6-bd0e-b0379d2f1f41", name: "Northern Arizona University" },
    { id: "b6ee6754-a449-47a0-83cf-bdf50feb22c6", name: "Northern Caribbean University" },
    { id: "74b0c26f-0c5e-4d02-9769-25a6b305c32f", name: "Northern Illinois University" },
    { id: "5373042c-8d2e-4e58-92ba-368ab3a7ba9d", name: "Northern Institute of Technology (NIT)" },
    { id: "4b038202-15de-4279-a01d-91c876744450", name: "Northern Kentucky University" },
    { id: "4dee878b-4e73-49c2-9072-b89363bc9769", name: "Northern Lights College" },
    { id: "ae33b510-a68c-4ad7-b1fa-d6c1820bbb14", name: "Northern Michigan University" },
    { id: "9c61e9a9-1e2d-4350-827a-4db4949001ce", name: "Northern New Mexico College" },
    { id: "cb35f6c1-60e6-485d-95bb-966ea4b36fcc", name: "Northern Oklahoma College" },
    { id: "e7e5eb84-dcaf-415e-bb94-d624e62557b4", name: "Northern State University" },
    { id: "2317bf42-da7d-4bde-ac7a-0cb11ed81571", name: "Northern Virginia Community College" },
    { id: "896ed97c-eaa0-44ba-b3de-431ada86977c", name: "Northland College" },
    { id: "265a78f5-8d1b-4934-93fc-5fab6089eadf", name: "Northland Community and Technical College" },
    { id: "a1f33e9a-a045-4097-a7d0-0e5f1711c76a", name: "Northland International University" },
    { id: "ab1741bc-e496-4e26-9d5b-fb1ec4c68822", name: "Northland Polytechnic" },
    { id: "01973256-26a1-446a-91e2-65a145aa1f53", name: "Northport-East Northport Schools" },
    { id: "4303dfa6-31a8-458c-a389-1a6d69997761", name: "Northshore Technical Community College" },
    { id: "0fe91907-404e-4fb2-a954-93fef3327b96", name: "NorthShore University HealthSystem" },
    { id: "cdb2d262-f0a2-4191-b9ed-9e651456ee21", name: "Northumbria University" },
    { id: "568cb06c-8ca1-4b3d-a75c-daed02ec7cfc", name: "Northwest Arkansas Community College" },
    { id: "b9078862-1f2b-4ed8-b264-c33612d657c5", name: "Northwest Christian College" },
    { id: "4d50f314-1f38-4b97-b15a-bc8162ae4b85", name: "Northwest Comm College" },
    { id: "a8c356d7-8638-4ed2-8808-a71f15c607cb", name: "Northwest Connecticut Community College" },
    { id: "0dca5e46-d7fc-45f4-aeb4-e837a01b929e", name: "Northwest Executive Education" },
    { id: "01acb82c-76c6-4834-bed6-450a7bb30dbd", name: "Northwest Independent School District" },
    { id: "4d89182d-da5c-4d67-9349-1c5c2a24676e", name: "Northwest Indian College" },
    { id: "6695d49c-e90f-415e-9c55-f2ab6322235b", name: "Northwest Missouri State University" },
    { id: "3e4342f4-ab20-4c69-8215-4d2245f79afd", name: "Northwest Nazarene University" },
    { id: "09eaddc3-a128-402d-a985-8b1b762ba86f", name: "Northwest Normal University" },
    { id: "d1204257-a628-45a2-ac19-67efbf13ec33", name: "Northwest University" },
    { id: "448a5783-0d70-4e4c-8d1d-081bb2bf5c7f", name: "Northwestern College" },
    { id: "c53a66c8-bcb7-464c-a66b-eba04ec85dd6", name: "Northwestern Exec Ed (Online Usage Reporting Place Holder)" },
    { id: "558f3077-1acb-48f8-8af9-71c408c7d0a1", name: "Northwestern Michigan College" },
    { id: "fc839ab9-c868-496e-976f-9a61c397e5b2", name: "Northwestern Oklahoma State University" },
    { id: "bb7855ae-a303-4fba-83f4-842c971a7fc3", name: "Northwestern Polytechnic University" },
    { id: "2f76bed6-2334-44cb-a747-a41182eeeb15", name: "Northwestern School of Law" },
    { id: "245b905d-042d-411c-a1d6-982d79c89593", name: "Northwestern University" },
    { id: "3970119c-6853-4030-9939-694e2e4394d0", name: "Northwestern University Alumni Relations and Development" },
    { id: "a750f2b2-81e5-4166-aff2-9b2b12d33665", name: "Northwestern's McCormick School of Engineering" },
    { id: "7027ff54-d3af-4471-ad43-c36f2b3ff52a", name: "Northwood University" },
    { id: "c9a32737-0e8f-4031-ad85-3142b12731f6", name: "Nortumbria Univ School of Law" },
    { id: "27af7bc9-6a48-4b49-b4fc-7c470994d58a", name: "Norvik University College" },
    { id: "749a3957-103c-4587-ba42-59beceead45d", name: "Norwalk Community College" },
    { id: "e8b7b31d-3a5b-4e8c-9810-22ec92088b8a", name: "Norwegian School of Econ and Bus" },
    { id: "1e12ef4d-8df6-4e49-806b-8f6743dcde71", name: "Norwegian School of Economics (NHH)" },
    { id: "1bfcfb1f-d31a-4f33-8942-9617624f3cb1", name: "Norwegian University of Life Sciences" },
    { id: "17dc5db2-00a8-44b9-a5f5-89026af24944", name: "Norwegian University of Science and Technology" },
    { id: "1977902a-79b4-41c4-a1e8-3f583824ffb8", name: "Norwich School" },
    { id: "a1b23e91-5300-448e-af95-a70b272564ee", name: "Norwich University" },
    { id: "2600c7cf-4a13-4b57-ad23-b4d3db9dc698", name: "Norwich University School of Graduate and Continuing Studies" },
    { id: "b8923fe4-c2d7-4cfa-a14c-35d698121e92", name: "Notre Dame College" },
    { id: "47fde953-1b5a-4933-b85f-6b9648f272f4", name: "Notre Dame de Namur University" },
    { id: "98741e5f-47d9-418a-99aa-a376bad8b569", name: "Notre Dame of Maryland University" },
    { id: "0d09c35d-57ae-43db-b027-f9458d199a15", name: "Notre Dame University" },
    { id: "c2a8d4a5-a5ac-4440-8cfd-1b47f1e02c6f", name: "Nottingham Law School" },
    { id: "e6bcd4ba-8f06-4585-b373-f9e6345d36e7", name: "Nottingham Trent University" },
    { id: "759648c3-9971-4ec9-8f52-92617d5de74b", name: "Nova Forum (Executive Education Institute)" },
    { id: "30d43f26-8988-45d6-a439-6c72fe616fae", name: "Nova School of Business and Economics" },
    { id: "6e0deb1f-2024-4587-aefc-ffe98f4bf41f", name: "Nova Scotia Agricultural College" },
    { id: "fd2db3b9-54cd-4cf1-89c8-d4e30a73c962", name: "Nova Scotia College of Art and Design University" },
    { id: "a36555ac-4aad-4ac7-b742-cb7ba40fb5d2", name: "Nova Scotia Community College" },
    { id: "81d189f6-6728-48b2-af0c-8987b848a6da", name: "Nova Southeastern University" },
    { id: "c58ebab3-4623-43cc-8815-b212655c71be", name: "Novancia Business School" },
    { id: "193b5c50-dbbe-4322-b2e0-d30c0bd2923c", name: "Novel Institute of Management Studies" },
    { id: "df727bdd-bce2-41c3-8979-89a9ac221593", name: "Novo Nordisk Saglik Urunleri Tic. Ltd. Sti." },
    { id: "567436fe-72d0-4522-b0a1-e6dffd9928d0", name: "NSW Government" },
    { id: "d2aac8f4-225d-4689-ae77-67133f727b5f", name: "NTPC School of Business" },
    { id: "98e2fd80-28a9-476a-857c-f51360fa6dd8", name: "NTU Nanyang Technopreneurship Center" },
    { id: "7b39395f-c0ad-474e-87fb-a3c90c30c1de", name: "NTU School of Civil and Environmental Engineering" },
    { id: "f2821e9f-3d3c-41a5-8504-751e43d4bfc5", name: "NUCB - Nagoya University of Commerce & Business" },
    { id: "fc35eec8-a6a4-49c0-8d31-0a53c7f488f9", name: "Nurnberg University of Applied Sciences" },
    { id: "8a2f9668-b4b0-4a44-8eb1-53a63c53b530", name: "Nurtingen-Geislingen University" },
    { id: "a1b810de-2830-41bb-bc83-b11b6ded3601", name: "NUS Business School" },
    { id: "584910eb-8c3c-4686-8dbc-9ed9ad2e4f2d", name: "NUS Executive Education" },
    { id: "2b922d00-93a1-437f-925b-cb1a60b3dee4", name: "NUS Graduate Studies" },
    { id: "79304831-71ab-4ebc-b2a9-1ba0a1fb5e27", name: "NUS School of Computing" },
    { id: "d342f851-cd4b-4820-a6af-1fb8e75dcb3a", name: "NUST - National University of Science and Technology" },
    { id: "f29c3c1a-cf40-47d9-bf71-1a9998af2166", name: "Nutrition Therapy Institute" },
    { id: "66d16131-cffb-4f28-af4e-744cecd2c705", name: "NW College" },
    { id: "79d2ae8c-c38b-4e08-8793-145670b1cd2a", name: "NW Conn. Comm.College" },
    { id: "26f55e8b-dac5-4c53-994a-4ada0365f00a", name: "Nyack College" },
    { id: "bdd1786e-f1df-4d5a-a08d-7d89c89a02c3", name: "NYC DEPARTMENT OF EDUCATION" },
    { id: "b91b3edd-c102-4404-a507-a212d066fc17", name: "Nyenrode Business University" },
    { id: "366c177e-aca8-4211-8d57-3052e51cc310", name: "NYU Tandon School of Engineering" },
    { id: "9c3aa5b3-6548-439d-8949-bb692db4e1e7", name: "O'hare Depaul" },
    { id: "e55359b0-f6b0-4721-b408-10350ae91d22", name: "O. P. Jindal Global University" },
    { id: "0bca453a-6269-4ca0-904a-b31926b12db5", name: "Oakland City University" },
    { id: "5775c755-fcaf-4363-bc06-a231bb77f752", name: "Oakland Community College" },
    { id: "16b91210-6a26-4803-85e7-49e6432fa2a9", name: "Oakland Community College Foundation" },
    { id: "fa70d764-da09-4bf9-8aa4-eb6042e0c88c", name: "Oakland University" },
    { id: "242a5bd7-4cbc-4ddb-afab-103bcd855d2d", name: "Oakton Community College" },
    { id: "14f594c6-ee4d-4d03-a890-5b1fc3557c73", name: "Oakwood University" },
    { id: "82bc2cb1-3cfa-4c29-9dfc-29134e7cf444", name: "Oberlin College" },
    { id: "02c99070-f339-4070-9f5a-e71c835b53b6", name: "Oberlin College Library" },
    { id: "61ec4e4e-7e4e-487c-922e-41d62d2c7e98", name: "OBS Business School" },
    { id: "8373ea2f-bc04-4b37-ab85-58cf4285a06f", name: "OC&C Strategy Consultants, UK" },
    { id: "bcb0b3ba-29f2-4e5e-b95e-7a8e73d46b86", name: "Occidental College" },
    { id: "f3eadc4a-e79c-4902-8059-5e13f0c242f1", name: "Ocean County College" },
    { id: "d93692b4-1838-4e6d-8d61-739a3a9b9b27", name: "Ocean University of China" },
    { id: "599c9b6b-0a00-4593-81d9-10cfabe549ca", name: "Octavio Paz International Academy" },
    { id: "09d6b9ac-4485-40d6-a2a5-bb4e2084a830", name: "Odessa College" },
    { id: "a9b92369-d231-4c75-8f86-882c002ca14e", name: "Odyssey Bookshop" },
    { id: "feaf8e67-3154-4c67-9d3a-316b745d01c3", name: "OFFICE OF PERSONNEL MANAGEMENT" },
    { id: "4dbc24a9-deac-4ae1-8da9-bb1d57fc3772", name: "Official University of St Augustine" },
    { id: "9360fe19-6d9f-4cbc-89c5-2259a16d6667", name: "Oglethorpe University" },
    { id: "deecdaf3-0a21-48a5-8633-e32625aa5cfd", name: "Ohio Christian University" },
    { id: "d70a1f72-65c3-415a-b963-963ed719a2a1", name: "Ohio Dominican University" },
    { id: "6de59336-1b18-4f59-9d57-041648dc356c", name: "Ohio Northern University" },
    { id: "aa900d95-abc8-4dc6-a7cf-2b1fb021405e", name: "Ohio State Bookstore Account" },
    { id: "10ad5b8c-ca5d-4c98-b93e-e2e94ec1a26f", name: "Ohio State University - College of Education and Human Ecology" },
    { id: "a0552e92-15fa-46bd-8b09-8c4eaa37dad2", name: "Ohio State University - College of Public Health" },
    { id: "6ce5ff3e-2658-4cd1-acd5-e00b13dc5eb8", name: "Ohio University" },
    { id: "636d5722-731b-41a0-8ed9-adaf19b6c187", name: "Ohio University Southern" },
    { id: "36c16df9-986a-499e-9fbc-9707880e4a09", name: "Ohio Valley University" },
    { id: "1487af72-4d35-46c2-80bc-2fd29b778a91", name: "Ohio Wesleyan University" },
    { id: "776576fa-a5c4-478b-a548-e22eba7a6ffc", name: "Ohlone College" },
    { id: "9e64dfd1-e064-4ff1-b2d1-942965758c28", name: "Oita University" },
    { id: "da17e666-4a09-4b6e-b2ef-2b7e93439c04", name: "Okan University" },
    { id: "7882a779-bdd1-4725-95ad-5a31bbb420d4", name: "Okanagan College" },
    { id: "cbc33e02-a69f-478b-9f8a-4e6ff9921b20", name: "Okanagan School of Business" },
    { id: "6bf1fd7a-449f-41bb-a4b2-511cdbda6e37", name: "Okayama Prefectural University" },
    { id: "5333dad6-9142-4761-8d40-215342fe32bb", name: "Okayama University" },
    { id: "91c84101-f895-40c4-860b-f7756cb23ae1", name: "Okefenokee Technical College" },
    { id: "1b3181e2-1b96-4f1a-ae2c-7bc186925e64", name: "Okinawa Christian University" },
    { id: "6be03f5c-9bf7-4a06-8287-df33d3f125a0", name: "Oklahoma Baptist University" },
    { id: "39619997-02af-431b-9b9a-69988c97b45f", name: "Oklahoma Christian Bookstore" },
    { id: "73cd0a81-8748-43e4-9102-1d5c58469b5d", name: "Oklahoma Christian University" },
    { id: "94f5066b-e17c-41b4-baf3-cd67b1c86395", name: "Oklahoma City University" },
    { id: "e71b0045-df2d-4817-997b-471306e4cc7f", name: "Oklahoma State University" },
    { id: "f3271212-32b2-4fa6-a647-e151d2de676d", name: "Oklahoma State University - Oklahoma City" },
    { id: "4c391af0-95eb-4a88-bf5d-2c2bc13dfb76", name: "Oklahoma State University - Tulsa" },
    { id: "f747e2bd-9d37-4ee8-a723-29d44d5fc1f4", name: "Oklahoma Wesleyan University" },
    { id: "ac3fdd9e-826e-445b-a5f8-473b6b410b1a", name: "Olabisi Onabanjo University" },
    { id: "7f296970-c2d6-4839-a19d-5639302af806", name: "Old Dominion University" },
    { id: "c4616c98-a94e-4608-b837-1819dcdc3486", name: "Old Mutual Plc" },
    { id: "d14d6adb-2154-4493-83ac-05cdb7fc3510", name: "Olds College" },
    { id: "8921edff-00a4-45fc-8855-84e3d5844f66", name: "Olivet College" },
    { id: "f07cb308-aca7-44fe-b565-2426e85bb1c1", name: "Olivet Nazarene University" },
    { id: "61a1c0b2-cf8e-47f9-8b4b-bb80a1fa6cb1", name: "Olivet University" },
    { id: "cbd318c3-c0a8-4762-8791-1a0336656505", name: "Olney Central College" },
    { id: "c44c66d0-0627-41b4-8b64-ccf687e4e2cd", name: "Olympic College" },
    { id: "b0e62ec3-c756-4ae3-a525-7f633344be3b", name: "One League" },
    { id: "631c8fbd-4595-4fe5-8fd0-432df789a3ff", name: "Ono Academic College" },
    { id: "85c0f0c0-589b-4ecb-b3fc-d9f181887db8", name: "Onondaga Community College" },
    { id: "f2647d77-161f-487a-91cb-a2bc4caf937f", name: "Ontario College of Art and Design" },
    { id: "3b3f9671-27b6-4c8a-a7ff-787222364de1", name: "Ontario Institute of PMAC" },
    { id: "17ef1e50-6c52-42be-a238-8db276a322db", name: "Ontario Police College" },
    { id: "ecc3d2d3-2c6d-4eac-ae49-0b1df371ce82", name: "Open University" },
    { id: "f410beff-ced9-4e25-a3a5-10abdb5e659b", name: "Open University Malaysia" },
    { id: "951d5e45-d1b7-43af-ac70-e8c66c888350", name: "Open University Netherlands" },
    { id: "e74db27b-21b5-48fb-8181-f055a33907f1", name: "Open University of Cyprus" },
    { id: "6fb9bcb5-311b-40d0-9795-9e014b78b9dd", name: "Open University of Japan" },
    { id: "a78e8257-fc67-4858-8707-5b6c5d61b745", name: "Open University of Mauritius" },
    { id: "4c745292-3c48-4d4b-8eba-b235ab14030f", name: "OpenClassroom" },
    { id: "7996fece-2dad-4836-9445-c289b8a9c87b", name: "OpenStage West" },
    { id: "37ff48ef-817f-4e4c-bfcf-bbc46fc3c066", name: "Opole University" },
    { id: "c8691c57-7dc5-4244-8ff9-07e5420df82b", name: "Oral Roberts University" },
    { id: "89d0be48-06cc-4b13-bb5d-a999c6e055d3", name: "Orange Coast College" },
    { id: "69ec8ae5-cf0c-43ee-bcd4-219aec74eb89", name: "Orange County Public Schools" },
    { id: "af4c2172-3680-458c-a9da-8086bd30a902", name: "Orangeburg-Calhoun Technical College" },
    { id: "da8b52af-2b1b-4587-80ec-f2e3dd8759bc", name: "ORDINE DEGLI INGEGNERI DELLA PROVINCIA DI ROMA" },
    { id: "4bfdf8b3-8e3f-4f67-8878-d9a57367a927", name: "Oregon Health & Science University" },
    { id: "ecd457fd-d5a2-4271-8c0b-f4f34dcf61e9", name: "Oregon Health Sci Univ" },
    { id: "14db7f92-544b-476d-8be2-776a491e695b", name: "Oregon Institute of Technology" },
    { id: "ddb7db0a-fea3-4ab1-ab86-d5d0e7f18bb9", name: "Oregon State University" },
    { id: "5c8b5e63-b66d-4fcf-8f80-30ba207a6691", name: "Organizacion Educativa Tabscoob" },
    { id: "73daee12-7fb1-4acd-a344-6456e46ef205", name: "Oriental Institute of Management" },
    { id: "a098a4b8-1085-4b6a-8964-d309e09ceff8", name: "Ortopedicos Futuro" },
    { id: "c99748b5-f57c-4b12-89ab-7726e535140d", name: "Osaka Jogakuin University / College" },
    { id: "0e1480ce-ccb6-411d-bb1d-96ecea6e88e7", name: "Osaka Seikei University" },
    { id: "471a309a-fe21-48e6-bc9a-812cc2284d45", name: "Osaka University" },
    { id: "89104030-b847-485e-b157-cddb4500951c", name: "Osmania University" },
    { id: "82819c91-f90d-436b-84c7-a8973739d76f", name: "Osnabrueck University" },
    { id: "a4c44131-23f6-4e74-ba22-4609eac21951", name: "Ostbayerischen Technischen Hochschule Regensburg (OTH Regensburg)" },
    { id: "50ef6f8f-d21a-4265-b393-e037f5c2c289", name: "Ostfalia University of Applied Sciences" },
    { id: "e164ab6c-5d7c-4a84-8a90-dae9518db5fc", name: "Ostfold University College" },
    { id: "b6ef0e1c-b420-4403-a970-d8374cfd5d97", name: "Ostrava Business School" },
    { id: "df19a9e7-97dc-49cc-a4ef-78da9b17da60", name: "Otago Polytechnic" },
    { id: "671249f8-7141-4e8c-8c84-acbecfb65494", name: "Otaru University of Commerce" },
    { id: "6a720725-8765-44a4-8094-6d65b6b0260b", name: "Otis College of Art and Design" },
    { id: "426b119a-eda0-4417-8dad-d1086fe13a01", name: "Otsuma Women's University" },
    { id: "98084e90-3eac-4907-ae1a-960554af054b", name: "Ottawa Township High School" },
    { id: "022057c3-6c4d-4a90-bafb-c4a541bb0498", name: "Ottawa University" },
    { id: "e6c88846-082a-478d-b74b-6d0a30bb427d", name: "Otterbein University" },
    { id: "38dbe726-0531-45c1-8c23-2a4b683c2147", name: "Otto-Friedrich-University Bamberg" },
    { id: "3daaf1e8-31b9-4099-9d87-484022cfe7dd", name: "Otto-Von-Guericke-Universitat Magdeburg" },
    { id: "3e89f07b-d9cd-48e5-91a4-9d21bf4384ce", name: "Ouachita Baptist University" },
    { id: "d49d1b36-18fd-4b5b-9e5d-8db67ba7f5d4", name: "Our Lady of the Elms School" },
    { id: "3d8de5c0-c4af-4cc2-9766-0e1f1b3c99f6", name: "Our Lady of the Lake University" },
    { id: "081e3218-8623-4bff-acc6-8ae44ea59651", name: "Owens Community College" },
    { id: "75486aa3-3513-42c3-a789-6c400bd7c127", name: "Oxford Brookes University" },
    { id: "c127df60-423c-4f26-a862-5e15d317c521", name: "Oxford Copy Shop" },
    { id: "8c34bdff-a8c1-46e2-97c3-cfcfe0403ca7", name: "Oxford Intensive School of English" },
    { id: "962aa0a9-478e-4dc9-9076-d98ec5b09942", name: "Oxford University Press" },
    { id: "6008323a-f898-473e-85c1-5473acfccdbb", name: "Oxnard College" },
    { id: "450b2728-08ac-4a0d-b949-812b4c4bce2c", name: "Oyama National College of Technology" },
    { id: "eb89d9a1-b988-4409-8950-8e4ab69984ee", name: "Ozarka College" },
    { id: "03d36ee6-5eb3-449f-89db-5e58aca73676", name: "Ozarks Technical Community College" },
    { id: "da96eb75-2b93-4fe6-a406-50ca6dddfed0", name: "Ozyegin University" },
    { id: "c211be9f-5f8f-42df-bcbf-fc5a2ba81cc1", name: "Pablo de Olavide University" },
    { id: "55acc0ff-3abd-4c74-825e-8be30a2e767d", name: "Pace University" },
    { id: "7755dede-32b7-4770-ada1-af1d839bbe61", name: "Pacific Basin Ctr Found" },
    { id: "ef94dbac-1385-4f3a-aa21-fbbd99803ebc", name: "Pacific Coast Banking School" },
    { id: "b3dc3ca3-c55e-4c09-b927-76c3247845a9", name: "Pacific Lutheran University" },
    { id: "211dd665-de0d-4ecf-8196-711ae0a66f86", name: "Pacific Northwest University of Health Sciences" },
    { id: "d6c29c3b-6bc6-486c-8059-82e1bc2fc4ff", name: "Pacific Oaks College" },
    { id: "1bdfef70-0fa9-43fd-ab5d-fb34f0cd1d63", name: "Pacific States University" },
    { id: "44325342-b795-46df-8965-b32ae08b5469", name: "Pacific Union Colllege" },
    { id: "fb6ec8e5-5d9b-42ac-9050-f305774d6524", name: "Pacific University" },
    { id: "409c418e-5910-4a55-a274-60f3dc6da572", name: "Padmashree Dr. D.Y. Patil Institute of Management Studies" },
    { id: "32686030-e9c7-4aed-9cf2-31e5a3ca2a4c", name: "Padre Conceicao College of Engineering PCCE" },
    { id: "44dcce1f-ef21-4ec2-96c2-97e38efb895d", name: "Paine University" },
    { id: "38849ffd-42b0-4136-a6a9-ffc85243efc8", name: "Pakistan Institute of Development Economics PIDE" },
    { id: "9c5f0e3b-39af-4ec2-b9e1-43cec6e02eea", name: "Pakistan Institute of Fashion Design" },
    { id: "cda5cfb3-b053-4c29-9fa0-d7181311f405", name: "Pakistan Institute of Management" },
    { id: "37d173b7-0fd9-4b07-8e2e-1af7369c4b3d", name: "Palm Beach Atlantic University" },
    { id: "11a3a6b8-777e-475a-8fce-e0b934aeff1d", name: "Palm Beach State College" },
    { id: "f5be7e01-c685-4a18-89b4-d9eecf07ee89", name: "Palmer College of Chiropractic" },
    { id: "8cc58d0d-14ee-4d64-8b0a-f2c0d3b3b3dd", name: "Palo Alto College" },
    { id: "b03e74d1-289e-403c-9e4b-83cd82a25f48", name: "Palo Alto University" },
    { id: "ac9f9047-7bb1-4dd7-ba4e-0085c0daca86", name: "Palomar Collge" },
    { id: "e77eaf74-a06c-4e93-a79a-5670618d5356", name: "Pamantasan ng Lungsod ng Maynila" },
    { id: "bcfac364-b49e-497a-a311-ea0cd75c031c", name: "Pamlico Community College" },
    { id: "aa059844-3ad0-4562-9132-15d81c241710", name: "Pamukkale University" },
    { id: "cd604113-6e21-4ffe-9419-239995e3ff28", name: "Pan Africa Christian University" },
    { id: "045e7870-8505-4555-a6d3-67d13a3689bf", name: "Pan-Atlantic University" },
    { id: "dbfe034f-9696-4392-9db6-ad749f519852", name: "Pandit Deendayal Petroleum University" },
    { id: "bff73790-cb62-4198-b6ee-8b1ff265b2a9", name: "Pandit Ravinshankar Shukla University" },
    { id: "c943d836-5d29-44fd-b4fd-94e012da10b1", name: "Panjab University" },
    { id: "6b6a55b6-37b8-41db-ab24-3e36dcee898a", name: "Panteion University" },
    { id: "569727eb-bae1-4439-a792-fae541014cfd", name: "Pao Yue-kong Library" },
    { id: "461ae360-eecb-4bbb-933a-359bae35d26b", name: "Papua New Guinea University of Technology" },
    { id: "36820598-7b10-4005-819f-56d3ca55ba45", name: "Paradise Valley Community College" },
    { id: "4ead713b-0d2b-4d1e-bf59-cf617df530b5", name: "Parahyangan Catholic University (UNPAR)" },
    { id: "dee3dd69-ba0c-4e8a-b645-535d6567602a", name: "Pardee RAND Graduate School" },
    { id: "f0207b62-7f7d-43fb-9ce1-7ab071140e9c", name: "Paris Business College" },
    { id: "6a564964-d3a2-4619-b901-6120b5a796d4", name: "Paris Diderot University" },
    { id: "67d35a1c-2821-48b7-97ce-ed81d3d9003d", name: "Paris ESLSCA Business School - Egypt" },
    { id: "e1fe0c0d-074d-49a5-a9db-c761f760e5bc", name: "Paris Junior College" },
    { id: "2820eece-8781-4c4b-84ef-b317969c63b5", name: "Park Continuing Education for CEO's" },
    { id: "5c45a746-308d-403d-bea4-abe572985e06", name: "Park Global School of Business Excellence" },
    { id: "08c87e6c-8b1e-4a15-9b3d-b7d03d56abc3", name: "Park University" },
    { id: "8c64a269-2b1c-40fd-8632-8e0fbb167e72", name: "Parker University" },
    { id: "39923c7f-e634-4f4b-b958-04d8f94d5479", name: "Parkland College" },
    { id: "5e8f5c10-8f3d-408e-968c-7634920406cf", name: "Parkway South High School" },
    { id: "579dcb84-bcd4-429e-8d16-a2ad99144a21", name: "Parsons Paris School of Design" },
    { id: "4d910080-a2b9-4207-9eb0-a8db3c454899", name: "Partners in Health" },
    { id: "5a6a88ae-e6c9-40c8-a4b0-83324cb22b5f", name: "Pasadena Arts Center" },
    { id: "0a3b9eb0-ac8d-485f-8b5c-11b39d99f9f9", name: "Pasadena City College" },
    { id: "91766d03-8a8e-4d48-8139-5ba02b843cab", name: "Pasco-Hernando College" },
    { id: "91da10fc-ba23-433e-8e7b-482fde83a193", name: "Passaic County Community College" },
    { id: "532e6104-513b-4e2a-93e5-d267b6224f07", name: "Patrick Henry College" },
    { id: "a706f7ff-ad5f-4259-878a-0bf53953a3df", name: "Patrick Henry Community College" },
    { id: "461e96db-d99b-466c-aeec-883183b1b911", name: "Patten University" },
    { id: "35cf9680-87ec-4f65-bf93-0ef54281ee73", name: "Patuakhali Science and Technology University" },
    { id: "fdc69502-c9d2-4afa-8907-b542a7813036", name: "Paul Smith's College" },
    { id: "465555af-94be-49f4-92b9-adb6074a4e7b", name: "Payap University" },
    { id: "3576efcf-f725-42fa-a3b8-1244aea32d5e", name: "Payments Manager" },
    { id: "7d9fd3b4-1cab-47fc-ba98-7c3c6f7f5747", name: "PBMS" },
    { id: "efee0e8d-1476-4881-a3c0-52faa868d1e9", name: "PBNA" },
    { id: "04d84c47-df7c-453d-93f3-6e3b69c5c4e6", name: "Peace College" },
    { id: "ce2eeac3-6c3a-4b92-82f0-598ebcdb71d8", name: "Peace University, USA" },
    { id: "b142ed2f-d046-479a-be77-88f79c5b7fac", name: "PEAKS Business School" },
    { id: "10e4d167-db97-4827-ae4b-9b7f08c009d1", name: "Pearl Academy" },
    { id: "0d71fc30-6e63-41f3-a277-b8935ca53729", name: "Pearl River Community College" },
    { id: "1a730339-7466-497b-904d-ffe163eb2fcc", name: "Pearls Institute of Hospitality and Management" },
    { id: "c4945181-1250-4ce4-8fd7-bb4e6a30d2e1", name: "Pearson College" },
    { id: "1785254a-fb78-4f3d-a157-a76b76e6e68e", name: "Pearson Education Australia" },
    { id: "42e2e496-6d4f-443a-adc2-9b58bd1e414f", name: "Pearson Education UK" },
    { id: "ad425c92-fa3b-4108-9c27-0c0b69766ccb", name: "Pearson India Education Pvt Ltd" },
    { id: "884d4fa2-7dc5-413a-af38-eb1327b424b3", name: "PECB University" },
    { id: "f697bd48-a9fc-43a8-ab66-8092a5921dfe", name: "Peddie School" },
    { id: "d854427d-9af6-4eb4-ab6b-acf3cc00b50b", name: "Peirce College" },
    { id: "26b00526-a37a-46ab-bacb-b7eaf579cd23", name: "Peking University" },
    { id: "4fbaded9-892a-4ed3-aa02-392251c032e7", name: "Peking University HSBC School of Business" },
    { id: "e8c16a74-e7c2-4200-92fe-58ccddd93b04", name: "Peninsula College" },
    { id: "e649cdc0-2e32-4ce8-b5a5-0af9864c009b", name: "Penn Concessions Inc" },
    { id: "360b3b55-0f7f-42d0-a8b6-34a1b6d6d13d", name: "Penn State - Brandywine" },
    { id: "135cc618-b745-431b-b2b0-297758d47349", name: "Penn State Abington" },
    { id: "8fc3e39a-1ee2-4521-ab97-bec6847d42d7", name: "Penn State Berks" },
    { id: "77848f93-79be-426d-88c8-60200a1ebecb", name: "Penn State Copy Shop Online Usage Reporting Account" },
    { id: "742920f7-3ddf-46b5-bf85-3605a90f49ad", name: "Penn State Erie, The Behrend College" },
    { id: "31044371-07f0-48a4-bbd9-b5cc69db9e35", name: "Penn State Shenango" },
    { id: "2b507324-98a6-453e-b5dc-65801671c3f3", name: "Penn State University - Altoona" },
    { id: "47a32bea-9e3c-4102-a07f-8460bb71d5c7", name: "Penn State University - Beaver" },
    { id: "021a9e83-3f2b-45b4-824e-6c012a2700e3", name: "Penn State University - College of Agricultural Sciences" },
    { id: "84e19360-c13a-4dff-bf3d-90048b684c94", name: "Penn State University - Great Valley" },
    { id: "281cf21a-5e09-49bc-b8a6-b821296208b9", name: "Penn State University - Harrisburg" },
    { id: "56b8732b-9741-4829-8598-c2bab308ea84", name: "Penn State University - Lehigh Valley" },
    { id: "ccd873ea-6007-42b7-b3ba-2ae675eb23a9", name: "Penn State University - New Kensington" },
    { id: "b95d2e0a-b1a4-4327-937a-cf13748c6181", name: "Penn State University - Reading" },
    { id: "64ecb75d-ea64-481e-94f6-445c464be84d", name: "Penn State University - School of Hospitality Management" },
    { id: "155350d4-c75c-41eb-8314-05157efbeca3", name: "Penn State University - Scranton" },
    { id: "70743e58-6c8e-494e-b2c7-7f81315d96e3", name: "Penn State University - Wilkes-Barre" },
    { id: "e7e5c177-a793-4dae-bdf6-405e360a1ba5", name: "Penn State University - World Campus" },
    { id: "d1cdad3c-c7f3-4c89-8219-ee374777fe39", name: "Penn State York" },
    { id: "0cc96e3f-cc46-4e21-9050-1fe15711808c", name: "Pennsylvania College of Health Sciences" },
    { id: "83565aa0-3820-4f4f-9a46-274c168152bc", name: "Pennsylvania College of Technology" },
    { id: "1d101a78-95b0-43a0-8e40-9150db4315c2", name: "Pennsylvania Western University" },
    { id: "c1721407-6f3a-46f4-a1f1-56006444a2e4", name: "Pensacola Christian College" },
    { id: "e011eb8e-4b09-4471-a956-ddea22ededde", name: "Pensacola Junior College" },
    { id: "f9f45149-da1c-416d-b35d-f15aba92c4d0", name: "Pensacola State College" },
    { id: "648d11eb-2c57-47ce-9130-c75975a7c28d", name: "Pentecost University" },
    { id: "c847f47e-3605-41b6-8925-f50fae305779", name: "People Communicate Ltd" },
    { id: "5a68a95a-3666-4c5b-ad1b-f2c7b26ccc12", name: "Peoples' Friendship University of Russia" },
    { id: "d0f3777d-8fb2-48a2-8e35-7057b50f378f", name: "Pepperdine University" },
    { id: "20f41b04-33d7-450b-8ba7-db829f683d5e", name: "Pequannock Township Public Schools" },
    { id: "bb0ca336-bd57-4264-8aa9-5a05ee36bd50", name: "Perbanas Institute" },
    { id: "ec7b97a1-c11f-4f9e-a35f-554a8ff77b88", name: "Peregrine Academic Services" },
    { id: "fad47fea-ce16-43ea-838e-0d5b3ddfe9b8", name: "Peres Academic Center" },
    { id: "16fa1200-463d-4dd5-a6f4-72e36801a644", name: "Periyar Maniammai University" },
    { id: "7ec668f0-1f25-43e8-a0a6-933d57877c04", name: "Perm State University" },
    { id: "feb0093b-af41-440f-b5d2-bb6a3d3b54d6", name: "Permira Beteiligungsberatung Gmbh" },
    { id: "cd4b27fc-4569-4ad3-8da8-b008c39da708", name: "PERSEUS DISTRIBUTION (CDS)" },
    { id: "e3706e28-9823-445a-b5d1-f8039060b995", name: "Peru State College" },
    { id: "4e21af20-a65e-4807-8151-12bdf93d7be6", name: "PES Institute of Technology" },
    { id: "dbc991ba-0147-4b58-91d6-fcafd8454cd4", name: "PES University" },
    { id: "28622b2f-45b5-474c-9c17-b770742f4dc7", name: "Peter Fitzpatrick" },
    { id: "55979bd9-50f3-45f0-b80d-95f981be468a", name: "Petra Christian University" },
    { id: "382002a4-6e1c-4edd-abbb-6a53750b831b", name: "Petro Mohyla Black Sea State University" },
    { id: "96501e49-6a38-42da-8584-ea56497a727f", name: "Pfeiffer University" },
    { id: "84fc9bc3-bd4e-40fe-8fe8-8506b1c60752", name: "Pforzheim University of Applied Sciences" },
    { id: "680ffa3a-7671-4444-bfd9-2c06958fdf8c", name: "Philadelphia College of Osteopathic Medicine" },
    { id: "9e525033-58f8-4be4-b6a5-b0bd23f1793b", name: "Philadelphia University" },
    { id: "005b55dd-ac0e-4cea-9c48-bf4273cbbf94", name: "Philadelphia University - Paul J. Gutman Library" },
    { id: "0195de87-5b9e-46c7-94bd-d4c06bc5c5fe", name: "Philippine Christian University" },
    { id: "b00d4966-b96b-4005-89fb-76fd9b2422a0", name: "Philipps University of Marburg" },
    { id: "688ddd6c-b762-4062-aa55-09d9f5e91668", name: "Phillips Academy" },
    { id: "57a1547e-b185-4eab-9835-54c17920f121", name: "Phoenix College" },
    { id: "766a7238-4aac-4ef3-a527-cb179bf90c1c", name: "PICSIE Associates" },
    { id: "1d3d7a59-6923-42c4-8eb4-f92b14013f40", name: "PICT School of Technology and Management" },
    { id: "ee8eb035-a8ba-4347-831a-66cada588b44", name: "PIEAS - Pakistan Institute of Engineering & Applied Sciences" },
    { id: "d30584a7-0f5e-43c1-9209-407891db995d", name: "Piedmont College" },
    { id: "97cc3b30-2327-4aef-b76d-7e5bf960a8fa", name: "Piedmont Virginia Community College" },
    { id: "24836d54-d6ff-4790-a02e-4ae08d2ac4b3", name: "Pierce University" },
    { id: "02db669d-2c2c-406e-bab8-d549e4b05cec", name: "Pikes Peak Community College" },
    { id: "8f4d757b-0a4a-4365-9d6e-fda3510c45d6", name: "Pilbara Institute" },
    { id: "920301ed-b77a-4878-a066-d3b81f8b9837", name: "Pillai Institute of Management Studies and Research PiMSR" },
    { id: "ecbe8166-6d07-4053-a35c-b1ecb11289b8", name: "PIM & CEMS Student and Alumni Club Cologne e.V." },
    { id: "a544dac6-b181-4d9d-84a1-eda12d762e88", name: "Pima Bookstore Northwest" },
    { id: "8c13dd58-dc69-4ea5-9b33-e0dcce669ff7", name: "Pima Community College" },
    { id: "7fa3e627-cd84-49eb-99fd-2343d2cfd00e", name: "Pinchot University" },
    { id: "41e95f2e-6af1-4ecc-ab69-84edddf1ed70", name: "Pine Lake Country Club" },
    { id: "94b59347-8bdd-4bde-9435-a5f4bcfcf14f", name: "Pine Manor College" },
    { id: "3fb03bef-52d2-47e2-a352-56036d00c5e1", name: "Pip Printing" },
    { id: "fc39336a-340d-4381-9c2d-c5cf22d3d0d3", name: "Pitman Training Institute" },
    { id: "597b3f0f-ae8e-4f11-8024-05204115121c", name: "Pittsburg State University" },
    { id: "43470e7b-6231-414b-8181-100884d83c4a", name: "Pittsburgh Technical College" },
    { id: "c4791ccd-76b3-484f-9b25-3f61aca2230a", name: "Pitzer College" },
    { id: "bb5259b1-136e-4964-8303-4db32fb41727", name: "Plan Strategic Ltd" },
    { id: "a5688aef-0522-401f-8be5-c542b89c38e2", name: "Plantijn Hogeschool" },
    { id: "014a54db-a550-42a7-90f4-83eea84149aa", name: "Plato College of Higher Education" },
    { id: "e6a7f5ad-fbfe-4838-ac75-c3d2ddbb6316", name: "Platt College" },
    { id: "279e4b78-ed0e-4c1b-9103-6bbe7083f725", name: "Plekhanov Russian Academy of Economics" },
    { id: "fa079ba8-38da-4cba-814f-04b75024a97f", name: "Plekhanov Russian University of Economics" },
    { id: "1e3d9cd3-e455-49e0-a2db-477e0073d9d4", name: "Plymouth State University" },
    { id: "d839c083-bf93-4691-8f18-f59b8c7b5ba9", name: "Point Loma Nazarene University" },
    { id: "89be4fd5-970f-407f-a107-db709cc5f76a", name: "Point Park University" },
    { id: "29f23a65-44c8-4bad-9e4b-d1144ed6167f", name: "Pokhara University" },
    { id: "f357c84b-49b2-4c1c-bf6e-78a72bbd6e15", name: "Polis University" },
    { id: "6559cd1e-8807-4637-9440-ca6e742556c0", name: "Polish Open University" },
    { id: "f4cb13bf-47bf-46ff-a25e-ce0ea7911de5", name: "Politechnika Lubelska" },
    { id: "4cac6005-2d68-44c8-9fd6-e21368767fc2", name: "Politechnika Warszawska" },
    { id: "4131109b-89c7-4e8e-aef6-15e19dacd7a1", name: "Politechnika Wroc?awska" },
    { id: "ff4d959f-93a3-4f47-a364-546e100c8a39", name: "Politecnico Di Torino" },
    { id: "57a41af7-98b8-4731-9116-37e8454e3925", name: "Politecnico do Porto" },
    { id: "e9fd7a32-6a92-41f4-badf-ef5efd8ea670", name: "Politehnica University of Timișoara" },
    { id: "95ebbcf1-8663-48ad-8d23-3eababb495dd", name: "Politeknik Kota Kinabalu" },
    { id: "8bf4b262-0212-48c6-9ab8-3d44426391b4", name: "Politécnico de Guanajuato" },
    { id: "12b84cc6-958a-46c9-8fbe-15fee9432ae5", name: "Polk State College" },
    { id: "0fdb370a-241a-4391-8e0e-5e24f1e425a4", name: "Polsko-Japo?ska Wy?sza Szko?a Technik Komputerowych" },
    { id: "41bf8789-3b7d-47f6-9ce0-144e000e940a", name: "Polytechnic Institute Australia" },
    { id: "61656f2b-3da3-4901-8bad-7563477de234", name: "Polytechnic of Namibia" },
    { id: "f705627d-faef-4007-89d2-c490a42353a9", name: "Polytechnic of Zagreb" },
    { id: "0169be25-7aa7-4632-b4b5-78e4b2898cfc", name: "Polytechnic University" },
    { id: "13a27569-0781-4161-b72d-0d4459f8ffad", name: "Polytechnic University of Bari" },
    { id: "a878cb9e-c8c8-4bb2-9395-2343a571914c", name: "Polytechnic University of TimiÅŸoara" },
    { id: "dff8151e-7f7d-4701-80c5-b5e744b36207", name: "Polytechnique Montreal" },
    { id: "dec052dd-be8e-4b1d-9084-54f0f4ad8e25", name: "Pomona College" },
    { id: "f339cdcd-91da-44cb-882b-7cab29674f43", name: "Pondicherry University" },
    { id: "a325e708-f5ea-4873-9ca4-b978e122eb73", name: "Pontifical Lateran University (Pontificia Università Lateranense)" },
    { id: "ffcc8689-7fb6-47f1-8271-a32566f89814", name: "Pontifical University of the Holy Cross" },
    { id: "7be4af9b-b5c9-428b-a5e0-134b6af453ea", name: "Pontificia Universidad Catolica Argentina" },
    { id: "a61f3f6e-09d6-4806-b754-2ea5a24ca6de", name: "Pontificia Universidad Catolica Chile (PUC-Chile)" },
    { id: "a8b113b5-efa0-406a-a592-1ad87a49669a", name: "Pontificia Universidad Catolica de Puerto Rico" },
    { id: "8b31af9b-b356-461a-af58-27d77d51adbf", name: "Pontificia Universidad Catolica de Valparaiso" },
    { id: "9712be86-b442-4c99-9903-a2c3af7dcef8", name: "Pontificia Universidad Catolica del Ecuador" },
    { id: "a14fadfa-77f0-4ae9-9c82-10bb69ea1429", name: "Pontificia Universidad Catolica del Peru (PUCP)" },
    { id: "e1684cc4-c288-4d3c-b627-5544028b6610", name: "Pontificia Universidad Catolica Madre y Maestra" },
    { id: "4a1a1ac5-ac08-4b73-b7cd-640db410d31e", name: "Pontificia Universidad Javeriana" },
    { id: "f65573b6-01f5-4b8e-b868-e1ba9c634c31", name: "Pontificia Universidad Javeriana Cali" },
    { id: "4a00cd00-d5b1-4d46-8264-a438f3a83fc1", name: "Pontificia Universidade Catolica de Campinas" },
    { id: "fd326902-5052-468e-9c85-f4108b377523", name: "Pontificia Universidade Catolica de Goias" },
    { id: "dd3b7546-3f1f-4af4-9419-288d8f6d0376", name: "Pontificia Universidade Catolica de Minas Gerais" },
    { id: "269d858f-1e57-4299-91f4-152a7a52da3d", name: "Pontificia Universidade Catolica de Sao Paulo" },
    { id: "a269e4cf-91da-4793-9082-67d8920deb97", name: "Pontificia Universidade Catolica do Parana" },
    { id: "886140bf-0c13-4363-b672-ee57e721e4eb", name: "Pontificia Universidade Catolica Do Rio Grande Do Sul" },
    { id: "9215843d-66a3-4705-880d-6e887996660c", name: "Porterville College" },
    { id: "5d1b94d9-d692-481c-bc53-4400f599537d", name: "Portland Community College" },
    { id: "ed255ed4-7d93-4719-acf9-57f9529c6a62", name: "Portland State University" },
    { id: "42231851-1076-41af-86c1-2e1d0404578f", name: "Porto Business School" },
    { id: "4d49ddfa-6506-4cda-9a66-2d5b75cd7cf4", name: "Posman Collegiate Stores" },
    { id: "14656910-6d4c-4fe9-9468-158216a50796", name: "Post Graduate Diploma In Business Analytics (PGDBA)" },
    { id: "8204519d-7edb-4c14-93db-ad4ffc5b1c26", name: "Postech - Pohang University of Science and Technology" },
    { id: "844545dc-c5c3-458c-91be-cc650a721298", name: "Potomac College" },
    { id: "a7b4acc1-2899-4f7c-88be-5e283e8380e9", name: "Pottstown School District" },
    { id: "dd5a9d9f-f37f-4f7f-9c3c-de9630d9c87f", name: "Powereducation, S.L." },
    { id: "bd4bb192-a8e7-491f-b2aa-1de3a0c44ba1", name: "Poznan University of Economics" },
    { id: "e71b932d-68e8-4fc0-a1ce-81901de42d08", name: "PPA - Pole Paris Alternance" },
    { id: "eaf1a50e-c9c0-417a-a9ba-78c4064002de", name: "PPM Institute of Management" },
    { id: "83af0ac6-25c9-473c-a336-316192265d07", name: "Pragati Mahavidyalaya PG College" },
    { id: "7b998765-022f-4ade-839c-62880bfc1e80", name: "Prague College" },
    { id: "844f8d24-6fed-4ed3-9ba4-8f75d615b14b", name: "Prairie State College" },
    { id: "c6269562-9ee3-4194-b821-cce4d561036d", name: "Prairie View A&M University" },
    { id: "4481154b-ce6e-48ae-acee-457a4c0c9d57", name: "Pranveer Singh Institute of Technology" },
    { id: "edc5997f-2809-4748-881c-ea724986fe48", name: "Prasetiya Mulya Business School" },
    { id: "0ee2c443-dc7d-4948-8e85-075f92bf9c16", name: "Pratt Community College" },
    { id: "f5633ea5-d16f-47d8-a906-43575699b454", name: "Pratt Institute" },
    { id: "288a03b8-d7b2-4a1b-98a3-e59c36a858f5", name: "Praxis Business School" },
    { id: "368701d4-2a57-498d-976c-67b93a008176", name: "Presbyterian College" },
    { id: "f63998ed-f901-498c-ba8e-7816474769cc", name: "President and Fellows of Middlebury College" },
    { id: "81eb98e0-3313-4980-a244-2580035b4c2d", name: "President College" },
    { id: "551e1c5b-b1b1-495a-85fc-b3dfe0c0eeeb", name: "Presidio School of Management" },
    { id: "fee9d7a4-ad5b-4f0d-b989-5edaa1be6e06", name: "Prestige Institute of Global Management, Indore" },
    { id: "b2fe1c55-2f7b-4bfa-aa19-6b8d86fcdff7", name: "Prestige Institute of Management and Research, Indore" },
    { id: "165a8b74-7504-4f0d-bfe1-5ae647e3c026", name: "Prestige University" },
    { id: "cb6495fa-3acb-4196-80be-94f332b212bf", name: "Preston University Ajman" },
    { id: "17bfff50-d6e2-45c2-8eb0-83ac73966a63", name: "Price College of Business" },
    { id: "4eb2a5ca-9816-40fa-8201-bab2ee79e086", name: "Primeiro Escola De Negocios Ltda" },
    { id: "f27b5ddc-8a28-4243-8427-9a89707a9330", name: "Prince George Community College" },
    { id: "6bb91524-e718-44c1-a2bf-2fdc24e0e114", name: "Prince George's County Public Schools" },
    { id: "77a35df5-a20a-4ad8-967a-c487772e7e8b", name: "Prince Mohammad Bin Fahd University" },
    { id: "714c9d0a-a55c-4647-848a-69504487f8bf", name: "Prince Mohammad Bin Salman College of Business and Entrepreneurship (MBSC)" },
    { id: "934fa8b0-af08-4ace-aa83-5543f8fa1b34", name: "Prince of Songkla University" },
    { id: "a0f2737e-c88a-48fb-84ea-7b80934282f2", name: "Prince Sultan University" },
    { id: "51c5f747-b3c7-4cb3-878c-d2759c97ffaf", name: "Princess Nourah Bint Abdulrahman University" },
    { id: "f645c00c-c5d5-4176-9e27-c4c552d4b983", name: "Princess Sumaya University for Technology" },
    { id: "8264ca65-61b7-4c75-a565-c6e3b60d509f", name: "Princeton Theological Seminary" },
    { id: "544582ad-9206-4322-8aa6-0d3ef1178e85", name: "Princeton University" },
    { id: "958fe483-52f2-48ec-b878-090526bff071", name: "Principia College" },
    { id: "e447321a-3da3-4d92-b315-8697dca66585", name: "PRIST University" },
    { id: "d3b53f33-6c41-4348-ba73-1abbe4ce3fcd", name: "Private Hochschule Wirtschaft Phw" },
    { id: "a2de23be-073e-4951-9b00-a34a52e7f01f", name: "Private University of Applied Sciences" },
    { id: "26fa585d-28b9-4639-a719-9df742be087a", name: "Private Universität für Gesundheitswissenschaften, Medizinische Informatik und Technik Tirol" },
    { id: "1e27286d-aaf9-40ad-9b44-c6428fb19cfc", name: "Privatuniversitat Schloss Seeburg" },
    { id: "c142929e-dbbd-4808-b33d-4ed9a39c77c4", name: "Procter & Gamble, UK" },
    { id: "7f941662-62d8-4304-896a-aab171772f9e", name: "Proctor Academy" },
    { id: "8fb04885-9932-4ea2-86e4-56d5359990d8", name: "Product Leadership, Bengaluru (IoPL)" },
    { id: "3af030df-9189-40fe-a4ac-8727ef123c8e", name: "Prospero's Books Budapest Ltd." },
    { id: "716dcef8-02cf-4b14-824e-7b93b9dd7245", name: "PROTON Business School" },
    { id: "94b143f1-47b5-45c1-b2a4-baa1cac1a440", name: "Provadis School of International Management & Technology" },
    { id: "e57d526e-1911-4184-8d99-3fa5b93e048a", name: "Providence Christian College" },
    { id: "ec03faab-6a2a-4aa0-8aa3-56437675be3e", name: "Providence College" },
    { id: "e49459dc-f7d7-48ff-b804-d9d016925c63", name: "Providence University - Luking Library" },
    { id: "8530e695-ab13-4f9c-941f-1d7589474eaa", name: "Providence University College" },
    { id: "3048cfad-8d74-445f-8ed6-292efb836870", name: "Providence University Foreign Language Center" },
    { id: "fc2875b9-8f91-4348-bf7f-c3c9a1958697", name: "Provinciale Hogeschool Limburg" },
    { id: "b72a1739-614a-429e-8a64-2f96448efa84", name: "PSB Academy" },
    { id: "87430089-446a-4d84-969e-bf93da866e3e", name: "PSB Paris School of Business" },
    { id: "adef7e24-ebc8-46ad-97e7-f35ce2af6f44", name: "PSG College of Arts and Science" },
    { id: "2f9e0973-8a3c-4c17-92b3-1f86f49019c7", name: "PSG Institute of Management (PSGIM)" },
    { id: "b1d96257-23ba-41d9-bd92-da9f7a8095ca", name: "PSIT College of Higher Education" },
    { id: "fcceee40-218b-4925-b060-76a61a1d8da6", name: "PTVA's Institute of Management, Mumbai" },
    { id: "d3462972-30b8-4070-bf3a-a45bfb88bd6f", name: "Public Education Leadership Project" },
    { id: "a54c5c62-7b6f-4b64-a46b-f9267229a818", name: "Public Service" },
    { id: "55535c26-811a-4b4d-8d82-7e25c9c3e70e", name: "Public University of Navarra" },
    { id: "3b7b3ab1-28b0-4dd6-8e24-db9af2940465", name: "Publigroup Sa" },
    { id: "b19d9017-ab67-4940-9bfe-fd4d4b9dbd29", name: "PUC - Campinas" },
    { id: "a4fdedec-d47c-4bc3-a952-7a2eb71d95ea", name: "PUC Chile - Ingenieria" },
    { id: "582da096-2df9-4d66-bbf8-ab3a49f20c54", name: "PUC Rio" },
    { id: "a86e79a0-f892-401d-9152-bddcf3d229ce", name: "Pueblo Community College" },
    { id: "22c930ad-27c0-487d-9d52-5ee9a1ca6194", name: "Pukyong National University" },
    { id: "9f6e8afb-b45b-4169-8ddd-e1c8b9413a15", name: "Pulaski Technical College" },
    { id: "dd0c3ce5-e9b9-45a6-9e3e-d0601360663d", name: "Pune Institute of Business Management (PIBM)" },
    { id: "2e6979bf-5d10-4394-bb34-0db5eafc0a09", name: "Pune Institute of Computer Technology" },
    { id: "824fe282-c968-4d2d-8a4b-47d9e9d6082d", name: "Punjab Agricultural University" },
    { id: "4b1116e5-202b-4070-89ac-6dc32451097e", name: "Punjab Technical University" },
    { id: "08f05150-ae6a-4a60-89bf-d714bee6ba38", name: "Punjabi University, Patiala" },
    { id: "fe11e269-ffa3-4c17-9620-80232334697f", name: "Purbanchal University" },
    { id: "aab22305-221b-4fe6-b525-e4da29391574", name: "Purdue - School of Hospitality and Tourism Management" },
    { id: "925cc801-4c88-4d5e-a7fa-83d8ebd5c224", name: "Purdue University" },
    { id: "a65a57fb-0f9a-4c00-b564-bf449f3a25e9", name: "Purdue University - Calumet" },
    { id: "08c58d3c-8146-4005-a033-b233f9a41e2d", name: "Purdue University Global" },
    { id: "0f87ba98-c066-49a5-b38d-442b2c1baf8f", name: "Purdue University North Central" },
    { id: "f4d7ef44-4283-4305-ad47-64ea7895ae6e", name: "Purdue University Northwest" },
    { id: "ca909f49-84c0-4251-bc6e-518ce6338268", name: "Pusan National University" },
    { id: "b7785379-e649-43d8-87e8-dddc7a6f366e", name: "Putra Business School" },
    { id: "d06df542-7764-45f2-a43d-7db2062fbb97", name: "Pvi Ghana Ltd" },
    { id: "f1a5cf06-e715-494c-85da-d18c57f11cd7", name: "Pyongyang University of Science and Technology" },
    { id: "7cfaba0e-4f63-4539-acce-2ff60c18bee1", name: "Pädagogische Hochschule Steiermark" },
    { id: "b7f0c1ce-f751-4aa4-a1c6-ac4741aa41f8", name: "Qassim University" },
    { id: "2b68d45f-2668-4587-beae-254aaac6c377", name: "Qatar Foundation" },
    { id: "caabd6ab-5473-4215-a919-1fb6c6035d16", name: "Qatar University" },
    { id: "fe90f7fa-5913-45b1-9f23-cf79d50860fa", name: "Qingdao University" },
    { id: "3309bb70-26fb-4273-8b61-2e5cb9ec2239", name: "Qinghai Minzu University" },
    { id: "a7f57461-3d82-4a53-81ae-45c8bf879b48", name: "QLU - Quality Leadership University" },
    { id: "4b256cb2-addc-4170-803d-d6bded278c09", name: "Quadriga University Berlin" },
    { id: "d5778850-872f-4670-a601-5e25099cc417", name: "Quaid-i-Azam University" },
    { id: "e189b4ef-5b16-4f28-bf2e-136360f6bb84", name: "Quan Wai E-school" },
    { id: "f03fbee6-a9b3-446f-8c2a-3c6ff94a386c", name: "Queen Margaret University" },
    { id: "29784bc4-084c-4f8e-947d-6394943e4d49", name: "Queen Mary - University of London" },
    { id: "2fb4fee5-dd98-4a30-9e23-e6dbcb7956f2", name: "Queen's University - Canada" },
    { id: "dacc6f93-ba54-4a5b-8ef6-e5255cf919fe", name: "Queen's University of Belfast" },
    { id: "3003d41a-1733-422b-a8d7-b27bda542acb", name: "Queens University of Charlotte" },
    { id: "13105280-7789-4206-b4f1-bcc17353b886", name: "Queensland University of Technology" },
    { id: "b2c38286-69ee-4e44-a0cd-cb2b4db8786c", name: "Quest International University" },
    { id: "012a9404-2b16-4525-8d4c-5d75df198538", name: "Quest University Canada" },
    { id: "557c2a7f-0866-4ade-926f-d54472f627c6", name: "Quincy College" },
    { id: "f0577fe2-43bf-4d2f-8474-240d1b38bdfc", name: "Quincy University" },
    { id: "9eba5004-2f28-4bc7-afa2-3c75e6c719de", name: "Quinnipiac University" },
    { id: "741691a6-e539-4678-8059-e09e297d4054", name: "Quinsigamond Community College" },
    { id: "f078399c-977a-4d38-9dcf-038d25d318bd", name: "Qujing Normal University" },
    { id: "c4b403bd-815f-4ed0-a156-acecc603be5d", name: "R.O.C. Naval Academy" },
    { id: "82864cbb-c0f6-4d5b-8615-29c0be18272e", name: "R.V.Institute of Management" },
    { id: "562418ae-f5ff-4e95-84c2-ce22c847d562", name: "R?gas uz??m?jdarb?bas koleda" },
    { id: "de3bcc31-420d-47e5-8570-0b7cafc011d0", name: "R?zeknes Augstskola" },
    { id: "975c5713-e775-4f5e-9e05-75df589da180", name: "Race Director University" },
    { id: "94cc5ab9-f756-44d5-829f-05fa509b0720", name: "Radboud University Nijmegen" },
    { id: "d9f09dcb-0035-49e2-9b4f-5ee947e979e7", name: "Radford University" },
    { id: "44f5fe8c-304e-416f-9dc3-744e5330d721", name: "Raffles College of Design and Commerce" },
    { id: "5484d014-efba-4610-842d-d188d363c04a", name: "Raffles College of Higher Education" },
    { id: "0c83bffa-1d5f-4ffc-bc9a-00a30834e075", name: "Raffles International College" },
    { id: "f4429154-451e-4141-8757-300f74ea43e8", name: "Raffles University" },
    { id: "cc3f9591-627b-40b9-b8e7-6826d9ffc4f0", name: "Rainy River Community College" },
    { id: "37c6cf17-23a0-4d77-8053-eee85ed10a86", name: "Raj School of Management and Sciences" },
    { id: "7a30581a-3c85-49d3-8c4f-5e1f15aad296", name: "Rajagiri Business School (RBS)" },
    { id: "c25d171f-c7c4-478f-b69f-be56fbcd0277", name: "Rajagiri College of Social Sciences (RCSS)" },
    { id: "ca8debc2-b443-42df-8c69-dea649b1f81d", name: "Rajalakshmi Engineering College" },
    { id: "db58b155-b2d1-4c7b-9320-70139e614d2e", name: "Rajalakshmi School of Business Chennai" },
    { id: "79b2ee61-4688-4060-ab21-18ae87084244", name: "Rajasthan Agricultural University" },
    { id: "cda2d2bd-cc53-4f1b-a356-6a9f51a6c23d", name: "Rajasthan Technical University" },
    { id: "d71fc441-2c74-4f7f-9b4e-d821d3a88443", name: "Rajendra Mishra School of Engineering Entrepreneurship (RMSOEE)" },
    { id: "1484550d-479e-40b5-b4cb-1b2b246afa89", name: "Rajiv Gandhi Institute of Petroleum Technology (RGIPT)" },
    { id: "cebe4559-014c-47e8-9511-2191a76ee8df", name: "Rajiv Gandhi Institute of Technology" },
    { id: "9b8a4858-5748-4ec4-b7a5-b48ad9fefc57", name: "Rajshahi University" },
    { id: "cbe6a078-4e35-4913-b6e9-a359b32dec9b", name: "Ralph C Mahar School District" },
    { id: "7eaaaebc-8aa1-42b9-b7cd-7cdb6bad22ae", name: "Ramapo College of New Jersey" },
    { id: "55fe81ab-9643-45f4-b8ca-5a2a7572f13c", name: "Randolph College" },
    { id: "6cbd92f4-a562-414d-a456-d4374f2504af", name: "Randolph Community College" },
    { id: "299006b3-3b25-486f-b852-dea24ea78ece", name: "Randolph Macon College" },
    { id: "24b6b5dd-d6e6-4e06-95db-e94cd6428abb", name: "RANE - Russian Presidential Academy of National Economy and Public Administration" },
    { id: "86fc2f88-5817-4eff-bd4a-36595c8d6c51", name: "Rangsit University" },
    { id: "642eea36-745d-474b-8529-02b79255aa4f", name: "Rani Channamma University Belagavi" },
    { id: "9a1508f2-538e-4762-ad5a-2a1418835943", name: "Ranken Technical College" },
    { id: "8a0441bd-7f68-434a-8444-2f82bbddaa2d", name: "Rappahannock Community College" },
    { id: "c5cb72f9-fd86-4754-a23b-f174b967cc80", name: "Raritan Valley Community College" },
    { id: "d4d587f1-db96-41a8-81c8-8d54c8e5ee54", name: "Rashtrasant Tukadoji Maharaj Nagpur University" },
    { id: "d79f4f26-601e-45cb-8583-d68c0e6a4f07", name: "Rasmussen - Illinois" },
    { id: "e3b0ac87-bce8-42d6-8032-596bbd842f13", name: "Rasmussen - Minneapolis" },
    { id: "20bc25d9-c9a2-4264-a9da-f0d0ff2e07ac", name: "Rasmussen College - Green Bay" },
    { id: "aa3f1cd9-cc85-447e-87be-974d21ea974e", name: "Rasmussen College - Orlando" },
    { id: "aaa93a23-4033-4e61-85d6-24f5d03824fa", name: "Ravensbourne College of Design and Communication" },
    { id: "91b00447-1265-47a1-9488-6a9410e5a6aa", name: "Rayat London College" },
    { id: "68eae3d4-9e2a-4a1f-84e5-e9bf08452eca", name: "Raymond Levitt" },
    { id: "eb8bbe5d-c715-4156-8627-34ff17a23fbf", name: "Rayner Institute" },
    { id: "7e626292-773a-4bc3-8e04-814234bb487c", name: "RCC Institute of Technology" },
    { id: "1ae12b32-f4c7-41bb-b325-9bbf120db2c2", name: "RCCG Bible College & Seminary" },
    { id: "3065e5a3-1bd6-4708-95ec-fb2bf01f9c01", name: "RCSI Institute of Leadership" },
    { id: "cd6f454c-13dc-4651-bfa1-bea49eb8e828", name: "Reading Area Community College" },
    { id: "21112895-ddc1-492b-9433-baddd02ee65d", name: "Rechts- und Staatswissenschaftliche Fakultät" },
    { id: "7b3cb8b5-9e1b-4b93-bdd0-069bfe4c0b0a", name: "Recruiting.com" },
    { id: "605ab2da-8ddd-4255-a6ec-d9758232ef51", name: "Red Clay School District" },
    { id: "a97dc352-8117-4f9d-80a2-e7d5b8e829f9", name: "Red Rocks Community College" },
    { id: "d6e594f5-1ff0-455e-beb4-bdb4589785f3", name: "Red Tail Books" },
    { id: "e97809c0-ad9c-4402-9155-947a1402479f", name: "Rede Metodista de Educacao do Sol IPA" },
    { id: "94fe0dd8-97a4-41a7-aaf2-ad8881d630b8", name: "Redeemer University College" },
    { id: "4b48a9b2-8f25-401b-83d2-9515fdbc2117", name: "Redlands Community College" },
    { id: "3be5b72d-77fd-46b2-89b6-d28199d8393b", name: "Reed College" },
    { id: "35788ae5-4dda-4d8f-ac3f-2dec113dcd8e", name: "Reedley College" },
    { id: "82baa896-cc26-4da3-8220-366db24064f7", name: "Regenesys Business School" },
    { id: "95f2e6a7-25e2-4dcb-b5e8-1e786c1d5143", name: "Regensburg University" },
    { id: "13c8f9be-8655-4a5d-be74-cfc8646a0901", name: "Regent Business School" },
    { id: "a38c4d20-0e1f-4ceb-8af8-8ea4af0ff910", name: "Regent College" },
    { id: "b4288176-b6b3-40d3-b688-075d5f589d50", name: "Regent University" },
    { id: "af6a2767-8f45-4ca2-952a-085433614582", name: "Regents University London" },
    { id: "89ea638c-90ab-4a16-8fe6-4b2e2b09efe6", name: "Regional College of Management RCM" },
    { id: "1f1bb87e-49d2-4892-8957-827e791b1e13", name: "Regional IT Institute - Maastricht School of Management" },
    { id: "5475f88e-19a8-4a29-821e-fb4cc6dc0f88", name: "Regis College" },
    { id: "3304d3b5-d1a6-44b7-ad6d-6f30aea1fa98", name: "Regis University" },
    { id: "52278a88-470d-4981-8ae0-372c2cbd69da", name: "Regis University Bookstore" },
    { id: "805d6ab9-d260-4f1d-88c2-ca5c7846f664", name: "Reichman University" },
    { id: "7ab231aa-392f-454e-99c4-f62f06081b87", name: "Reims Management School" },
    { id: "a0009310-7801-49d8-8d37-1c94a3278055", name: "Reinhardt University" },
    { id: "14a286d5-e5ad-45f4-9bdf-adedf9b9565b", name: "Rekha Book Distributors" },
    { id: "17a8a930-23d7-4907-ac02-39019b50865a", name: "Remington College" },
    { id: "4e495dca-a53d-40d0-92ce-8d7420b87f9a", name: "Rend Lake College" },
    { id: "765805f2-23bd-45e5-91b9-410113f66f98", name: "Renmin University of China" },
    { id: "3c3ac600-4bac-4ec8-b84e-5cda21cadf8f", name: "Rennes 2 University" },
    { id: "efd50abf-ce94-40f9-8218-e5e79bf2769d", name: "Rennes School of Business" },
    { id: "d6309616-bf93-4307-9062-0f22a5634b9e", name: "Rensselaer Polytechnic Institute" },
    { id: "242f02fc-7705-4282-b5cf-cfc4a4d169fd", name: "Rensselaer Polytechnic Institute - Hartford" },
    { id: "39221d2d-603c-43f3-a0e0-0d124b519fbb", name: "Rensselaer Polytechnic Institute - Troy" },
    { id: "2717efe2-3c9e-42ad-b37e-6237662ed34c", name: "Renton Technical College" },
    { id: "98d6d419-f283-4bc3-8e85-94dc66655c3f", name: "Rep/Buyer Comp Copies" },
    { id: "25300d7d-81d5-40bf-b9ac-c55991baab21", name: "Republic Polytechnic School of Engineering" },
    { id: "112ee75c-f9f9-4670-93c7-4d4732e4c9f9", name: "Research College of Nursing" },
    { id: "2a9e1d0a-623b-4486-a7c9-f5adf4710f90", name: "Research Foundation of S.U.N.Y." },
    { id: "b4b4b129-d6be-44b6-bf91-68256cc68d3e", name: "Research Institute of Economics and Management" },
    { id: "e93cc1c6-c73c-49b2-afa0-e74469342bbd", name: "Researchco Book Centre" },
    { id: "a7b74dbe-5e2a-425b-ad65-8915e9e35729", name: "Reseau Ges" },
    { id: "55dce0be-80bb-48e5-937c-f1cf33282e16", name: "Reutlingen University" },
    { id: "20fdb582-6e91-46d2-89b9-0f6e5032bb2d", name: "REVA University" },
    { id: "af4ecec0-6035-42fd-9032-a85071469f5d", name: "Revans (Shanghai) Education Technology Co." },
    { id: "add87549-0d71-413a-bf38-a97e9fee0903", name: "Revel Academy" },
    { id: "18a4d6ec-07ed-4956-bf15-6417c3be4981", name: "Reykjavik University" },
    { id: "138e9fb6-deb6-4631-a98e-37cd2a787d80", name: "Reynolds Community College" },
    { id: "9dcf3ff1-7818-4a02-9ba2-3c14363457f8", name: "Rhein-Waal University" },
    { id: "7db5159b-7595-49b4-b667-9bc5d4efc725", name: "Rheinische Fachhochschule Köln" },
    { id: "ec72b09f-413f-42be-b690-85cb73a1321e", name: "Rhema University" },
    { id: "7cbb6020-cde7-4747-8133-1c4444be6abf", name: "Rhode Island College" },
    { id: "b7244d63-5af5-4756-8f23-87101a693385", name: "Rhode Island School of Design" },
    { id: "cf310f4f-8dd3-4d54-8d02-988aafebb412", name: "Rhodes College" },
    { id: "ca6231ed-0df6-4c52-b1ab-ea2fefc6f69d", name: "Rhodes State College" },
    { id: "454d30a7-040e-469c-aecf-ed3df6b63c0e", name: "Rhodes University" },
    { id: "5dc215a5-0496-4ecc-8747-92a8467267bf", name: "Rice University" },
    { id: "48b29de9-570b-42ae-bcda-bc84f3915010", name: "Rice University Bookstore - Barnes & Noble" },
    { id: "18864cba-b377-4e86-bb75-42c375599a46", name: "Richard Stockton College" },
    { id: "67c5c75d-f969-4f56-aa91-ea9af4754fbb", name: "Richfield Graduate Institute of Technology" },
    { id: "55723246-5135-4ae2-8491-f4e4cd283759", name: "Richland College" },
    { id: "8215aa6f-3f42-46e3-b3e0-aae34ba6d252", name: "Richland Community College" },
    { id: "f9a1085d-d754-446c-9286-a90964e73862", name: "Richland School Distrtict" },
    { id: "ba440ca0-58d2-4fa1-9cf8-49c797eab292", name: "Richmond American International University - London" },
    { id: "61dc2d15-0dd4-4e87-82aa-5fc28be9233d", name: "Rider University" },
    { id: "64f2f457-8286-4c7a-8ef9-42c5c823cbc3", name: "Riga Graduate School of Law" },
    { id: "bb89108d-7f0d-42d2-8c36-f37c51d09a04", name: "Riga International School of Economics & Business Administration" },
    { id: "a3bb8969-9dcf-46c6-a0eb-e0b50ab30205", name: "Riga Teacher Training and Educational Management Academy" },
    { id: "ee3d47e4-6419-44ec-b522-fe62af1fbbf4", name: "Riga Technical University" },
    { id: "95d3fcf0-e29b-4586-bfd1-238524085d16", name: "Rightsholder Customer Relations" },
    { id: "bf571f57-e413-49f7-a402-bc6f9cd53564", name: "Rikkyo University" },
    { id: "d608fed7-1e1d-413d-a063-5802abe449b0", name: "Ringling School of Art and Design" },
    { id: "a6836007-88a2-4e07-828c-f330910040e1", name: "Rio Salado College" },
    { id: "365c807f-32a3-4dbf-ab0c-2e38b744460d", name: "Riphah International University" },
    { id: "f3a944cf-009e-40d6-a140-a4e7a3f7bc2b", name: "Ripon College" },
    { id: "1fb04f24-8e06-45c6-9bb5-cd312e5c1ecc", name: "RISEBA University" },
    { id: "8973ebf6-7683-4a6a-87a7-60d31f64d944", name: "Rissho University" },
    { id: "94e5ed2f-507c-4634-89a4-4d01e8cc4782", name: "Ritsumeikan Asia Pacific University" },
    { id: "69ad80be-848a-4f42-8895-96ab6eb73d68", name: "Ritsumeikan University" },
    { id: "1b091ef5-1b0c-4434-99c9-bccaa3ec3265", name: "River Valley Community College" },
    { id: "ef6e9dde-6b27-455f-b087-1d05ccd637d8", name: "Riverside Community College" },
    { id: "e8f0b377-1646-4264-80f6-ee8a11f0bae1", name: "Rizal Technological University" },
    { id: "a46a1e9a-7b98-48bb-bee0-6cca7e695150", name: "Rizvi Institute of Management Studies and Research" },
    { id: "863abdef-6038-4c3d-a1b0-dfef8d88b4ee", name: "RK Business School" },
    { id: "b99451d6-bf67-4e82-9879-51de79d34e6b", name: "RK University" },
    { id: "ff586bfb-fe45-4ae0-874a-58f3b7d05e90", name: "RMIT University" },
    { id: "82818962-f7c2-4d8b-8296-4cd2b7a47bf1", name: "Roane State Community College" },
    { id: "8d4336ac-97b2-4f17-88f4-dc6ba8ff4a1a", name: "Roanoke College" },
    { id: "ab4f8d8f-0cc8-4381-862d-24d3a8e8d4b1", name: "Robert Gordon University" },
    { id: "620bfc09-24bf-4ab5-8f87-3a3c0bcf911d", name: "Robert Kennedy College" },
    { id: "d29971fe-1978-4e7c-a61b-4414ed683627", name: "Robert Morris University - Illinois" },
    { id: "28d0db32-f4cf-4df6-be9c-1d185c64fe26", name: "Robert Morris University - Pennsylvania" },
    { id: "49410a09-4b4b-4561-a291-e34b668e1876", name: "Roberts Wesleyan College" },
    { id: "21dc3dbd-6c6c-40ce-966c-e255a431058c", name: "Roberts Wesleyan College Bookstore" },
    { id: "06ffde09-ce1a-42c6-be4c-2c54c7f3d084", name: "Robertson College" },
    { id: "de4bf79f-007f-4c03-a946-5c6014d421eb", name: "Robeson Community College" },
    { id: "4e868d4f-0438-4d66-bc87-61f5eb773a65", name: "Rochat School of Business" },
    { id: "f4d70a38-ccf4-4e68-bfe4-e419bb3bd4c9", name: "Rochester College" },
    { id: "bf34ea3b-5733-4863-9215-454918e12d01", name: "Rochester Comm & Tech College" },
    { id: "08a95f79-7213-4894-beed-82762693ed4d", name: "Rochester Institute of Technology" },
    { id: "07fc62ac-446b-4034-9a3f-d283b07cf87a", name: "Rochester Institute of Technology Croatia - Zagreb" },
    { id: "424940b3-04aa-4808-aaa0-faac982b7f19", name: "Rochester-Bern Executive MBA" },
    { id: "39a611d3-46c7-420a-ae44-e564546332c0", name: "Rock Valley College" },
    { id: "97d4eee8-e900-41e5-8388-160173100cb5", name: "Rockfield College of Management" },
    { id: "8ef5a427-a08b-4ba5-9fa8-3fba9a62fde8", name: "Rockford University" },
    { id: "b46b574b-38b2-4e0f-a98c-0ad8a8e7d533", name: "Rockhurst University" },
    { id: "6dc6b69c-97a2-4866-903f-7bc94027b61c", name: "Rockland Community College" },
    { id: "5ab7cda1-9ff5-4b40-bb18-087370c50fca", name: "Rocky Mountain College" },
    { id: "efcb50ae-66a8-4cd6-8d9e-2bed0e040936", name: "Rocky Mountain University of Health Professionals" },
    { id: "b2850ea7-0ad3-409c-99b4-2eeb22476c2e", name: "Rocky Mountain University of Health Professions" },
    { id: "4bc51861-b990-4be9-aa1d-90e004f566a1", name: "Roehampton University of Surrey" },
    { id: "1979c3fe-ef60-4c94-87e4-a9565ede66c9", name: "Roger Williams University" },
    { id: "0b407bbf-b421-4238-879f-d242ea315b16", name: "Rogers State University" },
    { id: "64f51e53-0ce4-459c-a3ec-84db3c4d1b47", name: "Rogue Community College" },
    { id: "0a0db2e7-40e7-4149-9921-bc6d4b94d330", name: "Roland Berger Strategy Consultants Gmbh" },
    { id: "10012952-96a1-4b6f-abb5-76de98eeaea1", name: "Rollins College" },
    { id: "71702eda-7b39-4b11-b839-3dbcf934f12c", name: "Roma Tre University" },
    { id: "de1c9dea-d5c6-43eb-b2e2-ed1ba4c67232", name: "Romanian-American University" },
    { id: "e95e64c0-4ab7-48ad-b2c3-621761eb99e8", name: "Roosevelt High School" },
    { id: "90176b17-9552-4bb9-b0fe-0b0310861f4c", name: "Roosevelt University" },
    { id: "728363f7-73de-4d91-be46-ecbe851cb1c9", name: "Rosalind Franklin University" },
    { id: "963f36eb-e245-41d0-bd61-3f51a3299d9e", name: "Rose Bruford College" },
    { id: "d2cfab3e-589b-4884-8429-e83e257dfdac", name: "Rose State College" },
    { id: "902e70b7-9a4d-49ff-89f2-90c2d45831a8", name: "Rose-Hulman Institute of Technology" },
    { id: "7dd4310c-1c8d-4297-8f31-2ad1e803d9e8", name: "Roseman University Health Sciences" },
    { id: "09c2a46f-292c-4540-a3e0-9b26c2a79d1a", name: "Rosemont College" },
    { id: "a964e54b-5ade-4d41-8afd-cc337f0bc3a4", name: "Rosemont College of the Holy Child Jesus" },
    { id: "3490a747-ca39-44a1-9361-631ee26ef742", name: "Roskilde University" },
    { id: "ac33cf74-98b9-488d-85b3-b6adf624cb48", name: "Rossier School of Education" },
    { id: "601878db-82dc-425b-80bf-63d4a93957f8", name: "Rostov State University" },
    { id: "9828e11a-d4c8-4bd8-a1c0-017fffe28485", name: "Round Lake School District" },
    { id: "1a85b036-4652-46fb-9543-e50504af7395", name: "Rovira i Virgili University" },
    { id: "380fcff8-8fb6-44ba-bd04-0cd54ab66797", name: "Rowan College of South Jersey" },
    { id: "cfe57ee2-c1dc-4235-b778-53178d4a6cb2", name: "Rowan University" },
    { id: "2d94abef-acc1-411e-8ce9-e8385361f3bf", name: "Rowan-Cabarrus Community College" },
    { id: "496dd6fd-4fbe-43c4-a7fa-00cf30235219", name: "Roxbury Community College" },
    { id: "223759b7-33c3-4f1b-8acc-e680e1e99f22", name: "Royal Agricultural College" },
    { id: "867ad8e3-784b-4901-931a-e187911b78a1", name: "Royal Canadian Air Force" },
    { id: "e8bd4dc1-ab40-43d0-a423-ce15f88b0fa0", name: "Royal College of Art" },
    { id: "9d90a83a-61ee-45e4-b8d5-798a1311de31", name: "Royal College of Surgeons In Ireland" },
    { id: "52ce8ccc-9edf-481e-9f53-8923fbbff8f8", name: "Royal Conservatoire of Scotland" },
    { id: "e334c1e0-3c7e-4d57-8cce-e87d4ccb9679", name: "Royal DSM N.V." },
    { id: "c8f03bb6-ae2a-4032-a20f-1b6f29ce6b11", name: "Royal Holloway, University of London" },
    { id: "bad9619e-035a-4036-aa37-11585f2d57f2", name: "Royal Institute of Colombo" },
    { id: "a8d8899a-6764-4494-922c-06f78a4270e1", name: "Royal Institute of Management" },
    { id: "a7578435-7201-4468-930e-8928a913b1f0", name: "Royal Institute of Technology" },
    { id: "7968636f-8722-44f9-b821-ab9698bd80a9", name: "Royal Military College of Canada" },
    { id: "866fac25-9c72-4cf6-a317-58ae01036243", name: "Royal Roads University" },
    { id: "b919c930-937d-4ad2-a4d4-cb00db1079d3", name: "Royal St. George's College" },
    { id: "a0056735-1b1c-4914-b999-e0df1769099e", name: "Royal Thimphu College" },
    { id: "84b5ebfd-1d23-49a8-99b7-933ef2790602", name: "Royal University of Bhutan" },
    { id: "a1fff789-fa0b-491a-9896-7b62c291c113", name: "RSUH Instistute for Economics, Management, and Law" },
    { id: "00d0c39a-bab0-4ae3-82dd-d4011b0182c7", name: "RTI International" },
    { id: "8d9074f3-3a48-4b27-9ec0-684d4b959e95", name: "Ruhr University" },
    { id: "f48a7320-ffb8-4987-88c0-a3f3226d2d5f", name: "Rumah Universiti Malaya" },
    { id: "aa7bd097-fe8e-409d-ba26-9a1e89663b05", name: "Ruppin Academic Center" },
    { id: "815b3664-4bf9-4bbf-86af-7de9f64d3329", name: "Rush University" },
    { id: "c84719e7-6fcf-4a45-8132-80dd3643b0b6", name: "Rushford Business School" },
    { id: "98c975ce-2acb-424d-8c08-13610224c938", name: "Ruskin College" },
    { id: "f11315ff-bd5a-46ef-9946-81fe8ac4fab9", name: "Russian School of management" },
    { id: "42e49424-4430-4fd5-897e-83778ed99f0a", name: "Russian State University for the Humanities" },
    { id: "60f5c4b8-c504-4ee0-9dd0-4acc2042e74b", name: "Russo Business School" },
    { id: "515cf6ed-e1b1-4b74-b549-ba91692b1ae0", name: "Rust College" },
    { id: "0a075e3f-6bd9-4036-a96e-476b4ec61775", name: "Rutgers University" },
    { id: "2c9808aa-1171-474a-a119-e661219bacc0", name: "Rutgers University - Camden" },
    { id: "80dae472-7bd2-4c84-a308-1e4d6e16ad46", name: "Rutgers University - Newark" },
    { id: "5a696b82-24d0-412c-bb4e-82f4b7679ad3", name: "RVS College of Arts & Sciences" },
    { id: "a39b918e-2838-4ead-89e7-8b0cd23e6c01", name: "RVS Institute of Management Studies and Research" },
    { id: "2a84d0ac-15e8-40ab-aed0-dd9effb2ec4f", name: "RWTH Aachen University" },
    { id: "88b980b0-c3c7-462c-a254-5b87fcf9043b", name: "Ryokan College" },
    { id: "530e6ab8-eebd-4098-bf1b-a0f6707a56d0", name: "Ryukoku University" },
    { id: "d0c6ffde-06b3-40fc-b941-a14ce8608333", name: "RzeszÃ³w University" },
    { id: "68f04644-b779-4c19-bfde-87b0aec35a26", name: "S Ill Univ Bkstr" },
    { id: "ed81ba92-6eff-4149-a944-0e0ec1c23caa", name: "S P Jain Inst of Mgmt and Res (SPJIMR)" },
    { id: "22835df3-5a02-4972-9db5-9eb6f554fbae", name: "S P Jain School of Global Management - Dubai" },
    { id: "e75cfe5a-f455-4e1e-a9fa-30d3fd33f178", name: "S P Jain School of Global Management - Singapore" },
    { id: "c91fe1a6-f56f-4629-8625-a776cba5f2a5", name: "S P Jain School of Global Management - Sydney" },
    { id: "9e7964a0-7688-4e40-a8bd-9d14668ae136", name: "S. K. Patel Institute of Management & Computer Studies" },
    { id: "79642ddd-6831-42d6-82cb-4262cdc215e4", name: "S.D.S.U. Research Foundation" },
    { id: "3d3673d5-31a6-40be-81de-24bcfed3c147", name: "S.V. Agricultural College" },
    { id: "eb779959-fbe1-4fc6-9499-9fe16ba3b68b", name: "Sabanci University" },
    { id: "aa15fd25-5354-4e82-b041-d5b0518513d0", name: "Saber College" },
    { id: "8e753601-578f-4511-918c-d23c5bf28136", name: "Sabes University" },
    { id: "ef52ad35-8a00-4bff-b2e3-4c2be40064a4", name: "SACI - Florence" },
    { id: "b07f8bee-ae86-462e-abec-93029981aad3", name: "Sacramento City College" },
    { id: "4fc4481a-21d2-4b38-9191-36ccc0824259", name: "Sacramento State University Bookstore" },
    { id: "dfda277b-1bb1-4ff7-89b5-6ad8d5b4d0ba", name: "Sacre Coeur" },
    { id: "a765842d-1c1b-4e3a-bcbb-8e37a7ce1c5a", name: "Sacred Heart Canossian College of Commerce" },
    { id: "408bdc52-ce57-49d5-9aec-861b5d39e392", name: "Sacred Heart University" },
    { id: "0aabdc97-cd65-4506-a7e6-d95fd6aef34b", name: "Sadat Academy for Management Sciences" },
    { id: "a0c8b67b-04dd-4007-8ca0-904279c1dc07", name: "Saddleback College" },
    { id: "81a9272a-8c0d-412a-b833-3bce7599a5e1", name: "Sagami Women's University" },
    { id: "a6df8c47-159c-4c64-b7e2-c7d501dbd87d", name: "Sagardoy Business & Law School" },
    { id: "5e5e4cba-da7a-4a20-bccf-1e78e2acd7b2", name: "Sage College of Albany" },
    { id: "7a4eeee0-a0a3-4967-84f7-72656ac3ad96", name: "Sage Day" },
    { id: "7b08f27f-a9de-450b-b2c0-df36a896610f", name: "Saginaw Area Catholic Schools" },
    { id: "6d1d0850-94a0-4cfc-a3d2-1ccaf1bfa185", name: "Saginaw Intermediate School District" },
    { id: "50e177e6-2167-4db3-8655-3cc6d4f9e1b6", name: "Saginaw Valley State Univ" },
    { id: "59680d5d-2314-43d7-8e57-c64be3cf6fa3", name: "Saginaw Valley State University" },
    { id: "0c288a36-2e67-45fa-a0a9-0095f427cabb", name: "Sahyadri College of Engineering & Management" },
    { id: "4db5b2b8-0adf-45d9-8b63-49751c137575", name: "Said Business School, University of Oxford" },
    { id: "3378d856-eb0f-49ef-8e5e-af83c1e284cf", name: "Saigon Institute Of Technology" },
    { id: "f43ad89c-fcee-4b35-addf-51a61f2dfb9f", name: "SAIID BUSINESS SCHOOL Executive Education" },
    { id: "baadab8e-6a2e-4a10-ac1a-94abc426bd16", name: "SAIM College" },
    { id: "0d6948e8-6fbd-4ace-9033-27d33f37e40d", name: "Saimaa University of Applied Sciences" },
    { id: "fa770095-11b6-4064-9c3c-24198609d603", name: "Sainsbury Management Fellowship" },
    { id: "5f456587-d81e-4fa1-8478-429c397414ec", name: "Saint Ambrose University" },
    { id: "653f9300-8792-4899-bcd2-cf7fe69605bd", name: "Saint Anselm College" },
    { id: "1f9188a4-fadb-47ff-a17f-8a8ede3b7e78", name: "Saint Augustine's University" },
    { id: "070fafc5-7377-43d9-b12a-0a921d8250ad", name: "Saint Bonaventure University" },
    { id: "6402228e-5ce2-4bc9-87cb-96d5613bdab7", name: "Saint Francis University" },
    { id: "e5736b38-3a92-476d-9574-b6aab55d94ee", name: "Saint Francis Xavier University" },
    { id: "30f3ff86-fd98-46b0-8d33-dda66eb272d0", name: "Saint George's University" },
    { id: "e0d378cb-c6d9-410a-98e8-96a78ef9d524", name: "Saint John Fischer College" },
    { id: "346daa3d-6e67-48de-893d-935118c9ad66", name: "Saint John School" },
    { id: "1cc1dcc5-01f4-4bc0-88c0-a869bc80be91", name: "Saint Joseph College - CT" },
    { id: "567435dc-ba88-40a5-b30b-6b584706afdd", name: "Saint Joseph's College" },
    { id: "226fe7c7-12ad-4f1a-a909-b6c1e2de6274", name: "Saint Joseph's University - Philadelphia" },
    { id: "33d18862-c7bb-425f-afbb-297ed3b3e497", name: "Saint Josephs College - Brooklyn" },
    { id: "e805838b-7563-4111-80c4-2d7d39933d4d", name: "Saint Josephs College - Indiana" },
    { id: "a56bb75f-d49c-4871-91c8-8c6839507c09", name: "Saint Josephs College - Long Island" },
    { id: "9650624f-d3f8-4b66-b251-9996d5c36c0d", name: "Saint Josephs College - Maine" },
    { id: "e860192e-49d9-4d23-b2dd-f7cc2a32c23c", name: "Saint Lawrence College" },
    { id: "d0a3fa68-bf66-49c7-aed5-ffd16a1ca2fa", name: "Saint Leo University" },
    { id: "467d32a7-8597-4b94-bd22-aff550e0d83e", name: "Saint Louis College" },
    { id: "a25c1f07-c0ff-4049-a440-5522611a23fa", name: "Saint Louis University" },
    { id: "d33e00c3-522e-408c-8098-d7d034de9bc4", name: "Saint Louis University - Brussels" },
    { id: "e1d91607-bf93-4e6f-aaec-e7e2b5a5213d", name: "Saint Martin's University" },
    { id: "a64f4867-a515-49c2-b711-771e6dd217d7", name: "Saint Mary's University - Canada" },
    { id: "7d2be371-7671-40ed-803d-62989845b82c", name: "Saint Mary's University - Texas" },
    { id: "a1406273-dc4d-4703-bdbf-273e3666e458", name: "Saint Mary's University of Minnesota" },
    { id: "4bc72456-2790-46b9-9210-b21ac84e567c", name: "Saint Mary-of-the-Woods College" },
    { id: "439e0f91-be77-4947-8066-6db933ba561e", name: "Saint Marys College - Indiana" },
    { id: "9a02451c-f083-4a95-abd0-a11ed47bba2f", name: "Saint Marys College of Maryland" },
    { id: "75333e56-7308-4ec1-bb83-65a5371c4462", name: "Saint Michaels College" },
    { id: "cec8b27a-8b31-48a5-8054-6a4529b3179f", name: "Saint Norbert College" },
    { id: "c9524f0c-4863-4aee-b49f-31fd42660cc4", name: "Saint Olaf College" },
    { id: "a4124275-11ce-4aff-a6d0-930b54cad2e7", name: "Saint Paul - Escola de Negocios" },
    { id: "df40b240-aa68-4dfb-8bbf-2129bc799184", name: "Saint Paul University" },
    { id: "610f8b9d-c02f-4195-93e1-b2174b5f48cb", name: "Saint Peter's College" },
    { id: "10426b8b-8222-4c4e-b3c9-f8a7bb30ec0f", name: "Saint Peter's University" },
    { id: "a3656380-4de2-4a77-a8fa-dafcfbd3f4b7", name: "Saint Petersburg College" },
    { id: "09457628-8d02-42eb-929a-33aa355ab877", name: "Saint Petersburg State University" },
    { id: "8389347d-6ae5-496d-9987-2525777ff867", name: "Saint Thomas Aquinas College" },
    { id: "18215f76-2c79-45d6-8573-5667b72c61c8", name: "Saint Thomas University - Florida" },
    { id: "667e4109-809b-46d2-be55-db4b89699016", name: "Saint Vincent College" },
    { id: "6493f7ae-e15c-4eba-83c3-4f069366d3aa", name: "Saint Xavier University" },
    { id: "7684817b-1a91-4b75-a090-5fe4860c7755", name: "Saint-Petersburg State University of Economics" },
    { id: "e5e26794-ef70-4886-92bb-eb863a047854", name: "Sainte-Maire Lyon" },
    { id: "b53b6e71-a93b-45b9-bbb7-f7f12ecdde54", name: "Saintgits Institute of Management" },
    { id: "8708cd32-97b5-4734-bcd1-ac7dfd864088", name: "Saints Cyril and Methodius University of Skopje" },
    { id: "13ec838c-a373-4dfe-86d9-518ee91ab762", name: "SAIT Polytechnic, Canada" },
    { id: "bedcd852-1887-49d7-91ad-f089d774ff10", name: "Sakarya University" },
    { id: "d1de33f1-785f-4ac2-8d9e-054d650a0cae", name: "Sakushin University" },
    { id: "926a24e5-e9ee-4aa7-bcf5-1b402bcaeb64", name: "Salem College" },
    { id: "b1efe546-1c7f-47a6-94c6-948cfe1f4c1e", name: "Salem International University" },
    { id: "5a73f8e9-e275-412c-b557-bed6e9cc7b95", name: "Salem State University" },
    { id: "4989ce75-df53-48db-8541-f8fafbc3f044", name: "Sales Business School Online" },
    { id: "3c651b17-c646-4bd3-ba02-771ad2928170", name: "Sales Manager Akademie" },
    { id: "0763cece-3466-4b3f-8fdb-f12f9f9ec775", name: "Salford Business School" },
    { id: "ef0324fb-8ff7-417f-8457-7c1e17038a26", name: "Salford Royal NHS Foundation Trust" },
    { id: "983f9de4-1308-4879-8a9b-acc752c66cbd", name: "Salisbury State College" },
    { id: "45b58685-22e9-448c-8c6a-36fbb477ff68", name: "Salisbury University" },
    { id: "4cfb4a9b-bd25-4849-9802-a7f11ebe265b", name: "Salish Kootenai College" },
    { id: "4a862123-db92-4477-9298-9f2aa5c0b739", name: "Salt Lake Community College" },
    { id: "e95ab0c3-2495-442d-a743-feb15ff60e9f", name: "Salve Regina University" },
    { id: "4cc0fe6b-a041-4e29-ae8b-3f1af807bdd5", name: "Sam Higginbottom Institute of Agriculture, Technology & Sciences" },
    { id: "8b7b657f-e972-469b-a919-5dbd10ff5443", name: "Sam Houston State University" },
    { id: "3842406d-897b-441b-9042-2acadd2686d0", name: "Samalkha Group of Institutions" },
    { id: "5ad2624c-1962-4750-847a-60760abe8ffa", name: "Samara State University" },
    { id: "0f913985-b922-4de0-a3c8-ae0488f93854", name: "Samband Islenskra Sveitarfelaga" },
    { id: "4d4fe98a-1a21-4329-8949-7ab8c8419bca", name: "Samford University" },
    { id: "ac51cd2c-d530-42c4-af5a-bc6080f5509e", name: "Samfundslitteratur DBK" },
    { id: "7f7bb02a-e458-49c2-93ea-84cfd6ddf975", name: "Samuel E Hubbard Elem School" },
    { id: "719a2907-de6c-4613-9ff1-a1b307991576", name: "Samuel Merritt College" },
    { id: "8c3586f8-9f8e-495e-83f6-90a043a3d8c2", name: "Samuel Merritt University" },
    { id: "a78b809f-8611-4ca3-a50b-5edbd2e0093c", name: "San Beda University" },
    { id: "43305456-3362-4db1-9408-ade142cad70b", name: "San Diego Christian College" },
    { id: "c63e55a7-9a76-4519-92d8-892be6ea3470", name: "San Diego City College" },
    { id: "8d43304d-ae0c-498c-8916-1499e7b019a0", name: "San Diego Community College" },
    { id: "a0fb5c8f-7a1a-4fb1-b95c-88a0d40e5130", name: "San Diego Mesa College" },
    { id: "c3b2c317-38b3-4ec6-8d4a-17f98550a519", name: "San Diego State University" },
    { id: "a6a6a470-543b-4cb4-a341-5790c415c333", name: "San Francisco Art Institute" },
    { id: "ac2a6f6d-12a7-42d8-aa48-b37bf5583c5c", name: "San Francisco State University" },
    { id: "cceb227e-eddf-4cf6-8a34-e1f1c06376e7", name: "San Ignacio College" },
    { id: "3fc718b4-7841-43fb-90cb-6a6f04086823", name: "San Jacinto College" },
    { id: "8bd16caa-552e-4880-9f44-c9598540d2f0", name: "San Joaquin Valley College" },
    { id: "08a4f8b3-6ac4-4371-a6cf-72bd1a38357f", name: "San Jose City College" },
    { id: "64cb8808-665a-43df-835a-6cdf66c70d65", name: "San Jose State University" },
    { id: "6730ab33-8473-44b0-a4a6-5a2107c0ece7", name: "San Juan College" },
    { id: "7af7c4a0-16fe-4ba6-badc-1b9a3cd39936", name: "San Mateo County Community College District" },
    { id: "5ea4444c-290c-4bf3-8646-ccb1119218d0", name: "San Telmo Business School" },
    { id: "3f3732ed-7f5b-4f8b-979c-888a238e0787", name: "University of Texas El Paso Bookstore" },
    { id: "863b21ec-c0a3-47c3-9ff3-4475b63ab58e", name: "Sana'a University" },
    { id: "0b94b0cc-07b4-498f-90d5-96aab1dec34a", name: "Sandip Foundation" },
    { id: "f4f69324-0a2c-49ab-abb2-df8556e1cc7c", name: "Sandip Institute of Technology & Research Centre (SITRC)" },
    { id: "96eaec13-4678-4b14-b3c0-126955eb20ba", name: "Sanford-Brown College" },
    { id: "81528f61-694b-4d94-81b6-712cc2869b0c", name: "Sangam University" },
    { id: "fc34cb0d-8b86-4450-bffd-5231dd8f0f7c", name: "Sanghvi Institute of Management & Science" },
    { id: "3760bb67-4b3e-4c5c-a66c-52dd9354d2dd", name: "Sangji University" },
    { id: "b8cb94b9-7eac-43fa-aa07-685be21a969a", name: "Sangmyung University" },
    { id: "d237ccfd-f5ce-42d1-ae5c-61a4b36d2d24", name: "Sanjay Ghodawat University (SGU) - Kolhapur" },
    { id: "591cc12b-e1d3-48ec-ac10-5ca29e406c6e", name: "Sanming College" },
    { id: "275070e1-5a78-4c34-8378-2803dc91bb8c", name: "SANNO Institute of Management" },
    { id: "bab89a63-e629-4388-80a6-05b21f04b326", name: "SANS Technology Institute" },
    { id: "a929009d-ec4a-4251-8926-ce6b24a71bd6", name: "Sanskar Institute of Management & Information Technology" },
    { id: "2d04fc57-1d80-4204-9080-73c19a6c13a5", name: "Sant Gadge Baba Amravati University" },
    { id: "32a1ddf2-e655-41cb-a3d4-f6fa48f0e8b0", name: "Sant Hirdaram Girls College" },
    { id: "e4edfb5c-2358-4067-91ec-655ac3321478", name: "Santa Ana College" },
    { id: "1a099ce3-e8b8-49cd-b24c-54046ab66ed3", name: "Santa Ana Valley College" },
    { id: "df4b9291-74a6-41d1-ac67-1e7941560a76", name: "Santa Barbara Business College" },
    { id: "e72344c0-2d74-456d-9343-4560f06d1540", name: "Santa Barbara City College" },
    { id: "4a81de7e-fcb4-440c-90ae-1a31dcb75eeb", name: "Santa Clara University" },
    { id: "43067a5b-cc1f-46a2-a980-08326d0e9bd0", name: "Santa Fe Community College" },
    { id: "6a2a5b38-647d-46fd-bb92-44645e8921c1", name: "Santa Fe Institute" },
    { id: "af484879-1957-4829-ada0-6d555f94b493", name: "Santa Fe University of Art and Design" },
    { id: "da0f36e0-ad92-4dd5-81e7-07b77b83d1d9", name: "Santa Monica College" },
    { id: "38605cb3-68fd-415f-87b8-c25a6804d9b9", name: "Sap Business School Vienna" },
    { id: "5b7cdbc0-be22-4422-85d9-47260f2bed5d", name: "Sapienza University of Rome" },
    { id: "c15fbcef-9153-4c86-bb43-71bf6ec71ea8", name: "Sarah Lawrence Bookstore" },
    { id: "245b895f-43ce-4279-a2d9-55505d99c3c0", name: "Sarah Lawrence College" },
    { id: "b50e4464-c106-4608-a224-18eedf7bb808", name: "Sarajevo School of Science and Technology" },
    { id: "8369372d-be34-418c-8828-a2cd6aa312e8", name: "Saranathan College of Engineering" },
    { id: "0d10ba18-348f-407b-9812-5e570acebf7d", name: "Sarasota University" },
    { id: "10ebc1cd-6bf1-4db7-876b-dad6d78a0023", name: "Saratov State Law Academy" },
    { id: "599f547a-d323-4f63-87f0-e56c3a47bcc9", name: "Sardar Patel University" },
    { id: "e352a45d-254d-40d1-9cb3-018ca97c836d", name: "Sasin Graduate Institute of Business Administration" },
    { id: "d4df809c-d965-4483-a1aa-71636639ca8a", name: "Saskatchewan Polytechnic" },
    { id: "7fd37008-6a2d-4dae-9bc4-6628e748017d", name: "SASTRA University" },
    { id: "43b0cc8d-59d2-4bb0-8a7b-c056bad8484a", name: "Satakunnan Ammattikorkeakoulu" },
    { id: "d7ddf3ae-1df7-49ba-a18a-3b2bfbd389cc", name: "Satakunta University of Applied Sciences" },
    { id: "26b3984a-ec84-4828-b084-ccda32a8c101", name: "Sathyabama University" },
    { id: "1161506f-fffc-42c5-90c1-7ec674f229e6", name: "Saudi Industrial Development Fund" },
    { id: "62cd7cff-78bf-43bd-b368-2f2e5959f04a", name: "Sault College" },
    { id: "89cbcc0d-eb68-4b7c-ada6-f8d24dc4d9c5", name: "Savannah College of Art & Design" },
    { id: "dddb0838-f864-49a8-9f74-68c10f9ca3a8", name: "Savannah State University" },
    { id: "47ab9f1d-931d-4f75-b9e8-88f5dc40a7f8", name: "Savannah Technical College" },
    { id: "cac0b505-2226-4f41-8536-b6505adc6004", name: "Savitribai Phule Pune University" },
    { id: "433450c0-8acb-4327-a95a-673f7c38604c", name: "Saxion Universities" },
    { id: "f1be4678-e6ca-4e48-aa2d-380c0fedbce3", name: "Saybrook University" },
    { id: "3ae1e175-e062-4574-a61d-101615420545", name: "SBS Swiss Business School" },
    { id: "234e454c-7245-4bc1-8aa1-50dbbc696974", name: "Schering-Plough Corporation" },
    { id: "dc9102fb-f57b-4a63-aa25-5d1a30014b45", name: "Schiller International University" },
    { id: "329f7de4-4f36-40f2-8387-666e019497dd", name: "Schiller International University - Madrid" },
    { id: "deb1b70e-8a8e-4450-9fa8-b00d639e2eeb", name: "School District of Whitefish Bay" },
    { id: "cec65c57-3d60-4623-89f4-f2e24d0a84ff", name: "School for Advanced Studies in the Social Sciences (EHESS)" },
    { id: "40e6275d-a4df-4115-a9e1-964c0e8722c8", name: "School for International Training" },
    { id: "dcd2cc86-ed8e-441c-a90c-4659afc450b5", name: "School House Educational Publishing" },
    { id: "82c67c37-02c6-4654-a55c-3163dfb9446a", name: "School of Business & Computer Science" },
    { id: "71c17de1-aaed-43e0-9b18-7c66b6e19218", name: "School of Business Administration - Turiba" },
    { id: "f24d3ce5-7351-45fc-88d4-0a6fc3983b89", name: "School of Communication and Mgmt Studies" },
    { id: "4bf7cca4-0c2f-4f93-b622-9ea144819ed4", name: "School of Economics & Management Beihang University" },
    { id: "ec17d4f7-c508-4de4-9ead-2670f1755910", name: "School of Economics and Management, Tongji University (SEM)" },
    { id: "932e3f6b-6e14-424a-b323-30daedfd5ad3", name: "School of Government and Public Policy" },
    { id: "495b325f-f04f-40f7-b0d3-5e2711dcc71b", name: "School of Graduate and Professional Programs" },
    { id: "7ff787dc-0e63-43eb-97c2-7d36c33133fb", name: "School of Information Systems" },
    { id: "76d3ade7-522b-43b1-a238-e1cac3271758", name: "School of International Business and Entrepreneurship" },
    { id: "4ba71c58-a8c4-4fed-b9b0-8215ff50c1dc", name: "School of Management and Labour Studies Tata Institute of Social Science" },
    { id: "a5a3efcf-90da-49ae-83ce-882ebc4a4b15", name: "School of Management Sciences - Khushipur" },
    { id: "9784015f-4306-4c3d-aaae-5eee2a657ab8", name: "School of Petroleum Management" },
    { id: "c1f3d2fb-a29c-440a-9f7a-63853d3db0db", name: "School of Planning and Architecture" },
    { id: "4318b9e9-1358-4a57-977f-6e99c7ea48a6", name: "School of Restoration Arts at Willowbank" },
    { id: "b3b4d06e-3e86-4d8b-bb76-72b045b00a0c", name: "School of the Art Insitute of Chicago (SAIC)" },
    { id: "0f8de2b7-fdd3-4445-bd6c-63c161d13858", name: "School of Visual Arts" },
    { id: "6919f08d-92b9-4094-ac5a-7714badb8bb1", name: "Schoolcraft College" },
    { id: "5eb0eee0-d885-436d-96bc-e84101d4724a", name: "Schreiner University" },
    { id: "cae5d711-47d6-47bd-a27a-338350e23b79", name: "Sciences PO - France" },
    { id: "d0b03bb8-bda5-4db7-a6e1-c64bf68c8f4e", name: "Sciences Po Grenoble" },
    { id: "b10af4a4-8ad0-4101-8344-42bde389583c", name: "Sciences Po Lille" },
    { id: "7fd3c312-e1de-4aab-846c-b1fa867f3216", name: "Sciences PO Paris" },
    { id: "63b5cddc-b9ed-496f-839f-f195689a98e0", name: "SciencesPo Paris" },
    { id: "6c1fbc67-89d7-4c3a-9e43-b93942dba871", name: "SCMA (Supply Chain Management Association)" },
    { id: "cf6a84a9-6499-48ed-b7d9-695034a3202b", name: "SCMS Cochin School of Business" },
    { id: "7aee99e9-fcaa-4626-8434-653924c1842f", name: "SCMS School of Engineering and Technology" },
    { id: "4ff41a70-0456-46aa-8246-880569af6a86", name: "Scotland's Rural College" },
    { id: "985122d3-0903-41fb-bf76-13bc601c16f6", name: "Scottsdale Community College" },
    { id: "ae6d0f73-6bd5-4c4f-9daa-7598385ffafd", name: "Scuola De Amministrazione Aziendale" },
    { id: "015f2a6a-7307-4a34-9f47-d4c7e46e7a83", name: "Scuola Internazionale Superiore di Studi Avanzati" },
    { id: "7b91aa66-0557-4b57-8eb8-038ac4fd282a", name: "Scuola Superiore Sant'Anna" },
    { id: "3b9173ce-a61c-4b09-89e4-25962ee43485", name: "SDH Institute (School D' Hospitality)" },
    { id: "cd9e4d4f-54db-4082-b22c-cc7697a83718", name: "SDM Institute for Management and Development (SDMIMD)" },
    { id: "51b4986f-833b-4694-9e67-e9b4828024d9", name: "SDSU Library - Serials" },
    { id: "98da583a-ff72-4d55-b393-b2cea09e8b98", name: "Seabury-Western Theological Seminary" },
    { id: "988a9eef-f9ab-4799-93ed-1c1ed6fb320f", name: "Sealed Air" },
    { id: "0699dee1-c949-4e18-80af-3068779d3906", name: "Sealed Air, Switzerland" },
    { id: "a1587372-887d-4b9d-8863-7c2f170150a9", name: "Seattle Academy" },
    { id: "20be48c1-58be-4e7f-ab4a-1d46d48b27f6", name: "Seattle Central Community College" },
    { id: "da9040dc-2fa6-4d76-bbba-66b3e4158994", name: "Seattle Pacific University" },
    { id: "2f198c58-dd49-4925-a71d-0b3a756eaec6", name: "Seattle Pacific University - School of Psychology" },
    { id: "29c54cef-7960-4a35-8c63-d6b245e1f345", name: "Seattle University" },
    { id: "98eb5339-35e7-4c90-b663-7c1bc06f9803", name: "Seconda Universita degli Studi" },
    { id: "b4679e36-3897-490d-be14-07f16d0a72ff", name: "Seconda Università degli Studi di Napoli" },
    { id: "8584b2c5-e790-489d-9b2a-40b3bb420170", name: "SEED - School for Executive Education and Development" },
    { id: "7de76376-bbe6-445c-9ca4-a19c71f8962a", name: "SEGi University College" },
    { id: "92641408-80fd-4d19-91b6-914f2ddfba4a", name: "Seigakuin University & Schools" },
    { id: "ebe3f4cb-b7e9-48b1-a144-29900e08bc4c", name: "Seijo University" },
    { id: "e1f853df-2929-4764-b7d2-22f06925f75c", name: "Seikei University" },
    { id: "b8946a5a-f890-4ddf-876b-c3f317a453b6", name: "Seinajoki University of Applied Sciences" },
    { id: "c9076128-84cb-4e5d-8dec-2dd78d0d8ee8", name: "Sejong Cyber ​​University" },
    { id: "63932a79-7970-43e1-a76d-ea4def075f79", name: "Sejong University" },
    { id: "fd082a1d-9933-4bde-b0ad-28526cdd3204", name: "Sejumi Institute" },
    { id: "d7493105-6422-4c07-ae1b-c333da03bd6b", name: "Sekolah Tinggi Ilmu Ekonomi Harapan Bangsa" },
    { id: "c5bc331f-1a6a-40fc-94a4-7b5618f9b222", name: "SelaQui Academy of Higher Education" },
    { id: "95d29895-e1a5-486c-923c-690eae2e1161", name: "Selcuk University" },
    { id: "64458406-c13b-4212-86c2-9ed205f6e59b", name: "Selkirk College" },
    { id: "9c1dacd4-eec9-4287-81be-6145aa1aa508", name: "SEMCOM College" },
    { id: "e8c04b9c-8177-4f8c-b0d4-a4e45598c101", name: "Semester at Sea" },
    { id: "23d8e635-7dc2-44f1-9b72-34187e6d3139", name: "Seminary Co-Op Bk St Inc - University of Chicago" },
    { id: "efd80ac3-dabd-4d03-b883-faa1ec164ba3", name: "Seminole State College of Florida" },
    { id: "dbcdd3a9-893d-4a6d-a302-0ccaad186130", name: "Semmelweis Univ. of Medicine" },
    { id: "b3846e64-30e5-4c1a-af5e-2cbd7b40a1f1", name: "Semmelweis University" },
    { id: "44a75111-bfe6-4b4b-8591-a397977ec142", name: "Semyung University" },
    { id: "ed7d2887-be5f-4c3b-996d-7813dab0a528", name: "Senaca High School" },
    { id: "0061ae34-8220-42b7-8103-301856976a03", name: "Seneca College" },
    { id: "e3dcb0a7-32a1-4362-b366-4ab5d5287ded", name: "Sengunthar Engineering College" },
    { id: "5be23bf3-6914-4d39-a671-f5ee32415932", name: "Senshu University" },
    { id: "3fd97a84-b04d-4798-92b5-3d2a629130cc", name: "Seongkonghoe University" },
    { id: "7787314c-76fb-4513-ace3-43eb833ecd08", name: "Seoul Education Training Institute" },
    { id: "a08c9e13-1dbd-4550-b2a0-346f8310acee", name: "Seoul National University" },
    { id: "1c3ad801-9679-4425-9873-60267d437a32", name: "Seoul National University of Science and Technology" },
    { id: "3ccca73b-0f3d-47bf-b737-2bc1ce83c4f6", name: "Seoul School of Integrated Science and Technology" },
    { id: "fc0c80d5-9979-45b7-8c02-8ddf6b921860", name: "Seoul Women's University" },
    { id: "1090fac2-1991-428d-b6ed-f620296fea0d", name: "Serco Shared Service Centre" },
    { id: "92b71991-9153-4a6d-a1cc-2cc08df210df", name: "Servico Nacional de Aprendizagem Comercial (SENAC)" },
    { id: "f385008b-e39c-466d-93a9-b714b663984d", name: "Seth Anandram Jaipuria Education Society - Ghaziabad" },
    { id: "4cf39628-c7b2-43b6-8949-c137a78b8cf2", name: "Seton Hall University" },
    { id: "66bb65de-8f20-4fd8-8a6b-d052c46db8e6", name: "Seton Hill University" },
    { id: "bc088432-e09c-4d71-acf5-54f10bd4c9ea", name: "Seton Hill University Barnes & Noble 8196" },
    { id: "49fb3d7e-3131-4691-913e-d228547a6005", name: "Setsunan University" },
    { id: "aee274d5-fba5-43db-946b-d3a70d9dccdb", name: "Severna Park High School" },
    { id: "4850c076-daac-468a-b5d1-ea83049f9f74", name: "Sewanee - The University of the South" },
    { id: "d9813d33-5ddd-40de-8c6b-716f1962e4fb", name: "SFU JDC West" },
    { id: "b075a6a6-332d-47a2-9b89-b35893e11893", name: "SGM College of Commerce & Management" },
    { id: "2fc9e3a0-2449-44d7-a21f-bad4a6d5ea61", name: "Sha Tin College" },
    { id: "955ea0cd-c168-4e97-a5c3-27929f6a1190", name: "Shah Abdul Latif Bhitai University" },
    { id: "e5c50b8e-fb09-4056-95e7-f4bd109df426", name: "Shaheed Bhagat Singh College" },
    { id: "a1ebe8f9-edde-4b47-8b4e-e3c12fcaf3bf", name: "Shaheed Mohtarma Benazir Bhutto Medical University" },
    { id: "2c374d05-1aab-411a-8a72-c5e03f2467ae", name: "Shaheed Sukhdev College of Business Studies" },
    { id: "5796f848-f8db-4220-b660-fc69516245ee", name: "Shaheed Zulfikar Ali Bhutto Institute of Science & Technology" },
    { id: "50863a56-c05e-4fd9-9bdd-afc32b191b6d", name: "Shahid Beheshti University" },
    { id: "5f387d12-0c22-416e-817b-404fd777258b", name: "Shailesh J. Mehta School of Management (SJMSOM)" },
    { id: "dc2af727-123d-4ab3-be46-ca3f9237b0c8", name: "SHAKESPEARE & CO" },
    { id: "dd1ddda5-9102-4f68-9eec-bd8aaf8bb703", name: "Shalem College" },
    { id: "4eb05f25-1663-4e11-bb43-2bf6ee6008f5", name: "Shandong University" },
    { id: "c624247d-f8fe-4641-9228-01badc0a8ec4", name: "Shanghai Advanced Institute of Finance, Shanghai Jiao Tong University" },
    { id: "4deae2fe-9df8-4902-8eaf-12fd75a48077", name: "Shanghai Advanced Institute of Science" },
    { id: "3dc732ee-c82e-43c2-92e0-0335956a89f2", name: "Shanghai American School" },
    { id: "a07734fa-4d89-4476-8fca-2174cbbfe82f", name: "Shanghai CIIC-College" },
    { id: "41eb540e-18bd-4631-8baf-fad928270da4", name: "Shanghai Commercial Accounting School" },
    { id: "a85ffbe0-da72-40f3-b348-e3ddb546de82", name: "Shanghai Ha Hui Culture & Communications Co.. (HHE)" },
    { id: "93acc942-4941-467d-8651-8f184193f0df", name: "Shanghai Institute of Electric Power" },
    { id: "1f721bdb-4d1a-4d3e-8b34-33148704496b", name: "Shanghai Institute of Foreign Trade" },
    { id: "721f44c7-dd05-4c5f-a71b-c6edfe19c032", name: "Shanghai Institute of Physical Education" },
    { id: "f938655e-1bc7-4f1a-98ca-8fe8855fc38f", name: "Shanghai Institute of Technology" },
    { id: "f6a45b22-f937-40a7-9ad2-779fb917b6f1", name: "Shanghai International Studies University" },
    { id: "78bb47ff-60d9-4cf4-8c95-76c9c0f7bfe5", name: "Shanghai Jiao Tong University - Antai College of Economics and Management" },
    { id: "fa7abef6-9627-404e-9bd5-cdc067133855", name: "Shanghai Lixin University of Commerce" },
    { id: "fa67600f-8823-4780-82bb-578366471f3f", name: "Shanghai Maritime University" },
    { id: "240d6063-45e5-4681-8b38-68550e95fb4d", name: "Shanghai National Accounting Institute" },
    { id: "47d0920e-088d-4b19-9f9d-4732c36ea5ff", name: "Shanghai University" },
    { id: "2a2822b7-1e1c-4b03-8d75-5fdd287e4998", name: "Shanghai University of Finance & Economics" },
    { id: "cd18f15e-b4ff-4122-8f4b-289c5ae1623e", name: "Shanghai University of International Business and Economics" },
    { id: "a9e823e0-81cf-49d4-88bc-082c69834ec4", name: "ShanghaiTech University" },
    { id: "7c1b9d04-9519-47e5-a0b1-4c6ccee7e224", name: "Shankaracharya Colleges" },
    { id: "edd285d4-dfd6-472a-9c13-2386e025e539", name: "Shanti Business School" },
    { id: "e995b8cc-47dc-42c2-bf72-758061c8407f", name: "Shanti Informatics" },
    { id: "8f41e7c2-0bee-4aea-a1eb-a46cda674aa4", name: "Shantou University" },
    { id: "c07f1e46-08d6-43cd-8250-1d632e52955a", name: "Sharda University" },
    { id: "550d7c1d-e55e-4ef3-a360-6db97b19472f", name: "Sharif University of Technology" },
    { id: "1abf0fdd-3611-4315-a79a-13256ff3751c", name: "Shasta College" },
    { id: "1e10228c-dee2-44bb-8ed3-a72920bb0569", name: "Shaw University" },
    { id: "55afb8b1-e49d-4644-8664-a9cecdb0ea1d", name: "Sheffield Hallam University" },
    { id: "b7526233-c7b6-47ac-8a25-531b59e1a864", name: "Shelton State Community College" },
    { id: "70729e4f-d339-47d5-b8ed-a32d8e21d477", name: "Shenandoah University" },
    { id: "ad6201f1-8038-4f77-8cd5-6e482acc629e", name: "Shenyang University" },
    { id: "c24172b5-56d0-4a8d-a66e-90eb66b5773c", name: "Shenzhen Finance Institute" },
    { id: "5ae7c0a8-1e0b-408f-9302-bef001946802", name: "Shenzhen Technology University" },
    { id: "72ba91df-137a-4b13-8bed-3e54a0c79871", name: "Shenzhen University" },
    { id: "16ae79ff-da27-49a3-8453-6007c9915f57", name: "Shepherd University" },
    { id: "d4d4f826-8aea-429b-9d46-9b25aec1c778", name: "Sheridan College" },
    { id: "d17df80d-a608-4411-a4c6-4b192a4ce233", name: "Sheridan College - Mississauga" },
    { id: "596be5ad-7737-4cbf-9d3c-6269d1c8d7d1", name: "Shiga University of Medical Science" },
    { id: "ca0afcfc-aa90-4519-a81a-c3cf025c49e2", name: "Shih Chien University" },
    { id: "9539684a-d4ca-49d5-b20c-6e424bf55305", name: "Shih Hsin University" },
    { id: "be37fd81-07da-42c0-91fc-b6a4988436b1", name: "Shihezi University" },
    { id: "c2df61ca-8b3f-48a8-a895-a26055ce82ca", name: "Shijiazhuang Institute of Technology" },
    { id: "5f6f67c6-4b05-42f9-9258-d3521db00142", name: "Shimer College" },
    { id: "68cdfea9-f5e4-4fb7-9acd-63d62b953139", name: "Shinas College of Technology" },
    { id: "0822c2bf-e69b-4117-9189-d792aba4bc35", name: "Shinawatra University" },
    { id: "329cdb96-8f79-4469-b114-8ab12025b506", name: "Shinshu University" },
    { id: "036409d0-84fe-4a78-ab9f-a8a6f4af954a", name: "Shippensburg University" },
    { id: "b397446a-0e83-441c-90d8-ddbda18174bf", name: "Shirayuri College" },
    { id: "76976796-f13c-429f-aac0-5705f24f38d8", name: "Shiv Nadar University" },
    { id: "3731e5a9-a100-4b16-b1d1-d6f654cc3c6a", name: "Shiva Institute of Management Studies" },
    { id: "658c110b-a615-4c28-9218-9e9b63a30892", name: "Shivalik Institute of Management Education and Research" },
    { id: "47b7ae06-9f76-49bd-9f41-f5f4786e0057", name: "Shizuoka University" },
    { id: "ac4c057d-2994-4947-8f00-dd64a22e9277", name: "Shokei Gakuin University" },
    { id: "469c87fe-9838-40f2-924d-2d67ffb1a858", name: "Sholokhov Moscow State University for Humanities" },
    { id: "8ef67bd0-9f3b-4c5d-9c77-4d41c11513a6", name: "Shoolini University" },
    { id: "fcdbb98b-d2e8-44be-950f-7a81e0031292", name: "Shooters Hill Campus" },
    { id: "ab7bcd94-ca9b-40e8-81a5-e8633b33f590", name: "Shoreline Community College" },
    { id: "87750413-e6f6-4901-9047-44c2fdda9655", name: "Shorter University" },
    { id: "9f1d9a12-605d-4555-9554-40c5d94234be", name: "Showa University" },
    { id: "88cd7d3c-62af-4f55-9e58-59da3ed424d4", name: "Shri Dharmasthala Manjunatheshwara College of Engineering and Technology" },
    { id: "20b04f93-42de-4948-8913-d8dd32900401", name: "Shri Mata Vaishno Devi School of Business (SMVDU)" },
    { id: "1288c329-dbfe-4212-ab8c-2b140279ffe0", name: "Shri Mata Vaishno Devi University SMVDU" },
    { id: "2e842b4d-7432-43c4-bfc6-454eb3be2231", name: "Shri Ram College of Commerce" },
    { id: "a11f004b-2017-48d4-9669-d522368fb3a0", name: "Shri Ram Murti Smarak College of Engineering & Technology" },
    { id: "8147b7d3-a45a-4740-a9f1-f0bbdd542913", name: "Shri Ramswaroop Memorial College of Management" },
    { id: "05fb5aaf-e415-4980-a692-e48091648a59", name: "Shri Sant Gajanan Maharaj College of Engineering" },
    { id: "6ec0e189-ac46-4a9e-8f97-f0007f8afc7d", name: "Shujitsu University" },
    { id: "4db37be1-da57-434a-b6f9-fa9f60bb260b", name: "Shuxiang College" },
    { id: "490c1667-33bc-4704-8ce7-21871bffe093", name: "Siam University" },
    { id: "0d83cf67-8049-47f0-9603-1bdbf5ecc47c", name: "Sias International University" },
    { id: "2c166b50-bf56-41e5-b609-feebb18d84c9", name: "SIB - Schweizerisches Institut fÃ¼r BetriebsÃ¶konomie" },
    { id: "f4208411-99a3-4d9c-a16b-0acfc4876650", name: "SIB Schweizerisches Institut für Betriebsökonomie" },
    { id: "03a7114c-dd31-486a-ac26-e776fe2072f0", name: "Sibelius Academy" },
    { id: "09786656-ca4f-40b8-8412-b2c0ffb1ca7e", name: "Siberian Federal University" },
    { id: "c4ac3a2f-2892-4551-8e7e-240ee26aa38c", name: "SIC COllege of Business and Technology" },
    { id: "5668e256-e64a-472f-b148-aaeb8946d911", name: "Sichuan University" },
    { id: "e1c462bc-7bb6-49b0-86ac-5a38f20d5e77", name: "Sicily Management" },
    { id: "d68dd9dc-9915-44a5-8a9a-aa19b01fe801", name: "Siddaganga Institute of Technology" },
    { id: "ac7ee0c1-bca6-4c66-8510-7f1504206b48", name: "Sidhu UK Ltd" },
    { id: "8f8f55b5-bc2b-4073-92c6-de619039f88f", name: "Siena College" },
    { id: "468af3b9-62c0-4c2e-8b54-c07cf523578e", name: "Siena College of Quezon City" },
    { id: "04be3594-95f4-4fe3-8384-001856006eb3", name: "Siena Heights University" },
    { id: "782f3548-c604-42e6-b955-743ea736b257", name: "Sierra Club" },
    { id: "8ec10cc0-76b5-4a37-b545-f36bbb5c6848", name: "Sierra College" },
    { id: "f08638c0-8295-4719-ae94-63397ed98cc7", name: "Sierra Nevada College" },
    { id: "5f801210-7081-4dfd-8aa9-2778b52201c5", name: "SIES College of Management Studies (SIESCOMS)" },
    { id: "b99dec73-e20b-49e5-bfae-952b0dd1fe60", name: "Sikkim University" },
    { id: "f50810b4-8903-45e5-861b-79656e1d0ee6", name: "Siksha O Anusandhan University" },
    { id: "221a7587-f85b-481f-b48d-bee4e3c1f601", name: "Silpakorn University" },
    { id: "1d5d608d-766d-4855-925f-4c9fe6da8ec9", name: "Silver Lake College" },
    { id: "54e67058-7c75-472d-abcf-9dc14a75d2ff", name: "SIMI Scandinavian International Management Institute" },
    { id: "632d2c90-59cf-4de9-bed6-d8eab45729f5", name: "Simmons & Simmons" },
    { id: "83aa1409-67f2-49ef-9d5e-83b64e29dff7", name: "Simmons College" },
    { id: "2e88e05a-85d3-432d-9b61-170e7af4ed9c", name: "Simmons University" },
    { id: "f7992203-905b-4957-9e34-280d172b12bb", name: "Simon Fraser University" },
    { id: "2c0e9303-7ab8-4453-a855-7be1354f7051", name: "Simpson College" },
    { id: "e0a6bf58-4a67-4759-9b52-764992075117", name: "Simpson University" },
    { id: "aff20d9f-a319-4212-9c89-e326e8d79f48", name: "Sinclair Community College" },
    { id: "a208bda7-2a0c-40f9-b8ae-8640434c144e", name: "Singapore Hotel and Tourism Education Centre" },
    { id: "221cb060-96c2-4383-9edc-d5a2b172273d", name: "Singapore Institute of Management Group Limited" },
    { id: "3ab7d14d-b86a-4170-956d-bda18d4def99", name: "Singapore Institute of Technology" },
    { id: "2902bc45-69ab-4cbb-ade0-d4955e5b8639", name: "Singapore Management University" },
    { id: "018eeb92-00a2-459d-9692-227388bd233f", name: "Singapore Polytechnic" },
    { id: "fed22381-185d-4659-af12-28850e377cca", name: "Singapore Univ" },
    { id: "9ec07523-efcb-417f-a8ca-5b345a5a4d28", name: "Singapore University of Social Sciences" },
    { id: "dfb8b97b-5c03-45de-9ab9-add2ea6e37e6", name: "Singapore University of Technology and Design" },
    { id: "e2e740e5-13b3-4cd7-af56-f0bd16a98ffa", name: "Singidunum University" },
    { id: "1401e651-16a9-4977-9c22-f37181a1e520", name: "Sinhgad Institutes" },
    { id: "e0f35439-f28d-44bc-8cc1-723f4a6e1ddb", name: "Sinhgad Technical Education Society (STES)" },
    { id: "3a6ec3f3-4bc1-468f-b6f1-20cac095d050", name: "Sioux Falls Seminary" },
    { id: "07425cbb-cce5-4e15-a7c9-649da6e60a4e", name: "SIPRE - Società Italiana di Psicoanalisi Della Relazione" },
    { id: "dc51fc0c-8d4b-4869-9185-22a3a62a9bae", name: "SIPX Inc." },
    { id: "9932f9f0-8e65-41e6-963d-0c4667ae98f9", name: "Sir Padampat Singhania University" },
    { id: "3ff809e9-f567-427e-bfa6-d3b54bf34428", name: "Sir Syed CASE Institute of Technology" },
    { id: "d7b4efb4-6466-4bee-835f-eaa854fbcf45", name: "Sir Syed University of Engineering & Tech" },
    { id: "0a16bd4d-4814-42dd-8ae7-eb241abc7586", name: "Sistema Universitario Ana G. Mendez (SUAGM)" },
    { id: "99dc087c-2419-4975-b802-0a72358c7c9f", name: "Siva Sivani Institute of Management" },
    { id: "886a1899-eed3-4521-ba10-208fac45cdab", name: "SK Research Institute" },
    { id: "0e5429cc-c38f-48b3-a802-04aa18797070", name: "Skagit Valley College" },
    { id: "754b7b78-c0d2-447d-8316-160e02c05549", name: "SKANS School of Accountancy" },
    { id: "586ea3e5-8bb1-4690-8ff7-3f4118ed2c50", name: "Skema Business School" },
    { id: "6b5472f1-6d75-432c-9669-1ca7f931df00", name: "Skema Business School, Suzhou" },
    { id: "8d628728-ff33-4faa-ba7d-99a38741b460", name: "Skidmore College" },
    { id: "300cbb8a-77d7-4ec5-ac2a-e871573769da", name: "Skills for Change" },
    { id: "e6b4b188-d0a9-4924-9580-79e387b83948", name: "SKK Graduate School for Business" },
    { id: "26ef55eb-9ee4-4180-ac0a-990daa24417e", name: "SKS Microfinance Limited" },
    { id: "4a79b3ed-9727-44ca-b771-5309fd82906c", name: "SKT Education Group" },
    { id: "ba4b8587-427a-4f56-a113-eddb03f6c3fa", name: "Skyline College" },
    { id: "b4b27ed7-4ebc-4789-94ef-87f56ba232d0", name: "Skyline University College" },
    { id: "0bb102b2-2c4e-4b1a-86ca-307b38e6984b", name: "Slippery Rock University" },
    { id: "9fe0d5cb-d01a-4f62-a4a2-574d579c0c84", name: "Slovak University of Agriculture" },
    { id: "267df54e-ea46-4949-a061-311b6fb3d69d", name: "Slovak University of Technology in Bratislava" },
    { id: "ab2e8504-4f95-4763-971b-a044388e879b", name: "Smart ICBM Business School" },
    { id: "fb99fa5d-1655-4131-b2ad-fa4488974eff", name: "Smart Institute Pvt. Ltd" },
    { id: "0b4f36a0-50bc-4f18-a842-4ea88da963e6", name: "Smith College" },
    { id: "e31e3136-9e16-436c-bf09-ace5b54f7090", name: "Smith College Libraries" },
    { id: "4949d4d5-250e-442b-8d58-66105a5e61ff", name: "SMS Varanasi" },
    { id: "e45ac088-05f5-443e-8ce6-55c435157790", name: "SMU Cox Executive Education" },
    { id: "5f15ef18-666f-40b2-88ce-2ae89a9050a7", name: "SMU Office of Executive Education" },
    { id: "937bdd5e-15e7-4e97-80d0-50bcb6b32ab4", name: "SMU PGPP" },
    { id: "24fd2a7f-ad65-4186-be4a-61f6c9f852fb", name: "SMU School of Accountancy" },
    { id: "796f7853-2d77-4ccf-9f12-c98bfa5fdfb5", name: "SMU School of Economics" },
    { id: "81eec3db-bbc3-4ea4-9ee8-9b80f22f9fe0", name: "SMU School of Information Systems" },
    { id: "db972112-f164-4c12-9ba4-fa7b73e3f765", name: "SMU School of Law" },
    { id: "9eb8def7-b8b1-461f-b71f-1ae0222cfa75", name: "SMU School of Social Sciences" },
    { id: "c47ef97b-b9be-4a72-8cfe-5409354a851f", name: "Snow College" },
    { id: "c50d4df8-7440-4318-9b6d-4234440ded61", name: "SOAS, University of London" },
    { id: "15714426-d82d-49ea-ab56-0e983e8a9d1e", name: "Sobhasaria Group of Institutions" },
    { id: "841b328c-5643-4b8c-933a-9169dfe400f3", name: "Socialini? moksl? kolegija, V?" },
    { id: "077e7f48-a0a4-4bd8-8195-9b8a8b8c2db6", name: "Society for Higher Education and Practical Application, Varanasi" },
    { id: "3100fdbc-9852-4b54-8211-751fb25101a6", name: "Sofia University" },
    { id: "3753aba6-7927-46d5-8bda-5b3f2d6e88a9", name: "Sogang University" },
    { id: "35d9db84-9462-4947-b2cb-a1fb503a5836", name: "Sohar University" },
    { id: "baff346e-8124-4f78-88ce-5e453aba39ef", name: "Soho Business Solutions" },
    { id: "e9816451-21c7-43d7-a7ba-babf2a1500f6", name: "SOIL Institute of Management" },
    { id: "cc481739-17fe-4a0e-856c-e503aa49b8fc", name: "SOIL School of Business Design" },
    { id: "18441f2e-e5bb-42ca-b360-385f2e978fa7", name: "Sojourner-Douglass College" },
    { id: "8ecf52f0-b25b-439a-bf61-f531eec23037", name: "Soka University" },
    { id: "fd4872cc-271f-47b9-a63b-e411fe9f3132", name: "SolBridge International School of Business" },
    { id: "8b1aa11f-51a4-40de-aaaa-7ee91ef70ea0", name: "Som-Lalit Institute of Management Studies" },
    { id: "c91338b9-b649-4bab-ac41-bc3dc2217836", name: "Somaiya Vidyavihar" },
    { id: "0272a99c-8120-4df0-9e78-d847996d4b5a", name: "Somaiya Vidyavihar - K J Somaiya Institute of Management" },
    { id: "dea0b832-380c-473e-8e0a-634d23317603", name: "Somerset Christian College" },
    { id: "f5bbea80-64b3-4db7-aae2-2932b41d4e4d", name: "Somerset Community College" },
    { id: "e21f2185-5451-4be7-ba41-51e68cb67a94", name: "Sona College of Technology" },
    { id: "20fae0ec-fa0a-4dc3-a700-75a024146300", name: "Sonoda Women's University" },
    { id: "22bd75ef-ef70-4362-afb5-d3526bbe462c", name: "Sonoma State University" },
    { id: "a7ea6ec6-5432-4805-bc04-9531020e366d", name: "Sonora Institute of Technology (ITSON)" },
    { id: "9a513f44-e91d-482c-87b1-6823f41d817e", name: "Soochow University" },
    { id: "ac2f1cc2-aced-4578-b56c-8649d74429e5", name: "Sookmyung Women's University" },
    { id: "84d2fae4-a00c-4d28-a9ef-c8e183ce02da", name: "Soomo Publishing LLC" },
    { id: "704bc191-672d-48b4-94a1-9ea11e6ad3fe", name: "Soongsil University" },
    { id: "6fe22d88-ff15-4bd2-91f3-1d402379430a", name: "Sophia University" },
    { id: "ea0306d7-12f9-4f71-a8c2-f760719ebfb1", name: "Sopocka Szko?a Wy?sza" },
    { id: "af73a21d-3d54-42fd-9141-aa1487c7fd70", name: "Sor-Trondelag University College" },
    { id: "d201c563-a9b1-4366-9f8a-a9013a76455c", name: "Sorbonne - Paris" },
    { id: "cd3bff3f-5123-45d5-aa2e-b2fb634a2ee4", name: "Sotheby's Institute of Art - London" },
    { id: "6797777e-05bc-4431-b927-ddfdab965bb6", name: "Sotheby's Institute of Art - New York" },
    { id: "d5170d21-6060-4f45-94fe-ef54d8e0b49f", name: "SOU Bookstore" },
    { id: "db8d5aa8-2a62-46b7-bbf5-477250eb0ea1", name: "Sousa, Antunes & Câ„¢ S A" },
    { id: "7cb7a7ee-c5e7-42d5-9c73-d3cf5e88b68a", name: "South Asian Institute of Management" },
    { id: "706941f7-1ae9-48ad-aa02-b32517dab11f", name: "South Carolina Governor's School for Science & Mathematics" },
    { id: "1bcd9b15-fb0e-4177-95bb-1b104944f344", name: "South Carolina State University" },
    { id: "0acf3596-478a-4bcf-9caf-815a2c440166", name: "South Carolina University" },
    { id: "6e39e41d-38ce-4808-9612-61fb812103ac", name: "South Central College" },
    { id: "1bd8a7bc-2a04-4d92-858d-76e0fe78b988", name: "South China Normal University" },
    { id: "e1f91ee9-a9bb-4cd0-9e03-ec160511844a", name: "South China University of Technology" },
    { id: "8b9be26b-df95-4e2e-8bf7-86cb47dd45bc", name: "South College" },
    { id: "49d4ce5f-4dac-4335-86f5-a75294e9455a", name: "South Dakota School of Mines" },
    { id: "3aea6e09-97b9-4055-87f8-7f604801a8e1", name: "South Dakota State University" },
    { id: "8a06c1d4-1cdb-4ceb-aae2-59dc9c2aaed4", name: "South East European University" },
    { id: "fb4fb309-1a35-4751-b5f8-1773c45dfd91", name: "South Florida State College" },
    { id: "988de009-e34f-46b1-90b0-1c0ecb0d1c49", name: "South Philippines Adventist College" },
    { id: "4736c22c-e3b4-4115-90fe-3fc388098dcd", name: "South Piedmont Community College" },
    { id: "c4b6cd47-547c-46f0-949f-e35fd9972b0f", name: "South Puget Sound Community College" },
    { id: "11e9379d-2916-4b03-9420-d7ac107b14cd", name: "South Suburban College" },
    { id: "b007e5f5-1e0f-4e6c-9f75-21106f32bd41", name: "South Texas College" },
    { id: "6b64d8bb-f71a-4377-97ce-832c523604cf", name: "South University" },
    { id: "f5e087aa-0a15-4ef3-8b40-d36cab21867f", name: "South University Georgia" },
    { id: "e67d9787-cc47-467b-b676-b6fce5b5efe9", name: "South University Virginia Beach" },
    { id: "609a0b5e-dd33-4309-9655-62a919c9b4b6", name: "South Westfalia Univ of Applied Sci" },
    { id: "f07b2267-404e-4102-9e38-56ac832fae35", name: "Southampton Solent University" },
    { id: "ceb81f0d-aca8-451c-856a-d58a6ee93f47", name: "Southeast Arkansas College" },
    { id: "01858013-14c0-41da-ac9c-06101c1a6481", name: "Southeast Community College" },
    { id: "9ab3ad1e-af5e-4818-95ca-3e754d424cef", name: "Southeast Missouri State University" },
    { id: "458c7a51-7534-4c4d-a6d7-6735fd2eed81", name: "Southeast Technical Institute" },
    { id: "519ba2d1-2b8a-4fb5-ae2f-19b407b3b581", name: "Southeast University" },
    { id: "dfb26a60-c5a5-4190-9911-c9a913c52e2c", name: "Southeast University Bangladesh" },
    { id: "ca01f5bd-5f67-483e-a067-b48e07a6e3ef", name: "Southeastern Baptist Theological Seminary" },
    { id: "9447b986-8f71-4c6f-b446-9b623e4558b3", name: "Southeastern Louisiana University" },
    { id: "813020a9-f0a0-4b89-b56f-fd6349463b99", name: "Southeastern Oklahoma State University" },
    { id: "17f0ed3b-d53f-4a3b-892c-03198c049ba6", name: "Southeastern University" },
    { id: "4ff3f70f-5262-401b-b874-145ed712543a", name: "Southern Adventist University" },
    { id: "c1de18bb-53c6-408d-bf97-3c6183becb0a", name: "Southern Alberta Institute of Technology" },
    { id: "3c10ad40-109b-4778-8f31-af6504f3e7ae", name: "Southern Arkansas University" },
    { id: "ee18337f-a30e-4a37-a299-8c27b4160298", name: "Southern Baptist Theological Seminary" },
    { id: "e773b11e-98e0-40f9-a765-cb67004d6802", name: "Southern Connecticut State University" },
    { id: "1e5f5395-6c92-4fda-9ff7-139c5cd506a9", name: "Southern Cross University" },
    { id: "50684ab0-457e-4230-b0d8-7bd061a92bcf", name: "Southern Federal University" },
    { id: "a51cc30f-37bf-485a-b161-18780a04c721", name: "Southern Illinois University - Carbondale" },
    { id: "6b52288b-7d29-46ac-a8e5-7659ed7186ec", name: "Southern Illinois University - Edwardsville" },
    { id: "fec4c252-6d41-47d4-b8dd-c298f48836ad", name: "Southern Institute of Technology" },
    { id: "11668d73-05e3-4143-bc24-f0c66d4520e6", name: "Southern Methodist University" },
    { id: "c0094f68-8613-4ee6-91ae-166df0d4c450", name: "Southern Nazarene University" },
    { id: "e9a35914-519f-4db3-8a5f-648ac3503e60", name: "Southern New Hampshire University" },
    { id: "61b3690a-0653-4610-a890-f0a60743f949", name: "Southern Orange County Community College District" },
    { id: "2ca2eba4-f8d9-408b-a35a-e453863a7a2d", name: "Southern Oregon University" },
    { id: "1aa10f51-6ced-422f-997e-c1c452926e40", name: "Southern Polytechnical State University" },
    { id: "73508a9c-074b-4d0e-85fe-db2a92688637", name: "Southern State Community College" },
    { id: "b0d2332c-0ae1-4cca-b5f9-bee1ea2e2815", name: "Southern States University" },
    { id: "caeb60c9-d751-4304-bf9a-fc6ebd4e5de9", name: "Southern Taiwan University of Technology" },
    { id: "b767f6e3-1188-466b-8ebd-4071477620c6", name: "Southern Union State Community" },
    { id: "041070ee-4680-44d2-802e-7d78d45a67f9", name: "Southern University and A&M College" },
    { id: "d255f9ee-5ccd-44bd-a4ab-5c2a9290391d", name: "Southern University at Baton Rouge" },
    { id: "9088411d-7917-431b-928d-7b4870d9ecf1", name: "Southern University at New Orleans" },
    { id: "7ac51b68-96f6-43bc-bbd2-0ce912e3a561", name: "Southern Utah University" },
    { id: "e18e9ee9-da64-407b-9b83-ea039ab1e441", name: "Southern Vermont College" },
    { id: "2600d8f3-b0ba-4f06-bce7-4e0de90c792c", name: "Southern Virginia University" },
    { id: "b994f305-3c00-4c1d-a661-07463be75043", name: "Southern Wesleyan University" },
    { id: "0405ca82-3a68-4796-b415-fc16834e2e56", name: "Southville Foreign University" },
    { id: "7840d734-b381-4598-ad88-fef300455095", name: "Southville International School and Colleges" },
    { id: "6c462631-8043-44e2-9d66-6c49da9635a4", name: "Southwest Baptist University" },
    { id: "544b4cd3-11c4-4eda-b116-e08e5f152411", name: "Southwest Florida College" },
    { id: "28ed373b-9548-4a14-a0b1-cb37ca11f3d0", name: "Southwest Jiaotong University" },
    { id: "0b28fe8c-1f54-491e-b8c3-72265251a3ad", name: "Southwest Licking School District" },
    { id: "368ee2eb-ae06-48d4-92ef-77706f33d86f", name: "Southwest Minnesota State University" },
    { id: "55fb3fba-5908-4060-82d5-12ddb0399e04", name: "Southwest Petroleum University" },
    { id: "9110586c-38c5-4c19-91df-2425b1a87961", name: "Southwest Tennessee Community College" },
    { id: "195a7e82-cd0d-43ba-aff6-663a83f4f74b", name: "Southwest University" },
    { id: "3e2e56c0-224f-4d0a-9e7e-edf172272543", name: "Southwest University of Science and Technology" },
    { id: "bfe894ea-9c0c-41f9-9140-83acc4a5e174", name: "Southwestern" },
    { id: "1339cddc-3696-4900-a974-83d20e80b05a", name: "Southwestern Adventist University" },
    { id: "0466db49-c328-4247-813d-f1223dd253a0", name: "Southwestern Assemblies of God Univ" },
    { id: "4c741204-6126-4890-ab19-69b29351c1af", name: "Southwestern Christian University" },
    { id: "b25842e0-4bc2-41ab-8242-47eb7d504b6d", name: "Southwestern College" },
    { id: "ed42840e-4bc4-49a9-8def-bec1dea68819", name: "Southwestern Community College" },
    { id: "7227f555-3f81-480f-8d42-487d4a33d1c9", name: "Southwestern Illinois College" },
    { id: "775aac6f-02f1-4815-9f5b-195233ef153a", name: "Southwestern Illinois College - Belleville" },
    { id: "c0834d32-08aa-4325-a4ae-d6823b4f5936", name: "Southwestern Indian Polytechnic Institute" },
    { id: "9e9e79d6-bda5-47df-a803-650b1ed4ebca", name: "Southwestern Law School" },
    { id: "59d5ad5d-383a-4f96-a723-80a4c541c6be", name: "Southwestern Michigan College" },
    { id: "cf2b0bce-0336-4052-bbf6-fedeb292de26", name: "Southwestern Oklahoma State University" },
    { id: "4523da4a-5c39-45ab-bd42-81aa4e57a6b4", name: "Southwestern Oregon Community College" },
    { id: "97471a2e-083f-41ce-8281-f80310826428", name: "Southwestern University" },
    { id: "f8f9dd85-a780-4775-8b0a-4e063487691b", name: "Southwestern University - Philippines" },
    { id: "15317f61-48f3-44b9-8575-119a9d8e479f", name: "Southwestern University of Finance and Economics" },
    { id: "389e6e39-f83b-4b6e-b315-1add22156526", name: "SP Jain London School of Management" },
    { id: "7a7fe5a5-36b0-44e5-ba1e-b35434ff5c7b", name: "SP Jain School of Global Management - Mumbai" },
    { id: "f89b9978-cb72-4b2d-aebd-f3b777541f1b", name: "Spalding University" },
    { id: "18b62117-2845-4197-9bee-e47d18905439", name: "Spartan Bkst/Sjsu" },
    { id: "60b2e6b5-beac-4f04-bb15-e3ab22ad1eda", name: "Spartanburg Community College" },
    { id: "527fddd1-fca6-4760-a916-e4c54d89d730", name: "Spectrum" },
    { id: "619d22a1-6175-41ae-97c5-614ce0fa3e3e", name: "Spectrum Health" },
    { id: "186c807d-7d6c-4a37-a6f7-8024dd4821b6", name: "Spertus College of Judica" },
    { id: "d35420af-5d4c-4a3b-a05f-0e399c70f2d8", name: "Spirit of Math Schools Inc" },
    { id: "11489ae1-e7e5-4e21-bf29-6768605fcf7b", name: "Spokane Falls Community College" },
    { id: "25479ea0-16f0-455e-83c1-fd5d377dc80d", name: "Spokane Falls Community College Bookstore" },
    { id: "99b4f8cf-447b-4c2e-8772-73b87a06f905", name: "Spring Arbor University" },
    { id: "da7529db-10da-4015-a2cc-a43f1b46bbc2", name: "Spring Hill College" },
    { id: "bcd6748a-71be-4d67-b666-bb8ac5496af2", name: "Springfield College - Illinois" },
    { id: "15d9067d-e57e-45b6-9fa4-c16cef00eb86", name: "Springfield College - Massachusetts" },
    { id: "11dee6cd-e53d-42b2-b8bb-f9f120723eb2", name: "Springfield Polic Department" },
    { id: "328536e3-15fe-4e15-ae8a-0c6b0d189cb3", name: "Sprott Professional Programs" },
    { id: "6d6b6039-31cf-46b4-9f5d-e934dec10963", name: "Sprott Shaw Degree College" },
    { id: "1364b724-4866-4e3f-94b0-9326c0f12573", name: "Sree Narayana Guru Institute of Science and Technology" },
    { id: "0bb52c44-6859-491f-bb4a-34105ce0265a", name: "Sree Narayana Gurukulam College of Engineering" },
    { id: "c038b82b-1256-4efc-9c72-ac8bda142374", name: "SRH Hochschule Berlin" },
    { id: "80a298d0-d892-4c28-aa7e-fa6bcb7dec97", name: "SRH Hochschule Hamm" },
    { id: "b138e32f-0c58-4485-a177-bd3ae5944ff3", name: "SRH Hochschule Heidelberg" },
    { id: "942902a9-a0ca-434e-8735-cd040609dfc7", name: "Sri Balaji Society" },
    { id: "af294ab7-c5f6-4c7d-90d2-bacbd7e1aa0a", name: "Sri Guru Granth Sahib World University" },
    { id: "c784af8a-9061-4f40-9b81-b0b5d939bb71", name: "Sri Krishna College of Engineering and Technology" },
    { id: "f44219b4-b96a-4e65-9faa-ee51c9823563", name: "Sri Lanka Institute of Marketing" },
    { id: "2cdcf8ae-2fef-47f2-9664-24d13e6439dc", name: "Sri Manakula Vinayagar Engineering College" },
    { id: "d7d031ba-7d0f-430e-b75c-b855bfc8930a", name: "Sri Ramakrishna Engineering College" },
    { id: "51802fa8-2371-4d08-a130-efcf575e49d0", name: "Sri Sai University" },
    { id: "c3f8326c-d9da-4db1-aa2c-9230bfb637b6", name: "Sri Sathya Sai Institute of Higher Learning" },
    { id: "fc862ae5-ffa3-4662-a843-7af4a07ed4be", name: "Sri Sharada Institute of Indian Management Research" },
    { id: "5a663104-38c5-4ced-bc43-8cca6f14b3a4", name: "Sri Sharda Group of Institutions" },
    { id: "e3a7771e-aba7-412e-b876-726a4b83d6e5", name: "Sri Sri Institute of Management Studies, Goa" },
    { id: "42933bac-67cc-4397-a5b3-6ccf206225fb", name: "Sri Sri University" },
    { id: "fe6c83d1-9ad0-47fe-9f72-c950aeedabe3", name: "Srinakharinwirot University" },
    { id: "62ceb57e-ecd9-4548-9d93-5e1da6349dcd", name: "Sripatum University" },
    { id: "90214da1-2bf9-4e7f-b1ec-e764df70340a", name: "Sriram Business School" },
    { id: "ff275797-c10d-467d-b897-11a267065c15", name: "SRM University" },
    { id: "84b03002-bff2-4fd5-90bd-156b68352a92", name: "SSN School of Advanced Software Engineering" },
    { id: "046b640b-3d98-4535-8cad-8f43348c0279", name: "ST ANDREW UNITED METHODIST CHURCH" },
    { id: "d21dc628-36c7-48d0-b91e-86c7773f0c9f", name: "St George & Sutherland Community College" },
    { id: "4f462312-733c-42da-ae80-31290d17d43b", name: "St Johns University - Minnesota" },
    { id: "d7e38349-cc60-4f66-b6b9-67b327c18a33", name: "St Lawrence University" },
    { id: "e08f00e9-6db5-4a6c-a62c-f9dd1aa811bf", name: "St Mary's University, Twickenham London" },
    { id: "5f4cb367-1861-4005-90af-c53e0f42f224", name: "St Theresa International College" },
    { id: "04675e1a-0774-428d-a2da-b2ae13024a3d", name: "ST VINCENT COLL BKSTR" },
    { id: "7dc2be9b-52fb-4676-8089-adb65530b626", name: "St. Albert's College" },
    { id: "284e30dd-3283-417e-9677-0f19c18d104c", name: "ST. AMBROSE UNIVERSITY" },
    { id: "0fa3184f-244b-4858-b7b0-2dacda4f9451", name: "St. Andrews University" },
    { id: "8bf15841-c676-4815-9002-75e997d74837", name: "St. Anne's-Belfield School" },
    { id: "d2a8c799-bc13-421a-b989-2cb1b0d45116", name: "St. Catherine’s University" },
    { id: "cd3ce4a5-1698-40c4-9bee-4b46233d340a", name: "St. Charles Community College LRC" },
    { id: "e181566f-1d37-45b4-afc7-1285a104b54a", name: "St. Clair County Community College" },
    { id: "819dc062-12c7-43de-a18f-9c367a0042a4", name: "St. Clement's School" },
    { id: "21a911b8-7609-4b70-9fb8-2541ffd328e4", name: "St. Clements School" },
    { id: "b2e5a279-abd9-4975-aa21-a8e3497f156a", name: "St. Cloud State University" },
    { id: "6c24e4c8-d2fa-4363-9293-3c2fb6360466", name: "St. Cloud Technical and Community College" },
    { id: "97de3055-1e2b-417e-a9af-08dc86012336", name: "St. Edward's University" },
    { id: "71e03424-fb7d-48d6-a37c-d6337a6efd1e", name: "St. Francis Institute of Management & Research" },
    { id: "71ddbff4-b0e6-4a23-8dae-bb027bff503c", name: "St. Francis Intsitute of Technology" },
    { id: "6586f84f-bdc0-4538-becf-bc7fc971ae42", name: "St. George's University" },
    { id: "852b7bb6-7ca9-4933-a7d9-75ab7733f544", name: "St. Gregory's University" },
    { id: "c76c6b73-aa39-4859-b8d5-4c5cbd13d4ae", name: "St. Ignatius Loyola College" },
    { id: "e0c39429-5bfd-48c2-84bf-221801ce979d", name: "St. John Fisher College" },
    { id: "2545bc1c-1f81-4da4-86ed-8e474ad33759", name: "St. John's University - New York" },
    { id: "36a1845e-8e93-4ebf-9e4e-6b05a5aa8669", name: "St. Johns River State College" },
    { id: "e20198a6-3108-4b93-bebd-4a7121fb04f9", name: "St. Joseph Catholic School" },
    { id: "0efe7cb6-efa1-495d-825c-1a8451d57358", name: "St. Joseph's College" },
    { id: "028c0019-2bff-4216-afd1-0b71a3c79fa5", name: "St. Joseph's College - New York" },
    { id: "46e0f10a-85ff-4ef0-8bb8-f14354afe4e3", name: "St. Joseph's Institute of Management (SJIM)" },
    { id: "62bad2d8-31c4-4a94-a7e1-c4d16175306a", name: "St. Louis College" },
    { id: "c355e1c3-7112-4423-b9bd-eeb8c6b5d7dc", name: "St. Louis College of Pharmacy" },
    { id: "67bd8a5e-5fae-4ce1-b62f-0c1ce7f92f0f", name: "St. Louis Community College - Florissant Valley" },
    { id: "98b95dd1-0bd5-4817-8208-cfb43e2cc428", name: "St. Louis Community College - Meramec" },
    { id: "c3209bf3-2ee5-44e4-8d36-e9a71c0f7788", name: "St. Louis Public Schools" },
    { id: "6e3b6734-2b71-4341-a8e5-52ffaeec8efd", name: "ST. LOUIS UNIVERSITY-MAIN CAMPUS" },
    { id: "42ead6a3-a3b3-403d-a32f-dee19effbdde", name: "St. Mary's College" },
    { id: "af5379b2-2fba-4e6a-96a1-26a9a7519313", name: "St. Mary's College of California" },
    { id: "919bd600-fc25-4df3-898c-50bc67475b67", name: "St. Mary's University" },
    { id: "68c914f5-cc6f-4c5e-9d5e-e42f189d85d6", name: "St. Olaf College" },
    { id: "5a22eeeb-54c1-4e3d-bf22-1fb0f0d6f355", name: "St. Patrick's College" },
    { id: "baecfbe3-774f-4630-9feb-a46f8088ec0f", name: "St. Paul University - Manila" },
    { id: "c55e352e-c5c6-44bf-8b86-c32c9ff851d3", name: "St. Paul's University Kenya" },
    { id: "7bd04ef3-7d25-44b3-819f-cbd8561ecdc3", name: "St. Peter's Seminary" },
    { id: "f2b79a38-f50e-41e2-b540-d76b402b78a9", name: "St. Petersburg College" },
    { id: "1d6b1271-7a22-4685-84b8-9b880e9deda3", name: "St. Petersburg State Polytechnical University" },
    { id: "bcb4cbc3-b024-4b95-b5ee-fc7af953ff62", name: "St. Petersburg State University" },
    { id: "4df05325-2a28-4831-bc74-ee6141f55da5", name: "St. Petersburg State University Graduate School of Management (Gsom)" },
    { id: "9b37b724-d253-458b-8f85-ff97db87b45c", name: "St. Thomas University" },
    { id: "0b097c25-4fa1-4276-b68c-6fa86ff9fbfb", name: "St. Xavier's College" },
    { id: "4d95e657-c10a-4d6e-90b2-8ef49bec3d97", name: "St.James's Business School" },
    { id: "b5639516-4ff6-488b-99a9-5697c0cad87a", name: "St.Pölten University of Applied Sciences" },
    { id: "4c93da5f-3d9f-4120-94c5-5a16fb395b79", name: "Stafford County Public Schools" },
    { id: "e73aadfc-767a-45c4-aed8-e55e28bffd7d", name: "Staffordshire University" },
    { id: "0ed91e87-b5b3-45ff-a26e-73d43378b308", name: "Stamford College" },
    { id: "17da06fc-39bb-400e-9b1a-1baf2435eeea", name: "Stamford International University" },
    { id: "b56aa07e-cc02-4a2e-b64f-cebbb65950ac", name: "Stanbridge College" },
    { id: "432355dc-df1c-4f77-ac32-5219419b8fb2", name: "Stanford University" },
    { id: "eb782b54-e4ad-4204-af24-7fcf0646eed5", name: "Stanley College of Engineering and Technology for Women" },
    { id: "ebbc310a-b781-4165-9044-1c6cf37a2d91", name: "Stanly Community College" },
    { id: "69be10f8-deb7-4712-871d-c4801918ba9f", name: "Starcom Mediavest" },
    { id: "e1fb69bd-bcc7-4d68-850d-ce30b3795ec1", name: "Starjobs Learning Centre Ltd" },
    { id: "f71f2bca-bfec-4b29-a12d-9f5203856002", name: "Stark State College of Technology" },
    { id: "e48ec3f6-ac32-4996-ba30-6e8c053d6fd2", name: "Starkmann Inc" },
    { id: "7b40e12d-2644-4960-879b-8d5495f2252a", name: "State College Area School District" },
    { id: "10f6260b-d7d7-4588-8680-d9c87b48a3c2", name: "State College of Florida" },
    { id: "df1f5667-e811-4b7d-97f6-e2cffb229b9f", name: "State Ctr Community College" },
    { id: "78b97480-df73-4cfd-8a30-7753354b83ab", name: "State Fair Community College" },
    { id: "c5bca1ae-4ad6-4cc8-acb5-2b0bd0d34e74", name: "State of Hawaii Department of Education" },
    { id: "e8466bd1-6e35-4e30-9f4b-ae2216d1ef64", name: "State University - Higher School of Economics" },
    { id: "18961f14-66cd-495e-9428-7b56039076b2", name: "State University of Bangladesh" },
    { id: "a5f6063f-7280-4550-aebe-eaa0cef98693", name: "State University of Management" },
    { id: "84552049-2e85-4c2b-a0fb-2f2d382887f6", name: "Steinbeis-Hochschule Berlin" },
    { id: "58e832e5-4515-4171-9dcf-50d2548c5b8d", name: "Stella Maris College" },
    { id: "8fd186fd-7bea-4150-928b-885c10387698", name: "Stellaris" },
    { id: "aa9cdbd7-3337-4ec7-8076-2731e7b69bda", name: "Stenden University Bali" },
    { id: "b86837a5-e0a8-44b3-945b-d7e3816a5ac3", name: "Stenden University of Applied Sciences" },
    { id: "f667822b-8657-4d3c-aa84-6082761bd81a", name: "Stepan Bookstore" },
    { id: "f29c7792-2bcc-4f88-a92a-d815b1a5dd5b", name: "Stephen F Austin State University" },
    { id: "dcc8de57-ca39-4e96-8e22-ae17c81384f1", name: "Stephens College" },
    { id: "c2c9aecb-bcd0-407c-9170-8e2c678fa8ea", name: "Stetson University" },
    { id: "8721b665-98cb-4cd0-8b62-d2b7eb21d96a", name: "Stevens Institute of Technology" },
    { id: "e44acfd9-55f2-4d90-a88b-3f7d45c60207", name: "Stevens-Henager College" },
    { id: "18b0effa-60bd-4fd0-ac7c-a63c0166fb87", name: "Stevenson College" },
    { id: "25b405c7-3681-48eb-8b4c-11189df48a89", name: "Stevenson University" },
    { id: "d33a9d86-d776-4e16-b04a-9f7a547ff2b7", name: "STI College" },
    { id: "d0d30d4d-0240-4138-adf1-8c1e472e673a", name: "Stiching Publishers Rights" },
    { id: "07c86923-c5b3-411e-9425-e4376cb89c03", name: "Stoa Business School" },
    { id: "0ac29ac1-f3e2-440f-ae7d-14a0a3c0fb6a", name: "Stockholm School of Business" },
    { id: "82fdfe68-d968-46e1-bebb-18d1bcb7c06d", name: "Stockholm School of Economics" },
    { id: "9df9912c-7329-4899-9a2e-131ae6add415", name: "Stockholm School of Economics (Online Usage Reporting Placeholder)" },
    { id: "4c5d41d8-5e88-449c-af8e-93e9f6dc3bce", name: "Stockholm School of Economics in Riga" },
    { id: "7aa89b94-0397-45b0-90eb-9cf670af16ec", name: "Stockholm School of Economics Russia" },
    { id: "93a29609-2922-4783-a2ee-e4ef8a2fc0ee", name: "Stockholm University" },
    { id: "584833c9-9028-456c-8850-58d69171b4d0", name: "Stockton College Bookstore" },
    { id: "b11716e3-419c-434b-bed0-8af8f7c0ca85", name: "Stonehill College" },
    { id: "525a1585-71fd-4f12-84c9-ae3928e584bc", name: "Stony Brook University" },
    { id: "54a7e32c-938a-4a2c-afe1-3c29c80c71a5", name: "Stott's Colleges" },
    { id: "ced81533-028e-4073-8b94-a7adede8b0b2", name: "Strabsnet Intl Centre for Distance Learning" },
    { id: "aecc1cac-2601-4a28-9a82-0750c6d35db5", name: "Stranmillis University College" },
    { id: "41283bc0-352c-48e3-b29a-d5f6fd4f7547", name: "Strategic Management Institute" },
    { id: "9c3983e3-b407-44a6-b8fd-0637828c9027", name: "Strategy First Institute" },
    { id: "335b96d8-fd83-4f5c-b0b2-a79a5f3841af", name: "Stratford University" },
    { id: "87ee0e5e-1e3b-48df-9ccf-9f7d54e82f30", name: "Stratford University - Falls Church" },
    { id: "bb177d6e-098d-4d5e-a492-5831ca2661ea", name: "Stratford University - Glen Allen Campus" },
    { id: "1ddb5cb2-aa55-4870-9f95-87ee881dc0dd", name: "Strathmore University" },
    { id: "1ceb1496-ba9f-4768-b287-6f30cd59044f", name: "Strayer University" },
    { id: "9e95aa69-54bc-4f30-9ac2-725635bdf144", name: "Strayer University Academic Programs" },
    { id: "6a7033e9-45b1-4cf4-bdd8-1eefd315a0b5", name: "Strive India Education Foundation" },
    { id: "4c24dee1-3db4-460b-93d0-999c6d9b9078", name: "Stuart Slatter Training" },
    { id: "fc5b1032-2467-442d-b290-faa50b116485", name: "Stuart Timperley Associates Ltd." },
    { id: "c4a09734-7a3a-4419-b82f-6d20e45c4b6b", name: "Student Bkst Inc" },
    { id: "ecf91c5d-0b53-4bfe-857c-66df2bb59290", name: "Student Book Exchange" },
    { id: "3be7263d-7dbc-41e9-8cf9-21b46ea298ca", name: "Student Stores" },
    { id: "17bfe2df-0dd6-40a1-9cf1-ef9b62ee17e9", name: "Students First" },
    { id: "9c3ecf2a-7cfc-44dd-8cb8-7d7ddb7235b4", name: "Students Society of Mcgill University" },
    { id: "d710490a-50bc-485a-8f4b-dd31a99b1ce6", name: "Study Center Gerzensee" },
    { id: "f00c435c-7d10-4464-b772-eecdf9bb2b1e", name: "Study Foundation of the German People" },
    { id: "4dc52c74-da1a-4bde-99d9-49aacef8cbae", name: "Stuttgart Institute of Management and Technology" },
    { id: "d5b49e1e-2801-4f24-bea4-3222d2d8e375", name: "Stuttgart University" },
    { id: "40329eac-690c-4ddf-9117-69942b5844b4", name: "Stuyvesant High School" },
    { id: "f802205d-46af-43cd-ad15-9ae367e13ccb", name: "Sudha Rustagi College of Dental Sciences & Research" },
    { id: "007a81ad-a22b-4f93-9a2d-7e5ae9b19895", name: "Suffolk Community College" },
    { id: "07ef58a4-68c4-4b43-8617-d33ad4a91063", name: "Suffolk New College" },
    { id: "b43c62ec-ae86-4475-85c2-5dff9535687a", name: "Suffolk University" },
    { id: "3668c63e-2368-46be-9ef5-60d22d97a32e", name: "Suffolk University bookstore" },
    { id: "f1361631-e577-411e-a770-229260888c52", name: "Sugiyama Jogakuen University" },
    { id: "514296ab-cdde-41a4-b382-d5c3490ce304", name: "Sukhothai Thammathirat Open University" },
    { id: "8df84313-9dbd-45f1-bae2-b0a3935af504", name: "Sukkur Institute of Business Administration" },
    { id: "040234ba-e01c-46a1-aba6-ce96397cc139", name: "Sul Ross State University" },
    { id: "de1be838-80d9-4b32-9b91-1fbd560737fb", name: "Sulaiman Al-Rajhi Colleges" },
    { id: "706f51e9-cfa2-4b67-b4b5-1b43e301b666", name: "Suleyman Sah Universitesi" },
    { id: "d4d7d81e-e25c-4879-9d96-7294149baecf", name: "Sullivan County Community College" },
    { id: "81dbf485-162f-4aed-9d63-f626b9c854fb", name: "Sullivan University" },
    { id: "2250af46-95d5-4590-bb9a-13e455879d0d", name: "Sultan Qaboos University" },
    { id: "1cdf4d0e-e16f-4082-97eb-f00ca7017de3", name: "Sumner School District" },
    { id: "47bf8b87-0fc8-43e2-b321-2931475b17d6", name: "Sun Yat Sen University" },
    { id: "835d9ad8-7b42-4d65-8cdf-73b3cae4fe34", name: "Sungkyunkwan Graduate School of Business (SKK GSB)" },
    { id: "8b77d346-0eae-4d22-bff6-78520fa5c6b3", name: "Sungkyunkwan University" },
    { id: "86823389-eb5d-441b-98b1-51ed0f54f6ca", name: "Sungshin Women's University" },
    { id: "8ecade3f-511b-4c72-9c4b-62ef312003c3", name: "Sunway College" },
    { id: "5886a64f-a4c9-4022-9c69-232537add328", name: "Sunway Education Group" },
    { id: "b165608f-ef31-4fef-8a07-81c0459915aa", name: "Sunway University" },
    { id: "33794c03-2021-444a-bb62-77c1eb1c1ec1", name: "SUNY - Brockport" },
    { id: "aaed8fe9-d6d1-4f33-90e8-56ddc7052bb7", name: "SUNY - Cobleskill" },
    { id: "9a4065f0-32c2-42e4-826e-3c7b63a210dd", name: "SUNY - Delhi" },
    { id: "928e7e7e-c53a-4974-8754-246172cdf6d7", name: "SUNY - Fredonia" },
    { id: "94ddf7c9-fd14-441e-9316-071c1b7690fc", name: "SUNY - Geneseo" },
    { id: "73cb30e1-2103-4f8e-b8d2-86786339c803", name: "SUNY - IT" },
    { id: "b2cae0ca-43c6-4d9e-a3ef-c112244253e4", name: "SUNY - Korea" },
    { id: "04d76680-8a7a-4465-95ae-52aec3405d32", name: "SUNY - New Paltz" },
    { id: "d1b4e6e5-8ba1-46e5-8bee-3c2a0bfbe166", name: "SUNY - Oneonta" },
    { id: "2aadebe8-fc7b-4b04-a1cf-719d6a56d3f2", name: "SUNY - Oswego" },
    { id: "5290a882-8526-41bf-a4f7-be7e05d3ff35", name: "SUNY - Plattsburgh" },
    { id: "43a31ec1-ceb7-48b9-ba0e-fdd778c8c67f", name: "SUNY - Ulster" },
    { id: "46f833d2-97a1-465e-a074-4885270db852", name: "SUNY Adirondack" },
    { id: "2e0c263d-4cfa-4498-961c-76b5f1ce8135", name: "SUNY Broome" },
    { id: "b00052a8-3004-40a9-9f0b-99755785c97c", name: "SUNY Canton" },
    { id: "da674737-a711-44a1-859c-8b8113b2dd28", name: "SUNY College at Old Westbury" },
    { id: "8181c4c3-047f-48e5-9ddc-4c9281b73cee", name: "SUNY College of Environmental Science and Forestry" },
    { id: "94b3efe7-1c5c-4821-86ae-368c790c72a5", name: "SUNY Downstate Medical Center" },
    { id: "53f6cd5b-9b04-4b89-ac93-1536ab4a44fd", name: "SUNY Herkimer" },
    { id: "3a2840ff-a3df-42b5-80c3-4036d12c4dba", name: "SUNY Korea" },
    { id: "65511766-1914-403b-9b20-98136c740583", name: "SUNY Maritime" },
    { id: "0bae93c1-5ac9-4905-b191-9006bfd93a50", name: "SUNY Orange" },
    { id: "b61e0fd0-c780-4840-af2a-df0fbc2ea3dc", name: "SUNY Suffolk" },
    { id: "0e4dad13-ff2d-4c15-b6db-767fd1a3996b", name: "SUNY Sullivan" },
    { id: "c8b286ab-cce0-4eae-9f57-406b9968f35b", name: "SUNY Upstate Medical University" },
    { id: "e249f7a5-013f-4f43-be88-bd875fbb00e5", name: "Sup De Co Marrakesh" },
    { id: "fef74a86-c58a-4c63-9119-03aeff4692e6", name: "Superior Technology School" },
    { id: "764a96b2-a728-4fd5-ad80-cc9b05e24200", name: "Superior University Lahore" },
    { id: "fe3cf412-d465-4f96-927e-ba8638838ab1", name: "Supinfo International University" },
    { id: "55585060-c980-4195-be29-acad4ae0b188", name: "Supméca - Institut supérieur de mécanique de Paris" },
    { id: "97e6286c-2edb-4d40-a651-322dadb1243e", name: "SUPSI-Scuola Universitaria Professionale Della Svizzera Italiana" },
    { id: "ffa1df34-6eb8-46bb-87cb-3f6653a2b3d1", name: "Surana College" },
    { id: "ccf8e1f2-d229-4250-a428-589d84fb0c92", name: "Suranaree University of Technology" },
    { id: "1ea21bf1-ea51-481b-a5a6-532f78ae33fe", name: "Surrey Place Center" },
    { id: "577404de-5f5f-4d63-89c8-5e595b2601cb", name: "Susquehanna University" },
    { id: "70d6db8a-bd81-4452-846a-f9e11026f233", name: "Sustainability Management School" },
    { id: "ffb8c082-8a95-4393-9312-6cc4d0a4213e", name: "Sustainability Management School SA" },
    { id: "7514fc07-b095-4da6-bd24-6e5979e9ce1d", name: "Suzhou University of Science and Technology" },
    { id: "49162418-5dd6-4a1f-a31b-efc63b2c927b", name: "Sveriges lantbruksuniversitet (SLU) - Alnarp" },
    { id: "7e743ae6-9bc8-4c41-b95f-514cb8ce89f7", name: "Sveriges lantbruksuniversitet (SLU) - Uppsala" },
    { id: "d7ae0a00-a308-45b0-b735-63b4a32c6ec0", name: "Swami Ramananda Tirtha Institute of Science & Technology" },
    { id: "da57719f-dadd-49b6-9f14-b44837487f2c", name: "Swansea University" },
    { id: "1a284b95-c3e8-4608-b5ed-52ed2c3fc244", name: "Swarovski Academy" },
    { id: "d83f035f-0668-4e6c-9dba-747394e9fdb7", name: "Swarthmore College" },
    { id: "5a97ede8-b95f-486a-9232-b453eefb8f5c", name: "Swedish School of Econ & Business Admin" },
    { id: "5c94bb83-23a1-4d70-bc61-4b0eb058e790", name: "Swedish University of Agricultural Sciences" },
    { id: "ec49b74d-44d1-4b0b-9965-1c56e9be7f89", name: "Sweet Briar College" },
    { id: "8cbd8122-243c-4378-979d-30b65c11f415", name: "Sweetwater Union High School District" },
    { id: "703bd73f-2bfc-4230-9658-47a524f9dd36", name: "Swinburne University of Technology" },
    { id: "f74c2798-5719-42c6-86f8-35c45bb6e191", name: "SWING an der TU Ilmenau e.V." },
    { id: "57ed26df-a0cb-4fa9-9929-9b4f8a9f5b60", name: "Swiss Asia Banking School" },
    { id: "30f8978b-3913-4af3-a52c-a0c1bc574c6c", name: "Swiss Engineering Institute" },
    { id: "d1449e2b-6bcd-40e1-8d40-bcf0e914a163", name: "Swiss Finance Institute" },
    { id: "3ba11eda-96af-4354-83fd-8dc7dc4612af", name: "Swiss German University" },
    { id: "50bb5477-8b24-4b5e-9bf8-c2d2793b4350", name: "Swiss Hotel Management School (SHMS)" },
    { id: "f375d350-3221-41b9-8cb5-184687d292b8", name: "Swiss Manangement Center Ag" },
    { id: "75205655-9828-46cd-b02c-e09429a86ce6", name: "Swiss School of Business and Management Geneva" },
    { id: "ca1d7667-aa23-4a9a-8dcc-41f82b930546", name: "Swiss School of Management - Rome" },
    { id: "2095b0d8-f37a-4928-99c2-647bec19e192", name: "Swiss UMEF University" },
    { id: "3d6e5f5d-e8a0-42a1-a786-950ecc2b2d07", name: "Swiss Virtual Business School AG" },
    { id: "5470ae41-1cd7-4231-a6af-1edfaf494394", name: "Syddansk Universitet" },
    { id: "f169ecc5-9401-4ed8-ba9a-ee1e7809a1f2", name: "Sydney Community College" },
    { id: "cec7f69e-e36d-4225-b51d-807719a13e00", name: "Syiah Kuala University" },
    { id: "1e3c5fec-9d01-4af6-9f84-8974a57f134b", name: "SykesFairBairn" },
    { id: "79cbebb0-9289-4532-8a6b-72c168ba4b8f", name: "Symbiosis Centre for Information Technology" },
    { id: "c606865f-565a-4b2c-9fe0-d99899fc5a3a", name: "Symbiosis Centre for Management & Human Resource Development" },
    { id: "a988a40d-8b4e-4f9e-ba70-e515bfb4afda", name: "Symbiosis Centre for Management and Human Resource Development - PUNE" },
    { id: "8d453d61-06bc-4f15-a1a6-547384e59f0d", name: "Symbiosis Institute of Business Management (SIBM)" },
    { id: "c583b66a-a0a7-42e2-8c3a-91c72e2d716b", name: "Symbiosis Institute of Computer Studies and Research (SICSR)" },
    { id: "0886c6f0-4004-457f-b347-9af74a58dfc3", name: "Symbiosis Institute of Digital and Telecom Management (SIDTM)" },
    { id: "0963744d-872d-4f4d-ad1e-327d904e529a", name: "Symbiosis Institute of International Business" },
    { id: "cd6866f3-cbb8-48d2-ac90-29cee54bdff0", name: "Symbiosis Institute of Operations Management, SIOM Nashik" },
    { id: "e5c65cc3-976f-4668-a050-83f847c97c4f", name: "Symbiosis International University (SIU)" },
    { id: "d72d0d61-992c-4652-ad77-618aa8eb3a52", name: "Synapses" },
    { id: "b8a6942a-8cf3-42ee-aec4-f4c547ce91fb", name: "Synergy University" },
    { id: "8626ee88-d930-4db4-a347-30e1ac803671", name: "Syracuse University" },
    { id: "8846e988-f294-42cc-8a2f-cb5c8f8ae7a9", name: "Syracuse University - London" },
    { id: "8276aa2a-f4e1-45df-874c-a0c9880cc7ac", name: "Syracuse University Madrid" },
    { id: "dacd468f-9783-40c6-8fe0-8062fec6b031", name: "Syracuse University, UK" },
    { id: "5d4fa94f-0b70-404e-9e49-ef896ddc9208", name: "Szechenyi Istvan University" },
    { id: "dbc777bf-371f-4a87-a4ba-95c0b3e1d881", name: "SKODA AUTO University" },
    { id: "e81afe1a-5969-424a-b80a-5af7e5ac8cd9", name: "Södertörns högskola" },
    { id: "56d43142-c22c-4a45-a78e-207a8324409e", name: "T. John Group of Institutions" },
    { id: "8f35749c-5136-4dc3-841b-1111bdc84397", name: "T.I.S. Incorporated" },
    { id: "88b9eebb-15a6-45ea-8a10-04334b080615", name: "T.Lab Accelerated Learning Centers" },
    { id: "18c5b193-2c0d-4dc0-b1ec-e39a8ac8304b", name: "Ta Pai Management Institute (Tapmi)" },
    { id: "33b709ba-7c45-406a-8572-4dc33f878725", name: "Tabor College" },
    { id: "48b469a1-e4c6-4dbf-b537-34e69011ca7a", name: "TAFE NSW - The Hills College, Castle Hill Campus" },
    { id: "7f9e8d8d-c276-4984-948e-c07d3cabc796", name: "TAFE NSW Riverina Institute" },
    { id: "ec3b688c-617c-4a8b-b788-d22a78ce9cbf", name: "Tafila Technical University" },
    { id: "4428a20f-d201-4801-8678-b7538406993d", name: "Tagore Government College of Education" },
    { id: "6392a879-e530-41bc-9eb4-0c83ab7b269e", name: "Taipei American School" },
    { id: "6a3ae796-9c0d-4096-b0b8-6b4b13488be0", name: "Taipei Medical University" },
    { id: "bb289f53-e5f6-42b1-a96a-c0327f869ce5", name: "Taipei National University of the Arts" },
    { id: "76b8acf5-f4e5-46a2-a3c8-69a22a732c5c", name: "Takasaki Univ of Commerce" },
    { id: "4c0e5974-7dc4-4133-b837-89c207533bf8", name: "Taksila Business School" },
    { id: "057f73e8-0c83-41b6-b4ab-b8955b9e608b", name: "Takushoku University" },
    { id: "3fcf8542-9c52-4289-8939-0b6c7e735390", name: "Talisis" },
    { id: "16e4e08c-89ca-4b78-94c1-da98a0cfa49e", name: "Talladega College" },
    { id: "da706c4c-d84b-4ded-8249-9ab0f1c22d1b", name: "Tallahassee Community College" },
    { id: "5d50d56f-6d61-46a8-b617-ab396c52eaa8", name: "Tallinn University" },
    { id: "f1cd63bf-1cf7-4d30-a743-cfe9972d5ce7", name: "Tallinn University of Technology" },
    { id: "5c009495-e359-4e4e-9a48-6a804867280c", name: "Tama University" },
    { id: "a476a638-335b-43dd-b2a2-6208369c2da4", name: "Tamagawa University" },
    { id: "125299af-8999-4c1e-8823-ba3910446ea5", name: "Tambov State University" },
    { id: "c6dd5b85-6c91-4dee-8742-f2f40109aedc", name: "Tamil Nadu Agricultural University" },
    { id: "ccb7768a-c47a-47b5-8441-223c2dbc871d", name: "Tamiu Bookstore" },
    { id: "67c7edd5-7c8a-4558-8ab0-91be5055ecf0", name: "Tamkang University" },
    { id: "d914b9f4-9bbb-4b43-af33-530e86599624", name: "Tampere University of Technology" },
    { id: "3cef8109-b36e-4b9b-b248-e57bba3dad54", name: "TAMU Recreation, Park & Tourism" },
    { id: "524913e6-ff86-424f-8048-5ca484b8bb5b", name: "Tanjong Katong Girls' School" },
    { id: "797ac410-dd43-4614-a0dd-8a807e9313f7", name: "Tanta University" },
    { id: "6b47793b-1839-41ba-98e0-99ebd54d605e", name: "Taoyuan Innovation Institute of Technology" },
    { id: "b5d50168-10cb-42a8-b8cc-0ad073124433", name: "TAPMI School of Business - Jaipur" },
    { id: "8946716d-e5cc-42fe-b047-d6de0159d4d8", name: "Taras Shevchenko National University of Kyiv" },
    { id: "844de9b4-cb57-4c38-bb92-94ef448c010b", name: "Tarbiat Modares University" },
    { id: "df958674-b3f3-4e2c-aecf-fffef7ff2876", name: "Targray Technical International" },
    { id: "d0dbd130-f6df-4d3a-b1de-972f303657a7", name: "Tarrant County College" },
    { id: "fc30ac9a-362f-45f2-9110-9b131254dee9", name: "Tata Institute of Social Sciences" },
    { id: "fb38f1a7-a9bc-4eff-8ed0-5ff64777f4f1", name: "Tata Management Training Centre" },
    { id: "dc59d817-ee7c-4700-a980-94b4ce51d14b", name: "Tate and Lyle Plc" },
    { id: "c61def27-2578-4ff0-aa07-be05d924603c", name: "Tatung University" },
    { id: "ad0ef105-daba-4c45-b873-63b5bdc01a69", name: "Taxila Business School" },
    { id: "0e69dc07-0813-411a-8f8e-49b6921044db", name: "Taylor University" },
    { id: "bf57a08c-5ff4-4687-b63a-7fbfe1c772c1", name: "Taylor's University" },
    { id: "d381e03b-ebf1-4b7f-8556-45a4001c9777", name: "TBC Univ Bkstr" },
    { id: "ad9f7092-539a-4eab-ad7f-41c14f82d19c", name: "TCI College of Technology" },
    { id: "1f45e79c-b4dc-4e66-9b4a-2d5c1b4c8cd5", name: "TCM International Institute" },
    { id: "770b2bf9-ac29-46fa-9de3-d5d7f921d857", name: "Te Wananga o Aotearoa" },
    { id: "51d047fa-8d17-4ad5-928a-c5e2b1fb125b", name: "Teach Plus" },
    { id: "aee3deb2-9082-4e9e-b12f-61cead152304", name: "Teaching for Change *" },
    { id: "e7713730-08ed-4ebc-92bc-1f809e9da0fb", name: "Teamworks Career Centre" },
    { id: "a8a73113-afc5-4443-a73b-80cf28a9cd96", name: "Tec Milenio University" },
    { id: "33663359-c183-4eae-933e-14c1ff2fa447", name: "Technical University - Berlin" },
    { id: "35b35a2b-adc5-4533-9da8-a451eff1a887", name: "Technical University - Sofia" },
    { id: "86625a43-597f-4ce0-87c5-185c3de4fe63", name: "Technical University in Zvolen" },
    { id: "a655b90c-7d98-4ce8-b1f4-95ee70969249", name: "Technical University of Applied Sciences Wildau" },
    { id: "f3aa43dd-6013-4d20-9aaf-43528b121208", name: "Technical University of Civil Engingeering" },
    { id: "86a1efe8-ef42-41a9-bc0a-ca4d1fa9bf4a", name: "Technical University of Cluj-Napoca" },
    { id: "71345fb8-2a1e-40a6-a2e4-b78c1cc6650c", name: "Technical University of Cologne" },
    { id: "23ee26f8-0d58-4e7e-953e-0ae954b63803", name: "Technical University of Crete" },
    { id: "a380af45-5411-4834-8466-89598ec69ec8", name: "Technical University of Denmark" },
    { id: "c2b522a1-2c2a-4bf4-94bc-48055eefb300", name: "Technical University of Graz" },
    { id: "ee8c0a67-1576-4178-8162-b764b16b83cc", name: "Technical University of Lodz" },
    { id: "4649390b-7db0-48b6-b5ca-56f4876a38e8", name: "Technical University of Machala" },
    { id: "da7b87f1-0ff6-444d-9b7d-2c3182cf70c7", name: "Technical University of Munich - Munich" },
    { id: "4fe16a26-4d3e-488c-a37f-001b978ca215", name: "Technical University - Braunschweig" },
    { id: "d5a0b213-abec-42a5-8624-40c258048fda", name: "Technion Israel Institute of Technology" },
    { id: "b25a9b29-3554-45cd-b102-dd93c6a298b2", name: "Technische Hochschule - Wildau" },
    { id: "742c8b93-d43d-4307-88ad-35b89fabcef7", name: "Technische Hochschule Ingolstadt" },
    { id: "19ff2cf9-0380-477c-a73d-ca488cec7108", name: "Technische Hochschule Mittelhessen" },
    { id: "e2b9e501-08de-4ef5-b663-eb9b8a6aefa3", name: "Technische Universitaet Braunschweig" },
    { id: "59bd54fa-1e9b-44a2-822c-03446e51734f", name: "Technische Universitat Darmstadt" },
    { id: "02a9ead7-8213-42b6-811a-ffe33543d01b", name: "Technische Universitat Kaiserslautern" },
    { id: "27127680-9ab8-4b2b-8e87-5ffe1ffeb492", name: "Technische Universitat Munchen" },
    { id: "5c175941-ce25-4d86-bf22-e864a6b196dd", name: "Techno India University" },
    { id: "1dc37422-3206-49f2-abcd-122588cc3fc6", name: "Techno Institute of Management Sciences" },
    { id: "233a0eeb-9787-4409-a8d2-a62a18c6f954", name: "Technocampus" },
    { id: "60d66190-29dc-45cb-9b63-07444ade8d32", name: "Technological Education Institute of Larissa" },
    { id: "892ab364-213c-4d69-ba5a-44d4f9177f89", name: "Technological Educational Institute of Central Macedonia" },
    { id: "d89bba0c-5a3b-44e6-8f7e-107793ac4add", name: "Technological Educational Institute of Chalkis" },
    { id: "4ca39bd1-6b4c-48c2-88b1-a2c2cead455c", name: "Technological Educational Institute of Crete" },
    { id: "2418f2b7-609e-4a5f-8daf-f9c11d4cb21a", name: "Technological Educational Institute of Halkida" },
    { id: "389a3ee0-812b-4038-b380-765bdfeeee4a", name: "Technological Educational Institute of Kavala" },
    { id: "3f518352-63a8-44dd-9e6b-26d208ed3460", name: "Technological Educational Institute of Messolonghi" },
    { id: "01504204-5a63-4004-a8d6-9c2768f0f05b", name: "Technological Educational Institute of Patra" },
    { id: "3952690e-47ae-4eac-b54a-7d374435831c", name: "Technological University Dublin" },
    { id: "aa2199c6-2f1b-4da3-979d-e5c2884ccc94", name: "Technological University of the Central Region of Coahuila (UTRCC)" },
    { id: "38975e8a-6985-48b3-bcfe-212aa57e8457", name: "TecnoCampus" },
    { id: "17e9ac94-3550-455a-9e42-1e078ae0114a", name: "Tecnológico de Estudios Superiores de Cuautitlán Izcalli" },
    { id: "536c81cc-b242-4a3a-ade6-bf25836ec3c2", name: "Tecnológico de Monterrey" },
    { id: "be0cedbb-0aa2-496e-b83a-c1c7be4c7005", name: "Tecnológico de Parral" },
    { id: "d530c7d0-1cdc-4111-aafd-efca74f98d56", name: "TED Universitesi" },
    { id: "7c26dce9-87af-4c90-984f-0f1eb6090c02", name: "Ted University" },
    { id: "397fca75-c2d4-4dd3-8c5d-eecf5ae6d454", name: "Teesside University" },
    { id: "8d693ead-0797-4422-ad18-cc0b1fb70b86", name: "Teikyo University" },
    { id: "f91433cd-4852-4465-bf16-9b00d1221491", name: "Tel Aviv University" },
    { id: "8effbdc4-c311-473f-b560-53af7a738489", name: "Tel-Hai College" },
    { id: "e3f0e50e-0398-420f-834a-afbd24849509", name: "Tele-Universite" },
    { id: "702581a0-a9f7-4c2b-8a89-cd96d3a26051", name: "Telecom Business School" },
    { id: "187cf424-b515-4448-9410-38f86d22f3da", name: "Telecom Et Management Sudparis" },
    { id: "fb7c19ca-ef50-478f-8805-0a3311aa641f", name: "Telecom Management Sudparis" },
    { id: "42f4346f-78b0-4cca-aee3-6db1c1352b45", name: "Telkom University" },
    { id: "42264b46-5d27-447a-86bc-b9daac2bfe43", name: "Temasek Polytechnic" },
    { id: "c614d1fb-1ad6-4337-aba1-897d2cdafbf5", name: "Temple College" },
    { id: "07075e9b-7600-423a-bce4-64e5ecf12b71", name: "Temple University" },
    { id: "2637a1a3-e552-41f0-9752-300e1320458f", name: "Temple University Japan Campus" },
    { id: "c16d4e43-d6bb-40e4-b3b2-e9fe0649587f", name: "Templeton College" },
    { id: "1a923071-b28c-4a23-b94c-8a3b367f215a", name: "Tenaris University" },
    { id: "38e14028-da8b-421a-990c-66f69ad3610f", name: "Tennessee State University" },
    { id: "35c7da08-5f68-40b1-a010-280e1f7608eb", name: "Tennessee Technological University" },
    { id: "3ca80be6-0b08-4b7b-8255-68a5ebd4af1e", name: "Tennessee Wesleyan College" },
    { id: "67992d32-5be0-4a68-ada1-c55c59432be3", name: "TERI - The Engergy and Resources Institute" },
    { id: "d59b467e-ff3c-4889-a76c-7f03a564bef6", name: "Ternopil National Economic University" },
    { id: "f941c869-4bb6-4a00-a788-81b85e116cb1", name: "TESST College of Technology" },
    { id: "037c660b-6cb6-43ed-80f2-84c69953ac5c", name: "TEST email account" },
    { id: "08fb7851-0a2b-453f-a9b3-158e9a86d139", name: "Teva Pharmaceutical Industries Ltd." },
    { id: "f48a7099-a0b2-43be-af10-5765bb5432c7", name: "Texas A & M Univ-Galveston" },
    { id: "7e14c0bc-705a-4156-906d-10bb66eff069", name: "Texas A & M University - San Antonio" },
    { id: "74a5c893-1b73-41c4-be43-d79624763763", name: "Texas A Chr(38) M International" },
    { id: "5700c7b5-8119-4824-9aa3-0aa0ec7b2d77", name: "Texas A&M International University" },
    { id: "3d37ec51-8e9c-4868-85fd-cc613288da0e", name: "Texas A&M University" },
    { id: "abe5ab49-115d-4748-b0f0-8173d1e17afd", name: "Texas A&M University - Central Texas" },
    { id: "c0628aa9-90d3-40d8-9e46-45e082b16407", name: "Texas A&M University - Commerce" },
    { id: "ad0b58c0-3b42-46b6-b711-3a92c02a380e", name: "Texas A&M University - Corpus Christi" },
    { id: "15fcc249-6cb6-44d7-af52-ac1d652886b5", name: "Texas A&M University - Kingsville" },
    { id: "29d8e0ff-39a6-4ced-9fb5-8667e26bb46d", name: "Texas A&M University - San Antonio" },
    { id: "c18e94ef-9163-472c-a5b6-1302a33dd810", name: "Texas A&M University - Texarkana" },
    { id: "6e7d6e42-1f45-429c-8aaa-586632f865cb", name: "Texas Christian University" },
    { id: "bd1b5735-4228-435b-aac5-acd5c4b268c5", name: "Texas Lutheran University" },
    { id: "158bd49d-a924-4e7c-bace-cb4761292d06", name: "Texas Medical Center" },
    { id: "79b245dc-4630-4647-96cf-20f03ca73070", name: "Texas Southern University" },
    { id: "cd048d00-7fa2-402f-bfa9-ea590de38002", name: "Texas State Technical College" },
    { id: "7dd5a388-684b-47e7-8a72-435811e01145", name: "Texas State University" },
    { id: "cccf90bd-5c22-4885-a7a3-c1ba9374439f", name: "Texas Tech University" },
    { id: "41ffc596-0915-4420-a604-6cbd331bd5b4", name: "Texas Wesleyan University" },
    { id: "5bb5ee9f-fe43-41b3-9819-99015a3c6d67", name: "Tezpur University" },
    { id: "7702416b-eb50-42e6-8669-e822fda97c29", name: "Tezukayama University" },
    { id: "2864611e-b414-4eb0-8ac4-0f6486347343", name: "TFPL" },
    { id: "0aaebec3-6841-4159-9adc-2656a1e03bad", name: "Thai Institute of Directors" },
    { id: "b2f92c80-777b-4349-88ca-846bff7c1a6d", name: "Thai-Nichi Institute of Technology" },
    { id: "491b214f-e116-4bde-a238-9e14b39718bb", name: "Thakur Institute of Management Studies and Research" },
    { id: "f5675dd6-5c4f-4046-b168-0611b4823178", name: "Thales Universite" },
    { id: "9b739b56-c3c7-412c-846a-e6d2b3cbb31f", name: "Thames Valley University" },
    { id: "e1279dab-041c-42fd-bfc5-b2036745d482", name: "Thammasat Business School" },
    { id: "61fa570f-f41b-4a8f-9782-15ccb3a04cd5", name: "Thammasat University" },
    { id: "f69c90ea-e8d8-400a-9820-30a0888dfc38", name: "Thanos Hotels" },
    { id: "de1af6f9-587f-4f99-8c5f-f5b45bcb3063", name: "Thapar University" },
    { id: "07774c6a-5e82-4b6b-8ea0-423a0b7947c4", name: "THD - Technische Hochschule Deggendorf" },
    { id: "ff79f8c2-c02b-4e12-86e1-0756fd3e8389", name: "The Academy at Harvard Square" },
    { id: "ee166811-8763-4798-92bf-01c572a5b9d5", name: "The Academy of Business Management and Entrepreneurship (BME Academy) at Northwest ISD" },
    { id: "87792711-0bf0-4acf-935c-60db9aace7f9", name: "The American Institute of Business and Economics" },
    { id: "31252f77-a97b-4f81-b037-afa3cc50c10d", name: "The Art Institute of California - Hollywood" },
    { id: "f9b25bde-2cdb-4280-a9ef-f4dd51768ac4", name: "The Art Institute of Washington" },
    { id: "59eb3860-436a-498b-bc37-f592fb4b3d94", name: "The Art Institutes" },
    { id: "00653183-881d-4dff-8359-02db85679271", name: "The Art Institutes International Minnesota" },
    { id: "a25b3ea9-88d5-4caf-a4a7-86cfb8f62185", name: "The Ashtabula County Technical and Career Center" },
    { id: "e0259b1c-4ec7-4f2b-a2d7-101abfd9b4f1", name: "The Australia and New Zealand School of Government" },
    { id: "ac6c0052-8adb-430c-abfc-4a95d594074d", name: "The Baptist College of Florida" },
    { id: "73b96599-e723-4361-b60a-2ba04278fff6", name: "The Boeing Company" },
    { id: "d066b44f-8969-4bba-95a7-b2814965c819", name: "The Bookmen Company Limited" },
    { id: "a32f50d0-fed4-4935-94f2-a1e00c4b5d5f", name: "The British College" },
    { id: "e3b353cd-e06c-4b7b-8ad6-d20dc631645d", name: "THE BROAD CENTER FOR THE MANAGEMENT OF SCHOOL SYSTEMS" },
    { id: "6841ba32-b454-4be9-8051-9e022bf2d224", name: "The Catholic University of America" },
    { id: "6bec5e3e-e81f-43ab-b30b-c7efcae0e7b1", name: "The Chartered Institute of Marketing" },
    { id: "b652588d-ee38-4a3e-bb3b-854ab249eb58", name: "The Chicago School of Professional Psychology" },
    { id: "96133963-abcc-42ca-91bf-2e333659e86c", name: "The Chinese University of Hong Kong (CUHK)" },
    { id: "15b50a11-71a9-43bd-a3a6-1aad3ad9f1cc", name: "The Chinese University of Hong Kong, Shenzhen" },
    { id: "c695c64f-d5e2-4d16-ba25-dda24208a4d4", name: "The Citadel - Military College of South Carolina" },
    { id: "18ea04bf-d89a-421c-8753-d9d8db7605e3", name: "The Claremont Colleges Services (TCCS)" },
    { id: "0bdc01ad-04e1-42d4-aa75-2b3014bc576c", name: "The College of Idaho" },
    { id: "b9b0a858-7508-4d3d-8c76-1ba8a5584529", name: "The College of Legal Practice Limited" },
    { id: "120bde84-7fe4-4d8a-a303-6203582e70dc", name: "The College of Management - Central Library" },
    { id: "cf89bbe5-865b-476c-8a3e-7e8bb2a67822", name: "The College of New Jersey" },
    { id: "3180ac42-4110-4a2f-998f-afb1f479083b", name: "The College of Saint Rose" },
    { id: "2040d385-4f76-424c-8a92-953926b2f2a8", name: "The College of Westchester" },
    { id: "805725a0-1d99-43dd-bd0a-c7292609f643", name: "The Continents States University" },
    { id: "3848b7d1-9138-4a12-9597-a22b02eae8d9", name: "The Cooper Union" },
    { id: "11c1897a-5cb1-4c22-ae22-bf68717aeaa8", name: "The Costin Institute for Osteopathic Medical Educators" },
    { id: "240d4ccf-67a0-4118-9262-3b2efcb71afe", name: "The Culinary Institute of America" },
    { id: "feb960bd-d7fb-4306-a9a4-fe1b726ead79", name: "The Directors College" },
    { id: "05601707-c76b-4b58-9f05-dbc5205dbe75", name: "The Eisenhower School" },
    { id: "397c169a-01d9-4a1d-94ad-d71e23b89485", name: "The Entrepreneurship School" },
    { id: "9bf0ff37-7b38-424a-824c-ca9529e89e07", name: "The Financial University International School of Business" },
    { id: "be368267-0e1d-449f-8724-f02ce83759aa", name: "The Flatiron School" },
    { id: "b919306d-70b6-404c-b69a-bfeacd6516ee", name: "The Gandhigram Rural Institute - Deemed University" },
    { id: "57dfa083-716d-4b91-b1cd-ad526b1b6233", name: "The Global Business School" },
    { id: "9d5f1d70-160e-4072-88e2-56e233fbe301", name: "The Glocal University" },
    { id: "41f36d9c-699e-4044-9f51-a60fb036be48", name: "The Graduate School of Public Administration and Public Policy (EGAP) at ITESM" },
    { id: "95ef69df-9ff5-4e67-9548-023baa8a521e", name: "The Hague University" },
    { id: "d2feed00-2182-49b6-9d39-4a6b3d20d2b5", name: "The Hang Seng University of Hong Kong" },
    { id: "fc5c76a1-b36f-41a6-9a69-a0d0eff1d352", name: "The Harvard Shop" },
    { id: "a71dac7e-90a6-4257-af57-9242d784a6fe", name: "The Hong Kong Institute of Education" },
    { id: "bd03117c-a39d-4f79-822c-ca15d95ef668", name: "The ICT University" },
    { id: "ca75c7f4-1a3c-4aa8-a51e-6aa1713b6cbd", name: "The Independent Institute of Education (IIE MSA)" },
    { id: "747e0236-6fde-4f60-9bcd-0c662605bbd4", name: "The Institute of Allied Medical Professions" },
    { id: "3dab8185-5731-453c-a374-1e4072e3f56c", name: "The Institute of Banking" },
    { id: "39d8ff46-43a7-47fc-b61c-4b65e7aae1d2", name: "The Interdisciplinary Center - Israel" },
    { id: "09d3b04d-cb78-42ef-9855-e8ced6c67d9a", name: "The J Paul Getty Trust" },
    { id: "3ae96b07-da22-4abf-8c48-da505525d636", name: "The Juilliard School" },
    { id: "35bff00d-a9f4-46c9-ab92-91c7f20dec09", name: "The King's College" },
    { id: "6a0eb62d-25c0-470d-a260-0281e6747c08", name: "The King's Language Institute" },
    { id: "6f47395a-7c5b-42c8-ae06-57d181cf3797", name: "THE KLU - Kuhne Logistics University" },
    { id: "024d5992-f788-43be-92c8-ecba41881fd8", name: "The Kyushu-Asia Institute of Leadership" },
    { id: "aa77ddcf-00a9-4659-8ba3-ad85e93fc172", name: "The Language Academy" },
    { id: "e51b2d1a-3315-4394-9c37-21fa90551db3", name: "The Lawrenceville School" },
    { id: "9093cade-4ac3-40ba-8e2a-907316be071f", name: "The Learning Lab" },
    { id: "67f371d0-3d64-454e-8ae9-f985f75f18f7", name: "The London Institute of Banking & Finance" },
    { id: "b52cd250-4a98-4a8a-bf54-2ddfa5d1319a", name: "The Los Angeles Film School" },
    { id: "c5182eeb-c8bb-4dbc-ae68-2b81aac527cd", name: "The Malcolm McDonald Academy" },
    { id: "71b6d355-70ca-4d18-8e7e-458c6ee7d93f", name: "The Master's University" },
    { id: "8841cc83-71b8-4b6a-b7f4-26d0d78587f0", name: "The New England Institute of Art" },
    { id: "61a5a5f8-8856-4fb1-bb78-be84edf50d70", name: "The New School" },
    { id: "40a20e53-e182-4ac4-b95c-29aaef9e0224", name: "The Northern Sydney Institute" },
    { id: "09b2f20e-41d3-480d-b5c0-f3cb078c6bea", name: "The Ohio State University" },
    { id: "1f8ef4b3-82b1-409c-babd-51ef5e2d51a4", name: "The Open University of Israel" },
    { id: "417c96db-f595-407a-adfe-e4aaf29b322e", name: "The Oxford College of Business Management" },
    { id: "52545a8b-bff5-4d1a-873b-052711c17d4a", name: "The Pennington School" },
    { id: "c9616d86-36ae-4189-92e8-f2229f8e7b51", name: "The Pennsylvania State University" },
    { id: "71e84cef-d770-4b2b-a26f-7b26e5261f41", name: "The Phillips Exeter Academy" },
    { id: "80fee116-10ef-4d87-ad58-6e3677c572cb", name: "The Potomac School" },
    { id: "b490a392-11c5-41c2-8a05-e94dbfbb6a73", name: "The Rivers School" },
    { id: "272ce58a-aaea-49a1-9c69-016bf0001bde", name: "The Rockefeller University" },
    { id: "3c9e0765-ce36-4b79-a101-5dcea8a22c83", name: "The Royal College of Physicians and Surgeons of Canada" },
    { id: "2fde7bc1-efcb-4c41-845b-b704c9bd8c31", name: "The School of Pharmacy, University of London" },
    { id: "5879495b-dc7a-4a91-b926-ff06a94040ce", name: "The Shipley School" },
    { id: "f93b9d15-94b4-4a47-a46c-5989eee7d1fc", name: "The Textile Institute of Pakistan" },
    { id: "2ef6af53-a141-4063-87e8-04c7f9468ec5", name: "The Tikvah Fund" },
    { id: "ebb2fea7-a05a-42a9-b5d6-ecc0dc3b4fcd", name: "The United Methodist Publishing House" },
    { id: "46270361-6f83-43ae-b942-1c9d97c5f3ed", name: "The Univ.Club of Washington" },
    { id: "f66e8b65-6633-4abb-acee-4b01b7413fa8", name: "The University of Alabama - Tuscaloosa" },
    { id: "d7548037-838b-4481-a155-fa85e0d17766", name: "The University of British Columbia - Okanagan" },
    { id: "8f3c197a-16da-43f2-963c-4a937f001167", name: "The University Of Da Nang" },
    { id: "1c17e2ff-70c8-47f8-8af8-0ee2f643c927", name: "The University of Electronic Science and Technology of China" },
    { id: "23e43b5a-8bd9-4614-90c0-c499e787f363", name: "The University of Hong Kong (HKU)" },
    { id: "d63c7270-893e-47a8-a3e3-60b8707a571b", name: "The University of Hong Kong - Faculty of Business & Economics" },
    { id: "363861af-3cac-4058-be85-ad464528fdf4", name: "The University of Jordan" },
    { id: "1c3b6afd-1d54-4d37-b1f7-0b0ed3fb298c", name: "The University of Kansas" },
    { id: "0ac1a509-6889-42a2-948a-51a67c735f7b", name: "The University of Mons" },
    { id: "9b94ef2c-ad84-4b08-b73b-95acf2f0c1b8", name: "The University of Nottingham Ningbo China" },
    { id: "80f833ad-e7e0-4832-9163-768f24f5a40d", name: "The University of Tennessee - Knoxville" },
    { id: "08e66263-3bf2-48d8-a74d-f2f7575862d8", name: "The University of Tennessee Space Institute (UTSI)" },
    { id: "30fbffb8-f7da-447c-93dc-00384831dd49", name: "The University of Texas - Pan American" },
    { id: "378f5a24-f25d-4e82-beb2-dee3b127f811", name: "The University of Texas at El Paso" },
    { id: "d353c85d-3eec-4a89-9337-5a873270ed94", name: "The University of Texas MD Anderson Cancer Center" },
    { id: "1ae80bf4-edbd-4379-9be4-c743a1340276", name: "The University of Texas of the Permian Basin" },
    { id: "deb7ccb2-1aab-47c7-8c65-99978d63af9b", name: "The University of the Arts" },
    { id: "1fd6dd91-4463-43d7-b037-fa8c58a55b42", name: "The Valley Digital Business School" },
    { id: "00275c7b-9db0-4a53-b0d3-3f4a77a379c1", name: "The Washington Campus" },
    { id: "47bcd57d-148a-43a2-bb81-91bdca61fad1", name: "The Washington Center" },
    { id: "8125075a-052e-4da4-9766-f6a0ae6ac245", name: "The Webb Schools" },
    { id: "da6dc4ff-e6ce-475e-88be-019c0f3c7e9a", name: "The World Bank" },
    { id: "1d83d2d1-b1b9-4cfc-9306-12f03dfedf86", name: "THEMA - Université de Cergy-Pontoise" },
    { id: "a310cb18-7df1-4b9b-8287-ca3c28aba6e7", name: "Thiagarajar School of Management (TSM)" },
    { id: "c2d850e1-0bf6-4e0a-8171-86e26c755474", name: "Think Education Group" },
    { id: "e4475994-9993-44f3-9b35-aaf168ae36be", name: "Thomas A Edison State College" },
    { id: "0147d0dc-4f9c-4d70-a811-328ef5641d82", name: "Thomas Jefferson University" },
    { id: "830218e0-7ffe-41ba-900b-202370d90aa9", name: "Thomas Media Consultancy" },
    { id: "400a7235-9fb0-4116-9ceb-97dab63f68a7", name: "Thomas More College" },
    { id: "b3559d34-c403-469b-b6bb-0ba2585c59df", name: "Thomas Nelson Community College" },
    { id: "c7eac8d2-dace-43c2-b3aa-3851da05976d", name: "Thomas Reuters" },
    { id: "375a323d-67bd-439f-b48b-59d02fe11105", name: "Thomas University" },
    { id: "c74056e5-2efe-4b91-b77b-0b27c436d09b", name: "Thompson Rivers University" },
    { id: "c86694df-9407-4e25-ac15-56003e69c69d", name: "Three Rivers Community College" },
    { id: "f6bdef59-e356-4769-9a04-2e794bc09057", name: "Thrive Leadership School" },
    { id: "42feb4a2-7029-4055-a7e9-8db989d06390", name: "Thrivent Financ for Lutherans" },
    { id: "83f95400-1860-4e58-b39b-314b95101147", name: "Thunderbird Europe" },
    { id: "031cd3f2-4925-4f74-8c99-4960bf67d3d8", name: "Thunderbird School of Global Management" },
    { id: "1dd97deb-b8d9-42bf-93a9-adaa9a82a692", name: "Thurrock & Basildon College" },
    { id: "817baac0-707f-40ad-a6bf-12dd7565fbc1", name: "Tianjin University" },
    { id: "c65948f1-cff5-441f-b197-b9f617bec690", name: "Tianjin University of Finance & Economics" },
    { id: "62db6fce-0d14-49b7-b6c6-af504bb9da6a", name: "TIAS School for Business and Society" },
    { id: "28d01566-9451-4dac-934b-7af556f57c1e", name: "Tichenor Publishing" },
    { id: "d31a0760-7d45-4290-8d57-5894cedb9143", name: "Tidewater Community College" },
    { id: "6c072ade-c37d-49dc-a3a4-fb271028e599", name: "Tiffin University" },
    { id: "f96e6b80-f842-4f9a-bbe5-9cadd10c71d6", name: "Tilburg University" },
    { id: "7751b43b-0931-4bdd-a7da-124174b336eb", name: "Timoney Leadership Institute" },
    { id: "b22aeae7-cd03-4bde-b16e-2b751bc71327", name: "Tipperary Institute" },
    { id: "9a21661f-bf05-47cf-8994-4d37d967f05f", name: "Tischner European University" },
    { id: "e6878c5f-5d59-4e2b-96d2-34d02235d140", name: "TKK DIPOLI" },
    { id: "75281dfa-458f-4638-9198-dcc19097383a", name: "TKM Institute of Management" },
    { id: "bd9f77f7-08e5-43d3-bb71-eb468eeac83c", name: "TLBU Graduate School of Law" },
    { id: "63c0c71e-090d-42a8-8218-c3b26680c1c7", name: "TOBB University Of Economics And Technology" },
    { id: "d57e0644-e568-49ae-bef0-bbc46debcffc", name: "Toccoa Falls College" },
    { id: "fa0b229a-4bb6-436c-bc09-b778b335a8d4", name: "Tohoku University" },
    { id: "8f53edb5-58d3-4720-a162-548515e25cfb", name: "Tokyo Electric Power Company (Tepco)" },
    { id: "e5a93a95-e1f9-4fa5-bc9b-62bbddb077b8", name: "Tokyo Fuji University" },
    { id: "36249fa0-c258-47d9-8744-747fb9c7d3d7", name: "Tokyo Institute of Technology" },
    { id: "8f43b54b-9b1c-42cf-afdc-5a46ff77cddb", name: "Tokyo International University" },
    { id: "3ac31a9a-10e1-4f18-823f-e933f29daf58", name: "Tokyo Keizai University" },
    { id: "d0069551-d029-4a06-866c-f1ab07393255", name: "Tokyo Medical and Dental University" },
    { id: "485a7e84-5926-4802-8ff7-38384f48f559", name: "Tokyo Metropolitan University" },
    { id: "7afea3db-f519-4c27-90b3-2c2a3d521ce2", name: "Tokyo SME University" },
    { id: "7355f867-a169-4539-9540-054be4dae599", name: "Tokyo University of Agriculture and Technology" },
    { id: "c9adf6b9-e5b8-4899-a14e-22969200f491", name: "Tokyo University of Science" },
    { id: "2dd8615b-6439-47d8-8e35-2a2995ccac7f", name: "Tokyo University of Technology" },
    { id: "543c7b8d-c4c4-4670-a0a5-2216d5b0182b", name: "Tolani Institute of Management Studies" },
    { id: "f453f158-8745-4667-b8d1-ca983b0e8390", name: "Tomaree Community College" },
    { id: "983579d2-82e8-4b4a-b351-644ad0b432c6", name: "Tomas Bata University" },
    { id: "beea302c-7d99-4664-9715-3b50aae14521", name: "Tomorrow University Of Applied Sciences gGmbH" },
    { id: "f08b1aba-897b-4ad7-b7b7-dc01b60dde52", name: "Tompkins Cortland Community College" },
    { id: "0a0f7f6a-1ace-4023-9d98-90cec4c04c16", name: "Tonawanda City School District" },
    { id: "6b1d3f54-8057-4ac3-9842-b882b55bb8bd", name: "Tongji University" },
    { id: "90d2a795-c9df-4385-ba19-968ae5866e6e", name: "Tongji University - Shanghai International MBA" },
    { id: "e4d6da80-7778-4f4c-8b0e-33b5ad0c61bc", name: "Tongji University Mannheim EMBA" },
    { id: "51100484-190e-4191-a5ca-f569d07e2e0d", name: "Top Education Institute" },
    { id: "d18f9fcc-794a-4451-ae04-b1f6ea68b7c2", name: "Toronto Metropolitan University" },
    { id: "c0e61363-b9fc-49a3-8351-d4da7b48f374", name: "Torrens University Australia" },
    { id: "163a71ee-0585-403a-991a-ec2fb347a4a4", name: "Tottori University" },
    { id: "c63ff248-d51c-4009-801a-c41ab1b3b12d", name: "Toulouse Business School" },
    { id: "8809a5f5-ca8b-4d1a-8c9a-e57a3c7a986a", name: "Toulouse National Polytechnic Institute ICSI" },
    { id: "d50fb7ca-22f5-43b0-a8aa-45c1ca44622e", name: "Toulouse School of Economics (TSE)" },
    { id: "0c4851e7-d0ab-497d-95ac-3d1fc2ea2ab2", name: "Toulouse School of Management" },
    { id: "8f7f6a2a-63b5-4a25-896d-239c1e03b8dc", name: "Tourismusschulen MODUL" },
    { id: "d8a8e3f2-5b16-44c3-ac10-bdd59c29d622", name: "Touro University Worldwide" },
    { id: "ec2548d2-5eb3-4eeb-8c6d-64e6738504ed", name: "Town of Addison" },
    { id: "623a996a-697e-4b06-9851-34c7b5775e7a", name: "Towson University" },
    { id: "957ff569-b58f-4482-89a7-c54306da9870", name: "Toyo Gakuen University" },
    { id: "3ed6cfd1-763b-4cba-9d6a-4d27f22ca37b", name: "Toyo University" },
    { id: "4fc227a0-ad15-4fce-9bb1-fb4f44c53c9a", name: "Training Vision Institute Pte Ltd" },
    { id: "7ca3412d-8eb9-44f4-aaa5-00742edb61a9", name: "Trakya University" },
    { id: "4592a187-49e4-4fd7-aec1-2f6dacb0a243", name: "Transfer Account" },
    { id: "b2d59d44-c442-4595-90ea-ed33ab4ffadf", name: 'Transilvania" University of Brasov' },
    { id: "45d0e795-267a-428e-ba59-400bf439399c", name: "Transylvania University" },
    { id: "6e46797a-7950-4397-8a67-6b90828d66ca", name: "Travel University Internation" },
    { id: "2285f3cd-5bc2-4490-b040-6f8d2d086fd4", name: "Trent University" },
    { id: "69ba4262-06c5-4195-a15c-dd59362d47cc", name: "Trevecca Nazarene University" },
    { id: "a1a813b9-7281-44ce-9623-8395b6990863", name: "Trevisan Escola de Negócios" },
    { id: "742e019b-3990-443e-9882-b69a3ffa229c", name: "Tri State University" },
    { id: "466195cb-1de4-46aa-b23e-f8fe40eeb473", name: "Tri-County Technical College" },
    { id: "604ebc7f-75c5-428f-b050-3945aa85cc12", name: "Tribeca Flashpoint Media Arts Academy" },
    { id: "7fc3cd21-fd4d-470f-9982-1a2f2977f5b2", name: "Tribhuvan University" },
    { id: "d5774e92-1d28-48f9-9072-3f84ac28849c", name: "Tribhuvan University of Nepal" },
    { id: "49a1aaa7-468e-4d0a-87d5-64985dcb86f1", name: "Trident Technical College" },
    { id: "7b4d82f4-fee8-401a-b39b-042ddec79721", name: "Trident University International" },
    { id: "e11c6d77-83fb-4340-b3b3-8b00001a65f0", name: "Trier University" },
    { id: "1846a80e-2fc3-4b8f-ab95-0e8f05099481", name: "Trine University" },
    { id: "804513a3-1488-49ae-8cde-0c5c16a9a87a", name: "Trinidad State Junior College" },
    { id: "0ffc9788-6c2d-4106-95a8-4c4df743a446", name: "Trinity Bible College" },
    { id: "07f5e3f6-9714-443c-85bf-3061ee4ace62", name: "Trinity Christian College" },
    { id: "c97d90e9-e158-4d22-9864-f2e9d404b897", name: "Trinity College" },
    { id: "382ffd44-5d2b-412a-a5de-a395db53ba83", name: "Trinity College Dublin, The University of Dublin" },
    { id: "54c27f2e-14fb-4d2e-8fa0-966d4dca9656", name: "Trinity College of Florida" },
    { id: "f83531ad-fac9-4bc4-be4d-b0ba2a2cdfb1", name: "Trinity International University" },
    { id: "37744683-ae4d-49ad-87be-1c91ba6bbae6", name: "Trinity Lutheran College" },
    { id: "2e38998d-e681-4c1b-b113-87f9c0a3385c", name: "Trinity University - Texas" },
    { id: "a30b7e54-bde2-480e-aab4-a97ebb6430a2", name: "Trinity University of Asia" },
    { id: "98cf0f4e-857c-4810-b8a4-87e9ed3cc0a3", name: "Trinity Washington University" },
    { id: "c2183f1f-0bf9-4830-9f9c-c1646f605cac", name: "Trinity Western University" },
    { id: "63cccbff-9ab6-4b89-b0fc-138f72e650db", name: "Trinity Worldwide Reprographics" },
    { id: "6404fa8f-a177-4e12-b422-96b90172bfa1", name: "TriOS College" },
    { id: "bb16a614-cf3c-420b-a2ab-e5a0f20e673f", name: "Trisakti University" },
    { id: "40f898fe-8b41-423a-81aa-358003522b27", name: "Triton College" },
    { id: "cb8c7d7e-45d6-45a9-a87c-4accce19eb48", name: "TRIUM" },
    { id: "a477676b-6512-4edb-a744-c04440d7e552", name: "Trocaire College" },
    { id: "b6a0e69e-5690-4820-b38f-ae38e73c5416", name: "Troy State University - Atlantic Region" },
    { id: "7383e6cd-3a17-49c9-81fe-412e434a17c2", name: "Troy University" },
    { id: "311d05fe-4823-46e7-a218-67cd486ea812", name: "Truckee Meadows Community College" },
    { id: "cd5e7b8d-952b-4731-bfe2-e04621303348", name: "Truman College - City Colleges of Chicago" },
    { id: "4b5b7ae9-8c84-453b-8998-b509ac7e8e2b", name: "Truman State University" },
    { id: "3cd60f47-3c8a-4d31-a580-1bdf99cfe29e", name: "Tshwane University of Technology" },
    { id: "c70ecead-e357-4272-ac91-20d4899bbcbd", name: "Tsinghua University" },
    { id: "bd46c577-852f-412b-bce1-876ddd19bb96", name: "Tsinghua University, Schwarzman College" },
    { id: "05516705-fb7d-4a1a-bedb-9a629175284f", name: "Tsinghua, PBC School of Finance" },
    { id: "5d6599a3-cd9e-456d-aa64-6908ab10ee25", name: "TSM Business School" },
    { id: "968feecf-1943-4cb3-955f-865b247424e4", name: "Tsuru University" },
    { id: "9298cd12-f556-4317-8dcb-09d9cc5fa118", name: "Tu Bergakademie Freiberg" },
    { id: "11bb670c-c8c5-4f86-8108-a70c24c8ba1b", name: "TU Clausthal" },
    { id: "0ef6fe52-c927-4110-9250-6424f6962c20", name: "TU Dortmund University" },
    { id: "5225fd9a-ad28-46d5-bb49-3baaae577afe", name: "Tufts Medical Center" },
    { id: "0ab1bb9a-db23-4b2d-8f43-5c2b743e79d8", name: "Tufts University" },
    { id: "c6345898-615e-4078-9734-33979722f06d", name: "Tulane University" },
    { id: "d7f8a528-43f9-4c38-9e70-a493dc25eb46", name: "Tulane University - School of Public Health and Tropical Medicine" },
    { id: "5fb15161-c8ea-4434-b42e-e1a319b7d4e4", name: "Tulsa Community College" },
    { id: "f0be2723-026d-4f47-b919-e47b6cd8cd2f", name: "TUM Asia - German Institute of Science and Technology" },
    { id: "46614a11-4ee6-4596-8bc8-e08fbc298a11", name: "Tung Wah College" },
    { id: "c14612c8-c886-499c-81dd-203723702b5b", name: "Tunghai University" },
    { id: "d3e4eb27-5b4a-44a0-8a86-73a247edf3fb", name: "Tunku Abdul Rahman University College" },
    { id: "84f4af16-12e9-4570-9914-23d0579fb8a9", name: "Turgut Özal University" },
    { id: "07c95eb0-56d3-4858-b945-f77beab03078", name: "Turiba University" },
    { id: "db48b9f8-9d85-4fda-9d39-c3ffc9b274df", name: "Turk Hava Kurumu Universitesi" },
    { id: "2cf1a8c1-2bbd-4e67-b8c3-27bccebadfa5", name: "Turkish National Police Academy" },
    { id: "dc81edea-8db5-45ec-a8a7-0c5448710fd5", name: "Turkish-German University" },
    { id: "8e524f6c-2d28-42b3-8486-0c5846c32742", name: "Turku School of Economics Bus Admin" },
    { id: "b276f221-5326-4dd4-b4b8-b2dab8f64cb2", name: "Turlock High School" },
    { id: "ac4bbe95-f06a-457a-bfb5-f510ae35cbc1", name: "Tusculum University" },
    { id: "da4398ce-b97e-41a2-9388-e9fe3e95c1b4", name: "Tuskegee University" },
    { id: "0385fc47-3896-48d5-bbca-2d5936ce64ee", name: "Tyndale Theological Seminary & Biblical Institute" },
    { id: "cc9699d6-0b72-416f-89f9-27599e930c4c", name: "Tyndale University College & Seminary" },
    { id: "c797850d-0a3d-447f-98b3-919ce6f888f0", name: "Tyngsboro Campus" },
    { id: "3cefd7d3-3722-42f3-9319-8e616c1653ea", name: "U of M Executive Educatio" },
    { id: "8cfd5f55-4e7e-4dcb-b2f6-c8d9fd1d5197", name: "U of Miami" },
    { id: "fd2ed147-473d-49b2-99f6-17cf5cf0c5f1", name: "U.S. Army Command and General Staff College" },
    { id: "9c0a5385-11d2-4452-a796-338c0392482a", name: "Ubiquity University" },
    { id: "158e6cb4-409d-4892-9073-e1317bfac3fe", name: "UBIS University of Business and International Studies" },
    { id: "aaff45e0-db9c-4b8e-acd8-9e480d88a3eb", name: "UC Berkeley Extension" },
    { id: "15aa05b2-9119-46b4-bcd0-353e971ce2d0", name: "UC David - Department of Family and Community Medicine" },
    { id: "422298c5-488e-4129-b576-d7d07dd70ffd", name: "UC San Diego Extension" },
    { id: "12f07f7a-1d8e-4e56-8df3-e67a764ce10a", name: "UCAM Universidad Católica San Antonio de Murcia" },
    { id: "05f7a0da-4b03-4deb-9fcf-d70aa35e32ce", name: "UCI Bookstore" },
    { id: "a2cb9702-45e5-4ea5-b7db-a73101bda30a", name: "UCL - Louvain School of Management" },
    { id: "43b06bb9-a46f-417b-a2d2-a48abbedb228", name: "UCL Library Services" },
    { id: "02ce7002-4d56-4124-a0cd-7956d6434a29", name: "UCL Shcool of Energy & Resources Australia" },
    { id: "b5a971d8-2ab8-4673-a502-60704d498d8a", name: "UCLA Extension" },
    { id: "17b84089-08ed-4e3c-a818-495bba258c0d", name: "UCSC Silcon Valley Extension" },
    { id: "469d446f-8f02-42dd-a49e-9ed8c82312d5", name: "UCSI Education" },
    { id: "57bea3b9-0f9a-41c8-84ad-880afd962ba6", name: "UCSI University" },
    { id: "7daf936c-9ad0-4c89-9fd0-57b57f5aa180", name: "Udayana University" },
    { id: "5c74873d-0619-4353-bd93-0ff937ada42c", name: "UDLA Universidad de las Americas" },
    { id: "dcedeac5-d9ec-4b7e-a1e1-c65a10701bc0", name: "UDLAP Universidad de las Americas Puebla" },
    { id: "11077eac-1695-4f41-998d-c3647404781b", name: "Ufa State Aviation Technical University" },
    { id: "54e9bc58-ad1a-44b3-b0b9-1e4ea17ccd29", name: "UFRS School of Management - Colegio de Aplicados" },
    { id: "0e5390a8-6d0b-4db6-96c9-abac67e43898", name: "UHS - University Hospital" },
    { id: "2f54d7c2-ea5c-4a4b-9fad-43775d5d70e9", name: "UIBE" },
    { id: "ca1edf2d-a2e4-4d85-aeba-f768f0b1d701", name: "UiT Norges arktiske universitet" },
    { id: "09613b95-6c70-45da-b1c0-b773c3581750", name: "UJCV - Universidad Jose Cecilio del Valle" },
    { id: "3369834c-163c-4a84-9c8d-5598b7badbca", name: "Ukrainian Catholic University" },
    { id: "69ff29a0-3087-4ef9-974c-c25f53a6378b", name: "Ukrainian Corporate Governance Institute" },
    { id: "1a879ad6-a258-4c44-b860-5e89ee95f8eb", name: "Ukrainian-American Concordia University" },
    { id: "87c08072-73d4-40ee-b070-cb4696e3c7f5", name: "Ukrainian-American Liberal Arts Institute Wisconsin International University (USA) Ukraine" },
    { id: "091f5652-8644-4bd4-9f5a-8d69c18929f7", name: "Ukranian Corporate Governance Academy" },
    { id: "d9470817-a507-4d11-a58d-005e5b8687f4", name: "ULACIT - Universidad Latinoamericana de Ciencia y Tecnologia" },
    { id: "986c5fcd-ffb6-487f-90b0-6e7478e0ed52", name: "Ulm University" },
    { id: "c9367b92-6f9a-4e36-81a2-dced1ce952da", name: "Ulsan National Institute of Science and Technology" },
    { id: "739622ea-4e1f-4155-958d-9253ed3c3b08", name: "Ulster BOCES" },
    { id: "d3aedade-6068-4f20-ac11-495718c17d58", name: "UMC Utrecht" },
    { id: "83ecc093-40bd-44bf-bbd6-2c92972c7e16", name: "Umea School of Business and Economics" },
    { id: "62d9314f-ac16-4d12-9e52-25fb792afa26", name: "Umea University" },
    { id: "d4fe6f9b-e22b-4128-a894-56c3620990a0", name: "Umeå universitet" },
    { id: "1c2e8587-ed99-4584-931e-450d75af44bc", name: "Umf Store" },
    { id: "e762342f-0cc2-493b-b769-01b7933d3f30", name: "UMIT - Private Universität für Gesundheitswissenschaften, Medizinische Informatik und Technik" },
    { id: "75c96ccd-9f55-40e3-b9d1-c02f596c56a1", name: "UMIT Health and Life Science University" },
    { id: "477d34e4-452b-42b5-9aa9-374bc0247e64", name: "Umpqua Community College" },
    { id: "9a06b6dc-864e-4649-b236-a1cd3676325b", name: "Umpqua Community College Bookstore" },
    { id: "d7e02ef7-2a20-483b-9a2f-968369206ae5", name: "UN System Staff College" },
    { id: "05b55515-f7ce-4d88-939f-cce3b1d37e96", name: "UNAD Florida" },
    { id: "e09b9f7d-ec4a-4773-92e3-47b5cb7b297c", name: "UNC-Wilmington Printing Services" },
    { id: "29554e59-0f4d-4679-b4a3-8fba931c5d68", name: "UNED" },
    { id: "057a7304-d5ec-4ca8-8600-25900315084e", name: "UNESP - Universidade Estadual Paulista" },
    { id: "0806e13a-8d59-4213-90c8-1c4c7e6281ac", name: "Unext" },
    { id: "8208929b-671b-4d5f-b801-9cc66088b287", name: "UNF Library" },
    { id: "24240ee9-15c6-42a8-a42a-ef7512ee8d88", name: "Unfolding Sprl" },
    { id: "257ad03f-79e0-4a0c-b6a7-2e82802876fa", name: "Ungku Omar Polytechnic" },
    { id: "416cfe59-4fc3-4d9f-a307-d764de257bc3", name: "Unicatolica - Fundacion Universitaria Catolica" },
    { id: "f05280dd-7f9f-440e-baf5-e0d38d41aab0", name: "Unicatolica Quixada" },
    { id: "a9cde14f-5058-4c51-bc99-4ff1af8aeaf2", name: "UNICENTRO - Universidade Estadual do Centro-Oeste" },
    { id: "4d49580b-3274-4172-8a00-e1fa65fc8196", name: "Unicesumar" },
    { id: "fdb8ade4-9abb-42b0-8324-3b68d5f418e1", name: "UNIDA - Universidad de la Integracion de las Americas" },
    { id: "efe7fa41-0c36-471a-a7b6-6dada8723fe1", name: "Unidad Central del Valle del Cauca (UCEVA)" },
    { id: "89f03cc9-5bd6-431c-a9e0-0dc5e2ee2674", name: "Uniempresarial" },
    { id: "59b7db69-10e0-460f-b3b4-9ebad83e6fcc", name: "UNIFACS" },
    { id: "57456351-655f-4fb5-b5c5-ba6345e99206", name: "UNIFEI - Universidade Federal de Itajuba" },
    { id: "4311099d-8545-4b3f-b0ca-5b0070a5bda3", name: "Unifieo - Centro Univ.Fieo" },
    { id: "2130b90c-7e1a-47c9-a340-d79c8d988553", name: "Uniformed Services University of the Health Sciences" },
    { id: "db14f115-c68b-47c1-a140-96f12130a25f", name: "Unijorge" },
    { id: "323befc9-bad1-4f3d-a0ba-162eaa0c5e29", name: "Unilatina UL School of Business" },
    { id: "5882cf24-9c8c-451d-9eca-a4b34f44dd0e", name: "UNIMINUTO" },
    { id: "5cc4c757-1cf6-4432-bd3e-8c193e1dd287", name: "Uninove" },
    { id: "de7a1aae-dcdb-4172-92f0-1d1904c676d7", name: "UNINTER" },
    { id: "f045b7fc-a732-4cfa-a5a7-422bccfad24a", name: "Union College" },
    { id: "80b0f551-7861-404b-ac2b-982068316b31", name: "Union Institute & University" },
    { id: "40fff99a-408d-4415-a06e-8bfc055ffda9", name: "Union University" },
    { id: "6ae3a45f-ab6a-4832-8984-f6beb01c5a1b", name: "UNIP Universidade Paulista" },
    { id: "2067336e-ad6d-43c9-a170-9c7631df8af0", name: "Unipart Group of Companies Ltd." },
    { id: "a52031d3-155b-4209-b83d-8891c3062e0f", name: "Unipe" },
    { id: "e0218832-214d-4b9a-84a8-2dae54d2bd8c", name: "UNIPID - University of Cultural, Technological and Digital Innovation" },
    { id: "76b0515e-0d56-41da-861e-7b34cdb617d2", name: "Unisinos" },
    { id: "25dd72d7-6224-4ab5-bc13-1d5818f4cbde", name: "UNISON" },
    { id: "c151bd98-8973-4c73-acbc-5bf8076d4cef", name: "Unisport Universitas" },
    { id: "43019b2e-baf9-47ef-81c0-5a9c6c5bb359", name: "UNIST Sinmungo" },
    { id: "86683920-c0e2-49ec-a040-fe20d5dd2510", name: "Unitar International University" },
    { id: "b7240b34-25b0-42c5-becb-1ea427af3f16", name: "Unitec Institute of Technology" },
    { id: "5fd1a8d5-7602-4f77-9a72-62b2eb53938c", name: "UNITEC: Universidad Tecnológica Centroamericana" },
    { id: "7ffe6853-7c42-4028-bd90-7e31b3ca9b38", name: "United Arab Emirates University (UAEU)" },
    { id: "dfebdbc0-00d6-4e1d-add3-7c3a258fc4bf", name: "United Business Institutes - Brussels" },
    { id: "6429446c-1e39-49fe-aa70-7c2373f4a762", name: "United International Business Schools" },
    { id: "8548a453-12a6-4afa-8f51-60019c982b7c", name: "United International Business Schools of Spain SA" },
    { id: "62ee7c94-88c2-4b53-be11-0ca0884f72c0", name: "United International College" },
    { id: "c5097a18-83c9-4114-bd83-e1ba43e6db2b", name: "United International University" },
    { id: "0536f6cb-8901-480d-be3a-2cc1cde129d6", name: "United Nations University" },
    { id: "aa0bc662-bb95-41ca-bb86-e3f1ca5cd989", name: "United States Air Force Academy" },
    { id: "8f460be0-aa1d-4289-b122-ff6a36e6dc31", name: "United States Air Force War College" },
    { id: "8740547f-21ca-4d60-aca1-1020dd5079f8", name: "United States Army Combines Arms Center" },
    { id: "44140338-f815-42c4-bade-1e6ea34d9219", name: "United States Coast Guard Academy" },
    { id: "9864f711-4d38-4442-a984-f1b04899357a", name: "United States Defense Information School" },
    { id: "5cca556b-2f5e-46c6-bc1b-628480c65779", name: "United States Department of Labor" },
    { id: "a26431eb-2116-4ef2-bb20-5aa4bea16f0d", name: "United States Fire Administration" },
    { id: "fd0cc0d9-1948-4a63-9169-a6b02988dbcd", name: "United States Military Academy" },
    { id: "98cfb1df-7465-41a0-9d59-4f9638f04920", name: "United States Naval Academy" },
    { id: "597b37ae-a68d-44f5-bae3-91316a025dd0", name: "United States Sports Academy" },
    { id: "5081061c-f829-4ea7-9d43-4bacb99739f7", name: "United States University" },
    { id: "2451db5f-a376-4842-8479-e0e39d6aefa1", name: "United Technologies Center" },
    { id: "2b6ebad8-4af1-4123-861e-ec6a13b0c551", name: "United Tribes Technical College" },
    { id: "24e71989-64f2-4f24-9713-b040a9163747", name: "United World College of South East Asia" },
    { id: "10451316-6f98-4dcc-986e-4737de092650", name: "Unitedworld School of Business" },
    { id: "9a1efdd0-4027-440f-be62-69d98c383207", name: "UNITEN - Universiti Tenaga Nasional" },
    { id: "758187f9-d744-48a4-b728-1112640fb85c", name: "Unity College" },
    { id: "839dbddc-af4c-4695-8010-0ba0325ca497", name: "Univ Library of Bozen/Blozano" },
    { id: "6e99d67a-ce18-4d8b-b963-3c82d92a8a6e", name: "Univ of Applied Sci Northwestern Switzer" },
    { id: "c27c1634-254d-404d-84f0-8fee12248acb", name: "Univ of Applied Sciences Geneva" },
    { id: "905db364-aa6b-49a7-8722-497290deba7e", name: "Univ of Applied Sciences, Sudwestfalen" },
    { id: "22813834-20fd-476e-b220-39c80d59fd6d", name: "Univ of Houston Bookstore B & N 510" },
    { id: "6ae067b9-7f7a-4979-860e-01a4fab95cd1", name: "Univ of Marketing and Distribution Sciences" },
    { id: "1246741e-353b-4eeb-8dc4-308bbe662f3b", name: "Univ of Newcastle" },
    { id: "818dd901-7c0e-49c3-8789-6ae66a46d0be", name: "UNIV OF NORTHERN IOWA" },
    { id: "7d4e7ae1-5a26-409f-bcce-f3c6d1fc0263", name: "Univ of Texas" },
    { id: "d8c5f2dc-1aee-4b13-a07c-ce8ef57c7345", name: "Univ of the South" },
    { id: "026b178b-17c3-4b16-a626-13bd1c6cc176", name: "Univ of Wisc Learning Innovs" },
    { id: "cbb82910-0044-44d0-b411-5b6c1f228987", name: "Univ of Wisconsin-Madison Copy Center" },
    { id: "aa8334c4-b9b4-4bf0-b718-9c22a263c408", name: "Univ System of Georgia - Webmba" },
    { id: "8689d4bb-b182-4f56-8e43-eb92385dcc0b", name: "Univ Zaragoza Esc Tec Superior" },
    { id: "8ae4d7b0-0079-45b5-ab52-1ec78bcbad81", name: "Univ. Campus Store" },
    { id: "63e5e4c9-eb74-4c6f-9763-31da8755b1ed", name: "UNIV. OF MASSACHUSETTS- LOWELL" },
    { id: "c6c089ef-8022-4fbb-adb0-fcd6fa6247fe", name: "UNIVA La Universidad Catolica" },
    { id: "d73e2a22-4ae0-4763-823b-8238874509ea", name: "Univerity of Salzburg" },
    { id: "9b3ee32e-4526-4e78-b65f-eefdcf8fd7a9", name: "Universal College Bangladesh" },
    { id: "27cd4263-f68c-439e-ab71-43b7d6524402", name: "Universia" },
    { id: "f78a7d7f-e227-469c-8d41-cf616876307c", name: "Universidad a Distancia de Madrid" },
    { id: "f53c3cb8-be1b-44d2-894c-ce1c30c8761f", name: "Universidad Adolfo Ibanez (UAI)" },
    { id: "eab4f693-cda3-43f5-b989-c8cdc25cc1b7", name: "Universidad Alas Peruanas" },
    { id: "8ad34394-d24a-4b6b-b57b-165b385519a2", name: "Universidad Alberto Hurtado" },
    { id: "4703b841-6bb0-4a0d-9e86-638a87e77d2a", name: "Universidad Alejandro de Humboldt" },
    { id: "ad3cb66f-ca87-4ff4-9b78-8ece6a38c0b0", name: "UNIVERSIDAD AMERICANA (UAM) -Costa Rica" },
    { id: "9c1a109e-41b1-4b90-85cd-ce118050d2b9", name: "Universidad Americana - Nicaragua" },
    { id: "d44e8147-d7a0-4347-8a4b-fcbca4deff9a", name: "Universidad Americana - Paraguay" },
    { id: "e6f20948-6ba6-4a9f-a823-67cdd42b5ce8", name: "Universidad Anahuac" },
    { id: "8496d1b0-5185-4902-9ac3-ee38029f9ac9", name: "Universidad Anahuac Queretaro" },
    { id: "47f9606b-0ce5-44b1-ae69-419e88b4a0a7", name: "Universidad Andina Simon Bolivar" },
    { id: "89dbf99e-0703-4ce8-aef7-af429d3c88e6", name: "Universidad Andres Bello" },
    { id: "de413a1c-0e02-44e5-aeb9-375f31e4420a", name: "Universidad APEC" },
    { id: "c34f223e-68d2-4d71-976f-c5a7293e7d18", name: "Universidad Argentina De La Empresa (UADE)" },
    { id: "8189f6be-43d0-4b3a-affe-381599b15572", name: "Universidad Arturo Prat" },
    { id: "a102bf91-4b6b-4e8e-8468-b6dcf7e7cd47", name: "Universidad Austral" },
    { id: "59781b65-442c-48a3-ba74-b1884a2eb2c6", name: "Universidad Austral de Chile" },
    { id: "b48f9032-dbae-4a4b-8aa3-0f072c25694d", name: "Universidad Autonoma de Baja California" },
    { id: "d609ac17-5fd6-482d-b58a-e6d1b8fe8247", name: "Universidad Autonoma de Chihuahua (UACH)" },
    { id: "b19a0c5c-7180-4b0e-879a-cea03b26e323", name: "Universidad Autonoma de Chile" },
    { id: "ffb784e8-61e2-4632-87ff-c284df3feee4", name: "Universidad Autonoma de Ciudad Juarez" },
    { id: "43e0bfd3-0e16-4263-999d-63edf7b8f033", name: "Universidad Autonoma de la Ciudad de Mexico" },
    { id: "4e0dfe2d-1d1b-43b1-981a-98d1f13d74c7", name: "Universidad Autonoma de Madrid" },
    { id: "0040f356-90bf-4fc9-9c8c-6738684a458c", name: "Universidad Autonoma de Manizales" },
    { id: "05db5a51-b259-4b57-89e6-d18954607149", name: "Universidad Autonoma de Nuevo Leon (UANL)" },
    { id: "0007774e-aa82-4fee-9493-6a37c72d36b0", name: "Universidad Autonoma de San Luis Potosi" },
    { id: "700bf33d-12d8-4cf9-9ab6-e94f06ad3e2c", name: "Universidad Autonoma de Sinaloa (UAS)" },
    { id: "be1aeb26-bc29-4c41-94f4-01874fb9f3a5", name: "Universidad Autonoma de Yucatan" },
    { id: "6525bab1-9792-49a3-ae51-df6d6eede92e", name: "Universidad Autonoma de Zacatecas" },
    { id: "a51a8b2f-8eab-4cd9-8166-6787c9ad5648", name: "Universidad Autonoma del Caribe" },
    { id: "9b22b707-ae04-4d1b-b8a3-09140f81fc63", name: "Universidad Autonoma del Carmen" },
    { id: "8eae62da-f782-48d8-bb80-5159995e4bac", name: "Universidad Autonoma del Estado de Mexico" },
    { id: "18aa3b08-5c69-4730-80cd-4347bd302d1f", name: "Universidad Autonoma Gabriel Rene Moreno" },
    { id: "525d9b06-d5c5-4ffc-b99e-bcb7e4e7c92e", name: "Universidad Autonoma Metropolitana" },
    { id: "95456d5b-4c7c-4296-a6cd-3dcc10033299", name: "Universidad Autonóma de Coahuila" },
    { id: "e37bcfb8-6c86-4900-a16c-701030f757a2", name: "Universidad Autónoma Chapingo" },
    { id: "bb5ae0fc-0d4c-435e-b1a3-31dfaeabf064", name: "Universidad Autónoma de Aguascalientes" },
    { id: "0e1e057f-f02d-4581-8c59-b48f2f228632", name: "Universidad Autónoma de Aguascalientes - UAA" },
    { id: "77190c14-cc35-4efb-9a3f-ebd4d22439df", name: "Universidad Autónoma de Baja California - Campus Tijuana" },
    { id: "dcba5bac-8d35-44d0-b45a-f6bfef4a508b", name: "Universidad Autónoma de Bucaramanga" },
    { id: "a8beb568-7dbe-45ec-968c-6709ff5ffec5", name: "Universidad Autónoma de Campeche" },
    { id: "8e1b971b-31ae-45aa-b87f-c1cb67268834", name: "Universidad Autónoma de Ciudad Juárez" },
    { id: "b83d0423-8324-480b-890d-c7b8055f94d7", name: "Universidad Autónoma de Nayarit" },
    { id: "61f8618e-2a93-4fe8-9cec-4027187acf78", name: "Universidad Autónoma de Tamaulipas" },
    { id: "cfcc1d32-a292-4173-95fb-3f55426eab7e", name: "Universidad Autónoma de Tlaxcala" },
    { id: "cdc3f2d7-0d2d-49ef-b4f6-91392a14d914", name: "Universidad Autónoma del Carmen" },
    { id: "df60fc12-d51c-4a77-9e02-b6ccce250cba", name: "Universidad Autónoma del Estado de Hidalgo" },
    { id: "9b6a031b-0b66-44e0-8bd9-29b4bb3ccfd5", name: "Universidad Autónoma Del Estado De Morelos" },
    { id: "d560cb73-9f3f-420c-a972-21096484871f", name: "Universidad Autónoma Latinoamericana - UNAULA" },
    { id: "528bcb31-c069-4abe-a4b9-c5f2c2ee4f21", name: "Universidad Carlos III de Madrid" },
    { id: "5f990f29-eabe-4463-a89a-463efdf71c9d", name: "Universidad Casa Blanca" },
    { id: "88de6bf5-84aa-451e-bfe8-43b63421144c", name: "Universidad Casa Grande" },
    { id: "8202276d-436d-45ee-85c1-4f6b08deba5b", name: "Universidad Catolica Argentina" },
    { id: "7b179896-03a8-403e-be11-a258006ccf61", name: "Universidad Catolica Boliviana" },
    { id: "a029e81c-1e08-4c44-8fac-922c4e62f0ad", name: "Universidad Catolica de Asuncion" },
    { id: "41d9daa8-d98c-4541-b1f6-f8692e057b23", name: "Universidad Catolica de Cordoba" },
    { id: "863c1963-0111-4f63-8bed-c5e914dab082", name: "Universidad Catolica de la Santisima Concepcion" },
    { id: "00ceb6b4-ccb1-40eb-9521-88592e4dbe1a", name: "Universidad Catolica de Oriente" },
    { id: "dcc6da54-648f-427d-b840-c9c046bfd126", name: "Universidad Catolica de Salta (UCASAL)" },
    { id: "15ad0201-0c3a-4283-8cb1-4cd51adfd631", name: "Universidad Catolica de Santa Fe UCSF" },
    { id: "be6d2d71-d1aa-46d1-8b2f-779c372bfd54", name: "Universidad Catolica de Valencia San Vicente Martir" },
    { id: "67b74f3f-385b-4458-9cd7-207d6eeebd4e", name: "Universidad Catolica San Pablo" },
    { id: "7f9ad19c-2b8e-4f90-8e46-493569f2b793", name: "Universidad Catolica Santa Maria la Antigua (USMA)" },
    { id: "0d15403f-118d-409a-ab17-db183f368e6b", name: "Universidad Catolica Sedes Sapientiae" },
    { id: "d85c1f5d-1c4a-4011-bb5c-72dbefaec11f", name: "Universidad CatÃ³lica del Uruguay DÃ¡maso Antonio LarraÃ±aga" },
    { id: "9db50709-f5af-4824-a977-4061a5e66163", name: "Universidad Católica de Santiago de Guayaquil" },
    { id: "e13a376b-f7b4-428d-b99f-abe8a4412df0", name: "Universidad Católica del Norte" },
    { id: "730e3318-63a0-4270-bda4-1572d4cc3aef", name: "Universidad Católica del Uruguay (UCU)" },
    { id: "578689bb-0f9b-45aa-b67a-347588db5a29", name: "Universidad Católica Santo Toribio de Mogrovejo" },
    { id: "e3f9c092-17e3-40db-896e-3731fa4544d1", name: "Universidad Católica Silva Henríquez" },
    { id: "1c2625a0-7938-4c49-b328-ca3e9c66daf7", name: "Universidad Central" },
    { id: "39e493e3-9e2b-45ce-942c-c8c28528eb87", name: "Universidad Centroamericana" },
    { id: "9c708d18-3a23-4f8d-af62-7a34d6f01cb7", name: "Universidad Cesar Vallejo" },
    { id: "73563fc3-2479-45dc-96c6-a547aed87eb8", name: "Universidad CEU Cardenal Herrera" },
    { id: "3f00b6c8-fe51-4d38-87ef-08166c9b29c0", name: "Universidad CEU San Pablo" },
    { id: "a6230c88-5c4b-4878-8142-20d47efbb6f5", name: "Universidad Champagnat" },
    { id: "288df238-61b9-4667-9bcf-ff74f954aeaa", name: "Universidad Científica del Sur" },
    { id: "fbe2d7a5-20cb-48b9-8db1-8cc916fcbcf5", name: "Universidad Continental" },
    { id: "aac78d94-ccc9-49b2-a3c7-451d3dca14dc", name: "Universidad cooperativa de Colombia" },
    { id: "bf712a4e-2332-49ad-bc31-3e64751827d3", name: "Universidad Cristóbal Colón" },
    { id: "1d8afb82-adc3-4daf-8cad-303d056f164e", name: "Universidad Cuauhtémoc Plantel Aguascalientes" },
    { id: "392400d3-0635-47a8-bef3-8a8cbd1905c3", name: "Universidad de Alicante" },
    { id: "c8e89edb-9219-42fd-b06b-dcbf35f46f0f", name: "Universidad de Almería" },
    { id: "2e6eb6a1-acc2-4ccc-8b57-9983ce2c4123", name: "Universidad de Antioquia" },
    { id: "731b9252-5e77-4ce6-915a-536b0f1f4882", name: "Universidad de Aquino Bolivia (UDABOL)" },
    { id: "1cebccdf-9475-4794-bfaf-19a9dd6f26d0", name: "Universidad de Barcelona" },
    { id: "974d8311-ae53-4735-8519-c42ae4b0992f", name: "Universidad de Boyaca" },
    { id: "5bdb299c-0389-4f88-acd6-e3676094e497", name: "Universidad de Cabo Verde" },
    { id: "dc2f4778-674a-4703-8cbb-e996ad6e9d91", name: "Universidad de Caldas" },
    { id: "baa2d579-6647-4438-a707-2960a986cf1a", name: "Universidad de Cantabria" },
    { id: "3691e8ff-2e7f-47b1-8f20-074937cf766b", name: "Universidad de Castilla - La Mancha" },
    { id: "6b27edb8-49a7-4652-92e3-c837a5218cce", name: "Universidad de Celaya" },
    { id: "76f02827-106a-4295-9699-2028bf171555", name: "Universidad de Chile" },
    { id: "acf25c6e-090d-40ff-a610-5b35cde61a08", name: "Universidad de Ciencias Empresariales Y Sociales UCES" },
    { id: "5343a633-5eb8-4691-8bad-f8288253e169", name: "Universidad de Concepcion (UdeC)" },
    { id: "0aa2db55-ddce-449a-8ddd-761e2b1b00cc", name: "Universidad de Costa Rica" },
    { id: "d1548c9f-d71b-459c-8c90-1321519a3b05", name: "Universidad de Cuenca" },
    { id: "64e573f3-1ca1-4c84-863e-22890f0caad1", name: "Universidad de CÃ³rdoba" },
    { id: "2110e836-4041-4816-94e7-843062dfe893", name: "Universidad de Desarrollo (UDD)" },
    { id: "bb7ed747-6622-46c5-91e0-430873949315", name: "Universidad de Especialidades Espiritu Santo (UEES)" },
    { id: "7d11fa14-0e12-4674-9706-42ab7fe7a477", name: "Universidad de Extremadura - Campus Badajoz" },
    { id: "9ea09e86-673e-4fd3-88f2-63553104dc9c", name: "Universidad de Granada" },
    { id: "23de886c-4555-4098-9dcb-a092910c72ad", name: "Universidad de Guadalajara" },
    { id: "c435a520-9044-4948-95e7-94d50d26a0b0", name: "Universidad de Guanajuato" },
    { id: "797ac4ee-bd63-4bb5-bb91-974966d5f73e", name: "Universidad de Huelva" },
    { id: "3138e206-2976-41ee-add6-fb32a5f9f1d2", name: "Universidad de Ingenieria y Tecnologia UTEC" },
    { id: "b2362f94-889b-4194-82c7-e8169c451891", name: "Universidad de Ingeniería y Tecnología" },
    { id: "6f69e971-8dec-4dc9-b33b-c6817d6a3492", name: "Universidad de Ixtlahuca CUI" },
    { id: "7370675b-62ec-4d26-b7b1-5604049f3245", name: "Universidad de Jaén" },
    { id: "67a9163e-c269-4982-b60d-5a449487e197", name: "Universidad de la Amazonia" },
    { id: "755371c5-d3bf-4f8c-8102-5ec0f59d0487", name: "Universidad de la Costa (CUC)" },
    { id: "1203fc84-12ee-4848-b6a9-a729a69df472", name: "Universidad de la Frontera (UFRO)" },
    { id: "0b41e67c-f34a-4960-8928-349b504b5303", name: "Universidad de la Republica - Uruguay" },
    { id: "e1fa7a5e-ba7f-4d2a-8ecd-804826248ddb", name: "Universidad de la Sabana" },
    { id: "0b3cc3b9-ba8e-491d-9852-89547ae872cf", name: "Universidad de la Sabana (INALDE)" },
    { id: "4b7477ce-c1c0-4527-a8a1-c0a9633b5df2", name: "Universidad De La Salle - Colombia" },
    { id: "1e176bc9-c056-48e6-9906-f3a52a4773b4", name: "Universidad de La Salle - Leon (MX)" },
    { id: "a2f91d70-3926-4c1b-8c43-ef1e1f192bff", name: "Universidad de Las Américas Chile (UDLA) - Chile" },
    { id: "766934d1-9b8d-4c45-8dac-31a90b98a6a4", name: "Universidad de las Islas Baleares" },
    { id: "eb49dd85-d7b3-4bc7-8b5f-08fc00feda0e", name: "Universidad de Las Palmas de Gran Canaria" },
    { id: "8e0a1eb9-f879-416d-b51f-831339fdc50e", name: "Universidad de Leon" },
    { id: "55080e8d-0984-4f10-968e-1fdca44a648c", name: "Universidad de Lima" },
    { id: "90e02fe9-b72e-4f52-96ab-a78fcc6e0992", name: "Universidad de los Andes - Chile" },
    { id: "6e1853dc-d08b-4d3a-9b9c-0c6736d76f3c", name: "Universidad de Los Andes - Colombia (UniAndes)" },
    { id: "64bd389d-63b9-4f44-9b37-2a47e5be60f3", name: "Universidad de los Hemisferios" },
    { id: "42f9ce20-6721-4e3b-8ab7-670b80301cdf", name: "Universidad de los Lagos" },
    { id: "f157ebaf-fa8e-4637-84d5-d5175c3de578", name: "Universidad de Medellin" },
    { id: "5fd2f2d6-8e14-4a52-b507-390fb5a17778", name: "Universidad de Montemorelos" },
    { id: "c780eca0-d563-462d-8e9b-19a845e34ffa", name: "Universidad De Monterrey (UDEM)" },
    { id: "473a59e2-4551-4610-8a2f-a50807e70fc5", name: "Universidad de Montevideo" },
    { id: "47a5569e-3e6e-4b21-aa1f-783487777c97", name: "Universidad de Moron" },
    { id: "30a2c1cb-b3ea-4133-9307-662b3d6308b4", name: "Universidad de Málaga" },
    { id: "5d8fd3b5-59c7-4759-941e-d89661eb4acf", name: "Universidad de Occidente" },
    { id: "bcd668f5-d04d-4377-bf2d-e4da2fd94bad", name: "Universidad de Oriente (UNIVO)" },
    { id: "4dde20be-0512-4b78-a12e-d8a85743883e", name: "Universidad de Oviedo" },
    { id: "82dc2cc6-3064-4da8-bdaf-79119bc24a44", name: "Universidad De Piura (PAD)" },
    { id: "3b3af79a-8280-42d8-a036-11bbe6234f7c", name: "Universidad de Puerto Rico" },
    { id: "7934c42c-3df0-49fe-9b37-592a330c513d", name: "Universidad de Puerto Rico en Utuado" },
    { id: "a5b119b1-9026-4b81-a1cb-30cd0a5d22f7", name: "Universidad de San Andres (UDESA)" },
    { id: "ad050ddc-b362-4e2c-a5bb-dce0acfefaa0", name: "Universidad de San Carlos Guatemala" },
    { id: "a36b6685-8c9d-4127-8f2d-175f60e44697", name: "Universidad de San Pedro Sula" },
    { id: "023e2470-f13d-498d-b59a-536c071be4b5", name: "Universidad de Santander UDES" },
    { id: "6772f6da-826a-41f6-b7cf-ccc1001fb568", name: "Universidad de Santiago de Chile (USACH)" },
    { id: "3706ee59-8a19-4aa3-bcb8-50d9e12e0a3f", name: "Universidad de Sonora" },
    { id: "922902a4-b6c9-44db-96a9-67262bf21ff1", name: "Universidad de Talca" },
    { id: "e1a4e0da-243c-4a17-8042-1a3a7de3f70e", name: "Universidad de Tarapaca" },
    { id: "b6460f40-d515-4ae7-9f20-856e4a4c8d58", name: "Universidad de Valparaíso" },
    { id: "9365facb-a6b1-4b07-917b-132f1b593a16", name: "Universidad de Vigo" },
    { id: "2d4e5c80-6e93-430f-a38e-76d39e57c1e7", name: "Universidad de Yucatán" },
    { id: "30636ffc-76cb-4255-87b6-3bc5e17ecdc4", name: "Universidad del Bio-Bio" },
    { id: "57f63426-f85c-4715-a19d-b3de4ab7b53a", name: "Universidad del Caribe" },
    { id: "6fa87ecf-5b2d-497d-a273-df61689242a1", name: "Universidad del CEMA" },
    { id: "0c122886-eeec-431d-a308-d47607955089", name: "Universidad del Este - SUAGM" },
    { id: "23577075-8f38-4ad5-a371-f00b2fbeb049", name: "Universidad del Istmo (UNIS)" },
    { id: "a6836961-3596-44db-9617-5b5009361ceb", name: "Universidad del Magdalena" },
    { id: "ce8dbe9a-6755-4b56-8c18-0f67ae5db890", name: "Universidad del Norte (Uninorte) - Colombia" },
    { id: "c0153b25-735a-498a-b0e3-6ddb8df5617e", name: "Universidad del Norte Santo Tomas de Aquino" },
    { id: "48d7661c-a0ba-4b36-8856-84d682651506", name: "Universidad Del Pacifico" },
    { id: "32095428-ae62-4780-a700-89b52d71827a", name: "Universidad del Pacifico-Ecuador" },
    { id: "7cdfe294-b884-435b-8f2b-fcfe3dab17df", name: "Universidad del País Vasco - Campus Gipuzkoa" },
    { id: "31bb0a19-d86c-4e09-a6b4-1b29bc1d2f34", name: "Universidad del Pedregal" },
    { id: "625be052-cae7-421d-aad6-fd7c31a00639", name: "Universidad del Quindio" },
    { id: "3079b336-a115-4742-b922-3fcf8856c160", name: "Universidad del Rosario" },
    { id: "925aa04f-80cc-41bb-8a96-e34b4a98174d", name: "Universidad Del Sagrado Corazon" },
    { id: "c8a07eb9-2014-40d9-aee7-9556ee973723", name: "Universidad del Salvador" },
    { id: "80319fce-cc79-40b2-b3c8-4fc599ac7093", name: "Universidad del Turabo - SUAGM" },
    { id: "89b4bb6e-9bc8-4c8a-971e-3847db93ae9f", name: "Universidad del Valle - Cali" },
    { id: "30821b9d-cadf-404b-b869-ea702cdd7f67", name: "Universidad del Valle de Atemajac" },
    { id: "2a6213e6-b942-4b09-b2d9-dffd000b9952", name: "Universidad del Valle de Guatemala (UVG)" },
    { id: "7241b590-5587-4877-9b87-2548d1fa32af", name: "Universidad del Valle de Mexico - Laureate International Universities" },
    { id: "30f9b803-1cd6-43e8-9c11-b609bcdee5a5", name: "Universidad Diego Portales (UDP)" },
    { id: "accb246e-39cb-4e25-9dea-6dca5fd963a3", name: "Universidad Dr. Jose Matias Delgado" },
    { id: "168b4b8e-fa03-4361-a3e0-70c778879ac5", name: "Universidad EAFIT" },
    { id: "371a6879-fb63-4481-a990-64fad91d5030", name: "Universidad EAN" },
    { id: "5e9f5bdc-f243-4380-afb3-8f2a86636db2", name: "Universidad Ecotec" },
    { id: "dec80bd4-f73c-42aa-ace6-cb5d00015421", name: "Universidad El Bosque" },
    { id: "ead3142a-6adb-45ee-bb8f-ed54c7dd86d1", name: "Universidad Empresarial Siglo 21" },
    { id: "0f96a862-5ac4-4b65-aa13-8e634e5b34ae", name: "Universidad en Puerto Vallarta" },
    { id: "ecb9b39d-1055-4f19-959c-ab4d02712832", name: "Universidad Enrique Diaz de Leon (UNEDL)" },
    { id: "f6387a65-f482-40f8-8d19-dbaf68aa0074", name: "Universidad Enrique Díaz de León" },
    { id: "54eb2fa7-1754-4e0e-bc1a-6fe2e6cd8917", name: "Universidad Estatal de Milagro" },
    { id: "6b24958f-7e38-4c81-9281-a3591a909220", name: "Universidad Europea" },
    { id: "48359af8-4431-4856-a04a-6c52667c8056", name: "Universidad Europea del Atlántico" },
    { id: "6fbfbc9a-3a96-41a4-a840-3135ea7d24b6", name: "Universidad Externado de Colombia" },
    { id: "560acd89-11f0-4f9c-92c5-3a8e054daa16", name: "Universidad Femenina Del Sagrado Corazón (UNIFE)" },
    { id: "623dde18-5ae7-4ca0-9c69-5765693d0310", name: "Universidad Finis Terrae" },
    { id: "6241fa16-962d-41ea-bc6d-38771e1d4474", name: "Universidad Francisco de Vitoria" },
    { id: "2759859b-cae7-4044-89de-d23aee327f7b", name: "Universidad Francisco Gavidia" },
    { id: "7d1ae493-6e41-4ff1-bb4e-20eff4b7f0d0", name: "Universidad Francisco Marroquin (UFM)" },
    { id: "9a41d0ba-4687-4441-9e6c-6abdc18787f8", name: "Universidad Gabriela Mistral" },
    { id: "5b54d777-aff4-4b89-98c3-3a137a99b0a4", name: "Universidad Galileo" },
    { id: "8c14c228-6792-4b7c-b1af-6ba7b44e7e41", name: "Universidad Gerardo Barrios" },
    { id: "6d3e1c18-4825-4bd6-ab9a-3df6755794da", name: "Universidad Ibereoamericana Santo Domingo" },
    { id: "40f71f87-26e0-4dc7-a7d2-8309356e167d", name: "Universidad Iberoamericana (UNIBE) - Dominican Republic" },
    { id: "b65de323-0d31-4d2d-ab92-b860b0f7dd19", name: "Universidad Iberoamericana - Mexico" },
    { id: "fcd84c78-bad7-48fc-b7b5-1d2d2982bb0e", name: "Universidad ICESI" },
    { id: "e5dcfa9b-5873-4168-a2ed-66a88ddc9371", name: "Universidad Inca Garcilaso de la Vega" },
    { id: "59f613e5-a78f-4f73-80c9-a1b122c8383b", name: "Universidad Industrial de Santander (UIS)" },
    { id: "69cf719b-07f3-4d40-93c7-905e04bcde9a", name: "Universidad Interamericana - Panama" },
    { id: "43b2e8fe-bf33-4e84-818e-e3ed2df22c6c", name: "UNIVERSIDAD INTERAMERICANA DE PANAMA" },
    { id: "176eab8c-8e18-4d0a-86cd-c95a4373b331", name: "Universidad Interamericana de Puerto Rico" },
    { id: "cd3c22ad-f7c7-4762-a566-4dcbefeb62af", name: "Universidad Interamericana para el Desarrollo (UNID)" },
    { id: "42ede63e-bc87-41d9-ac73-41c36ef6d6de", name: "Universidad Intercontinental (UIC)" },
    { id: "65108fcc-2c03-47d7-956a-eddc5225811c", name: "Universidad Internacional De la Rioja SA" },
    { id: "3a73965b-8c32-42b6-a26d-554e7dffcedc", name: "Universidad Internacional del Ecuador (UIDE)" },
    { id: "804ed8b7-df2e-4e7b-9411-51dfb860c34c", name: "Universidad Internacional SEK" },
    { id: "3f4b9fce-638d-44ba-8c85-5be5d830b9a2", name: "Universidad Internacional Tonala" },
    { id: "bd888f43-1d2c-422a-940e-ef2345135049", name: "Universidad Isabel 1" },
    { id: "58b5378a-3808-4147-844f-b2c29973ff77", name: "Universidad Juarez Autonoma de Tabasco (UJAT)" },
    { id: "7ec659a0-6516-4bf7-820f-75516f514d09", name: "Universidad Juárez Autónoma de Tabasco" },
    { id: "ac52be7e-6556-4aee-961a-85a9501d5eb1", name: "Universidad La Gran Colombia" },
    { id: "259170e0-f75b-4dcd-bc47-a1d84942d5fa", name: "Universidad La Salle - Chihuahua" },
    { id: "59e58396-38c0-4bbf-b9ca-186dbed6f040", name: "Universidad La Salle - Ciudad de Mexico" },
    { id: "183e23b8-15bf-4cf5-98c2-e0221adb5cc3", name: "Universidad La Salle - Pachuca" },
    { id: "6621aabb-4d4b-466d-9d80-f692ef3c3cfa", name: "Universidad Laica Vicente Rocafuerte de Guayaquil" },
    { id: "0fa81b6f-f667-4e1a-a435-7f8f7ff00fe2", name: "Universidad Latina de América" },
    { id: "14bb9bdf-9d2e-4d37-a451-0b5b60ad7e56", name: "Universidad Latina de Costa Rica" },
    { id: "4ff12110-efc9-4f34-a320-6a65239edc61", name: "Universidad Latina de Panama" },
    { id: "2a4d1c7b-43af-48fa-9465-b731d83b7e10", name: "Universidad Libre Colombia" },
    { id: "f01da96d-62a7-4376-b290-3f0306e9aba2", name: "Universidad Loyola Andalucia" },
    { id: "188d5b7d-0501-49f4-8179-dddf9b38f78b", name: "Universidad Loyola del Pacifico" },
    { id: "0839945c-a992-4bbe-b8f2-7f3a0faa32b9", name: "Universidad Madero" },
    { id: "ee8ddb3b-7c87-4078-a0f8-f7bad7f03dd6", name: "Universidad Marista de Mérida" },
    { id: "c2a27474-84ad-4403-8515-2d8208f373e2", name: "Universidad Mayor" },
    { id: "f1e281aa-ed01-4396-91a2-c266e45aa4df", name: "Universidad Mesoamericana" },
    { id: "5a34c4a9-8179-42e2-a7ad-91feca0d1b9f", name: "Universidad Metropolitana - SUAGM" },
    { id: "2678ac63-5e45-4ce9-889a-55243f73a9ae", name: "Universidad Metropolitana de Caracas" },
    { id: "9a117453-c329-45d3-baa5-ae87c679e15a", name: "Universidad Mexicana de Innovacion en Negocios UMIN" },
    { id: "9b79d998-d893-4397-bbef-7eac1b00b2d5", name: "Universidad Mexiquense del Bicentenario" },
    { id: "75e2107a-ea5d-4c0f-902d-7559d11b098a", name: "Universidad Michoacana de San Nicolás de Hidalgo" },
    { id: "3c3a0aaa-1d0e-4da8-b86d-f1b53b45ab2f", name: "Universidad Miguel Hernández" },
    { id: "86dd8f67-c82f-4c4c-93fb-66b92579f40b", name: "Universidad Motolinia del Pedregal" },
    { id: "deaa68f1-9f0f-438a-b613-b67d73fe5c6f", name: "Universidad Nacional Abierta y a Distancia" },
    { id: "1b2e0cbc-bb54-4f3a-9987-a6882423c71b", name: "Universidad Nacional Arturo Jauretche" },
    { id: "5be2185e-3e48-422e-be33-b8f80b2a166c", name: "Universidad Nacional Autonoma de Honduras" },
    { id: "d72fc464-dc1b-43f5-81d2-d15e16e68c0c", name: "Universidad Nacional Autonoma de Mexico UNAM" },
    { id: "34750fdc-6a77-4719-8cb7-94b0b734f821", name: "Universidad Nacional de Asunción" },
    { id: "4bea66a8-2e95-428b-9951-f8eec9a8e6fe", name: "Universidad Nacional de Colombia" },
    { id: "e0422f83-c83b-4b9c-8e59-99817f86ff3a", name: "Universidad Nacional de Cordoba" },
    { id: "249bbf0c-d0ba-4030-9714-84c6d6a9e111", name: "Universidad Nacional de Costa Rica" },
    { id: "1471f1a2-948d-4d4d-83d5-6142261e3bbf", name: "Universidad Nacional de Cuyo" },
    { id: "e4dd7f86-7358-4831-adb0-19632d48d11c", name: "Universidad Nacional de General Sarmiento UNGS" },
    { id: "3f5d278d-8fac-448c-960c-dab22488dd75", name: "Universidad Nacional de Ingenieria" },
    { id: "95f4a0a4-a5a6-4ce3-a388-17d8c62b2ec3", name: "Universidad Nacional de la Matanza" },
    { id: "af865c67-b703-4cab-a93f-2a4f0fa2335d", name: "Universidad Nacional de la Pampa (UNLPam)" },
    { id: "7d3d1e79-c451-44c1-8dc3-2bfe09521b58", name: "Universidad Nacional de La Plata" },
    { id: "bbe2bf8d-2d9d-4def-af7b-9cbfe4146908", name: "Universidad Nacional de Lanus UNLA" },
    { id: "410d611e-aa24-4177-a9ac-fd6e4095f61e", name: "Universidad Nacional de Loja" },
    { id: "62611b1b-6e51-4778-99f3-8a0a6606a274", name: "Universidad Nacional de Lomas de Zamora" },
    { id: "2fb05be8-22da-4476-9794-49db18f5665a", name: "Universidad Nacional de Mar del Plata UNMDP" },
    { id: "1924396d-b47f-417f-b7fa-0d0d336088d8", name: "Universidad Nacional de Rosario" },
    { id: "6b352992-33b1-4d56-9e1c-a01796fbe50a", name: "Universidad Nacional de San Martín - UNSAM" },
    { id: "389a1043-5231-404f-99d1-4825c69aba52", name: "Universidad Nacional de Tres de Febrero UNTREF" },
    { id: "07c2c56e-6952-42d1-bcca-be8363acf733", name: "Universidad Nacional de Tumbes" },
    { id: "30e12416-11e7-4c69-ac50-7fe27ff2fe64", name: "Universidad Nacional del Litoral" },
    { id: "1d1309e7-6acb-48bb-b659-c324cda2b8e9", name: "Universidad Nacional del Nordeste" },
    { id: "751bc995-606d-4cb9-8da1-ec4c44205857", name: "Universidad Nacional du Tucuman" },
    { id: "7e35b4ad-1558-472d-bd48-2639d67291a7", name: "Universidad Nacional Federico Villarreal" },
    { id: "ec688c31-f734-406f-8faa-39fec96d2dc5", name: "Universidad Nacional Jorge Basadre Grohmann" },
    { id: "1c76dda5-6812-4e3c-8122-723e5cf7b3e2", name: "Universidad Nacional Mayor de San Marcos UNMSM" },
    { id: "f814944d-cb85-4a3d-a1c7-a9e52c9979f0", name: "Universidad Nacional Pedro Henriquez Urena" },
    { id: "432a8ced-3c90-4e32-94f8-ffb32c2d63fa", name: "Universidad Nacional San Luis Gonzaga de Ica" },
    { id: "075eaff7-f8e5-49b1-ae40-c1b80ed9281f", name: "Universidad ORT Uruguay" },
    { id: "eac8a5b9-46f1-49fe-9e26-92be493dc4fb", name: "Universidad Panamericana" },
    { id: "6f069f0c-d964-42fc-ad12-d706663d2874", name: "Universidad Particular de Chiclayo" },
    { id: "ae702d6b-351a-4aee-a707-e73a899da7a4", name: "Universidad Particular Internacional SEK" },
    { id: "97c68b4e-f501-4de2-bfdd-f375d1880225", name: "Universidad Peruana Cayetano Heredia UPCH" },
    { id: "a93d9e39-7029-410d-878f-11e375d71359", name: "Universidad Peruana de Ciencias Aplicadas (UPC)" },
    { id: "8d328695-641f-4bbe-a300-c403d9730f04", name: "Universidad Peruana Union" },
    { id: "1c11a88a-6be2-4383-9230-a8b6d026d6c8", name: "Universidad Piloto de Colombia" },
    { id: "003e7751-4acb-4fe5-8b5c-fa132672f47a", name: "Universidad Politecnica de Madrid" },
    { id: "b92f08fa-91bf-4c2f-b87c-de259addc581", name: "Universidad Politecnica de Puerto Rico" },
    { id: "5fa73be4-9274-43a4-a85f-d52346a6d67c", name: "Universidad Politecnica de Tulancingo" },
    { id: "64607390-4b2a-4459-9770-e5e97cdb78f3", name: "Universidad Politecnica de Valencia" },
    { id: "5fd394e3-a294-4c87-ab0c-1a6148189d4a", name: "Universidad Politecnica del Estado de Morelos" },
    { id: "5d7914e7-b31c-448a-8f54-953800c678a6", name: "Universidad Politecnica Metropolitana de Hidalgo" },
    { id: "793e67f1-f3e3-4f8c-bf05-8b8de1be33ef", name: "Universidad Politécnica de Aguascalientes" },
    { id: "f2cd769f-fb89-43ad-b135-22988ea5fd0e", name: "Universidad Politécnica de Cartagena" },
    { id: "7d2f92e3-d71c-4732-a2cb-33a6287c9306", name: "Universidad Politécnica de Tlaxcala" },
    { id: "edf31061-e25b-4989-9832-9f090a26a1ae", name: "Universidad Politécnica del Sur de Zacatecas" },
    { id: "8bc8ef76-aeea-494d-a10e-03bc4dd4c988", name: "Universidad Pontificia Bolivariana" },
    { id: "cde16803-2281-4b2f-8dd4-2f2fde0fcccc", name: "Universidad Pontificia Comillas de Madrid" },
    { id: "a875f958-2fc8-4f25-adc6-23466f662900", name: "Universidad Privada Boliviana" },
    { id: "405563e9-0c5e-4327-b912-675328f7cf77", name: "Universidad Privada de Santa Cruz de la Sierra" },
    { id: "0bd817b8-b4b1-42a3-9ea4-abc9f19987d5", name: "Universidad Privada de Tacna" },
    { id: "34a46843-2298-4753-86d6-47ea0410fd77", name: "Universidad Privada del Norte (UPN)" },
    { id: "a47d76d8-90d9-46bc-9b36-56107bcc7bd4", name: "Universidad Privada Dr. Rafael Belloso Chacin" },
    { id: "bc33f0cc-3456-4413-9b78-68253f1c2ed8", name: "Universidad Rafael Landivar (URL)" },
    { id: "28500761-66c5-4807-8755-fcbec4766dfc", name: "Universidad Regiomontana (UR)" },
    { id: "efa35eff-63d7-4e01-b5d7-0576246dd370", name: "Universidad Rey Juan Carlos" },
    { id: "6eb94c1a-19bd-4a43-93f8-1a72fae96c86", name: "Universidad San Francisco de Quito - USFQ" },
    { id: "e779ff76-e894-4e71-8774-783bac44d66a", name: "Universidad San Ignacio de Loyola (USIL)" },
    { id: "5b73e380-2b71-4bc5-b12c-b4d841b61e33", name: "Universidad San Jorge" },
    { id: "34442a9a-6436-45e0-94c5-38d772e12fb9", name: "Universidad San Martin de Porres" },
    { id: "d0b25dd5-5a86-4ba0-8c30-adb9d1691293", name: "Universidad San Pablo de Guatemala" },
    { id: "a19ed0fd-1267-4b62-9a97-5f2aab299bd9", name: "Universidad San Sebastián" },
    { id: "9a96626e-92b7-4f9f-b4f2-03f1582793db", name: "Universidad Santa Maria" },
    { id: "3f8d71d1-d361-4989-913d-d06ffd3bdc24", name: "Universidad Santo Tomas" },
    { id: "3d07ac2f-c28f-4ef6-8a35-eb2643df380f", name: "Universidad Senor de Sipan" },
    { id: "d5effa39-7be1-4d06-b806-500b3738f67a", name: "Universidad Sergio Arboleda" },
    { id: "8d43513f-8e2d-4262-9164-ea4133015fe3", name: "Universidad Surcolombiana" },
    { id: "632bc214-7a85-4538-b0c3-83dac68f8516", name: "Universidad Technologica del Norte de Guanajuato" },
    { id: "e76c0d34-7a69-4dcc-be40-781fbaec4702", name: "Universidad Tecnica Frederico Santa Maria (UTFSM)" },
    { id: "cf218325-7764-4646-b5ad-4fda382e532a", name: "Universidad Tecnolgica de Corregidora" },
    { id: "32e92d15-58dd-46d2-ba2e-fdfa743c5cb4", name: "Universidad Tecnologica de Bolivar" },
    { id: "89a3070d-3497-4820-a7aa-78fd1a7017ef", name: "Universidad Tecnologica de Chile - INACAP" },
    { id: "de7779ed-acb5-4d92-a891-84eb86c7a0ab", name: "Universidad Tecnologica de Ciudad Juarez" },
    { id: "00498d9c-674a-4944-9f5d-ee0be2b2dcc6", name: "Universidad Tecnologica de Honduras (UTH)" },
    { id: "d6d0243f-4843-44ac-98df-24ed4080446c", name: "Universidad Tecnologica de la Mixteca" },
    { id: "b9385fa3-f7ba-458e-b81a-49923200e812", name: "Universidad Tecnologica de losValles Centrales de Oaxaca" },
    { id: "02af40ff-b151-4b25-8363-5193873e09b8", name: "Universidad tecnologica de mexico (UNITEC)" },
    { id: "a2000e34-3450-416d-ad3a-803277eafae7", name: "Universidad Tecnologica de Queretaro" },
    { id: "7c463968-490d-467c-b201-b4680c880808", name: "Universidad tecnologica de Tulancingo" },
    { id: "ffce3eff-ae62-4dd9-bd6a-85e2683b8ca9", name: "Universidad Tecnologica del El Salvador (UTEC)" },
    { id: "995fd273-a448-413f-8d57-b618055f14e3", name: "Universidad Tecnologica del Peru" },
    { id: "1efb2f4a-b271-45f0-bb8c-a0139bf9196b", name: "Universidad Tecnologica del Valle del Mezquital" },
    { id: "ed3e990a-4a28-4e53-8c34-22d2f5852c56", name: "Universidad Tecnologica Emiliano Zapata (UTEZ)" },
    { id: "febe0f30-91e8-421a-9503-f7eaff4987fc", name: "Universidad Tecnologica Empresarial de Guayaquil (UTEG)" },
    { id: "788ebd24-8410-4f2c-a86e-8c00ac585330", name: "Universidad Tecnologica Nacional" },
    { id: "e293cbfd-95b4-4c3d-b354-813d18d7254d", name: "Universidad Tecnológica de Bahia de Banderas" },
    { id: "8ea85ffc-f108-4aa1-9317-75083c137847", name: "Universidad Tecnológica de la Región Centro de Coahuila" },
    { id: "b497934b-d39a-4936-96bf-2083da935de4", name: "Universidad Tecnológica de México" },
    { id: "511baec9-6777-42f6-be6d-2d29874950aa", name: "Universidad Tecnológica de San Juan del Río" },
    { id: "5ed39d73-5a04-43b0-977b-c161a18550cc", name: "Universidad Tecnológica del Estado de Zacatecas" },
    { id: "8d643c3a-85b3-4efb-a9d8-7f1561aa2ef1", name: "Universidad Thomas More" },
    { id: "87acf793-3e9c-4105-99e4-68c793b98beb", name: "Universidad Torcuato di Tella (UTDT)" },
    { id: "bf3c73ac-796a-4126-b804-3d05801afb0c", name: "Universidad Técnica de Ambato" },
    { id: "1383e38e-7bcc-4bbc-93d1-840573dd82ad", name: "Universidad Técnica Particular de Loja - UTPL" },
    { id: "8b2e26ef-ad2a-48d1-8a23-f321870deaa2", name: "Universidad UNIACC" },
    { id: "e1595c1d-551f-4644-868b-766d6bbe04a2", name: "Universidad Vasco de Quiroga" },
    { id: "17b724fa-dda8-4ebd-a74a-e1aeb9321f00", name: "Universidad Veracruzana" },
    { id: "be01b94a-0302-48f6-9898-105e3659da2d", name: "Universidad Vina del Mar (UVM)" },
    { id: "d5977082-4e33-412c-9a05-192fed6ef489", name: "Universidad Virtual del Estado de Guanajuato" },
    { id: "3ed32aee-c031-4853-8a8b-0e6bc3bca717", name: "Universidad Westhill" },
    { id: "4a7530f8-9ff2-4fa7-bd91-2249d76779fa", name: "Universidade Aberta" },
    { id: "344e9884-7c56-449f-824e-731a93f79463", name: "Universidade Anhembi Morumbi (UAM)" },
    { id: "54b3e225-4331-46eb-a094-fdcd1d8f58a8", name: "Universidade Braz Cubas" },
    { id: "5d2e723b-3b95-4657-bdb0-3bb828266b31", name: "Universidade Candido Mendes" },
    { id: "9d3221ec-baf2-4886-b55c-b7788a716ebe", name: "Universidade Corporativa Sebrae" },
    { id: "5abd4400-cc49-41c3-8895-fe99b5c7caf7", name: "Universidade da Coruña" },
    { id: "7ec62291-6bdd-4403-a482-51e9d927b36f", name: "Universidade de Beira Interior" },
    { id: "0d984dc3-9f7c-4006-bc7d-5ada173a694a", name: "Universidade De Brasilia" },
    { id: "f514da3b-4672-4a19-909a-49664e35c013", name: "Universidade de Caxias do Sul" },
    { id: "20924e73-09cd-4c01-a100-e2ed45d40e31", name: "Universidade De Fortaleza" },
    { id: "5b42c277-dae6-49f3-a546-40ad0ef61d74", name: "Universidade de Lisboa" },
    { id: "860d717d-3f91-4876-856d-d88bd8807712", name: "Universidade de Pernambuco UPE" },
    { id: "0843a72d-a9fb-4e13-8d20-3a28ea3a3155", name: "Universidade de Porto - School of Engineering" },
    { id: "ef1b3e1d-cdf2-447d-9172-785198f4d01e", name: "Universidade de Ribeirao Preto UNAERP" },
    { id: "fc2e6615-6c29-409d-a8f4-5ad022108674", name: "Universidade de Santa Cruz do Sul (UNISC)" },
    { id: "98a4af31-143f-4c56-a24f-3348918a3add", name: "Universidade de Sao Paulo (USP)" },
    { id: "72194f85-18d1-4a95-bc20-caf00a4f30e1", name: "Universidade de Sorocaba UNISO" },
    { id: "0e7a8b46-9d48-46d3-8785-d33336d0af59", name: "Universidade de Taubate" },
    { id: "602a476f-005c-4108-bfa5-e6aa4da6ae71", name: "Universidade de Évora" },
    { id: "266ce3e5-eaae-4059-91cb-5e1e38b7f12b", name: "Universidade do Algarve" },
    { id: "f160fe52-c1d2-49ad-8955-587f3412fd99", name: "Universidade do Estado da Bahia" },
    { id: "034176e1-6c54-4c6b-a706-ae25b4b88916", name: "Universidade do Estado de Minas Gerais UEMG" },
    { id: "0304e23b-2617-460a-8d5f-ac9fd70c27f5", name: "Universidade do Estado de Santa Catarina (UDESC)" },
    { id: "4c6d5ccd-1bfd-4cc4-a02f-fbba4b3f869c", name: "Universidade do Estado do Rio de Janeiro UERJ" },
    { id: "f39d1f49-b17c-4f59-a89f-b0642613b85a", name: "Universidade do Estado do Rio Grande do Norte Dame" },
    { id: "a3005884-38bc-4adf-8c9c-3d4cf33bfe79", name: "Universidade do Futebol" },
    { id: "6ed28bb8-ca62-428b-98bf-6f771c58eae7", name: "Universidade do Vale do Itajai" },
    { id: "25ceb76f-b8df-4ed8-88c2-c28faf0280b1", name: "Universidade Do Vale Do Rio Dos Sinos" },
    { id: "190fc3ba-a215-4151-a22b-145ee4f06f04", name: "Universidade dos Acores" },
    { id: "24c4144f-4f36-4ad0-9b58-47dc7a5ee5dd", name: "Universidade Estadual de Campinas (UNICAMP)" },
    { id: "bc624c46-d8b1-4119-a973-2518dff31493", name: "Universidade Estadual de Londrina" },
    { id: "03fabb09-4ed7-475d-be21-3102ec85bb5f", name: "Universidade Europeia" },
    { id: "19c8931d-63a4-4a18-a9a5-63b82ee23a67", name: "Universidade Federal da Bahia" },
    { id: "6d1554d0-4cfe-44b2-a595-9fcab5a18ec1", name: "Universidade Federal de Juiz de Fora FACC" },
    { id: "a462097a-39c9-4809-86ef-a75f104f40be", name: "Universidade Federal de Lavras UFLA" },
    { id: "edd310f2-19e5-423b-abad-7120a2a31d71", name: "Universidade Federal de Mato Grosso UFMT" },
    { id: "250cf52f-88cf-4862-8ce7-ef0b0f762d93", name: "Universidade Federal de Minas Gerais" },
    { id: "517a8a09-82b0-4735-99b1-db025ee9b70a", name: "Universidade Federal de Ouro Preto UFOP" },
    { id: "8bf3a5eb-ca7a-4771-affd-f53e5880096c", name: "Universidade Federal de Santa Catarina" },
    { id: "d47e0fae-132e-41cc-b00d-86bb9858bbf8", name: "Universidade Federal de Sao Carlos" },
    { id: "b4f7de5b-771f-427d-af28-4e1dfbe81a98", name: "Universidade Federal de Sao Joao del-Rei UFSJ" },
    { id: "4d26fd0e-b5e2-473a-9ae2-9bc65d27b1db", name: "Universidade Federal de Sao Paulo (UNIFESP)" },
    { id: "19619979-c589-41e1-85b4-9d0718ec8364", name: "Universidade Federal de Uberlandia" },
    { id: "c3120abc-fcdd-4494-aa75-f59fd3d5bc02", name: "Universidade Federal de Vicosa UFV" },
    { id: "73c98c23-30cd-4333-9c9e-70a91ffb2dc3", name: "Universidade Federal do ABC" },
    { id: "b8322c67-8e50-4892-95a7-2919db5e0218", name: "Universidade Federal do Amazonas UFAM" },
    { id: "20cf842e-f326-4a6d-a661-42c9361fa5ac", name: "Universidade Federal do Ceara" },
    { id: "8c429a05-8b1b-487c-9bc2-4df936beb00c", name: "Universidade Federal do Espírito Santo - UFES" },
    { id: "e5dd8269-b21a-4203-af54-6caae64b68d8", name: "Universidade Federal Do Parana" },
    { id: "50a0c71f-e1e2-4105-8ebe-2d2138318fd6", name: "Universidade Federal do Piaui" },
    { id: "047b5c8e-dd67-42d7-918d-4bf4710f11e6", name: "Universidade Federal do Rio de Janeiro" },
    { id: "ef31fae7-bf16-4385-a14b-de1b0bc001d0", name: "Universidade Federal do Rio Grande do Norte" },
    { id: "fcc7baee-12cf-46e0-8c7d-a0afb6297a2a", name: "Universidade Federal Do Rio Grande Do Sul" },
    { id: "6b091b19-38a5-49d7-8064-258057767a72", name: "Universidade Federal Fluminense (UFF)" },
    { id: "a9fe9b61-728e-4254-afcb-6660a91cddd0", name: "Universidade Feevale" },
    { id: "7fca447d-eeeb-4c07-8530-c60905895e8f", name: "Universidade Fernando Pessoa" },
    { id: "079cf7ee-3024-43c8-b08f-91c4fcd404b7", name: "Universidade FUMEC" },
    { id: "c670c6e3-a0fd-4c2d-b7e0-211f76ad3ec4", name: "Universidade Gama Filho UGF" },
    { id: "a2982e2c-bd6e-4d9b-8f3f-61b6eb689410", name: "Universidade Lusofona de Humanidades e Tecnologias" },
    { id: "8d71408c-1980-4333-99a1-ee149b514d21", name: "Universidade Luterana do Brasil ULBRA" },
    { id: "0d29b860-8225-4daf-ac82-db5c6380e449", name: "Universidade Metodista de Piracicaba UNIMEP" },
    { id: "025d4677-3eaa-476f-a3ed-23dd5c701779", name: "Universidade Metropolitana de Santos UNIMES" },
    { id: "a8e72648-308c-46ea-85ad-44013caa0c83", name: "Universidade Municipal de Sao Caetano do Sul USCS" },
    { id: "07e38a17-ba29-4904-b753-5bca79762b5c", name: "Universidade Nova de Lisboa (UNL)" },
    { id: "26bff795-0549-4581-857a-6d97dccdccfe", name: "Universidade Nove de Julho" },
    { id: "2c7298bd-908b-4b8a-bc0a-5b07f4429e78", name: "Universidade Paranaense UNIPAR" },
    { id: "5a14b94c-3e9e-4d96-9704-8bf0ee669de2", name: "Universidade Positivo" },
    { id: "36797a50-d570-4615-8a97-2ff33f006918", name: "Universidade Potiguar (UNP)" },
    { id: "126e61d9-1780-4593-b3fb-32fd6bdf8ae7", name: "Universidade Presbiteriana MacKenzie" },
    { id: "cf5d072f-6a11-4702-96da-68ffb853d440", name: "Universidade Regional de Blumenau FURB" },
    { id: "7ae2b620-d7aa-4545-9ea4-b184a91c6a66", name: "Universidade Salvador (UNIFACS)" },
    { id: "2dc77333-7e2e-4ec2-bc0c-255cb96ee153", name: "Universidade Sao Francisco USF" },
    { id: "6485dd40-6670-4e35-b8f4-d061faac4348", name: "Universidade Vila Velha UVV" },
    { id: "74bb6a46-3fca-4b12-b724-3c2dfddfe69d", name: "Universio IPCA" },
    { id: "351bf010-2e3c-4421-a14d-57f6b6d46d46", name: "Universita Carlo Cattaneo (LIUC)" },
    { id: "e0e06cf1-c51b-439f-a81d-308dcca28bbd", name: "Universita degli Studi di Firenze" },
    { id: "ef9922e0-1582-42dd-981f-42183edd34ce", name: "Universita degli studi di Messina" },
    { id: "c3d34182-7af5-4241-8fdd-f4e1baa70fb2", name: "Universita degli Studi di Milano" },
    { id: "b13bd66d-319d-4359-90ab-b42ffd88a223", name: "Universita degli studi di Padova" },
    { id: "40ea4255-578c-4951-91b5-96cd8adbce0c", name: "Universita degli Studi di Palermo (UNIPA)" },
    { id: "b2766b7c-ef53-4d5b-a73b-8dc6f4d74888", name: "Universita degli Studi di Parma" },
    { id: "16542ed9-ddb6-4ba9-a0d3-ca98e6c2a2e8", name: "Universita degli Studi di Pavia" },
    { id: "f22a8b42-ebce-4fb5-ab77-e11d03bcd1e9", name: "Universita IULM" },
    { id: "4c8d9e00-bda5-4afe-a6c1-3ba1f24565a7", name: "Universita Politecnica Delle Marche" },
    { id: "af2f0d78-a3fd-4b98-a81b-39180991a3c6", name: "Universita Studi Di Pavia" },
    { id: "eef2ae05-5945-4544-8337-d13e1a14219a", name: "Universita' Di Scienze Gastronomiche" },
    { id: "96339487-701f-4b0a-8800-11e2a7618395", name: "Universitaet Leipzig" },
    { id: "15c1d3d9-59bf-457d-b03c-d434c50f3714", name: "Universitaet Osnabrueck" },
    { id: "15da5a13-4988-4b77-b594-6b7d79e593f1", name: "Universitaet Zuerich" },
    { id: "26f0ae5a-dce8-4f58-8637-f25d9c87821a", name: "Universitaetsbibliothek Leipzig" },
    { id: "ac8cfb93-dfc3-4169-8b28-fbd078b3d676", name: "Universitas 21 Global" },
    { id: "87667f5d-ad0d-4a89-8002-17e23d10a8e1", name: "Universitas Bakrie Manajemen" },
    { id: "23c07ec6-11be-46b7-9269-727f085ace4c", name: "Universitas Esa Unggul" },
    { id: "66ac868b-8a0f-4b07-b550-5e33e67d3c1e", name: "Universitas Lambung Mangkurat" },
    { id: "d6003e9c-ecc8-4403-8c1b-3d8e7ef8119a", name: "Universitas Methodist Indonesia" },
    { id: "2433addb-5ced-47d2-9e5f-f0d34bf98f80", name: "Universitas Pelita Harapan" },
    { id: "48802a4b-202b-4d5e-8024-c4ee1e2a04ee", name: "Universitas Sanata Dharma" },
    { id: "6dc27202-03f1-464c-9d1a-0b0db88663fc", name: "Universitas Siswa Bangsa Internasional (Sampoerna University) (SU)" },
    { id: "2002718b-97ad-403c-973c-0f5c8ef09500", name: "Universitas Surabaya" },
    { id: "6a150530-d490-480d-98f4-2fda2199ff4d", name: "Universitas Widyatama" },
    { id: "f8537566-4c66-4209-b321-ac95a24a7809", name: "Universitat Abat Oliba" },
    { id: "b5f10528-a14e-481f-aed0-c8adb3e5a1ff", name: "Universitat Autonoma de Barcelona (UAB)" },
    { id: "a9d57be1-6ec0-4919-8dc6-51d94ca71c74", name: "Universitat Bamberg" },
    { id: "a3f5bbe6-f4e0-4ff4-9acb-0513fdb3c2ec", name: "Universitat Basel" },
    { id: "087ee243-fabe-48d9-bb43-caa7cfb06e5c", name: "Universitat de Girona" },
    { id: "32128ce8-d64a-4520-bb09-a5b9545e2ecc", name: "Universitat der Bundeswehr - Munich" },
    { id: "d02dff82-103e-4970-9322-f4a0dd00f19e", name: "Universitat Des Saarlandes" },
    { id: "db01032f-ab24-458a-9ee9-9aa385c584ca", name: "Universitat Duisburg-Essen" },
    { id: "c22ee580-b143-4715-8b3a-7427c23bc9a8", name: "Universitat Erfurt" },
    { id: "b7598d10-c002-4f83-b854-261cd2bd01d7", name: "Universitat Flensburg" },
    { id: "edd2fe9c-9a2f-4ec3-ac5b-fd1072cbf18c", name: "Universitat Hildesheim" },
    { id: "cb2a3735-5fcb-46b9-9156-3554e736a77b", name: "Universitat Innsbruck" },
    { id: "9a5f2f00-4935-413c-b21f-49a9c746a264", name: "Universitat Internacional de Catalunya" },
    { id: "c9c8570a-1a9e-476a-a8f7-b9cc17a39820", name: "Universitat Jaume" },
    { id: "3199b9a1-c8c0-4fe2-aa5d-485b115c1ce7", name: "Universitat Jaume I" },
    { id: "ba62fc40-62a0-40d3-a047-acfbfcc0416a", name: "Universitat Oberta de Catlunya (UOC), Spain" },
    { id: "c3ccbd54-2df1-4d72-9c5f-d0e31cd3ea2f", name: "Universitat Paderborn" },
    { id: "ffe9f636-9d25-497e-98a0-674980484fbd", name: "Universitat Politecnica de Catalunya" },
    { id: "b40adbe1-92bc-49ef-81e7-9d5305d03c20", name: "Universitat Pompeu Fabra (UPF)" },
    { id: "f517203e-1261-4774-9d9e-44c4df01f36a", name: "Universitat Ramon Llull" },
    { id: "9b8111b5-5d69-4bb9-a3b3-bcc5e8d4d7a4", name: "Universitat Siegen" },
    { id: "51c53697-3d49-4a28-8079-0a93a772309d", name: "Universitat zu Koln" },
    { id: "8ecbc2ee-78d0-41c4-b711-91df71428ead", name: "Universitatea Babes-Bolyai" },
    { id: "ce13e14f-932c-46dd-8001-2327ccb34db6", name: "Universitatea Emanuel Oradea" },
    { id: "22d56f52-b4e9-4aca-8a69-97f27f61d446", name: "Universitatea TITU MAIORESCU" },
    { id: "f84f8f07-0080-404a-8ff5-39e24772a8f6", name: "Universita  degli Studi di Torino" },
    { id: "199272e3-5bd2-4992-ac65-401854952902", name: "Universite Aix-Marseille II" },
    { id: "225d48cc-1fc5-4afd-b88b-2e50109419cf", name: "Universite Blaise Pascal Clermont 2" },
    { id: "c0e02ee3-f8bd-4128-bc77-d716738fb04f", name: "Universite Bordeaux 1" },
    { id: "dd44ef82-90f0-4f4a-891e-eec3df563bfa", name: "Universite Bourgogne IAE Ufr Sciences Economiques" },
    { id: "c29dee82-3624-4f64-aef6-5bf3395049fd", name: "Universite Cadi Ayyad" },
    { id: "40aab81e-3408-46c0-91fa-23b6d0bcfa3c", name: "Universite de Grenoble" },
    { id: "79a2f0bd-d118-4a4c-96b1-d78e539f8762", name: "Universite de Hearst" },
    { id: "439a81ef-317c-4375-b5f8-7d9964973e85", name: "Universite de Neuchatel" },
    { id: "492c6250-6539-469b-98d3-1243b06d54c4", name: "Universite de Pau et des Pays de l'Adour" },
    { id: "c85e5720-c954-47f7-a75e-46533d5d405d", name: "Universite des Mascareignes" },
    { id: "26898274-db7f-4e64-b8ed-6443e16107ab", name: "Universite Du Littoral Cote D'Opale" },
    { id: "f49a4011-ce40-48af-b5d5-cf7cc88d0866", name: "Universite du Quebec A Montreal" },
    { id: "a30e9ab4-57f6-4a20-aa27-477818b7b160", name: "Universite du Quebec a Rimouski" },
    { id: "c830f004-1f99-4dd8-8328-a6d76b553920", name: "Universite du Quebec a Trois-Rivières" },
    { id: "dddc0909-1d19-4a21-971d-bd9472de4f5c", name: "Universite Francois-Rabelai Tours" },
    { id: "c7490ff8-9f05-46f7-abc2-07c06ef89212", name: "Universite Haute Alsace" },
    { id: "4e2f3ace-292c-4e8d-af52-57dc8a7305c7", name: "Universite IFM Geneve - Institut de Finance et Management" },
    { id: "8cfba426-f218-400e-ba37-7203deb74c99", name: "Universite Internationale Casablanca (UIC)" },
    { id: "bddcc934-9075-4f3f-89a8-6ba58d89dff3", name: "Universite Jean Moulin Lyon 3" },
    { id: "bbe4252a-19c9-41aa-865d-4d462aed00bd", name: "Universite Laval" },
    { id: "00fc4cb7-16bf-49f4-8ab2-0e77f24e85db", name: "Universite Libre de Bruxelles" },
    { id: "3e253779-2bc9-42a4-8b9d-8ce984148322", name: "Universite Libre de Bruxelles - Solvay Brussels" },
    { id: "beb3bbea-e349-4a18-a8fc-ef5b4598eb94", name: "Universite Lumiere Lyon 2" },
    { id: "1efdd3cd-b507-483d-a8a5-c5b4950f2558", name: "Universite Mohammed V-Rabat" },
    { id: "51d367e2-35d7-43fb-a1cd-b3c7e7836cc3", name: "Universite Montpellier 2" },
    { id: "653223b4-7548-4c34-bbda-5a9a823bc8d0", name: "Universite Mundiapolis" },
    { id: "a0e4d1a0-ec11-4005-969d-ebf1fbc2a87b", name: "Universite Nantes" },
    { id: "7020170f-391e-4b75-8566-d9f183d42fb5", name: "Universite Pantheon-Assas Paris II" },
    { id: "a835db95-2b98-48b7-977f-a7efdfd683c2", name: "Universite Paris 1 Pantheon-Sorbonne" },
    { id: "86004b6d-dc29-4b19-b0ef-5a1b259d63dc", name: "Universite Paris Dauphine" },
    { id: "cf62bf8a-bb52-4b04-bfa5-70e495e69351", name: "Universite Paris-Est Marne-la-Vallee" },
    { id: "d5de6e41-1f54-44fa-ad43-1ae8d7aaf4fd", name: "Universite Paris-Saclay" },
    { id: "a3d7a6dd-4d31-4685-90a9-8ddc590d9417", name: "Universite Paris-Sud 11" },
    { id: "83fd06ea-fe0a-4250-bc20-3fe9eabba722", name: "Universite Paul Valery Montpellier 3" },
    { id: "02b03529-cd66-4786-b69b-623346cc2d24", name: "Universite Pierre Mendes" },
    { id: "6e150dee-ea64-48c4-ac18-3190309c2fc7", name: "Universite Saint-Esprit de Kaslik" },
    { id: "ff1256e2-7762-4250-8001-f722df12f01a", name: "Universite Saint-Joseph de Beyrouth" },
    { id: "e2757652-c56e-4a1d-8d1d-18f98009dc24", name: "Universite Savoie Mont Blanc" },
    { id: "5fc8bdee-0de8-40f1-a9bf-cc7ae743966d", name: "Universite Wesford Geneve" },
    { id: "3c7f270f-8f72-4554-b061-3577d0e1049a", name: "Universiteit Hasselt" },
    { id: "342c302d-c510-414f-ad36-a347fc0b6427", name: "Universiteitsbibliotheek Leiden" },
    { id: "9115994c-534c-40be-9780-920a73693aca", name: "Universitetet i Agder" },
    { id: "d1c070e1-8e64-430f-aee1-8382b898d501", name: "Universitetet i Stavanger" },
    { id: "61e99ee8-d631-4f2a-93af-6b67910b9433", name: "Universiteti Akademia E Biznesit" },
    { id: "1565c31a-afd3-45a0-aa2c-82dd4c35dc86", name: "Universiteti Luarasi" },
    { id: "72285dae-8464-42c1-b76a-7584dc5466de", name: "Universiteti Marin Barleti" },
    { id: "b494ca95-9e3d-4529-8739-1735a0a9dfe8", name: "Universiti Brunei Darussalam" },
    { id: "cdd101a9-38d2-4a4e-9ad8-0dc511394576", name: "Universiti Kebangsaan Malaysia" },
    { id: "4392880e-85bd-434e-a021-ed80acd52897", name: "Universiti Kuala Lumpur" },
    { id: "817642e3-d07d-4ae6-b944-d73d8b6b000b", name: "Universiti Malaya" },
    { id: "891b0571-7185-4e32-be72-c811c7f7c743", name: "Universiti Malaysia Kelantan" },
    { id: "523bcfe9-e719-4aa4-9124-58d2555c6f9d", name: "Universiti Malaysia Pahang" },
    { id: "56f2eeac-3808-42a1-8047-03a3c7b32b9b", name: "Universiti Malaysia Perils" },
    { id: "20ca5826-dcf0-4558-9ee8-f02d8cc5af8b", name: "Universiti Malaysia Perlis" },
    { id: "3f78bd84-d427-4e1f-b4e0-8ef557406082", name: "Universiti Malaysia Sabah" },
    { id: "c50438fa-bddd-4d5c-a44d-476678a2d8da", name: "Universiti Malaysia Sarawak" },
    { id: "efdad80c-7e28-49e2-9cc9-47d63a21caab", name: "Universiti Malaysia Terengganu" },
    { id: "618b7d60-dc07-45cd-9d87-0414440b7592", name: "Universiti Pendidikan Sultan Idris" },
    { id: "0345bfc0-3a89-4de5-94a8-495c12341a5f", name: "Universiti Pertahanan Nasional Malaysia" },
    { id: "9fa4879b-dfa2-43f7-ba28-01f4f8e9236a", name: "Universiti Putra Malaysia" },
    { id: "6307facd-b32e-4a50-bd24-96216bcae755", name: "Universiti Sains Islam Malaysia" },
    { id: "2ecda9fa-549c-4760-b57b-396f36ae28ca", name: "Universiti Sains Malaysia" },
    { id: "936ea29f-91d1-43a1-8da1-9d4cd43472b6", name: "Universiti Sebelas Merat" },
    { id: "309ef39d-1713-4bcb-a27f-b1076b8f89ee", name: "Universiti Selangor" },
    { id: "f6939f5c-e4cd-4a86-baa1-1700282284e9", name: "Universiti Teknikal Malaysia Melaka" },
    { id: "91c4d4ae-3915-4d26-b9fd-a9068bec3500", name: "Universiti Teknologi Brunei" },
    { id: "d99e7227-fff3-4b78-8efd-c255663e3944", name: "Universiti Teknologi Malaysia" },
    { id: "88c585f6-1087-4619-919f-a3c34c5625c2", name: "Universiti Teknologi MARA" },
    { id: "f32fbace-6ceb-4ca7-8173-57b5c3ac3921", name: "Universiti Teknologi MARA (Perak)" },
    { id: "03d7ba95-5fd3-4693-8836-37566562a217", name: "Universiti Teknologi MARA (UiTM)" },
    { id: "a9beca30-ab76-4eab-8bfe-c0962f79f149", name: "Universiti Teknologi PETRONAS" },
    { id: "faec13a6-0f1f-4a96-8a80-c0a56adfe1b7", name: "Universiti Tun Hussein Onn Malaysia" },
    { id: "bcc01bc2-7ef3-4318-8e49-c9ac25a5ef82", name: "Universiti Tunku Abdul Rahman" },
    { id: "9c304166-4bae-433a-89cf-6c6dfe55d9eb", name: "Universiti Tunku Abdul Rahman (UTAR)" },
    { id: "664420bd-db11-499d-8aea-9287b521f2d7", name: "Universiti Utara Malaysia" },
    { id: "34dede3e-89ec-42f0-afdb-13080d4b38a3", name: 'University "Lucian Blaga" - Sibiu' },
    { id: "41c009a1-09cf-47f4-9c7e-03995a524a17", name: "University American College Skopje" },
    { id: "af2183f4-ec49-4399-9ba8-cb9399752a7c", name: "University Angers" },
    { id: "6760a5f0-dcff-449e-9709-1c0b072388b5", name: "University at Albany - SUNY" },
    { id: "b229bc0e-40d6-48bd-82d6-050b28414fc2", name: "University at Buffalo" },
    { id: "4dff0573-4c29-497a-a615-cb67c451634f", name: "University Bk & Supply" },
    { id: "10cd89e7-f861-4e6a-835d-aed59700b9f5", name: "University Bk Str Inc" },
    { id: "e5e9cb0f-09c3-4cd9-bb82-8594e7babe4f", name: "University Bookstore" },
    { id: "6e68105e-863d-4f4e-a9c6-fd482baf40e3", name: "University Brunei Darussalam" },
    { id: "f18ac2ad-bbb4-4afa-84e8-da7da5904306", name: "University Buenos Aires Argentina (UBA)" },
    { id: "2475032d-d400-4094-a418-b7b9667bf38f", name: "University Caen Normandy" },
    { id: "098ac6bd-1b88-486d-82ec-7d9cea18fc22", name: "University Campus Suffolk" },
    { id: "93fbc053-e7c2-49e4-a7b9-f19b69165e81", name: "University Canada West" },
    { id: "f1ea45fc-60cd-4973-b3cc-08c3f9d43ae1", name: "University Center Cesar Ritz" },
    { id: "a58df9a5-a9f0-4ec9-b1f5-397700e276ec", name: "University Clermont Auvergne Ecole de Mgmt" },
    { id: "b14cf7aa-0696-4e32-a769-09d45566aa47", name: "University College - Syddanmark" },
    { id: "6ffbd1df-5b90-4bd7-a68a-9d7550b84ae0", name: "University College Bestari" },
    { id: "37485f5e-599b-4dd1-9b48-1e047031d98a", name: "University College Birmingham" },
    { id: "aeff2eb9-4c81-4238-9913-e3ac2901a806", name: "University College Cayman Islands" },
    { id: "1989c338-1a51-4d4d-bda0-88cda331576f", name: "University College Cork (UCC)" },
    { id: "7cc4aadc-14db-4c81-822b-5003a3baaf82", name: "University College Dublin (UCD)" },
    { id: "0c4a5cc9-5b32-40cf-97b0-b8b324ed7a2c", name: "University College London" },
    { id: "51580a87-423a-4d8e-b185-d47edd1d43bb", name: "University College Nikola Zrinski" },
    { id: "c73df982-f37a-4561-8905-0549b5b4ec70", name: "University College of Gavle" },
    { id: "981aa504-1354-4c17-a12a-1c886d8a3ad7", name: "University College of Northern Denmark" },
    { id: "fb578d5c-ba7a-4510-8a2e-3fede2cec6f6", name: "University College of the Caribbean" },
    { id: "0c83f4ab-fef8-4efb-ac26-b779f1015994", name: "University College Southeast Norway (HSN)" },
    { id: "98ef1a4c-255a-480b-8a1d-129bc2c5482a", name: "University College Zealand" },
    { id: "61a86455-94f6-4ecc-a7c3-1b9b02331b07", name: "University Cooperative Society" },
    { id: "35928fed-f8fb-46fb-8b03-7329088ea03b", name: "University Copy & More" },
    { id: "ed86a00e-5b80-43c5-ad5a-5f70812f803f", name: "University de Alcala" },
    { id: "d686be28-1a72-49ce-b816-9c9f93acb3a6", name: "University Donja Gorica" },
    { id: "fab803f7-2fdc-45d4-8199-acdf90aeb8c7", name: "University for Music and Performing Arts Vienna" },
    { id: "f9acd2a0-d6a4-4213-bd8b-cac378197baf", name: "University for the Creative Arts" },
    { id: "b8976534-b9bd-49d4-b856-cbd3697bddea", name: "University G. D'Annunzio" },
    { id: "0b06687e-a75b-4a22-9dab-613e09c392a2", name: "University Ibn Tofail" },
    { id: "0e31b0e8-9ae2-4251-89ea-1b09f5025970", name: "University KwaZulu-Natal" },
    { id: "20939d9d-aa17-4858-9a87-d15ec361e5be", name: "University Library Northern Illinios University" },
    { id: "1f3835ff-e505-4762-9d40-aee0f5d78a11", name: "University Lyon IAE" },
    { id: "d8c3cc18-9e1b-4146-a27e-1a4a28206184", name: "University Malaysia of Computer Science & Engineering" },
    { id: "3f62d224-cc7f-4f5d-8e5a-9298d350cedd", name: "University Nuertingen" },
    { id: "78317621-52f5-4650-ae7f-6107cc98af10", name: "University of Aberdeen" },
    { id: "b06ef5d9-bf35-4f13-8ff8-2bb838b386ad", name: "University of Abuja" },
    { id: "41072aee-f404-48dd-a6eb-a5316faafc23", name: "University of Adelaide" },
    { id: "bd5c6bc7-18a9-4540-bf62-9be2afed7125", name: "University of Advancing Technology" },
    { id: "d0d6d0e2-01ec-4aca-9877-c02e173a81fb", name: "University of Agriculture, Makurdi, Nigeria" },
    { id: "0ad8e2b9-88f1-413a-886f-c852f65037d9", name: "University of Agriculture, Peshawar-Pakistan" },
    { id: "c790e019-8028-4a42-9ad4-74f0665c7bb4", name: "University of Airlangga" },
    { id: "6200a0cc-907e-4069-b1da-6e4a2167527a", name: "University of Aix Marseille" },
    { id: "14fd3766-811d-497e-8501-0bdd4cbaee79", name: "University of Akron" },
    { id: "bf60c2f6-b07c-4cd7-9019-6e62631e3079", name: "University of Akureyri" },
    { id: "e9b4128e-8ba9-4cc2-8f42-6146ac2bebb0", name: "University of Alabama at Birmingham" },
    { id: "9d8e39d7-5e7a-443c-b28f-334303923760", name: "University of Alabama at Birmingham - Snoozy's College Bookstore" },
    { id: "d500e4b8-195c-40f8-a05a-9452f4ce3003", name: "University of Alabama in Huntsville" },
    { id: "ea13ed71-047a-47f1-92c8-ba7d74573e65", name: "University of Alaska" },
    { id: "f6ecd59a-9793-4183-91ab-f1365962946b", name: "University of Alaska - Anchorage" },
    { id: "602bed4b-e921-49ec-870d-a59d69fd52fd", name: "University of Alaska - Fairbanks" },
    { id: "7d8158a0-2128-4c57-ba1d-6fefd49e8403", name: "University of Alaska Southeast" },
    { id: "f8d06121-145c-4de4-8eb8-b0d13c8aa3a9", name: "University of Alberta" },
    { id: "5b1ff1a3-650c-4be1-a06a-8d35628698af", name: "University of Amsterdam" },
    { id: "a9905353-ca99-4d01-80a5-4365f973252d", name: "University of Antananarivo" },
    { id: "1655693d-7ce3-4dac-852e-bd9a7dd172b2", name: "University of Antwerp" },
    { id: "7af2558f-e339-41bb-bdf9-cbedf5bcf9e1", name: "University of Applied Arts Vienna" },
    { id: "bc7a9557-827c-4b11-9b2c-533ef34d1533", name: "University of Applied Management" },
    { id: "56741dcd-90f9-4bff-af67-d2fc1335860d", name: "University of Applied Science - Frankfurt" },
    { id: "e4752dc3-3c62-4895-ba32-29885d459cba", name: "University of Applied Science - Fresenius" },
    { id: "1c293200-63d5-4560-b4b2-b2e41255ae88", name: "University of Applied Sciences" },
    { id: "b6cffe36-e47c-486c-bd34-250d00392ad7", name: "University of Applied Sciences - Ingolstadt" },
    { id: "905933df-f096-4654-88f0-2365704cc892", name: "University of Applied Sciences - Kempten" },
    { id: "b14069ac-adf4-4462-be28-3260757b69d4", name: "University of Applied Sciences - Landshut" },
    { id: "bbaeff9e-3a09-4e32-95fb-21ef66bc0067", name: "University of Applied Sciences - Mainz" },
    { id: "e41e52af-75f4-4feb-b82e-957326fe955c", name: "University of Applied Sciences - Northwest Switzerland" },
    { id: "ded0fc66-4ca4-4d20-a820-6c39f21ffcf6", name: "University of Applied Sciences - Offenburg" },
    { id: "934ad01a-8b11-4bb6-99ae-2f300ee98217", name: "University of Applied Sciences - Utrecht" },
    { id: "c655470f-c998-4d9d-9e60-67ba69cd2fa1", name: "University of Applied Sciences - Wedel" },
    { id: "466d7010-adb6-4bb9-81f8-5fdeae8d1ebb", name: "University of Applied Sciences BFI Vienna" },
    { id: "dda51054-699e-4a68-924c-fbb74e2404d0", name: "University of Applied Sciences Coburg" },
    { id: "d861f710-b110-4669-883a-6a43c19f9bee", name: "University of Applied Sciences Hof" },
    { id: "7f4351cd-d5c2-41f2-8224-528f1e1cc7a1", name: "University of Applied Sciences HTW Chur" },
    { id: "35a06673-3fe3-452f-86e8-733d75f728be", name: "University of Applied Sciences Kufstein" },
    { id: "946dc83f-19e8-4075-b12a-0551e12d8021", name: "University of Applied Sciences of Eastern Switzerland" },
    { id: "f2929910-dc96-417f-abde-e161b8f77790", name: "University of Applied Sciences of Northwestern Switzerland" },
    { id: "15731d66-42de-43d3-885f-dfebda5b69a5", name: "University of Applied Sciences Rhein-Main" },
    { id: "65518355-1f11-4d07-912b-daf8492345cf", name: "University of Applied Sciences Rhine-Waal" },
    { id: "60aeb13d-0202-4cca-a67b-4217a952be6f", name: "University of Applied Sciences Rosenheim" },
    { id: "3a6647f2-b4b1-4cb5-931d-b3dc8b158e8c", name: "University of Applied Sciences Schmalkalden" },
    { id: "975c542d-84b9-4c68-86db-d81eb1db0351", name: "University of Applied Sciences Technikum Wien" },
    { id: "222e2947-f306-49d5-bf25-88fb1c48fc43", name: "University of Applied Sciences Trier (Fachhochschule Trier)" },
    { id: "004ef093-4218-4945-b910-c3e785ccffb9", name: "University of Applied Sciences Weihenstephan-Triesdorf" },
    { id: "093430b1-a1b8-46f7-b97c-4b49720d75b3", name: "University of Applied Sciences Western Switzerland" },
    { id: "845c7da3-ef84-4d05-bdb5-100e604a9158", name: "University of Applied Sciences, Brandenburg" },
    { id: "38f6e58d-4aeb-4ffb-9d66-1724ae2feb3c", name: "University of Applied Sciences, Ludwigshafen" },
    { id: "887721ef-e763-4dcd-be80-1c6de6caa389", name: "University of Arid Agriculture" },
    { id: "08b0469f-c921-43b7-8981-318d16508175", name: "University of Arizona" },
    { id: "28a954b5-4f03-4cc4-ae51-91026da6c6d1", name: "University of Arkansas - Fayetteville" },
    { id: "bfbb5d95-66ba-4ae4-a542-843622e5b72a", name: "University of Arkansas - Fort Smith" },
    { id: "881429ea-0c82-401c-a344-0d6bd54b930e", name: "University of Arkansas - Little Rock" },
    { id: "c1c5555d-d409-4731-af55-97bb98f27a9a", name: "University of Arkansas - Pine Bluff" },
    { id: "0131cc37-0285-4366-ae33-1941a3234c8b", name: "University of Arkansas Grantham" },
    { id: "405386d8-988a-4a08-b002-9e9ac5542936", name: "University of Artois" },
    { id: "8692c2c8-6f33-4542-9ac9-76837189f17f", name: "University of Arts and Industrial Design Linz" },
    { id: "c12ec1bf-909d-4068-9735-134a0d77b812", name: "University of Asia and the Pacific" },
    { id: "3b12a9d9-15e4-4c81-a822-681e58a74d6d", name: "University of Atacama" },
    { id: "3df61ead-4949-46ca-9819-84f1f7806624", name: "University of Athens" },
    { id: "d83f9dfc-5ff4-4823-aa85-0ca12d867327", name: "University of Auckland" },
    { id: "21b7da38-0e1d-4a13-9096-932940c2c007", name: "University of Augsburg" },
    { id: "6642fb2e-34f8-4858-8e0f-1d6979219bfb", name: "University of Aveiro" },
    { id: "4599b898-cf5a-4888-95d4-34d740ac75d7", name: "University of Bahrain" },
    { id: "440660f7-394b-4cfc-9095-3e63e39a714d", name: "University of Balamand" },
    { id: "5e9a6087-bb52-49fd-a05c-0ab9baeef9e7", name: "University of Ballarat" },
    { id: "11dd35a4-79a4-42a9-8d02-7f3208e7cd4f", name: "University of Balochistan" },
    { id: "5623930f-4847-45a9-ac8c-614754d42d7a", name: "University of Baltimore" },
    { id: "268db711-fe22-4b66-9018-62fe7b3e391f", name: "University of Basel" },
    { id: "6812ba10-4ec4-4c37-81df-2348a395cb6e", name: "University of Batangas" },
    { id: "d106167f-5d5f-4d48-8d8b-eb665bd08f2c", name: "University of Bath" },
    { id: "ca676024-6256-4430-a7ee-5e1a873805c8", name: "University of Bayreuth" },
    { id: "97857017-6cb8-49a5-a6cb-f13ca65bd637", name: "University of Bedfordshire" },
    { id: "37a9238c-b8c4-4dc4-b0c9-9c12c5401c51", name: "University of Belgrade" },
    { id: "0a355624-411c-46c5-b582-877345796253", name: "University of Belgrano" },
    { id: "cc07a85a-60b5-4e6c-ba37-f148388c9464", name: "University of Beliz Bookstore" },
    { id: "90abc916-5490-43a1-aeca-66f0ed813f4a", name: "University of Benghazi" },
    { id: "384eb3be-9bef-42f9-913c-f1fc38092652", name: "University of Bern" },
    { id: "1b4e72f4-b03f-4f33-95a1-052ca7fba0bf", name: "University of Bertagne Sud DSEG" },
    { id: "46054bbc-300e-4458-927b-57634974034d", name: "University of Birmingham" },
    { id: "9bc6c1ab-1363-4c6e-96d4-fbe6539586e7", name: "University of Bojnord" },
    { id: "4e5b3078-2faa-4e74-9b57-3c114d753341", name: "University of Bologna" },
    { id: "c6a8a230-2d74-4af3-8d3e-d0290aa4d2f7", name: "University of Bolton" },
    { id: "9f8c2b22-d798-4aca-9cdf-76ba5cfd1f6d", name: "University of Bonn" },
    { id: "27e389e9-4e8c-46be-a166-d4aba15ee680", name: "University of Borås" },
    { id: "4652062e-36a1-4dbb-bbe3-474d7208c1a2", name: "University of Bradford" },
    { id: "c39085c8-e818-4f54-b09b-f3e4c3e68c4f", name: "University of Bretagne SUD Faculte DSEG" },
    { id: "067f43f0-a097-4e0a-8300-3f56d062b70a", name: "University of Bridgeport" },
    { id: "4e68062e-14fd-421f-94b1-cd58713fadc0", name: "University of Bridgeport Bookstore" },
    { id: "257f4a11-05a5-498a-a3a0-2223f95f1534", name: "University of Brighton" },
    { id: "571db46b-8d0a-4c5b-ba2b-c85707000770", name: "University of Bristol" },
    { id: "a80d3eb8-bc3d-42c9-95f8-681b3aa55b55", name: "University of British Columbia" },
    { id: "2bda6800-d26a-4838-8110-a24668e36b68", name: "University of Bucharest" },
    { id: "29003da3-c4cb-4c3a-af74-29d73b57cf70", name: "University of Buckingham" },
    { id: "a21105a2-9a04-46d9-9351-891ef7d11f92", name: "University of Buea" },
    { id: "59af322f-c791-4330-8d75-20aad6491295", name: "University of Buffalo - Law School" },
    { id: "7a2d628a-a32f-478b-94c9-8f5c511ba41b", name: "University of Business and Technology (UBT)" },
    { id: "205e8dda-84fd-4f98-9b1d-eeb7119e228f", name: "University of Calcutta" },
    { id: "a1169994-9c0f-431c-a97d-0d60545396be", name: "University of Calgary" },
    { id: "c5579aec-e36b-4cdf-80ac-f7e5dbe65688", name: "University of Calicut" },
    { id: "71e72bd2-28cb-43d0-84c9-c1a838af3d1a", name: "University of California" },
    { id: "dd344ac7-221b-4990-adbd-637da69efe27", name: "University of California - Berkeley" },
    { id: "1e6c9904-e6b0-456a-90a6-6c65905ee50f", name: "University of California - Berkeley Extension" },
    { id: "7b24e483-66df-4122-9002-40cebb99c9f5", name: "University of California - Davis" },
    { id: "c3b983fa-f441-4f87-8e2b-c05ab5390799", name: "University of California - Hastings College of the Law" },
    { id: "b982e7d7-3735-44dc-a106-30b317d82474", name: "University of California - Irvine" },
    { id: "494236b0-ca81-4020-a426-43d713b5cb1a", name: "University of California - Los Angeles" },
    { id: "96dbb48f-585e-4d55-86fd-c1e16ab6f724", name: "University of California - Los Angeles Extension" },
    { id: "8d093ca1-15c3-4272-8ec9-2a33f0b9730f", name: "University of California - Merced" },
    { id: "761c7934-4584-4d9a-ba42-4e11d2628d9c", name: "University of California - Riverside" },
    { id: "b38abaf9-0aa0-464d-8dc9-10e6cf105f78", name: "University of California - Riverside Extension" },
    { id: "57d2ceef-331c-4047-a915-19c3abe8800b", name: "University of California - San Diego" },
    { id: "19e4a949-02d9-4bd9-bedc-352c7f3817b5", name: "University of California - San Diego Extenstion" },
    { id: "4928b7db-ffb6-41a9-b00a-cb329a59c7c5", name: "University of California - San Francisco" },
    { id: "ff558887-a7fa-4242-b8a6-f2cb58319daf", name: "University of California - Santa Barbara" },
    { id: "af6e66cb-e133-4ced-bb25-951884284823", name: "University of California - Santa Cruz" },
    { id: "ed6a4011-efda-4801-8d06-f054db2d8a30", name: "University of Cambridge" },
    { id: "c7e8b55c-c75a-491e-84d5-56f49f9fee8c", name: "University of Canberra" },
    { id: "72eb836b-2817-4a29-b4d8-0f2680dbecad", name: "University of Canterbury" },
    { id: "69f2ff28-03d6-4f90-99ce-3d7ba0b09e57", name: "University of Cape Coast" },
    { id: "6869061b-eff4-4f8a-85b1-9d0e11601cb3", name: "University of Cape Town" },
    { id: "ec80efdc-1470-44b5-8e8b-5de0f3f8f392", name: "University of Carabobo" },
    { id: "371091bf-2fff-4659-8e79-4e5c88a53620", name: "University of Catania" },
    { id: "2a3a6141-1e0a-4222-9299-06ff0f53f475", name: 'University of Central "Punjab" Business School' },
    { id: "b35bc120-5732-43ff-94ad-58346d13ee42", name: "University of Central Arkansas" },
    { id: "bce887dc-88e5-4614-96d5-a93bd01e4fb9", name: "University of Central Florida" },
    { id: "abde59d1-418f-45ae-b05e-b243bd37a6d7", name: "University of Central Lancashire" },
    { id: "0103ed7f-e06f-47f6-862b-2e4a58859a2a", name: "University of Central Missouri" },
    { id: "c2502e74-4d41-40af-9428-68347c13db0c", name: "University of Central Oklahoma" },
    { id: "0b3a1a0a-80ed-48f4-a0f5-213771702d5c", name: "University of Central Punjab UCP" },
    { id: "a1db1df3-9d17-4814-a077-70c31981b826", name: "University of Charleston" },
    { id: "eab98c63-c35c-45dd-b124-b018e3a5ad65", name: "University of Chester" },
    { id: "be1c90d4-9d6e-4fab-9994-16dde6131745", name: "University of Chicago" },
    { id: "8d48189b-c7c2-419f-b5df-8cc78117a71f", name: "University of Chichester" },
    { id: "5203706b-0d5f-49e0-9d21-9e3aa9e357ba", name: "University of Chieti" },
    { id: "add7e6ff-9567-4b7a-a3f7-477e0a3892a2", name: "University of Chinese Academy of Sciences" },
    { id: "759c805d-049f-4eb4-b744-841614284460", name: "University of Cincinnati" },
    { id: "2fddd973-740b-4375-8d72-9b4b880946d7", name: "University of Cincinnati Bookstore" },
    { id: "5ec85e4d-179d-4239-af63-e8da5e1a4dbc", name: "University of Coimbra" },
    { id: "fc1aa0f7-c879-497d-8f14-9607845c52c7", name: "University of Colima" },
    { id: "f52234a9-dd9e-4877-96d0-a98355dae648", name: "University of Cologne" },
    { id: "fab909e7-99b4-449c-9f99-7901b5589588", name: "University of Colombo" },
    { id: "c7a01f2e-3c1b-45ed-8bc5-78fdbbe073a8", name: "University of Colombo School of Computing" },
    { id: "1876e9b1-e839-4604-87ef-d885d2461937", name: "University of Colorado - Boulder" },
    { id: "4d095004-6a2a-4448-9f52-5c8a3174749a", name: "University of Colorado - Colorado Springs" },
    { id: "d3a48179-b5f6-4a82-8fbb-9fa8283f9711", name: "University of Colorado - Denver" },
    { id: "437c6fc2-ddf2-4cf9-b814-e3f5b1861f61", name: "University of Colorado - Denver Anschutz Medical Campus" },
    { id: "b715e5a8-95ab-4c0d-bffb-a3b0705de16c", name: "University of Colorado - Denver Business School" },
    { id: "dfb36193-35ce-4004-a598-ad5bc043909d", name: "University of Connecticut" },
    { id: "fc30d45d-6e8c-4ac8-a050-c710696f4374", name: "University of Copenhagen" },
    { id: "bf3c9962-f5b3-4575-b207-209c1eb6c6c7", name: "University of Coruna" },
    { id: "a9aac6be-a598-4c89-865a-f9e9ab6a182c", name: "University of Crete" },
    { id: "44944d5d-8c0d-4e3c-887c-86eca8a07e31", name: "University of Cyprus" },
    { id: "27d4d587-2982-4610-8aaa-12b40bcfb468", name: "University of Córdoba" },
    { id: "d5a1b285-fbfe-4835-b65c-1cecf4fa7d70", name: "University of Dallas" },
    { id: "7784e702-85dd-4d08-8ecc-2a11f05edfcd", name: "University of Dayton" },
    { id: "b441452f-8eaf-4556-930d-5792603b2363", name: "University of Debrecen" },
    { id: "4c0ae4d6-a18b-4966-b0f8-76c0ff1fdc3f", name: "University of Delaware" },
    { id: "35fd9e26-8d56-454b-8fd8-db132d1eb7a1", name: "University of Delaware Bookstore" },
    { id: "f3d1d7a8-e9ab-4002-a625-a00ceee5a778", name: "University of Delhi" },
    { id: "596bb427-750c-4802-a411-d4fff2100099", name: "University of Denver" },
    { id: "b03400b3-8a8a-4273-b8b5-39b9b8d40e78", name: "University of Derby" },
    { id: "e11ee6a4-44f4-48ef-95de-eb3c825ebd56", name: "University of Detroit" },
    { id: "344c6da0-fa88-4772-bf6d-67928472497b", name: "University of Detroit Mercy" },
    { id: "ed5bd231-c29c-4643-b307-163c2394bab0", name: "University of Deusto - Deusto Business School" },
    { id: "6efbc99c-3165-4021-904d-743a7e30901d", name: "University of Dhaka" },
    { id: "c2485852-2d4a-4aaa-943e-412a24c9a498", name: "University of Doha for Science and Technology (UDST)" },
    { id: "22e48efc-605c-4e9b-b090-c5b8398d5619", name: "University of Dubai" },
    { id: "710466a2-c333-43e5-bcfe-16a0f150706e", name: "University of Dubuque" },
    { id: "ff5e0759-6383-47d4-862a-c1fda89340b5", name: "University of Dundee" },
    { id: "8d6f8a4c-deb2-4058-8db5-f5564de9b389", name: "University of Dunee - Centre for Energy Petroleum and Mineral Law and Policy (Cepmlp)" },
    { id: "07f677bf-2510-4f07-9ef3-92a89f33d68c", name: "University of East Anglia" },
    { id: "ad10ca1f-8b3c-452e-98ba-551854a389d3", name: "University of East London" },
    { id: "eb069fbe-b83f-4ddf-8c60-ec0ce06d705b", name: "University of Eastern Finland" },
    { id: "04900094-15f9-4c72-8b81-228f718cf181", name: "University of Economics - Bratislava" },
    { id: "63f6aa27-cf90-42ee-8a30-b3f58a85146d", name: "University of Economics - Ho Chi Minh City" },
    { id: "f171bec2-ee27-4e46-9533-0b594e89f9c0", name: "University of Economics Prague" },
    { id: "4758478d-497e-42a3-a65d-c9bf7f4fcd35", name: "University of Edinburgh" },
    { id: "e7c41d89-6d5c-41ac-8d2c-d14eb6970f78", name: "University of Electronic Science and Technology of China" },
    { id: "d60809df-c467-46a9-b3a4-7f2f1849d487", name: "University of Engineering and Technology UET Lahore" },
    { id: "9ec3f8cc-cea0-4e0c-9170-ae4bfbaf8869", name: "University of Engineering and Technology, Peshawar" },
    { id: "e8341d1a-6ca0-436f-a788-bcda55cc5649", name: "University of Essex" },
    { id: "09ce59f7-d231-47f1-b37a-28c77231605b", name: "University of Evansville" },
    { id: "8b99e7c4-01c6-4d64-8fd6-b393a9de0c5a", name: "University of Exeter" },
    { id: "a3071e34-704e-4fee-bd9a-2ea9cfebc1e8", name: "University of Exeter Business School" },
    { id: "9a26197b-5ef8-4504-b6cf-bfb056574ec8", name: "University of Ferrara" },
    { id: "887616f5-851c-4f67-9bca-3b7e570b5719", name: "University of Finance and Administration" },
    { id: "b782b8f6-e69e-4a71-b441-418426c077ea", name: "University of Findlay" },
    { id: "43670cd1-fb01-4454-9675-d8d4caa59dc0", name: "University of Florida" },
    { id: "8dc11594-eb42-4bd5-b3d7-d2949a3eace0", name: "University of Fort Hare" },
    { id: "5e59478f-e114-49d4-9c74-f7199c363a6d", name: "University of Fort Lauderdale" },
    { id: "78d0e865-f036-4ef6-80ff-d288201bd00a", name: "University of Fredericton" },
    { id: "6df00580-e3f4-4049-918c-819419b51a9c", name: "University of Freiburg" },
    { id: "ba1356f7-e68b-4369-874c-66ba7e43629e", name: "University of Fribourg" },
    { id: "7c8e4f97-2f06-4639-b2a6-bc51300a0c32", name: "University of Galway" },
    { id: "59e9dbb9-a999-4c9e-a46b-2d6bcbf5cfcd", name: "University of Gdansk" },
    { id: "d84ca2e5-dfaa-49c9-9170-e923710c787f", name: "University of Geneva" },
    { id: "16264ef8-9fa0-4788-9d07-540840c2fae5", name: "University of Genova" },
    { id: "9a1d26d6-4274-4a48-827e-8d4e0505fa1b", name: "University of Georgia" },
    { id: "0e2c8421-25a0-42a3-9fa2-5c1638b12a80", name: "University of Ghana" },
    { id: "a9fea2ff-60fa-418a-a5e6-ad8b89c60667", name: "University of Ghana Business School" },
    { id: "3495f724-bb12-42b1-8fde-146bf361050d", name: "University of Gibraltar" },
    { id: "d7d56652-5326-4e54-8f81-c3c58cf48eae", name: "University of Glamorgan" },
    { id: "c1f050ae-219b-4158-b085-8e0a5849c984", name: "University of Glasgow" },
    { id: "84515569-847c-4c12-bf1c-cf1144423ba6", name: "University of Gloucestershire" },
    { id: "c6c233a7-bcee-453b-86cf-e1c31be3163a", name: "University of Goettingen" },
    { id: "efe8d444-48cb-4afc-b76a-ec03f63a7592", name: "University of Gothenburg" },
    { id: "ea10e256-a65b-48f8-bbeb-6f1b20083031", name: "University of Graz" },
    { id: "9f15db47-8d9e-4a03-a09e-ae8b2a9e07a2", name: "University of Great Falls" },
    { id: "78c96f99-6151-48c6-909b-b4bb1b59f3fc", name: "University of Greenwich" },
    { id: "d652a3a0-8405-4aec-8636-233df5163c14", name: "University of Groningen" },
    { id: "3ec52a50-d54d-4cbe-9286-5e334bc9da69", name: "University of Guam" },
    { id: "8c82ac9d-fded-45b4-8bf7-eb91c1c6aff3", name: "University of Guelph" },
    { id: "938a64bd-fe72-4ffe-a78f-c08c9af8e3c1", name: "University of Guelph - Humber" },
    { id: "20a14ff9-034d-40d3-86ef-5c07361c77a4", name: "University of Gujrat" },
    { id: "f90074ba-dbad-4d70-9c5d-8d89984c20ed", name: "University of Haifa" },
    { id: "eacd7f08-7cf5-4e0a-9205-7fb7edcfbb79", name: "University of Hail" },
    { id: "32d772f7-461a-4fb7-b7ed-952a2ac6f49d", name: "University of Hamburg" },
    { id: "e39c5d6a-17ea-4511-bec6-89ccd767da78", name: "University of Hanbat" },
    { id: "ffc1b440-edec-4077-961a-24124a4f7836", name: "University of Haripur" },
    { id: "60f2c66b-da32-4642-b0af-c79d4edccd37", name: "University of Hartford" },
    { id: "a9b2b97e-800a-49b2-ab41-472623d23ac8", name: "University of Hawaii" },
    { id: "db96927e-f531-4653-8cc4-fc30dac5bc2b", name: "University of Hawaii - Hilo" },
    { id: "a8ca28c1-eb69-48c0-85d7-f4e45f73731d", name: "University of Hawaii at West O'ahu" },
    { id: "dc41fd1b-faa2-48e8-a0d9-492ed1036283", name: "University of Helsinki" },
    { id: "fa305afc-b421-4364-b8e3-79f71f36578c", name: "University of Hertfordshire" },
    { id: "a6abdd34-0129-4dc7-b7aa-eda5194bb8a1", name: "University of Hohenheim" },
    { id: "f8e39618-069f-46d1-89c1-6e648aef9dd2", name: "University of Holy Cross" },
    { id: "d1afa65d-4b6b-49fa-b54a-c1a096075aa2", name: "University of Houston" },
    { id: "53ceeb68-3906-4ea0-b4f1-433a17d8af77", name: "University of Houston - Clear Lake" },
    { id: "a37ceb8b-3e92-4105-ba4d-d05d84d77af2", name: "University of Houston - Downtown" },
    { id: "81f3118e-ed3c-49d2-b80c-5a834d68dc7c", name: "University of Houston - Victoria" },
    { id: "6d00a5ea-0add-4bd6-bea8-d615a8b4fe07", name: "University of Huddersfield" },
    { id: "28f90815-0d19-496c-981e-dba5f331f136", name: "University of Hull" },
    { id: "ee751132-1fa8-4465-83aa-f25de333b1aa", name: "University of Hyderabad" },
    { id: "ddefee61-b626-467f-a13d-5cf974c11923", name: "University of Hyogo" },
    { id: "78559eed-0082-462d-bf27-decc228b5357", name: "University of Ibague" },
    { id: "68661e13-e519-4bad-a474-a23ded17efc9", name: "University of Iceland" },
    { id: "3e9493c5-2891-47bf-907f-585e828c5f38", name: "University of Idaho" },
    { id: "9c02b7c6-d03a-480b-8ca8-80aedfa271f3", name: "University of Illinois - Springfield" },
    { id: "65d272a3-58d9-490b-8f14-5679ca46227e", name: "University of Illinois - Urbana - College of Agricultural, Consumer and Environmenta" },
    { id: "9c7b904f-95c1-458c-b679-edf217908934", name: "University of Illinois - Urbana-Champaign" },
    { id: "55e079de-df94-4703-a28f-9579320dfbf2", name: "University of Illinois at Chicago" },
    { id: "4886f02b-bd77-4528-8cb7-71fb6be13d8f", name: "University of Imam Muhammad bin Saud Islamic University" },
    { id: "6d17edb6-e343-4027-be48-2569edc1a34b", name: "University of Indianapolis" },
    { id: "9ecff372-8850-4967-9aa9-13d52de85897", name: "University of Indonesia" },
    { id: "e950ff69-1aae-4114-868c-0ca31fc6dda8", name: "University of International Business and Economics (UIBE)" },
    { id: "e6388b71-3298-4e30-831e-942d267f385a", name: "University of Iowa" },
    { id: "87e3ffcd-630f-44ee-a432-82629e76abd6", name: "University of Jaen" },
    { id: "43c10415-5d25-4cd4-9622-44c6250c0897", name: "University of Jamestown" },
    { id: "cfeb8043-3d2b-4759-84c8-d4b653c4bbd2", name: "University of Johannesburg" },
    { id: "bba16d36-af88-414e-b9a3-7a32b2e09377", name: "University of Jyvaskyla" },
    { id: "d1b5a219-0c39-4ad6-b552-b7b78f4507ff", name: "University of Kansas Hospital" },
    { id: "f80e5217-27bf-4c1b-85cd-65293b0ea5c9", name: "University of Kansas Medical Center" },
    { id: "eee98806-9ef5-4247-9249-3993ac44b795", name: "University of Kelaniya" },
    { id: "cadc4c8c-6c37-4509-80e5-347c6f7c9b50", name: "University of Kent" },
    { id: "e8ed8405-14da-4e2b-b4d6-3fa90154587c", name: "University of Kentucky" },
    { id: "580e994b-a6ca-4127-aea4-29b62ea370d2", name: "University of Kerala" },
    { id: "0e77a1f3-cf3c-491b-b4c5-a24f9c711748", name: "University of King's College" },
    { id: "e845fa26-f8b2-44c0-9050-2f03b62a1323", name: "University of Kitakyushu" },
    { id: "66eb734a-5340-41d5-83a0-ba5f4f742606", name: "University of Konstanz" },
    { id: "2156afe6-d729-4b86-ba2d-49ba95ccc53b", name: "University of Kragujevac" },
    { id: "0e2fe7d7-9a33-4b12-be3f-091b41a260a0", name: "University of Kurdistan Hewlêr" },
    { id: "95c58ab1-1cc3-4d09-a100-71b896fac7b3", name: "University of La Laguna" },
    { id: "2a8e7a12-b3c4-4462-9d3b-a564ff34b71e", name: "University of La Verne" },
    { id: "bce6a958-27ef-4c24-8bfb-b7aac965fcbe", name: "University of Lagos Business School" },
    { id: "cfde1e61-898b-4fb3-b635-2c39a3a89ea9", name: "University of Lahore" },
    { id: "90debc58-39bd-44fe-b21f-242e45fbbd23", name: "University of Lapland" },
    { id: "c2015b55-d0f6-47f1-b6c9-cad415deac83", name: "University of Latvia" },
    { id: "2a4702e1-51ad-4ec5-98ce-469986a0feab", name: "University of Lausanne" },
    { id: "dc7baba1-f568-4ab2-bed6-f1b1233f7aac", name: "University of Leeds" },
    { id: "74230d3d-478a-4289-a77f-b57591962da6", name: "University of Leicester" },
    { id: "b2b6599a-24fe-454e-86ff-5b1c4b295eb9", name: "University of Liberal Arts Bangladesh" },
    { id: "f4ad257c-4e0f-4f62-89e7-52e76d842b55", name: "University of Liechtenstein" },
    { id: "6d97451e-012d-4e14-84b9-56eb7b142ce2", name: "University of Liege" },
    { id: "76c82efd-376a-4f71-9d09-87c8b743b3a2", name: "University of Limerick" },
    { id: "7cd85447-7d88-44bb-b052-69f83bda6cae", name: "University of Limpopo" },
    { id: "64c6afac-a560-430b-96e9-78d40bb966d1", name: "University of Lincoln" },
    { id: "488d1bcc-6dd9-49d2-8b6b-1c55c0976118", name: "University of Liverpool" },
    { id: "9e57b81d-a01f-425e-8a79-6fbbc050bc5e", name: "University of Ljubljana" },
    { id: "5ef20d6f-c95a-4c78-82e3-5d2df4eef76e", name: "University of Lodz" },
    { id: "53982434-abc2-40af-b3f9-f89f078d212e", name: "University of London" },
    { id: "8ea31345-1ad6-4e07-b7f9-47cab8387227", name: "University of Louisiana at Lafayette" },
    { id: "fa6dede5-c7b0-4f28-8d00-131788288398", name: "University of Louisiana Monroe" },
    { id: "e84f795a-30ea-45cc-8e04-95ee931e9249", name: "University of Louisville" },
    { id: "7f9ed113-7178-4498-8081-a456bc4f83df", name: "University of Lucerne" },
    { id: "0e319922-b067-4146-92de-0b1c42d09d21", name: "University of Luxembourg" },
    { id: "35e4e0af-cb62-4e23-aa38-d285257db74a", name: "University of Macau" },
    { id: "ad22dab0-4735-484e-8311-ad3bd516b480", name: "University of Macedonia" },
    { id: "23e826a5-7ef0-42c5-a0c2-632b4f4710dc", name: "University of Madeira" },
    { id: "d49e249b-6c21-47b9-96b0-d4ecdbc32cc3", name: "University of Madras" },
    { id: "70d650d0-1bb9-42db-8de1-a0b2c10cc709", name: "University of Maine" },
    { id: "4b682955-6177-41e9-9342-b1251cb9f060", name: "University of Maine at Presque Isle" },
    { id: "c10c84fa-7f3b-492e-9298-e35331af8309", name: "University of Mainz, Executive Mba" },
    { id: "0b997153-a59d-49f3-8dee-dba5cc8ddf17", name: "University of Makati" },
    { id: "3761253d-cde3-450c-804b-0eb7f5951207", name: "University of Malaya" },
    { id: "1dcca891-33f0-4a9b-bb6e-b965c8a6cdba", name: "University of Malaysia" },
    { id: "b5143fd2-aa69-4525-879d-e77a423142f7", name: "University of Malta" },
    { id: "549d13b3-c8c1-4e8c-8e74-d6b7461d66da", name: "University of Malta International Masters Programme" },
    { id: "6359bf6d-746b-4053-86a0-e080f78c1262", name: "University of Management and Technology (UMT)" },
    { id: "7099027a-c097-4735-90d9-417f5565c3ca", name: "University of Manchester" },
    { id: "771a84d4-4cda-4219-a296-207d62bd3fb5", name: "University of Manitoba" },
    { id: "8e38f870-f040-4863-bc28-d9c6f6307d21", name: "University of Mannheim" },
    { id: "aeff9fe0-170a-40e5-9b3c-964040f06480", name: "University of Maribor" },
    { id: "0ca105ce-fe0b-4b1b-a4cc-591cac80c0db", name: "University of Mary" },
    { id: "aeadaaac-6e15-40cd-b454-34c49ea5a453", name: "University of Mary Hardin Baylor" },
    { id: "8785ab4f-f7e8-472a-8b60-599376243b3e", name: "University of Mary Washington" },
    { id: "379548e9-e7ae-46ea-be36-a5d0dcf9fb03", name: "University of Maryland" },
    { id: "69a6d3f6-9ddc-4f21-996f-8c10b04f2949", name: "University of Maryland - Baltimore" },
    { id: "df295685-3186-43a2-a0a1-8882f77282a4", name: "University of Maryland - Eastern Shore" },
    { id: "2af66db2-1a80-42e8-97f0-b40e59de94f7", name: "University of Maryland - School of Public Health" },
    { id: "54af4075-8407-4190-b7a2-d434e80a7720", name: "University of Maryland - University College" },
    { id: "83073229-ec53-4272-984d-ebc6352965c1", name: "University of Massachusetts - Amherst" },
    { id: "cdc5c902-3767-435f-b578-95efc160525c", name: "University of Massachusetts - Boston" },
    { id: "19fc2943-cd63-4e23-8fe6-26dc43dd7aa7", name: "University of Massachusetts - Dartmouth" },
    { id: "0558a7ba-0028-4cb0-8442-9d2cdaa32493", name: "University of Massachusetts - Lowell" },
    { id: "ee9999a5-f20a-4716-97be-1f2c5714a8bb", name: "University of Mauritius" },
    { id: "24cb7a43-5cb5-4fb6-8db0-e8e5b21938c9", name: "University of Melbourne" },
    { id: "43d380f0-ab42-406a-9ecf-38307907df8d", name: "University of Memphis" },
    { id: "79c70360-dfb8-4301-bc2b-1c617914e092", name: "University of Miami" },
    { id: "8ea42aee-4790-4536-9b15-acb9d1b81c70", name: "University of Miami Bookstore" },
    { id: "b1a7f6e2-7a16-4531-8885-965e9afceab1", name: "University of Michigan - Ann Arbor" },
    { id: "2c065a65-765e-4873-8e97-6b9edf70d1a1", name: "University of Michigan - Dearborn" },
    { id: "4a0d6d4b-47af-44b3-99a8-c0e54444d91a", name: "University of Michigan - Flint" },
    { id: "3f73c8fa-5e0f-4f3a-a616-e6022b703bd2", name: "University of Michigan Museum of Art" },
    { id: "9fa61e20-9dbb-4d25-afa3-8dff455bbb55", name: "University of Milan" },
    { id: "330233f9-e0cd-48b4-9a3c-907c08a53ea1", name: "University of Milan Bicocca" },
    { id: "50daa9dc-aa55-459c-bcab-3efa4728d08f", name: "University of Mindanao" },
    { id: "e082ff75-168a-4709-be01-80ce83ac7157", name: "University of Minho" },
    { id: "f36626fe-db26-4727-aa70-ae6f9f52721c", name: "University of Minnesota" },
    { id: "a910e9d9-23a5-41cd-a482-9235c4dfc2f7", name: "University of Minnesota - Duluth" },
    { id: "4ab6d1e8-15a8-4601-9bf3-4b7bb4bbf1e5", name: "University of Minnesota Crookston" },
    { id: "c7e2ffee-c8eb-43d1-8129-202ba41318c4", name: "University of Miskolc" },
    { id: "527eecbe-aaf3-43f2-92a5-94742706ba06", name: "University of Mississippi" },
    { id: "623b52bc-e766-488b-871d-592310f2be3d", name: "University of Missouri - Columbia" },
    { id: "20a45486-76e0-44a3-a452-404b813b9bb8", name: "University of Missouri - Kansas City" },
    { id: "dfe9f8fe-ac03-4c2c-8f44-ba3686eb001e", name: "University of Missouri - St Louis" },
    { id: "705ecb6e-2c30-48ef-9c4d-1af096d0fb5f", name: "University of Mobile" },
    { id: "dee953e9-9ec0-4a58-ac03-70e63023dee7", name: "University of Modena and Reggio Emilia" },
    { id: "26316b29-18dc-4cdc-bec9-f25e54f4a340", name: "University of Modern Sciences" },
    { id: "34a61ce4-6b25-4d8c-802f-efc325d369c5", name: "University of Moncton" },
    { id: "86498c4b-5a08-4db2-a26a-2ecf2eb1abfa", name: "University of Montana" },
    { id: "4f6ad93a-77ec-4100-bcf8-f97fa244f168", name: "University of Montana Western" },
    { id: "de444a03-7b5a-4c71-a2ff-21a3d8ec5401", name: "University of Montevallo" },
    { id: "ec5ea14a-85ee-4806-933e-530322b4a62d", name: "University of Montreal" },
    { id: "a26f726f-816b-40a9-9a1e-0ce7c576e619", name: "University of Moratuwa" },
    { id: "d03d9643-b5cd-4212-9fdf-197496a1472f", name: "University of Mount Union" },
    { id: "16646528-bec6-4afd-b2e6-038b24cac589", name: "University of Mumbai" },
    { id: "c683a69c-ceb3-413a-a617-e1d20faa8ac3", name: "University of Murcia" },
    { id: "5dc5fc7a-781a-4220-877c-bb6ac9a32a88", name: "University of Mysore" },
    { id: "a9df43cc-01db-4e8d-8a59-cb4651786838", name: "University of Münster" },
    { id: "b00ac7eb-31da-4bc6-a867-634e1e624493", name: "University of Nairobi" },
    { id: "0f47d4d8-5a26-4b58-9dc4-24db1653e91b", name: "University of Namur" },
    { id: "5361318c-131a-4cfd-a336-1583eadfe77c", name: "University of National and World Economy" },
    { id: "6d7eefe1-3384-4f2c-bb04-a85820d09549", name: "University of National Economics" },
    { id: "dcb58463-0321-4408-88d2-7ea2dda571ed", name: "University of Natural Resources and Life Sciences" },
    { id: "b469668b-30bd-45c2-af65-c9658d9c6f03", name: "University of Navarra" },
    { id: "bbd68315-f913-44dc-8983-85917e52f6b6", name: "University of Nebraska - Kearney" },
    { id: "75d3b6e2-a507-4626-af0b-63ae0a357440", name: "University of Nebraska - Lincoln" },
    { id: "fe680c08-d800-4a04-bbf9-44a609c5000c", name: "University of Nebraska at Omaha" },
    { id: "7d37ba9d-e372-432e-8e0a-d76436de051b", name: "University of Nebraska Medical Center" },
    { id: "c5d37207-d581-478a-bb1f-9682435cb60e", name: "University of Nevada - Las Vegas" },
    { id: "fde9322d-17a9-4127-9db5-d0b3b773221c", name: "University of Nevada - Reno" },
    { id: "f964298a-cc6d-4d02-a76d-2f33c7d2a9e0", name: "University of New Brunswick" },
    { id: "25aec29e-0020-4934-bea7-8a7d5460bea1", name: "University of New England" },
    { id: "3e552937-72c3-4269-842c-f117e88a03a3", name: "University of New England (AUS)" },
    { id: "a779730e-7273-4adb-b7bb-efd46f4ce21b", name: "University of New Hampshire" },
    { id: "0b2b9390-c679-4bcb-8378-c46b051ed4db", name: "University of New Hampshire - Manchester" },
    { id: "921916ce-b826-4c6c-bbf7-6a0cd52f9f53", name: "University of New Haven" },
    { id: "36020aea-3c97-4b18-88de-0fd2aee788c8", name: "University of New Mexico" },
    { id: "736711b9-6f6a-441a-8ed4-a6616fb89e5a", name: "University of New Orleans" },
    { id: "174dab2e-45f4-43f5-a40c-6ae139d52334", name: "University of New South Wales" },
    { id: "b9064713-b232-4a72-9d1a-0257cb4318cd", name: "University of New York in Prague (UNYP)" },
    { id: "33a309d3-97bf-4193-ba8c-eadb6abb563a", name: "University of New York Tirana" },
    { id: "d3aac05c-4365-477a-b37e-3bee84fdfde0", name: "University of Newcastle" },
    { id: "9c72ff22-3ba8-4d93-9cf6-e91933a70e48", name: "University of Newcastle - Newcastle Business School (Australia)" },
    { id: "a401e49a-f25c-4790-952c-e1206d6ff082", name: "University of Newcastle Business School" },
    { id: "e5472fc0-b321-4068-b823-85043523a87b", name: "University of Nicosia" },
    { id: "c104b1b6-82ba-47be-bbb9-7c7f9b29a535", name: "University of Nigeria, Nsukka" },
    { id: "f8a48ef1-8f78-4d2f-9ea6-248e58214f2a", name: "University of Nordland" },
    { id: "d1db1008-cdc7-4414-90b4-ef5900d6ecc2", name: "University of North Alabama" },
    { id: "57e8f47e-dce3-4144-880f-f52569c38239", name: "University of North Carolina" },
    { id: "a02258f5-d2c4-45f6-8bd2-58fe8265cd64", name: "University of North Carolina - Asheville" },
    { id: "07f47f1d-4b70-4d1e-a643-8bba65bcdb74", name: "University of North Carolina - Chapel Hill" },
    { id: "70df8bf0-9513-4be5-a0ce-34b14bc035f4", name: "University of North Carolina - Charlotte" },
    { id: "5487bfd6-57c5-4299-9dd3-78bb97493c82", name: "University of North Carolina - Greensboro" },
    { id: "d59ff3c6-6d40-4343-bc9f-2b89b5eb1c30", name: "University of North Carolina - Pembroke" },
    { id: "8ff84ea5-620c-4bdc-9ade-826dd8c92f75", name: "University of North Carolina - Wilmington" },
    { id: "1e6997aa-e3ce-4123-9848-f1466a48422b", name: "University of North Dakota" },
    { id: "f2fb4c12-c360-415b-a123-5bb7505fb727", name: "University of North Florida" },
    { id: "b2a26bbe-2a55-401e-b0eb-ce2ad8995089", name: "University of North Georgia" },
    { id: "a92b4f25-09e4-46c9-94f0-d7cd274e90ea", name: "University of North Georgia - Dahlonega" },
    { id: "60499927-ac85-478e-b89a-65e15e350320", name: "University of North Texas" },
    { id: "8d6560e2-5d79-40a9-8fa1-3a1133687267", name: "University of Northampton" },
    { id: "3f73a470-5182-472d-a397-b94f8529fc0b", name: "University of Northern Colorado" },
    { id: "2172bcb2-e3b4-4bb3-a374-2290db9205db", name: "University of Northern Iowa" },
    { id: "48e3e9f0-7e50-4dd5-b67a-4f05be52e06f", name: "University of Northwestern - St. paul" },
    { id: "1992bfe3-64a7-46e3-b834-d71f848da809", name: "University of Northwestern Ohio" },
    { id: "e6d94507-2b92-4c08-9bc6-abe004ddac1b", name: "University of Notre Dame" },
    { id: "e48ffc39-8032-4e74-b482-fe3de05ef201", name: "University of Notre Dame Australia" },
    { id: "db8d12c1-9647-4264-8bab-399dc5b4b33a", name: "University of Nottingham" },
    { id: "4982cd82-d7f0-49c2-af94-957db8413e5e", name: "University of Nottingham Malaysia Campus" },
    { id: "8d008f7d-db5a-45e6-a4f1-352117ec98e2", name: "University of Novi Sad" },
    { id: "1cbd07ad-5b8e-4b53-b86f-a4bf561eec41", name: "University of Oklahoma" },
    { id: "dff4bfff-5c04-408c-8f99-02c627339d05", name: "University of Oklahoma - Tulsa" },
    { id: "d26987b3-fabc-4e69-996d-4c8319bfd2df", name: "University of Oldenburg" },
    { id: "93d15a31-b024-4aab-bea9-97ebd22abf39", name: "University of Ontario Institute of Technology" },
    { id: "b85bc478-611c-44c9-a68f-3b4d6d603043", name: "University of Oregon" },
    { id: "b94a1982-8794-4084-bfb2-c9628b4f6726", name: "University of Oslo" },
    { id: "4a6064b8-2aa7-42d9-9dc9-a1303fe581be", name: "University of Otago" },
    { id: "3df45e71-1a77-4082-b7b7-25cf9936f2a4", name: "University of Ottawa" },
    { id: "d98f083f-68b5-4388-b76c-871c02739cab", name: "University of Ouargla" },
    { id: "ca7d488e-2daa-4295-abf4-4eda40f0d92a", name: "University of Oulu" },
    { id: "56510621-9397-4e71-82ca-93519db35de7", name: "University of Oxford" },
    { id: "f1ebe914-0aa9-4439-ab3a-0b75cbd54e6d", name: "University of Padua" },
    { id: "f34bc399-2fbb-4e77-a5ec-eb76dabbe849", name: "University of Palermo (UPAL)" },
    { id: "2c43c9e1-8a2d-4510-b7af-8c4096eb38a2", name: "University of Pannonia" },
    { id: "f2082bad-2221-46d4-bd70-676c6acb6a61", name: "University of Papua New Guinea" },
    { id: "c59822b3-9b29-4879-8c97-4d3979bfd3ea", name: "University of Paramadina" },
    { id: "1698c87d-711b-401b-9363-754b805e8d92", name: "University of Passau" },
    { id: "28e8f0a1-4922-493a-b3c8-102080a1f201", name: "University of Patras" },
    { id: "4d87f9ed-2b38-41e0-8d5e-98087dc859e9", name: "University of Pavia" },
    { id: "cb1e8580-dd7e-4e89-aa55-bb0c2401a66a", name: "University of Pecs" },
    { id: "7a83c1b4-61e0-4919-ae4c-8e65e3ec86e2", name: "University of Pennsylvania" },
    { id: "b123d700-a5fc-4276-9b97-988a70eafed2", name: "University of Perpignan" },
    { id: "8b7b4a08-b4fa-4311-acb1-a6c8b0a3b883", name: "University of Peshawar" },
    { id: "49cdb29d-d683-4323-bebe-fe0cdca4c11e", name: "University of Petroleum & Energy Studies (UPES)" },
    { id: "3ed51dba-9e26-4bee-92fe-bb883882a14c", name: "University of Phoenix" },
    { id: "aa11e0e4-5ea2-40b2-923e-d0caf287a7e7", name: "University of Pikesville" },
    { id: "f11d52fd-41ec-4afb-ac54-8a2f0de207b1", name: "University of Pikeville" },
    { id: "57e620d4-42b1-4810-b4f6-32e7aabf63f7", name: "University of Piraeus" },
    { id: "4e9b9180-fae3-4217-a102-562abdf71439", name: "University of Pittsburgh" },
    { id: "79adf329-4381-4147-bea3-c6ce42e29692", name: "University of Pittsburgh - Bradford" },
    { id: "6c0c6ed2-6d09-462d-a8e5-d946a0892c65", name: "University of Pittsburgh - Johnstown" },
    { id: "d6d3a830-a50e-4720-b05a-2c9e55fc0979", name: "University of Pittsburgh Greensburg" },
    { id: "80e93afb-99c6-424f-a07d-72bf1cf0619a", name: "University of Plymouth" },
    { id: "51c41db9-4e2e-4b8f-8cc9-45d498f30775", name: "University of Poitiers" },
    { id: "011d3177-bd90-411c-9dc9-be513569f44b", name: "University of Portland" },
    { id: "3a4c51e3-63f6-4689-ac5f-9c0ea7db0475", name: "University of Portsmouth" },
    { id: "c02dcb53-2b89-49b7-b07d-25b311213cf4", name: "University of Pretoria" },
    { id: "b3611b0f-78e6-458f-9f93-44b6f41a85be", name: "University of Primorska" },
    { id: "adac496f-508b-4fe1-9312-db0c73d117c1", name: "University of Prince Edward Island" },
    { id: "0e78f9a4-fe4f-4526-ae2f-db3be361fd5f", name: "University of Provence Aix-Marseille" },
    { id: "ee8bc561-43d0-47ae-b421-955a7889cfc9", name: "University of Puerto Rico" },
    { id: "46f89b93-9ce0-4d07-9682-43e534b816eb", name: "University of Puerto Rico - San Juan" },
    { id: "b909732f-0b6d-4295-bdd2-f2c8fa482aa6", name: "University of Puget Sound" },
    { id: "a7725b4a-70b8-4997-a3eb-5aee67e4a982", name: "University of Pune" },
    { id: "00eb23b1-0dd8-4db9-8e9d-e17fb68d37fc", name: "University of Puthisastra" },
    { id: "de984b06-abc3-4257-9482-dfe56d1664fc", name: "University of Queensland" },
    { id: "df6853b9-c32b-4f68-929b-f585c3bda2ef", name: "University of Queensland Business School" },
    { id: "8107bbd3-53cc-4e07-b820-e44f2fa8e664", name: "University of Rajasthan" },
    { id: "2d2f9113-9e17-4af1-bf84-598e9209d1f2", name: "University of Reading" },
    { id: "8612ba17-f578-4240-bb0d-d68ee1f0409b", name: "University of Reading - Henley Business School" },
    { id: "c633908e-702a-49fe-a4ee-290746a4f3ba", name: "University of Redlands" },
    { id: "1229bb08-8be1-4681-95a7-b4c667d035ff", name: "University of Regina" },
    { id: "d6de9140-da87-4846-8d5c-b5d913a0ec7e", name: "University of Rhode Island" },
    { id: "217d17a6-da48-4463-8b4f-a065c0cddd32", name: "University of Richmond" },
    { id: "93e648c5-f64e-4263-a5ed-5f5e70c2a665", name: "University of Rijeka" },
    { id: "af5fa65a-4a8f-4d49-ba8e-2ce83fdd91e0", name: "University of Rio Grande" },
    { id: "94c8cf6b-ace0-46ee-8f62-a77a56458853", name: "University of Rochester" },
    { id: "876c1aec-d130-41a8-b144-da60a5a64788", name: "University of Roehampton" },
    { id: "a3a63358-6800-4d9e-98d7-8c0aefcecfda", name: "University of Rondonia" },
    { id: "073f37be-cadf-4217-9cf6-a2e714de8e91", name: "University of Rostock" },
    { id: "4265dafb-c8cf-474a-9e6d-e51943ba3838", name: "University of Saint Francis" },
    { id: "fc96b27f-cc3f-4140-8ee3-5d8ef6393aea", name: "University of Saint Francis - Joliet" },
    { id: "997ee785-d034-438a-8084-a7bbf0d9761d", name: "University of Saint Joseph" },
    { id: "d86cab3c-4805-49bf-ba08-1b320b66826b", name: "University of Saint Joseph - Macau" },
    { id: "8ba33fe0-5201-46b1-9152-ae7f34ef3f86", name: "University of Saint Mary" },
    { id: "aa4d9387-4823-4cea-97f0-0c00ff251ef9", name: "University of Saint Thomas" },
    { id: "14945d07-98c7-4fb2-b76a-e28a7e1b354c", name: "University of Salamanca" },
    { id: "1709032d-44d7-4d93-bd93-85bbd7902e26", name: "University of Salford" },
    { id: "8c82dbae-1332-44ec-9f16-f1fbe4305e65", name: "University of Salzburg" },
    { id: "37624747-8c78-45a4-bbbd-a2867a5fbabb", name: "University of San Diego" },
    { id: "426d012b-fccc-4658-9cf5-5c9e9ea77372", name: "University of San Francisco" },
    { id: "f35cc906-bcf7-451a-bb1d-4057c680b85d", name: "University of San Jose - Recoletos" },
    { id: "f579e418-dda3-4028-ab1e-7bb0c1980727", name: "University of San Martin de Porres" },
    { id: "3e27053d-2cef-4416-a3a9-325bc008fb38", name: "University of San Sebastian" },
    { id: "8db31cc8-f708-4efb-8d10-506a2ce8b07c", name: "University of Santo Tomas" },
    { id: "908d4046-149c-4540-859d-2a333c89b345", name: "University of Sarajevo" },
    { id: "7e10e139-cbd5-4f38-bea6-d359093df6e2", name: "University of Saskatchewan" },
    { id: "1237494d-a933-4e90-b0d6-24af788c0b56", name: "University of Savoy" },
    { id: "93e1f0e0-bd96-4873-8ab1-13b091969010", name: "University of Science and Technology Beijing" },
    { id: "1faa6bc3-53d2-448d-a4e2-e6dbe3045ac3", name: "University of Science and Technology of China (USTC)" },
    { id: "ebf255ce-fdcd-42a0-9178-dd0a5b22c928", name: "University of Scranton" },
    { id: "6d048558-df96-45f1-b629-3bace94940d8", name: "University of Seoul" },
    { id: "a9c02eec-4f39-4fb5-a548-5aefc49384ec", name: "University of Sevilla" },
    { id: "f9c65196-a1b6-41d7-8e2d-fe1122001bdf", name: "University of Sheffield" },
    { id: "f5a8d4a8-fae7-4748-89a5-c33f4913f2d7", name: "University of Shiga Prefecture" },
    { id: "1db7a398-bd26-4bff-97ce-6c9245be9645", name: "University of Shkodra" },
    { id: "8ed28fad-673a-4f9c-89df-31bd051a2b87", name: "University of Siena" },
    { id: "eeff29ab-2231-4217-93b1-93544665ce51", name: "University of Silicon Valley" },
    { id: "a62b306c-59c5-4b5c-bb81-f473ac18277c", name: "University of Sioux Falls" },
    { id: "0cca1fca-1542-4bc0-b12d-c21671e40826", name: "University of Social Sciences and Humanities" },
    { id: "cc4a423f-c235-46af-a4fd-7c51c11b71f5", name: "University of South Africa (UNISA)" },
    { id: "ba8e126c-efbe-4616-8cc0-aaba73e84f21", name: "University of South Alabama" },
    { id: "2a2eae5a-c3eb-48a6-818b-3b16e73f597f", name: "University of South Australia" },
    { id: "e033ab25-f553-43b4-848d-e1964e0c775d", name: "University of South Carolina" },
    { id: "26f1d046-cb51-422f-a460-e5ac4d19b691", name: "University of South Carolina at Aiken" },
    { id: "926ed3eb-1cc8-4d07-955d-eabf04f7e48e", name: "University of South Carolina Upstate" },
    { id: "5be8a943-8f88-43f0-8ca0-a926cf91e717", name: "University of South Dakota" },
    { id: "eb2397c5-4315-4b6f-89f2-9b2ad22b417d", name: "University of South Florida" },
    { id: "02bf0dcd-a7e1-440c-a83b-6282f378c877", name: "University of South Florida - Saint Petersburg" },
    { id: "7d9ea307-43e4-402f-b798-3d8439b71b3d", name: "University of South Florida - Sarasota" },
    { id: "e99d7b0b-2fb0-4916-acfb-5d7bc547d6eb", name: "University of South Florida Polytechnic" },
    { id: "53ab72a3-f106-47ea-bf57-886477660fa1", name: "University of South Wales" },
    { id: "a9500491-7b5c-40aa-9a32-ebd34eb3695b", name: "University of South Wales (UK)" },
    { id: "ec49d868-9b4d-4bfd-8063-7975ddd0dbfb", name: "University of Southampton" },
    { id: "08d89ab0-bdaa-4943-88ff-9884b3bda108", name: "University of Southeastern Philippines" },
    { id: "b80ec87a-846b-4b41-b556-a4cd57222850", name: "University of Southern California" },
    { id: "b73cabd1-b267-4abb-9479-616b3344320c", name: "University of Southern Denmark" },
    { id: "e333532c-17c1-4df8-ad24-e56b640ecf4d", name: "University of Southern Indiana" },
    { id: "1a1333ab-24a2-4665-b1a5-f5a0c67558ce", name: "University of Southern Maine" },
    { id: "bc552520-cc00-4acf-979b-dbbe4f13f4ff", name: "University of Southern Maine - Portland Bookstore" },
    { id: "e1261409-e49d-4295-a227-da402faf66ae", name: "University of Southern Mississippi" },
    { id: "b3028d30-4578-44af-8979-954313e7232b", name: "University of Southern Nevada" },
    { id: "9f7986c0-92d7-4506-b9b8-ca59dbe16d47", name: "University of Southern Queensland" },
    { id: "767d7273-d0a1-47d1-8c91-dd51f4e80e58", name: "University of Southernmost Florida" },
    { id: "eb32e3ec-2ce2-4c3b-bca3-586e34e42750", name: "University of Split" },
    { id: "36dd2c65-3db5-48ba-baa5-26765c32b72a", name: "University of Sri Jayewardenepura" },
    { id: "cef87585-4d41-4532-9ce3-4e00da56e4ac", name: "University of St Mark & St John" },
    { id: "a69356f5-786c-4e87-9e48-eecdef32acd2", name: "University of St Thomas - Houston" },
    { id: "7fa723eb-7ab2-49c6-a8a0-78a0ce7db201", name: "University of St Thomas - Minnesota" },
    { id: "eca5b055-f16a-461f-94b7-9635b710410d", name: "University of St. Andrews" },
    { id: "8ee21960-4372-40d8-9927-abfc3a2bf404", name: "University of St. Gallen" },
    { id: "f5868aea-b879-4be9-81c2-ff86ec53cc80", name: "University of Stavanger" },
    { id: "4fd73b0f-a2c2-4184-a69f-9ef4df328085", name: "University of Stellenbosch" },
    { id: "cd444483-1f30-49ab-8769-39fe3d4a58fc", name: "University of Stirling" },
    { id: "86361c72-6d24-4ab0-a50e-bd59f57221c7", name: "University of Strasbourg" },
    { id: "9402861a-04b7-4210-839d-10d7f9f4e907", name: "University of Strathclyde" },
    { id: "74d2d42d-7c48-4f76-af33-4acd1f8cace1", name: "University of Suffolk" },
    { id: "ef26e12e-5082-4547-be5f-a497b68e7ca1", name: "University of Sulaimani" },
    { id: "331386d5-12e3-43ac-b26f-9e3675ef8d8c", name: "University of Sumatera Utara" },
    { id: "aaf7294d-bf14-450a-9660-a2bc2d2336b3", name: "University of Sunderland" },
    { id: "46b8a33c-f061-4a5d-ac8c-e46b49e0b8a9", name: "University of Sunshine Coast" },
    { id: "c6c520a8-7e34-4780-964d-1f804c3306f5", name: "University of Surrey" },
    { id: "7e6fbba5-cbef-4b00-b8a0-3cff1514fccc", name: "University of Sussex" },
    { id: "5b924a4c-6703-4e0f-af13-d63aa3b9a021", name: "University of Suwon" },
    { id: "f2f9e213-c143-4532-9ea7-89fb1e27365d", name: "University of Sydney" },
    { id: "02b68a62-4613-4eb4-89fd-5fe13772f2e9", name: "University of Szczecin" },
    { id: "f6fafb19-c732-458d-a9d1-3653201382c9", name: "University of Szeged" },
    { id: "945c90b7-f34e-42ad-9a96-baea7e054355", name: "University of Tampa" },
    { id: "08664dcb-7973-4568-91c1-3fadb526feac", name: "University of Tampere" },
    { id: "a2f04fad-9a3f-42ae-81c7-be7b2be4e2e3", name: "University of Tartu" },
    { id: "9603a87e-b12e-4808-9f24-86142806d3d6", name: "University of Tasmania" },
    { id: "59b777b0-3e4d-4cca-85e8-effa93e20ab4", name: "University of Technology and Applied Sciences" },
    { id: "cb423b69-e77a-4f18-b5b3-f0ce6947a007", name: "University of Technology Rapperswil" },
    { id: "469051c2-0c5e-4dae-9c4c-848302d15f9d", name: "University of Technology Sydney" },
    { id: "93fbefbd-4a2d-464f-85ef-740c1cf9a30e", name: "University of Technology, Jamaica" },
    { id: "c30e3ee5-a105-4a60-9adb-0f1f3c10a9ba", name: "University of Tennessee" },
    { id: "ba44c463-d09a-4638-9add-5f723984a696", name: "University of Tennessee - Chattanooga" },
    { id: "0dc73325-bba0-4082-91ab-d0bacb89a9cd", name: "University of Tennessee - Martin" },
    { id: "b8d003f2-ae0a-4f97-a497-af99a86d9656", name: "University of Tennessee - Memphis" },
    { id: "9715295b-b627-42af-8ad0-4028e6283b59", name: "University of Tennessee Southern" },
    { id: "14acfd35-5872-4c42-b2cf-8fc025eaa2aa", name: "University of Texas - Brownsville" },
    { id: "16fc021f-1691-452f-a0b6-4c830198f787", name: "University of Texas - Rio Grande Valley" },
    { id: "ba6a4f59-907e-4d00-803c-dc6d59465274", name: "University of Texas - Tyler" },
    { id: "4708740d-54f0-41cc-bd0c-f8614b969ec1", name: "University of Texas at Arlington" },
    { id: "88e6bac2-0c81-4f2d-bf17-583bb623bf24", name: "University of Texas at Austin" },
    { id: "726fc254-0460-43fc-97b8-e73832859fbd", name: "University of Texas at Dallas" },
    { id: "077b53df-654c-4d90-a1b0-890dbde30e40", name: "University of Texas at San Antonio" },
    { id: "a27d6555-a24f-46da-97da-7aac6c938342", name: "University of Texas Health Science Center - San Antonio" },
    { id: "d4e8a009-32a6-407a-bcc8-bb56a31b4239", name: "University of Texas Health Science Center At Houston" },
    { id: "68f63618-1175-43f7-aefa-d540de63d57b", name: "University of Texas Medical Branch" },
    { id: "4341a8ec-44a7-402f-b077-c6968df6cc91", name: "University of the Aegean" },
    { id: "efa1c0b0-ac74-427f-a300-36e85aa291fd", name: "University of the Arts London" },
    { id: "d60db1d4-9cfc-48b3-94dc-426950b65585", name: "University of the Bahamas" },
    { id: "32ffcae9-1b28-4a56-b713-fe53340df784", name: "University of the Balearic Islands" },
    { id: "a6942671-7f29-4799-b731-28b04e4fcd67", name: "University of the Cordilleras" },
    { id: "f73ddec8-f878-47bb-bf74-cbbbdec3305d", name: "University of the Cumberlands" },
    { id: "e360ad84-009a-4747-a26a-2491fe644972", name: "University of the District of Columbia" },
    { id: "be64f453-f685-4af1-b4f7-c3b9dda1359a", name: "University of the East" },
    { id: "794bef12-4791-469c-8f2c-5be6da1ca187", name: "University of the Free State" },
    { id: "2ae63575-73f2-4c59-a21e-1728cb1d5641", name: "University of the French West Indies and Guyana" },
    { id: "ba8d570d-efb8-4bfc-8ccc-bca412ad97be", name: "University of the Incarnate Word" },
    { id: "c3ffde85-cbc3-47f4-a93b-54889c94075d", name: "University of the Latin American Educational Center UCEL" },
    { id: "be7db00f-8306-4d38-a6d0-46c6af3dbe53", name: "University of the Netherlands Antilles" },
    { id: "c033846b-0a39-434f-a0d4-2ab2f4bc2648", name: "University of the Ozarks" },
    { id: "38470a49-305e-496a-b666-75902ac6979f", name: "University of the Pacific" },
    { id: "61246092-3e3f-4dc2-acdc-2f8912cad905", name: "University of the People" },
    { id: "c1f46e09-8257-4bc2-a2a0-128d71a0e52c", name: "University of the Philippines" },
    { id: "c146863e-ed6e-4e6c-8f31-35ca253a96fb", name: "University of the Philippines Los Banos" },
    { id: "00c34fe2-411f-49ff-9ed9-a32c9f7247f9", name: "University of the Punjab" },
    { id: "58192430-5c6c-426a-8b24-f2453ccff1a5", name: "University of the Rockies" },
    { id: "1ca1fb44-bbd3-40dd-b973-f2287e344f7b", name: "University of the Sciences In Philadelphia" },
    { id: "1aafeef2-6527-44b8-af1f-948e1a84cd82", name: "University of the South" },
    { id: "17f200d4-6e5a-408c-b984-d3b56a83ba28", name: "University of the South Pacific USP" },
    { id: "5004584e-5dd6-4198-8e95-132679ca6042", name: "University of the South, Toulon-Var" },
    { id: "0cd79a23-903d-44a7-981f-7035d5335c76", name: "University of the Southern Caribbean" },
    { id: "b26b368a-f333-414e-9037-4c7164391998", name: "University of the Thai Chamber of Commerce" },
    { id: "e4660726-9804-4a38-97fb-ab34e5321273", name: "University of the Virgin Islands" },
    { id: "90ce4d24-9bcc-4bc5-a597-e659795594e3", name: "University of the West Indies" },
    { id: "27729d2b-28a1-4af3-96a1-285ff1e8ea8e", name: "University of the West of England - Bristol" },
    { id: "aa120b4b-cdba-45fe-82ac-b66fb352f007", name: "University of the West of Scotland - Ayr Campus" },
    { id: "c7333ac6-291d-4f09-a012-4e073c9660ce", name: "University of the Witwatersrand (WITS)" },
    { id: "6a8204c9-086f-47df-8269-9ccf620900c8", name: "University of Thessaly" },
    { id: "1779325d-5c4a-4a5c-851a-264dd1817814", name: "University of Tirana" },
    { id: "8de76e66-7329-4577-8c14-3fbd70b8b5cb", name: "University of Tokyo" },
    { id: "c253fb17-caea-4fcd-8cdd-a198f03d8870", name: "University of Toledo" },
    { id: "16a9583b-224d-47b7-864c-09d9d204c6d7", name: "University of Toronto" },
    { id: "fd0a8352-076a-48d5-a7ff-6d87720ddc32", name: "University of Toronto - Mississauga" },
    { id: "f36f7974-3ae3-4e17-afad-d0d4c676f253", name: "University of Toronto at Scarborough" },
    { id: "f74a9ac5-7a75-43e5-a165-711428fcf870", name: "UNIVERSITY OF TORONTO SCARBOROUGH" },
    { id: "0a068255-5d04-4d5e-9609-d94207e21516", name: "University of Toulouse 1 Capitole" },
    { id: "939e3b05-38d9-45fa-9c24-9e5f6cb6ecb9", name: "University of Trento - UNITN" },
    { id: "36194a92-9827-486f-a985-9d526415ad52", name: "University of Trieste" },
    { id: "80b9f451-12da-44d2-ac91-c6305a8e8a2b", name: "University of Trinidad/Tobago" },
    { id: "b274b156-e2e7-4e76-bf8a-0901779931f6", name: "University of Tromso" },
    { id: "bce1d65c-3f52-491d-8efc-fc8e6259d693", name: "University of Tsukuba" },
    { id: "1cb2666c-3fc9-440f-ba22-02cc634296d9", name: "University of Tuebingen" },
    { id: "654d8092-8a24-409e-ae4b-3289309aa1a8", name: "University of Tulsa" },
    { id: "af04cd68-6072-43be-b46c-51a2aa4b9dda", name: "University of Tunis" },
    { id: "f09e8431-00d3-4f87-a4ea-5a51c40d1c6d", name: "University of Turbat" },
    { id: "c77e3fb4-57d4-4a96-bbf3-240224584115", name: "University of Turin" },
    { id: "575e7200-84e5-4254-818f-6d844988c8d1", name: "University of Turku" },
    { id: "0a717557-c520-4226-871c-988b2545fef2", name: "University of Twente" },
    { id: "ce48cf89-8e43-4f81-ae60-6e53a14d965c", name: "University of Ulsan" },
    { id: "e5426fbb-55b2-4c51-ab3a-61d88c29c14f", name: "University of Ulster" },
    { id: "094cd510-33dc-4e15-90c1-f8827a07b8b8", name: "University of Utah" },
    { id: "ec7e86db-3209-4c58-8234-cfcdadc8c6f3", name: "University of Vaasa" },
    { id: "afbc9ecd-05bf-4718-addf-1612b7ba1177", name: "University of Valencia" },
    { id: "f361e66d-e197-4b42-8b4c-31415337c7e4", name: "University of Venice, Department of Business Economics and Management" },
    { id: "4000c0f0-4e12-4205-9924-69174f110704", name: "University of Vermont" },
    { id: "14e02715-baea-4244-8e0b-fbc4ddb8db1b", name: "University of Versailles Saint-Quentin-en-Yvelines (UVSQ)" },
    { id: "c60100bb-f1f7-49ea-ac69-972bb4cb091e", name: "University of Victoria" },
    { id: "04aab416-7cb7-451b-8622-eb257aac93d4", name: "University of Vienna" },
    { id: "5a8a2c6a-70fe-4c9c-a995-fd6c969c265a", name: "University of Virginia" },
    { id: "140e8e3b-7dbe-439a-b6a5-79ae69c4dc7e", name: "University of Virginia - Darden School of Business" },
    { id: "bbc8c865-901d-4d83-961c-1c2f6c3a2d68", name: "University of Virginia Medical Center" },
    { id: "1b70f1f4-e7e6-44b4-af2a-c5e0fba1a2a8", name: "University of Waikato" },
    { id: "65291ad2-bb8a-41e6-8391-ed6611d8bd60", name: "University of Wales" },
    { id: "3ba41a58-869e-495f-b918-ca8b259b0a0f", name: "University of Wales Trinity Saint David - London Campus" },
    { id: "d641b2d0-814a-4be0-9a2e-b0eb163f33b5", name: "University of Wales Trinity Saint David - Swansea Campus" },
    { id: "104a85cc-a247-4f6b-9c83-b7208b26844c", name: "University of Warmia and Mazury" },
    { id: "a977564e-2e61-4508-bdf3-a5d21db4a922", name: "University of Warsaw" },
    { id: "eb26573a-ac55-4007-886f-57f575068f3e", name: "University of Warwick" },
    { id: "97ac3172-7ff6-423e-8e37-c09fd6c52908", name: "University of Washington" },
    { id: "918a81a8-4e00-404b-9664-08c47c1296b7", name: "University of Washington - Bothell" },
    { id: "e771d1b2-5f0b-4c88-90e7-f429f861da2c", name: "University of Washington - Tacoma" },
    { id: "4938dbad-5ef3-4cea-847d-e6d66f442fe9", name: "University of Washington Libraries" },
    { id: "5e89a4c4-21d4-4c89-930f-d11210d2a252", name: "University of Washington Library" },
    { id: "6e4afda3-9f68-4acd-8d1f-2aeeaf9e5fcc", name: "University of Waterloo" },
    { id: "ef89a6c6-7e3f-4859-8a6f-0a569b63d803", name: "University of Waterloo - School of Public Health and Health Systems" },
    { id: "fcbbe5ed-621d-4def-9d36-202cbf3c6389", name: "University of West Alabama" },
    { id: "473f78f9-45f3-4776-b45f-77c72bd5960f", name: "University of West Bohemia" },
    { id: "dc47e359-dbe1-4346-b2dc-5f2eefa9ee49", name: "University of West Florida" },
    { id: "74e46851-744c-4e70-b1c5-094955dbb197", name: "University of West Georgia" },
    { id: "b72c978c-2a4d-4319-8c87-c3c78952d03e", name: "University of West Hungary" },
    { id: "c98027b5-abe6-4614-9727-9fdc00019047", name: "University of West Indies, Grenada" },
    { id: "81230b55-25e1-4aa6-81e4-bfdffdcb4828", name: "University of West London" },
    { id: "0a9cea9a-dad6-45fa-92ed-705a6756aa18", name: "University of West Los Angeles" },
    { id: "708b01ef-2426-4135-8d88-0b6ad8164d02", name: "University of Western Australia" },
    { id: "8f1c292e-db78-4efe-aa5e-ddaa599ba4a7", name: "University of Western Cape" },
    { id: "44f16638-a98f-4f66-84eb-e6de26e01bd4", name: "University of Western Macedonia" },
    { id: "fd442d07-62a3-47e7-8615-8678197d31ae", name: "University of Westminster" },
    { id: "76c1b537-a255-4ea8-aec7-a53b4bac76eb", name: "University of Winchester" },
    { id: "e109bf68-f242-4d1b-963e-90221fe9c0ba", name: "University of Windsor" },
    { id: "b0f39403-943d-49ae-b971-852b85e21b8b", name: "University of Wisconsin - Baraboo Sauk County" },
    { id: "29009cb5-3844-4e38-9ef2-1edb3abb359a", name: "University of Wisconsin - Eau Claire" },
    { id: "aea6dcf7-752c-4b9d-84cc-03b862169bc1", name: "University of Wisconsin - Extension" },
    { id: "44491d44-30eb-44fa-b542-a451a7883a9f", name: "University of Wisconsin - Green Bay" },
    { id: "dfad1b9f-d867-4f6b-a4f6-5a56609acd43", name: "University of Wisconsin - La Crosse" },
    { id: "df24b828-d371-4b1a-b018-e1f03ba123fc", name: "University of Wisconsin - Madison" },
    { id: "c89fb6d9-6ac5-4ced-955b-3bcd9ce70158", name: "University of Wisconsin - Madison - Agricultural & Applied Economics" },
    { id: "42c07108-6f7a-42c4-b3fa-967cdb227d3a", name: "University of Wisconsin - Milwaukee" },
    { id: "1b79190c-d1b3-4251-aff8-6bfa3fe0d370", name: "University of Wisconsin - Oshkosh" },
    { id: "8913a3d9-f413-436f-98b7-cdc382e7144e", name: "University of Wisconsin - Parkside" },
    { id: "6f9997d8-2bdc-4f44-8370-675031c67119", name: "University of Wisconsin - Platteville" },
    { id: "69115d1e-f9e6-45ed-827c-94780f2d633f", name: "University of Wisconsin - River Falls" },
    { id: "6959663d-0384-4342-9438-e8f211648caa", name: "University of Wisconsin - Stevens Point" },
    { id: "b0d0fd66-88a2-4cb8-8eaa-f9b7436d77d0", name: "University of Wisconsin - Stout" },
    { id: "9c7c8640-6749-4506-b582-de18e56ab0c5", name: "University of Wisconsin - Superior" },
    { id: "8f431727-9e1c-4264-b9af-57a8cc9ca435", name: "University of Wisconsin - Waukesha" },
    { id: "8753ac10-e5a6-4c87-bad5-84b02b08d3bb", name: "University of Wisconsin - Whitewater" },
    { id: "d1bf96e6-dcce-4d6a-9d06-ae7e411557b9", name: "University of Wollongong" },
    { id: "3ddf9cab-0ab4-4987-b771-4acb833bb926", name: "University of Wollongong - Dubai" },
    { id: "24df1396-57cb-41a0-992c-bab1c0ea6112", name: "University of Wolverhampton" },
    { id: "af236ef1-0d8e-4cbd-b743-d1cfc05cb179", name: "University of Worcester" },
    { id: "ae4329fc-6af8-4125-863b-9c6781685b4a", name: "University of Wroclaw" },
    { id: "893a2755-c43e-4532-abe1-448a4af5ae61", name: "University of Wuerzburg" },
    { id: "86ab3b9a-afd3-48e0-84fb-859570a38db5", name: "University of Wurzburg" },
    { id: "347439c5-d01c-4424-88eb-680cb13fefc2", name: "University of Wyoming" },
    { id: "3e8c456a-34d8-411f-a572-3fa92d41e66f", name: "University of Wyoming Extention" },
    { id: "14104aa3-8211-40c5-9ced-0810bcd62376", name: "University of Yamanashi" },
    { id: "ab9eb4c5-411d-44e6-accb-059a4a3cc3db", name: "University of Yangon" },
    { id: "9e215b99-a6fd-4033-85e4-a4f8d5d4e8f2", name: "University of York" },
    { id: "9358eb5b-3cae-4075-9700-bf6da7eaa19f", name: "University of Zagreb" },
    { id: "5f88a1c9-e05c-4d22-a652-1e3a3052f9fb", name: "University of Zambia" },
    { id: "f527964d-89a8-4e19-bc9d-8359d10ceb50", name: "University of Zaragoza" },
    { id: "e473fe79-8ab4-4806-8bb6-f24e86242ab5", name: "University of Zululand" },
    { id: "25d92db4-66c3-4c65-a84c-7518c9da5db5", name: "University of Zurich" },
    { id: "00eba140-65f4-4623-9ca4-9aca85fa3fd4", name: "University of Zurich - Executive MBA" },
    { id: "8217d594-cc9a-42c4-a50e-ad130fbe1fd2", name: "University Paris-Est Creteil UPEC" },
    { id: "3a16a2df-f415-4748-9f1b-49c78bdde3af", name: "University Politehnica of Bucharest" },
    { id: "1d8720fe-d829-42ee-8537-bed7f3dfd6b7", name: "University Readers" },
    { id: "074db84e-6579-4e6c-8acb-4f2a4f183b7c", name: "University School" },
    { id: "3043b3ff-5250-4b2f-913c-9d8140233aa5", name: "University Teknologi PETRONAS" },
    { id: "313931a2-c782-4f8b-a965-d351fdfa581d", name: "University Tun Abdul Razak" },
    { id: "e5725de5-bfd7-4b66-8a66-4e9e7e615b51", name: "Università Cattolica del Sacro Cuore" },
    { id: "0da8ffec-8f96-4ac9-bf77-dc20f7640b08", name: "Università degli Studi del Molise" },
    { id: "46271c3d-cd6b-469c-8e55-8f57586b5f4d", name: 'Università degli Studi del Piemonte Orientale "Amedeo Avogadro"' },
    { id: "7e3b7e52-1bef-4a09-9a34-9728337c6537", name: "Università degli Studi dell'Insubria" },
    { id: "041588a9-cb70-405f-90cf-e2c7549cc3a6", name: "Università degli Studi della Basilicata" },
    { id: "5dcefc88-8232-4503-b1e5-38f0661a796b", name: "Università degli Studi di Bari Aldo Moro" },
    { id: "6adcc6b0-1c1d-46dd-a77b-3061fb41b74e", name: "Università degli Studi di Bergamo" },
    { id: "ce15213b-dc4a-4f87-b93e-3d224e04c4e9", name: "Università degli Studi di Brescia" },
    { id: "9be3ebb6-7f1d-41ae-bb52-73ad515b40a8", name: "Università degli Studi di Cassino e del Lazio Meridionale" },
    { id: "8ea4cfbb-559c-4f60-a75e-3af6d5531a55", name: "Università degli Studi di Ferrara" },
    { id: "7164f7e0-bfe9-4b10-bd36-b6c345e77c62", name: "Università degli Studi di Foggia" },
    { id: "b4d8fa2a-662d-47b3-a27a-d4dadc96af91", name: "Università degli Studi di Genova" },
    { id: "c3103011-1a97-404f-b628-6a415693bb45", name: "UNIVERSITÀ DEGLI STUDI DI MILANO" },
    { id: "9c11b05c-70a8-4fe9-9123-03227d831fdf", name: "Università degli Studi di Milano-Bicocca" },
    { id: "693d8213-5424-482a-a437-621b09a51709", name: 'Università degli Studi di Napoli "Parthenope"' },
    { id: "5c15b683-865a-4a2e-89ad-a13e2a8ad77e", name: "Università degli Studi di Napoli Federico II" },
    { id: "c170c0e0-2f62-4003-807e-13e8f7e636a9", name: "Università degli Studi di Palermo" },
    { id: "8fddab60-bf55-41bb-b9be-6010e9c9d7fa", name: "Università degli Studi di Perugia" },
    { id: "1e295c64-9bb8-4e0d-baf9-6f39adb91ba7", name: "Università degli Studi di Roma Tor Vergata" },
    { id: "2b21284e-2935-40d5-a7c8-4fd1809ce83a", name: "Università degli Studi di Sassari" },
    { id: "e8def2ca-179a-4293-95f4-256b46e009a3", name: "Università degli Studi di Urbino" },
    { id: "e8f2c62e-3df6-402a-8c85-13d486baf83f", name: "Università degli Studi Guglielmo Marconi" },
    { id: "60233a80-ba09-4dd5-a73c-ac157c20134f", name: "Università del Salento" },
    { id: "a1f7f333-cf21-46b7-af51-45b1b8998ed0", name: "Università della Calabria" },
    { id: "4537d601-7900-4230-aabc-137ef1315e91", name: "Università Della Svizzera Italiana (USI)" },
    { id: "726478b8-a945-46d4-8d9c-96318883cffe", name: "Università di Cagliari" },
    { id: "031c6fe7-3414-4c10-8dc9-0e3f6fe2c300", name: "Università di Domani" },
    { id: "6a951159-672d-438e-8255-2a3a9f860fbb", name: "Università di Macerata" },
    { id: "ea2a0a4d-677c-4df0-978b-c28068bd17df", name: "Università di Pisa" },
    { id: "bb7da6f0-88f5-4419-942d-0eb6aeaf24e2", name: "Università di Udine" },
    { id: "d4f0e1ef-748b-4291-9f86-5e3b9750f36b", name: "Università per Stranieri di Perugia" },
    { id: "f79a11e5-f1b5-4610-8f39-16a650d3e972", name: "Universität Bielefeld" },
    { id: "44a48341-df37-445e-b9bc-bf694f9d30b0", name: "Universität der Künste Berlin" },
    { id: "b0b01897-a2e9-436a-85ca-4ca9e61688b9", name: "Universität Oldenburg Ammerländer Heerstr" },
    { id: "348a1bfa-e77b-4e0c-8497-b25cf1c6deb8", name: "Universität Potsdam" },
    { id: "f7d27bb4-db52-45af-a183-727f21fc61a3", name: "Universität Siegen" },
    { id: "54521663-aea9-4d69-a439-5810368570f6", name: "Universität Witten/Herdecke" },
    { id: "596b6802-58c5-46f9-9886-c1bd4db3761b", name: "Universität Zürich" },
    { id: "24ea0719-c5d3-4d50-8f1f-247d478493e1", name: "Université Catholique de l'Ouest (UCO)" },
    { id: "141df1b4-13bd-4663-8d52-13d2f69ed5c2", name: "Université Catholique de Lyon" },
    { id: "bde1f44e-c2f3-489c-8875-e59e4cadb587", name: "Université d'Auvergne Clermont-Ferrand I" },
    { id: "3df2b070-d05a-42fa-81bb-ef453701add6", name: "Université d'Orléans" },
    { id: "20037b87-21a6-4fbd-91b0-72cb84b00bf6", name: "Université de Caen Basse Normandie" },
    { id: "a431ecee-623b-4c8f-9984-f26404c6df82", name: "Université de Genève" },
    { id: "400333b7-5421-4fe0-96a5-474adbd62e62", name: "Université de La Rochelle" },
    { id: "6dadd243-f16b-4292-998d-b6e28e6da2a2", name: "Université de Lille" },
    { id: "1706f047-0f7c-40ab-9b37-51be17466216", name: "Université de Limoges" },
    { id: "62ecad5a-d637-4977-a5ac-6bceb8450081", name: "Université de Lorraine" },
    { id: "4a7e9bb0-8663-459f-a5c7-cf6a5c92f299", name: "Université de Mons-Hainaut" },
    { id: "1199e38f-7c00-4490-8167-0201cee105be", name: "Université de Reims Champagne Ardenne" },
    { id: "8ebd4c97-a6b2-4f4a-b26f-e2ac1b53da78", name: "Université du Maine" },
    { id: "a88e0a13-7e7f-4361-871d-a187af5736e4", name: "Université du Québec en Abitibi-Témiscamingue (UQAT)" },
    { id: "250c2f6e-3a70-42d6-9d7a-f02a2da155cc", name: "Université du Québec à Chicoutimi" },
    { id: "fba1c8d6-f197-4c59-853f-163e0db5d50c", name: "Université Lille 2 Droit et Santé" },
    { id: "b2a69d51-7996-4a3b-9635-ffef579b72e2", name: "Université Montesquieu - Bordeaux IV" },
    { id: "489d324c-882d-41a7-99f7-f2e1e3d2d261", name: "Université Montpellier 1" },
    { id: "78c6d48b-280c-4759-85a2-ece2ce222617", name: "Université Nice Sophia Antipolis" },
    { id: "d07efbda-4401-4e1a-b35f-b54fadc0322a", name: "Université Paris 8" },
    { id: "3a8a95b5-3854-4c2c-84b3-6e97f8356abc", name: "Université Paris Descartes" },
    { id: "1a3698c8-533a-41fb-99f6-8dfd72ddabc9", name: "Université Paris-Est Créteil Val de Marne" },
    { id: "041ac8e8-318d-4d6f-a61d-117d92b2e9a0", name: "Université Paris-Sorbonne - Abu Dhabi" },
    { id: "596cced0-70c0-4ee2-ba22-d1bb01e361ea", name: "Université Pierre-Mendès" },
    { id: "c6e58e82-852d-4aa8-bc3f-f1e12178ea85", name: "Université Polytechnique des Hauts-de-France" },
    { id: "4491edf1-da15-4dcb-9434-3da22227abea", name: "Université TÉLUQ" },
    { id: "0f3f55eb-fa0c-46fb-9172-4817545c4173", name: "Univerzita Pardubice" },
    { id: "4fd3259b-6a1e-4845-bf54-6a303f96c69a", name: "Univerzitet U Sarajevo" },
    { id: "fd794f22-524c-4854-9d90-c582e0e0833d", name: "Univerzitet Vitez" },
    { id: "e3b5d6fb-2a8d-4d20-acc4-676f7ee0b331", name: "Univix" },
    { id: "d4976607-194f-4a67-af74-b2e5ec7cb1df", name: "Uniwersytet Lodzki" },
    { id: "95eb70e1-3c08-44d0-ad5a-58b67883875f", name: "Uniwersytet Technologiczno-Humanistyczny" },
    { id: "1ce646f4-4c4a-4277-a5dd-9174728a38d1", name: "Uniwersytet w Bia?ymstoku Wydzia? Ekonomii i Zarz?dzania" },
    { id: "1bdcf72f-6f35-4192-89bd-f7ef9455c96a", name: "Uniwersytet Zienlonogorski" },
    { id: "c2dac734-d364-4bac-b4d5-2d21716b9e52", name: "UNSW Australia - Canberra" },
    { id: "db6402af-31cc-4798-aa64-50b80bd03727", name: "Unviversity of Puthisastra" },
    { id: "13c09717-9622-4800-b797-c4c620eb059f", name: "UP Inc. Preparatory School" },
    { id: "555055e1-f976-4510-a48f-87f026405d62", name: "UPAEP - Universidad Popular Autonoma del Estado de Puebla" },
    { id: "c04cf83e-c049-497f-b8d2-dcbd96e2a557", name: "UPC Barcelona Tech" },
    { id: "3c243c7e-d7b2-407d-9b9e-6c349ac5cff3", name: "Upgrad" },
    { id: "b31307c9-7b07-4682-bfbe-36c9947d5a54", name: "Upper Austria University of Applied Sciences" },
    { id: "6f38455d-1061-420f-a2dd-238785f3728a", name: "Upper Iowa University" },
    { id: "e6d2efab-cd5d-451e-b043-54ec53cc766e", name: "Upper Valley Educators Institute" },
    { id: "14e0730f-20ae-4715-ad02-0a46007efb29", name: "Uppsala University" },
    { id: "aed78221-cb43-49a1-8c95-dc49ca955432", name: "Ural Federal University" },
    { id: "e4114fd5-7677-4543-b8fe-c1d825b2f525", name: "Ursinus College" },
    { id: "09a79914-93b1-4ce2-9405-d862933c4b66", name: "Ursuline College" },
    { id: "5968620f-09e6-40fa-8376-81653774f930", name: "US Army Sergeants Major Academy" },
    { id: "658190ef-783d-4a08-abeb-94200489e9c3", name: "US Army War College" },
    { id: "59aa20c4-c35b-43c9-a176-96a8829595bc", name: "US Coastguard Exchange System" },
    { id: "ade7606a-2d45-4902-9efc-d39dcf058b3f", name: "US-CHINA Business Training Center" },
    { id: "f8078126-4fdc-4d3f-946a-ce23c2fa1aa2", name: "Usmanu Danfodiyo University" },
    { id: "92a7761f-1fa6-46db-ba49-98ca8d6ebcb7", name: "Ustav Prava" },
    { id: "ddaa981a-2ccf-4ac9-82cc-39b8325eeb78", name: "USU Campus Store" },
    { id: "b0bbdd58-21ed-420d-9565-dd203a49d2a3", name: "UT Southwestern Medical Center" },
    { id: "f79f36a9-788d-4d0e-8dce-aa8af3eb1e2d", name: "Utah State University" },
    { id: "4fbe6b99-5ddd-4809-bc7e-780cd13c1b76", name: "Utah Tech University" },
    { id: "a81f8ca4-3e0b-4b77-8245-823056bd92e2", name: "Utah Valley University" },
    { id: "55a28bfa-bdf3-417d-84da-65004c8a6d7f", name: "UTEL University" },
    { id: "ea150e05-6269-498b-996a-1c2c519b6050", name: "Utica College" },
    { id: "df5b0d23-00da-4a87-b50d-e5bd66fc4d55", name: "UTKAL UNIVERSITY" },
    { id: "116ea12b-b6fa-49b6-af7c-82b4637c3ec8", name: "Utrecht University" },
    { id: "eeb7224d-49de-42b0-8bd0-596de41385a3", name: "Utrecht University - School of Economics" },
    { id: "ce935065-4110-4d39-8e76-da97235cc6fa", name: "Uttarakhand Open University" },
    { id: "7314eb86-3af8-4f8a-a3e4-5c35636bea54", name: "UVA Libraries" },
    { id: "028de538-3688-451e-bd7a-56ce978d5be0", name: "UW Parkside Campus Store # 487" },
    { id: "aaac9d1f-681e-4c61-bd79-e2308ef43238", name: "UWC Red Cross Nordic" },
    { id: "2e6dbd39-504f-4f9c-87a3-6923417fb0ee", name: "UWI Roytec" },
    { id: "3e753a2c-16e2-4177-a1a8-f291b5439c70", name: "UWI School of Business and Applied Sciences" },
    { id: "52ab811d-3a47-45a6-9ba2-b02d80646eaf", name: "Uxbridge College" },
    { id: "54684ba2-d2e5-4862-827b-9f5bdf2a5d2d", name: "V.M. Patel Institute of Management" },
    { id: "49adb612-84b1-4b22-89db-98b7712c2864", name: "VAAL UNIVERSITY OF TECHNOLOGY" },
    { id: "dc5d1603-4136-45d5-96a9-2aa4a9e85fcf", name: "Valdosta State University" },
    { id: "ad8e588c-fd7b-4496-b0bc-3639a5161b4e", name: "Valencia College" },
    { id: "d4610f91-960f-45ef-a1b3-ff1511bbd3c1", name: "Valladolid University" },
    { id: "2f1a111d-2626-4cee-a1ef-64faa4fe5daa", name: "Valley Christian High School" },
    { id: "efea4b8c-0d96-4625-8370-2f83dc9dfd58", name: "Valley Forge Christian College" },
    { id: "11ecaa43-6416-4810-84ed-48ce93df7410", name: "Valparaiso University" },
    { id: "47991364-b86a-4d28-9307-dcee8bd30c9e", name: "Vancouver Community College - Hospitality Management" },
    { id: "832d55eb-b6e8-4124-8a57-e21a50b34fa2", name: "Vancouver Island University" },
    { id: "7353aca5-76cd-496a-ac61-60a6994670e2", name: "VandalStore/ University of Idaho" },
    { id: "45bc863b-3996-4481-9e97-7cefd524f707", name: "Vanderbilt University" },
    { id: "00dd8753-184f-48c1-be6d-2a3822593785", name: "Vanguard University" },
    { id: "a4847a46-13eb-43c5-9194-fdf697c30fb3", name: "Varsity Bkst of Lubbock" },
    { id: "997ffe27-6c07-4631-96ff-b2d979a5fefb", name: "Vassar College" },
    { id: "b6208436-8438-444f-b27b-24e104550965", name: "Vatel International Business School - Los Angeles" },
    { id: "6549c1f8-679c-493c-b754-76d051beca0c", name: "Vatel Nimes Intl Business School" },
    { id: "9063b7a8-a0d0-4629-87cb-08e5f28fab61", name: "Vatel Paris" },
    { id: "3a05f242-fa0d-415f-b32c-224dc2c1e661", name: "Vatel Switzerland - International Business School" },
    { id: "5d715ea9-8321-4da6-b9f3-33a6665e4c7c", name: "Vatterott College" },
    { id: "d56af520-963c-414a-beeb-b4faad1e5dff", name: "Vaughn College of Aeronautics & Tech" },
    { id: "529f6d76-5382-4951-947f-1b9a158f79fd", name: "Vedatya Institute" },
    { id: "e6950e6a-400d-4c68-9afb-c55d16a71dd4", name: "Vedica Scholars" },
    { id: "592d0828-d680-4e93-af72-4e4fb7d8a798", name: "Veer Narmad South Gujarat University" },
    { id: "948be4cc-f454-4c66-9413-c8d449c781d1", name: "Velammal Institute of Technology" },
    { id: "c4c4f79c-8b1c-47ef-92ee-69dc366bca38", name: "Venda ecommerce" },
    { id: "dfa4bfbd-7043-4240-baf0-9c6d94e33251", name: "Venice International University" },
    { id: "b33c3bf3-1dd1-43fd-b896-c3d35c6a1a30", name: "Vennard College" },
    { id: "4c2353d4-a292-41c8-9851-4cca5d564574", name: "Ventura County Community College District" },
    { id: "9b410d2d-c1e2-440c-93a8-19e28f0f5885", name: "Vermont Law School" },
    { id: "96c4fb7c-1b97-48fc-b44e-1a0ebca7babf", name: "Vernon College" },
    { id: "a4317e25-154d-44b8-902d-4dbe4ad0074f", name: "Verona University" },
    { id: "8a632c38-11eb-4213-84ad-523b522c3c0d", name: "Verwaltung Wirtschaft Akademie (VWA)" },
    { id: "e27b6272-d7f7-477a-a4ca-b64ef73b2537", name: "Vesalius College" },
    { id: "98d8c24c-f0e2-45ed-a919-7dd1b4f04aa8", name: "VESIM - Vivekanand Edu Society Inst of Mgmt Studies & Research" },
    { id: "07b2e214-e58d-45da-884c-e547cc5020b2", name: "VIA University College" },
    { id: "b26fc4d5-1348-4a4a-9c76-34da00d5f792", name: "Victor Valley Community College" },
    { id: "e68cd02d-f0d0-40e0-b218-1c04d7f9dd79", name: "Victoria College" },
    { id: "3312c029-424e-48f9-9dad-93a1c6aac781", name: "Victoria University - Melbourne" },
    { id: "c35057e0-5476-458d-9f0e-87d021755183", name: "Victoria University - Wellington" },
    { id: "ce043b51-aa0f-4c31-964c-352913773558", name: "Victoria University Canada" },
    { id: "ea866083-efa1-4f69-bce3-be21f1745763", name: "Victory University" },
    { id: "ec50b75f-06b4-46ed-a7f9-0ed6f84d9450", name: "Vidzeme University College" },
    { id: "42e5f183-ae84-4256-b8ea-c195d8301511", name: "Vienna University of Technology" },
    { id: "64fc5475-1d68-42e3-81de-b413282431a5", name: "Vietnam National Univ, Ho Chi Minh City" },
    { id: "aa1b165c-aa70-4919-8149-a5a8b0e93424", name: "Vietnam National University" },
    { id: "00f333f0-6dfa-4568-b0b4-556691f2182e", name: "Vietnam University of Commerce" },
    { id: "66580ef1-ef5f-4f52-86ba-4c887eac6533", name: "Vietnamese-German University (VGU)" },
    { id: "9f37f8c9-9c25-4b2e-8faf-cc346bb20f9e", name: "Vignana Jyothi Institute of Management" },
    { id: "c918643d-f560-413e-8b34-88b17560251c", name: "Vignana Jyothi Institute of Management (VJIM)" },
    { id: "2e00d57b-b6b1-4090-8e81-8abe4ba37bb4", name: "Vikram University" },
    { id: "eb64f13a-6378-4b5a-a964-bb1c44416a82", name: "Villa Julie College" },
    { id: "c635f1ef-fe67-4f58-bed8-c841a2c5d093", name: "Villa Maria College" },
    { id: "7d6b92a7-ae88-4a3c-b2c2-a53756a77fdd", name: "Villanova University" },
    { id: "cb480366-5c1a-4c89-aa94-6288586523c9", name: "Vilnius Cooperative College" },
    { id: "aa12346f-5b65-4958-af39-de18d6eafb2b", name: "Vilnius Gediminas Technical University" },
    { id: "0b0728d2-97c3-4272-a514-aeb37b2b3c14", name: "Vilnius University" },
    { id: "87e57b52-7201-42b3-8bee-679f72a57163", name: "Vimal Jyothi Institute of Management" },
    { id: "a3978e47-b938-414f-a50d-a2d981b84267", name: "Vincennes University" },
    { id: "581fcc0f-1b46-43a2-9714-969e9d781aaa", name: "Vinod Gupta School of Management IT Kharagpur, VGSOM-IITK" },
    { id: "6ffc4dd0-43fb-44ca-b1c2-7f2108349d8c", name: "VINS Christian College of Engineering" },
    { id: "b27e60b8-bc40-43b1-9539-d066aa8263f5", name: "VinUniversity" },
    { id: "39726353-107b-4fd3-84b9-527c720f45ab", name: "Virginia Beach Public Schools" },
    { id: "dd1a8073-33af-4def-ba3c-4a19398cd8a7", name: "Virginia College - Alabama" },
    { id: "4fa00324-9eb3-4c50-bf63-2140ab8150ea", name: "Virginia Commonwealth University" },
    { id: "594afddf-f365-4cac-85ef-c6f50fb07809", name: "Virginia Intermont College" },
    { id: "3ab8968e-0af5-4c17-ab2e-4a99acc871fc", name: "Virginia International University" },
    { id: "32f44cdb-9fde-45ae-9432-a972d955d54f", name: "Virginia Military Institute" },
    { id: "4ec2202d-9d17-4a0f-982d-b0960d659bfa", name: "Virginia State University" },
    { id: "2c281fd4-9016-4dec-90ce-17a207239c6a", name: "Virginia Tech University" },
    { id: "e531eee7-4e4c-4300-a7a3-eee5b492a03b", name: "Virginia University - Lynchburg" },
    { id: "72d4404b-438a-4a85-ae65-f611f5d64093", name: "Virginia Wesleyan College" },
    { id: "d2eccc3a-3251-43f4-9c40-d21a5a41b78d", name: "Virginia Western Community College" },
    { id: "5a0b925d-405b-4504-bd56-db70669f3ac4", name: "Virtual Staff College" },
    { id: "25b9eef5-9ea8-4c2d-94f1-35cd415e0ad7", name: "Virtual University of Pakistan" },
    { id: "6b5aa425-74c7-4a5f-b3bf-5f7c5736a8ed", name: "Visvesvaraya Technological University" },
    { id: "0727baf3-aef5-4acd-9783-907079f7f9e3", name: "Vit University" },
    { id: "1c0df709-6f94-4a34-8645-dd57738da48c", name: "VIT University - Chennai" },
    { id: "ab43ac2b-8e9f-4cfe-8993-bac84c9c89b0", name: "Viterbo College" },
    { id: "fa906194-b167-4b33-8f47-59ea53fa4b8e", name: "Viterbo University" },
    { id: "41fe66b8-10ab-44d9-9d61-ee09a74c462b", name: "Vivekanand Education Society's Institute of Technology" },
    { id: "74ea7e30-c36c-481b-8777-7cb78be63515", name: "Vivekananda Global University" },
    { id: "388b8a6e-3b0f-44e3-9d6b-be56417070ea", name: "Vladimir State University" },
    { id: "c8b1afc1-b107-4ef4-9282-0b07f5a62be2", name: "Vladivostok State University of Economics and Service" },
    { id: "448d5890-4d01-4d40-b4cc-e7232fb2bfb2", name: "Vlekho Business School" },
    { id: "8c9ec5b5-98d7-4f93-b90b-f22fcb066fee", name: "Vlerick Business School" },
    { id: "7d41b1ca-837b-4d50-aa20-46c915baf38f", name: "VNU University of Economics and Business" },
    { id: "4d2ccc83-b89f-454f-9532-018a1361e01e", name: "Vocational Academy - Stuttgart" },
    { id: "18bc2d91-4c68-4c9c-96bd-31bb52e5938d", name: "Voertman's" },
    { id: "b9c7f45c-3f88-43c5-9ab6-37700bc8174c", name: "Voorhees College" },
    { id: "09c90052-d22b-419d-b175-862ff9424961", name: "Vorläufereinrichtungen der Fachhochschule Würzburg-Schweinfurt" },
    { id: "7ead2b87-3d6d-46e2-a919-6d9f4ebdfd03", name: "Vrije Universiteit Brussels" },
    { id: "e1da90ad-f8ad-45bb-926b-61217c3d0e00", name: "VU University Amsterdam" },
    { id: "e360efad-d1c2-460b-bf63-5ac402924954", name: "VUZF University" },
    { id: "b0812f46-0c2e-487f-9f51-863171d1514f", name: "Vwa-Studienakademie, University of Cooperative Education" },
    { id: "657d3775-cb6d-4b4e-8abc-d6fe49285789", name: "Vzw HUB-EHSAL" },
    { id: "b09e8bc9-c4d9-4029-bb4a-49274421cfd0", name: "W. P. Carey China Programs" },
    { id: "c66ea586-3b47-4068-9a5a-f96c7a19a94e", name: "Wabash College" },
    { id: "6203ae7b-5524-411d-93d0-be51eaa83b05", name: "Wageningen Universiteit" },
    { id: "e7af1d34-f9b6-4155-b510-f8b61ad89ba7", name: "Wageningen Ur" },
    { id: "2c92e511-9dd8-4070-8f7a-825283bc2b8a", name: "Wagner College" },
    { id: "876333e8-36ae-4b66-ab8b-ecd036ea52d6", name: "Waiariki Institute of Technology" },
    { id: "42736787-e04e-4316-ab3d-3c15b1612640", name: "Waikato Institute of Technology" },
    { id: "aacee3a4-53b1-400c-a8d4-30c64746bcaf", name: "Wajdi University College of Technology" },
    { id: "006d7a45-c7f7-49d9-af40-2338a6024d9a", name: "Wakayama University" },
    { id: "d42b793b-63ea-4ff0-b407-fd1c3f135102", name: "Wake Forest University" },
    { id: "4736ecc5-f552-475e-a523-32e6a995a864", name: "Wake Tech Community College" },
    { id: "90b18c7e-8c2a-4960-885b-52bf24c94fc8", name: "Wakefield High School" },
    { id: "1df3b45f-6fe5-4777-8d00-49225a2c531d", name: "Walden University" },
    { id: "657c5f52-63bf-4904-9ac9-67895e3a3073", name: "Wales Deanery (School of Posgraduate Medical and Dental Education)" },
    { id: "e8cd6361-38c0-4aa8-8d33-c8fcb88ab5ff", name: "Walla Walla Community College" },
    { id: "aeed5fdb-72c9-49e4-90e8-c0d88c31aa73", name: "Walsall College" },
    { id: "99f734bc-0d65-4a01-922f-a02bd64e8f93", name: "Walsh College" },
    { id: "8687393f-ab75-40c1-a540-2c723bdc78bf", name: "Walsh University" },
    { id: "ea958939-1d55-467e-930b-be286b6f8d82", name: "Warner Pacific College" },
    { id: "3c800987-236d-403d-9167-5f162c6eba94", name: "Warner University" },
    { id: "15b954a8-7f3c-4e97-b7d2-6955fb4e2ff4", name: "Warren Consolidated Schools" },
    { id: "5fb5db6b-c67f-4693-8457-f2f48a3ceb5d", name: "Warren Wilson College" },
    { id: "7c336f61-52b2-45bd-965b-9a11530fa346", name: "Warsaw Medical University" },
    { id: "55052c11-4877-4f74-b862-5a945f3c3170", name: "Warsaw School of Economics" },
    { id: "c878b25e-2805-4d0e-b376-36baf326a411", name: "Warsaw University of Technology" },
    { id: "fe7e1641-58bf-4077-a93e-6297cf8e4a1b", name: "Wartburg College" },
    { id: "71c5e53c-c2e7-4112-83fc-c6c99c40760b", name: "Warwick Manufacturing Group" },
    { id: "eb56ff50-9cb1-45af-b3ef-f0bf7abce95c", name: "Waseda University" },
    { id: "4342405b-21d2-46f6-9548-095a4f175478", name: "Washburn University" },
    { id: "024f992c-3546-497d-b0e9-93595edf27df", name: "Washington Adventist University" },
    { id: "aa0c7eb7-8119-4a61-b46e-2609af5dbaf2", name: "Washington and Jefferson College" },
    { id: "0fc16969-f581-4ae3-8544-a9fed0c6353b", name: "Washington and Lee University" },
    { id: "cc9ba245-5e47-4ede-bd52-68be1417c98b", name: "Washington Baptist University" },
    { id: "ad16ad04-0cd7-4db4-9f5e-a6366a9eaa39", name: "Washington Bible College" },
    { id: "6da701d2-8ae0-46bc-9130-55fb8186fa2d", name: "Washington College" },
    { id: "20ddc29c-6a0a-4443-9db3-6882a4e18a4a", name: "Washington Institute" },
    { id: "7e6a2af5-2dbc-42ba-89cb-4714aeddca08", name: "Washington State University" },
    { id: "4ea7f40c-b74b-4b25-9153-4be28501e52c", name: "Washington State University - Tri Cities" },
    { id: "b8f8fec9-0d49-4add-a750-48fb28548309", name: "Washington State University - Vancouver" },
    { id: "bff542f9-1dc3-4782-be6f-e6172edc4c8e", name: "Washington University in St. Louis" },
    { id: "d4a0295a-8931-47c5-b27e-f00c5ef26284", name: "Washtenaw Community College" },
    { id: "1d774bc9-1c9b-46fd-b605-dfaf33fa2e4a", name: "Waterford Institute of Technology" },
    { id: "3a046b3f-52b4-4968-8196-68d15ad5fbc3", name: "Waterloo Region Dist. School" },
    { id: "e01adf07-6ea9-4d2e-9e74-a7bd83a23f62", name: "Waubonsee Community College" },
    { id: "b6a786fa-fd5a-4ecc-a5e6-00f31292af86", name: "Waukee Community School District" },
    { id: "cd83f193-039b-4b9a-964d-4000c5603358", name: "Waukesha Technical Community College" },
    { id: "097572aa-5949-4a56-9192-94e7a1d2ef20", name: "Wawasan Open University" },
    { id: "256a8359-7939-4d98-a68d-eb6f275423b7", name: "Wayamba University of Sri Lanka" },
    { id: "4a4015aa-0d2a-4380-8e30-e71b7deba9fd", name: "Wayland Baptist University" },
    { id: "9a1154c2-7a35-4531-a658-1adddcdc2b34", name: "Wayne Community College" },
    { id: "9fc33241-4f57-4866-be03-6b0c7b3d8e2c", name: "Wayne County Community College District" },
    { id: "746a96bb-00e9-4cc6-bbbd-b7a181b51e1f", name: "Wayne State College" },
    { id: "3a970333-316b-4ef9-8867-f42756a0159c", name: "Wayne State University" },
    { id: "ce91cc62-c60c-4eb0-9e3d-fd34f7913d56", name: "Waynesburg University" },
    { id: "ddcea876-ec34-488c-8d85-46f19dc9a9b4", name: "Wayzata Public Schools" },
    { id: "087e8cb4-c5c2-4b39-8b27-bad9cd9f46fa", name: "Weatherford College" },
    { id: "37a9ecfe-10c3-4a16-b5d0-6de57a7891cb", name: "Webb Institute" },
    { id: "3c8c19fa-3da4-4806-ae99-4594c9a96d72", name: "Webber International University" },
    { id: "5f3370c9-1222-4111-9b77-428e0cd05bae", name: "Weber State University" },
    { id: "5412fd10-1e48-427f-a128-a90047769e6b", name: "Webster University" },
    { id: "fce4cfb5-c56f-43b2-8cd6-e059cd3d06ec", name: "Webster University - George Herbert WaIker School of Business & Technology" },
    { id: "5fc4573e-15de-4d55-8c06-3d52ab9e00b7", name: "Webster University - Greenville Metropolitan" },
    { id: "e7a0fc33-f929-4902-b846-af466ac86723", name: "Webster University - Netherlands" },
    { id: "f45e13d1-f243-4dd8-b9df-0926cb6f6044", name: "Webster University - Switzerland" },
    { id: "2bde7980-c4e3-4a71-962c-ed377a73b7f7", name: "Webster University - Vienna Campus" },
    { id: "2a958746-f951-4850-8dbf-97312bbe34b0", name: "Webster University Thailand" },
    { id: "9a42d676-b9d7-4f6f-85d4-a18948b96930", name: "Webster Vienna Private University" },
    { id: "48404898-19c5-4e32-90f1-2b8ff9113132", name: "Weiterbildungsakademie der Hochschule" },
    { id: "fd34ff71-69a9-47ee-945b-1585350151a8", name: "Weizmann Institute of Science" },
    { id: "96b48a43-3f46-43c9-acad-9a5242677934", name: "Wel University" },
    { id: "60ddeb54-0061-435f-989a-90ffabbf92b7", name: "Welingkar Institute of Management" },
    { id: "9b36aad5-ae10-485a-be0c-46f55a77194e", name: "Welingkar Institute of Management - Mumbai" },
    { id: "8da68ecd-15f6-4a30-998d-edf8f25bee19", name: "Wellesley College" },
    { id: "ad3a7efa-5cd7-490f-89ff-caa00b348942", name: "Wellesley High School" },
    { id: "b78f57a3-bb2e-43d5-a5a8-b09fe43dfe82", name: "Wells College" },
    { id: "da4900fc-b35d-4479-b995-7f89305283f9", name: "WelTec - Wellington Institute of Technology" },
    { id: "5f9c4403-dfc4-413d-941e-15b4ce88f6c3", name: "Wendell School District" },
    { id: "9d2c9402-1c7a-4e7d-b514-19658b81f50b", name: "Wentworth Institute of Technology" },
    { id: "7764689e-e74e-45e9-a3f0-e6cf78280d34", name: "Wenzao Ursuline University of Languages" },
    { id: "3d6bed08-6107-45c9-926e-ff6bcf29a6ce", name: "Wesley College" },
    { id: "ced3fa12-681d-4a9f-a15e-90958e5ba6b0", name: "Wesley Institute" },
    { id: "0b7bc8be-52d3-4c38-a70a-7281885a7a61", name: "Wesley Theological Seminary" },
    { id: "18ea863a-cbfd-4ae3-9fb5-74ea627760d6", name: "Wesleyan College" },
    { id: "b2d5a08a-0eed-40c3-87aa-d8519e7ff118", name: "Wesleyan University" },
    { id: "5f306e39-7ec9-4d61-8f95-af13fe3f0ea2", name: "West Chester University" },
    { id: "45d67784-c984-48c4-9b49-04b4eaf53c48", name: "West Coast University" },
    { id: "80a331da-c813-45f4-9214-5931b2e986bd", name: "West Herts College" },
    { id: "bdd0d99c-d5a8-4c06-9af4-0a91c64c1d39", name: "West Island College" },
    { id: "a1886d9f-b0f0-463e-9598-b759a976b7ea", name: "West Liberty University" },
    { id: "778e82ba-8cf4-4e06-9823-4f8712544aec", name: "West Los Angeles College" },
    { id: "db518abb-0241-43e7-97a1-8334b261e8e0", name: "West Texas A&M University" },
    { id: "5db443dc-1deb-481a-9d03-d15e7de0f579", name: "West University of Timisoara" },
    { id: "613b0097-8586-4598-848d-256d104c2555", name: "West Valley College" },
    { id: "87c4bb84-41e6-486a-8442-aa691c0542f3", name: "West Virginia Bookstore" },
    { id: "94805ff7-4c80-4307-8c80-e97ee02ac4e2", name: "West Virginia State University Bookstore" },
    { id: "e20a7c62-d915-4b8e-b22d-c68225585a66", name: "West Virginia Tech University" },
    { id: "9cb31207-fe09-492f-95b9-22b4bf1554ce", name: "West Virginia University" },
    { id: "cc5610c9-b4e7-4f53-9788-14ff5dd78395", name: "West Virginia University At Parkersburg" },
    { id: "dd675f7a-09d8-40eb-99fa-ea90111425c6", name: "West Virginia Wesleyan College" },
    { id: "ff6d7f4f-54f4-4e24-85f6-496072f0e8df", name: "Westchester Community College" },
    { id: "bc5d94bd-677b-4785-9fb5-f8c8ebbec6ba", name: "Westchester University of Pennsylvania - Slippery Rock" },
    { id: "7c1c5350-36b8-4a9b-9958-0823eb53b02a", name: "Westcliff University" },
    { id: "3548a62a-de22-4f3b-b9eb-4b0c98dbb528", name: "Westeren NE College" },
    { id: "fe92c6ba-8d8e-42b4-ac5c-77797ace115e", name: "Western Carolina University" },
    { id: "ea058ca9-d804-4ba5-a26d-bab0f49f27a3", name: "Western Conn State University" },
    { id: "650b037b-9286-462e-b33c-1ebdc2231a65", name: "Western Connecticut State University" },
    { id: "6c785dd2-e6f9-49cd-a3af-07e6573c1330", name: "Western Governors University" },
    { id: "a97c121c-dc8e-4263-a1af-ff8db7cca50d", name: "Western Illinois University" },
    { id: "048db8fe-8ab1-47c8-9d6b-b6f751a93e40", name: "Western International School of Shanghai" },
    { id: "c29297ea-d54d-41a8-926f-2c77596d6b8e", name: "Western International University" },
    { id: "eabc89d6-2526-43d6-b646-2e1ae284378f", name: "Western Kentucky University" },
    { id: "c4206133-0110-47f5-a462-7ed28788e6a5", name: "Western Michigan University" },
    { id: "e4b0d529-e78d-4dc4-8d28-bde911903098", name: "Western Mindanao State University" },
    { id: "2f196175-8971-42c1-bce4-770d90129dc1", name: "Western Nebraska Community College" },
    { id: "8921d67d-4492-4f7e-994d-025af09696bb", name: "Western Nevada College" },
    { id: "d6634ad4-3bd0-4856-b4c5-70bda6109177", name: "Western New Mexico University" },
    { id: "1fae7250-6262-4b6a-a4e9-97ee94e7d878", name: "Western Oregon University" },
    { id: "37680eb5-3c48-4af1-9acb-00b877643a33", name: "Western Oregon University Bookstore" },
    { id: "c5c651e7-bc5a-4922-b39a-67265c96b022", name: "Western Shores Institute" },
    { id: "edb4a939-c387-4380-94aa-8e2f7a596e62", name: "Western State College" },
    { id: "14e9610c-51c5-418d-86f1-aed3c08e96a6", name: "Western State Colorado University" },
    { id: "32ea4765-3e56-4347-aebe-d78ceb516ff3", name: "Western Sydney University" },
    { id: "2a30fd2d-7550-406e-bcc3-da652e95b9c5", name: "Western Technical College" },
    { id: "90a036f5-fe48-410c-858a-ee5c6e1da9ec", name: "Western Theological Seminary" },
    { id: "216e25cc-fcc0-4692-890c-a0d8a6a3437e", name: "Western University" },
    { id: "4ac3cf66-7cfa-4282-8367-ce0717b19593", name: "Western University of Health Sciences" },
    { id: "29d1ed74-0853-4bd8-967a-961ab38fd6bc", name: "Western Washington University" },
    { id: "d3fa3430-2be9-432d-944b-1db53dc45b15", name: "Western Wyoming Community College" },
    { id: "eedd412b-06c9-4b1a-935c-b1fe188e3ed8", name: "Westfield State University" },
    { id: "34500bc3-a472-479c-8cfa-4e8635f629e5", name: "Westford School of Management" },
    { id: "b1c0f1de-c473-4775-b367-5c5a3b158d45", name: "Westford University College" },
    { id: "fd591443-0d52-4edc-8b44-a2e53aa7cbc1", name: "Westfälische Hochschule" },
    { id: "134e122f-5ad6-4142-a38e-4ce01392d5a9", name: "Westfälische Hochschule Gelsenkirchen Bocholt Recklinghausen University of Applied Sciences" },
    { id: "20ba2d97-e192-4b38-a8fb-48c393c95cb8", name: "Westminster College" },
    { id: "317b4dff-1239-4aa3-b242-08017bb99eee", name: "Westminster International University Tashkent" },
    { id: "c49d58e1-717e-4aa0-bbca-354528172e4a", name: "Westminster Kingsway College" },
    { id: "4d755224-b574-4e1c-b4d5-821de1e9149d", name: "Westminster School" },
    { id: "49862717-7169-4735-be04-0a639bb9cafe", name: "Westmont College" },
    { id: "b27fe075-b9e5-4069-aa21-2f806e87c05f", name: "Westmoreland County Community College" },
    { id: "97adb0e7-1b52-4fdf-9782-33ac7068212a", name: "Westmount College" },
    { id: "38d63d59-c9fb-43d7-8949-b0d3697ea985", name: "Weston Collegiate Institute" },
    { id: "9178fc8e-2607-40f9-a662-8b26cddd254e", name: "Weston Distance Learning" },
    { id: "bc2959e2-c808-407f-bb87-6165b6c3951c", name: "Westside Community Schools" },
    { id: "60070d77-3eae-4f11-8197-66389078b00b", name: "Westwood College" },
    { id: "47a57b25-fe80-4ae6-b956-3ed04d8ae342", name: "Westwood Online" },
    { id: "87ae3b49-8726-49aa-9dc6-32c96bb1fee3", name: "Wharton County Jr College" },
    { id: "90af4cf1-f9c2-43aa-88fd-5f27689b3a1c", name: "Wharton Executive Education" },
    { id: "2d14cc27-bfde-47af-9cec-2e7ef27e2cad", name: "Wheaton College" },
    { id: "f490211f-ddde-4f07-8ab3-c8f57147a7f3", name: "Wheaton College - Illinois" },
    { id: "62b9634f-a849-4684-aa24-4c7a8e396e78", name: "Wheeling University" },
    { id: "970d0576-2991-4f27-ac45-7aa0f911252e", name: "Wheelock College" },
    { id: "d0cbcff1-82f2-4ddd-a8b3-29454291c1c3", name: "Whittier College" },
    { id: "4d3fd0c5-e02b-4d34-ba1f-857725733203", name: "Whitworth University" },
    { id: "972906f3-6499-42ca-a86e-9d88f952a9d0", name: "WHU - Otto Beisheim School of Management" },
    { id: "2c9047e2-b492-455d-9b15-755f63f0a91a", name: "Wichita State University" },
    { id: "c3911fcc-2ceb-4c43-98de-70923de9ad48", name: "Widener University" },
    { id: "a4114253-4483-4f83-9aef-5a1aba698a3f", name: "Widener University - School of Law" },
    { id: "9a9b3568-ba9d-44de-aae1-6e98dff92e92", name: "Wigan & Leigh College" },
    { id: "028b706c-8c03-4529-b02c-f17c16e26319", name: "Wiisdom International School for Higher Education Studies" },
    { id: "aacf8284-00c4-4ffc-93fd-dfa1bc2cc7c6", name: "Wilberforce International School" },
    { id: "8a259ca1-935c-4952-aa54-09c1ebb81827", name: "Wilfrid Laurier University" },
    { id: "ce56eac1-1870-4f4c-ab65-c24886df76b0", name: "Wilkes University" },
    { id: "41540fe3-b32b-4ccb-93c1-c31e37ca197a", name: "Willamette University" },
    { id: "3fe6e038-b3c7-4a49-b15a-7e45e8a16275", name: "William Angliss Institute" },
    { id: "a274403d-bf80-487d-9140-4f495673e1b9", name: "William Howard Taft University" },
    { id: "a615f376-ca4d-4af1-b0b3-7357e9265468", name: "William James College" },
    { id: "837437bf-d30e-45a0-9185-42dd2ed972a3", name: "William Jessup University" },
    { id: "2b6c8da6-f41b-4bae-a0be-a5b401b554ef", name: "William Jewell College" },
    { id: "f911d487-3999-4eee-a6d5-dd599c911ac0", name: "William Mitchell College of Law" },
    { id: "7efbef32-fa64-4c06-9660-200f8a66b9e4", name: "William Paterson University" },
    { id: "3543b755-7780-484c-893d-b44a348bb26d", name: "William Peace University" },
    { id: "99ce0fcf-a4b6-4ad2-96db-5f0f8a224b11", name: "William Penn University" },
    { id: "6bae7107-6ce7-4099-8160-a88e08ab7058", name: "William Rainey Harper College" },
    { id: "6abaebf3-c9f7-41c4-8304-a6db8282fdd1", name: "William Woods Univ" },
    { id: "b2721aa3-af8d-4bb0-932f-6d6816ad0a6b", name: "William Woods University" },
    { id: "a4f7be9d-7d69-41ed-af2a-7b9f4d0f6451", name: "Williams College" },
    { id: "38ec1b94-094c-4577-8329-505172643842", name: "Williamson Christian College" },
    { id: "3f436838-39fa-4b26-ac43-9f193331a137", name: "Williston Northampton School" },
    { id: "b44dc354-7bfc-4f47-b2c9-cb4d8c03b518", name: "Willkimmen in Ulm" },
    { id: "cfd5d8fb-eb96-4d7e-a8d3-73ec20ea5efe", name: "Willow International Community College" },
    { id: "c3d34eed-5ec1-4262-aa9e-00d9286c6d31", name: "Wilmington College" },
    { id: "5793d6f2-6201-4829-b3cd-1a64e1e6525e", name: "Wilmington College of Ohio" },
    { id: "9c52ae07-03a8-4ddb-9d14-fad437d57dc2", name: "Wilmington University" },
    { id: "2cacbe51-4d60-4a89-9a79-80dd7126694b", name: "Wilson College" },
    { id: "6d9268e5-7035-4051-a095-e10757fe9898", name: "Wilson School District" },
    { id: "a0de90f5-2ffa-43bd-b83b-d8e868b63390", name: "Windesheim" },
    { id: "c62469bc-ab38-4a89-a86d-bebb7dbb2fbb", name: "Wingate University" },
    { id: "fb674215-ee67-4eca-915f-60b35993f98d", name: "Winona State University" },
    { id: "87e8cc14-1cb3-4091-a50c-4031ee39240b", name: "Winston Salem State University" },
    { id: "b929eeb3-4a2f-4c75-8771-410d70dedb5e", name: "Winston-Salem Bible College" },
    { id: "aeccd283-7304-4121-83aa-025f591bcc6c", name: "Winthrop University" },
    { id: "c88fc2de-fd90-47b9-a24f-c1b7b08fd157", name: "Wirtschafts-Wissenschaften" },
    { id: "13319473-6f99-46fb-b28b-5a9d9915dbad", name: "Wirtschaftsakademie Schleswig-Holstein" },
    { id: "bbfd9f7f-46de-40f4-8a1b-65188b998dc0", name: "Wirtschaftswissenschaftliche Fakultät" },
    { id: "b1ca5ec2-13a1-46a8-851e-cd170fceef78", name: "Wisconsin International University College" },
    { id: "b2b119c0-bba6-4d2f-a95c-7e37069e230e", name: "Wisconsin Lutheran College" },
    { id: "2ec89ff8-63bf-4612-9690-dfba3463e800", name: "Wisdom University" },
    { id: "3732adf1-656d-4b15-a9a1-832f2cbae8e8", name: "Witten/Herdecke University" },
    { id: "2f71b484-70e1-4890-8343-9e5fad12f785", name: "Wittenberg University" },
    { id: "83a4a75a-223d-482e-bbf0-fb26f67e8fa7", name: "Wittenberg University Library" },
    { id: "50c41a68-9139-4f73-8012-66ebe47319ad", name: "Wittenborg University of Applied Sciences" },
    { id: "1fab4f99-e20e-4623-8e55-cf88238a7f93", name: "Wofford College" },
    { id: "514d90fe-3cb9-4c50-bc2c-0da2371e8e53", name: "Woodbury University" },
    { id: "326b721c-66b0-432c-8ea6-fe42fe3b449b", name: "Woosong University" },
    { id: "5ec77b04-9cd1-4ea8-996c-2e8d886802f3", name: "Wor-Wic Community College" },
    { id: "4ed6fa8c-baa7-4a53-976a-71ddfecd7639", name: "Worcester Academy" },
    { id: "f1fc35f4-e741-4ecd-ba16-428d84221e42", name: "Worcester Polytechnic Institute" },
    { id: "4e38c634-990b-4460-a662-7e06a1c5873b", name: "Worcester State University" },
    { id: "ec46c7da-5805-4337-b04e-db4c6e42838f", name: "Words of Wisdom LLC" },
    { id: "7960f697-a4fa-46d6-9e25-5351160cadc8", name: "World Academy of Sport" },
    { id: "229d887b-5557-498c-9142-8a4e9b1e18e8", name: "World Quant University" },
    { id: "11961d60-77f8-427c-992e-f68127b0da0e", name: "World Trade Center University" },
    { id: "95b6d550-c9c8-476c-8b90-8c1d72101abc", name: "World University Of Design" },
    { id: "8a8b7fc9-d1cc-4464-b398-a92fbb1662d3", name: "Worldquant University" },
    { id: "166b6395-f22c-41a1-824b-5b78741af1cc", name: "Worlds-best-mentor" },
    { id: "a7e89df3-f5dd-4d25-88fb-e4983f1d09d4", name: "Worms University of Applied Sciences" },
    { id: "3b2a4bb1-b29a-45bd-928a-6eef9e2bd88a", name: "Woxsen School of Business, Hyderabad" },
    { id: "22d85d22-5ce9-4338-b47d-b84f98c39f21", name: "Wright State University" },
    { id: "4b46eef0-9047-4279-9fb4-860c08c83763", name: "Wright University" },
    { id: "a1969a9a-3139-4b81-871f-d5137d7795de", name: "Writtle College" },
    { id: "5bd34f45-cd18-44db-9467-3a9621fb8218", name: "Wroclaw School of Banking" },
    { id: "982574bc-b00f-4e9d-8fd9-66509349e5b7", name: "Wroclaw University of Economics" },
    { id: "210ce1c4-7fb8-4cb6-ad6d-8007e5c4c162", name: "WU Vienna" },
    { id: "d57b8c0a-a3b7-408c-9659-45e3e217be48", name: "WU Vienna University of Economics and Business" },
    { id: "4a0b1f6d-4b99-423e-8e67-a723c3d13ebb", name: "Wy?sza Hanzeatycka Szko?a Zarz?dzania" },
    { id: "d775247c-40da-46a7-ad8b-15d4d73c4a25", name: "Wy?sza Szko?a Administracji Publicznej" },
    { id: "19241adb-3526-4479-a1a5-d982c7ed0263", name: "Wy?sza Szko?a Biznesu - National-Louis University" },
    { id: "dd541c2b-acad-4a75-8d63-d5d39209cfe9", name: "Wy?sza Szko?a Gospodarki Euroregionalnej im. Alcide De Gasperi w Józefowie" },
    { id: "a045890d-736c-4549-9a3a-e046825920b7", name: "Wy?sza Szko?a Nauk Humanistycznych i Dziennikarstwa (WSHID)" },
    { id: "ef8403aa-5448-4b23-b383-40e92bb21b07", name: "Wyzsza Szkola Biznesu - National-Louis University" },
    { id: "e2297896-a8de-493d-9acc-d3845876ea87", name: "X" },
    { id: "5d0512ac-f03e-4bb1-95bb-82df3a338644", name: "Xavier Institute of Development Action and Studies XIDAS" },
    { id: "c18a3e96-7bce-475e-af16-44dfb0997a94", name: "Xavier Institute of Management & Entrepreneurship (XIME)" },
    { id: "27571fcb-79bc-43a0-9fdb-c7cdfab9c320", name: "Xavier Institute of Management (XIMB)" },
    { id: "e9f49f71-2e2c-4ce7-b548-087ba730312f", name: "Xavier Institute of Management and Research (XIMR)" },
    { id: "e24e9fb8-e449-4935-8a43-50741a5045aa", name: "Xavier Institute of Management, Jabalpur (XIMJ)" },
    { id: "753492e3-72d0-4c4f-b732-f0b90a7e2352", name: "Xavier Labour Relations Institute (XLRI)" },
    { id: "d632159a-7cc4-4540-bd49-3a1329e1b8e5", name: "Xavier University" },
    { id: "6b9490fb-f150-45ea-9cec-da38ddabf229", name: "Xavier University - Ateneo de Cagayan" },
    { id: "543bc4d4-60df-4d5c-bebe-5949510dc843", name: "Xavier University of Louisiana" },
    { id: "a54c3699-99a0-4dc6-91f5-abf17c60fe55", name: "Xcellon Education Limited" },
    { id: "94ef66b7-e361-4cea-bad6-f6060b0977d6", name: "XEIM Limited" },
    { id: "8844661b-5398-449f-a589-14d169726f43", name: "Xi'an Jiaotong University" },
    { id: "346aa1a9-8413-42dc-8a71-a8377e8a24e9", name: "Xi'an Shiyou University" },
    { id: "a3f35f6a-11d8-484a-9d7f-b5ac721eb6a3", name: "Xi'an Technological University" },
    { id: "6748f903-857b-4488-a448-bb24d8cb2495", name: "Xi'an University of Posts and Telecommunications" },
    { id: "efaad4df-753b-44d0-9308-d74175469e5f", name: "Xiamen University" },
    { id: "0b1983eb-c472-4145-bed2-0f6c73974f75", name: "Xiamen University of Technology" },
    { id: "e815cccc-c634-4c76-9b0c-d11a19cee350", name: "Xian Jiaotong-Liverpool University (XJTLU)" },
    { id: "0cb0bfcf-d057-414f-b202-b92cc298a831", name: "XIME - Chennai" },
    { id: "2959db47-742a-417e-925c-fab901f3d811", name: "Yale University" },
    { id: "fa3d608a-a6bc-4f74-bef8-79c34951eb45", name: "Yamagata University" },
    { id: "e5e49b2c-73b5-4678-80bf-85c5b60492aa", name: "Yamaguchi University" },
    { id: "0ce3642f-94a5-463d-a4d8-7f046617ae46", name: "Yamanashi Gakuin University" },
    { id: "4a15e5b0-476e-439f-8adf-6a518275fcf3", name: "Yanbu Industrial College" },
    { id: "9995d551-bb2a-4094-9596-8555c42bc2b6", name: "Yanshan University" },
    { id: "633ad390-228d-415c-9045-ab1f10c3af1a", name: "Yarmouk University" },
    { id: "da68deb3-38ee-44b0-926e-108460a07f0d", name: "Yasar University" },
    { id: "7a7c972a-d4a9-45b0-ad8b-36dd234ceb12", name: "Yeditepe University" },
    { id: "47d3d84e-4b6b-48b4-ae1f-d5cb08b9dc7f", name: "Yenching Academy, Peking University" },
    { id: "5cfa804f-67db-40fd-906f-9978f95a058b", name: "Yeshiva University" },
    { id: "9740d499-eb02-46fa-ad8b-96bfecfde2e5", name: "Yildiz Technical University" },
    { id: "eb865347-0974-4666-931b-0bb1edbfab9c", name: "Yo San University" },
    { id: "f91b5a6d-e562-47c2-85e3-60d00496ef5c", name: "Yokohama National University" },
    { id: "688b3bca-e600-495a-ae5f-ccebb477b626", name: "Yonsei University" },
    { id: "4f1fdb23-2d89-4125-b139-38a22459f0f8", name: "York College (CUNY)" },
    { id: "49049d16-969c-420b-a3a5-55789c7305e3", name: "York College (UK)" },
    { id: "c2eaa1a1-0d9c-4db6-a323-ac151c44da93", name: "York College - Nebraska" },
    { id: "e8f10c5a-264e-4956-a8b4-9e95a0c6e048", name: "York College of Pennsylvania" },
    { id: "93a7c917-6555-4845-bfb2-5644ee9984e4", name: "York County Community College" },
    { id: "3e7cff56-082c-47df-adba-d8f422667c31", name: "York Region District School Board" },
    { id: "a6d6b687-cf3e-407d-9c02-61c7fd796b23", name: "York School" },
    { id: "ea0dc340-b039-47d4-8448-10afc1b22d9c", name: "York St. John University" },
    { id: "55bf5271-830f-49bd-af49-4a2dc1d31622", name: "York Technical College" },
    { id: "c05fbbac-3dc1-4f8e-bb3f-42890aab67a0", name: "York Technical Institute" },
    { id: "1d4faea3-8871-4a71-af9d-2595b0c39f40", name: "York University" },
    { id: "3a3464ab-961e-4f31-a913-e27bd96bba23", name: "Yorkville University" },
    { id: "bc4bdb55-53c7-4b0f-8df1-8cdd335f6fcd", name: "Youngstown State University" },
    { id: "dd9737b2-731f-4c28-9b87-b2218d3efe0c", name: "Your company" },
    { id: "997adad1-afd4-4139-8009-ab1402df0673", name: "Youth Opportunity Academy" },
    { id: "1e75e5ac-32f4-45e2-983d-65400c27f2cd", name: "Yuan Ze University" },
    { id: "8a7d96ff-9b87-4c72-af8c-c8f1dd24e947", name: "Yuanpei University" },
    { id: "719ebad1-062f-4439-8475-9623086bd56d", name: "Yuba College" },
    { id: "8b9dbeee-3306-42d8-8240-f05220e51f32", name: "Yue Yang Publications Co. Ltd." },
    { id: "6c95816f-04d6-49fa-9c82-f3ea187ea77d", name: "Yun Lin Techn Coll Bin" },
    { id: "ab3677b8-02ab-4727-8e09-dce3397692fe", name: "Yunnan Normal University" },
    { id: "faf60fc0-26af-4f8f-9c84-1531460fb1e6", name: "Yunnan University" },
    { id: "fda7143c-c7b7-4f2f-b640-77dd61825af1", name: "Yunnan University of Finance & Economics" },
    { id: "1a4a4db1-a939-4d0e-a5d4-ceb1c36d5ca2", name: "Yunnan University of Finance and Economics" },
    { id: "7380339a-cb0b-49d2-9107-1a5e3d16abb8", name: "Zagazig University" },
    { id: "2ce101dc-9e79-4846-9bbc-c35af01f0f94", name: "Zagreb School of Economics and Management" },
    { id: "9638c4a4-a643-4034-9617-1efa5450486f", name: "Zambia Centre for Accountancy Studies" },
    { id: "bc875976-d519-4c35-bafd-749e48fba69a", name: "Zamorano University" },
    { id: "cbcb3e30-844a-41fe-a56d-4f216a326eb0", name: "Zayed University" },
    { id: "7df6fbe9-0d97-48b0-97f0-e047e780d6fb", name: "ZBC - Sjaelland Handelshojskole" },
    { id: "a6e69577-ec59-4f45-af0a-4371cb891de7", name: "Zealand Institute of Business and Technology" },
    { id: "97b97343-5ff4-489f-91bb-e5c4195cd077", name: "ZEBS - Zurich Elite Business School" },
    { id: "6eea6a55-3cfa-4e79-81fb-6b91bd1662c6", name: "Zenith University College" },
    { id: "b6efc853-f8cd-4941-96ed-b725734bf14a", name: "Zeppelin University" },
    { id: "21485d23-c3c7-4dd3-8116-4ba3855ae095", name: "Zhejiang Gongshang University" },
    { id: "7274f5f7-e6fd-4cef-b8d7-5f350c4e2030", name: "Zhejiang Sci-Tech University" },
    { id: "a497cf90-a599-4c89-88b6-477a9af5b895", name: "Zhejiang University" },
    { id: "2b5c7f3d-face-4bc2-b8a2-e60f95cda24a", name: "Zhejiang University City College" },
    { id: "fe330766-96ba-4f8a-b7ea-be824e56459e", name: "Zhejiang University of Finance and Economics" },
    { id: "7a9abaf6-3731-47c5-816f-2a82ead43c41", name: "Zhejiang University of Technology" },
    { id: "322384f8-087e-44af-b2f2-7f6b158b8b1f", name: "Zhengzhou University" },
    { id: "d94bbec9-9c63-48ca-9cb6-167a985ed05c", name: "Zhongnan University of Economics and Law" },
    { id: "97e23a73-4b53-42dc-90e7-4d5ed3bef496", name: "Zhongshan University" },
    { id: "9ce67a76-7459-4902-be04-64b55ce2b062", name: "Zigurat Global Institute of Technology" },
    { id: "77a67260-ee97-473e-8637-4a4c23a5b47b", name: "Zionsville Community Schools Inc" },
    { id: "30d5a385-6582-41a7-89b7-d5f5e3ba9ca9", name: "Zirve Üniversitesi" },
    { id: "263a4168-75e6-43d7-97d1-d95fa3048099", name: "Zurcher Hochschule fur angewandte Wissenschaften (ZHAW)" },
    { id: "91ddc5a2-e2f3-487f-a9f8-dcb042e66226", name: "Zurich University of Applied Science" },
    { id: "969cdd1a-aa2f-4bb1-b7e8-219c3d221998", name: "Zuyd University of Applied Sciences" },
    { id: "caede3b9-42f0-4182-9b41-ea3cbc87baf3", name: "ZWW-Zentrum fÃ¼r Weiterbildung und Wissenstransfer" },
    { id: "27ab85a6-3645-4a48-90a8-28b385b7aeed", name: "Ágora Portals International School" },
    { id: "edc8e181-05ef-4f51-b8a0-6a8224b018f8", name: "Çankaya University" },
    { id: "a6ee6ad8-126d-4d5d-8ced-8ca67f581d24", name: "École Active Bilingue Jeannine Manuel" },
    { id: "bf367481-0337-4ddc-be7d-cfc658cd7c46", name: "École Polytechnique de Montréal" },
    { id: "c1af61d7-1ecc-48cb-9c3b-709ad28a1fb5", name: "Óbuda University" },
    { id: "4970b96b-67f4-4557-bcc4-aa4dc2aee9c1", name: "Üsküdar Üniversitesi" },


    { id: "4a977051-e61c-4e01-a7dd-8ee08b10377f", name: "Hamedan University of Technology" },
    { id: "64c2e953-984c-4854-864f-41a226b1bf9b", name: "Hekmat Private University" },
    { id: "2ffc4184-75a7-4d43-92f6-c795614823b7", name: "Abadan University of Medical Sciences" },
    { id: "23c99d97-9a4e-4bc6-bfe1-569b706b91e6", name: "Alzahra University" },
    { id: "fbd8a432-e413-4801-aa5b-b13048e28898", name: "Amirkabir University of Technology (Tehran Polytechnic)" },
    { id: "8b1cb907-f383-45a3-8b02-10e78186042e", name: "Ahvaz Jundishapur University of Medical Sciences" },
    { id: "edf9c473-3f4e-41be-aa4b-7e03c4f9e715", name: "Comprehensive University of Technology Tehran" },
    { id: "4d3e9cd9-65be-4d41-bc25-4cd1d43dc4b0", name: "Damghan University" },
    { id: "3c7b6dc3-11af-413c-b37f-c855ada259ec", name: "Hatef Higher Education Institute Zahedan" },
    { id: "cf69baa6-ad94-4828-bd39-d003b3e44033", name: "Faran Virtual University Tehran" },
    { id: "b0c095e0-539d-4ef5-abe0-4a2eb48e969d", name: "Parsian Virtual University Tehran" },
    { id: "77df85f9-9f3e-4f61-b031-4a67a70ac9c3", name: "Lamei Gorgani Institute of Higher Education" },
    { id: "b45bf88d-cdb7-4aed-b1e9-b9fab215990b", name: "Farhangian University" },
    { id: "ef72c8b1-0665-4eb9-afd6-cde949e15a03", name: "Fasa University" },
    { id: "d568def1-e011-4dca-bd86-0a033c60ac62", name: "Fasa University of Medical Sciences" },
    { id: "11ce6eac-c11e-419c-8ecb-24cba518a764", name: "Fatemiye University of Medical Sciences Qom" },
    { id: "7ef17eae-f8a5-4e89-9192-1f2cb58c58a9", name: "Ferdowsi University of Mashhad" },
    { id: "394d9220-4028-43d2-91c1-d61bba1800bf", name: "Mohajer Technical University of Isfahan" },
    { id: "ed32a4e2-96d0-4c85-82b1-7a31b931b6a8", name: "Garmsar University" },
    { id: "81b66721-8454-426d-a142-f09dffa20115", name: "Golestan University" },
    { id: "299ef1ca-fa44-4dfc-8e94-b5df5b70953e", name: "Golestan University of Medical Sciences" },
    { id: "eea29f90-6cdb-4a60-9a24-1abaea1da770", name: "Golpayegan University of Engineering" },
    { id: "690af38a-25cd-42b8-940d-597a2577bd4e", name: "Gonabad University of Medical Sciences" },
    { id: "e6da3f85-2a33-4b62-bb60-faeca3c9d3f5", name: "Tabari Institute of Higher Education" },
    { id: "a474b633-16e8-4733-894a-36afa724a86f", name: "Gonbad Kavous University" },
    { id: "26149c8a-d38b-4c4a-a426-4458f5d91bd5", name: "Gorgan University of Agricultural Sciences and Natural Resources" },
    { id: "f11854f0-6645-4f42-92de-fa5f08c68118", name: "Gorgan University of Medical Sciences" },
    { id: "37664cc0-3694-4b78-ba5f-c62d8550cde7", name: "Gilan University of Medical Sciences" },
    { id: "9149a21e-977f-4530-85a4-991e0f0af8a7", name: "Hadith Science College" },
    { id: "d5e3d2d4-f0f8-44e4-b1b2-971ef724c720", name: "Hamedan University of Medical Sciences" },
    { id: "1a554140-9f73-48ce-94ce-313f4108f854", name: "Hormozgan University" },
    { id: "d31b2e66-4903-4b11-a56d-e971bd112ef2", name: "Hormozgan University of Medical Sciences" },
    { id: "c39d70c8-4302-461a-bdc7-cb58780359d1", name: "Ilam University" },
    { id: "7e0a19fb-5404-4cac-826f-4b8a2c3767ce", name: "Information and Communication Technology Faculty Tehran" },
    { id: "b361e224-8032-48d8-87e3-aeeb1f9ca18e", name: "Institute for Advanced Studies in Basic Sciences (IASBS) Zanjan" },
    { id: "24c82f18-634d-44bd-a8c7-0d2a1b878012", name: "Institute for Higher Education ACECR Khouzestan" },
    { id: "84070224-97d3-42d4-8289-f2da4a43f597", name: "Institute for Studies in Theoretical Physics and Mathematics (IPM)" },
    { id: "14fee968-72f4-49f9-b875-79e35282d68f", name: "International Institute of Earthquake Engineering and Seismology" },
    { id: "95b227fb-48d4-429c-b2a6-abf737c02d0f", name: "International University of Chabahar" },
    { id: "0f5bbdbb-029f-4d40-8be4-70b152f38d96", name: "International University of Iran" },
    { id: "134ef016-0cba-459f-9030-45dead485475", name: "Iran Informatic Institute (U.A.S.T.)" },
    { id: "3aa6e3e9-a1c6-4dcf-9bd1-fa06dcbfe23d", name: "Iran Polymer and Petrochemical Institute" },
    { id: "ebb321c2-c96a-469b-ad63-40f14beb65a1", name: "Iran University of Medical Sciences" },
    { id: "c8efd2db-48da-42a3-8fc7-eb9a273338e7", name: "Iran University of Science and Technology (IUST)" },
    { id: "c448f1e9-d716-4757-88f0-f55938561229", name: "University of Science and Culture" },
    { id: "8ebc05b4-7801-4cd5-a894-b25cedd08fb9", name: "Iraniyan Internet University Tehran" },
    { id: "c7e15235-6d2f-4785-a27b-00b6cc6a3fe8", name: "Isfahan University of Art" },
    { id: "5f6edb05-ff9d-4b75-a193-64406407f3cb", name: "Isfahan University of Medical Sciences" },
    { id: "3087f920-cd6d-4c8f-817e-af8a7a29b1f2", name: "Isfahan University of Technology" },
    { id: "2fcdca97-241d-4ae6-aee5-7274985d2942", name: "Azad University" },
    { id: "4e93ba4e-43d4-4a68-8dc6-40f2ee6a9386", name: "Azad University Abhar" },
    { id: "4ed28efe-b2be-4716-ae15-0957a9d42a4f", name: "Azad University Ahar Branch" },
    { id: "edc4f440-4bb4-4279-8546-da83e387608c", name: "Azad University Ahvaz Branch" },
    { id: "a5924f76-46c5-4a81-90ef-246c5c70fee2", name: "Azad University Aliabad Katul" },
    { id: "8f839e46-e87d-45b1-896b-12e5b36b66af", name: "Azad University Arak Branch" },
    { id: "38880ed1-f1d9-485f-87f2-b892cecdb5de", name: "Azad University Astara Branch" },
    { id: "d7887932-7326-421e-aee9-e12018e2cf1c", name: "Azad University Baft" },
    { id: "d353e9d6-fe6e-4c5c-bb2a-2900f863dc95", name: "Azad University Bandar Abbas" },
    { id: "065c8c33-32e8-4f06-9324-9a7c319620fd", name: "Azad University Bojnourd Branch" },
    { id: "38c0751e-4c1c-4101-bb29-0953e8aa268b", name: "Azad University Bonab" },
    { id: "9301fc2a-3a73-471f-a7c4-c59f2aaeaeb3", name: "Azad University Bushehr" },
    { id: "7e1a0c5b-b81b-418e-aaf7-880630a2de60", name: "Azad University Central Tehran Branch" },
    { id: "5fc62eff-c799-4ca0-a3ca-a74bbbec842a", name: "Azad University Damavand Branch" },
    { id: "836b5af0-7e53-4ec4-8204-cac44087c6ef", name: "Azad University Darab Branch" },
    { id: "3377febb-4be0-4954-ab9a-f06f983ee50d", name: "Azad University Dezful Branch" },
    { id: "5b8485cf-a488-4e08-a427-5a007e1a5138", name: "Azad University Eghlid Branch" },
    { id: "1960c163-f4cc-48a9-89ec-c507e5c4597f", name: "Azad University Estahban Branch" },
    { id: "95e6881c-5d48-4056-b50f-829c1e5eff1f", name: "Azad University Falavarjan Branch" },
    { id: "a489ec1b-5786-4e93-aa36-2c2eab079ffd", name: "Azad University Farahan Branch" },
    { id: "47f8e979-3db3-42c5-b9e0-2795b837236e", name: "Azad University Science and Research Branch Fars" },
    { id: "3b2a9de5-4ee4-4050-bc72-561628d06df4", name: "Azad University Ferdows Branch" },
    { id: "3b37aac0-78f3-4181-a162-10094d8fc402", name: "Azad University Firuzabad Branch" },
    { id: "e8dcc686-3a40-453e-a001-2705d68365b1", name: "Azad University Gachsaran Branch" },
    { id: "fb704632-cb66-4927-a8a8-18718c76b1e1", name: "Azad University Garmsar Branch" },
    { id: "7e46e1d0-4380-4a7c-86c3-cc4d3575b3ac", name: "Azad University Gonabad Branch" },
    { id: "1d848365-fdd9-4401-b2b4-0787a4463749", name: "Azad University Hamedan Branch" },
    { id: "da4da0ea-74d3-4d6a-ba2d-776379e40fbb", name: "Azad University Islamshahr Branch" },
    { id: "105da26f-9c07-4a2f-a281-2b736677957f", name: "Azad University Karaj Branch" },
    { id: "eb81734a-3218-479e-9855-a777e26783a0", name: "Azad University Kazeroon Branch" },
    { id: "b054be63-1336-48a0-adde-1d4212d049cd", name: "Azad University Kerman Branch" },
    { id: "7388ad8b-e7e8-426b-abaf-864934eae708", name: "Azad University Kermanshah Branch" },
    { id: "3fb219b4-3e61-40a4-a227-48849aa74c7d", name: "Azad University Khomain Branch" },
    { id: "2a2a7dcd-1548-4959-8a9c-3d51a7df580a", name: "Azad University Khomeyni Shahr Branch" },
    { id: "f76e67b0-fcfc-4019-9cd3-cc5759c41902", name: "Azad University Isfahan Khorasgan Branch" },
    { id: "19e6c8e6-7d68-4ed1-b65e-abc56fb55959", name: "Azad University Khoy Branch" },
    { id: "2783a1b4-a0db-4c60-add8-64a051caa2d6", name: "Azad University Larestan Branch" },
    { id: "6ca8587d-0bfe-41c7-a0c1-349863fde071", name: "Azad University Mahabad Branch" },
    { id: "41d1d51e-876d-4749-bc8c-12be91da635f", name: "Azad University Majlesi Branch" },
    { id: "a88d0677-0f46-4523-a516-152260c18b54", name: "Azad University Maku Branch" },
    { id: "6c9892a8-c884-40da-9017-0c847f9e5d1e", name: "Azad University Maragheh Branch" },
    { id: "942c6da1-ab55-4b6c-9ef6-3544b8394bb3", name: "Azad University Marvdasht Branch" },
    { id: "4067bb25-9b88-44d5-a667-db57bdb1d5a2", name: "Azad University Maybod Branch" },
    { id: "725de53f-a000-4f2b-bacc-4ff098f37638", name: "Azad University Miyaneh Branch" },
    { id: "d9f49daf-95f7-4f3c-a0d1-d43435d3029f", name: "Azad University Naein Branch" },
    { id: "26de7973-95c1-488f-8f4c-a9bc7e1c8676", name: "Azad University Nishabur Branch" },
    { id: "7865d917-a436-4f3f-8fd0-eb962c5fc2ad", name: "Azad University Noor Branch" },
    { id: "a9ea7da3-0dc2-4904-991f-19fd00197d06", name: "Azad University Gorgan Branch" },
    { id: "9d4ce669-f4a7-4ade-bf08-b234c4dcd607", name: "Azad University Mashhad Branch" },
    { id: "96c566be-ca08-4ed5-8f71-c32a6a1c6829", name: "Azad University Masjed Soleyman Branch" },
    { id: "48790968-f190-450c-8d65-d8f127ed6c29", name: "Azad University Najafabad Branch" },
    { id: "46f66503-2fac-45e5-9160-36aa733ae2d9", name: "Azad University Neyriz Branch" },
    { id: "ebcddf14-73cc-474e-8eb8-989407b3f345", name: "Azad University Shahinshahr Branch" },
    { id: "53764982-5c9b-4b2b-86aa-90f968c71dd0", name: "Azad University Parand Branch" },
    { id: "df5ee90b-0704-4388-8125-4186cc8e8e0e", name: "Azad University Qazvin" },
    { id: "12ac776a-5aef-4cf9-b399-c8c15e92c05e", name: "Azad University Shahr e Qods Branch" },
    { id: "23124027-ea04-40c0-9786-cd52d34169ff", name: "Azad University Qom Branch" },
    { id: "2fa0c287-f4fa-43d7-b3dc-a08ba02a0b4f", name: "Azad University Quchan Branch" },
    { id: "3f8c9624-3601-44de-a43d-68e0792bb52f", name: "Azad University Region One" },
    { id: "ddb50c9f-2d53-4c4e-ac92-7263b6c6b902", name: "Azad University Roudehen Branch" },
    { id: "ce3a2e2a-8f25-438c-8f3e-7a801fadf442", name: "Azad University Sabzevar Branch" },
    { id: "c4c3a210-aacc-430f-9dd2-c70d5eb1625e", name: "Azad University Sanandaj Branch" },
    { id: "ead23971-8b29-4b4e-a81c-be60a4c9d6af", name: "Azad University Sari Branch" },
    { id: "c7ddbb6d-3b26-47e9-ac07-adbe89038f0f", name: "Azad University Saveh Branch" },
    { id: "61aa4310-15e4-454e-8d82-317a376ee295", name: "Azad University Science and Research Branch Tehran" },
    { id: "6a7dcf1f-963a-4388-8aa4-85b7ef9eeefd", name: "Azad University West Tehran Branch" },
    { id: "41116197-5a49-4a22-a02e-b1789333a9c2", name: "Azad University Sepidan Branch" },
    { id: "cefb9a7a-2b19-4791-88ae-112b9e5b4174", name: "Azad University Shabestar Branch" },
    { id: "6c899ac4-5cbd-43ce-ae76-06df4edbf76c", name: "Azad University Shahr e Rey Branch" },
    { id: "6fdbfb0f-e8ad-4594-be10-33edc78d319a", name: "Azad University Shahrekord Branch" },
    { id: "1d2cc99d-59cb-4a10-82cb-7d72b29334a9", name: "Azad University Shahreza Branch" },
    { id: "5d82415a-d5fd-4426-8868-ab6fc17cd0fd", name: "Azad University Shahrood Branch" },
    { id: "e25c6d3a-eaeb-4e4d-9776-355f0810a76f", name: "Azad University Shiraz Branch" },
    { id: "3a3bfdda-92b8-4dca-bb31-167c44ad3470", name: "Azad University Shirvan Branch" },
    { id: "062e8302-a906-460a-b2cf-9dd04cd4b992", name: "Azad University Shoushtar Branch" },
    { id: "4a102eec-2d93-4c94-91d9-9508cec3e415", name: "Azad University Sirjan Branch" },
    { id: "658e722c-05ff-493a-b763-674ed9f83875", name: "Azad University Tabriz Branch" },
    { id: "5467b5cb-06d9-4303-ba2b-f265ae058beb", name: "Azad University Tafresh Branch" },
    { id: "a801f080-f426-4d9d-b8c4-0ee883cef0fb", name: "Azad University Takistan Branch" },
    { id: "4ee7f5e6-97e8-4477-ab0c-8cd316f3ece0", name: "Azad University East Tehran Branch" },
    { id: "71b3237a-a5c4-4219-a0af-708b69d70abf", name: "Azad University Tehran Medical Branch" },
    { id: "388e2a24-fa36-4a07-be9c-f68d66a4cf56", name: "Azad University North Tehran Branch" },
    { id: "7347d1c9-c502-4069-ad5a-63a2bf8e2ffd", name: "Azad University South Tehran Branch" },
    { id: "d6d66efc-fdc2-4f03-b327-1b3c32ea837a", name: "Azad University Urmia Branch" },
    { id: "6fc4977e-6722-47ee-aea1-61aecded3025", name: "Azad University Varamin Branch" },
    { id: "08dda8a0-6957-4f6f-82c8-9b65f7174ab9", name: "Azad University Yasuj Branch" },
    { id: "cd03e719-8e25-4d32-97fe-308eb633a2d8", name: "Azad University Yazd Branch" },
    { id: "00523d2a-4436-4b75-8670-cb82706c0952", name: "Azad University Zahedan Branch" },
    { id: "3e829f46-8ad5-4344-b736-14e858326859", name: "Azad University Zanjan Branch" },
    { id: "2fed2bde-9d2b-4fe8-a62c-87e9a1a1faa8", name: "Azad University Zarand Branch" },
    { id: "e84d9022-1baf-4154-abd5-688a77205c43", name: "Jahad Daneshgahi Academic Institute of Ahvaz" },
    { id: "cde0afe5-2cdb-41d8-928d-063262c89ce3", name: "Jahrom University of Medical Sciences" },
    { id: "2aadd262-9469-4c6f-8849-31254e7defc6", name: "Jami Institute of Technology Isfahan" },
    { id: "684e87b7-da97-4af4-b41b-b33792b9dd41", name: "Kar Higher Education Institute Qazvin" },
    { id: "a0460c60-114f-4f24-90c3-e3198cf162e3", name: "K.N.Toosi University of Technology" },
    { id: "ee65cf5b-9249-45e7-93fd-484fa76479d4", name: "Kashan University of Medical Sciences" },
    { id: "b3630268-c6d1-4e76-9b58-867915efb169", name: "Kerman Khaje - Nasir Higher Education Center (K.K.H.E.C.)" },
    { id: "9ae9c222-bee1-4ab3-a602-80e3b5ccb28c", name: "Kerman University of Medical Sciences" },
    { id: "5915f359-7f09-4302-9364-30ca8889a9c6", name: "Kermanshah University of Medical Sciences" },
    { id: "9221c935-19b2-4b80-beb6-85385f03205a", name: "Kermanshah University of Technology" },
    { id: "4ec309c4-6416-45d0-9922-19d1a9e4d3ac", name: "Kharazmi University" },
    { id: "4ca45c5d-83e6-4eb6-876e-bbd73bb84770", name: "Khayyam Institute of Higher Education Mashhad" },
    { id: "dc2fd5af-a7a3-401e-b62b-a114fce2066d", name: "Khorasan Institute of Higher Education Mashhad" },
    { id: "6428025b-a507-449c-a70b-08555946cb85", name: "Khorramshahr University of Nautical Sciences and Technologies" },
    { id: "b4884def-1026-4e44-bf57-cd1ed1d952f2", name: "Kish University (Kish Higher Education Institute)" },
    { id: "f3fe9a99-89e4-4382-9f6a-ec4a2e3b8c05", name: "Kordestan University of Medical Sciences Sanandaj" },
    { id: "d61b3d83-f6a0-495b-bb23-2518e667af2c", name: "Lorestan University Khorramabad" },
    { id: "34b837ec-d3dd-4c55-8bea-2edc233edec3", name: "Lorestan University of Medical Sciences Khorramabad" },
    { id: "00a9534a-1edd-4b35-8c97-e5940d4ddeca", name: "Mashhad University of Medical Sciences" },
    { id: "f0c48e81-7221-4a44-8bb9-cd7f6f5db079", name: "Mazandaran University of Medical Sciences Sari" },
    { id: "cd182c9a-7e74-4e4c-8550-de6af162dd50", name: "Mazandaran University of Science and Technology" },
    { id: "931d9177-b8cc-4746-a0b5-a1c4e6994873", name: "Medical University for the Republic of Iran's Army Tehran" },
    { id: "ce1dbbce-ae07-4c44-a1bb-ee5cbe9ba257", name: "Medical University of Ilam" },
    { id: "3d7b1da1-02a1-4371-8862-277aa107c0d3", name: "Meybod University" },
    { id: "67a78d5e-fd59-40d6-918e-ccd67e7c2f9d", name: "Mofid University" },
    { id: "a4c3dcae-b1dc-4ffd-b9c2-5b557cee0d55", name: "Mohaghegh Ardabili University" },
    { id: "3177b3ab-04a8-4428-8b8f-7c668d024df1", name: "Montazeri Technical College of Mashhad" },
    { id: "556e3a45-ecd7-4696-aedf-decb0b6a19f3", name: "NAJA University of Police Tehran" },
    { id: "6908ea49-67cf-4d99-81d4-d5f98066eaff", name: "Nooretouba Virtual University Tehran" },
    { id: "ea17e882-5c54-4708-9e73-370ce30e86c5", name: "Payame Noor University of Mashhad" },
    { id: "0fe082a2-2194-4792-81d2-d49401e92f7b", name: "Payame Noor University of Karaj" },
    { id: "920d9100-b78d-454c-936c-f1a7cd85c710", name: "Payame Noor University of Eshtehard" },
    { id: "049315ad-24c8-495f-b4ce-7d5e67837225", name: "Payame Noor University of Shiraz" },
    { id: "33829e79-c2d5-41ab-9257-427019a21bce", name: "Payame Noor University of Tabriz" },
    { id: "728b7005-afdd-4045-a68b-7ce45cbfa2e8", name: "Payame Noor University of Tehran" },
    { id: "d3faedc0-4389-4c67-bfc8-1efb49bcacf5", name: "Payame Noor University of Urmia" },
    { id: "3489ebdc-8613-49c0-ba3a-69c2256047a2", name: "Persian Gulf University" },
    { id: "2909a2b8-b331-4424-801f-be1c222f255f", name: "Petroleum University of Technology" },
    { id: "cc873522-afa3-4785-aa38-cf37c0c348cc", name: "Power and Water University of Technology Tehran" },
    { id: "3df4685f-8a46-4645-8010-9f8ae8a417d4", name: "Qazvin University of Medical Sciences" },
    { id: "6cb22068-5116-4e1a-8de7-6e89a5631622", name: "Qeshm Institute of Higher Education" },
    { id: "a5294e76-f802-4122-804f-9b5f98994d91", name: "Qom Boy's Technical College" },
    { id: "11ce110b-dd67-417f-a25a-736ac329b678", name: "Qom University of Medical Sciences" },
    { id: "28ab0eaf-3551-4ed7-a594-42463a8ae874", name: "Rafsanjan University of Medical Sciences" },
    { id: "335ca9d4-1f66-4d87-921a-740a3e572742", name: "Raja University Qazvin" },
    { id: "a71ebb88-50db-410e-9968-8ccacee9c0ec", name: "Razi University" },
    { id: "edb9a123-fd34-4530-995d-614bc6d00f91", name: "The Research Institute of Hawzeh va Daneshgah" },
    { id: "8749ed89-f13d-4cca-b9e8-09c8913d1504", name: "Sabzevar School of Medical Sciences" },
    { id: "2543fb82-2614-4dda-baf7-3cd50eab90af", name: "Sadjad Institute of Higher Education Mashhad" },
    { id: "2f0d14b3-3dcd-4465-a726-e93a4aae35f0", name: "Safir Danesh University of Ilam" },
    { id: "723e34a8-73a8-4764-be76-5f4659d5b657", name: "Sari Agricultural Sciences and Natural Resources University" },
    { id: "804ae3df-e9ab-4d75-b13a-938fc84ce474", name: "Sahand University of Technology" },
    { id: "9648a5cd-60a0-493d-b2e0-5c0e6f2cc025", name: "School of International Relations (SIR) Tehran" },
    { id: "30b96657-e44b-4f8c-80fa-57b6cc2df1e0", name: "Semnan University of Medical Sciences" },
    { id: "d04d2a25-6878-484b-8626-2fbd96015b39", name: "Shahab Danesh University" },
    { id: "c86739f0-3a27-4a3c-a907-a68bc5e7073c", name: "Shahed University Tehran" },
    { id: "e4c0259d-f757-4c1e-acf4-15e88ac7c4ea", name: "Shahed University of Medical Sciences Tehran" },
    { id: "e40339cf-0910-4c2a-b617-c11b934b038c", name: "Shahid Bahonar University of Kerman" },
    { id: "f70ce4fa-9a4f-4e51-b65b-86c743674ce7", name: "Shahid Beheshti Teacher Training College" },
    { id: "0053f1c3-1770-41ba-b864-0e433a2319a0", name: "Shahid Beheshti University Tehran" },
    { id: "916e4e10-39a5-4659-bd19-dc51030c19a2", name: "Shahid Beheshti University of Medical Sciences" },
    { id: "a5e571a3-bf8e-497b-9dab-0feda94693b2", name: "Shahid Chamran University North Branch of Dezful" },
    { id: "d35020ed-f2e0-4b84-9afd-048d9dd53363", name: "Shahid Chamran University of Ahvaz" },
    { id: "8687547a-bfea-486e-81ef-e62d57806407", name: "Shahid Rajaee Teacher Training University Tehran" },
    { id: "5d4b57ac-05e2-4469-99eb-6da14ffc05e0", name: "Shahid Sadoughi University of Medical Sciences and Health Services" },
    { id: "2164f785-8345-4a4b-ad51-e5bec7fd08cb", name: "Shahid Sattari University of Aeronautical Engineering Tehran" },
    { id: "15073bb5-c140-42b7-8957-0dccb26429f8", name: "Shahid Shamsipour Institute of Technology" },
    { id: "f2c96f8b-d68a-43f1-801e-8e26e85a7b92", name: "Shahrekord University" },
    { id: "2e1c0802-c694-429c-89d7-28d1c264f4ce", name: "Shahrekord University of Medical Sciences" },
    { id: "b0754e0f-06c8-4cbf-af4c-dd1f584a2c25", name: "Shahrood University of Technology" },
    { id: "139d79be-a0a3-4cd2-9200-b2653dc7cb30", name: "Shahroud University of Medical Sciences" },
    { id: "87e7b7fd-8b84-48f4-b94e-53bf2fe6ec2f", name: "Shariaty Technical College Tehran" },
    { id: "216915d7-1762-4528-916e-6f2badc40723", name: "Sharif University of Technology Tehran" },
    { id: "c4d466d9-6318-46e3-b4e9-79de27f68765", name: "Sharif University of Technology International Campus - Kish" },
    { id: "10edc2e0-7506-4cdb-a5af-b07efff04795", name: "Sheikhbahaee institute of higher education Isfahan" },
    { id: "7bae489c-c29b-4fd3-92d9-31553fc89183", name: "Shiraz University" },
    { id: "f868cf06-f2de-4a7d-8691-75c4d1cffe75", name: "Shiraz University of Medical Sciences" },
    { id: "6218892f-845e-490f-80a9-f8aa37d66a91", name: "Shiraz University of Technology" },
    { id: "d0b37178-d4e7-44a9-b3b1-9dff9918b9c9", name: "Shomal University" },
    { id: "bd4fd6d4-f70b-4972-8b7f-2cf2e28726cb", name: "Soore University" },
    { id: "9bc5c5ce-eb54-4b30-9b29-e28446612bde", name: "Tabarestan University (Tabarestan Higher Education Institution)" },
    { id: "ccb6f9c4-121a-444c-bee4-1f8de987300c", name: "Tabriz Art University" },
    { id: "dc4f854e-aad7-441d-8f93-740d56d07821", name: "Tabriz University of Medical Sciences" },
    { id: "e63b22cd-5573-485b-a7e0-8857d2dc5ace", name: "Tafresh University" },
    { id: "f41d2feb-d455-4d98-9c1c-4c68f71d8ac7", name: "Tarbiat Moallem University of Arak" },
    { id: "ba4badc2-9d9d-457a-92bb-38affba0e946", name: "Tarbiat Moallem University of Sabzevar" },
    { id: "07c040a7-a40f-464b-b9ab-f6b7b09a9854", name: "Tarbiat Modares University Professor Training University" },
    { id: "497534e8-866f-4e2e-b346-4353b66e7aff", name: "Tehran University of Medical Sciences" },
    { id: "16d39182-5e17-4725-b42d-bc11ead7b039", name: "Technical and Vocational University" },
    { id: "7be05faa-529e-4f46-bd75-2fe58e8024d7", name: "Tehran University of Medical Sciences - International Campus" },
    { id: "2eb2fb7a-754c-429a-a85e-33bf70596a03", name: "Torbat Heydarieh University of Medical Sciences" },
    { id: "ab908232-05b4-4896-b00c-f29c4d2e462e", name: "University College of Nabi Akram Tabriz" },
    { id: "8cbf7b20-6f55-498b-bdca-0a101cd79048", name: "University of Applied Science and Technology" },
    { id: "3c0aba57-8b21-4d87-946a-00b4a8a69a2e", name: "University of Arak" },
    { id: "0cff6a4a-0b65-4343-9f71-834e5c9cb530", name: "University of Art Tehran" },
    { id: "68127f34-5bce-4980-8e9f-a1eb7556845e", name: "University of Birjand" },
    { id: "60a663c6-3e17-4a45-a20a-ec5ee3db4959", name: "University of Bonab" },
    { id: "724836a0-09d2-44f2-b096-6053de1957c2", name: "University of Defence Sciences and Technologies Isfahan" },
    { id: "53dc5788-e7b8-4a61-845c-f4150f848247", name: "University of Economic sciences Tehran" },
    { id: "16b557e7-0519-4909-ad22-d717669ec1d1", name: "University of Guilan" },
    { id: "1ac3deb8-38fb-43ae-92a5-e1d01b1c0682", name: "University of Isfahan" },
    { id: "ff0417bb-46d8-4baf-a78e-94cd8f54b66c", name: "University of Sects Tehran" },
    { id: "ddb1ac20-ffed-4969-998e-56f80997c732", name: "University of Judicial Sciences and Administrative Services Tehran" },
    { id: "01af1cf9-571a-4cdd-b3be-b2f8194319ac", name: "University of Kashan" },
    { id: "66640504-d59a-4495-95a6-16f6fe24a177", name: "University of Kordestan" },
    { id: "40640b7b-bfb6-482c-823b-a470e2be00d8", name: "University of Mazandaran Babolsar" },
    { id: "2fa05a82-0bfa-410a-9d24-5ba4fb2387d3", name: "University of Qom" },
    { id: "ee41aada-c897-43eb-a9f4-eb0f88badfcd", name: "University of Semnan" },
    { id: "81dcd352-4cb9-4bc8-bc25-ec57b31a340e", name: "University of Sistan and Baluchestan Zahedan" },
    { id: "5d6f15ea-ee35-4c81-a23c-376508b31458", name: "University of Social Welfare and Rehabilitation Sciences Tehran" },
    { id: "1debc8c1-f139-44d1-9681-2eeb537b15e8", name: "University of Tabriz" },
    { id: "bafa1627-0b1c-499b-82ce-c01f918f8567", name: "University of Tehran" },
    { id: "e20a4100-60db-44bd-a96c-f05724b014c7", name: "University of Zabol" },
    { id: "3c2cbd05-15cd-46ee-9d00-ea5f9d6d5912", name: "University of Yazd" },
    { id: "560bf26f-130f-455f-80fe-b803c4bbd3e0", name: "University of Zanjan" },
    { id: "a54f3f8e-90b1-4513-b3d9-4e93db582cda", name: "Urmia University" },
    { id: "96fd47ac-11f6-4b69-9c29-6aff7f95ac8c", name: "Urmia University of Medical Sciences" },
    { id: "56e9fc51-ec65-477c-ae53-4ed8020a3862", name: "Urmia University of Technology" },
    { id: "e33d7d03-bde9-4b1a-bacd-747a831749da", name: "ValiAsr University of Rafsanjan (Vali e Asr)" },
    { id: "bb244457-5bf8-4104-82d7-34068973c14f", name: "Yasuj University" },
    { id: "1546fb5b-2721-4025-a12d-fa9a9363435d", name: "Yasuj University of Medical Sciences" },
    { id: "808670b4-feac-46cf-83e5-56af547635d7", name: "Yazd Institute of Higher Education ACECR" },
    { id: "de6c5acd-0dff-44b6-a252-ee4599e361dd", name: "Isfahan Institute of Higher Education ACECR" },
    { id: "ed08a616-497d-42af-aa9f-ac347e5c2b6b", name: "Zabol University of Medical Sciences" },
    { id: "89e63b19-8739-4c39-bf06-7905b4d174f7", name: "Zabol University" },
    { id: "2e28e281-f1ea-4d4c-bb50-ef0cc31151d5", name: "Zahedan University of Medical Sciences" },
    { id: "c21ae724-da34-4bda-a627-648c5e38801d", name: "Zanjan University of Medical Sciences" },
];