import React from 'react';
import EdHelmet from '../../components/edHelmet';
import BlockPosts from './../../components/blockPosts';
import { dataBlogPosts } from './../../data/dataBlogPosts';

const PageBlogPost = () =>
    <main>
        <EdHelmet title="Blog" description="EdPLATO an AI-powered platform transforming learning. Explore the professor-supervised Knowledge Center with courses, quizzes, case writing, AI grading, and more evaluation tools." />
        <section>
            <h1>Blog</h1>
            <p className="mb-10 text-lg text-gray-600 dark:text-gray-400 lg:text-lg">
                EdPLATO an AI-powered platform transforming learning. Explore the professor-supervised Knowledge Center with courses, quizzes, case writing, AI grading, and more evaluation tools.
            </p>
            <hr />
        </section>

        <section className='max-w-5xl mx-auto grid gap-6 grid-cols-1 md:grid-cols-2'>
            <BlockPosts posts={dataBlogPosts} lang="en" />
        </section>
    </main>


export default PageBlogPost