export const dataOurCareers = [
    {
        department: { en: "Marketing", fr: "Marketing" },
        title: { en: "Graphic Designer (UI/UX)", fr: "Designer graphique (UI/UX)" },
        location: { en: "Remote (WFH)", fr: "Télétravail" },
    },
    {
        department: { en: "Marketing", fr: "Marketing" },
        title: { en: "Content Strategist/Copywriter", fr: "Stratège de contenu/Rédacteur" },
        location: { en: "Remote (WFH)", fr: "Télétravail" },
    },
    {
        department: { en: "Marketing", fr: "Marketing" },
        title: { en: "Social Media Strategist", fr: "Stratège en médias sociaux" },
        location: { en: "Remote (WFH)", fr: "Télétravail" },
    },
    {
        department: { en: "Sales", fr: "Ventes" },
        title: { en: "Market Research Analyst", fr: "Analyste de recherche de marché" },
        location: { en: "Remote (WFH)", fr: "Télétravail" },
    },
    {
        department: { en: "DEV", fr: "DEV" },
        title: { en: "UI/UX Designer", fr: "Designer UI/UX" },
        location: { en: "Remote (WFH)", fr: "Télétravail" },
    },
    {
        department: { en: "DEV", fr: "DEV" },
        title: { en: "DevOps", fr: "DevOps" },
        location: { en: "Remote (WFH)", fr: "Télétravail" },
    },
    {
        department: { en: "DEV", fr: "DEV" },
        title: { en: "LLM Specialist", fr: "Spécialiste LLM" },
        location: { en: "Remote (WFH)", fr: "Télétravail" },
    },
    {
        department: { en: "DEV", fr: "DEV" },
        title: { en: "QA Engineer", fr: "Ingénieur QA" },
        location: { en: "Remote (WFH)", fr: "Télétravail" },
    },
    {
        department: { en: "DEV", fr: "DEV" },
        title: { en: "Software Developer", fr: "Développeur logiciel" },
        location: { en: "Remote (WFH)", fr: "Télétravail" },
    },
    {
        department: { en: "DEV", fr: "DEV" },
        title: { en: "AI Research Scientist", fr: "Scientifique en recherche IA" },
        location: { en: "Remote (WFH)", fr: "Télétravail" },
    },
]
