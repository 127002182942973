import React from "react";
import { useSpring, animated } from "react-spring";
import { ContextTheme } from "../pages/contextTheme";
import { isEmpty } from "../helper/stringHelper";

const BlockDarkSwitch = ({ className }) => {
    const { theme, setTheme } = React.useContext(ContextTheme);
    const propSun = { r: 9, transform: "rotate(40deg)", cx: 12, cy: 4, opacity: 0 };
    const propMoon = { r: 5, transform: "rotate(90deg)", cx: 30, cy: 0, opacity: 1 };
    const propSpringConfig = { mass: 4, tension: 250, friction: 35 };
    const { r, transform, cx, cy, opacity } = (theme === 'dark') ? propSun : propMoon;
    const propsSvgContainer = useSpring({ transform, config: propSpringConfig });
    const propsCenterCircle = useSpring({ r, config: propSpringConfig });
    const propsMaskedCircle = useSpring({ cx, cy, config: propSpringConfig });
    const propsLines = useSpring({ opacity, config: propSpringConfig });

    React.useEffect(() => {
        const defaultTheme = window.localStorage.getItem('theme')
        if (isEmpty(defaultTheme)) {
            if (isEmpty(theme)) {
                try {
                    if (window?.matchMedia('(prefers-color-scheme: dark)')?.matches === true) changeTheme("dark")
                    else changeTheme("light")
                }
                catch {
                    changeTheme("light")
                }
            }
            else {
                changeTheme(defaultTheme)
            }
        }
        else {
            changeTheme(defaultTheme)
        }
        // eslint-disable-next-line
    }, [])

    const changeTheme = (newTheme) => {
        setTheme(newTheme)
        window.localStorage.setItem('theme', newTheme)
        if (newTheme === "dark") {
            document.documentElement.classList.add("dark");
        } else {
            document.documentElement.classList.remove("dark");
        }
    }

    return (
        <div className={`flex items-center ${className || ""}`}>
            <animated.svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#666"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                style={{ ...propsSvgContainer, cursor: "pointer" }}
                onClick={() => changeTheme(theme === "dark" ? "light" : "dark")}
            >
                <mask id="mask">
                    <rect x="0" y="0" width="100%" height="100%" fill="white" />
                    <animated.circle style={propsMaskedCircle} cx="12" cy="4" r="10" fill="black" />
                </mask>
                <animated.circle style={propsCenterCircle} fill="#ddd" cx="12" cy="12" r="9" mask="url(#mask)" />
                <animated.g style={propsLines} fill="#666">
                    <line x1="12" y1="1" x2="12" y2="3" />
                    <line x1="12" y1="21" x2="12" y2="23" />
                    <line x1="4.22" y1="4.22" x2="5.64" y2="5.64" />
                    <line x1="18.36" y1="18.36" x2="19.78" y2="19.78" />
                    <line x1="1" y1="12" x2="3" y2="12" />
                    <line x1="21" y1="12" x2="23" y2="12" />
                    <line x1="4.22" y1="19.78" x2="5.64" y2="18.36" />
                    <line x1="18.36" y1="5.64" x2="19.78" y2="4.22" />
                </animated.g>
            </animated.svg>
        </div>
    );
};


export default BlockDarkSwitch