export const dataCourses = [
    {
        "id": "a40d23e5-0695-404b-bc32-fcd26736a8ef",
        "createdOn": "2023-07-10",
        "source": "hbs",
        "isModified": false,
        "en": {
            "name": "Strategic Management",
            "sessions": [
                {
                    "name": "Understanding Competitive Forces",
                    "materials": [
                        "Porter_Five_Forces.pdf",
                        "Competitive_Strategy.ppt",
                        "Case_Study_Alphabet.pdf"
                    ]
                },
                {
                    "name": "Corporate Strategy",
                    "materials": [
                        "Mergers_Acquisitions.pdf",
                        "Corporate_Strategy_Framework.ppt",
                        "Case_Study_General_Electric.pdf"
                    ]
                },
                {
                    "name": "Business Ethics",
                    "materials": [
                        "Business_Ethics_Fundamentals.pdf",
                        "Ethical_Decision_Making.ppt",
                        "Case_Study_Enron.pdf"
                    ]
                },
                {
                    "name": "Innovation and Entrepreneurship",
                    "materials": [
                        "Innovation_Strategies.pdf",
                        "Entrepreneurship_Framework.ppt",
                        "Case_Study_Tesla.pdf"
                    ]
                }
            ]
        },
        "fr": {
            "name": "Management Stratégique",
            "sessions": [
                {
                    "name": "Comprendre les forces concurrentielles",
                    "materials": [
                        "Porter_Five_Forces.pdf",
                        "Competitive_Strategy.ppt",
                        "Case_Study_Alphabet.pdf"
                    ]
                },
                {
                    "name": "Stratégie d'entreprise",
                    "materials": [
                        "Mergers_Acquisitions.pdf",
                        "Corporate_Strategy_Framework.ppt",
                        "Case_Study_General_Electric.pdf"
                    ]
                },
                {
                    "name": "Éthique des affaires",
                    "materials": [
                        "Business_Ethics_Fundamentals.pdf",
                        "Ethical_Decision_Making.ppt",
                        "Case_Study_Enron.pdf"
                    ]
                },
                {
                    "name": "Innovation et entrepreneuriat",
                    "materials": [
                        "Innovation_Strategies.pdf",
                        "Entrepreneurship_Framework.ppt",
                        "Case_Study_Tesla.pdf"
                    ]
                }
            ]
        }
    },
    {
        "id": "f69d3ad3-9b76-4d52-a9a2-7f706795bad7",
        "createdOn": "2023-01-12",
        "source": "canvas",
        "isModified": true,
        "en": {
            "name": "Financial Markets",
            "sessions": [
                {
                    "name": "Basics of Financial Markets",
                    "materials": [
                        "Introduction_to_Financial_Markets.pdf",
                        "Stocks_and_Bonds.ppt",
                        "Case_Study_NYSE.pdf"
                    ]
                },
                {
                    "name": "Derivative Instruments",
                    "materials": [
                        "Derivatives_Explained.pdf",
                        "Futures_and_Options.ppt",
                        "Case_Study_CBOE.pdf"
                    ]
                },
                {
                    "name": "Portfolio Management",
                    "materials": [
                        "Modern_Portfolio_Theory.pdf",
                        "Risk_and_Return.ppt",
                        "Case_Study_BlackRock.pdf"
                    ]
                },
                {
                    "name": "Financial Crisis",
                    "materials": [
                        "Financial_Crisis_2008.pdf",
                        "Causes_and_Effects.ppt",
                        "Case_Study_Lehman_Brothers.pdf"
                    ]
                },
                {
                    "name": "Cryptocurrencies",
                    "materials": [
                        "Understanding_Cryptocurrencies.pdf",
                        "Blockchain_and_Bitcoin.ppt",
                        "Case_Study_Bitcoin.pdf"
                    ]
                }
            ]
        },
        "fr": {
            "name": "Marchés Financiers",
            "sessions": [
                {
                    "name": "Les bases des marchés financiers",
                    "materials": [
                        "Introduction_aux_Marchés_Financiers.pdf",
                        "Actions_et_Obligations.ppt",
                        "Étude_de_Cas_NYSE.pdf"
                    ]
                },
                {
                    "name": "Instruments dérivés",
                    "materials": [
                        "Les_Dérivés_Expliqués.pdf",
                        "Contrats_à_Terme_et_Options.ppt",
                        "Étude_de_Cas_CBOE.pdf"
                    ]
                },
                {
                    "name": "Gestion de portefeuille",
                    "materials": [
                        "Théorie_Moderne_de_Portefeuille.pdf",
                        "Risque_et_Rendement.ppt",
                        "Étude_de_Cas_BlackRock.pdf"
                    ]
                },
                {
                    "name": "Crise financière",
                    "materials": [
                        "Crise_Financière_2008.pdf",
                        "Causes_et_Effets.ppt",
                        "Étude_de_Cas_Lehman_Brothers.pdf"
                    ]
                },
                {
                    "name": "Cryptomonnaies",
                    "materials": [
                        "Compréhension_des_Cryptomonnaies.pdf",
                        "Blockchain_et_Bitcoin.ppt",
                        "Étude_de_Cas_Bitcoin.pdf"
                    ]
                }
            ]
        }
    },
    {
        "id": "0dfb61b8-b238-451d-97c9-7398f8441072",
        "createdOn": "2023-03-28",
        "name": "Marketing Strategy",
        "source": "moodle",
        "en": {
            "isModified": false,
            "sessions": [
                {
                    "name": "Market Segmentation",
                    "materials": [
                        "Understanding_Market_Segmentation.pdf",
                        "Segmentation_Strategies.ppt",
                        "Case_Study_Netflix.pdf"
                    ]
                },
                {
                    "name": "Brand Positioning",
                    "materials": [
                        "Brand_Positioning_Basics.pdf",
                        "Positioning_Strategies.ppt",
                        "Case_Study_Apple.pdf"
                    ]
                },
                {
                    "name": "Product Life Cycle",
                    "materials": [
                        "Product_Life_Cycle_Concepts.pdf",
                        "Life_Cycle_Management.ppt",
                        "Case_Study_Coca_Cola.pdf"
                    ]
                },
                {
                    "name": "Digital Marketing",
                    "materials": [
                        "Digital_Marketing_Overview.pdf",
                        "SEO_and_Social_Media.ppt",
                        "Case_Study_Airbnb.pdf"
                    ]
                },
                {
                    "name": "Consumer Behavior",
                    "materials": [
                        "Understanding_Consumer_Behavior.pdf",
                        "Psychological_Factors.ppt",
                        "Case_Study_Amazon.pdf"
                    ]
                },
                {
                    "name": "Global Marketing",
                    "materials": [
                        "Global_Marketing_Strategies.pdf",
                        "Cultural_Considerations.ppt",
                        "Case_Study_McDonalds.pdf"
                    ]
                }
            ]
        },
        "fr": {
            "name": "Stratégie Marketing",
            "sessions": [
                {
                    "name": "Segmentation de marché",
                    "materials": [
                        "Comprendre_la_Segmentation_de_Marché.pdf",
                        "Stratégies_de_Segmentation.ppt",
                        "Étude_de_Cas_Netflix.pdf"
                    ]
                },
                {
                    "name": "Positionnement de marque",
                    "materials": [
                        "Bases_du_Positionnement_de_Marque.pdf",
                        "Stratégies_de_Positionnement.ppt",
                        "Étude_de_Cas_Apple.pdf"
                    ]
                },
                {
                    "name": "Cycle de vie du produit",
                    "materials": [
                        "Concepts_du_Cycle_de_Vie_du_Produit.pdf",
                        "Gestion_du_Cycle_de_Vie.ppt",
                        "Étude_de_Cas_Coca_Cola.pdf"
                    ]
                },
                {
                    "name": "Marketing numérique",
                    "materials": [
                        "Aperçu_du_Marketing_Numérique.pdf",
                        "SEO_et_Médias_Sociaux.ppt",
                        "Étude_de_Cas_Airbnb.pdf"
                    ]
                },
                {
                    "name": "Comportement du consommateur",
                    "materials": [
                        "Comprendre_le_Comportement_du_Consommateur.pdf",
                        "Facteurs_Psychologiques.ppt",
                        "Étude_de_Cas_Amazon.pdf"
                    ]
                },
                {
                    "name": "Marketing mondial",
                    "materials": [
                        "Stratégies_de_Marketing_Mondial.pdf",
                        "Considérations_Culturelles.ppt",
                        "Étude_de_Cas_McDonalds.pdf"
                    ]
                }
            ]
        }
    },
    {
        "id": "15aa9c3f-7d61-4780-b1fa-544580fed1d6",
        "createdOn": "2023-05-01",
        "source": "blackboard",
        "isModified": true,
        "en": {
            "name": "Business Analytics",
            "sessions": [
                {
                    "name": "Introduction to Business Analytics",
                    "materials": [
                        "Overview_of_Business_Analytics.pdf",
                        "Business_Analytics_Tools.ppt",
                        "Case_Study_IBM.pdf"
                    ]
                },
                {
                    "name": "Data Visualization",
                    "materials": [
                        "Principles_of_Data_Visualization.pdf",
                        "Data_Visualization_Tools.ppt",
                        "Case_Study_Tableau.pdf"
                    ]
                },
                {
                    "name": "Predictive Analytics",
                    "materials": [
                        "Predictive_Analytics_Basics.pdf",
                        "Predictive_Modeling.ppt",
                        "Case_Study_Uber.pdf"
                    ]
                },
                {
                    "name": "Machine Learning for Business",
                    "materials": [
                        "Machine_Learning_Applications.pdf",
                        "Supervised_vs_Unsupervised_Learning.ppt",
                        "Case_Study_Google.pdf"
                    ]
                },
                {
                    "name": "Big Data and Cloud Computing",
                    "materials": [
                        "Understanding_Big_Data.pdf",
                        "Cloud_Computing_Overview.ppt",
                        "Case_Study_Amazon_Web_Services.pdf"
                    ]
                },
                {
                    "name": "Data Privacy and Ethics",
                    "materials": [
                        "Data_Privacy_Fundamentals.pdf",
                        "Ethics_in_Data_Science.ppt",
                        "Case_Study_Facebook.pdf"
                    ]
                }
            ]
        },
        "fr": {
            "name": "Analyse d'Affaires",
            "sessions": [
                {
                    "name": "Introduction à l'Analyse d'Affaires",
                    "materials": [
                        "Aperçu_de_l'Analyse_d'Affaires.pdf",
                        "Outils_d'Analyse_d'Affaires.ppt",
                        "Étude_de_Cas_IBM.pdf"
                    ]
                },
                {
                    "name": "Visualisation des Données",
                    "materials": [
                        "Principes_de_la_Visualisation_des_Données.pdf",
                        "Outils_de_Visualisation_des_Données.ppt",
                        "Étude_de_Cas_Tableau.pdf"
                    ]
                },
                {
                    "name": "Analyse Prédictive",
                    "materials": [
                        "Fondements_de_l'Analyse_Prédictive.pdf",
                        "Modélisation_Prédictive.ppt",
                        "Étude_de_Cas_Uber.pdf"
                    ]
                },
                {
                    "name": "Apprentissage Automatique pour les Affaires",
                    "materials": [
                        "Applications_de_l'Apprentissage_Automatique.pdf",
                        "Apprentissage_Supervisé_vs_Non_Supervisé.ppt",
                        "Étude_de_Cas_Google.pdf"
                    ]
                },
                {
                    "name": "Big Data et Informatique en Nuage",
                    "materials": [
                        "Compréhension_du_Big_Data.pdf",
                        "Aperçu_de_l'Informatique_en_Nuage.ppt",
                        "Étude_de_Cas_Amazon_Web_Services.pdf"
                    ]
                },
                {
                    "name": "Confidentialité des Données et Éthique",
                    "materials": [
                        "Fondements_de_la_Confidentialité_des_Données.pdf",
                        "Éthique_en_Science_des_Données.ppt",
                        "Étude_de_Cas_Facebook.pdf"
                    ]
                }
            ]
        }
    },
    {
        "id": "ad3fed75-6a13-4992-887f-47731709febd",
        "createdOn": "2023-08-01",
        "source": "hbs",
        "isModified": false,
        "en": {
            "name": "Marketing Strategy",
            "sessions": [
                {
                    "name": "Market Segmentation",
                    "materials": ["segmentation.pdf", "marketing.pptx", "targeting.docx", "positioning.pdf", "brands.pptx", "promotion.docx", "pricing.pdf"]
                },
                {
                    "name": "Consumer Behavior",
                    "materials": ["behavior.pdf", "psychology.pptx", "buying_process.docx", "consumer_insights.pdf", "trends.pptx"]
                }
            ]
        },
        "fr": {
            "name": "Stratégie Marketing",
            "sessions": [
                {
                    "name": "Segmentation de marché",
                    "materials": ["segmentation.pdf", "marketing.pptx", "targeting.docx", "positioning.pdf", "brands.pptx", "promotion.docx", "pricing.pdf"]
                },
                {
                    "name": "Comportement du consommateur",
                    "materials": ["behavior.pdf", "psychology.pptx", "buying_process.docx", "consumer_insights.pdf", "trends.pptx"]
                }
            ]
        }
    },
    {
        "id": "b65d9ccc-8fcd-4aea-843f-9b53bb5377b9",
        "createdOn": "2023-08-15",
        "source": "canvas",
        "isModified": true,
        "en": {
            "name": "Operations Management",
            "sessions": [
                {
                    "name": "Process Analysis",
                    "materials": ["process.pdf", "analysis.pptx", "flowcharts.docx", "optimization.pdf", "efficiency.pptx", "bottlenecks.docx", "variance.pdf", "standardization.pptx"]
                },
                {
                    "name": "Supply Chain Management",
                    "materials": ["supply_chain.pdf", "management.pptx", "logistics.docx", "inventory.pdf", "forecasting.pptx", "sourcing.docx", "distribution.pdf", "risk.pptx"]
                }
            ]
        },
        "fr": {
            "name": "Gestion des Opérations",
            "sessions": [
                {
                    "name": "Analyse des Processus",
                    "materials": ["process.pdf", "analysis.pptx", "flowcharts.docx", "optimization.pdf", "efficiency.pptx", "bottlenecks.docx", "variance.pdf", "standardization.pptx"]
                },
                {
                    "name": "Gestion de la Chaîne d'Approvisionnement",
                    "materials": ["supply_chain.pdf", "management.pptx", "logistics.docx", "inventory.pdf", "forecasting.pptx", "sourcing.docx", "distribution.pdf", "risk.pptx"]
                }
            ]
        }
    },
    {
        "id": "73f47b25-5915-464d-9c5e-647189a049e9",
        "createdOn": "2023-09-01",
        "source": "moodle",
        "isModified": false,
        "en": {
            "name": "Organizational Behavior",
            "sessions": [
                {
                    "name": "Leadership",
                    "materials": ["leadership.pdf", "styles.pptx", "motivation.docx", "ethics.pdf", "communication.pptx", "decision_making.docx"]
                },
                {
                    "name": "Team Dynamics",
                    "materials": ["team.pdf", "dynamics.pptx", "conflict.docx", "negotiation.pdf", "culture.pptx", "diversity.docx", "performance.pdf"]
                }
            ]
        },
        "fr": {
            "name": "Comportement Organisationnel",
            "sessions": [
                {
                    "name": "Leadership",
                    "materials": ["leadership.pdf", "styles.pptx", "motivation.docx", "ethics.pdf", "communication.pptx", "decision_making.docx"]
                },
                {
                    "name": "Dynamique d'Équipe",
                    "materials": ["team.pdf", "dynamics.pptx", "conflict.docx", "negotiation.pdf", "culture.pptx", "diversity.docx", "performance.pdf"]
                }
            ]
        }
    }
];
