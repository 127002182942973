import React from 'react';
import { Table, Breadcrumb } from 'flowbite-react';
import BlockFilterBox from './../../components/blockFilterBox';


const PageTests = () =>
    <main className="dashboard p-8 pb-24">

        <Breadcrumb aria-label="breadcrumb" className='-ml-3'>
            <Breadcrumb.Item href="/"><i className="fas fa-home mr-2"></i> Home</Breadcrumb.Item>
            <Breadcrumb.Item href="/dashboard">Dashboard</Breadcrumb.Item>
            <Breadcrumb.Item>Tests</Breadcrumb.Item>
        </Breadcrumb>

        <h1 className="text-3xl mb-0">Tests</h1>
        <p className="text-lg mb-6">View list of your generated tests.</p>

        <div className="relative bg-white shadow-md dark:bg-gray-800 sm:rounded-lg border border-gray-100 dark:border-gray-700">

            {/* Table Toolbar */}
            <div className="flex flex-col items-center justify-between p-4 space-y-3 md:flex-row md:space-y-0 md:space-x-4">
                <BlockFilterBox />
                {/* Right Side */}
                <div className="flex flex-col items-stretch justify-end flex-shrink-0 w-full space-y-2 md:w-auto md:flex-row md:space-y-0 md:items-center md:space-x-3">
                </div>
            </div>

            {/* Table */}
            <div className="overflow-x-auto">
                <div className="relative">
                    <Table>

                    </Table>
                </div>
            </div>

            {/* Table Footer */}
            <nav className="flex flex-col md:flex-row justify-between items-start md:items-center space-y-3 md:space-y-0 p-4" aria-label="Table navigation">
            </nav>
        </div>

    </main>

export default PageTests
