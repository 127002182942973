import React from 'react';
import EdHelmet from '../../components/edHelmet';

const PageTerms = () =>
    <main>
        <EdHelmet title="Terms of Service" description="Understand the terms and conditions that govern your use of EdPLATO's platform, fostering a positive and productive learning community." />
        <section>
            <h1>Terms of Service</h1>
            <p className="mb-8 text-lg lg:text-lg">
                Understand the terms and conditions that govern your use of <a className="" href="/">EdPLATO</a>'s platform, fostering a positive and productive learning community.
            </p>
            <hr />
        </section>

        <section>
        </section>

    </main>

export default PageTerms