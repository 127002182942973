import { useRouteError } from "react-router-dom";
import EdHelmet from "../../components/edHelmet";

const PageError = () => {
    const error = useRouteError();
    console.error("Error Page", error);

    return (
        <div id="error-page">
            <EdHelmet title="Erreur" description="" />
            <h1>Oups !</h1>
            <p>Désolé, une erreur inattendue s'est produite.</p>
            <p>
                <i>{error.statusText || error.message}</i>
            </p>
        </div>
    );
}

export default PageError;